import { faTrash } from '@fortawesome/pro-light-svg-icons';
import {
    DialogTitle
} from '@mui/material';
import React, { useContext, useState } from 'react';
import { buttonStyles } from '../../../styles/buttonStyles';
import PrimaryBtnIcon from '../../buttons/PrimaryBtnIcon';
import axiosRequest from '../../../helpers/axiosRequest';
import { UserContext } from '../../../store/UserContext';
import { MachineContext } from '../../../store/MachineContext';
import { useHistory } from 'react-router-dom';
import { RESET_MACHINES } from '../../../store/MachineReducers';
import Alert from '@mui/material/Alert';
import GlobalDialog from '../../reusable/GlobalDialog';
import useAsync from '../../../Hooks/useAsync';
import setLanguageText from '../../../helpers/setLanguageText';
import { LayoutContext } from '../../../store/LayoutContext';

function DeleteMachine({ selectedMachine }) {

    const history = useHistory();

    //****** CONTEXT ******
    const { language } = useContext(LayoutContext);
    const { authUser, user } = useContext(UserContext);
    const { dispatchMachines } = useContext(MachineContext);

    const {
        error,
        data,
        loading,
        executeAsync,
        executeLog,
    } = useAsync({
        axiosRequest: axiosRequest
    });

    //****** STATE ******

    const [openDelete, setOpenDelete] = useState(false);

    //****** STYLES ******
    const buttonStyle = buttonStyles();


    //****** FUNCTIONS ******

    const openDialog = () => {
        setOpenDelete(true)
    };

    const closeDialog = () => {
        setOpenDelete(false);
    };

    const confirmDeleteMachine = async e => {
        e.preventDefault();
        const response = await executeAsync({
            endpoint: 'machines',
            api_key: authUser.api_key,
            method: 'delete',
            body: {
                machine_id: selectedMachine.machine_id
            }
        });
        if (response) {
            await executeLog({
                endpoint: 'activity',
                api_key: authUser.api_key,
                body: {
                    attributes: {
                        type: 'Machine Deleted',
                        user_id: user.id,
                        machine_id: selectedMachine.machine_id,
                        machine: selectedMachine
                    }
                }
            });
        }
        setTimeout(() => {
            dispatchMachines({ type: RESET_MACHINES });
            history.push('/machines');
        }, 3000);

    };


    return (
        <>
            <PrimaryBtnIcon
                icon={faTrash}
                text="Remove Machine"
                //size="small"
                //iconSize="lg"
                //textVariant="caption"
                className={buttonStyle.danger}
                onClick={openDialog}
            />

            <GlobalDialog
                open={openDelete}
                closeModal={closeDialog}
                title="Remove Machine"
                handleSubmit={confirmDeleteMachine}
                buttonTitle="Confirm"
                successMessage="Machine deleted successfully!"
                loading={loading}
                error={error}
                data={data}
            >
                <DialogTitle>
                    <Alert variant="standard" color="error">
                    {setLanguageText(language,"Are you sure you want to delete this machine,this action will be permanent?")}
                  </Alert>
                </DialogTitle>
            </GlobalDialog>
        </>
    );
}

export default DeleteMachine;
