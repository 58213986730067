import React from 'react';
import {
  faFilePdf,
  faVideo
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonGroup, Grid, Typography } from '@mui/material';
import { buttonStyles } from '../../styles/buttonStyles';

const fileTypes = [
  { value: 0, name: 'ALL' },
  { value: 1, name: 'PDF' },
  { value: 2, name: 'Video' }
];
const sorts = [
  { value: 0, name: 'Old' },
  { value: 1, name: 'New' }
];

const FileTypes = ({
  fileTypeValue,
  setFileTypeValue,
  setSortValue
}) => {
  //******************* STYLES *******************
  const buttonStyle = buttonStyles();

  //******************* FUNCTIONS *******************
  const handleFileTypeChange = e => {
    setFileTypeValue(+e.target.value);
  };
  // const handleSortChange = e => {
  //   setSortValue(+e.target.value);
  // };

  return (
    <Grid
      alignItems="stretch"
      item
      container
      xs={12}
      md={7}
      spacing={1}
      justifyContent="space-between"
    >
      <Grid item xs={12} md={12}>
        <ButtonGroup fullWidth style={{ height: '100%' }}>
          {fileTypes.map(type => {
            return (
              <Button
                key={type.name}
                className={buttonStyle.fixPointerEvent}
                variant="contained"
                color={fileTypeValue === type.value ? 'primary' : 'inherit'}
                value={type.value}
                onClick={handleFileTypeChange}
              >
                {type.value > 0 && (
                  <FontAwesomeIcon
                    size="lg"
                    icon={
                      type.value === 1
                        ? faFilePdf
                        : type.value === 2
                        ? faVideo
                        : null
                    }
                  />
                )}
                <Typography className={buttonStyle.buttonIconText}>
                  {type.name}
                </Typography>
              </Button>
            );
          })}
        </ButtonGroup>
      </Grid>
      {/* SORT */}
      {/* <Grid item xs={12} md={5}>
        <ButtonGroup fullWidth style={{ height: '100%' }}>
          {sorts.map(type => (
            <Button
              key={type.name}
              className={buttonStyle.fixPointerEvent}
              variant="contained"
              color={sortValue === type.value ? 'primary' : 'inherit'}
              value={type.value}
              onClick={handleSortChange}
            >
              <FontAwesomeIcon
                size="2x"
                icon={
                  type.value === 0
                    ? faCalendarMinus
                    : type.value === 1
                    ? faCalendarPlus
                    : null
                }
              />

              <Typography className={buttonStyle.buttonIconText}>
                {type.name}
              </Typography>
            </Button>
          ))}
        </ButtonGroup>
      </Grid> */}
    </Grid>
  );
};

export default FileTypes;
