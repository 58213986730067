import React, { useContext, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCog,
  faIndustryAlt,
  faMoneyCheckEdit,
  faPencilRuler,
  faUser
} from '@fortawesome/pro-light-svg-icons';
import { ClickAwayListener, Grid } from '@mui/material';
import AddMachineDialog from './AddMachineDialog';
//import AddEntry from './AddEntry';
import AddEntry from '../Entry/AddEntry';
import AddCompany from '../Companies/AddCompany';
import AddUser from '../Users/AddUser';
import { UserContext } from '../../store/UserContext';
import { useHistory } from 'react-router';
import { isMobileOnly } from 'react-device-detect';
import { ThemeProvider, useTheme } from '@mui/system';
import setLanguageText from '../../helpers/setLanguageText';
import { LayoutContext } from '../../store/LayoutContext';

const useStyles = makeStyles(theme => ({
  speedDial: {
    marginRight: isMobileOnly ? 0 : theme.spacing(2),
    width: isMobileOnly ? '50%' : 'auto',

    '& .MuiSpeedDial-directionUp .MuiSpeedDial-actions': {
      height: isOpen => (!isOpen ? 0 : 'initial')
    },
    '& .MuiSpeedDialAction-staticTooltipLabel': {
      width: 'max-content'
    }
  },
  action: {
    '& .MuiSpeedDial-fab': {
      width: '100%' ,
      borderRadius: 0 
    },
    '& #SpeedDial-actions' : {
      alignSelf: 'flex-end'
    }
  },
  item: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: theme.spacing(3),
  
  }
}));

// These are the items that appear when Dial open
const actions = [
  {
    icon: <FontAwesomeIcon icon={faCog} size="xs"/>,
    name: 'Add Machine',
    operation: 'add_machine'
  },
  {
    icon: <FontAwesomeIcon icon={faPencilRuler} size="xs"/>,
    name: 'Add Entry',
    operation: 'add_entry'
  },
  {
    icon: <FontAwesomeIcon icon={faIndustryAlt} size="xs"/>,
    name: 'Add Company',
    operation: 'add_company'
  },
  {
    icon: <FontAwesomeIcon icon={faUser} size="xs"/>,
    name: 'Add User',
    operation: 'add_user'
  },
  {
    icon: <FontAwesomeIcon icon={faMoneyCheckEdit} size="xs"/>,
    name: 'Add Service Report',
    operation: 'add_service'
  }
];

export default function FloatingActionButton(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [isDialog, setIsDialog] = useState();
  const { user } = useContext(UserContext);
  const { language } = useContext(LayoutContext);
  const classes = useStyles(isOpen);


  const openFloating = () => setIsOpen(true);
  const closeFloating = () => setIsOpen(false);

  // Each Dial Item do something different based on the operation attribute
  const handleDialAction = (e, action) => {
console.log(props)

    if (action.operation === 'add_service'){
      console.log('add_service')
      props.push('/service');
    } 
      
    switch (action.operation) {
      case 'add_machine':
        closeFloating();
        setIsDialog(action.operation);
        break;
      case 'add_entry':
        closeFloating();
        setIsDialog(action.operation);
        break;
      case 'add_company':
        closeFloating();
        setIsDialog(action.operation);
        break;
      case 'add_user':
        closeFloating();
        setIsDialog(action.operation);
        break;
      default:
        break;
    }
  };

  let applicationTheme = useTheme();

  return (
    user.level > 6 && (
      <ThemeProvider theme={applicationTheme}>
      <Grid item className={classes.speedDial}>
        <ClickAwayListener onClickAway={closeFloating}>
          <SpeedDial
            ariaLabel="SpeedDial"
            icon={<SpeedDialIcon />}
            onClick={isOpen ? closeFloating : openFloating}
            open={isOpen}
            direction="up"
            className={isMobileOnly ? classes.action : ''}
          >
            {actions.map(action => {
              if (action.operation === 'add_user' && user.level < 8)
                return null;
              if (action.operation === 'add_company' && user.level < 9)
                return null;
              return (
                <SpeedDialAction
                  open={isOpen}
                  key={action.name}
                  icon={action.icon}
                  tooltipTitle={setLanguageText(language,action.name)}
                  tooltipOpen
                  onClick={e => {
                 
                      handleDialAction(e, action);
                  
                   
                  }}
                  className={classes.item}
                />
              );
            })}
          </SpeedDial>
        </ClickAwayListener>
        {/* Dialogs are always rendered so we only render them if we open them */}
        {isDialog === 'add_machine' ? (
          <AddMachineDialog
            isDialog={isDialog === 'add_machine'}
            setIsDialog={setIsDialog}
          />
        ) : isDialog === 'add_entry' ? (
          <AddEntry
            isDialog={isDialog === 'add_entry'}
            setIsDialog={setIsDialog}
          />
        ) : isDialog === 'add_company' ? (
          <AddCompany
            open={isDialog === 'add_company'}
            closeDialog={setIsDialog}
          />
        ) : isDialog === 'add_user' ? (
          <AddUser open={isDialog === 'add_user'} closeDialog={setIsDialog} />
        ) : null}
      </Grid>
      </ThemeProvider>
    )
  );
}
