import React, { useCallback, useContext, useEffect, useState } from 'react';
// import './App.css';
import Amplify from 'aws-amplify';
import AWS from 'aws-sdk';
//import Auth from '@aws-amplify/auth';
import {
  AmplifyAuthenticator,
  AmplifyAuthContainer,
  AmplifySignIn,
  AmplifyRequireNewPassword
} from '@aws-amplify/ui-react';
import config from '../../config';
import axios from 'axios';
import { UserContext } from '../../store/UserContext';
import { GET_USERS, AUTH_USER } from '../../store/UserReducers';
import { APP_THEME, USER_THEME } from '../../store/LayoutReducers';
import { LayoutContext } from '../../store/LayoutContext';
import parseUser from '../../helpers/parseUser';
import useAsync from '../../Hooks/useAsync';
import axiosRequest from '../../helpers/axiosRequest';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Fade,
  IconButton,
  InputAdornment,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import LoadingScreen from '../reusable/LoadingScreen';
import Footer from '../Footer/Footer';
import { useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCheckCircle, faTimes, faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import setLanguageText from '../../helpers/setLanguageText';
// import { Hub, HubCallback } from '@aws-amplify/core';
// import {
//   UI_AUTH_CHANNEL, TOAST_AUTH_ERROR_EVENT
//  } from '@aws-amplify/ui-components';
// import { Alert } from '@mui/material';

Amplify.configure({
  Auth: {
    //mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  },
  API: {
    endpoints: [
      {
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      }
    ]
  },
  Storage: {
    AWSS3: {
      region: config.s3.REGION,
      bucket: config.s3.BUCKET
    }
  }
});
AWS.config.update({
  region: config.cognito.REGION
});


const useStyle = makeStyles(theme => ({
  root: {
    '--amplify-primary-color': theme.palette.primary.main,
    '--amplify-primary-tint': theme.palette.primary.main,
    '--amplify-primary-shade': theme.palette.primary.main
  }
}));

const AmplifyAuth = ({ loadedTheme, children }) => {

  let location = useLocation();
  let history = useHistory();
  const { language } = useContext(LayoutContext);
  const [user, setUser] = useState();
  const [passwordChange, setPasswordChange] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  //detects URL to see if username and password is available
  useEffect(() => {
    //console.log(location.search)
    if (location.search) {
      var regex = /[?&]([^=#]+)=([^&#]*)/g,
        url = location.search,
        params = {},
        match;
      while (match = regex.exec(url)) {
        params[match[1]] = match[2];
      }
      if (params.u && params.p ) {
        //removes param in the url
        //history.replace('/');
        //console.log(decodeURI(params.u))
        //console.log(decodeURI(params.p))
        signIn(decodeURI(params.u), decodeURI(params.p))

      } else {
        console.log('wrong params')
      }
    }
  }, [location.search])

  //auto sign in if URL username and password is available
  async function signIn(username, password) {
    console.log('signin')
    try {
      const user = await Amplify.Auth.signIn(username, password);
      //console.log(user.challengeName)
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED' || user.challengeName === 'PASSWORD_VERIFIER' ) {
        setPasswordChange(true)
        setUser(user)
      }
      // if (user.challengeName === 'PASSWORD_VERIFIER') {
      //   setPasswordChange(true)
      //   setUser(user)
      // }
      //console.log(user.challengeName)
    } catch (error) {
      console.log('error signing in', error);
    }
  }

  const completePassword = (e) => {
    e.preventDefault()
    //console.log(newPassword)
    //console.log(user)
    Amplify.Auth.completeNewPassword(
      user,               // the Cognito User Object
      newPassword,       // the new password
    ).then(user => {
      // at this time the user is logged in if no MFA required
      //console.log(user);
    }).catch(e => {
      console.log(e);
    });
  }

  const classes = useStyle()
  const { executeLog } = useAsync({
    axiosRequest: axiosRequest
  });
  //const [authState, setAuthState] = useState();
  const [loggedIn, setLoggedIn] = useState(false);
  const [details, setDetails] = useState();
  const { dispatchAuthUser, dispatchUser, authUser, user: userContext } =
    useContext(UserContext);
  const { dispatchTheme } = useContext(LayoutContext);

  const fetchTheme = useCallback(
    async api_key => {
      try {
        const response = await axiosRequest({
          method: 'get',
          endpoint: 'theme',
          api_key: api_key,
          params: { theme_type: 'app' }
        });
        dispatchTheme({
          type: APP_THEME,
          payload: JSON.parse(response[0].theme_settings)
        });
      } catch (error) {
        console.log(error);
      }
    },
    [dispatchTheme]
  );

  // fetch Database User details
  const fetchUser = useCallback(
    async (user, authState) => {

      try {

        const response = await axiosRequest({
          method: 'get',
          endpoint: 'users',
          params: { email: user.attributes.email }
        });

        // const response = await axios.get(
        //   `${config.apiGateway.URL}/users?sk=${config.KEY}&email=${user.attributes.email}`
        // );
        // when fetched details authorize user in context
        dispatchAuthUser({
          type: AUTH_USER,
          payload: { isAuth: authState, api_key: response.api_key }
        });

        if (response.user) {
          //set user theme
          if(response?.user?.theme_settings){
            dispatchTheme({
              type: USER_THEME,
              payload: JSON.parse(response.user.theme_settings)
            });
          }
          dispatchUser({
            type: GET_USERS,
            payload: parseUser(response.user)
          });
        }
        // Save the company settings into Company Context
        // dispatchCompany_settings({
        //   type: SAVE_COMPANY_SETTINGS,
        //   payload: require(`../../themes/theme_${response.user.company_id}`)
        // });
        // and parse the user details in context

        //get application theme
        if(response.api_key){
          fetchTheme(response.api_key);
        }
      } catch (error) {
        console.log(error);
      }
    },
    [dispatchAuthUser, dispatchUser, fetchTheme]
  );

  const getUserCredentials = useCallback(
    async details => {
      const getCredentials = async details => {
        try {
          await Amplify.Auth.currentCredentials().then(data => {
            AWS.config.update({
              accessKeyId: data.accessKeyId,
              secretAccessKey: data.secretAccessKey,
              sessionToken: data.sessionToken
            });
            //console.log(data)
            //ADD USER DETAILS TO USER DATABASE
          });
        } catch (e) {
          alert(e.message);
        }
      };
      getCredentials().then(() => {
        fetchUser(details.user, details.authState);
      });
    },
    [fetchUser]
  );

  useEffect(() => {
    if (details) {
      getUserCredentials(details);
    }
  }, [details, getUserCredentials]);

  useEffect(() => {
    //console.log(authUser)
    //console.log(userContext)
    if (loggedIn && authUser.isAuth === 'signedin' && userContext.id) {
      setLoggedIn(false);
      const saveLog = async (authUser, user) => {
        await executeLog({
          endpoint: 'activity',
          api_key: authUser.api_key,
          body: {
            attributes: {
              type: 'login',
              user_id: user.id,
              user: user.name
              //,mobile: //get from context
            }
          }
        });
      };
      saveLog(authUser, userContext);
    }
  }, [authUser, userContext, loggedIn, executeLog]);

  //setBackground
  let app = config.APPNAME
  const [background, setBackground] = useState('');
  import(`../../image/${app}-background.jpg`).then((module) => {
    setBackground(module.default);
  });

  //error 
  // const [alertMessage, setAlertMessage] = useState('');
  // const [open, setOpen] =useState();

  // const handleClose = () => {
  //   setOpen(false);
  // };

  // const handleToastErrors: HubCallback = ({ payload }) => {
  //   if (payload.event === TOAST_AUTH_ERROR_EVENT && payload.message) {
  //     setAlertMessage(payload.message);
  //     setOpen(true)
  //   }
  // };

  // useEffect(() => {
  //   Hub.listen(UI_AUTH_CHANNEL, handleToastErrors);
  //   return () => Hub.remove(UI_AUTH_CHANNEL, handleToastErrors);
  // });

  return authUser.isAuth === 'signedin' && userContext ? (
    <>
      <LoadingScreen
        loading={loadedTheme}
        colour="#fff"
        background="#222"
      />
      {loadedTheme && children}
      <Footer />
    </>
  ) : (
      <>
         {/* {alertMessage && ( 
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error">
            {alertMessage}
            </Alert>
          </Snackbar>
          )} */}

        {passwordChange && user && (

          <Dialog
            open={true}
            //onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent
              style={{ padding: '35px 40px' }}
            >
              <Typography
                variant="h5"
                style={{
                  fontWeight: 700,
                  marginBottom: '.75em'
                }}
              >{setLanguageText(language,"Set New Password")}</Typography>
              <form onSubmit={completePassword} autoComplete="off">
                <TextField
                  id="outlined-password-input"
                  label={setLanguageText(language,"Password")}
                  type={showPassword ? "text" : "password"}
                  autoComplete="current-password"
                  variant="outlined"
                  onChange={(e) => {
                    setNewPassword(e.target.value)
                  }}
                  value={newPassword}
                  InputProps={{ // <-- This is where the toggle button is added.
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          size="large">
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                //inputProps={{ pattern: "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&+~|{}:;<>/])[A-Za-z0-9$^@$!%*?&+~|{}:;<>/]{8,30}" }}
                />
                <p>Your new password must contain</p>
                <div
                  style={{
                    background: '#f9f9f9',
                    padding: '1em 1em 1em 2em',
                    border: '1px solid #e2e2e2',
                    margin: '0 0 1em 0'
                  }}
                  >
                  <p>{newPassword.length > 7 ? <FontAwesomeIcon size="lg" color="#36a03a" icon={faCheckCircle} /> : <FontAwesomeIcon size="lg" color="#de2d20" icon={faTimesCircle} />} Between 8 - 30 characters</p>
                  <p>{new RegExp("[0-9]").test(newPassword) ? <FontAwesomeIcon size="lg" color="#36a03a" icon={faCheckCircle} /> : <FontAwesomeIcon size="lg" color="#de2d20" icon={faTimesCircle} />} 1 Number</p>
                  <p>{new RegExp("[A-Z]").test(newPassword) ? <FontAwesomeIcon size="lg" color="#36a03a" icon={faCheckCircle} /> : <FontAwesomeIcon size="lg" color="#de2d20" icon={faTimesCircle} />} 1 Uppercase letter</p>
                  <p>{new RegExp("[$^@$!%*?&+~|{}:;<>/]").test(newPassword) ? <FontAwesomeIcon size="lg" color="#36a03a" icon={faCheckCircle} /> : <FontAwesomeIcon size="lg" color="#de2d20" icon={faTimesCircle} />} 1 Special character</p>
                  <p>{new RegExp("[a-z]").test(newPassword) ? <FontAwesomeIcon size="lg" color="#36a03a" icon={faCheckCircle} /> : <FontAwesomeIcon size="lg" color="#de2d20" icon={faTimesCircle} />} 1 Lowercase letter</p>
                  </div>
                    {/* <ul style={{
                      background: '#f9f9f9',
                      padding: '1em 1em 1em 2em',
                      border: '1px solid #e2e2e2'
                    }}>
                    <li><p>1 Number {new RegExp("[0-9]").test(newPassword) ? <FontAwesomeIcon size="lg" color="#36a03a" icon={faCheckCircle} /> : <FontAwesomeIcon size="lg" color="#de2d20" icon={faTimesCircle} />} </p></li>
                    <li><p>1 Special character {new RegExp("[$^@$!%*?&+~|{}:;<>/]").test(newPassword) ? <FontAwesomeIcon size="lg" color="#36a03a" icon={faCheckCircle} /> : <FontAwesomeIcon size="lg" color="#de2d20" icon={faTimesCircle} />} </p></li>
                    <li><p>1 Uppercase letter {new RegExp("[A-Z]").test(newPassword) ? <FontAwesomeIcon size="lg" color="#36a03a" icon={faCheckCircle} /> : <FontAwesomeIcon size="lg" color="#de2d20" icon={faTimesCircle} />}</p></li>
                    <li><p>1 Lowercase letter {new RegExp("[a-z]").test(newPassword) ? <FontAwesomeIcon size="lg" color="#36a03a" icon={faCheckCircle} /> : <FontAwesomeIcon size="lg" color="#de2d20" icon={faTimesCircle} />}</p></li>
                  </ul> */}
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={new RegExp("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$^@$!%*?&+~|{}:;<>/])[A-Za-z0-9$^@$!%*?&+~|{}:;<>/]{8,30}").test(newPassword) ? false : true}
                    type="submit"
                    style={{
                      borderRadius: 0,
                      textTransform: 'uppercase',
                      fontWeight: 600,
                      padding: '1em',
                      boxShadow: 'none'
                    }}
                  >
                    Set Password
            </Button>
              </form>
            </DialogContent>
          </Dialog>
        )}
            <AmplifyAuthContainer>
              <img
                src={background}
                style={{
                  position: 'fixed',
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover'
                }}
                alt="Logo"
              />

              <AmplifyAuthenticator
                className="AWS-authenticator"
                handleAuthStateChange={(state, data) => {
                  //setAuthState(state);
                  //setUser(data);
                  //console.log(state)
                  //console.log(data)
                  //only does signin on sign in
                  if (state === 'signin') {
                    setLoggedIn(true);
                  }
                  if (data && state === 'signedin') {
                    setDetails({
                      authState: state,
                      user: data
                    });
                  }
                  if (data && state === 'resettingpassword') {
                      setPasswordChange(true)
                      setUser(data)
                    
                  }
                }}
              >
              <AmplifySignIn
                  headerText={`${config.NAME} Login`}
                  //slot="sign-in"
                  className={classes.root}
                  slot="sign-in"
                  formFields={[
                    // USERNAME INPUT
                    {
                      type: 'username',
                      label: 'Username or Email*',
                      placeholder: 'username or email address',
                      required: true,
                      handleInputChange: (username, send) => {
                        send(username);
                      }
                    },
                    // PASSWORD INPUT
                    {
                      type: 'password',
                      label: 'Password',
                      placeholder: 'password',
                      required: true,
                      handleInputChange: (password, send) => {
                        send(password);
                      }
                    }
                  ]}
                >
                  {/* remove "create account" link */}
                 
                  <div slot="secondary-footer-content"></div>
                </AmplifySignIn>
                
                <AmplifyRequireNewPassword
                 hidden={true}
                  headerText="My Custom Require New Password Text"
                  slot="require-new-password"
                ></AmplifyRequireNewPassword>

              </AmplifyAuthenticator>
              <Fade in={true}>
                <div>
                  <Footer />
                </div>
              </Fade>
            </AmplifyAuthContainer>
      </>
        );
};

export default AmplifyAuth;
