import { Grid, LinearProgress, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { buttonStyles } from '../../../styles/buttonStyles';
//import { Storage, Cache } from 'aws-amplify';
// import {AmplifyS3Image} from "@aws-amplify/ui-react";
import Image from "./../../reusable/Image";

const useStyles = makeStyles(theme => ({
  linearBarContainer: {
    position: 'relative'
  },
  linearBar: {
    height: '100%',
    borderRadius: theme.spacing(0.5),
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: theme.palette.success.main
    },
    '&.MuiLinearProgress-determinate': {
      backgroundColor: theme.palette.background.light
    }
  },
  percentage: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateY(-50%)'
  },
  image: {
    padding: '10px',
    '& img' : {
      width: '100%',
    }
  }
}));

const Overview = ({ additive, classes, totalMachines, noBar }) => {
  const percentage =
    additive.machines_count && totalMachines
      ? (additive.machines_count / totalMachines) * 100
      : 0;

  const linearBarStyle = useStyles();
  const buttonStyle = buttonStyles();
 // const [imageUrl, setImageUrl] = useState();

  // useEffect(() => {
  //   if (additive && additive.additive_attr.brand) {
  //       setImageUrl(`brands/${additive.additive_attr.brand.replace(/\s/g, '_').toLowerCase()}/additive.png`)
  //   } else {
  //     setImageUrl('brands/default/additive.png')
  //   }
  //   return () => {
  //   }
  // }, [additive])


  // //TODO NEED LOOKING AT, THIS ISN'T THE BEST OPTION FOR CHECKING & SETTING CACHED IMAGES
  // useEffect(() => {
  //   //console.log(additive)
  //   if (additive && additive.additive_attr.brand) {
  //     const getImgLinkCached = async key => {
  //       const cachedImage = Cache.getItem(key);
  //       //console.log(cachedImage)
  //       if (cachedImage) {
  //         //console.log('Cache hit: ', key)
  //         return cachedImage;
  //       }
  //       //console.log('Cache miss: ', key)
  //       const url = await Storage.get(`${key}`, { expires: 604800 });
  //       Cache.setItem(key, url);
  //       return url;
  //     };
  //     getImgLinkCached(
  //       `brands/${additive.additive_attr.brand
  //         .replace(/\s/g, '_')
  //         .toLowerCase()}/additive.png`
  //     ).then(url => {
  //       setImageUrl(url);
  //     });
  //   }
  //   return () => {};
  // }, [additive]);

  // const setDefault = () => {
  //   const getDefaultCached = async key => {
  //     const cachedImage = Cache.getItem(key);
  //     //console.log(cachedImage)
  //     if (cachedImage) {
  //       //console.log('Cache hit: ', key)
  //       return cachedImage;
  //     }
  //     //console.log('Cache miss: ', key)
  //     const url = await Storage.get(`${key}`, { expires: 604800 });
  //     Cache.setItem(key, url);
  //     return url;
  //   };
  //   getDefaultCached(`brands/default/additive.png`).then(url => {
  //     setImageUrl(url);
  //   });
  // };

  return (
    <Grid container direction="column" alignItems="stretch" spacing={1}>
      {!noBar && (
        <Grid item container spacing={2} alignItems="stretch">
          <Grid
            item
            xs={8}
            sm={9}
            className={linearBarStyle.linearBarContainer}
          >
            <LinearProgress
              value={percentage}
              variant="determinate"
              className={linearBarStyle.linearBar}
            />
            <Typography variant="h6" className={linearBarStyle.percentage}>
              {`${percentage.toFixed(2)}%`}
            </Typography>
          </Grid>
          <Grid item xs={4} sm={3}>
            <Paper className={`${buttonStyle.button} ${buttonStyle.warning}`}>
              <Typography variant="h6" align="center">
                {additive.machines_count && totalMachines
                  ? `${additive.machines_count}/${totalMachines}`
                  : `0/0`}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      )}
      <Grid item container className="product--details">
        <Grid item xs={10}>
          <Typography variant="body2" className="product--desc">
            {additive.additive_attr.application}
          </Typography>
          {/* <ul> */}
          {additive.additive_attr.selling_points &&
            additive.additive_attr.selling_points.map((point, index) => (
              <li key={`point--${index}`}>
                <Typography variant="body2" className={classes.li}>
                  {point}
                </Typography>
              </li>
            ))}
          {/* </ul> */}
        </Grid>
        <Grid item  className={linearBarStyle.image} xs={2}>
            <Image additive={additive}/>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Overview;
