import { Grid, Paper } from '@mui/material';
import React from 'react';
import { buttonStyles } from '../../../styles/buttonStyles';

const Machines = ({ additive }) => {
  const buttonStyle = buttonStyles();
  return (
    <Grid
      container
      style={{ maxHeight: '200px', overflow: 'scroll' }}
      spacing={1}
    >
      {additive.machines_using_additive.map((machine, index) => (
        <Grid item xs={6} key={`${machine}${index}`}>
          <Paper className={buttonStyle.button} variant="outlined">
            {machine}
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default Machines;
