import { TextField } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import axiosRequest from '../../helpers/axiosRequest';
import { UserContext } from '../../store/UserContext';
import useAsync from '../../Hooks/useAsync';
import GlobalDialog from '../reusable/GlobalDialog';
import { LayoutContext } from '../../store/LayoutContext';
import { CompanyContext } from '../../store/CompanyContext';
import { SAVE_COMPANIES } from '../../store/CompanyReducers';
import setLanguageText from '../../helpers/setLanguageText';

const AddCompany = ({ closeDialog, open }) => {
  //************************ CONTEXT ************************
  const { authUser, user } = useContext(UserContext);
  const { language } = useContext(LayoutContext);

  const {
    dispatchCompanies
  } = useContext(CompanyContext);

  //************************ USE STATES ************************
  const [companyName, setCompanyName] = useState('');
  const {
    error,
    data,
    loading,
    logCompleted,
    logError,
    executeAsync,
    executeLog,
    setData
  } = useAsync({
    axiosRequest: axiosRequest
  });

  //************************ USE EFFECTS ************************
  // Handle Axios Request
  useEffect(() => {
    if (data) {
      // TODO handle if activity error
      if (logError) console.log('Something went wrong with the Activity Log');
      if (logCompleted) {
        // Reload page
        //setTimeout(() => {
        //window.location.reload();
        //}, 2000);
        dispatchCompanies({ type: SAVE_COMPANIES, payload: [] });
      }
    }
  }, [data, logCompleted, logError, dispatchCompanies]);

  //************************ FUNCTIONS ************************
  const handleTyping = e => {
    e.persist();
    setCompanyName(e.target.value);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    //* ADD COMPANY
    const response = await executeAsync({
      endpoint: 'company',
      api_key: authUser.api_key,
      method: 'post',
      body: {
        company_name: companyName
      }
    });
    //* LOG ACTIVITY
    if (response) {
      await executeLog({
        endpoint: 'activity',
        api_key: authUser.api_key,
        body: {
          attributes: {
            type: 'Add Company',
            user_id: user.id,
            company_id: response.insertId,
            company_name: companyName
          }
        }
      });
      //refetch companys


      setData();
      closeDialog();
    }
  };

  return (
    <GlobalDialog
      open={open}
      closeModal={closeDialog}
      title="Add Company"
      handleSubmit={handleSubmit}
      submit_disabled={data}
      buttonTitle="Add Company"
      successMessage="Company added successfully!"
      loading={loading}
      error={error}
      data={data}
      fullWidth
    >
      <TextField
        onChange={handleTyping}
        value={companyName}
        label={setLanguageText(language,"Company Name")}
        required
        variant="outlined"
        fullWidth
        id="company_name"
      />
    </GlobalDialog>
  );
};

export default AddCompany;
