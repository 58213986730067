import React ,{ useReducer }  from 'react';
import config from '../config';
import { LayoutReducer } from './LayoutReducers';

export const LayoutContext = React.createContext();

export const LayoutProvider = ({ children }) => {
	const [theme, dispatchTheme] = useReducer(LayoutReducer, {});
	const [language, dispatchLanguage] = useReducer(LayoutReducer, config.LANGUAGE);
	const contextValues = {
		language,
		dispatchLanguage,
		theme,
		dispatchTheme,
	};
	return (
		<LayoutContext.Provider value={contextValues}>
			{children}
		</LayoutContext.Provider>
	);
};
