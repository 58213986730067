import React, { useContext, useEffect, useState } from 'react';
import { faEdit } from '@fortawesome/pro-light-svg-icons';
import PrimaryBtn from '../../buttons/PrimaryBtn';
import PrimaryBtnIcon from '../../buttons/PrimaryBtnIcon';
import axiosRequest from '../../../helpers/axiosRequest';
import { UserContext } from '../../../store/UserContext';
import ResetMachine from './ResetMachine';
import { MachineContext } from '../../../store/MachineContext';
import { SELECTED_MACHINE } from '../../../store/MachineReducers';
import GlobalDialog from '../../reusable/GlobalDialog';
import useAsync from '../../../Hooks/useAsync';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { LayoutContext } from '../../../store/LayoutContext';
import setLanguageText from '../../../helpers/setLanguageText';

const useStyles = makeStyles(theme => ({
  popover_content: {
    padding: theme.spacing(1)
  }
}));

const settingsObj = selectedMachine => {
  const machine_defaults = selectedMachine.machine_settings.machine_defaults;
  return {
    change_freq: machine_defaults.change_freq
      ? machine_defaults.change_freq
      : 0,
    factor: machine_defaults.factor ? machine_defaults.factor : machine_defaults.refractomer_factor, //refractomer_factor is legacy
    last_coolant_change: machine_defaults.last_coolant_change
      ? machine_defaults.last_coolant_change
      : 'undefined',
    op_con_max: machine_defaults.op_con_max,
    op_con_min: machine_defaults.op_con_min,
    ph_max: machine_defaults.ph_max,
    ph_min: machine_defaults.ph_min,
    sump: machine_defaults.sump
  };
};
function EditSettings() {
  //****** CONTEXT ******
  const { authUser , user} = useContext(UserContext);
  const { dispatchSelectedMachine, selectedMachine } = useContext(
    MachineContext
  );
  const { language } = useContext(LayoutContext)

  //****** STATE ******
  const [anchorEl, setAnchorEl] = useState(null);
  const [dialogAnchorEl, setDialogAnchorEl] = useState(null);
  const [settings, setSettings] = useState(null);

  //****** STYLES ******
  const classes = useStyles();

  //******  USE EFFECT ******
  useEffect(() => {
    if (
      selectedMachine.machine_settings &&
      selectedMachine.machine_settings.machine_defaults
    ) {
      setSettings(settingsObj(selectedMachine));
    }
  }, [selectedMachine]);

  //****** FUNCTIONS ******
  const openPopover = event => {
    setAnchorEl(event.currentTarget);
  };
  const closePopover = () => {
    setAnchorEl(null);
  };

  const openDialog = event => {
    setDialogAnchorEl(event.currentTarget);
  };

  const closeDialog = () => {
    setDialogAnchorEl(null);
    // Reset the Machine Name/Group if close the edit dialog
    setSettings(prevState => settingsObj(selectedMachine));
  };

  const handleEditSettings = event => {
    event.persist();
    setSettings(prevState => ({
      ...prevState,
      machine_id: selectedMachine.machine_id,
      [event.target.name]: event.target.value
    }));
  };
  
  const {
    error,
    data,
    loading,
    logCompleted,
    logError,
    executeAsync,
    executeLog,
    setData
  } = useAsync({
    axiosRequest: axiosRequest
  });

  const confirmEdit = async e => {

    //console.log(settings);
    e.preventDefault();

      const response = await executeAsync({
        endpoint: 'machines',
        api_key: authUser.api_key,
        method: 'patch',
        body: {
          edit: 'settings',
          machine_id: selectedMachine.machine_id,
          settings: settings,
          last_coolant_change: selectedMachine.last_coolant_change
        }
       });
       if (response) {
          await executeLog({
            endpoint: 'activity',
            api_key: authUser.api_key,
            body: {
              attributes: {
                type: 'Machine Edit Settings',
                user_id: user.id,
                machine_id: selectedMachine.machine_id,
                settings : settings
              }
            }
      });
   }
    // try {
    //   await axiosRequest({
    //     endpoint: 'machines',
    //     api_key: authUser.api_key,
    //     method: 'patch',
    //     body: {
    //       edit: 'settings',
    //       machine_id: selectedMachine.machine_id,
    //       settings: settings,
    //       last_coolant_change: selectedMachine.last_coolant_change
    //     }
    //   }).then(machine => {
    //     dispatchSelectedMachine({
    //       type: SELECTED_MACHINE,
    //       payload: {
    //         ...selectedMachine,
    //         machine_settings: JSON.parse(machine[0].machine_settings)
    //       }
    //     });
    //     closePopover();
    //   });
    //   //window.location.reload();
    // } catch (error) {
    //   throw error;
    // }
  };

  useEffect(() => {
    if (data) {
      // TODO handle if activity error
      if (logError) console.log('Something went wrong with the Activity Log');
      if (logCompleted) {
             
          dispatchSelectedMachine({
            type: SELECTED_MACHINE,
            payload: {
              ...selectedMachine,
              machine_settings: JSON.parse(data[0].machine_settings)
            }
          });
          setTimeout(()=>{
            closePopover();
            setData();
          },1000); 
      }
    }
  }, [
    dispatchSelectedMachine,
    data,
    logCompleted, 
    logError,
    setData
  ]);

  const handleDateChange = date => {
    setSettings(prevState => ({
      ...prevState,
      last_coolant_change: date
    }));
  };

  const isPopover = Boolean(anchorEl);
  const isDialog = Boolean(dialogAnchorEl);

  return (
    <>
      <PrimaryBtnIcon icon={faEdit} text="Edit Machine Details" onClick={openPopover} />
      <Popover
        open={isPopover}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Grid container className={classes.popover_content}>
          <Grid item container direction="column" spacing={1}>
            <Grid item>
              <Typography variant="body2" align="center">
              {setLanguageText(language,"Edit Machine Details")}
              </Typography>
            </Grid>
            <Grid item>
              <PrimaryBtn
                id="edit"
                icon={faEdit}
                text="Edit settings"
                onClick={openDialog}
                disabled={!settings}
              />
              {settings && (
                <GlobalDialog
                  open={isDialog && dialogAnchorEl.id === 'edit'}
                  closeModal={closeDialog}
                  title="Edit Machine Settings"
                  handleSubmit={confirmEdit}
                  buttonTitle="Confirm"
                  successMessage="Settings edited successfully!"
                  data={data}
                  error={error}
                  loading={loading}
                  submit_disabled={data ? true : false}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        name="sump"
                       label={setLanguageText(language,"Sump")}
                        value={settings.sump}
                        onChange={handleEditSettings}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        name="factor"
                       label={setLanguageText(language,"Refractometer Factor")} //refractomer_factor is legacy
                        value={settings.factor}
                        onChange={handleEditSettings}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        name="ph_max"
                       label={setLanguageText(language,"PH Max")}
                        value={settings.ph_max}
                        onChange={handleEditSettings}
                        required            
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        name="ph_min"
                       label={setLanguageText(language,"PH Min")}
                        value={settings.ph_min}
                        onChange={handleEditSettings}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        name="op_con_max"
                       label={setLanguageText(language,"Op Con Max")}
                        value={settings.op_con_max}
                        onChange={handleEditSettings}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        name="op_con_min"
                       label={setLanguageText(language,"Op Con Min")}
                        value={settings.op_con_min}
                        onChange={handleEditSettings}
                        required
                      />
                    </Grid>
                    {/* <Grid item xs={12}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          readOnly
                          disabled
                          fullWidth
                          disableToolbar
                          variant="inline"
                          format="dd MMMM yyyy"
                          inputVariant="outlined"
                          id="date-picker-inline"
                         label={setLanguageText(language,"last coolant change"
                          value={selectedMachine.last_coolant_change}
                          onChange={handleDateChange}
                          KeyboardButtonProps={{
                            'aria-label': 'change date'
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid> */}
                  </Grid>
                </GlobalDialog>
              )}
            </Grid>
            <Grid item>
              <ResetMachine selectedMachine={selectedMachine} />
            </Grid>
          </Grid>
        </Grid>
      </Popover>
    </>
  );
}

export default EditSettings;
