import React, { useContext } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { buttonStyles } from '../../styles/buttonStyles';
import { faVial } from '@fortawesome/pro-light-svg-icons';
import setLanguageText from '../../helpers/setLanguageText';
import { LayoutContext } from '../../store/LayoutContext';

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#fff',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
        padding: theme.spacing(1)
    },
}))(Tooltip);

function AdditiveTooltip({ additives, mobile }) {

    const { language } = useContext(LayoutContext);
    //console.log(additives)

    return (

        <>
            {mobile ?
                <>
                    {additives &&
                        additives.map((item, i) => {
                            return (
                                <Grid item xs={12} key={i}>
                                    <Typography variant="caption">
                                        {item.additive_name ? `${item.additive_name} - ${item.additive_type} (${item.additive_details})` : item.additive_type}
                                    </Typography>
                                </Grid>
                            );
                        })}
                </>
                :
                <HtmlTooltip
                    title={
                        <>
                            <Typography variant="h6">{setLanguageText(language,"Additives")}</Typography>
                            <Grid container>
                                {additives &&
                                    additives.map((item, i) => {
                                        return (
                                            <Grid item xs={12} key={i}>
                                                <Typography variant="caption">
                                                    {item.additive_name ? `${item.additive_name} - ${item.additive_type} (${item.additive_details})` : item.additive_type}
                                                </Typography>
                                            </Grid>
                                        );
                                    })}
                            </Grid>
                        </>}
                >
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="center"
                        className={buttonStyles().icon_md}
                        style={{ cursor: 'pointer' }}
                    >
                        <FontAwesomeIcon icon={faVial} />
                    </Grid>
                </HtmlTooltip>
            }
        </>
    );
}

export default AdditiveTooltip;

