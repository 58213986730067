import { faQrcode } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fab, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ThemeProvider, useTheme } from '@mui/system';
import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Link } from 'react-router-dom';

const useStyle = makeStyles(theme => ({
  root: {
    marginRight: isMobileOnly ? 0 : theme.spacing(2),
    width: isMobileOnly ? '50%' : 'auto'
  },
  fab : {
      width: '100%' ,
      borderRadius:  0
  }
}));

const QrCode = () => {

  const classes = useStyle();
  const applicationTheme = useTheme();

  return (
    <ThemeProvider theme={applicationTheme}>
    <Grid item className={classes.root}>
      <Link to="/qr-code?scan=true">
        <Fab color="secondary" className={isMobileOnly ? classes.fab : ''} >
          <FontAwesomeIcon icon={faQrcode} size="lg" />
        </Fab>
      </Link>
    </Grid>
    </ThemeProvider>
  );
};

export default QrCode;
