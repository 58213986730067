import React, { useState, useEffect, useContext } from 'react';
//import QrReader from 'react-qr-reader';
import QrReader from 'react-qr-scanner';
import config from '../../config';
import { buttonStyles } from '../../styles/buttonStyles';
import { makeStyles } from '@mui/styles';
import { Alert } from '@mui/material';
import { Grid, InputAdornment, MenuItem, Select, TextField, FormControl, InputLabel } from '@mui/material';
import PrimaryBtnIcon from '../buttons/PrimaryBtnIcon';
import { faCheckCircle, faQrcode } from '@fortawesome/pro-light-svg-icons';
import GlobalDialog from '../reusable/GlobalDialog';
import axiosRequest from '../../helpers/axiosRequest';
import { UserContext } from '../../store/UserContext';
import useAsync from '../../Hooks/useAsync';
import LoadingScreen from '../reusable/LoadingScreen';
import { SelectionsContext } from '../../store/SelectionsContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { REMOVE_QRCODES } from '../../store/SelectionsReducers';
import setLanguageText from '../../helpers/setLanguageText';
import { LayoutContext } from '../../store/LayoutContext';

const useStyles = makeStyles(theme => ({
  card: { height: '100%', width: '100%' },
  cardContainer: { height: '100%' },
  cardContent: {
    paddingTop: 0,
    flex: 1
  },
  sign_check: {
    color: theme.palette.success.main
  },
}));

const DipslideScanner = ({ setQrDetails, attrName }) => {

  //****** CONTEXT ******
  const { authUser } = useContext(UserContext);
  const { language } = useContext(LayoutContext);  
  const { addNewEntry, dispatchAddNewEntry } = useContext(SelectionsContext);

  //****** STATES ******

  //const [salting_old] = useState(btoa(config.SALTING));
  const [salting] = useState(Buffer.from(config.SALTING).toString('base64'));
  //console.log(salting_old)
  //console.log(salting)

  const [open, setOpen] = useState(false);
  //qr codes
  const [result, setResult] = useState();
  const [details, setDetails] = useState();
  const [textQRCode, setTextQRCode] = useState('');
  const [scanQRCode, setScanQRCode] = useState();
  const [qrError, setQrError] = useState();
  const [relink, setRelink] = useState(false);

  const [confirmCode, setConfirmCode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [scanned, setScanned] = useState(false);
  const [scanAgain, setScanAgain] = useState(false);
  const [time, setTime] = useState(72);
  // const [cameraId, setCameraId] = useState('');
  // const [devices, setDevices] = useState([]);
  // const [delay, setDelay] = useState(500);
  //****** STYLES ******
  const classes = useStyles();
  const buttonStyle = buttonStyles();

  const {
    error,
    data,
    executeAsync,
    setData,
  } = useAsync({
    axiosRequest: axiosRequest
  });

  //use reterned data to confirm code
  useEffect(() => {
    if (error || qrError) {
      setTextQRCode('');
      setTimeout(() => {
        setData();
        setQrError();
        setLoading(false);
        setScanAgain(false);
        setConfirmCode(false);
        setScanned(false);
      }, 2000)
    }
    return () => {
      //
    }
  }, [
    error, 
    qrError,
    setData
  ]);

  const handleScan = data => {
    if (data && !scanned) {
      //setLoading(true);
      //console.log(data);
      //scan once
      setScanned(true);
      setResult({
        result: data.text
      });
    }
  };

  // const handleError = err => {
  //   console.error(err);
  // };

  const reverse = s => {
    return s.split('').reverse().join('');
  };

  //set code from text box once it has reached 12 characters
  useEffect(() => {
    //console.log(textQRCode);
    //console.log(scanQRCode);
    if (textQRCode.length === 12) {
      //console.log('set final code');
      if (!scanQRCode) {
        //typed code
        //setDetails({ app: config.APPNAME, code: textQRCode, company_id: addNewEntry.company.company_id })
        //update to remove compnay id
        setDetails({ app: config.APPNAME, code: textQRCode, company_id: -1 })
      } else {
        //if a scanned qr use the scan data
        setDetails(scanQRCode)
      }
    }
  }, [
    addNewEntry,
    textQRCode, 
    scanQRCode
  ])

  const updateCode = (e) => {
    e.preventDefault();
    if (e.target.value.length <= 12) {
      setTextQRCode(e.target.value.toUpperCase())
    }
  }
  
  //set details from scaning qr code
  useEffect(() => {
    if (result) {
      setLoading(true);
      const data = result.result;
      //console.log(data);
      //legacy SDS/SDT QR code
      let reverseData = reverse(data);
      //console.log(reverseData)
      //console.log(salting)

      //machine codes only
      if (reverseData.includes(salting)) {
        reverseData = reverseData.substring(salting.length);

        
        //console.log(reverseData)
        //reverseData = reverseData.substring(salting.length);
        reverseData = JSON.parse(Buffer.from(reverseData, "base64").toString("utf-8"));
        //let reverseData_old = JSON.parse(atob(reverseData));
        //check the qr code is for thie selected company
        //console.log(reverseData)


        //console.log(addNewEntry.company.company_id)
        if (reverseData.app === config.APPNAME) {
          if (reverseData.company_id === addNewEntry.company.company_id) {
            setScanQRCode(reverseData);
            //set textbox from scan
            setTextQRCode(reverseData.code);
          } else {
            setQrError({
              result: 'error',
              message: 'QR code not from the selected company'
            });
            //setLoading(false);
          }
        } else {
          setQrError({
            result: 'error',
            message: 'QR code not from this application'
          });
          //setLoading(false);
        }
      } else if (data) {
        
        const myArr = data.split(" ");
        console.log(myArr)

        console.log(parseInt(myArr[0]))
        console.log(addNewEntry.company.company_id)

          //console.log(myArr)
          if (parseInt(myArr[0]) === addNewEntry.company.company_id) {
            setScanQRCode({
              app: myArr[2],
              code: myArr[1],
              company_id: parseInt(myArr[0])
            });
            //set textbox from scan
            setTextQRCode(myArr[1]);

          } else if (myArr[0] === '-1'){
            console.log(myArr[0])
            setScanQRCode({
              app: myArr[2],
              code: myArr[1],
              company_id: myArr[0]
            });
            //set textbox from scan
            setTextQRCode(myArr[1]);
          
          } else {
            setQrError({
              result: 'error',
              message: 'QR code not from the selected company'
            });
            //setLoading(false);
          }
      
      } else {
        setQrError({
          result: 'error',
          message: `Not a ${config.NAME} QR code`
        });
        //setLoading(false);
      }
    }
  }, [
    result, 
    setDetails, 
    salting, 
    addNewEntry
  ]);

  //check qr code isn't being used
 
  useEffect(() => {
    if (details) {
      //console.log(details)
      const checkCode = async (fullCode) => {
        await executeAsync({
          endpoint: 'qr',
          api_key: authUser.api_key,
          method: 'post',
          body: {
            code: fullCode
          }
        });
      }
      checkCode(details)
    }
  }, [
    details,
    executeAsync,
    authUser
  ])

  //use reterned data to confirm code
  useEffect(() => {
    if (data) {
      console.log(data)
      if (Object.keys(data).length === 0) {
        //check context incase this code has already been scanned for this form
        console.log(addNewEntry.qr_codes);
        if (Object.keys(addNewEntry.qr_codes).length === 0) {
          setConfirmCode(true);
          setLoading(false);
          //allow scan again
          setScanAgain(true);

        } else if (Object.keys(addNewEntry.qr_codes).length > 0) {
          //console.log('already a qr entry in context')
          //check that this is not the same as the other entry
          let found = addNewEntry.qr_codes.find((item) => item.code === details.code)
          if (!found) {
            //setTextQRCode(details.code);
            setConfirmCode(true);
            setLoading(false);
            //allow scan again
            setScanAgain(true);

          } else {
            setQrError({
              result: 'error',
              message: 'QR code already scanned, please use another'
            });
          }
        }
      }  else {
        setQrError({
          result: 'error',
          message: 'QR code already scanned, please use another'
        });
      }
    }
  }, [
    data
  ]);

  // const getDevices = () => {
  //   setLoading(true);
  //   navigator.mediaDevices.enumerateDevices().then((devices) => {
  //     const videoSelect = []
  //     devices.forEach((device) => {
  //       if (device.kind === 'videoinput') {
  //         videoSelect.push(device)
  //       }
  //     })
  //     return videoSelect
  //   })
  //     .then((devices) => {
  //       setDevices(devices);
  //       setCameraId(devices[0].deviceId);
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       console.log(error)
  //     })
  // }

  // useEffect(() => {
  //   if (!devices.length) {
  //     getDevices(details.code);
  //   }
  // }, [devices,details])

  // const setCamera = (e) => {
  //   e.preventDefault()
  //   setLoading(true);
  //   const value = e.target.value
  //   //console.log(value)
  //   setCameraId(value);
  //   setLoading(false);
  // }

  const reset = () => {
    setDetails();
    setQrError();
    setResult();
    setScanQRCode();
    setTextQRCode('');
    setLoading(false);
    setScanAgain(false);
    setConfirmCode(false);
    setScanned(false);
  }

  const handleClose = () => {
    console.log('close & reset');
    setOpen(false);
    reset();
    setScanned(false);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setQrDetails({ ...details, time: time });
    setRelink(true)
    setOpen(false);
    reset();
  }

  const handleOpen = () => {
    //console.log('open & remove last from context');
    if (addNewEntry.qr_codes.length > 0) {
      dispatchAddNewEntry({
        type: REMOVE_QRCODES,
        payload: attrName
      });
    }
    setOpen(true);

  }

  const handleError = () => {

  }

  return (
    <>
      <Grid container item sx={{padding: 'theme.spacing(1)'}} >
        <Grid item >
          <PrimaryBtnIcon
            icon={faQrcode}
            text={!relink? "Link QR code" : "Relink QR code"}
            className={!relink ? buttonStyle.primary : buttonStyle.warning }
            //iconSize="2x"
            onClick={e => handleOpen(e)}
          />
        </Grid>
      </Grid>
      <GlobalDialog
        open={open}
        closeModal={handleClose}
        title={'Scan or type in QR code'}
        handleSubmit={e => handleSubmit(e)}
        submit_disabled={!confirmCode || textQRCode.length < 12 || !scanAgain}
        buttonTitle={!relink? "Link QR code" : "Relink QR code"}
        fullWidth
        maxWidth="md"
        error={error || qrError}
      >
        <Grid container item spacing={2} >
          {/* <Grid item xs={12}>
            {devices.length && (
              <Select
                onChange={e => setCamera(e)}
                value={cameraId}
              >
                {devices.map((deviceInfo, index) => (

                  <MenuItem key={deviceInfo.deviceId} value={deviceInfo.deviceId}>{deviceInfo.label || `camera ${index}`}</MenuItem>

                ))}
              </Select>
            )
          }
          </Grid> */}
          <Grid item xs={12} style={{ position: 'relative' }}>
            {!scanAgain &&
              <QrReader
                //resolution={1440}  
                constraints={{
                  video: {
                    //deviceId: { cameraId },
                    facingMode: "environment"
                  }
                }}
                // //facingMode="environment"
                // //chooseDeviceId={cameraId}
                // delay={delay}
                onError={e => console.log('')}
                onScan={handleScan}
                style={{ width: '100%', maxWidth: '100%', margin: '0 auto' }}
              />

            }
            {loading && !error &&
              <LoadingScreen background="#fff" colour="#333" text="Checking QR code" height="auto" />
            }
          </Grid>
          {/* <Grid item >
            <p>Scan QR code or type in 12 character code</p>
          </Grid> */}
          <Grid item xs={12} md={8} >

            <TextField
             label={setLanguageText(language,"12 character code")}
              fullWidth
              value={textQRCode || ''}
              variant="outlined"
              placeholder={"12 character code"}
              //helperText={'Scan QR code or type in 12 character code'}
              onChange={e => updateCode(e)}
              InputProps={{
                maxLength: 12,
                endAdornment: confirmCode && textQRCode.length === 12 && scanAgain ? (
                  <InputAdornment position="end">
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className={classes.sign_check}
                      size="lg"
                    />
                  </InputAdornment>
                ) :   
                <InputAdornment position="end">
                  <small>({12 - textQRCode.length} left)</small>
                </InputAdornment>
              }}
            />
          </Grid>
          <Grid item container alignContent="stretch" xs={12} md={4}>
            <PrimaryBtnIcon
              icon={faQrcode}
              text={'Scan Again'}
              disabled={!scanAgain}
              //className={!error ? buttonStyle.warning : null}
              //iconSize="2x"
              onClick={e => {
                //reset
                reset();
              }}
            />
          </Grid>
          <Grid item xs={12} >
            <FormControl
              fullWidth
              variant="outlined"
              className={classes.formControl}
            >
              <InputLabel htmlFor="notifi">
                Email notification
              </InputLabel>
              <Select
               label={setLanguageText(language,"Email notification")}
                labelId="notifi"
                value={time}
                onChange={e => setTime(e.target.value)}
              >
                <MenuItem value={72}>72 hours</MenuItem>
                <MenuItem value={48}>48 hours</MenuItem>
                <MenuItem value={24}>24 hours</MenuItem>
                <MenuItem value={0}>No notification</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {error && (
            <Grid item xs={12}>
              <Alert severity="error">{error}</Alert>
            </Grid>
          )}
          {/* {result &&
              <p>{JSON.stringify(result)}</p>
          } */}
          {/* {details &&
              <p>{JSON.stringify(details)}</p>
          } */}
        </Grid>

      </GlobalDialog>
    </>
  );
};

export default DipslideScanner;
