import {
    Grid
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { SelectionsContext } from '../../store/SelectionsContext';
import { UserContext } from '../../store/UserContext';
import { CompanyContext } from '../../store/CompanyContext';
import { TextField } from '@mui/material';
import {
    INIT_ENTRY,
    SAVE_COMPANY,
    SAVE_MACHINE,
} from '../../store/SelectionsReducers';
import { fetchMachines } from '../../helpers/fetchMachines';
import Autocomplete from '@mui/material/Autocomplete';
import { MachineContext } from '../../store/MachineContext';
import removeAllOption from '../../helpers/removeAllOption';
import useAsync from '../../Hooks/useAsync';
import axiosRequest from '../../helpers/axiosRequest';
import parseCompanies from '../../helpers/parseCompanies';
import setLanguageText from '../../helpers/setLanguageText';
import { LayoutContext } from '../../store/LayoutContext';
import { Box } from '@mui/system';

function CompanySelect({ setError, setMachine }) {

    //****************** CONTEXT ******************

    const {
        addNewEntry,
        dispatchAddNewEntry
    } = useContext(SelectionsContext);

    const {
        executeAsync
    } = useAsync({
        axiosRequest: axiosRequest
    });

    const { companies, selectedCompany } = useContext(CompanyContext);
    const { selectedMachine } = useContext(MachineContext);
    const { user, authUser } = useContext(UserContext);
    const { language } = useContext(LayoutContext);
    const [localCompanies, setLocalCompanies] = useState([]);
    const [localMachines, setLocalMachines] = useState([]);

    //****************** USE EFFECT ******************


    // // setMachine
    // useEffect(() => {
    //     if (setMachine) {
    //         const setCompanyMachine = async (setMachine) => {
    //             console.log(setMachine)
    //             // fetch full company details
    //             let result = await executeAsync({
    //                 api_key: authUser.api_key,
    //                 method: 'get',
    //                 endpoint: 'companies',
    //                 params: {
    //                     user_level: user.level,
    //                     id: setMachine.company_id
    //                 }
    //             });
    //             if (result) {
    //                 //save compnay and set machines
    //                 //console.log(result)
    //                 dispatchAddNewEntry({ type: SAVE_COMPANY, payload: parseCompanies(result[0]) });
    //                 fetchMachines(
    //                     authUser.api_key,
    //                     false,
    //                     result[0].company_id
    //                 ).then(data => {
    //                     setLocalMachines(data);
    //                     console.log(data.filter( machine => parseInt(machine.machine_id) === parseInt(setMachine.machine_id)))
    //                     dispatchAddNewEntry({
    //                         type: SAVE_MACHINE,
    //                         payload: data.filter( machine => parseInt(machine.machine_id) === parseInt(setMachine.machine_id))
    //                     });
    //                 })
    //             }
    //         }
    //         //console.log(setMachine)
    //         setCompanyMachine(setMachine)
    //     }
    // }, [setMachine]);

    //set errors
    useEffect(() => {
        if (addNewEntry.machine && Object.keys(addNewEntry.machine).length) {
            if (addNewEntry.machine.coolant_id) {
                setError({
                    attr_id: "machine",
                    type: "none",
                });
            } else {
                setError({
                    attr_id: "machine",
                    type: "error",
                    message: "Machine doesn't have a coolant"
                })
            }
        } else {
            if (Object.keys(localMachines).length) {
                setError({
                    attr_id: "machine",
                    type: "error",
                    message: "Please select a machine"
                })
            } else {
                setError({
                    attr_id: "machine",
                    type: "error",
                    message: "No machines"
                })
            }
        }
    }, [addNewEntry, localMachines, setError]);


    //create a entry based on selected machine
    useEffect(() => {
        //console.log(addNewEntry)
        //update to fix entry form
        if (addNewEntry?.machine?.machine_id && addNewEntry?.company?.company_attributes) {
            //create empty object
            let defaultEntry = addNewEntry.company.company_attributes.map((attr) => {
                return {
                    attribute_id: attr.one.attr_id,
                    attribute_values: {

                    }
                }
            });
            //console.log(defaultEntry)
            //Include only PH, Concentration, Tramp Oil
            //console.log(addNewEntry)
            dispatchAddNewEntry({
                type: INIT_ENTRY,
                payload: {
                    attributes_new: defaultEntry,
                    date_stamp: new Date().toISOString(),
                    user_id: user.id,
                    machine_id: addNewEntry.machine.machine_id,
                    coolant_id: addNewEntry.machine.coolant_id,
                    factored: addNewEntry.machine.machine_settings.factored ? addNewEntry.machine.machine_settings.factored : false,
                    factor: addNewEntry.machine.machine_settings.machine_defaults.factor || addNewEntry.machine.machine_settings.machine_defaults.refractomer_factor
                }
            });
        }
    }, [addNewEntry.company, addNewEntry.machine, dispatchAddNewEntry, user.id]);

    // remove all option from companies
    useEffect(() => {
        if (companies.length && !companies[0].company_id) {
            setLocalCompanies(removeAllOption(companies));
        }
    }, [companies]);

    // if a company has been selected then set default
    //  useEffect(() => {
    //     if (machines) {
    //         setLocalMachines(machines);
    //     }
    // }, [machines]);

    // if a company has been selected then set default
    useEffect(() => {
        if (selectedCompany.company_id) {
            //console.log(selectedCompany)
            dispatchAddNewEntry({
                type: SAVE_COMPANY,
                payload: selectedCompany
            });
            fetchMachines(
                authUser.api_key,
                false,
                selectedCompany.company_id
            ).then(data => {
                setLocalMachines(data);
                dispatchAddNewEntry({
                    type: SAVE_MACHINE,
                    payload: selectedMachine.machine_id !== 0 ? selectedMachine : data[0]
                });
            })
        }
    }, [
        dispatchAddNewEntry,
        selectedCompany,
        selectedMachine,
        authUser
    ]);

    //if a machine has been selected then set default
    // useEffect(() => {
    //     console.log(selectedMachine)
    //     console.log(localMachines)
    //     if (Object.values(selectedMachine).length && firstLoad && localMachines) {
    //         setFirstLoad(false)
    //         console.log('set selected machine')
    //         //console.log(selectedMachine.machine_id)
    //         dispatchAddNewEntry({
    //             type: SAVE_MACHINE,
    //             payload: selectedMachine.company_id !== 0 ? selectedMachine : localMachines[0]
    //         });
    //     } 
    // }, [selectedMachine, localMachines, firstLoad]);

    //if a machine has been selected then set default
    //  useEffect(() => {
    //     console.log(localMachines)
    //    if(localMachines){
    //         dispatchAddNewEntry({
    //             type: SAVE_MACHINE,
    //             payload: localMachines[0]
    //         });
    //     }

    // }, [localMachines]);

    // //on company changes update machine to the first in the list
    // useEffect(()=>{
    //     if(addNewEntry.company){
    //     fetchMachines(
    //         authUser.api_key,
    //         false,
    //         addNewEntry.company.company_id
    //       ).then(data => {
    //         console.log('save machines')
    //         setLocalMachines(data);
    //         dispatchAddNewEntry({
    //             type: SAVE_MACHINE,
    //             payload: machines[0]
    //         });
    //     });
    // }
    // },[addNewEntry.company])


    // When user selects company from dropdown
    const onSelectCompany = async (e, company) => {
        //console.log(company)
        // fetch full company details
        let result = await executeAsync({
            api_key: authUser.api_key,
            method: 'get',
            endpoint: 'companies',
            params: {
                user_level: user.level,
                id: company.company_id
            }
        });

        if (result) {
            //save compnay and set machines
            //console.log(result)
            dispatchAddNewEntry({ type: SAVE_COMPANY, payload: parseCompanies(result[0]) });
            fetchMachines(
                authUser.api_key,
                false,
                result[0].company_id
            ).then(data => {
                setLocalMachines(data);
                dispatchAddNewEntry({
                    type: SAVE_MACHINE,
                    payload: data[0]
                });
            })
        }
    };

    //when a mchine is selected
    const onSelectMachine = (e, machine) => {
        dispatchAddNewEntry({
            type: SAVE_MACHINE,
            payload: machine
        });
    };

    return (
        <Grid item container spacing={2}>
            <Grid item xs={12} md={6}>
                <Autocomplete
                    disableClearable
                    id="companies"
                    options={localCompanies || []}
                    getOptionLabel={option => option.company_name || ''}
                    renderOption={(props, option) => (
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={option.company_id}>
                           {option.company_name}
                        </Box>
                    )}
                    renderInput={params => (
                        <TextField
                            required
                            {...params}
                            label={setLanguageText(language,"Select Company")}
                            variant="outlined"
                        />
                    )}
                    value={addNewEntry.company ? addNewEntry.company : null}
                    onChange={onSelectCompany}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <Autocomplete
                    disableClearable
                    id="machines"
                    options={localMachines || []}
                    getOptionLabel={option => option.machine_name || ''}  
                    renderInput={params => (
                        <TextField
                            required
                            {...params}
                            label={setLanguageText(language,"Select Company")}
                            variant="outlined"
                        />
                    )}
                    value={addNewEntry.machine ? addNewEntry.machine : null}
                    onChange={onSelectMachine}
                />
            </Grid>
        </Grid>
    );
}

export default CompanySelect;
