import {
    Grid,
    TextField
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import React, { useEffect, useContext, useState } from 'react';
import { UserContext } from '../../../store/UserContext';
import Defaults from '../../Products/Coolants/Defaults';
import { fetchCoolants } from '../../../helpers/fetchCoolants';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import GlobalDialog from '../../reusable/GlobalDialog';
import PrimaryBtnIcon from '../../buttons/PrimaryBtnIcon';
import { buttonStyles } from '../../../styles/buttonStyles';
import parseCoolants from '../../../helpers/parseCoolants';
import axiosRequest from '../../../helpers/axiosRequest';
import useAsync from '../../../Hooks/useAsync';
import { MachineContext } from '../../../store/MachineContext';
import { CoolantContext } from '../../../store/CoolantContext';
import { SAVE_SELECTED_COOLANT } from '../../../store/CoolantReducers';
import { UPDATE_MACHINE_SETTINGS } from '../../../store/MachineReducers';
import setLanguageText from '../../../helpers/setLanguageText';
import { LayoutContext } from '../../../store/LayoutContext';

const AddCoolant = ({ selectedCoolant, selectedMachine }) => {
    //****** CONTEXT ******
    const { authUser, user } = useContext(UserContext);
    const { language } = useContext(LayoutContext);
    const { dispatchSelectedMachine } = useContext(MachineContext);
    const { dispatchSelectedCoolant} = useContext(CoolantContext);

    //****** STATE ******
    const [coolantList, setCoolantList] = useState();
    const [coolant, setCoolant] = useState(selectedCoolant);
    const [addCoolant, setAddCoolant] = useState(false);

    const {
        error,
        data,
        loading,
        executeAsync,
        executeLog,
        setData
    } = useAsync({
        axiosRequest: axiosRequest
    });

    //****** STYLES ******
    const buttonStyle = buttonStyles();

    //****** FUNCTIONS ******

    useEffect(() => {
        if(addCoolant){
        // fetch Coolants list
        if (authUser.api_key) {
            fetchCoolants({
                api_key: authUser.api_key
            }).then(coolants => {
                setCoolantList(coolants);
            });
        }
    }
    return () => {
            
    }
    }, [addCoolant,authUser]);


    const setNewCoolant = (e, coolant) => {
        // console.log(e.target.value);
        // console.log(coolant);
        setCoolant(
            parseCoolants(coolant)
        );
    };

    const handleSubmit = async (e) => {

        e.preventDefault()

        let response = await executeAsync({
            endpoint: 'machines',
            api_key: authUser.api_key,
            method: 'patch',
            body: {
                edit: 'coolant',
                machine_id: selectedMachine.machine_id,
                coolant: coolant,
                newCoolant: coolant.coolant_id,
                resetDate: true,
                selectedDate: new Date(),
                resetMachineSettings: true
            }
        });

        dispatchSelectedCoolant({
            type: SAVE_SELECTED_COOLANT,
            payload: coolant
        });
        dispatchSelectedMachine({
            type: UPDATE_MACHINE_SETTINGS,
            payload: {
                coolant_id: coolant.coolant_id,
                machine_defaults: {
                    "factor": coolant.coolant_defaults.defaults.factor,
                    "op_con_max": coolant.coolant_defaults.defaults.con_max,
                    "op_con_min": coolant.coolant_defaults.defaults.con_min,
                    "ph_max": coolant.coolant_defaults.defaults.ph_max,
                    "ph_min": coolant.coolant_defaults.defaults.ph_min
                }
            }
        });
        dispatchSelectedMachine({
            type: UPDATE_MACHINE_SETTINGS,
            payload: {
                last_coolant_change: Date.now()
            }
        });
        if (response) {
            await executeLog({
                endpoint: 'activity',
                api_key: authUser.api_key,
                body: {
                    attributes: {
                        type: 'Coolant Added',
                        user_id: user.id,
                        machine_id: selectedMachine.machine_id,
                        new_settings: {
                            "newCoolant": coolant.coolant_id,
                        }
                    }
                }
            });
            setData();
            setAddCoolant(false);
        }
    }

    const closeReset = () => {
        setAddCoolant(false);
    }

    //****** RENDER ******
    return <>
        <Grid item style={{ margin: 'auto' }}>
            <PrimaryBtnIcon
                icon={faPlus}
                text="Add Coolant"
                onClick={e => setAddCoolant(true)}
                className={buttonStyle.danger}
            />
        </Grid>
      
        <GlobalDialog
            open={addCoolant}
            closeModal={closeReset}
            title="Add Coolant"
            handleSubmit={handleSubmit}
            buttonTitle="Add Coolant"
            successMessage="Coolant added successfully!"
            loading={loading}
            error={error}
            data={data}
            fullWidth
            maxWidth="md"

        > 
        {addCoolant && 
            <Grid container item spacing={2}>
                <Grid item xs={12}>
                    <Autocomplete
                        id="coolant"
                        options={coolantList || []}
                        loading={!coolantList  ? true : false}
                        fullWidth
                        getOptionLabel={(option) => option.coolant_name || ''}
                        isOptionEqualToValue={(option, value) => option.coolant_id === value}
                        renderInput={params => (
                            <TextField
                                required
                                {...params}
                               label={setLanguageText(language,"Select Coolant")}
                                variant="outlined"
                            />
                        )}
                        value={coolant || null}
                        onChange={setNewCoolant}
                    />
                </Grid>
                <Grid item xs={12}>
                    {coolant && <Defaults coolant={coolant} />}
                </Grid>

            </Grid>
            }
        </GlobalDialog>

    </>;
};

export default AddCoolant;
