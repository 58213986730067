// parse machines' attributes and settings
const parseActivityAttributes = activities => {
  if (activities) {
    return activities.map(activity => {
      const getDate = new Date(activity.time_stamp);
      return {
        ...activity,
        attributes: JSON.parse(activity.attributes),
        time_stamp: getDate.toLocaleString('en-GB', { timeZone: 'UTC' })
      };
    });
  }
  return [];
};
export default parseActivityAttributes;
