import {
  Button,
  ButtonGroup,

  Grid,
  TextField,
  Typography
} from '@mui/material';
import React, { useContext } from 'react';
import setLanguageText from '../../helpers/setLanguageText';
import { SelectionsContext } from '../../store/SelectionsContext';
import { ADD_NOTES, ADD_NOTE_ACTION } from '../../store/SelectionsReducers';
import { LayoutContext } from '../../store/LayoutContext';
import { buttonStyles } from '../../styles/buttonStyles';

function Notes({ setError, edit }) {

  //****************** STYLES******************
  const buttonStyle = buttonStyles();

  //****************** CONTEXT ******************
  const { addNewEntry, dispatchAddNewEntry } = useContext(SelectionsContext);
  const { language } = useContext(LayoutContext)

  //****************** FUNCTIONS ******************
  const handleButton = (e) => {
    //console.log(e.target.value)
    e.preventDefault();
    dispatchAddNewEntry({
      type: ADD_NOTE_ACTION,
      payload: e.target.value
    });
  };

  const handleNote = (e) => {
    //console.log(e.target.value)
    e.preventDefault();
    dispatchAddNewEntry({
      type: ADD_NOTES,
      payload: e.target.value
    });
  };

  return <>
    <Grid container item spacing={2}>
      <Grid item xs={12}>
        <TextField
          multiline
         label={setLanguageText(language,"Note body")}
          rows={10}
          variant="outlined"
          fullWidth
          onChange={e => handleNote(e)}
          value={addNewEntry.entry.notes || ''}
        />
      </Grid>
      <Grid item container alignItems="center" spacing={2}>
        <Grid item>
          <Typography variant="h6">{setLanguageText(language,"Need Action")}?</Typography>
        </Grid>
        <Grid item>
          <ButtonGroup>
            <Button
              value="N"
              onClick={e => handleButton(e)}
              className={`${buttonStyle.fixPointerEvent} ${addNewEntry.entry.note_action === 'N' && buttonStyle.success
                }`}>
              {setLanguageText(language,"No")}
                </Button>
            <Button
              value="Y"
              onClick={e => handleButton(e)}
              className={`${buttonStyle.fixPointerEvent} ${addNewEntry.entry.note_action === 'Y' && buttonStyle.warning
                }`}
            >
              {setLanguageText(language,"Yes")}
                </Button>
            <Button
              value="C"
              onClick={e => handleButton(e)}
              className={`${buttonStyle.fixPointerEvent} ${addNewEntry.entry.note_action === 'C' && buttonStyle.danger
                }`}
            >
              {setLanguageText(language,"Critical")}!
                </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
    </Grid>

  </>;
}

export default Notes;
