import React, { useContext, useState, useEffect } from 'react';
import { CompanyContext } from '../../store/CompanyContext';
import { LayoutContext } from '../../store/LayoutContext';
import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import removeAllOption from '../../helpers/removeAllOption';
import SelectionRow from '../Dashboard/Selection_Row/SelectionRow';
import { SELECTED_COMPANY } from '../../store/CompanyReducers';
import setLanguageText from '../../helpers/setLanguageText';

function DropdownCompaniesNew({removeAll}) {

    const { companies, selectedCompany, dispatchSelectedCompany } = useContext(CompanyContext);
    const { language } = useContext(LayoutContext);

    const [localCompanies, setLocalCompanies] = useState();

    useEffect(()=>{
        if(removeAll){
            setLocalCompanies(removeAllOption(companies));
        } else {
            setLocalCompanies(companies);
        }
    },[removeAll,companies])

    return (
        <SelectionRow >
            <Autocomplete
                disableClearable
                id="company"
                value={selectedCompany || ''}
                options={localCompanies || []}
                loading={localCompanies ? false : true}
                getOptionLabel={option => option.company_name || ''}
                isOptionEqualToValue={(option, value) =>
                    option.company_id === value.company_id
                }
                onChange={(e, newValue) => {
                    console.log(newValue)
                    dispatchSelectedCompany({
                        type: SELECTED_COMPANY,
                        payload: newValue
                    });
                }}
                renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.company_id}>
                        {option.company_name}
                      </li>
                    );
                }}
                renderInput={(params) => 
                    <TextField
                        {...params}
                        variant="outlined"
                        label={setLanguageText(language,"Select Company")}
                        required
                    />
                }
            />

        </SelectionRow>
    );
}

export default DropdownCompaniesNew;
