import React, { useContext } from 'react';
import { Fade, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { faTint } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import config from '../../config';
import { LayoutContext } from '../../store/LayoutContext';
import setLanguageText from '../../helpers/setLanguageText';

const useStyles = makeStyles(() => ({
  loading: {
    height: '100%',
    width: '100%',
    textAlign: 'center',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'grid',
    alignItems: 'center',
    fontSize: '4em',
    zIndex: 100,
    opacity: 0.9
  }
}));

const LoadingScreen = ({
  loading,
  background,
  colour,
  text,
  position,
  height,
  customAction
}) => {
  const classes = useStyles();
  const { language } = useContext(LayoutContext)
  
  return (
    <Fade in={!loading} unmountOnExit={true}>
      <div
        className={classes.loading}
        style={{
          backgroundColor: background ? background : '#008CBA',
          color: colour ? colour : '#fff',
          position: position ? position : 'absolute',
          minHeight: height ? height : '600px'
        }}
      >
        <Grid>
          <FontAwesomeIcon icon={faTint} />
          <Typography>{text ? setLanguageText(language,text) : `${setLanguageText(language,"Loading")} ${config.NAME}`}</Typography>
          {customAction && customAction} 
        </Grid>
      </div>
    </Fade>
  );
};

export default LoadingScreen;
