import React, { Fragment, useEffect, useContext, useState } from 'react';
import _ from 'lodash';
import {
  Button,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  StepLabel,
  Step,
  Stepper
} from '@mui/material';
import { FormOne, FormTwo, FormThree, FormFour } from './CompanyAttributeForms';
import { useForm, FormProvider } from 'react-hook-form';
import { CompanyContext } from '../../store/CompanyContext';
import { UserContext } from '../../store/UserContext';
import { createCompanyAttributes } from '../../helpers/createCompanyAttributes';
import { LayoutContext } from '../../store/LayoutContext';
import setLanguageText from '../../helpers/setLanguageText';

function getSteps() {
  return ['Attribute info', 'Styling rules', 'Form output', 'Chart options'];
}

function getStepContent(
  activeStep,
  compiledForm,
  currentVariables,
  isEdit
) {
  switch (activeStep) {
    case 0:
      //return <FormOne {...{ compiledForm, isEdit }} />;
      return <FormOne {...{ isEdit }} />;
    case 1:
      //return <FormTwo {...{ compiledForm, currentVariables }} />;
      return <FormTwo {...{ currentVariables }} />;
    case 2:
      return <FormThree />;
      //return <FormThree {...{ compiledForm }} />;
    case 3:
      return <FormFour />;
      //return <FormFour {...{ compiledForm }} />;
    default:
      return 'Unknown step';
  }
}

export const FormStepper = ({
  currentAttributes,
  currentVariables,
  selectedAttribute,
  closeModalRefresh
}) => {
  const methods = useForm({
    mode: 'all'
  });
  //const { watch, errors, reset } = methods;
  const { selectedCompany } = useContext(CompanyContext);
  const { language } = useContext(LayoutContext);
  const { authUser } = useContext(UserContext);
  const [activeStep, setActiveStep] = useState(0);
  const [compiledForm, setCompiledForm] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const steps = getSteps();
  const form = methods.watch();

  //console.log(form)

  useEffect(() => {
    if(selectedAttribute && Object.values(selectedAttribute).length){
      console.log(selectedAttribute)
      // setCompiledForm(selectedAttribute);
      setIsEdit(true);
      methods.reset({ ...selectedAttribute });
    }
  }, [selectedAttribute]);

  useEffect(() => {
    //console.log(selectedCompany);
    methods.setValue('one.company_id', selectedCompany.company_id);
  }, [methods.setValue, selectedCompany]);

  //   useEffect(() => {
  //   if(selectedAttribute){

  //     reset({...selectedAttribute})
  //   }
  // }, [reset,selectedAttribute]);

  // useEffect(() => {
  //     console.log("FORM CONTEXT", watch(), errors);
  // }, [watch, errors]);

  const handleNext = () => {
    let canContinue = true;

    console.log(compiledForm)
    console.log(form)

    if (_.isEmpty(methods.formState.errors)) {
      switch (activeStep) {
        case 0:
          //setCompiledForm({ ...compiledForm});
          //setCompiledForm({ ...compiledForm, one: form });
          canContinue = true;
          break;
        case 1:
          //setCompiledForm({ ...compiledForm});
          //setCompiledForm({ ...compiledForm, two: form });
          canContinue = true;
          break;
        case 2:
          //setCompiledForm({ ...compiledForm});
          //setCompiledForm({ ...compiledForm, three: form });
          canContinue = true;
          break;
        case 3:
          //setCompiledForm({ ...compiledForm});
          //setCompiledForm({ ...compiledForm, four: form });
          canContinue = handleSubmit({ ...form });
          break;
        default:
          return 'not a valid step';
      }
      if (canContinue) {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
      }
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep(prevActiveStep => prevActiveStep - 1);
      switch (activeStep) {
        case 1:
          //setCompiledForm({ ...compiledForm});
          break;
        case 2:
          //setCompiledForm({ ...compiledForm });
          break;
        case 3:
            //setCompiledForm({ ...compiledForm });
            break;
        default:
          return 'not a valid step';
      }
    }
  };

  const handleReset = () => {
    setActiveStep(0);
    //setCompiledForm({});
  };

  const handleSubmit = data => {
    if (_.isEmpty(methods.formState.errors)) {
      //console.log(errors);
      //console.log(data);
      //console.log(currentAttributes);
      //console.log(selectedAttribute);

      let updatedAttributes;
      if (currentAttributes) {
        let current = [...currentAttributes];
        if (selectedAttribute) {
          //update selected attribute
          const index = current.findIndex(
            e => e.one.attr_id === selectedAttribute.one.attr_id
          );
          console.log(index);
          if (index > -1) {
            current[index] = data;
          }
        } else {
          //add new attribute
          current.push(data);
        }
        updatedAttributes = current;
      } else {
        //add first attribute
        updatedAttributes = [data];
      }
      //console.log(updatedAttributes)
      let body = {
        id: selectedCompany.company_id,
        attributes: updatedAttributes
      };
      createCompanyAttributes(authUser.api_key, 'post', body).then(result => {
        //window.sessionStorage.removeItem('selectedCompany');
        closeModalRefresh();
        //window.location.reload();
      });
    }
  };

  return (
    <FormProvider {...methods}>
      <DialogTitle id="customized-dialog-title" onClose={() => closeModalRefresh()}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </DialogTitle>
      <DialogContent dividers>
        {activeStep === steps.length ? (
          <div>
            <Fragment>
              <Typography>{setLanguageText(language,'Completed')}</Typography>
              <Button onClick={handleReset}>{setLanguageText(language,'Close')}</Button>
            </Fragment>
          </div>
        ) : (
          <div>
            {getStepContent(
              activeStep,
              compiledForm,
              currentVariables,
              isEdit
            )}
          </div>
        )}
      </DialogContent>
      <DialogActions>
        {activeStep > 0 && <Button onClick={handleBack}>{setLanguageText(language,'Back')}</Button>}
        <Button
          variant="contained"
          color="primary"
          onClick={handleNext}
          disabled={!_.isEmpty(methods.formState.errors)}
        >
          {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
        </Button>
      </DialogActions>
    </FormProvider>
  );
};
