import React, { useContext, useState, useEffect} from 'react';
import {
    faTh,
    faList,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Button,
    ButtonGroup,
} from '@mui/material';
import { ProductsContext } from '../../store/ProductsContext';
import { UPDATE_PRODUCT_OPTIONS } from '../../store/ProductsReducers';

const LayoutSelect = () => {

    const {
        productsOptions,
        dispatchProductsOptions,
    } = useContext(ProductsContext);

    const handleView = view => {
        dispatchProductsOptions({
            type: UPDATE_PRODUCT_OPTIONS,
            payload: {toggleView : view }
        });
    };

    return (
        <ButtonGroup fullWidth>
            <Button
                variant="contained"
                color={
                    productsOptions.toggleView === 'grid' ? 'primary' : 'inherit'
                }
                onClick={() => handleView('grid')}
            >
                <FontAwesomeIcon icon={faTh} size="lg" />
            </Button>
            <Button
                variant="contained"
                color={
                    productsOptions.toggleView === 'list' ? 'primary' : 'inherit'
                }
                size="small"
                onClick={() => handleView('list')}
            >
                <FontAwesomeIcon icon={faList} size="lg" />
            </Button>
        </ButtonGroup>
    );
};

export default LayoutSelect;
