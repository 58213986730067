import React from 'react';
import {
  Grid,
  Divider,
  Typography,
  LinearProgress
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    linearBarContainer: {
      position: 'relative'
    },
    linearBar: {
      height: '100%',
      borderRadius: theme.spacing(0.5),
      '& .MuiLinearProgress-barColorPrimary': {
        backgroundColor: theme.palette.success.main
      },
      '&.MuiLinearProgress-determinate': {
        backgroundColor: theme.palette.background.light
      }
    },
    percentage: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translateY(-50%)'
    }
  }));

const AdditiveList = ({
  additive
}) => {

  //****** STYLES ******
  const linearBarStyle = useStyles();

  const percentage =
  additive.machines_count && additive.total_machines
    ? (additive.machines_count / additive.total_machines) * 100
    : 0;

  //****** RENDER ******
  return (
        <Grid
            item
            container
        >
            <Divider light style={{width :'100%', marginBottom : '1em'}} />
        <Grid item xs={4} >
            <Typography >{additive.additive_name}</Typography>
        </Grid>
        <Grid item xs={8} container spacing={2} alignItems="stretch">

          <Grid item xs={10}  className={linearBarStyle.linearBarContainer}>
            <LinearProgress
              value={percentage}
              variant="determinate"
              className={linearBarStyle.linearBar}
            />
            <Typography variant="h6" className={linearBarStyle.percentage}>
              {`${percentage.toFixed(2)}%`}
            </Typography>
          </Grid>
          <Grid item xs={2} >
              <Typography variant="h6" align="center">
                {additive.machines_count && additive.total_machines
                  ? `${additive.machines_count}/${additive.total_machines}`
                  : `0/0`}
              </Typography>
    
          </Grid>
        </Grid>
      
        </Grid>

  );
};

export default AdditiveList;
