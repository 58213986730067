// parse machines' attributes and settings
const parseEntries = entries => {
  if (entries.length) {
    return entries.map(entry => {
      //console.log(entry)
      if (typeof entry.attributes_new === 'string') {
        return {
          ...entry,
          additives:
            entry.additives && typeof entry.additives === 'string'
              ? JSON.parse(entry.additives)
              : [],
          attributes_new:
            entry.attributes_new && JSON.parse(entry.attributes_new),
          void: entry.void && JSON.parse(entry.void)
        };
      }
      return entry;
    });
  }
};

export default parseEntries;
