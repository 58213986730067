import {
  Grid,
  Paper,
  // Table,
  // TableBody,
  // TableCell,
  // TableContainer,
  // TableRow,
  Typography
} from '@mui/material';
import React, { useContext } from 'react';
import setLanguageText from '../../../helpers/setLanguageText';
import { LayoutContext } from '../../../store/LayoutContext';
import { buttonStyles } from '../../../styles/buttonStyles';

const Defaults = ({ coolant }) => {
  
  const { language } = useContext(LayoutContext);
  const defaults = coolant.coolant_defaults.defaults;
  const buttonStyle = buttonStyles();

  return (
    <Grid container>
      <Grid item container  spacing={1} >
      <Grid item xs={12}>
      <Paper className={`${buttonStyle.box}`} variant="outlined">
            <Typography variant="h6" align="center">
            {setLanguageText(language,"Coolant Defaults")}
            </Typography>
        </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper className={`${buttonStyle.box}`} variant="outlined">
            <Typography variant="body1" align="center">
              <strong>{setLanguageText(language,"Factor")}: </strong>
              {defaults.factor}
              {/* {`Factor: ${defaults.factor}`} */}
            </Typography>
          </Paper>
        </Grid>
          <Grid item xs={4} >
            <Paper className={`${buttonStyle.box}`} variant="outlined">
              <Typography variant="body1" align="center">
                <strong>{setLanguageText(language,"PH Max")}: </strong>
                {defaults.ph_max}
                {/* {`PH Max: ${defaults.ph_max}`} */}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper className={`${buttonStyle.box}`} variant="outlined">
              <Typography variant="body1" align="center">
                <strong>{setLanguageText(language,"PH Min")}: </strong>
                {defaults.ph_min}
                {/* {`Conc Min: ${defaults.con_max}`} */}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper className={`${buttonStyle.box}`} variant="outlined">
              <Typography variant="body1" align="center">
                <strong>{setLanguageText(language,"Conc Min")}: </strong>
                {defaults.con_min}
                {/* {`PH Min: ${defaults.ph_min}`} */}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper className={`${buttonStyle.box}`} variant="outlined">
              <Typography variant="body1" align="center">
                <strong>{setLanguageText(language,"Conc Max")}: </strong>
                {defaults.con_max}
                {/* {`Conc Min: ${defaults.con_min}`} */}
              </Typography>
            </Paper>
          </Grid>
      </Grid>
    </Grid>
  );
};

export default Defaults;
