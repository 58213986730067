import formatDate from "./formatDate";
import parseCompanies from "./parseCompanies";

const createServicePDFData = ({
    selectedCompany,
    rowData,
    otherData,
    fromTable,
    headers
}) => {

//console.log(headers)
//console.log(rowData)
//console.log(otherData)

//if the data come from the entries table (create service report)
if(fromTable){
    rowData = rowData.map((item) => item.original)
} else {
    selectedCompany = parseCompanies(selectedCompany)
}

let localHeaders;

if(!headers){
  let createHeaders = [
    { 'id': 'entry_date', 'name': 'Date' ,'isVisible': true},
    { 'id': 'machine_name', 'name': 'Machine' ,'isVisible': true},
    { 'id': 'machine_group', 'name': 'Group','isVisible': true }
  ]
  selectedCompany.company_attributes.forEach((item) => {
    createHeaders.push({ 'id': item.one.attr_id, 'name': item.one.attr_name ,'isVisible': true})
  });
  createHeaders.push(
    { 'id': 'additives', 'name': 'Additives' ,'isVisible': true},
    { 'id': 'notes', 'name': 'Notes' ,'isVisible': true }
  )
  localHeaders = createHeaders

} else {
  //if headers are sent through from the column selection options
  localHeaders = headers
}

//rows are alway built from the original data
let localRows = rowData.map((item) => {
  let row = [
    { 'id': 'entry_id', 'value': item.entry_id },
    { 'id': 'entry_date', 'value': item.date_stamp },
    { 'id': 'machine_name', 'value': item.machine_name },
    { 'id': 'machine_group', 'value': item.machine_group },
    { 'id': 'additives', 'value': JSON.parse(item.additives) },
    { 'id': 'notes', 'value': item.notes ,'action': item.note_action}
  ]
  JSON.parse(item.attributes_new).forEach((attr) => {
    if (attr.attribute_id === 'conc') {
      row.push({ 'id': attr.attribute_id, 'value': attr.attribute_values.output, 'factor': item.factor })
    } else {
      row.push({ 'id': attr.attribute_id, 'value': attr.attribute_values.output })
    }
  })
  return row
})

//console.log(localRows)

//create final reduce row, using the headers
let finalRows = []
  localRows.forEach((row, i) => {
    //console.log(row)
    let singleRow = []
    localHeaders.forEach((header, i) => {
      const output = row.find((rowItem) => rowItem.id === header.id);
      let value = '-'
      if (output) {
        value = output.value
        //data format
        if (output.id === 'entry_date') {
          value = formatDate(output.value)
        }
        //additive is an object
        if (output.id === 'additives') {
          if (output.value && Object.values(output.value).length) {
            value = output.value.map((additive) => {
              if (additive.additive_name) {
                return `${additive.additive_name} - ${additive.additive_type} (${additive.additive_details})`
              } else {
                return `${additive.additive_type} (${additive.additive_details})`
              }
            })
          } else {
            value = '-'
          }
        }
        if (output?.id === 'conc' && output?.factor > 1) {
          value = `${(Number(output.value) * Number(output.factor ? output.factor : 1)).toFixed(1)} (${Number(output.value).toFixed(1)})`
        }
        if (output?.id === 'notes') {
          value = {type: 'notes' , value :output.value, action: output.action }
        }
      }
      singleRow.push(value);
    })
    finalRows.push(singleRow)
  })

  return {
    'company': {
      'name': selectedCompany.company_name,
      'logo_path': selectedCompany.company_logo_path
    },
    'columns': localHeaders,
    'rows': finalRows,
    ...otherData
  }


};

export default createServicePDFData;