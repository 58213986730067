import React, { useContext } from 'react';
import {
    Typography,
    FormControlLabel,
    Switch,
    List,
    ListItem,
    Grid,
} from '@mui/material';
import setLanguageText from '../../helpers/setLanguageText';
import { LayoutContext } from '../../store/LayoutContext';


const ColumnOptions = ({ options, handleChange, title }) => {

    const { language } = useContext(LayoutContext);

    /*------------- UPDATE ORIENTATION OF PDF ---------------*/

    return (
        <Grid item xs={12} >
        <Typography variant="h6" >
            {title ? title : setLanguageText(language,"Column Options")}
        </Typography>
            <List>
                {options.map(setting => (
                    <ListItem style={{ borderTop: '1px solid #ececec' }} key={setting.id}>
                        <FormControlLabel
                            control={
                                <Switch
                                    onChange={handleChange}
                                    checked={setting.isVisible}
                                    name={setting.id}
                                    //disabled={value === 'machine_name'}
                                    color="primary"
                                />
                            }
                            name={setting.name}
                            label={setLanguageText(language,setting.name)}
                        />
                    </ListItem>
                ))}
            </List>

        </Grid>
    );
};

export default ColumnOptions;


