import { Grid, LinearProgress, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext } from 'react';
import createPercentage from '../../../helpers/createPercentage';
import setLanguageText from '../../../helpers/setLanguageText';
import { LayoutContext } from '../../../store/LayoutContext';

const useStyles = makeStyles(theme => ({
  linearBarContainer: {
    position: 'relative',
    height: '100%'
  },
  linearBar: {
    height: theme.spacing(8),
    borderRadius: theme.spacing(0.5),
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: theme.palette.success.main
    },
    '&.MuiLinearProgress-determinate': {
      backgroundColor: theme.palette.error.main
    }
  },
  percentage: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: theme.palette.background.light
  }
}));

const ProgressChart = ({ checked, totalMachines }) => {
  const { language } = useContext(LayoutContext)
  const linearBarStyle = useStyles();
  const percentage = checked ? (checked / totalMachines) * 100 : 0;

  return (
    <Grid item className={linearBarStyle.linearBarContainer}>
      <LinearProgress
        style={{height: '100%'}}
        variant="determinate"
        className={linearBarStyle.linearBar}
        value={percentage}
      />
      <Grid
        item
        container
        direction="column"
        className={linearBarStyle.percentage}
      >
        <Typography variant="h2" align="center">
          <strong>{`${percentage ? createPercentage(percentage) : '0%'}`}</strong>
        </Typography>
        <Typography variant="caption" align="center">
          <strong>{setLanguageText(language,"Checked")}</strong>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ProgressChart;
