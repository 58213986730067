import React, {
    useState,
    useEffect,
    useContext,
} from 'react';
import {
    faFilePdf,
    faFileExport
} from '@fortawesome/pro-light-svg-icons';
import {
    useTheme,
    Grid,
} from '@mui/material';
import { buttonStyles } from '../../styles/buttonStyles';
import GlobalPdfDialog from '../reusable/GlobalPdfDialog';
import LoadingScreen from '../reusable/LoadingScreen';
import LoadingButton from '../buttons/LoadingButton';
import ChartsPDF from '../pdf/ChartsPDF';
import { PDFViewer } from '@react-pdf/renderer';
import setLanguageText from '../../helpers/setLanguageText';
import { LayoutContext } from '../../store/LayoutContext';

const ExportCharts = ({ chartData, selectedCompany, selectedGroup }) => {

    const theme = useTheme();
    const { language } = useContext(LayoutContext)
    const [anchorEl, setAnchorEl] = useState(null);
    //const [selectedOptions, setSelectedOptions] = useState()
    const [localOrientation, setLocalOrientation] = useState('landscape');
    const [orientation, setOrientation] = useState();
    const buttonStyle = buttonStyles();
    const [showPreview, setShowPreview] = useState(false);
    //const [localOptions, setLocalOptions] = useState([]);
    const [reload, setReload] = useState(false);

    /*------------- OPEN POPUP ---------------*/

    const handleClick = e => {
        setAnchorEl(e.currentTarget);
        setShowPreview(true);
    };
    const handleClose = () => {
        setShowPreview(false);
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    /*------------- OPTIONS ---------------*/

    // const handleChange = event => {
    //     //console.log(event.target.name)
    //     event.persist()
    //     setShowPreview(false);
    //     setReload(true);
    //     //console.log(event.target.name)
    //     setLocalOptions(old => {
    //         let newOptions = [...old];
    //         let index = old.findIndex(obj => obj.id === event.target.name);
    //         //console.log(index)
    //         newOptions[index].isVisible = !newOptions[index].isVisible;
    //         return newOptions;
    //     });
    // };

    /*------------- UPDATE ORIENTATION OF PDF ---------------*/

    // const updateOrientation = (event, newOrientation) => {
    //     event.persist();
    //     setShowPreview(false);
    //     setReload(true);
    //     setOrientation(newOrientation);
    // };

    /*------------- DATA ---------------*/

    //set orintation
    useEffect(() => {
        if (localOrientation) {
            setOrientation(localOrientation);
        }
        return () => {
            setOrientation();
        }
    }, [localOrientation]);

    //set orintation
    useEffect(() => {
        if (chartData) {
            setShowPreview(true);
        }
    }, [chartData]);

    //Show Reload Button
    const reloadPDF = () => {
        setReload(false)
        //setShowPreview(true);
        //setSelectedOptions(localOptions);
        setTimeout(() => {
            setShowPreview(true)
        }, 100)
    };

    //console.log(orientation)
    //console.log(chartData)
    //console.log(showPreview)

    return (
        <>
            <LoadingButton
                aria-describedby={id}
                variant="contained"
                //loading={(selectedData) ? false : true}
                disabled={chartData ? false : true}
                onClick={e => handleClick(e)}
                className={buttonStyle.button}
                icon={faFileExport}
                text="Export Charts"
                fullWidth={false}
                iconSize="1x"
                color="primary"
            />
            <GlobalPdfDialog
                open={open}
                title="Export Charts"
                closeModal={handleClose}
                maxWidth="xl"
                fullWidth
                onEntered={e => setShowPreview(true)}
            >
                <Grid container style={{ height: "100%", overflow: "auto" }} >
                    {/* <Grid container item xs={4} md={3} style={{ height: "100%", overflow: "auto", borderRight: '1px solid #ccc', background: '#fbfbfb' }} >
                        <CardContent >
                            <Grid container item spacing={2} >
                                <Grid item xs={12} className={classes.orientation} >
                                    <Typography className={classes.heading}>
                                        Orientation
                                     </Typography>
                                    <ToggleButtonGroup
                                        value={orientation}
                                        exclusive
                                        onChange={updateOrientation}
                                        aria-label="text alignment"
                                        style={{ width: '100%' }}
                                    >
                                        <ToggleButton
                                            value="portrait"
                                            aria-label="centered"
                                            style={{ width: '100%' }}
                                        >
                                            <FontAwesomeIcon
                                                icon={faFile}
                                                style={{
                                                    fontSize: theme.spacing(3.5)
                                                }}
                                            />
                                        </ToggleButton>
                                        <ToggleButton
                                            value="landscape"
                                            aria-label="centered"
                                            style={{ width: '100%' }}
                                        >
                                            <FontAwesomeIcon
                                                icon={faFile}
                                                rotation={90}
                                                style={{
                                                    fontSize: theme.spacing(3.5)
                                                }}
                                            />
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </Grid>
                                <Grid item xs={12}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMore />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography className={classes.heading}>
                                                Chart Options
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <List>
                                            </List>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Grid> */}

                    {showPreview && chartData && orientation ?
                        <PDFViewer width="100%" height="100%">
                            <ChartsPDF
                                selectedCompany={selectedCompany}
                                selectedGroup={selectedGroup}
                                chartData={chartData}
                                orientation={orientation}
                                theme={theme.palette}
                                language={language}
                            />
                        </PDFViewer>
                        : reload ?
                            <Grid item style={{ height: "100%" }} container alignContent="center" justifyContent="center">
                                <Grid item>
                                    <LoadingButton
                                        variant="contained"
                                        onClick={e => reloadPDF(e)}
                                        className={buttonStyle.button}
                                        icon={faFilePdf}
                                        text={'Update Preview'}
                                        fullWidth={false}
                                        iconSize="1x"
                                        color="primary"
                                    />
                                </Grid>
                            </Grid>
                            :
                            <LoadingScreen
                                loading={false}
                                background="#666"
                                colour="#fff"
                                text="Loading PDF Preview"
                                position="relative"
                            />
                    }

                </Grid>
            </GlobalPdfDialog>
        </>
    );
};

export default ExportCharts;
