import React, { useContext, useEffect } from 'react';
import {
  faTint,
  faRaindrops
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  ButtonGroup,
  Grid,
  Typography
} from '@mui/material';
import DropdownCompanies from '../../Dashboard/Selection_Row/DropdownCompanies';
import SelectionRow from '../../Dashboard/Selection_Row/SelectionRow';
import PageHeader from '../../PageHeader';
import { buttonStyles } from '../../../styles/buttonStyles';
import { UserContext } from '../../../store/UserContext';
import { CompanyContext } from '../../../store/CompanyContext';
import { ProductsContext } from '../../../store/ProductsContext';
import axiosRequest from '../../../helpers/axiosRequest';
import useAsync from '../../../Hooks/useAsync';
import {
  SAVE_PRODUCTS
} from '../../../store/ProductsReducers';
import parseCoolants from '../../../helpers/parseCoolants';

//********** COMPONENT ************
const ProductsHeader = ({ productType }) => {

  const buttonStyle = buttonStyles();

  //****** CONTEXT ******

  const { user, authUser } = useContext(UserContext);
  const { selectedCompany } = useContext(CompanyContext);
  const {
    productsOptions,
    dispatchProducts
  } = useContext(ProductsContext);

  //****** FUNCTIONS ******

  const {
    error,
    loading,
    executeAsync
  } = useAsync({
    axiosRequest: axiosRequest
  });

  const getProducts = async (productType, company_id, level) => {
    //console.log('fetch users')
    const products = await executeAsync({
      method: 'get',
      api_key: authUser.api_key,
      endpoint: `coolants`,
      params: {
        user_level: level,
        company_ids: company_id,
        type: productType
      }
    });
    if (products.length) {
      dispatchProducts({
        type: SAVE_PRODUCTS,
        payload: parseCoolants(products)
      });
    } else {
      dispatchProducts({
        type: SAVE_PRODUCTS,
        payload: null
      });
    }
  };

  useEffect(() => {
    if (selectedCompany.company_id && productType) {
      // Fetch Coolants based on Company ID and type
      getProducts(productType, selectedCompany.company_id, user.level)

    }
  }, [
    user,
    productType,
    selectedCompany
  ]);

  return (
    <>
      <PageHeader>
        <Grid
          container
          alignItems="stretch"
          justifyContent="space-between"
          spacing={2}
        >
          <Grid item xs={12} md={4}>
            <SelectionRow >
              <DropdownCompanies />
            </SelectionRow>
          </Grid>
        </Grid>
      </PageHeader>

    </>
  );
};

export default ProductsHeader;
