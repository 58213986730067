import React, { useState, useEffect } from 'react';
import config from '../../config';

const Image = ({ coolant, additive, path }) => {

  const [imageUrl, setImageUrl] = useState();
  const [imageFallback, setFallbackImage] = useState();
  //const [showFallback, setShowFallback] = useState(false);

  useEffect(() => {
    if (additive) {
      setFallbackImage(`https://${config.s3.BUCKET}.s3.${config.s3.REGION}.amazonaws.com/public/brands/additive.png`);
      if (additive.brand_id) {
        setImageUrl(`https://${config.s3.BUCKET}.s3.${config.s3.REGION}.amazonaws.com/public/brands/${additive.brand_id}_additive.png`)
      } else {
        setImageUrl(`https://${config.s3.BUCKET}.s3.${config.s3.REGION}.amazonaws.com/public/brands/additive.png`)
      }
    } else if (coolant) {
      setFallbackImage(`https://${config.s3.BUCKET}.s3.${config.s3.REGION}.amazonaws.com/public/brands/coolant.png`);
      if (coolant.brand_id) {
        //console.log(coolant.brand_id)
        setImageUrl(`https://${config.s3.BUCKET}.s3.${config.s3.REGION}.amazonaws.com/public/brands/${coolant.brand_id}_coolant.png`)
      } else {
        setImageUrl(`https://${config.s3.BUCKET}.s3.${config.s3.REGION}.amazonaws.com/public/brands/coolant.png`)
      }
    } else {
      setImageUrl(path);
    }
    return () => {
    }
  }, [additive, coolant, path])

  function showFallbackImage(e){
    //console.log('error')
    setImageUrl(imageFallback)
    //setShowFallback(true)
  }
  
  return (
    <>
      { imageUrl ?
        <img
          src={imageUrl}
          alt=""
          onError={showFallbackImage}
        />
        : null}
      {/* {imageFallback && showFallback ?
        <img
          src={imageFallback}
          alt=""
        />
        : null} */}
    </>
  );
};

export default Image;
