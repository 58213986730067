import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import formatDate from '../../helpers/formatDate';

const create_pdf_rows = ({
  rows,
  styles,
  group_value,
  exclude_columns,
  expand_columns,
  reduce_columns
}) => {
  const rows_values = rows.map(row => {
    return Object.entries(row).map(cell => ({
      name: cell[0],
      value: cell[1]
    }));
  });

  let group_headers = {};

  const pdf_rows = rows_values.map((row, rowI) => {
    row.forEach(({ value, name }) => {
      if (name === group_value) {
        if (value !== group_headers.name) {
          group_headers = {
            name: value,
            count: 1
          };
        } else group_headers.count++;
      }
    });

    return (
      <React.Fragment key={`${group_headers.name}-row-${group_headers.count}`}>
        {/* Row with selected Grouped */}
        {group_headers.count === 1 && (
          <View style={styles.TABLE.GROUP_ROW} wrap={false}>
            {row.map((value, i) => {
              return !i ? (
                <View
                  key={`table-row-${value.value}`}
                  style={{ ...styles.TABLE.GROUP_ROW.COL }}
                >
                  <Text style={styles.TABLE.GROUP_ROW.CELL}>
                    {group_headers.name || '---'}
                  </Text>
                </View>
              ) : (
                <View key={`${value.name}-${value.value}`}></View>
              );
            })}
          </View>
        )}
        {/* PDF rows */}
        <View style={styles.TABLE.ROW} wrap={false}>
          {row?.map(({ value, name } , i) => {
            const is_date =
              name === 'last_coolant_change' || name === 'date_stamp';
            const value_or_date = is_date ? formatDate(value) : value;
            const to_expand = expand_columns?.includes(name)
              ? 2
              : reduce_columns?.includes(name)
              ? 0.5
              : 1;
            return (
              !exclude_columns?.includes(name) && (
                <View
                  key={`table-cell-${i}`}
                  style={{ ...styles.TABLE.COL, flex: to_expand }}
                >
                  <Text style={styles.TABLE.CELL}>{value_or_date}</Text>
                </View>
              )
            );
          })}
        </View>
      </React.Fragment>
    );
  });
  return pdf_rows;
};
export default create_pdf_rows;
