import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import axiosRequest from '../../helpers/axiosRequest';
import { UserContext } from '../../store/UserContext';
import { MachineContext } from '../../store/MachineContext';
import Alert from '@mui/material/Alert';
import useAsync from '../../Hooks/useAsync';
import {
  VOID_ENTRY
} from '../../store/MachineReducers';
import GlobalDialog from '../reusable/GlobalDialog';
import { LayoutContext } from '../../store/LayoutContext';
import setLanguageText from '../../helpers/setLanguageText';

const VoidEntry = ({
  open,
  handleClose,
  handleVoidedClose,
  copyEntry
}) => {

  //************************ STYLES ************************

  //************************  CONTEXT ************************
  const { authUser, user } = useContext(UserContext);
  const { dispatchSelectedMachine } = useContext(MachineContext);
  const { language } = useContext(LayoutContext)

  //************************ STATE ************************
  const [voidReason, setVoidReason] = useState('');
  const [selectedReason, setSelectedReason] = useState(0);
  const [presetReasons] = useState([
    { value: 0, reason: 'Custom Reason' },
    { value: 1, reason: 'Input Error' },
    { value: 2, reason: 'Wrong Machine' },
    { value: 3, reason: 'Wrong Date' }
  ]);
  const {
    error,
    data,
    loading,
    logCompleted,
    logError,
    executeAsync,
    executeLog
  } = useAsync({
    axiosRequest: axiosRequest
  });

  //************************ USE EFFECTS ************************

  // Handle Axios Request
  useEffect(() => {
    if (data) {
      // TODO handle if activity error
      if (logError) console.log('Something went wrong with the Activity Log');
    }
  }, [data, logCompleted, logError]);

  //************************ FUNCTIONS ************************
  const handleSelectReason = e => {
    setSelectedReason(e.target.value);
    !e.target.value
      ? setVoidReason('')
      : setVoidReason(presetReasons[e.target.value].reason);
  };
  const handleTyping = e => {
    e.persist();
    setVoidReason(e.target.value);
    setSelectedReason(0);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const todayDate = new Date().toISOString();
    // When click on Void button

    //* VOID entry
    const response = await executeAsync({
      endpoint: 'machines/entries',
      api_key: authUser.api_key,
      method: 'patch',
      body: {
        toVoid: true,
        date: todayDate,
        reason: voidReason,
        user_id : user.id,  
        user_name : user.name,
        entry_id: copyEntry.original.entry_id
      }
    });
    //* LOG ACTIVITY
    if (response) {
      await executeLog({
        endpoint: 'activity',
        api_key: authUser.api_key,
        body: {
          attributes: {
            type: 'Void Entry',
            user_id: user.id,
            user_name: user.name,
            entry_id: copyEntry.entry_id,
            void_reason: voidReason,
            machine_id: copyEntry.original.machine_id
          }
        }
      }); 
      await dispatchSelectedMachine({
          type: VOID_ENTRY,
          payload: {
            entry_id: copyEntry.original.entry_id,
            void: { date: todayDate, reason: voidReason, user_id : user.id,  user_name : user.name }
          }
      });

      // Save in context to reflect change
      setTimeout(() => {
        handleVoidedClose()
      }, 2000);
    }
  };

  return (
    <GlobalDialog
      open={open}
      closeModal={handleClose}
      title="Void Entry"
      handleSubmit={handleSubmit}
      buttonTitle="Void"
      loading={loading}
      error={error}
      data={data}
      successMessage="Entry voided successfully!"
      fullWidth
      maxWidth="sm"
    >
      <Grid container spacing={3}>
        <Grid item>
          <Alert severity="error">
            Entries can not be deleted. Only voided. Voided entries will not display on graphs/exports but will still be available to view
          </Alert>
        </Grid>
        <Grid item container spacing={1}>
          <Grid item xs={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="preset-reason">Preset Reason</InputLabel>
              <Select
                labelId="preset-reason"
                onChange={handleSelectReason}
                value={selectedReason}
               label={setLanguageText(language,"Preset Reason")}
              >
                {presetReasons.map(reason => (
                  <MenuItem key={reason.value} value={reason.value}>
                    {reason.reason}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              variant="outlined"
             label={setLanguageText(language,"Void Reason")}
              value={voidReason}
              fullWidth
              onChange={handleTyping}
            />
          </Grid>
        </Grid>
      </Grid>

    </GlobalDialog>
  );
};

export default VoidEntry;
