import React from 'react';
import PieChart from './PieChart';
import BarChart from './BarChart';
import { Grid, useTheme } from '@mui/material';

function DashboardCharts({chartData}) {

  const theme = useTheme();



  return (
    <>
      {chartData ?
        <Grid
          container
          spacing={2}
          style={{ marginBottom: theme.spacing(3) }}
          alignItems="stretch"
        >

          {chartData &&
            chartData.map(chart => {
  
              return (
                
                chart.chart_type === 'pie' ? (
                <Grid key={chart.id} item xs={12} sm={6} md={4}>
                  <PieChart
                    results={chart}
                  />
                </Grid>
                ) :  chart.chart_type === 'bar' ? (
                  <Grid key={chart.id} item xs={12} sm={6} md={4}>
                     <BarChart
                        results={chart}
                      />
                  </Grid>
                  ) : null
              );
            })} 
        </Grid>
        : null}
    </>
  );
}

export default DashboardCharts;
