import React, { Fragment, useContext, useState } from 'react';
import { faCog, faTable } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Menu,
  Checkbox,
  FormControlLabel,
  Typography,
  MenuItem,
  Grid,
  Divider,
  Box
} from '@mui/material';
import { buttonStyles } from '../../styles/buttonStyles';
import { LayoutContext } from '../../store/LayoutContext';
import setLanguageText from '../../helpers/setLanguageText';


const CheckBoxSelect = ({column}) => {


  return (
    <>
      {column?.getToggleHiddenProps ? 
        <Checkbox {...column.getToggleHiddenProps()} />
      :
        <Checkbox />
      }
    </>
  )
}

const TableSettings = ({
  allColumns,
  getToggleHideAllColumnsProps,
  showEntryText,
  showEntryOption,
  showEntry,
  setShowEntry
}) => {

  //console.log(allColumns)
  const { language } = useContext(LayoutContext);
  const buttonStyle = buttonStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  //************************ FUNCTIONS ************************
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        variant="contained"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={buttonStyle.danger}
      //size="small"
      >
        <FontAwesomeIcon icon={faTable} style={{ marginRight: '10px' }} />
        <Typography variant="body1" >
        {setLanguageText(language,"Edit Table")}
        </Typography>
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        //getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        {showEntryOption && (
          <MenuItem>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <Typography component="div">
                  <Box fontStyle="italic">{`${showEntryText}:`}</Box>
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  onClick={() => setShowEntry(prevState => !prevState)}
                  variant="contained"
                  size="small"
                  className={`${buttonStyle.fixPointerEvent} ${showEntry ? buttonStyle.success : buttonStyle.danger
                    }`}
                >
                  {String(showEntry)}
                </Button>
              </Grid>
            </Grid>
          </MenuItem>
        )}
        {/* <MenuItem>
          <ToggleAll {...getToggleHideAllColumnsProps()} />
        </MenuItem> */}
        <Divider />
        {allColumns.map(column => {
          return (
            column.id !== 'edit' &&
            column.id !== 'out_of_control' && (
              //column.id !== 'date_stamp' && 
              <MenuItem key={column.id}>
                <FormControlLabel
                  control={<CheckBoxSelect column={column} />}
                  label={setLanguageText(language,column.name) }
                />
              </MenuItem>
            )
          );
        })}
      </Menu>
    </>
  );
};

export default TableSettings;
