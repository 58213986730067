import React, { useState, useEffect, useContext } from 'react';
import { Grid, Typography, Paper, useTheme } from '@mui/material';
import ProgressChart from './ProgressChart';
import LoadingScreen from '../../reusable/LoadingScreen';
import setLanguageText from '../../../helpers/setLanguageText';
import { LayoutContext } from '../../../store/LayoutContext';

function HorizontalChart({ chartData }) {

  const { language } = useContext(LayoutContext)
  //****** STATE ******
  const theme = useTheme();
  const [totalMachines, setTotalMachine] = useState();
  const [checked, setChecked] = useState();

  // //****** USE EFFECT ******

  useEffect(() => {
    if (chartData) {
      let index = chartData.findIndex(item => item.id === 'totals');
      let total = chartData[index].data.no_entries.count + chartData[index].data.has_entries.count
      setTotalMachine(total)
      setChecked(chartData[index].data.has_entries.count)
    }
  }, [chartData])

  return (
    <>
      {totalMachines ?
        <Grid
          container
          style={{ marginBottom: theme.spacing(3) }}
          justifyContent="space-between"
          spacing={2}
        >
          <Grid item xs={12} sm={3}>
            <Paper
              elevation={0}
              style={{
                color: theme.palette.background.light,
                backgroundColor: theme.palette.primary.main
              }}
            >
              <Grid item container justifyContent="center" direction="column">
                <Typography variant="h2" align="center">
                  {/* avoid returning NaN when loading */}
                  <strong>{totalMachines}</strong>
                </Typography>
                <Typography variant="caption" align="center">
                  <strong>{setLanguageText(language,"Total Machines")}</strong>
                </Typography>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={9}>
            <ProgressChart
              checked={checked}
              totalMachines={totalMachines}
            />
          </Grid>
        </Grid>
        :
        <Grid>
          <LoadingScreen
            loading={false}
            background="#fafafa"
            colour="#666"
            text="No Machines, Please add a Machine"
          //position="relative"
          />
        </Grid>
      }
    </>
  );
}

export default HorizontalChart;
