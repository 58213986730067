import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { Grid } from '@mui/material';
import useAsync from '../../Hooks/useAsync';
import axiosRequest from '../../helpers/axiosRequest';
import { UserContext } from '../../store/UserContext';
import DropdownAdditivesTypes from '../reusable/DropdownAdditivesTypes';
import DropdownAdditivesByType from '../reusable/DropdownAdditivesByType';
import ReportCard from './ReportCard';
import ReportPDF from './ReportPDF';
import { Document, Page, Text, View } from '@react-pdf/renderer';
import create_pdf_headers from './create_pdf_headers';
import create_pdf_rows from './create_pdf_rows';
import slugifyString from '../../helpers/slugifyString';
import splitString from '../../helpers/splitString';
import formatDate from '../../helpers/formatDate';
import { LayoutContext } from '../../store/LayoutContext';

const AdditiveUsageByAdditive = () => {
  const { language } = useContext(LayoutContext);
  const { authUser, user } = useContext(UserContext);
  const [additive_type, setAdditive_type] = useState(null);
  const [additive, setAdditive] = useState(null);
  const [pdf_data, setPdf_data] = useState(null);
  const [csv_data, setCsv_data] = useState([]);
  const [open, setOpen] = useState(false);
  const [showPreview, setShowPreview] = useState(false);

  const { error, data, executeAsync, setData } = useAsync({
    axiosRequest: axiosRequest
  });
  const [noData, setNoData] = useState(false);
  //******************* USE CALLBACKS *******************

  const runReport = useCallback(() => {
    setOpen(true);
    //console.log(additive)
    executeAsync({
      method: 'get',
      api_key: authUser.api_key,
      endpoint: `reports/additives-usage`,
      params: { 
        additive_id: additive?.additive_id,
        user_level : user.level,
        company_ids: JSON.stringify(user.companies_id)
      }
    });
  }, [additive, authUser, user,  executeAsync]);

  //******************* USE EFFECTS *******************
  // react-pdf throws an error if PDFViewer is open and repass data again, so we save it locally to only render the pdf once
  useEffect(() => {
    if (data && !pdf_data) {
      if (!data.length) return setNoData(true);
      const parse_additives = data.map(row => {
        let additives = JSON.parse(row.additives);
        if (additives.length > 1) {
          additives = additives.filter(
            db_additive => db_additive.additive_id === additive.additive_id
          );
        }
        const add_details = {
          ...row,
          details: additives[0].additive_details
        };
        delete add_details.additives;
        return add_details;
      });
      setPdf_data(parse_additives);
      setShowPreview(true);
    }
  }, [additive, data, pdf_data]);

  useEffect(() => {
    if (noData)
      setTimeout(() => {
        setOpen(false);
        setNoData(false);
      }, 2000);
  }, [noData]);

  const exclude_columns = useMemo(() => ['company_name'], []);
  const expand_columns = useMemo(() => ['machine_name', 'machine_group'], []);
  const reduce_columns = useMemo(
    () => ['entry_id', 'machine_id', 'user_id'],
    []
  );

  const enableButton = useMemo(() => {
    if (additive_type && additive) return true;
  }, [additive, additive_type]);

  const create_pdf_data = useCallback(
    styles => {
      return (
        <Document key="Additive Usage by Additive" title="Additive Usage by Additive">
        <Page style={styles.BODY}>
          <View fixed style={styles.ROW_ABOVE_TABLE}>
            <Text style={styles.ROW_ABOVE_TABLE.BIG_TEXT}>
              {`${additive.additive_name} - ${additive_type.additive_type_name}`}
            </Text>
            <Text style={styles.ROW_ABOVE_TABLE.SMALL_TEXT}>
              Total Machines: {data.length}
            </Text>
          </View>
          <View style={styles.TABLE}>
            <View style={styles.TABLE.HEADER} fixed>
              {create_pdf_headers({
                   language: language,
                headers: pdf_data[0],
                styles,
                exclude_columns,
                expand_columns,
                reduce_columns
              })}
            </View>
            {create_pdf_rows({
              rows: pdf_data,
              styles,
              group_value: 'company_name',
              exclude_columns,
              expand_columns,
              reduce_columns
            })}
          </View>
          </Page>
        </Document>
      );
    },
    [
      additive,
      additive_type,
      data,
      exclude_columns,
      pdf_data,
      expand_columns,
      reduce_columns
    ]
  );

  const create_csv_data = useCallback(() => {
    const headers = Object.keys(pdf_data[0]).map(header => splitString(header));
    const values = pdf_data.map(value => {
      const formatDates = {
        ...value,
        date_stamp: formatDate(value.date_stamp)
      };
      return Object.values(formatDates);
    });

    setCsv_data([headers, ...values]);
  }, [pdf_data]);

  const csv_filename = useMemo(
    () =>
      `report_${slugifyString(
        additive_type?.additive_type_name
      )}_${slugifyString(additive?.additive_name)}`,
    [additive, additive_type]
  );

  return (
    <>
      <ReportCard
        title="Additive Usage by Additive"
        buttonLabel="Run Report"
        enableButton={enableButton}
        //loading={loading}
        //error={error}
        runReport={runReport}
        disabled={!additive_type || !additive ? true : false}
        noData={noData}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <DropdownAdditivesTypes setAdditive_type={setAdditive_type} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DropdownAdditivesByType
              additive_type={additive_type}
              setSelectedAdditive={setAdditive}
            />
          </Grid>
        </Grid>
      </ReportCard>
      {open &&
          <ReportPDF
            dialog_title="Additive Usage by Additive"
            dialogOpen={open}
            error={error}
            dialogClose={e => {
              //close and reset
              setOpen(false);
              setData();
              setPdf_data(null);
              setCsv_data([]);
              setShowPreview(false);
            }}
            noData={noData}
            showPreview={showPreview}
            create_pdf_data={create_pdf_data}
            create_csv_data={create_csv_data}
            csv_data={csv_data}
            csv_filename={csv_filename}
          />
        }
    </>
  );
};

export default AdditiveUsageByAdditive;
