const sortAdditives = (additives, sortType) => {
  switch (sortType) {
    case 'most_used':
      return additives.sort((a, b) => {
        // If machines_count is undefined need to set to 0 in order to sort it
        const zeroA = !a.machines_count ? 0 : a.machines_count;
        const zeroB = !b.machines_count ? 0 : b.machines_count;
        return zeroA < zeroB ? 1 : zeroA > zeroB ? -1 : 0;
      });
    case 'asc':
      return additives.sort((a, b) =>
        a.additive_name < b.additive_name
          ? -1
          : a.additive_name > b.additive_name
          ? 1
          : 0
      );
    case 'desc':
      return additives.sort((a, b) =>
        a.additive_name < b.additive_name
          ? 1
          : a.additive_name > b.additive_name
          ? -1
          : 0
      );
    default:
      break;
  }
};

export default sortAdditives;
