import React, { useContext, useState, useEffect } from 'react';
import SelectionRow from './Selection_Row/SelectionRow';
import HorizontalChart from './charts/HorizontalChart';
import DashboardCharts from './charts/DashboardCharts';
import DropdownGroup from './Selection_Row/DropdownGroups';
import DropdownMachines from './Selection_Row/DropdownMachines';
import DropdownCompanies from './Selection_Row/DropdownCompanies';
import { Grid, useTheme } from '@mui/material';
import PageHeader from '../PageHeader';
import PageContent from '../PageContent';
import ExportCharts from '../Exports/ExportCharts';
import { MachineContext } from '../../store/MachineContext';
import { CompanyContext } from '../../store/CompanyContext';
import CreateChartData from '../../helpers/createChartData';
import LoadingScreen from '../reusable/LoadingScreen';
import PrimaryBtnIcon from '../buttons/PrimaryBtnIcon';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import AddMachineDialog from '../FloatingActionButton/AddMachineDialog';
import { useHistory, useParams } from 'react-router-dom';
import setLanguageText from '../../helpers/setLanguageText';
import { LayoutContext } from '../../store/LayoutContext';

const Dashboard = () => {

  const history =useHistory();
  const theme = useTheme();
  const { filteredMachines, selectedMachine, selectedGroup , noMachines } = useContext(MachineContext);
  const { language } = useContext(LayoutContext)
  const { selectedCompany } = useContext(CompanyContext);
  const [chartData, setChartData] = useState();
  const [open, setOpen] = useState(false);
  
//remove credentials
  useEffect(() => {
    const url = new URLSearchParams(window.location.hash);
    const password = url.get('p');
    const user = url.get('u');
    if(password || user ){
      //force refresh to get rid of url params
      history.push('/')
    }
    //console.log(password)
  }, [window.location.hash]);


  useEffect(() => {
    // Prioritize if user select a specific machine from the Machine dropdown

    if (selectedMachine?.machine_id) {
      setChartData(
        CreateChartData([selectedMachine], selectedCompany.company_attributes, theme, selectedCompany.company_colour_rules,language)
      );
      // Otherwise create chart data based on filteredMachines
    } else if (Object.values(filteredMachines).length) {
        setChartData(
          CreateChartData(filteredMachines, selectedCompany.company_attributes, theme, selectedCompany.company_colour_rules,language)
        );
    } else {
      setChartData([])
    }

  }, [filteredMachines, selectedCompany.company_attributes, selectedCompany.company_colour_rules, selectedMachine, theme]);


  return (
    <>
      {/* HEADER */}
      <PageHeader>
        <SelectionRow />
        {/* DROPDOWN OPTIONS */}
        <Grid container spacing={1}>
          <Grid item xs={12} md={3}>
            <DropdownCompanies fetchEntries={true} />
          </Grid>
          <Grid item xs={12} md={3}>
            <DropdownGroup menuDirection="bottom" />
          </Grid>
          <Grid item xs={12} md={3}>
            <DropdownMachines />
          </Grid>
          {/* <Grid item xs={12} md={1} >
            <ResetButton />
          </Grid> */}
          <Grid item xs={12} md={2}>
            <ExportCharts
              chartData={chartData}
              selectedCompany={selectedCompany}
              selectedGroup={selectedGroup}
            />
          </Grid>
        </Grid>
      </PageHeader>
      {/* CHARTS */}

      <PageContent>
        {chartData && Object.values(chartData).length ?
          <>
            <HorizontalChart chartData={chartData} attributes={selectedCompany.company_attributes} />
            <DashboardCharts chartData={chartData} attributes={selectedCompany.company_attributes} />
          </>
        :
          <LoadingScreen
            loading={false}
            background="#fafafa"
            colour="#666"
            text={  !noMachines ? `Loading Machines` : `Company has no machines, please add a machine`}
            customAction={
              noMachines &&  (
                <Grid container justifyContent="space-between" alignContent="center">
                  <Grid item style={{ margin: 'auto' }}>
                    <PrimaryBtnIcon
                      //disabled
                      icon={faPlus}
                      text="Add First Machine"
                      onClick={e => setOpen(true)}
                    //className={buttonStyle.danger}
                    />
                    <AddMachineDialog
                      isDialog={open}
                      setIsDialog={setOpen}
                    />
                  </Grid>
                </Grid>
              )
            }
          />
        }
      </PageContent>
    </>
  );
};
export default Dashboard;
