import React, { forwardRef, useContext, useEffect, useState } from 'react';
import formatDate from '../../helpers/formatDate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faPen } from '@fortawesome/pro-light-svg-icons';
import { buttonStyles } from '../../styles/buttonStyles';
import OutlinedDiv from '../reusable/OutlinedDiv';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import setLanguageText from '../../helpers/setLanguageText';
import { LayoutContext } from '../../store/LayoutContext';

const useStyles = makeStyles(theme => ({
  signature: {
    marginBottom: theme.spacing(1)
  },
  sign_check: {
    color: theme.palette.success.main
  },
  sign_image: {
    width: '100%',
    maxHeight: 200
  }
}));

const ServiceDialog = forwardRef(
  (
    {
      selectedFlatRows,
      allColumns,
      selectedCompany,
      openEngineerSignOpen,
      openCustomerSignOpen,
      serviceObj,
      setServiceObj
    },
    ref
  ) => {

    //console.log(allColumns)
    //console.log(selectedFlatRows)
    //****************** STYLES ******************
    const classes = useStyles();
    const buttonStyle = buttonStyles();
    const { language } = useContext(LayoutContext);
    //****************** STYLES ******************
    const [localNotes, setLocalNotes] = useState('');
    const [localEngineerName, setLocalEngineerName] = useState(
      serviceObj.service_name
    );
    const [localCustomerName, setLocalCustomerName] = useState('');
    const [rows, setRows] = useState();
    const [headers, setHeaders] = useState();

    useEffect(() => {
      if (selectedFlatRows.length) {

        ////!!! need to look at this, if the coloumn isn't in view it doesn't have the cell option
        let table = [...selectedFlatRows]
        //console.log(table)
        let reducedRows = table.map(row => row.cells)
        let reducedHeaders = table[0].cells.map(cell => cell.column);

        //console.log(reducedHeaders)
        //console.log(reducedRows)
        //setHeaders(table[0].allCells.map( cell => cell.column))
        setHeaders(reducedHeaders)
        setRows(reducedRows)
      }
    }, [selectedFlatRows])

    //console.log(rows)

    //****************** FUNCTIONS ******************
    // Notes
    const handleNotes = e => {
      setLocalNotes(e.target.value);
    };
    const confirmNotes = e => {
      setServiceObj({ ...serviceObj, service_notes: localNotes });
    };
    // Service Start/End
    const handleServiceStart = time => {
      setServiceObj({
        ...serviceObj,
        service_attr: { ...serviceObj.service_attr, startTime: time }
      });
    };
    const handleServiceEnd = time => {
      setServiceObj({
        ...serviceObj,
        service_attr: { ...serviceObj.service_attr, finishTime: time }
      });
    };
    // Name Fields
    const handleEngineerName = e => {
      setLocalEngineerName(e.target.value);
    };
    const handleCustomerName = e => {
      setLocalCustomerName(e.target.value);
    };
    const confirmEngineerName = e => {
      setServiceObj({ ...serviceObj, service_name: localEngineerName });
    };
    const confirmCustomerName = e => {
      setServiceObj({ ...serviceObj, customer_name: localCustomerName });
    };

    return (
      <>
        <Grid container spacing={1} ref={ref}>
          <Grid item xs={12}>
            <Typography align="center" variant="h5" gutterBottom>
              {selectedCompany.company_name}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            {/* Selected Entries Table */}
            <Card variant="outlined">
              <CardContent
                style={{
                  overflowX: 'auto',
                  maxHeight: '70vh',
                  padding: '0'
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      {headers && headers.map(cell => {
                        return (
                          // exclude selection checkbox
                          cell.id !== 'selection' && (
                            <TableCell key={cell.id} align="left">
                              <Typography style={{ fontWeight: 'bold' }}>
                                {cell.name}
                              </Typography>
                            </TableCell>
                          )
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows && rows.map((row, i) => {
                      //console.log(row)
                      return (
                        <TableRow key={i}>
                          {row.map((cell, i) => {
                            return (
                              // exclude selection checkbox
                              cell.column.id !== 'selection' && (
                                <TableCell align="left" key={i}>
                                  {cell.column.id === 'Additives' ?
                                    JSON.parse(cell.value).map((additive) => {
                                      if (additive.additive_name) {
                                        return `${additive.additive_name} - ${additive.additive_type} (${additive.additive_details})`
                                      } else {
                                        return `${additive.additive_type} (${additive.additive_details})`
                                      }
                                    })
                                    :
                                    cell.column.id === 'entry_date'
                                      ? formatDate(cell.value)
                                      : cell.column.id === 'conc' && cell.row.original.factor > 1 ?
                                        `${(Number(cell.value) * Number(cell.row.original.factor ? cell.row.original.factor : 1)).toFixed(1)} (${Number(cell.value).toFixed(1)})`
                                        : cell.value}
                                </TableCell>
                              )
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </Grid>

          {/* Service Notes */}
          <Grid item xs={12}>
            <TextField
              value={localNotes}
              onChange={handleNotes}
              onBlur={confirmNotes}
              variant="outlined"
              label={setLanguageText(language, "Service Notes")}
              multiline
              fullWidth
            />
          </Grid>

          {/* Service Start / Finish Time */}
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid item xs={12} sm={6}>
              <DatePicker
                margin="none"
                value={serviceObj.service_attr.startTime}
                onChange={handleServiceStart}
                fullWidth
                inputVariant="outlined"
                label={setLanguageText(language, "Service Start")}
                required
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => <TextField {...params} />}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DatePicker
                margin="none"
                value={serviceObj.service_attr.finishTime}
                onChange={handleServiceEnd}
                fullWidth
                inputVariant="outlined"
                label={setLanguageText(language, "Service End")}
                required
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => <TextField {...params} />}
              />
            </Grid>
          </LocalizationProvider>

          {/* ENGINEER SIGNATURE*/}
          <Grid item xs={12} sm={6}>
            <Grid container>
              <Grid item xs={12} className={classes.signature}>
                <TextField
                  label={setLanguageText(language, "Engineer Name")}
                  fullWidth
                  variant="outlined"
                  onChange={handleEngineerName}
                  onBlur={confirmEngineerName}
                  value={localEngineerName}
                  required
                  InputProps={{
                    endAdornment: serviceObj.service_sign && (
                      <InputAdornment position="end">
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className={classes.sign_check}
                          size="lg"
                        />
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item container spacing={1}>
                {serviceObj.service_sign && (
                  <Grid item xs={12} sm={6}>
                    <OutlinedDiv label={setLanguageText(language, "Engineer Sign")}>
                      <img
                        src={serviceObj.service_sign}
                        alt="Engineer Sign"
                        className={classes.sign_image}
                      />
                    </OutlinedDiv>
                  </Grid>
                )}

                <Grid item xs={12} sm={serviceObj.service_sign ? 6 : 12}>
                  <Button
                    color={serviceObj.service_sign ? 'secondary' : 'primary'}
                    variant="contained"
                    fullWidth
                    onClick={openEngineerSignOpen}
                  >
                    <FontAwesomeIcon icon={faPen} />
                    <Typography className={buttonStyle.buttonIconText}>
                      {setLanguageText(language, serviceObj.service_sign
                        ? 'Edit Signature'
                        : 'Add Signature')}
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* CUSTOMER SIGNATURE */}
          <Grid item xs={12} sm={6}>
            <Grid container>
              <Grid item xs={12} className={classes.signature}>
                <TextField
                  label={setLanguageText(language, "Customer Name")}
                  fullWidth
                  variant="outlined"
                  onChange={handleCustomerName}
                  onBlur={confirmCustomerName}
                  value={localCustomerName}
                  required
                  InputProps={{
                    endAdornment: serviceObj.cust_sign && (
                      <InputAdornment position="end">
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className={classes.sign_check}
                          size="lg"
                        />
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item container spacing={1}>
                {serviceObj.cust_sign && (
                  <Grid item xs={12} sm={6}>
                    <OutlinedDiv label={setLanguageText(language, "Customer Sign")}>
                      <img
                        src={serviceObj.cust_sign}
                        alt="Customer Sign"
                        className={classes.sign_image}
                      />
                    </OutlinedDiv>
                  </Grid>
                )}
                <Grid item xs={12} sm={serviceObj.cust_sign ? 6 : 12}>
                  <Button
                    color={serviceObj.cust_sign ? 'secondary' : 'primary'}
                    variant="contained"
                    fullWidth
                    onClick={openCustomerSignOpen}
                  >
                    <FontAwesomeIcon icon={faPen} />
                    <Typography className={buttonStyle.buttonIconText}>
                      {setLanguageText(language, serviceObj.cust_sign
                        ? 'Edit Signature'
                        : 'Add Signature')}
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
);

export default ServiceDialog;
