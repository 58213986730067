// parse machines' attributes and settings
const parseAdditives = additives => {
  if (additives.length) {
    return additives.map(additive => {
      return {
        ...additive,
        additive_attr: JSON.parse(additive.additive_attr),
        machines_using_additive:
          additive.machines_using_additive &&
          JSON.parse(additive.machines_using_additive)
      };
    });
  }
  return [];
};
export default parseAdditives;
