import axiosRequest from './axiosRequest';

// Check user level and fetch companies accordingly
const fetchEntries = ({machine_id, date_from, date_to, api_key, voided}) => {
  return axiosRequest({
    api_key : api_key,
    method: 'get',
    endpoint: 'machines/entries',
    params: {
      machine_id: machine_id,
      date_from:date_from,
      date_to: date_to,
      voided : voided
    }
  });
};

export default fetchEntries;
