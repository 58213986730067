import {
  Button,
  DialogActions,
  Grid,
  TextField
} from '@mui/material';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import axiosRequest from '../../helpers/axiosRequest';
import useAsync from '../../Hooks/useAsync';
import { UserContext } from '../../store/UserContext';
import MyDatePicker from '../Machines/MachineDetails/MyDatePicker';
import DropdownActivityTypes from '../reusable/DropdownActivityTypes';
import DropdownUsers from '../reusable/DropdownUsers';
import ActivityTable from './ActivityTable';
import activityTable_columns from './activityTable_columns';
import ReportCard from './ReportCard';
import parseActivityAttributes from '../../helpers/parseActivityAttributes';
import formatDate from '../../helpers/formatDate';
import GlobalDialog from '../reusable/GlobalDialog';
import { CSVLink } from 'react-csv';
import { buttonStyles } from '../../styles/buttonStyles';
import LoadingButton from '../buttons/LoadingButton';
import { Autocomplete } from '@mui/material';
import setLanguageText from '../../helpers/setLanguageText';
import { LayoutContext } from '../../store/LayoutContext';

const ActivityLog = ({ companies }) => {
  //************************ USE STYLES ************************
  const buttonStyle = buttonStyles();
  //************************ USE CONTEXT ************************
  const { authUser, user: userContext } = useContext(UserContext);
  const { language } = useContext(LayoutContext);
  //const { companies } = useContext(CompanyContext);
  /*---- SET TO AND FROM DATES ----*/
  const [dateFrom, setDateFrom] = useState(); // from Midnight
  const [from, setFrom] = useState(new Date().setHours(0, 0, 0, 0)); // from Midnight
  const [dateTo, setDateTo] = useState(); // to current time
  const [to, setTo] = useState(new Date().getTime()); // to current time
  //************************ USE STATE ************************
  const [activityType, setActivityType] = useState({ activity_type: 'All Activities' });
  const [user, setUser] = useState(null);
  const { error, data, loading, executeAsync } = useAsync({
    axiosRequest: axiosRequest
  });
  const [noData, setNoData] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [csv_data, setCsv_data] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState();


  useEffect(() => {
    if (to) {
      //console.log('to')
      //console.log(new Date(parseInt(to)).toISOString())
      //midnight the day before
      setDateTo(new Date(to).setHours(24, 0, 0, 0))
      //console.log(new Date(to).setHours(24, 0, 0, 0))
    }
    if (from) {
      //console.log('from')
      //console.log(new Date(parseInt(from)).toISOString())  
      //current day at midnight
      setDateFrom(new Date(from).setHours(0, 0, 0, 0))
      //console.log(new Date(from).setHours(0, 0, 0, 0))
    }

  }, [to, from])


  const runReport = useCallback(() => {

    let params = {
      date_from: new Date(parseInt(dateFrom)).toISOString() ,
      date_to: new Date(parseInt(dateTo)).toISOString(),
      activity_type: activityType.activity_type,
      user_id: user.id,
      level: userContext.level,
      //company_id: selectedCompany.company_id ? selectedCompany.company_id : JSON.stringify(userContext.companies_id)
    };
    //if all compnaies selected - check user level
    if(selectedCompany.company_id === 0 ){   
      if(userContext.level === 9 ){
        params.fetch_all = true
      } else {
        params.company_id = JSON.stringify(userContext.companies_id);
      }
    } else {
        params.company_id =  selectedCompany.company_id ? selectedCompany.company_id : JSON.stringify(userContext.companies_id)
    }

    // if (activityType.activity_type !== 'All Activities')
    //   params = { ...params, activity_type: activityType.activity_type };
    // if (user.id) params = { ...params, user_id: user.id };
    // if (selectedCompany.company_id){
    //   params = { ...params, company_id: selectedCompany.company_id };
    //   params = { ...params, date_from: dateFrom, date_to: dateTo };

    executeAsync({
      method: 'get',
      api_key: authUser.api_key,
      endpoint: `reports/activity`,
      params
    });
  }, [
    activityType,
    authUser.api_key,
    userContext,
    selectedCompany,
    dateFrom,
    dateTo,
    executeAsync,
    user
  ]);

  useEffect(() => {
    if (data) {
      if (!data.length) return setNoData(true);
      setIsModal(true);
    }
  }, [data]);

  useEffect(() => {
    if (noData)
      setTimeout(() => {
        setNoData(false);
      }, 3000);
  }, [noData]);

  const closeModal = () => setIsModal(false);

  //************ TABLE ************
  const table_data = useMemo(() => parseActivityAttributes(data), [data]);
  const table_columns = useMemo(() => activityTable_columns({language:language}), []);

  //************ CSV ************
  const csv_filename = useMemo(() => {
    const dateFrom_string = formatDate(new Date(dateFrom));
    const dateTo_string = formatDate(new Date(dateTo));

    return `activity_log ${dateFrom_string} - ${dateTo_string}`;
  }, [dateFrom, dateTo]);

  const create_csv_data = useCallback(() => {
    const filterColumns = table_columns.filter(
      header => header.id !== 'more_details'
    );
    let row_values = [];
    const headers = filterColumns.map(header => header.name);

    table_data.forEach(value => {
      const column_value = [
        value.time_stamp,
        value.type,
        value.name,
        value.company_name,
        value.attributes.machine_id || '-'
      ];
      row_values = [...row_values, column_value];
    });
    setCsv_data([headers, ...row_values]);
  }, [table_columns, table_data]);



  //add Companies counter
  // const modifiedValue = useMemo(() => {
  //   return !selectedCompany?.company_id
  //     ? {
  //         ...selectedCompany,
  //         company_name: `${selectedCompany.company_name} (${
  //           companies.length > 0 ? companies.length - 1 : 0
  //         })`
  //       }
  //     : selectedCompany;
  // }, [companies, selectedCompany]);

  return <>
    <Grid container>
      <Grid item xs={12}>
        <ReportCard
          title="Activity Log"
          buttonLabel="Fetch Activity Logs"
          loading={loading}
          error={error}
          noData={noData}
          disabled={!selectedCompany ? true : false}
          runReport={runReport}
          datePicker={
            <MyDatePicker
              dateFrom={from}
              setDateFrom={setFrom}
              dateTo={to}
              setDateTo={setTo}
              size="small"
            />
          }
        >
          <Grid container spacing={1}>
            <Grid item xs={12} sm={4}>
              <Autocomplete
                disableClearable
                id="selected-company"
                value={selectedCompany || ''}
                options={companies || []}
                loading={companies ? false : true}
                getOptionLabel={option => {
                  if (option.company_id === 0) {
                    return `All Companies (${companies.length > 0 ? companies.length - 1 : 0})`
                  } else {
                    return option.company_name || ''
                  }
                }}
                isOptionEqualToValue={(option, value) =>
                  option.company_id === value.company_id
                }
                onChange={(e, newValue) => {
                  setSelectedCompany(
                    newValue
                  );
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={setLanguageText(language,"Select Company")}
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <DropdownUsers
                setSelected={setUser}
                selectedCompany={selectedCompany}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <DropdownActivityTypes
                setSelected={setActivityType}
                selectedType={activityType}
              />
            </Grid>
          </Grid>
        </ReportCard>
      </Grid>
    </Grid>
    <GlobalDialog
      open={isModal}
      closeModal={closeModal}
      title="Activity Log"
      maxWidth="xl"
      fullWidth
      customActions={
        <DialogActions>
          <Grid item>
            <Button variant="contained" onClick={closeModal} fullWidth>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <CSVLink
              data={csv_data}
              className={buttonStyle.buttonText}
              filename={`${csv_filename}.csv`}
            >
              <LoadingButton
                onClick={() => create_csv_data()}
                variant="contained"
                text="Download CSV"
              />
            </CSVLink>
          </Grid>
        </DialogActions>
      }
    >
      {/* <Grid container>
        <Grid item xs={6} sm={3}>
          Company: {selectedCompany.company_name}
        </Grid>
        <Grid item xs={6} sm={3}>
          User: {user?.name}
        </Grid>
        <Grid item xs={6} sm={3}>
          From: {formatDate(dateFrom)}
        </Grid>
        <Grid item xs={6} sm={3}>
          To: {formatDate(dateTo)}
        </Grid>
        <Grid item xs={12}> */}

      <ActivityTable data={table_data} columns={table_columns} />

      {/* </Grid>
      </Grid> */}
    </GlobalDialog>
  </>;
};

export default ActivityLog;
