import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import {
  Tabs,
  Tab
} from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import Companies from '../Companies/Companies';
import Users from '../Users/Users';
import Brands from '../Brands/Brands';
import Theme from '../Theme/Theme';
import Types from '../Types/Types';
import ApplicationDetails from './ApplicationDetails';
import { isMobileOnly } from 'react-device-detect';
import ComingSoon from '../ComingSoon';
import setLanguageText from '../../helpers/setLanguageText';
import { LayoutContext } from '../../store/LayoutContext';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: isMobileOnly ? 'block' : 'flex',
    //height: 224,
  },
  dropdown: {
    width: '100%',
    padding: '10px 10px 10px 0',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    minWidth: isMobileOnly ? '100%' : '240px',
    overflow: 'visible',
    background: '#fafafa'
  },
  tabs_content: {
    width: '100%',
    minHeight: '600px',
    overflow: 'auto',
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      className={classes.tabs_content}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        //lazy load
        children
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function createProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const ApplicationSettings = () => {

  let history = useHistory();
  let { tab } = useParams();
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const { language } = useContext(LayoutContext);  

  //update current tab from url poarameters
  useEffect(() => {
    //console.log(tab)
    setValue(parseInt(tab));
  }, [tab])

  //change URL parameters
  const handleChange = (event, newValue) => {
    history.push({ pathname: `/settings/app/${newValue}` })
    setValue(parseInt(newValue));
  };

  return (
    <>
    <div className={classes.root}>
      <Tabs
        allowScrollButtonsMobile
        orientation={isMobileOnly ? 'horizontal' : 'vertical'}
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Application Settings Menu"
        className={classes.tabs}
      >
        <Tab label={setLanguageText(language,"Application Details")} {...createProps(0)} />
        <Tab label={setLanguageText(language,"All Companies")} {...createProps(1)} />
        <Tab label={setLanguageText(language,"All Users")} {...createProps(2)} />
        <Tab label={setLanguageText(language,"Theme")} {...createProps(3)} />
        <Tab label={setLanguageText(language,"Brands")} {...createProps(4)} />
        <Tab label={setLanguageText(language,"Additive Types")} {...createProps(5)} />
        <Tab label={setLanguageText(language,"Training resources")} {...createProps(6)} />
        <Tab label={setLanguageText(language,"Diagnostic")} {...createProps(7)} />
      </Tabs>

      <TabPanel value={value} index={0}>
        <ApplicationDetails />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Companies />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Users />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Theme type="app" edit={true} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Brands />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <Types />
      </TabPanel>
      <TabPanel value={value} index={6}>
        <ComingSoon />
      </TabPanel>
      <TabPanel value={value} index={7}>
        <ComingSoon />
      </TabPanel>
    </div>
    </>
  );
}

export default ApplicationSettings;
