import React from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Popover,
  Tooltip,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ZoomIn } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/pro-light-svg-icons';
import { useHistory } from 'react-router';
import SelectColumnFilter from '../reusable/SelectColumnFilter';
import setLanguageText from '../../helpers/setLanguageText';

const useStyles = makeStyles(theme => ({
  popover: {
    pointerEvents: 'none'
  },
  paper: {
    maxWidth: '300px'
  }
}));

const activityTable_columns = ({language}) => {

  let columns = [
    // {
    //   id: 'activity_id',
    //   name: 'Activity ID',
    //   Header: () => <Typography variant="h6">Activity ID</Typography>,
    //   accessor: 'activity_id',
    //   Cell: ({ row, value }) => {
    //     return <Typography>{value}</Typography>;
    //   }
    // },
    {
      id: 'time_stamp',
      name: 'Date',
      Header: () => <Typography variant="h6">{setLanguageText(language, "Date")}</Typography>,
      accessor: 'time_stamp',
      Cell: ({ row, value }) => <Typography>{value}</Typography>
    },
    {
      id: 'type',
      name: 'Activity Type',
      //Header: () => <Typography variant="h6">Activity Type</Typography>,
      Header: () => null,
      accessor: 'type',
      Filter: (column) => SelectColumnFilter(column, setLanguageText(language,column.column.name),language),
      Cell: ({ row, value }) => {
        return <Typography>{value}</Typography>;
      }
    },
    {
      id: 'name',
      name: 'User Name',
      //Header: () => <Typography variant="h6">User Name</Typography>,
      Header: () => null,
      accessor: 'name',
      Filter: (column) => SelectColumnFilter(column, setLanguageText(language,column.column.name),language),
      Cell: ({ row, value }) => <Typography>{value}</Typography>
    },
    {
      id: 'company_name',
      name: 'Company Name',
      //Header: () => <Typography variant="h6">Company Name</Typography>,
      Header: () => null,
      accessor: 'company_name',
      Filter: (column) => SelectColumnFilter(column, setLanguageText(language,column.column.name),language),
      Cell: ({ value }) => {
        return <Typography>{value}</Typography>;
      }
    },
    {
      id: 'machine',
      name: 'Machine',
      Header: () => <Typography variant="h6">{setLanguageText(language, "Machine")}</Typography>,
      accessor: 'attributes',
      Cell: ({ value }) => {
        let history = useHistory();
        const handleMachineClick = () => {
          history.push(`/machine/${value.machine_id}`);
        };
        return value.machine_id ? (
          <Tooltip title={value.machine_id}>
            <IconButton onClick={handleMachineClick} style={{ padding: 0 }} size="large">
              <FontAwesomeIcon icon={faCog} />
            </IconButton>
          </Tooltip>
        ) : (
            ''
          );
      }
    },
    {
      id: 'more_details',
      name: 'More Details',
      Header: () => <Typography variant="h6">{setLanguageText(language, "More Details")}</Typography>,
      accessor: 'attributes',
      Cell: ({ row, cell }) => {
        // Popover styles
        const classes = useStyles();
        // save the hovered element in the Cell context and close the Popover
        const handlePopoverOpen = e => {
          cell.setState({ isOpen: true, anchorEl: e.currentTarget });
        };
        // remove any element in the Cell context and close the Popover
        const handlePopoverClose = () => {
          cell.setState({ isOpen: false, anchorEl: null });
        };
        return (
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            aria-owns={cell.state.isOpen ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            // Popover appear when hover the Status Cell
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          >
            <Grid item>
              <Grid container alignItems="center">
                <ZoomIn style={{ fontSize: '30px' }} />
                <Popover
                  id="mouse-over-popover"
                  // We need this in order to make the Popover close on hover when exit the Cell
                  className={classes.popover}
                  classes={{
                    paper: classes.paper
                  }}
                  open={cell.state.isOpen}
                  // the element where to position the Popover
                  anchorEl={cell.state.anchorEl}
                  // Position the Popover on the left
                  anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'center',
                    horizontal: 'right'
                  }}
                  onClose={handlePopoverClose}
                  disableRestoreFocus
                >
                  <Card>
                    <CardHeader title={row.original.type} />
                    <CardContent>
                      <pre>
                        {JSON.stringify(row.original.attributes, null, 2)}
                      </pre>
                    </CardContent>
                  </Card>
                </Popover>
              </Grid>
            </Grid>
          </Grid>
        );
      }
    }
  ]
  return columns
}

export default activityTable_columns;
