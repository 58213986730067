import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PageContent from '../PageContent';
import {
  Button,
  Grid,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { buttonStyles } from '../../styles/buttonStyles';
import BrandsTable from './BrandsTable';
import {
  faEdit,
  faPlus,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageHeader from '../PageHeader';
import useAsync from '../../Hooks/useAsync';
import { UserContext } from '../../store/UserContext';
import { BrandsContext } from '../../store/BrandsContext';
import { SAVE_BRANDS } from '../../store/BrandsReducers';
import axiosRequest from '../../helpers/axiosRequest';
import PrimaryBtnIcon from '../buttons/PrimaryBtnIcon';
import GlobalDialog from '../reusable/GlobalDialog';
import AddBrand from './AddBrand';
import BrandForm from './BrandForm';
import Image from '../reusable/Image'
import { Storage } from 'aws-amplify';
import LoadingScreen from './../reusable/LoadingScreen';
import config from '../../config';
import setLanguageText from '../../helpers/setLanguageText';
import { LayoutContext } from '../../store/LayoutContext';

const useStyles = makeStyles(theme => ({
  card: {
    width: '100%'
  },
  header: {
    borderBottom: `${theme.spacing(0.4)} solid ${theme.palette.background.light
      }`,
    padding: theme.spacing(1)
  },
  image: {
    '& img': {
      height: '100px',
      width: '100px',
      objectFit: 'contain'
    }
  }
}));

const Brands = () => {
  //************  STYLES ************
  const classes = useStyles();
  const buttonStyle = buttonStyles();
  //************  CONTEXT ************
  const { authUser, user } = useContext(UserContext);
  const { brands, dispatchBrands } = useContext(BrandsContext);
  const { language } = useContext(LayoutContext);

  //************ STATES ************
  const [editOpen, setEditOpen] = useState(false);
  const [addOpen, setAddOpen] = useState(false);
  const [additiveImage, setAdditiveImage] = useState();
  const [coolantImage, setCoolantImage] = useState();
  const [brand, setBrand] = useState();

  const {
    error: edit_error,
    data: edit_data,
    loading: edit_loading,
    executeAsync: edit_brand,
    executeLog : logBrand,
    setData
  } = useAsync({
    axiosRequest: axiosRequest
  });

  const {
    loading,
    executeAsync: fetch_brand
  } = useAsync({
    axiosRequest: axiosRequest
  });

const getBrands = useCallback(() => {
  
  fetch_brand({
    endpoint: 'brands',
    api_key: authUser.api_key,
    method: 'get'
  }).then(brands => {
    dispatchBrands({
      type: SAVE_BRANDS,
      payload: brands
    });
  });

},[authUser.api_key, fetch_brand, dispatchBrands])

  //************  USE EFFECT ************
  useEffect(() => {
    //console.log(brands)
    if (!brands.length) {
      getBrands();
    }
  }, [brands,getBrands]);

  //************ TABLE ************

  const table_data = useMemo(() => brands, [brands]);
  const table_columns = useMemo(() => [
    {
      id: 'brand_name',
      name: 'Brand Name',
      Header: () => <Typography variant="h6">{setLanguageText(language,"Brand Name")}</Typography>,
      accessor: 'brand_name',
    }, {
      id: 'coolant_image',
      name: 'Coolant Image',
      Header: () => <Typography variant="h6">{setLanguageText(language,"Coolant Image")}</Typography>,
      accessor: 'coolant_image',
      Cell: ({ value }) =>
        <Grid className={classes.image}>
          {value ? <Image className={classes.image} path={`https://${config.s3.BUCKET}.s3.${config.s3.REGION}.amazonaws.com/public/brands/${value}`} /> : null}
          </Grid>
    }, {
      id: 'additive_image',
      name: 'Additive Image',
      Header: () => <Typography variant="h6">{setLanguageText(language,"Additive Image")}</Typography>,
      accessor: 'additive_image',
      Cell: ({ value }) =>
        <Grid className={classes.image}>
         {value ? <Image path={`https://${config.s3.BUCKET}.s3.${config.s3.REGION}.amazonaws.com/public/brands/${value}`} /> : null}
          </Grid>
    }, {
      id: 'Actions',
      Header: () => <Typography variant="h6">{setLanguageText(language,"Actions")}</Typography>,
      sortable: false,
      minWidth: 50,
      width: 50,
      maxWidth: 50,
      Cell: ({ row }) => {
        return (
          <Grid container spacing={1} justifyContent="center">
            <Grid item xs={12}>
              <PrimaryBtnIcon
                icon={faEdit}
                text="Edit"//language in button component
                //iconSize="2x"
                onClick={e => openEditDialog(e, row)}
                size="small"
              />
            </Grid>
          </Grid>
        )
      }
    }
  ],[classes.image]);

  //************ FUNCTIONS ************

  const updateBrand = e => {

    setBrand((brand) => {
      return {
        ...brand,
        brand_name: e.target.value
      }
    });
  };

  const closeEditDialog = e => {
    setEditOpen(false);
  };

  const openEditDialog = (e, row) => {
    setBrand(row.original);
    setEditOpen(true);
  };

  const handleSubmit = async (e) => {
    //console.log('save edit')
    e.preventDefault()
    //console.log(coolantImage)
    //console.log(additiveImage)
    //console.log(brand)
    
    let body = {
      brand_id : brand.brand_id,
      brand_name : brand.brand_name, 
      coolant_image : coolantImage ? `${brand.brand_id}_coolant.png` : brand.coolant_image,
      additive_image : additiveImage ? `${brand.brand_id}_additive.png` : brand.additive_image
    }

    await edit_brand({
      endpoint: 'brands',
      api_key: authUser.api_key,
      method: 'patch',
      body: body
    });

    let imagesUpdated= {coolantImage : false, additiveImage: false}
    if (coolantImage) {
      try {
        await Storage.put(`brands/${brand.brand_id}_coolant.png`, coolantImage, {
          ACL: 'public-read',
          contentType: 'image/png',
          visibility: 'public',
          level: 'public',
        });
       
        imagesUpdated = {
          ...imagesUpdated,
          coolantImage : true,
        }

      } catch (err) {
        console.log(err);
      }
    }
    if (additiveImage) {
      try {
        await Storage.put(`brands/${brand.brand_id}_additive.png`, additiveImage, {
          ACL: 'public-read',
          contentType: 'image/png',
          visibility: 'public',
          level: 'public',
        });
        imagesUpdated = {
          ...imagesUpdated,
          additiveImage : true,
        }

      } catch (err) {
        console.log(err);
      }
    }

    await logBrand({
      endpoint: 'activity',
      api_key: authUser.api_key,
      body: {
        attributes: {
          type: 'Edit Brand',
          user_id: user.id,
          brand_id: brand.brand_id,
          imagesUpdated
        }
      }
    });

    setTimeout(() => {
      setEditOpen(false);
      //reset data from aysnc
      setData();
    }, 1000);
    //re fetch brands
    getBrands();

  }

  const closeAddDialog = e => {
    setAddOpen(false);
  };

  const openAddDialog = e => {
    setAddOpen(true);
  };

  return (
    <>
      {user.level === 9 && (
        <PageHeader>
          <Grid item xs={12}>
            <Grid
              item
              container
              justifyContent="space-between"
              className={classes.header}
            >
              <Grid item xs={12} sm={'auto'}>
                <Typography variant="h5">{setLanguageText(language,"Brands")}</Typography>
              </Grid>
              <Grid item xs={12} sm={'auto'}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={'auto'}>
                    <Button
                      fullWidth
                      onClick={openAddDialog}
                      variant="contained"
                      color="primary"
                      className={buttonStyle.fixPointerEvent}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                      {/* <FontAwesomeIcon icon={faIndustry} /> */}
                      <Typography className={buttonStyle.buttonIconText}>
                      {setLanguageText(language,"Add Brands")}
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <AddBrand
              closeAddDialog={closeAddDialog}
              open={addOpen}
            />
          </Grid>
        </PageHeader>
      )}
      <PageContent className="companies">
        <LoadingScreen 
          loading={!loading} 
          background="#fff" 
          colour="#666" 
          text="Loading Brands"
        />
        {table_data && !loading ?
          <BrandsTable data={table_data} columns={table_columns} />
        :
        null }
      </PageContent>
      <GlobalDialog
        open={editOpen}
        closeModal={closeEditDialog}
        title="Edit Brand"
        handleSubmit={ e => handleSubmit(e)}
        buttonTitle="Edit Brand"
        successMessage="Brand editied successfully!"
        loading={edit_loading}
        error={edit_error}
        data={edit_data}
        fullWidth
        maxWidth="sm"
      >
        <BrandForm
          brand={brand}
          updateBrand={updateBrand}
          setCoolantImage={e => setCoolantImage(e)}
          setAdditiveImage={e => setAdditiveImage(e)}
        />
      </GlobalDialog>
    </>
  );
};

export default Brands;
