import QuickChart from 'quickchart-js';
//import { useTheme } from '@mui/styles';

const createPieChart = (results) => {
    //console.log(results)
    const data = {
        datasets: [
          {
            data: [
              results.data.in.count,
              results.data.out.count,
              results.data.edge.count,
              results.data.none.count,
              results.data.no_entries.count
            ],
            backgroundColor: [
              results.data.in.colour,
              results.data.out.colour,
              results.data.edge.colour,
              results.data.none.colour,
              results.data.no_entries.colour
            ]
          }
        ],
        labels: [
            results.data.in.label,
            results.data.out.label,
            results.data.edge.label,
            results.data.none.label,
            results.data.no_entries.label
        ]
      };
      const options = {
        legend: {
          display: false
        },
        title: {
          display: false
        },
        plugins: {
            datalabels: {
                display: true,
                fontSize: 14,
                formatter: (val, ctx) => { 
                    if(val > 0 ){
                        return `${val}\n${ctx.chart.data.labels[ctx.dataIndex]}`
                    } else {
                        return null
                    }
                },
                textAlign: 'center',
                color: '#fff',
                backgroundColor: '#404040'
            }
        },
    };

    //console.log(data)

    const myChart = new QuickChart();
    myChart
        .setConfig({
            type: 'pie',
            data: data,
            options: options
        })
        .setWidth(250)
        .setHeight(200)
        .setBackgroundColor('transparent');

    // Print the chart URL
    console.log(myChart.getUrl()); 

    return myChart.getUrl();
}

export default createPieChart;
