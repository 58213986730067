import React, { useContext } from 'react';
import {
  Alert,
  Grid,
  Typography
} from '@mui/material';
import formatDate from '../../helpers/formatDate';
import CreateChip from '../reusable/CreateChip';
import setLanguageText from '../../helpers/setLanguageText';

const service_table_columns = ({ language, selectedCompany }) => {

  let columns = [
    {
      id: 'entry_id',
      name: 'ID',
      Header: () => <Typography  componenet="p" variant="body2" >ID</Typography>,
      accessor: 'entry_id',
      Cell: ({ row, value }) => {
        return <Typography variant="caption" >{value}</Typography>;
      },
      //show: false
    },
    {
      id: 'entry_date',
      name: 'Date',
      Header: () => <Typography  componenet="p" variant="body2" >{setLanguageText(language, "Date")}</Typography>,
      accessor: 'date_stamp',
      Cell: ({ row, value }) => {
        return <Typography variant="caption" >{formatDate(value)}</Typography>;
      },
      //show: false
    },
    {
      id: 'machine_name',
      name: 'Machine',
      Header: () => <Typography  componenet="p" variant="body2" >{setLanguageText(language, "Machine")}</Typography>,
      accessor: props => props.machine_name,
      Cell: ({ row, value }) => (
        <Typography variant="caption">
          <strong>{value}</strong>
        </Typography>
      )
    },
    {
      id: 'machine_group',
      name: 'Group',
      Header: () => <Typography  componenet="p" variant="body2" >{setLanguageText(language, "Group")}</Typography>,
      accessor: props => props.machine_group,
      Cell: ({ row, value }) => (
        <Typography variant="caption">
          <strong>{value}</strong>
        </Typography>
      )
    }
  ];

  /*------------- LOOP THROUGH COMPANY ATTRIBUTES TO CREATE OTHER COLUMNS ---------------*/
  if (Object.entries(selectedCompany).length) {
    //console.log(selectedCompany)
    selectedCompany?.company_attributes.forEach(item => {
      columns = [
        ...columns,
        {
          id: item.one.attr_id,
          name: item.one.attr_name,
          Header: <Typography  componenet="p" variant="body2" >{item.one.attr_name}</Typography>,
          accessor: props => {
            if (props.attributes_new) {
              const attributes_new = JSON.parse(props.attributes_new);

              let found = attributes_new.find(
                attr => attr.attribute_id === item.one.attr_id
              );
              return found && found.attribute_values.output;
            } else {
              return null;
            }
          },
          Cell: ({ row }) => {
            const attributes_new = JSON.parse(row.original.attributes_new);
            //console.log(row.original)
            return (
              <CreateChip
                company_attr_id={item.one.attr_id}
                entry_attributes={attributes_new}
                factorApplied={row.original?.factor ? true : false}
                entryFactor={row.original?.factor}
              />
            );
          },
          collapse: true
        }
      ];
    });
  }

  columns = [
    ...columns,
    {
      id: 'Additives',
      name: 'Additives',
      Header: () => <Typography  componenet="p" variant="body2" >{setLanguageText(language, "Additives")}</Typography>,
      accessor: props => props.additives,
      Cell: ({ value }) => {

        //console.log(row.original.additives)
        return (
          <Grid
            container
            alignItems="center"
            justifyContent="center"
          >
            {value ? (
              JSON.parse(value).map((item, i) =>
                <Grid key={i} item xs={12}>
                  <Typography variant="caption">
                    {item.additive_name ? `${item.additive_name} - ${item.additive_type}(${item.additive_details})` : `${item.additive_type}(${item.additive_details})`}
                  </Typography>
                </Grid>

              )

            ) : null}
          </Grid>
        );
      }
    },
    {
      id: 'notes',
      name: 'Notes',
      minWidth: 300,
      disableSortBy: true,
      Header: () => <Typography  componenet="p" variant="body2" >{setLanguageText(language, "Notes")}</Typography>,
      accessor: props => props.notes,
      Cell: ({ row, value }) => {
        //console.log(row.original.note_action)
        return (
          <>
            {row.original.note_action === 'C' &&
              <Alert style={{ width: '100%' }} severity="error"><strong>{setLanguageText(language, "Details")}:</strong> {row.original.notes}</Alert>
            }
            {row.original.note_action === 'N' &&
              <Alert style={{ width: '100%' }} severity="success"><strong>{setLanguageText(language, "Details")}:</strong> {row.original.notes}</Alert>
            }
            {row.original.note_action === 'Y' &&
              <Alert style={{ width: '100%' }} severity="warning"><strong>{setLanguageText(language, "Details")}:</strong> {row.original.notes}</Alert>
            }
          </>
        )
      }
      //Cell: ({ row, value }) => <Typography variant="body1">{value}</Typography>
    }
  ];

  return columns;
};

export default service_table_columns;
