import React, { useContext, useState, useEffect } from 'react';
import {
    Grid,
    Typography,
    Divider,
} from '@mui/material';
import { buttonStyles } from '../../../styles/buttonStyles';
import { UserContext } from '../../../store/UserContext';
import { faEdit, faPlus, faTimes } from '@fortawesome/pro-light-svg-icons';
import { ProductsContext } from '../../../store/ProductsContext';
import { SAVE_SELECTED_PRODUCT, UPDATE_PRODUCT_OPTIONS } from '../../../store/ProductsReducers';
import { Alert } from '@mui/material';
import { CompanyContext } from '../../../store/CompanyContext';
import useAsync from '../../../Hooks/useAsync';
import axiosRequest from '../../../helpers/axiosRequest';
import LoadingButton from '../../buttons/LoadingButton';


const ProductsList = ({
    product,
    openEditDialog,
    loading_vis
}) => {

    const { authUser } = useContext(UserContext);
    const { selectedCompany } = useContext(CompanyContext);
    const { dispatchSelectedProduct, productsOptions , dispatchProductsOptions } = useContext(ProductsContext);

    //****** STATES ******
    const [visible, setVisible] = useState();

    //****** STYLES ******
    const buttonStyle = buttonStyles();

    //set if visible to company
    useEffect(() => {
        if (productsOptions.visible) {
            //console.log(productsOptions.visible)
            const index = productsOptions.visible.findIndex(
                coolant => coolant.coolant_id === product.coolant_id
            );
            //console.log(index)
            if (index > -1) {
                setVisible(true);
            } else {
                setVisible(false);
            }
        }
    }, [
        productsOptions.visible,
        product
    ]);

    const {
        loading,
        executeAsync
    } = useAsync({
        axiosRequest: axiosRequest
    });

    const updateToggle = (product) => {
        //update the datbase
        //console.log(product)
        setVisible(!visible)
        executeAsync({
            method: 'patch',
            api_key: authUser.api_key,
            endpoint: `coolants`,
            body: {
                visible: !visible ? 'add' : 'remove',
                company_id: selectedCompany.company_id,
                coolant_id: product.coolant_id
            }
        });
        if (visible) {
            console.log('remove')
            
            dispatchProductsOptions({
                type: UPDATE_PRODUCT_OPTIONS,
                payload: { 'visible': productsOptions.visible.filter(visible => visible.coolant_id !== product.coolant_id) }
            });

        } else {
            console.log('add')
            dispatchProductsOptions({
                type: UPDATE_PRODUCT_OPTIONS,
                payload: { 'visible': [...productsOptions.visible, { company_id: selectedCompany.company_id, coolant_id: product.coolant_id } ]}
            });
        }
    };

    //****** RENDER ******
    return (
        <>
            <Grid
                item
                container
                justifyContent="center"
                alignContent="center"
                alignItems="center"
                spacing={1}
                style={{ padding: '5px' }}
            >

                <Grid item xs={6} md={2} >
                    <Typography variant="h6">{product.coolant_name}</Typography>
                </Grid>
                <Grid item xs={6} md={2} >
                    <Typography>{product.coolant_defaults.coolant.manufacturer}</Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={7}
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    alignContent="center"
                    spacing={2}
                >
                    {!loading_vis ?
                        <>
                            <Grid item xs={6} md={2} >
                                <LoadingButton
                                    fullWidth
                                    variant="contained"
                                    className={visible ? buttonStyle.danger : buttonStyle.success}
                                    onClick={() => updateToggle(product)}
                                    icon={visible ? faTimes : faPlus}
                                    text={visible ? 'Hide' : 'Show'}
                                    loading={loading}
                                />
                            </Grid>
                            <Grid item xs={6} md={10} >

                                <Alert
                                    severity={visible ? 'success' : 'error'}
                                    style={{ padding: '0 5px' }}
                                >
                                    {visible ? `Visible for ${selectedCompany.company_name}` : `Hidden for ${selectedCompany.company_name}`}
                                </Alert>

                            </Grid>
                        </>
                        :
                        <Alert severity="info">
                            Fetching Company Settings
                    </Alert>
                    }
                </Grid>
                <Grid item xs={12} md={1} >
                    <LoadingButton
                        fullWidth
                        icon={faEdit}
                        text="Edit"
                        textVariant="caption"
                        onClick={() => {
                            dispatchSelectedProduct({
                                type: SAVE_SELECTED_PRODUCT,
                                payload: product
                            });
                            openEditDialog();
                        }}
                    />
                </Grid>
            </Grid>
            <Divider light style={{ width: '100%', marginBottom: '0' }} />
        </>
    );
};

export default ProductsList;
