import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography, MenuItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { forwardRef } from 'react';
//import { isMobileOnly } from 'react-device-detect';
import { Link, useLocation } from 'react-router-dom';
import setLanguageText from '../../../helpers/setLanguageText';
import { buttonStyles } from '../../../styles/buttonStyles';

const useStyles = makeStyles(theme => ({
  menuItem: {
    padding: `${theme.spacing(1)} ${theme.spacing(4)} ${theme.spacing(1)} ${theme.spacing(4)}`
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    display: 'flex'
  },
  activeTab: {
    backgroundColor: theme.palette.primary.main,
    color: '#ffffff'
  }
}));
// Item need to use forwardRef in order to not return React warning
const Item = forwardRef(({ title, path, icon, handleClose, language }, ref) => {
  const buttonStyle = buttonStyles();
  const classes = useStyles();
  const pathname = useLocation().pathname;

  // const path = useMemo(() => {
  //   if (isMobileOnly) return `/${title.replace(/\s/g, '-').toLowerCase()}`;
  //   return title === 'Dashboard'
  //     ? '/'
  //     : `/${title.replace(/\s/g, '-').toLowerCase()}`;
  // }, [title]);
  
  return (
    <MenuItem
      onClick={handleClose}
      className={`${pathname === path ? classes.activeTab : null} ${
        classes.menuItem
      }`}
      ref={ref}
    >
      <Link to={path} className={classes.link}>
        <FontAwesomeIcon icon={icon} size="2x" />
        <Typography variant="h5" className={buttonStyle.buttonIconText}>
          {/* {title === 'Companies' ? `All ${title}` : title} */}
          {title ===  setLanguageText(language, "Companies") ? `${setLanguageText(language, "All")} ${title}` : title}
        </Typography>
      </Link>
    </MenuItem>
  );
});

export default Item;
