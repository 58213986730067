import React, { useContext } from 'react';
import {
    faTachometerAltSlowest,
    faCogs,
    faTint,
    faVial,
    faChartPie,
    faBook,
    faStethoscope,
    faMoneyCheckEdit,
    faScrewdriver,
    faVials,
} from '@fortawesome/pro-light-svg-icons';
import config from '../../config';
import { UserContext } from '../../store/UserContext';
import { styled, useTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DrawItem from './DrawItem';
import { Box, ThemeProvider } from '@mui/system';
import { Avatar, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import setLanguageText from '../../helpers/setLanguageText';
import { LayoutContext } from '../../store/LayoutContext';

const drawerWidth = 220;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(9)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(5.3),
    // necessary for content to be below app bar
    //...theme.mixins.toolbar,
    marginTop: theme.spacing(10),
}));


const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
        backgroundColor: theme.palette.primary.main,
    }),
);


const OpenIcon = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    backgroundColor: theme.palette.primary.main,
    zIndex: 100,
    right: '-15px',
    border: '1px solid #fff',
    color: '#fff',
    padding: 0,
    '&:hover': {
        backgroundColor: theme.palette.primary.main,
    }
}));

const SideBarDrawer = ({ sideBarOpen, setSideBarOpen }) => {

    const theme = useTheme();
    const { user } = useContext(UserContext);
    const { language } = useContext(LayoutContext);
    const handleDrawer = (sideBarOpen) => {
        setSideBarOpen(!sideBarOpen);
    };

    return (

        <ThemeProvider theme={theme}>
            <Drawer
                PaperProps={{
                    sx: {
                        flex: '0 0 auto',
                        backgroundColor: theme.palette.primary.main,
                        zIndex: 10,
                        overflow: 'visible!important'
                    }
                }}
                variant="permanent" open={sideBarOpen} >
                <DrawerHeader >
                    <OpenIcon onClick={e => handleDrawer(sideBarOpen)}>
                        {!sideBarOpen ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </OpenIcon>
                </DrawerHeader>
                <Divider />
                <Box
                sx={{
                    overflow: 'hidden',
                }} >
                <List style={{
                    width: '130%',
                    padding: '0 0 70px 0',
                    overflow: 'hidden auto',
                    height: '100%'
                }}>
                    <DrawItem color='primary' title={setLanguageText(language,'Dashboard')} url="" icon={faTachometerAltSlowest} isOpen={sideBarOpen} />
                    <DrawItem color='primary' title={setLanguageText(language,'Machines')} url="machines" icon={faCogs} isOpen={sideBarOpen} />
                    <DrawItem color='primary' title={setLanguageText(language,'Coolants')} url="coolants" icon={faTint} isOpen={sideBarOpen} />
                    <DrawItem color='primary' title={setLanguageText(language,'Products')} url="products" icon={faVials} isOpen={sideBarOpen} />
                    <DrawItem color='primary' title={setLanguageText(language,'Additives')} url="additives" icon={faVial} isOpen={sideBarOpen} />
                    <DrawItem color='primary' title={setLanguageText(language,'Reports')} url="reports" icon={faChartPie} isOpen={sideBarOpen} />
                    <DrawItem color='primary' title={setLanguageText(language,'Resources')} url="resources" icon={faBook} isOpen={sideBarOpen} />
                    {config.APPNAME === 'act' && user.level === 9 ?
                        <>
                            <DrawItem color='primary' title={setLanguageText(language,'Maintenance')} url="maintenance" icon={faScrewdriver} isOpen={sideBarOpen} />
                            <DrawItem color='primary' title={setLanguageText(language,'Diagnostic')} url="diagnostic" icon={faStethoscope} isOpen={sideBarOpen} />
                        </>
                        : config.APPNAME === 'cis' &&
                        <>
                            <DrawItem color='primary' title={setLanguageText(language,'Maintenance')} url="maintenance" icon={faScrewdriver} isOpen={sideBarOpen} />
                            <DrawItem color='primary' title={setLanguageText(language,'Diagnostic')} url="diagnostic" icon={faStethoscope} isOpen={sideBarOpen} />
                        </>
                    }
                    <DrawItem color='primary' title={setLanguageText(language,'Service')} url="service" icon={faMoneyCheckEdit} isOpen={sideBarOpen} />

                </List>
                </Box>
                {/* <Divider /> */}
                {/* <DrawItem color='secondary' title="My Account" url="account/0" icon={faUser} isOpen={sideBarOpen} /> */}
                <Grid sx={{
                    position: 'absolute',
                    bottom: '0',
                    width: '100%',
                    textAlign: 'center',
                    padding: '12px'
                }}>
                    <Avatar sx={{
                        width: theme.spacing(6),
                        height: theme.spacing(6),
                        backgroundColor: theme.palette.secondary.main,
                        border: `${theme.spacing(0.3)} solid ${theme.palette.background.light} `
                    }}>
                        <Link style={{
                            color: theme.palette.secondary.contrastText,
                            textDecoration: 'none'
                        }}
                            to="/account/0" >
                            <Typography variant="h4">{user.initial}</Typography>
                        </Link>
                    </Avatar>
                </Grid>
            </Drawer>
        </ThemeProvider>
    );
};

export default SideBarDrawer;

