import React, { useContext, useState, useEffect, useCallback } from 'react';
import { TextField } from '@mui/material';
import { UserContext } from '../../../store/UserContext';
import { CompanyContext } from '../../../store/CompanyContext';
import { MachineContext } from '../../../store/MachineContext';
import { AdditiveContext } from '../../../store/AdditiveContext';
import { CoolantContext } from '../../../store/CoolantContext';
import {
  SAVE_MACHINES,
  SAVE_GROUPS,
  SELECTED_MACHINE,
  SELECTED_MACHINES,
  SELECTED_GROUP,
  FILTER_MACHINES
} from '../../../store/MachineReducers';
import { SELECTED_COMPANY } from '../../../store/CompanyReducers';
// import {
//   RESET_ADDITIVES_IN_USE,
//   RESET_COOLANTS_IN_USE
// } from '../../../store/ProductsReducers';
import {
  RESET_ADDITIVES_IN_USE
} from '../../../store/AdditiveReducers';
import {
  RESET_COOLANTS_IN_USE
} from '../../../store/CoolantReducers';
import Autocomplete from '@mui/material/Autocomplete';
import { LayoutContext } from '../../../store/LayoutContext';
import { COMPANY_THEME } from '../../../store/LayoutReducers';
import useAsync from '../../../Hooks/useAsync';
import axiosRequest from '../../../helpers/axiosRequest';
import setLanguageText from '../../../helpers/setLanguageText';
import { Box } from '@mui/system';
//import removeAllOption from '../../../helpers/removeAllOption'

function DropdownCompanies({ fetchEntries, setLoading }) {
  //************************  CONTEXT ************************
  const { authUser, user } = useContext(UserContext);
  const { dispatchTheme,language } = useContext(LayoutContext);
  const { selectedCompany, dispatchSelectedCompany, companies } =
    useContext(CompanyContext);
  const {
    machines,
    dispatchNoMachines,
    dispatchFilteredMachines,
    dispatchMachines,
    dispatchGroups,
    dispatchSelectedMachine,
    dispatchSelectedMachines,
    dispatchSelectedGroup
  } = useContext(MachineContext);

  const { dispatchCoolantsInUse } = useContext(CoolantContext);
  const { dispatchAdditivesInUse } = useContext(AdditiveContext);

  //************************  STATE ************************
  // get save from session storage selected machine
  const [storedMachine] = useState(
    JSON.parse(window.sessionStorage.getItem('selectedMachine'))
  );
  //get save from session storage selected group
  const [storedGroup] = useState(
    JSON.parse(window.sessionStorage.getItem('selectedGroup'))
  );

  const { data, loading, executeAsync } = useAsync({
    axiosRequest: axiosRequest
  });

  const { 
    executeAsync : executeAsync_company
   } = useAsync({
    axiosRequest: axiosRequest
  });

  const [options, setOptions] = useState();

  //************************ USE CALLBACK ************************
  
  const fetchMachines = useCallback(
    async (endpoint, company_id) => {
      //console.log(user)
      let params =  {
        company_id
      }
      if(company_id === 0 && user.level !== 9 ){
        params =  {
          company_ids : JSON.stringify(user.companies_id)
        }
      }
      //console.log(params)
      executeAsync({
        api_key: authUser.api_key,
        method: 'get',
        endpoint,
        params: params
      });
    },
    [user, authUser.api_key, executeAsync]
  );

  //************************  USE EFFECT ************************
  // add the option "ALL" into the dropdown
  useEffect(() => {
    if (companies.length) {
        setOptions(companies);
    }
  }, [companies]);

  // ON RENDER
  useEffect(() => {
    if (setLoading) setLoading(loading);
    // The dropdown is also used in Products which doesn't need to fetchEntries
    if (fetchEntries) {
      // Wait for companies to being fetch first and for the selected company to be set
      if (!data && !loading) {
        if (companies.length && selectedCompany.company_id >= 0) {
          // if the machines array is empty
          fetchMachines('machines/entries', selectedCompany.company_id);
        }
      }
    }
  }, [
    companies.length,
    data,
    fetchEntries,
    fetchMachines,
    loading,
    machines,
    selectedCompany.company_id,
    setLoading
  ]);

  // console.log(machines);
  // console.log(data);

  useEffect(() => {
    if (data) {
      //console.log(data)
      // Machines fetched
      if(Object.values(data).length){
        dispatchNoMachines({ type: SAVE_MACHINES, payload: false });
      } else {
        dispatchNoMachines({ type: SAVE_MACHINES, payload: true });
      }
      dispatchMachines({ type: SAVE_MACHINES, payload: data });
      dispatchGroups({ type: SAVE_GROUPS, payload: data });
      if(!storedMachine){
        dispatchSelectedMachine({
          type: SELECTED_MACHINE,
          payload: { machine_id: 0, machine_name: setLanguageText(language, "All Machines") }
        });
        dispatchFilteredMachines({
          type: FILTER_MACHINES,
          payload: data
        });
      }
      if(!storedGroup){
          dispatchSelectedGroup({
          type: SELECTED_GROUP,
          payload:{ group_id: 0, group_name: setLanguageText(language, "All Groups") }
        });
      }
    }
  }, [
    data,
    dispatchNoMachines,
    dispatchFilteredMachines,
    dispatchGroups,
    dispatchMachines,
    dispatchSelectedGroup,
    dispatchSelectedMachine,
    storedMachine,
    storedGroup
  ]);
  
  //************************  FUNCTIONS ************************
  // When user selects company from dropdown
  const onSelectCompany = async company => {
    if (company) {
      if (fetchEntries) {
        // If the selected company is not 'ALL'
        // if (company.company_id) {
        // fetch machines with SELECTED COMPANY ID
        await fetchMachines(
          'machines/entries', 
          company.company_id,
          user
        );
        dispatchSelectedMachines({
          type: SELECTED_MACHINES,
          payload: []
        });
        // }
      }
      // RESET THE MACHINES IN USE
      // By resetting the Coolants in use we avoid to fetch twice the coolants when page render
      dispatchCoolantsInUse({ type: RESET_COOLANTS_IN_USE });
      dispatchAdditivesInUse({ type: RESET_ADDITIVES_IN_USE });
      
      // Save the selected Company despite fetchEntries
      // dispatchSelectedCompany({
      //   type: SELECTED_COMPANY,
      //   payload: company
      // });
      //update session storage
     // window.sessionStorage.setItem('selectedCompany',JSON.stringify(company));
     let result
     if(company.company_id !== 0 ){
        // fetch if its a real company
        result = await executeAsync_company({
          api_key: authUser.api_key,
          method: 'get',
          endpoint: 'companies',
          params: {
            user_level: user.level,
            id: company.company_id
          }
        });
      } else {
        //if the user selects all company, use thier compnay theme,attributes..etc as default
        let userCompany = await executeAsync_company({
          api_key: authUser.api_key,
          method: 'get',
          endpoint: 'companies',
          params: {
            user_level: user.level,
            id: user.company_id
          }
        }); 
        result = [{
          ...userCompany[0],
          company_id : company.company_id,
          company_name : company.company_name,
        }]

      }

      //console.log(result)

      dispatchSelectedCompany({
        type: SELECTED_COMPANY,
        payload: result[0]
      });
      window.sessionStorage.setItem('selectedCompany',JSON.stringify(result[0]));
      window.sessionStorage.setItem('selectedGroup',JSON.stringify({ group_name:  setLanguageText(language, "All Groups"), group_id: 0 }));
      window.sessionStorage.setItem('selectedMachine',JSON.stringify({ machine_id: 0, machine_name:  setLanguageText(language, "All Machines") }))

      //reset Groups
      dispatchSelectedGroup({
        type: SELECTED_GROUP,
        payload: { group_name:  setLanguageText(language, "All Groups"), group_id: 0 }
      });
      //reset machine
      dispatchSelectedMachine({
         type: SELECTED_MACHINE,
         payload: { machine_name: setLanguageText(language, "All Machines"), machine_id: 0 }
      });
    }
  };

  //update theme
  useEffect(() => {
    if (selectedCompany) {
      dispatchTheme({
        type: COMPANY_THEME,
        payload: selectedCompany.theme_settings
      });
    }
  }, [dispatchTheme, selectedCompany]);

  return <>
    <Autocomplete
      disableClearable
      id="selected-company"
      value={Object.entries(selectedCompany).length ? selectedCompany : null}
      options={options || []}
      loading={options ? false : true}
      getOptionLabel={option => option.company_name}
      isOptionEqualToValue={(option, value) =>
        option.company_id === value.company_id
      }
      renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={option.company_id}>
           { option.company_name}
        </Box>
    )}
      onChange={(e, newValue) => {
        onSelectCompany(newValue);
      }}
      renderInput={params => (
        <TextField {...params} variant="outlined" label={setLanguageText(language,"Selected Company")} />
      )}
    />
  </>;
}

export default DropdownCompanies;


