import React, { useContext, useEffect, useState } from 'react';
import PageContent from '../PageContent';
import { Grid, Typography, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PageHeader from '../PageHeader';
import { CompanyContext } from '../../store/CompanyContext';
import ImageUpload from '../reusable/ImageUpload';
import useAsync from '../../Hooks/useAsync';
import axiosRequest from '../../helpers/axiosRequest';
import { UserContext } from '../../store/UserContext';
import { faSave } from '@fortawesome/pro-light-svg-icons';
import LoadingButton from '../buttons/LoadingButton';
import OutlinedDiv from '../reusable/OutlinedDiv';
import { Storage } from 'aws-amplify';
import config from '../../config';
import { SELECTED_COMPANY } from '../../store/CompanyReducers';
import setLanguageText from '../../helpers/setLanguageText';
import { LayoutContext } from '../../store/LayoutContext';

const useStyles = makeStyles(theme => ({
  card: {
    width: '100%'
  },
  header: {
    borderBottom: `${theme.spacing(0.4)} solid ${
      theme.palette.background.light
    }`,
    padding: theme.spacing(1)
  }
}));

const CompanyDetails = ({edit}) => {
  //************  STYLES ************

  const classes = useStyles();

  //************  CONTEXT ************
  const { language } = useContext(LayoutContext);  
  const { authUser, user } = useContext(UserContext);
  const { selectedCompany, dispatchSelectedCompany } = useContext(CompanyContext);
  const [company, setCompany] = useState();
  const [logo, setLogo] = useState();
  
  const {
    //error,
    //data,
    loading,
    //logCompleted,
    //logError,
    executeAsync,
    executeLog,
    setData
  } = useAsync({
    axiosRequest: axiosRequest
  });

  //************ STATES ************

  //************  USE EFFECT ************

  useEffect(() => {
    if (Object.values(selectedCompany).length) {
      //console.log(selectedCompany);
      setCompany(selectedCompany);
    }
  }, [selectedCompany]);

  //************ FUNCTIONS ************

  const setCompanyLogo = e => {
    setLogo(e);
  };

  const setCompanyName = e => {
    e.persist();
    setCompany(prevState => ({
      ...prevState,
      company_name: e.target.value
    }));
  };

  const handleSubmit = async e => {
    console.log('save edit');
    e.preventDefault();
    let body = {
      company_id: company.company_id,
      company_name: company.company_name
    };
    //add logo if new one has been uploaded

    let type;

    if (logo) {
      console.log(logo)
      if(logo.type === 'image/png'){
        type = 'png'
      } else if (logo.type === 'image/jpeg') {
        type = 'jpg'
      }
      body.company_logo = `${company.company_id}_logo.${type}`;
    }
    
    await executeAsync({
      endpoint: 'company',
      api_key: authUser.api_key,
      method: 'patch',
      body: body
    });

    if (logo) {
      try {
        await Storage.put(`company/${body.company_logo}`, logo, {
          ACL: 'public-read',
          contentType: logo.type,
          visibility: 'public',
          level: 'public'
        });
      } catch (err) {
        console.log(err);
      }
    }
    await executeLog({
      endpoint: 'activity',
      api_key: authUser.api_key,
      body: {
        attributes: {
          type: 'Edit Company',
          user_id: user.id,
          company_id: company.company_id
        }
      }
    });
    let updateLogo = {
      ...selectedCompany,
      company_name :  company.company_name,
      company_logo_path :  body.company_logo 
    }
    dispatchSelectedCompany({
        type: SELECTED_COMPANY,
        payload: updateLogo
    });

    //setTimeout(() => {
      //reset data from aysnc
      setData();
      setLogo();
    //}, 1000);
    //re fetch brands
  };

  return (
    <>
      <PageHeader>
        <Grid item xs={12}>
          <Grid
            item
            container
            justifyContent="space-between"
            className={classes.header}
          >
            <Grid item xs={12} sm={'auto'}>
              <Typography variant="h5">{setLanguageText(language,"Company Details")}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </PageHeader>
      <PageContent className="ApplicationDetails">
        {company ? (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                disabled={!edit}
                fullWidth
                InputLabelProps={{
                  shrink: company.company_name ? true : false
                }}
                name="company_name"
               label={setLanguageText(language,"Company Name")}
                variant="outlined"
                value={company.company_name}
                onChange={e => setCompanyName(e)}
              />
            </Grid>
            <Grid item container xs={12}>
              <OutlinedDiv label={setLanguageText(language,"Company Logo")}>
                <ImageUpload
                  id="formatted-text-mask-input"
                  path={
                    company.company_logo_path
                      ? `https://${config.s3.BUCKET}.s3.${config.s3.REGION}.amazonaws.com/public/company/${company.company_logo_path}`
                      : null
                  }
                  setImage={setCompanyLogo}
                  edit={edit}
                />
              </OutlinedDiv>
            </Grid>
            <Grid item container xs={12}>
              <Grid item>
                <LoadingButton
                  disabled={!edit}
                  icon={faSave}
                  text="Update Company Details"
                  iconSize="1x"
                  color="primary"
                  loading={loading}
                  onClick={e => handleSubmit(e)}
                />
              </Grid>
            </Grid>
          </Grid>
        ) : null}
      </PageContent>
    </>
  );
};

export default CompanyDetails;
