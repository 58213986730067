import React from 'react';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme
} from '@mui/material';
import { usePagination, useTable } from 'react-table';

const TableBasic = ({ columns, data }) => {
  console.log(data)
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page // Instead of using 'rows', we'll use page,
    // The rest of these things are super handy, too ;)
    //canPreviousPage,
    //canNextPage,
    //pageCount,
    //gotoPage,
    //nextPage,
    //previousPage,
    //setPageSize,
    //state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, hiddenColumns: ['out_of_control'] }
    },
    usePagination
  );

  const theme = useTheme();

  return (
    // apply the table props
    <Grid style={{ maxWidth: '100%' }}>
      <Table
        {...getTableProps()}
        style={{
          borderTop: '1px solid #ccc',
          borderLeft: '1px solid #ccc'
        }}
      >
        <TableHead>
          {
            // Loop over the header rows
            headerGroups.map(headerGroup => (
              // Apply the header row props
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map(column => {
                    if (column.hidden) {
                      column.toggleHidden(true);
                    }

                    return (
                      // Apply the header cell props

                      <TableCell
                        {...column.getHeaderProps()}
                        style={{
                          textAlign: 'center',
                          backgroundColor: theme.palette.background.light,
                          borderRight: '1px solid #ccc',
                          padding: theme.spacing(1, 2)
                        }}
                      >
                        {
                          // Render the header
                          column.render('Header')
                        }
                      </TableCell>
                    );
                  })
                }
              </TableRow>
            ))
          }
        </TableHead>
        {/* Apply the table body props */}
        <TableBody {...getTableBodyProps()}>
          {
            // Loop over the table rows
            page.map((row, i) => {
              // Prepare the row for display
              prepareRow(row);
              return (
                // Apply the row props
                <TableRow
                  {...row.getRowProps()}
                  style={{ backgroundColor: '#ffffff' }}
                >
                  {
                    // Loop over the rows cells
                    row.cells.map(cell => {
                      // Apply the cell props
                      return (
                        <TableCell
                          {...cell.getCellProps()}
                          style={{
                            textAlign: 'center',
                            padding: theme.spacing(1, 2),
                            borderRight: '1px solid #ccc'
                          }}
                        >
                          {
                            // Render the cell contents
                            cell.render('Cell')
                          }
                        </TableCell>
                      );
                    })
                  }
                </TableRow>
              );
            })
          }
        </TableBody>
      </Table>
    </Grid>
  );
};

export default TableBasic;
