import React from 'react';
import { Card, CardContent, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Defaults from '../../Products/Coolants/Defaults';
import CoolantPDF from '../../Products/Coolants/CoolantPDF';


const useStyles = makeStyles(theme => ({
  card: {
    height: '100%',
    width: '100%',
    boxShadow: 'none'
  },
  cardContainer: { height: '100%' },
  cardContent: {
    flex: 1
  },
  tab: {
    minWidth: 'auto'
  },
  li: {
    display: 'inline'
  },
  cardFooter: {
    padding: theme.spacing(1),
    borderTop: `1px solid ${theme.palette.background.light}`,
    background: `${theme.palette.background.light}`
  },
  dialog: {
    height: 'calc(100% - 64px)'
  },
  leftButton: {
    paddingLeft: '4px'
  },
  rightButton: {
    paddingRight: '4px'
  }
}));

const CoolantDefaults = ({ coolant }) => {
  //****** STATES ******

  //****** STYLES ******
  const classes = useStyles();

  //****** USE EFFECTS ******

  //****** FUNCTIONS ******


  //****** RENDER ******
  return (
    <>
      <Card className={classes.card}>
        <Grid container direction="column" className={classes.cardContainer}>
          <CardContent justify="center" className={classes.cardContent}>
            <Defaults coolant={coolant} />
          </CardContent>
          <Grid
            item
            container
            justifyContent="space-around"
            className={classes.cardFooter}
          >
            <CoolantPDF coolant={coolant}/>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default CoolantDefaults;
