import React, { useMemo, useState, useEffect, useCallback, useContext } from 'react';
import config from './../../config';
import UserTable from './UserTable';
import {
  Grid,
  Typography,
  Button
} from '@mui/material';
import { buttonStyles } from '../../styles/buttonStyles';
import Alert from '@mui/material/Alert';
import cognitoPromise from '../../helpers/cognitoPromise';
import create_usersTable_columns from './create_usersTable_columns';
import useAsync from '../../Hooks/useAsync';
import PageContent from '../PageContent';
import PageHeader from '../PageHeader';
import AddUser from '../Users/AddUser';
import {
  faPlus
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@mui/styles';
import LoadingScreen from './../reusable/LoadingScreen';
import EditUser from './EditUser';
import axiosRequest from '../../helpers/axiosRequest';
import SelectionRow from '../Dashboard/Selection_Row/SelectionRow';
import { UserContext } from '../../store/UserContext';
import setLanguageText from '../../helpers/setLanguageText';
import { LayoutContext } from '../../store/LayoutContext';

const useStyles = makeStyles(theme => ({
  card: {
    width: '100%'
  },
  header: {
    borderBottom: `${theme.spacing(0.4)} solid ${theme.palette.background.light
      }`,
    padding: theme.spacing(1)
  },
  cell: {
    textAlign: 'left',
    whiteSpace: 'nowrap'
  }
}));

const Users = () => {
  //****** STYLES ******
  const buttonStyle = buttonStyles();
  const classes = useStyles();
  const { user: current_user, authUser } = useContext(UserContext)
  const { language } = useContext(LayoutContext)

  //Component state
  const [userOpen, setUserOpen] = useState(false);
  const [usersList, setUsersList] = useState([]);
  //const [usersPromises, setUsersPromises] = useState([]);
  //const [cognitoGroups, setCognitoGroups] = useState([]);
  // const [error, setError] = useState(null);
  const [editShow, setEditShow] = useState(false);
  const [user, setUser] = useState(false);
  const [cognitoError, setCognitoError] = useState(null);
  //const [isEdit, setIsEdit] = useState(false);
  const {
    error,
    loading,
    executeAsync
  } = useAsync({
    axiosRequest: axiosRequest
  });

  //********************* FUNCTIONS *********************

  // const getCognitoGroups = useCallback(async () => {
  //   try {
  //     const userParams = {
  //       UserPoolId: config.cognito.USER_POOL_ID // required
  //     };
  //     //* GET GROUPS
  //     const { Groups } = await cognitoPromise({
  //       params: userParams,
  //       fn: 'listGroups'
  //     });
  //     setCognitoGroups(Groups);
  //   } catch (err) {
  //     setCognitoError({ result: 'error', message: err.message });
  //   }
  // }, []);

  // const getCognitoUsers = useCallback(async cognito_groups => {
  //   const users_promises = cognito_groups.map(async cognito_group => {
  //     const groupParams = {
  //       GroupName: cognito_group.GroupName, // required
  //       UserPoolId: config.cognito.USER_POOL_ID // required
  //     };
  //     try {
  //       //* GET GROUP USERS
  //       const { Users } = await cognitoPromise({
  //         params: groupParams,
  //         fn: 'listUsersInGroup'
  //       });
  //       const add_groupName = Users.map(cognito_user => {
  //         return {
  //           ...cognito_user,
  //           GroupName: cognito_group.GroupName,
  //           // extract email from Attributes array
  //           email: cognito_user.Attributes.find(
  //             attribute => attribute.Name === 'email'
  //           ).Value
  //         };
  //       });
  //       // setCognitoUsers(prevState => [...prevState, ...add_groupName]);
  //       return add_groupName;
  //     } catch (err) {
  //       setCognitoError({ result: 'error', message: err.message });
  //     }
  //   });
  //   return users_promises;
  // }, []);


  function getUserList(token, users = []) {
    return new Promise((resolve, reject) =>
      getCognitoUsers(token).then(data => {
        users = [...users, ...data.Users];
        if (data.PaginationToken) {
          getUserList(data.PaginationToken, users).then(resolve).catch(reject)
        } else {
          resolve(users);
        }
      }).catch(reject));
  }

  const getCognitoUsers = useCallback(async (token) => {
    let userParams = {
      UserPoolId: config.cognito.USER_POOL_ID // required
    };
    if (token) {
      userParams = {
        ...userParams,
        PaginationToken: token
      }
    }
    try {
      //* GET GROUP USERS
      const data = await cognitoPromise({
        params: userParams,
        fn: 'listUsers'
      });
      return data

    } catch (err) {
      setCognitoError({ result: 'error', message: err.message });
    }

  }, []);

  const getDatabaseUsers = useCallback(
    async () => {
      //console.log('fetch users')
      const db_users = await executeAsync({
        method: 'get',
        api_key: authUser.api_key,
        endpoint: `users`,
        params: {
          user_level: current_user.level,
          company_id: current_user.company_id
        }
      })
      //console.log(current_user)
      // cognito_users_promises.forEach(async promise => {
      //   const cognito_users = await promise;
      //   cognito_users.forEach(cognito_user => {
      //     const found_user = db_users.find(db_user => {
      //       return db_user.email === cognito_user.email;
      //     });
      //     const new_user = { ...found_user, ...cognito_user };
      //     setUsersList(prevState => [...prevState, new_user]);
      //     //let sorted = userList.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
      //   });
      getUserList().then(congintoUsers => {
        // all planets have been loaded
        //console.log(congintoUsers)
        //const congintoUsers = await getCognitoUsers();
        //console.log('here')
        //console.log(congintoUsers)

        let users = []
        db_users.forEach((user) => {
          //console.log(user)
          users.push({
            ...user,
            ...((congintoUsers).find((item) => {
              let email = (item.Attributes).find((item) => item.Name === 'email');
              //console.log(email.Value ===  user.email)
              if (email.Value === user.email) {
                return item
              }
              return false;
            }))
          });
        });
        console.log(users)
        setUsersList(users)
        //});
      }).catch(console.error);

    },
    [
      getCognitoUsers,
      executeAsync,
      setUsersList,
      current_user,
      authUser
    ]
  );

  // //GET COGNITO GROUPS
  // useEffect(() => {
  //   if (!usersList.length && AWS?.config?.credentials?.accessKeyId) {
  //     getCognitoGroups();
  //   }
  // }, [AWS.config.credentials, getCognitoGroups, usersList]);

  // GET COGNITO USERS
  // useEffect(() => {
  //   if (!usersList.length && cognitoGroups.length) {
  //     getCognitoUsers(cognitoGroups).then(promises =>
  //       setUsersPromises(promises)
  //     );
  //   }
  // }, [cognitoGroups, getCognitoUsers, usersList]);

  useEffect(() => {
    if (!usersList.length) {
      getDatabaseUsers();
    }
  }, [getDatabaseUsers, usersList]);

  const reFetchUsers = () => {
    //setCognitoGroups([]);
    //setUsersPromises([]);
    setUsersList([]);
  }

  //open edit and set user
  const handleEdit = (row) => {
    setUser(row);
    setEditShow(true);
    //setIsEdit(true);
  }

  //open edit and set user
  const handleCreate = (row) => {
    setUser(row);
    setUserOpen(true);
  }

  const columns = useMemo(() => create_usersTable_columns({ handleEdit, handleCreate, classes, language }), [classes]);

  return (
    <>
      <PageHeader>
        <SelectionRow />
        <Grid item xs={12}>
          <Grid
            item
            container
            justifyContent="space-between"
            className={classes.header}
          >
            <Grid item xs={12} sm={'auto'}>
              <Typography variant="h5">{setLanguageText(language,"All Users")}</Typography>
            </Grid>

            <Grid item xs={12} sm={'auto'}>
              <Button
                fullWidth
                onClick={e => setUserOpen(true)}
                variant="contained"
                color="primary"
                className={buttonStyle.fixPointerEvent}
              >
                <FontAwesomeIcon icon={faPlus} />
                <Typography className={buttonStyle.buttonIconText}>
                {setLanguageText(language,"Add User")}
                </Typography>
              </Button>
            </Grid>
          </Grid>
          <AddUser 
          closeDialog={e => {
            //reFetchUsers();    
            setUserOpen(false);
          }} 
          open={userOpen} 
          createUser={user} />
        </Grid>
      </PageHeader>
      <PageContent className="users" >
        <LoadingScreen
          loading={!loading}
          background="#fff"
          colour="#666"
          text="Loading Users"
        />
        <Grid container spacing={1}>

          {error ||
            (cognitoError && (
              <Grid item>
                <Alert severity="error">
                  {error?.message || cognitoError.message}
                </Alert>
              </Grid>
            ))}

          {usersList.length && !loading && !cognitoError ? (
            <Grid item xs={12}>
              <UserTable columns={columns} data={usersList} />
            </Grid>
          ) : null}

          <EditUser closeDialog={e => {
            reFetchUsers();
            setEditShow(false);
          }}
            open={editShow} user={user} />
        </Grid>
      </PageContent>
    </>
  );
};

export default Users;
