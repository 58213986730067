import {
  faCog,
  faMoneyCheckEdit,
  faPencilRuler,
  faQrcode,
  faTint,
  faVial,
  faVials
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { buttonStyles } from '../../styles/buttonStyles';
import SelectionRow from '../Dashboard/Selection_Row/SelectionRow';
import AddEntry from '../Entry/AddEntry';
import PageContent from '../PageContent';
import Additives from './Additives';
import Coolants from './Coolants';
import Products from './Products';
import Machines from './Machines';
import { ThemeProvider, useTheme } from '@mui/system';
import setLanguageText from '../../helpers/setLanguageText';
import { LayoutContext } from '../../store/LayoutContext';

const useStyles = makeStyles(theme => ({
  button: {
    padding: theme.spacing(2)
  }
}));

const MobileToggle = () => {

  const { language } = useContext(LayoutContext)
  const buttonStyle = buttonStyles();
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();

  const [addEntry, setAddEntry] = useState(false);
  const [coolants, setCoolants] = useState(false);
  const [additives, setAdditives] = useState(false);
  const [machines, setMachines] = useState(false);
  const [products, setProducts] = useState(false);

  const handleAddEntry = () => {
    setAddEntry(true);
  };

  const handleCloseDialog = () => setAddEntry(false);

  const openProducts = () => {
    setProducts(true);
  };
  const closeProducts = () => {
    setProducts(false);
  };

  const openCoolants = () => {
    setCoolants(true);
  };
  const closeCoolants = () => {
    setCoolants(false);
  };

  const openAdditives = () => {
    setAdditives(true);
  };
  const closeAdditives = () => {
    setAdditives(false);
  };

  const openMachines = () => {
    setMachines(true);
  };
  const closeMachines = () => {
    setMachines(false);
  };

  const handleService = () => {
    history.push('/service');
  };

  return (
    <>
      <ThemeProvider theme={theme} >
      <PageContent>
        <Grid container spacing={1} >
          <Grid item xs={6}>
            <Link to="/qr-code" className={buttonStyle.buttonText}>
              <Button fullWidth className={classes.button} variant="contained" color="secondary">
                <Grid container>
                  <Grid item xs={12} >
                    <FontAwesomeIcon icon={faQrcode} size="2x" />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" >{setLanguageText(language,"Scan QR")}</Typography>
                  </Grid>
                </Grid>
              </Button>
            </Link>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleAddEntry}
              className={classes.button}
            >
              <Grid container>
                  <Grid item xs={12} >
                    <FontAwesomeIcon icon={faPencilRuler} size="2x" />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" >{setLanguageText(language,"Add Entry")}</Typography>
                  </Grid>
                </Grid>
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={openCoolants}
              className={classes.button}
            >
                <Grid container>
                  <Grid item xs={12} >
                    <FontAwesomeIcon icon={faTint} size="2x" />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" >{setLanguageText(language,"Coolant Search")}</Typography>
                  </Grid>
                </Grid>
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={openAdditives}
              className={classes.button}
            >
              <Grid container>
                  <Grid item xs={12} >
                    <FontAwesomeIcon icon={faVial} size="2x" />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" >{setLanguageText(language,"Additive Search")}</Typography>
                  </Grid>
                </Grid>
            </Button>
          </Grid>


          <Grid item xs={6} >
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={openMachines}
              className={classes.button}
            >
              <Grid container>
                  <Grid item xs={12} >
                    <FontAwesomeIcon icon={faCog} size="2x" />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" >{setLanguageText(language,"Machine Search")}</Typography>
                  </Grid>
                </Grid>
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={openProducts}
              className={classes.button}
            >
                <Grid container>
                  <Grid item xs={12} >
                    <FontAwesomeIcon icon={faVials} size="2x" />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" >{setLanguageText(language,"Products Search")}</Typography>
                  </Grid>
                </Grid>
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleService}
              className={classes.button}
            >
              <Grid container>
                  <Grid item xs={12} >
                    <FontAwesomeIcon icon={faMoneyCheckEdit} size="2x" />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" >{setLanguageText(language,"Create Service Report")}</Typography>
                  </Grid>
                </Grid>
            </Button>
          </Grid>
        </Grid>
      </PageContent>
      <SelectionRow/>
      <AddEntry isDialog={addEntry} setIsDialog={handleCloseDialog} />
      <Coolants isOpen={coolants} closeDialog={closeCoolants} />
      <Products isOpen={products} closeDialog={closeProducts} />
      <Additives isOpen={additives} closeDialog={closeAdditives} />
      <Machines isOpen={machines} closeDialog={closeMachines} />
      </ThemeProvider>
    </>
  );
};

export default MobileToggle;
