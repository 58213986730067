import axiosRequest from './axiosRequest';

// Check user level and fetch companies accordingly
export const fetchAdditives = ({ api_key, company_id , company_ids}) => {
  //console.log(company_id)
  if (company_ids) {
    return axiosRequest({
      api_key,
      method: 'get',
      endpoint: 'additives',
      params: {
        company_ids
      }
    });
  }
  if (typeof company_id === 'number') {
    return axiosRequest({
      api_key,
      method: 'get',
      endpoint: 'additives',
      params: {
        company_id
      }
    });
  }
  return axiosRequest({
    api_key,
    method: 'get',
    endpoint: 'additives'
  });
};
