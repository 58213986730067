import { Grid, Paper, Typography } from '@mui/material';
import React from 'react';
import { buttonStyles } from '../../../styles/buttonStyles';

const CardCell = ({ children, title, icon }) => {
  const buttonStyle = buttonStyles();
  return (
    <Paper className={`${buttonStyle.box}`} variant="outlined">
      <Grid container direction="column">
        <Grid item container justifyContent="center" alignItems="center">
          {icon && icon}
          <Typography align="center" variant="body2">
            {`${title}:`}
          </Typography>
        </Grid>
        <Grid item container justifyContent="center">
          {children}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CardCell;
