import { Card, CardContent, Grid, Typography, useTheme } from '@mui/material';
import React, { useContext } from 'react';
import { Pie } from 'react-chartjs-2';
import createPercentage from '../../../helpers/createPercentage';
import setLanguageText from '../../../helpers/setLanguageText';
import { LayoutContext } from '../../../store/LayoutContext';
import CardStyles from '../../../styles/cardStyles';

function PieChart({ results }) {

  const cardStyles = CardStyles();
  const theme = useTheme();
  const { language } = useContext(LayoutContext)

  let data

  if (results.data.in) {
     data = {
      datasets: [
        {
          data: [
            results.data.in.count,
            results.data.out.count,
            results.data.edge.count,
            results.data.none.count,
            results.data.no_entries.count
          ],
          backgroundColor: [
            results.data.in.colour,
            results.data.out.colour,
            results.data.edge.colour,
            results.data.none.colour,
            results.data.no_entries.colour
          ]
        }
      ],
      labels: [
        results.data.in.label,
        results.data.out.label,
        results.data.edge.label,
        results.data.none.label,
        results.data.no_entries.label
      ]
    };
  } else {
    data = {
      datasets: [
        {
          data:
            results.data.map((item) => 
              item.count
            ),
          backgroundColor:
            results.data.map((item) => 
             item.colour
          )
        }
      ],
      labels:
        results.data.map((item) => 
          item.label
        )
    };

  }

  // const data = {
  //   datasets: [
  //     {
  //       data: [
  //         results.data.in.count,
  //         results.data.out.count,
  //         results.data.edge.count,
  //         results.data.none.count,
  //         results.data.no_entries.count
  //       ],
  //       backgroundColor: [
  //         results.data.in.colour,
  //         results.data.out.colour,
  //         results.data.edge.colour,
  //         results.data.none.colour,
  //         results.data.no_entries.colour
  //       ]
  //     }
  //   ],
  //   labels: [
  //     results.data.in.label,
  //     results.data.out.label,
  //     results.data.edge.label,
  //     results.data.none.label,
  //     results.data.no_entries.label
  //   ]
  // };
  const options = {
    legend: {
      display: false
    },
    title: {
      display: false
    }
  };

  return (
    <Card style={{ height: '100%' }}>
      <Grid
        item
        container
        className={cardStyles.header}
        justifyContent="space-between"
      >
        <Typography variant="h5">{results.name}</Typography>
      </Grid>
      <CardContent>
        <Pie data={data} options={options} />
      </CardContent>
      <Grid
        item
        container
        justifyContent="space-between"
        className={cardStyles.footer}
      >
        <Grid item container xs={4} justifyContent="center">
          <Typography variant="body2">
            <span style={{ borderBottom: `1px dashed ${results?.data?.out?.colour}` }} >{setLanguageText(language,"Out")}</span>:</Typography>
          <Typography
            variant="body2"
            align="center"
            style={{ marginLeft: theme.spacing(1) }}
          >
            {/* avoid returning NaN when loading */}
            <strong>
              {results.data.out.count && createPercentage(
                (results.data.out.count /
                  (results.data.in.count +
                    results.data.out.count +
                    (results.data.edge.count
                      ? results.data.edge.count
                      : 0) +
                    results.data.no_entries.count +
                    (results.data.none.count
                      ? results.data.none.count
                      : 0))) *
                100
              )}
            </strong>
          </Typography>
        </Grid>

        <Grid item container xs={4} justifyContent="center">
          <Typography variant="body2"><span style={{
            borderBottom: `1px dashed ${results?.data?.in?.colour}`
          }} >In</span> &amp;
            <span style={{ borderBottom: `1px dashed ${results?.data?.edge?.colour}` }} >{setLanguageText(language,"Edge")}</span>:</Typography>
          <Typography
            variant="body2"
            align="center"
            style={{ marginLeft: theme.spacing(1) }}
          >
            <strong>
              {results.data.in.count && createPercentage(
                ((results.data.in.count +
                  (results.data.edge.count
                    ? results.data.edge.count
                    : 0)) /
                  (results.data.in.count +
                    results.data.out.count +
                    (results.data.edge.count
                      ? results.data.edge.count
                      : 0) +
                    results.data.no_entries.count +
                    (results.data.none.count
                      ? results.data.none.count
                      : 0))) *
                100
              )}
            </strong>
          </Typography>
        </Grid>

        <Grid item container xs={4} justifyContent="center">
          <Typography variant="body2"><span style={{ borderBottom: `1px dashed ${results?.data?.no_entries?.colour}` }} >{setLanguageText(language,"N/A")}</span>:</Typography>
          <Typography
            variant="body2"
            align="center"
            style={{ marginLeft: theme.spacing(1) }}
          >
            {/* avoid returning NaN when loading */}
            <strong>
              {results.data.no_entries.count && createPercentage(
                ((results.data.no_entries.count +
                  (results.data.none.count
                    ? results.data.none.count
                    : 0)) /
                  (results.data.in.count +
                    results.data.out.count +
                    (results.data.edge.count
                      ? results.data.edge.count
                      : 0) +
                    results.data.no_entries.count +
                    (results.data.none.count
                      ? results.data.none.count
                      : 0))) *
                100
              )}
            </strong>
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
}

export default PieChart;
