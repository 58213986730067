const sar_theme = {
  overrides: {
    MuiCssBaseline: {
      '@global': {
        ':host': {
          '--width': '100px',
          '--height': '100px'
        },
        '#react-app': {
          height: '100vh',
          position: 'relative',
          '& .AWS-authenticator ': {
            backgroundColor: '#fafafa',
            '& .application_container': {
              backgroundColor: '#fafafa',
              //marginBottom: '60px'
            }
          },
          '& .MuiAlert-root' : {
            alignItems : 'center'
          },
          '& .footer': {
            position: 'fixed',
            bottom: 0,
            width: '100%',
            height: '60px',
            padding: '1em',
            background: '#fafafa',
            textAlign: 'center',
            display: 'grid',
            alignItems: 'center'
          }
          // overflowX: 'hidden'
        },
        // make the machines table height NOT 0!!!
        '.machines_table > div:first-child': {
          height: 'auto !important'
        },
        '.machine_details_tables> div:first-child': {
          height: 'auto !important'
        }
      }
    }
  },
  palette: {
    primary: {
      main: '#014F9D'
    },
    secondary: {
      main: '#e4302d'
    },
    error: {
      main: '#fc466f'
    },
    warning: {
      main: '#ffc500'
    },
    success: {
      main: '#00d79b'
    },
    background: {
      darker: '#282d4e',
      dark: '#657696',
      light: '#f0f0f0',
      lighter: '#fafafa'
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 427,
      md: 769,
      lg: 1025,
      xl: 1441
    }
  },
  typography: {
    h1: {
      fontSize: '3rem'
    },
    h2: {
      fontSize: '2.5rem'
    },
    h3: {
      fontSize: '2rem'
    },
    h4: {
      fontSize: '1.5rem'
    },
    h5: {
      fontSize: '1.2rem'
    },
    h6: {
      fontWeight: 600,
      fontSize: '15px',
      lineHeight: 1.2
    },
    body1: {
      fontSize: '14px'
    },
    body2: {
      fontSize: '13px'
    },
    caption: {
      fontSize: '12px'
    },
    button: {
      textTransform: 'none'
    }
  }
};

export default sar_theme;
