import React, { useState, useEffect, useContext, useCallback } from 'react';
//import QrReader from 'react-qr-reader';
import { makeStyles } from '@mui/styles';
import { Autocomplete } from '@mui/material';
import { Button, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import PrimaryBtnIcon from '../buttons/PrimaryBtnIcon';
import { faCheckCircle, faKeyboard } from '@fortawesome/pro-light-svg-icons';
import axiosRequest from '../../helpers/axiosRequest';
import { UserContext } from '../../store/UserContext';
import useAsync from '../../Hooks/useAsync';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GlobalDialog from '../reusable/GlobalDialog';
import { CompanyContext } from '../../store/CompanyContext';
import SelectionRow from '../Dashboard/Selection_Row/SelectionRow';
import config from '../../config';
import { dipslideQRCodeCreater } from '../../helpers/dipslideQRCodeCreater';
import setLanguageText from '../../helpers/setLanguageText';
import { LayoutContext } from '../../store/LayoutContext';

const useStyles = makeStyles(theme => ({
    card: { height: '100%', width: '100%' },
    cardContainer: { height: '100%' },
    cardContent: {
        paddingTop: 0,
        flex: 1
    },
    tab: {
        minWidth: 'auto'
    },
    li: {
        display: 'inline'
    },
    cardFooter: {
        padding: theme.spacing(1),
        borderTop: `1px solid ${theme.palette.background.light}`,
        background: `${theme.palette.background.light}`
    },
    dialog: {
        height: 'calc(100% - 64px)'
    },
    leftButton: {
        paddingLeft: '4px'
    },
    rightButton: {
        paddingRight: '4px'
    },
    button: {
        padding: theme.spacing(2)
      }
}));

const ManualInputs = ({qrDetails}) => {

    //****** CONTEXT ******
    const { authUser } = useContext(UserContext);
    const { language } = useContext(LayoutContext);  
    const { companies: allCompanies } = useContext(CompanyContext);
    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState();
    const [QRCode, setQRCode] = useState();
    const [QRError, setQRError] = useState();
    const [textQRCode, setTextQRCode] = useState('');
    const [open, setOpen] = useState(false);
    const classes = useStyles();

    const {
        error,
        data,
        loading,
        executeAsync,
        setData
    } = useAsync({
        axiosRequest: axiosRequest
    });

    //****** STATES ****** 

    const checkCode = async (fullCode) => {
        await executeAsync({
            endpoint: 'qr',
            api_key: authUser.api_key,
            method: 'post',
            body: {
                code: fullCode,
                entry: true
            }
        });
    }

    //update text box
    const updateCode = (e) => {
        e.preventDefault();
        if (e.target.value.length <= 12) {
            setTextQRCode(e.target.value.toUpperCase())
        }
    }

    //remove all
    useEffect(() => {
        //console.log(allCompanies)
        if (allCompanies.length) {
            const removeAllOption = allCompanies.filter(
                company => company.company_id !== 0
            );
            setCompanies(removeAllOption);
        }
    }, [allCompanies]);

    const onSelectCompany = (e, company) => {
        //console.log(company)
        setSelectedCompany(company)
    }

    // useEffect(() => {
    //     if(textQRCode.length === 12 && selectedCompany){
    //         setQRCode({app: config.APPNAME, code: textQRCode , company_id: selectedCompany.company_id})
    //     }  
    // }, [selectedCompany,textQRCode]);

    useEffect(() => {
        if(textQRCode.length === 12 ){
            setQRCode({app: config.APPNAME, code: textQRCode , company_id: -1})
        }  
    }, [textQRCode]);

    //fecth linked entry
    const getLinkedEntry = useCallback((e) => {
        e.preventDefault();
        if(QRCode){
            //console.log(QRCode);
            const ordered = Object.fromEntries(Object.entries(QRCode).sort())
            checkCode(ordered);
        }

    }, [QRCode]);

    //remove all
    useEffect(() => {
        if(data){
            //console.log(allCompanies)
            if(data[0].entry_id) {
                setQRError();
                qrDetails(data)
            } else {
                setQRError({ message:'Not a QR code'});
                setData();
            }    
        }
    }, [
        data,
        setData,
        qrDetails
    ]);
    

    //close and reset
    const closeDialog = () => {
        setData();
        setQRError();
        setQRCode();
        setTextQRCode('');
        setSelectedCompany([]);
        setOpen(false);
    }

    return (
        <>
            <SelectionRow />
            <Grid item xs={6} sm={12}>
            <Button
                fullWidth
                variant="contained"
                color="secondary"
                onClick={e => {
                    setOpen(true)
                }}
                className={classes.button}
                >
                <Grid container>
                    <Grid item xs={12} >
                    <FontAwesomeIcon icon={faKeyboard} size="2x" />
                    </Grid>
                    <Grid item xs={12}>
                    <Typography variant="body1" >{setLanguageText(language,"Manual Input")}</Typography>
                    </Grid>
                </Grid>
                </Button>
{/*                 
                <PrimaryBtnIcon
                    color="secondary"
                    icon={faKeyboard}
                    text={'Manual Input'}
                    onClick={e => {
                        setOpen(true)
                    }}
                /> */}
            </Grid>
            <GlobalDialog
                open={open}
                closeModal={closeDialog}
                title="Manual QR input"
                handleSubmit={e => getLinkedEntry(e)}
                buttonTitle="Go"
                successMessage="Success!"
                loading={loading}
                error={error || QRError}
                data={data && data.length ? data : null}
                fullWidth
                maxWidth="sm"
            >
                <Grid item container spacing={2}>
                    {/* <Grid item xs={12} >
                        <Autocomplete
                            id="companies"
                            options={companies}
                            getOptionLabel={option => option.company_name || ''}
                            renderInput={params => (
                                <TextField
                                    required
                                    {...params}
                                   label={setLanguageText(language,"Select Company")}
                                    variant="outlined"
                                />
                            )}
                            value={selectedCompany || []}
                            onChange={onSelectCompany}
                        />
                    </Grid> */}
                    <Grid item xs={12}>
                        <TextField
                           label={setLanguageText(language,"12 character code")}
                            fullWidth
                            value={textQRCode}
                            variant="outlined"
                            placeholder={"12 character code"}
                            //helperText={'Scan QR code or type in 12 character code'}
                            onChange={e => updateCode(e)}
                            InputProps={{
                                maxLength: 12,
                                endAdornment: textQRCode.length === 12 ? (
                                    <InputAdornment position="end">
                                        <FontAwesomeIcon
                                            icon={faCheckCircle}
                                            className={classes.sign_check}
                                            size="lg"
                                        />
                                    </InputAdornment>
                                ) :
                                    <InputAdornment position="end">
                                        <small>({12 - textQRCode.length} left)</small>
                                    </InputAdornment>
                            }}
                        />
                    </Grid>

                    {QRCode && 

                    <Grid item container xs={12} alignContent="center" >
                        <img src={dipslideQRCodeCreater(QRCode)} /> 
                    </Grid>
                    
                    }
                </Grid>

            </GlobalDialog>

        </>
    );
};



export default ManualInputs;
