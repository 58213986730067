import { makeStyles } from '@mui/styles';

const CardStyles = makeStyles(theme => ({
  header: {
    borderBottom: `1px solid #e0e0e0`,
    padding: theme.spacing(1,2),
    alignItems: 'center'
  },
  adminHeader : {
    borderBottom: `1px solid #e0e0e0`,
    padding: theme.spacing(1,2),
    backgroundColor: '#d1e0e5'
  },
  card: { 
    height: '100%', 
    width: '100%' ,
    border: `1px solid #e0e0e0`,
  },
  cardContainer: { height: '100%' },
  cardContainerHide: {
    opacity: .2,
    height: '100%' 
  },
  cardContent: {
    //paddingTop: 0,
    flex: 1
  },
  cardContentHide: {
    opacity: .2,
    //paddingTop: 0,
    flex: 1
  },
  footer: {
    backgroundColor: theme.palette.background.light,
    padding: theme.spacing(1,2)
  },
  footerHide: {
    opacity: .2,
    backgroundColor: theme.palette.background.light,
    padding: theme.spacing(1,2)
  },

}));
export default CardStyles;
