import {
  Card,
  CardContent,
  Tabs,
  Tab,
  Grid
} from '@mui/material';
import React, {
  useEffect,
  useContext
} from 'react';
import PageContent from '../PageContent';
import { makeStyles } from '@mui/styles';
import ApplicationSettings from './ApplicationSettings';
import CompanySettings from './CompanySettings';
import { useHistory, useParams } from 'react-router-dom';
import { UserContext } from '../../store/UserContext';
import setLanguageText from '../../helpers/setLanguageText';
import { LayoutContext } from '../../store/LayoutContext';

const useStyles = makeStyles(theme => ({
  card: { height: '100%', width: '100%' },
  cardContainer: { height: '100%' },
  cardContent: {
    flex: 1
  },
  tab: {
    minWidth: 'auto'
  },
  li: {
    display: 'inline'
  },
  cardFooter: {
    padding: theme.spacing(1),
    borderTop: `1px solid ${theme.palette.background.light}`,
    background: `${theme.palette.background.light}`
  },
  dialog: {
    height: 'calc(100% - 64px)'
  },
  leftButton: {
    paddingLeft: '4px'
  },
  rightButton: {
    paddingRight: '4px'
  }
}));


function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        children
      )}
    </div>
  );
}

const Settings = () => {

  let history = useHistory();
  let { type } = useParams();
  const { user } = useContext(UserContext)
  const { language } = useContext(LayoutContext);  
  const [value, setValue] = React.useState(0);

  //****** STYLES ******
  const classes = useStyles();

  useEffect(() => {
    //console.log(tab)
    if (type === 'app') {
      setValue(0);
    } else if (type === 'company') {
      setValue(1);
    }
  }, [type, user])

  const handleChange = (event, newValue) => {
    if (newValue === 0) {
      history.push({ pathname: `/settings/app/0` })
    } else {
      history.push({ pathname: `/settings/company/0` })
    }
    //setValue(newValue);
  };

  return (
    <PageContent>
      {user.level === 9 ?
      <Grid container spacing={2}>
        
          <Grid
            item
            xs={12}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example"
              centered
            >
              <Tab label={setLanguageText(language,"Application Settings")} />
              <Tab label={setLanguageText(language,"Company Settings")} />
            </Tabs>

          </Grid>
   
        <Grid item xs={12}>
          <Card>
            <CardContent justify="center" className={classes.cardContent}>
            
                <TabPanel value={value} index={0}>
                  <ApplicationSettings />
                </TabPanel>
           
              <TabPanel value={value} index={1}>
                <CompanySettings />
              </TabPanel>

            </CardContent>
          </Card>
        </Grid>
      </Grid>
    : 
      <Grid item xs={12}>
          <Card>
            <CardContent justify="center" className={classes.cardContent}>
                <CompanySettings />
              </CardContent>
          </Card>
        </Grid>
    
    }
    </PageContent>
  );
}

export default Settings;
