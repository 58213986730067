import axiosRequest from './axiosRequest';

// Check user level and fetch companies accordingly
export const fetchMachine = (machine_id, user, api_key) => {
  //console.log(JSON.stringify(user.companies_id))
  
  return axiosRequest({
    api_key,
    method: 'get',
    endpoint: 'machines',
    params: {
      machine_id,
      user_level: user.level,
      company_ids : JSON.stringify(user.companies_id)
    }
  });
};
