import React, { useEffect, useState, useContext } from 'react';
import axiosRequest from '../../helpers/axiosRequest';
import useAsync from '../../Hooks/useAsync';
import GlobalDialog from '../reusable/GlobalDialog';
import UserForm from './UserForm';
import config from './../../config';
import AWS from 'aws-sdk';
import { UserContext } from '../../store/UserContext';

const EditUser = ({ closeDialog, open, user }) => {

  //************************ CONTEXT ************************
  const { authUser } = useContext(UserContext);
  //************************ USE STATES ************************

  const [newUser, setNewUser] = useState();
  const [originalUser, setOriginalUser] = useState();

  const {
    error,
    data,
    loading,
    logCompleted,
    logError,
    executeAsync,
    executeLog,
    setData
  } = useAsync({
    axiosRequest: axiosRequest
  });

  const [cognitoResponse, setCognitoResponse] = useState(null);

  //************************ USE EFFECTS ************************

  useEffect(() => {
    if (user) {
      console.log(user)
      setNewUser({
        id: user.original.id,
        name: user.original.Username,
        full_name: user.original.full_name,
        initial: user.original.initial,
        companies_id: user.original.companies_id ? JSON.parse(user.original.companies_id) : [user.original.company_id],
        company_id: user.original.company_id,
        level: user.original.level,
        level_group: user.original.level_group ? JSON.parse(user.original.level_group) : null, //new level details
        department: user.original.department,
        email: user.original.email,
        phone: ''
      })
    }
    setOriginalUser(user.original);

  }, [user]);

  // Handle Axios Request
  useEffect(() => {
    if (data) {
      // TODO handle if activity error
      if (logError) console.log('Something went wrong with the Activity Log');
      if (logCompleted) {
        setData();
        closeDialog();
      }
    }
  }, [data, logCompleted, logError]);

  // Remove Error alert after 5 sec
  useEffect(() => {
    if (cognitoResponse?.result === 'error')
      setTimeout(() => {
        setCognitoResponse(null);
      }, 5000);
  }, [cognitoResponse?.result]);

  //************************ FUNCTIONS ************************
  const handleSubmit = async e => {
    e.preventDefault();
    //console.log(newUser)
    //console.log(originalUser)
    //console.log(JSON.parse(originalUser.level_group).group)

    var userParams = {
      UserAttributes: [
        { Name: 'email', Value: newUser.email },
        { Name: 'email_verified', Value: 'true' }
      ],
      UserPoolId: config.cognito.USER_POOL_ID,
      Username: newUser.name
    }
    if (e.aws_phone) {
      // Add phone Number
      userParams = {
        ...userParams,
        UserAttributes: [
          ...userParams.UserAttributes,
          { Name: 'phone_number', Value: newUser.phone }
        ]
      };
    }
    const cognito_identity_service_provider = new AWS.CognitoIdentityServiceProvider(
      { apiVersion: '2016-04-18' }
    );

    const editCognitoUser = new Promise((resolve, reject) => {
      //* CREATE USER
      cognito_identity_service_provider.adminUpdateUserAttributes(
        userParams, (err, userData) => {
          if (err) {
            reject(err);
          } else {
            //* remove from old group
            const groupParams = {
              UserPoolId: config.cognito.USER_POOL_ID,
              Username: newUser.name,
            };

            //remove from group if needed
            if (originalUser.level_group) {
              cognito_identity_service_provider.adminRemoveUserFromGroup(
                { ...groupParams, GroupName: JSON.parse(originalUser.level_group).group },
                (err, groupData) => {
                  if (err) {
                    setCognitoResponse({
                      result: 'error',
                      message: err.message
                    });
                    reject(err);
                  }
                })
            }

            //add to new group
            cognito_identity_service_provider.adminAddUserToGroup(
              { ...groupParams, GroupName: newUser.level_group.group },
              (err, groupData) => {
                if (err) {
                  setCognitoResponse({
                    result: 'error',
                    message: err.message
                  });
                  reject(err);
                } else {
                  setCognitoResponse({
                    result: 'success',
                    message: data
                  });
                  resolve(userData);
                }
              }
            );
          }     
        }
      );
    });

try {
  const cognito_user_created = await editCognitoUser;
  if (cognito_user_created) {
    //* ADD USER TO DB
    const db_user_added = await executeAsync({
      endpoint: 'users',
      api_key: authUser.api_key,
      method: 'patch',
      body: newUser
    });

    await executeLog({
      endpoint: 'activity',
      api_key: authUser.api_key,
      body: {
        attributes: {
          type: 'Update User',
          user_id: newUser.id,
          dept: newUser.department,
          name: newUser.name,
          email: newUser.email,
          company_id: newUser.companies_id[0],
          companies_id: newUser.companies_id,
          level: newUser.level_group.level,
          level_group: newUser.level_group,
          initials: newUser.initial
        }
      }
    });
  }
} catch (err) {
  setCognitoResponse({
    result: 'error',
    message: err.message
  });
}
  };

return (
  <GlobalDialog
    open={open}
    closeModal={closeDialog}
    title="Edit User"
    handleSubmit={handleSubmit}
    buttonTitle="Edit User"
    successMessage="User editted successfully!"
    loading={loading}
    error={error}
    data={data}
    fullWidth
  >
    <UserForm
      edit={true}
      user={newUser}
      setUser={setNewUser}
    />
  </GlobalDialog>
);
};

export default EditUser;
