import React, { useEffect, useState } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
// COMPONENTS
import NotFound from '../NotFound';
import { Grid, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { isMobileOnly, isTablet } from 'react-device-detect';
import useWindowSize from '../Hooks/useOnWindowSize';
import Dashboard from './Dashboard/Dashboard';
import Machines from './Machines/Machines';
import DisplayView from './Display/DisplayView';
import Additives from './Products/Additives/Additives';
import Coolants from './Products/Coolants/Coolants';
import Reports from './Reports/Reports';
import Companies from './Companies/Companies';
import SettingsUser from './SettingsUser/Settings';
import Settings from './Settings/Settings';
import Machine from './Machines/MachineDetails/MachineDetails';
import Resources from './Resources/Resources';
import Diagnostic from './Diagnostic/Diagnostic';
import Maintenance from './Maintenance/Maintenance';
import Products from './Products/Products/Products';
import Chat from './Chat/Chat';
import CompanyAttributes from './Attributes/CompanyAttributes';
import Scanner from './QRScanner/Scanner';
import Service from './Service/Service';
import Mobile from './Mobile/MobileHome';
import FloatingDial from './FloatingActionButton/FloatingActionButton';
import QrCode from './FloatingActionButton/QrCode';
import FullEntry from './Entry/FullEntry';
import ReactGA from 'react-ga4';
import config from '../config';
import { Helmet } from 'react-helmet';

function MyRoutes({ component: Component, ...props }) {


  return (
    <>
    <Helmet>
        <title>{props.title}</title>
    </Helmet>
    <Box  className={props?.classes?.main ? props.classes.main : ''}>
      <Route {...props} component={Component} />
      <Box className={props.classes.floating}>
        <QrCode />
        <FloatingDial {...props} />
      </Box>
    </Box>
    </>
  );
}
function MyRoutesNoContainer({ component: Component, ...props }) {
  return <Grid item className={props.classes.display}>
    <Route {...props} component={Component} />
  </Grid>
}
const useStyles = makeStyles(theme => {
  return {
    floating: {
      position: 'fixed',
      bottom: isMobileOnly ? 0 : theme.spacing(2),
      right: theme.spacing(0),
      zIndex: 1000,
      display: 'flex',
      alignItems: 'flex-end',
      width: isMobileOnly ? '100%' : 'auto'
    },
    main: {
      minWidth: '100%',
      display: 'block',
      width: '100%',
      zIndex: 2,
      minHeight : `calc(100vh - ${theme.spacing(20)})`,
      marginTop: theme.spacing(10),
      marginBottom: isMobileOnly ? 0 : theme.spacing(10)
    },
    display: {
      overflow: 'hidden',
      height: '100vh',
      width: '100vw',
      position: 'fixed',
      background: '#f0f0f0',
      zIndex: 1,
      padding: '0 0 70px 0 '
    }
  };
});

const Routes = () => {
  const size = useWindowSize();
  const [shrink, setShrink] = useState(size.height <= 950);
  const classes = useStyles(shrink);

  var location = useLocation();
  var history = useHistory();

  //google analytics
  useEffect(()=>{
      if(config.ANALYTICS && process.env.NODE_ENV !== "development") {  
        ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
      }
  },[location, config.ANALYTICS])

  useEffect(() => {
    return size.height <= 950 ? setShrink(true) : setShrink(false);
  }, [size.height]);

  return (

    <Switch>
      <MyRoutes title={config.NAME} path="/" exact classes={classes} component={isMobileOnly ? Mobile : Dashboard} {...history}/>
      <MyRoutes title={config.NAME} path="/dashboard" exact classes={classes} component={isMobileOnly ? Mobile : Dashboard}  {...history}/>
      <MyRoutes title={`Machines - ${config.NAME}`} path="/machines" exact classes={classes} component={Machines}  {...history}/>
      <MyRoutesNoContainer title={`Display - ${config.NAME}`} path="/displayView" exact classes={classes} component={DisplayView}  {...history}/>
      <MyRoutes title={`Additives - ${config.NAME}`} path="/additives" exact classes={classes} component={Additives}  {...history}/>
      {/* <MyRoutes title={`Coolants - ${config.NAME}`} path={`${config.APPNAME === 'act' ? '/oils' : '/coolants'}`} exact classes={classes} component={Coolants}  {...history}/> */}
      <MyRoutes title={`Coolants - ${config.NAME}`} path="/coolants" exact classes={classes} component={Coolants}  {...history}/>
      <MyRoutes title={`Reports - ${config.NAME}`} path="/reports" exact classes={classes} component={Reports}  {...history}/>
      <MyRoutes title={`Account - ${config.NAME}`} path="/account/:tab" exact classes={classes} component={SettingsUser}  {...history}/>
      <MyRoutes title={`Companies - ${config.NAME}`} path="/companies" exact classes={classes} component={Companies}  {...history}/>
      <MyRoutes title={`Settings - ${config.NAME}`} path="/settings/:type/:tab" exact classes={classes} component={Settings}  {...history}/>
      <MyRoutes title={`Machine - ${config.NAME}`} path="/machine/:id" exact classes={classes} component={Machine}  {...history}/>
      <MyRoutes title={`Entry - ${config.NAME}`} path="/machine/:id/entry/:entry_id" exact classes={classes} component={FullEntry}  {...history}/>
      <MyRoutes title={`Resources - ${config.NAME}`} path="/resources" exact classes={classes} component={Resources}  {...history}/>
      <MyRoutes title={`Diagnostic - ${config.NAME}`} path="/diagnostic" exact classes={classes} component={Diagnostic} {...history}/>
      <MyRoutes title={`Scanner - ${config.NAME}`} path="/qr-code" exact classes={classes} component={Scanner} {...history}/>
      <MyRoutes title={`Service - ${config.NAME}`} path="/service" exact classes={classes} component={Service} {...history}/>
      <MyRoutes title={`Attributes - ${config.NAME}`} path="/attributes" exact classes={classes} component={CompanyAttributes} {...history}/>
      <MyRoutes title={`Attributes - ${config.NAME}`} path="/attributes" exact classes={classes} component={CompanyAttributes} {...history}/>
      <MyRoutes title={`Maintenance - ${config.NAME}`} path="/Maintenance" exact classes={classes} component={Maintenance} {...history}/>  
      <MyRoutes title={`Chat - ${config.NAME}`} path="/Chat" exact classes={classes} component={Chat} {...history}/>
      <MyRoutes title={`Products - ${config.NAME}`} path="/Products" exact classes={classes} component={Products} {...history}/>
      {/* Finally, catch all unmatched routes */}
      <MyRoutes exact classes={classes} component={NotFound} />
    </Switch>

  );
};

export default Routes;
