import { TextField } from '@mui/material';
import { Alert, Autocomplete } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import axiosRequest from '../../helpers/axiosRequest';
import setLanguageText from '../../helpers/setLanguageText';
import useAsync from '../../Hooks/useAsync';
import { UserContext } from '../../store/UserContext';
import { LayoutContext } from '../../store/LayoutContext';

const DropdownCoolantsByManufacturer = ({ manufacturer, setCoolant }) => {
  const { authUser } = useContext(UserContext);
  const { language } = useContext(LayoutContext);  
  const [coolants, setCoolants] = useState([]);
  const [value, setValue] = useState(null);
  const { error, data, loading, executeAsync } = useAsync({
    axiosRequest: axiosRequest
  });

  useEffect(() => {
    if (manufacturer) {
      executeAsync({
        method: 'get',
        api_key: authUser.api_key,
        endpoint: 'coolants',
        params: { manufacturer: manufacturer.brand_name }
      });
    }
  }, [authUser.api_key, executeAsync, manufacturer]);

  useEffect(() => {
    if (data) {
      setCoolants(data);
      setValue(data[0] || null);
    }
  }, [data]);
  useEffect(() => {
    if (value) {
      setCoolant(value);
    }
  }, [setCoolant, value]);

  const onSelectCoolant = (e, coolant) => {
    setValue(coolant);
  };

  if (error) return <Alert severity="error">{error.message}</Alert>;
  return (
    <Autocomplete
    disableClearable
      id="coolants"
      options={coolants}
      loading={loading}
      getOptionLabel={option => option.coolant_name || ''}
      renderInput={params => (
        <TextField
          required
          {...params}
         label={setLanguageText(language,"Select Coolant")}
          variant="outlined"
        />
      )}
      value={value}
      onChange={onSelectCoolant}
    />
  );
};

export default DropdownCoolantsByManufacturer;
