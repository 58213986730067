import React, { useContext, useMemo } from 'react';
import {
  StyleSheet,
  usePDF
} from '@react-pdf/renderer';
import { Button } from '@mui/material';
import { useTheme } from '@mui/styles';
import { CSVLink } from 'react-csv';
import { buttonStyles } from '../../styles/buttonStyles';
import GlobalPdfDialog from '../reusable/GlobalPdfDialog';
import LoadingScreen from '../reusable/LoadingScreen';
import ReactPDFFallback from '../pdf/ReactPDFFallback';
import setLanguageText from '../../helpers/setLanguageText';
import { LayoutContext } from '../../store/LayoutContext';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  dialogContent: {
    '& .MuiDialogContent-root': {
      overflow: 'hidden'
    },
    '& .MuiDialog-paper': {
      width: '100%',
      height: '100%'
    }
  }
}));

const create_pdf_styles = theme => {
  const borderColor = theme.palette.background.darker;
  const borderStyle = 'solid';

  return StyleSheet.create({
    BODY: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(6),
      paddingHorizontal: theme.spacing(2)
    },
    ROW_ABOVE_TABLE: {
      padding: theme.spacing(1),
      // backgroundColor: theme.palette.primary.main,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      SMALL_TEXT: {
        fontSize: theme.spacing(1.5)
      },
      BIG_TEXT: {
        fontSize: theme.spacing(2)
      }
    },
    TABLE: {
      display: 'flex',
      width: 'auto',
      marginBottom: 20,
      borderTop: '1px solid #ccc',
      borderLeft: '1px solid #ccc',
      borderRight: '1px solid #ccc',
      borderBottom: 'none',
      ROW: {
        padding: 5,
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: '#fff',
        color: '#222',
        borderBottom: '1px solid #ccc',
      },
      COL: {
        border: 0
        // flex: 1
      },
      CELL: {
        padding: 5,
        fontSize: 8,
        textAlign: 'left',
        overflow: 'hidden'
      },
      HEADER: {
        padding: 5,
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: '#efefef',
        color: '#222',
        borderBottom: '1px solid #ccc',
        CELL: {
          padding: 5,
          fontSize: 8,
          textAlign: 'left',
          overflow: 'hidden'
        },
      },
      GROUP_ROW: {
        backgroundColor: theme.palette.background.light,
        flexDirection: 'row',
        display: 'flex',
        borderBottom: '1px solid #ccc',
        backgroundColor: '#efefef',
        CELL: {
          padding: 5,
          fontSize: 12,
          fontWeight : 'bold',
          textAlign: 'left',
          overflow: 'hidden'
        },
      }
    },
    PAGE_NUMBER: {
      position: 'absolute',
      fontSize: theme.spacing(1.5),
      bottom: theme.spacing(1.5),
      left: 0,
      right: 0,
      textAlign: 'center',
      color: 'grey'
    }
  });
};

const CreatePDF = ({ document }) => {

  const [instance, updateInstance] = usePDF({ document: document });

  return (
    <>
      {instance.url ?
        <object data={instance.url} type="application/pdf" height="100%" width="100%">
          <ReactPDFFallback url={instance.url} />
        </object>
        :
        null
      }
    </>
  )
}

const ReportPDF = ({
  dialog_title,
  error,
  dialogOpen,
  dialogClose,
  showPreview,
  create_pdf_data,
  create_csv_data,
  csv_data,
  csv_filename,
  noData
}) => {
  const classes = useStyles();
  const { language } = useContext(LayoutContext)
  const buttonStyle = buttonStyles();
  const theme = useTheme();
  const pdf_styles = useMemo(() => create_pdf_styles(theme), [theme]);

  const handleClose = () => {
    dialogClose();
  };

  const handleCsv = () => {
    create_csv_data();
  };

  //console.log(showPreview)

  return (
    <GlobalPdfDialog
      open={dialogOpen}
      closeModal={handleClose}
      title={dialog_title}
      error={error}
      maxWidth="xl"
      fullWidth
      className={classes.dialogContent}
      csvDownloadComp={!noData && (
        <CSVLink
          data={csv_data}
          className={buttonStyle.buttonText}
          filename={`${csv_filename}.csv`}
        >
          <Button onClick={handleCsv} variant="contained" color="primary">
            {setLanguageText(language, 'Download CSV')}
          </Button>
        </CSVLink>
      )}
    >
      <>
        {showPreview ?
          <CreatePDF document={create_pdf_data(pdf_styles)} />
          :
          !noData ?
            <LoadingScreen
              loading={false}
              background="#666"
              colour="#fff"
              text="Loading PDF Preview"
              position="relative"
            />
            : <LoadingScreen
              loading={false}
              background="#666"
              colour="#fff"
              text="No Data"
              position="relative"
            />
        }

      </>
      {/* <PDFViewer width="100%" height="100%">
        <Document title={doc_title}>
          <Page style={pdf_styles.BODY}>
            {create_pdf_data(pdf_styles)}
            <Text
              style={pdf_styles.PAGE_NUMBER}
              render={({ pageNumber, totalPages }) =>
                `${pageNumber} / ${totalPages}`
              }
              fixed
            />
          </Page>
        </Document>
      </PDFViewer> */}
    </GlobalPdfDialog>
  );
};

export default ReportPDF;
