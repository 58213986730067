import React, { Fragment, useContext, useState, useEffect } from 'react';
import { Chip } from '@mui/material';
import { CompanyContext } from '../../store/CompanyContext';
import tableChipStyle from '../../styles/tableChipStyles';

import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import setColour from '../../helpers/setColour';

// parse machines' attributes and settings
const CreateChip = ({ factorApplied, entryFactor, company_attr_id, entry_attributes }) => {
  const classes = tableChipStyle();
  const { selectedCompany } = useContext(CompanyContext);
  const [found_attr, setFound_attr] = useState();
  const [colour_chip, setColour_chip] = useState();
  const [showFactored, setShowFactored] = useState(false);

  useEffect(() => {
    if (entry_attributes) {
      setFound_attr(
        entry_attributes.find(attr => attr.attribute_id === company_attr_id)
      );
    }
  }, [company_attr_id, entry_attributes]);

  useEffect(() => {
    if (found_attr) {
      //console.log(found_attr)
      //console.log(factorApplied)
      //set colour and show factored
      if (factorApplied && found_attr.attribute_id === 'conc') {
        // console.log(factorApplied)
        setShowFactored(true);
      } else {
        setShowFactored(false);
      }
      if (selectedCompany?.company_colour_rules) {
        setColour_chip(
          setColour(
            selectedCompany?.company_colour_rules,
            found_attr.attribute_values.status
          )
        );
      }
    }
  }, [entryFactor, factorApplied, found_attr, selectedCompany.company_colour_rules]);

  // TODO ADD ICON TO SAVED DATA FOR BETTER CONSISTENCY
  const selectIcon = (value, min, max, factor) => {
    //console.log(factor)
    if (value === -1 || value === -2) {
      return
    }
    //console.log(factor
    if (factor > 1) {
      if (value > (factor / max)) {
        return <ArrowUpward style={{ color: 'white', width: '16px' }} />
      } else if (value < (factor / min)) {
        return <ArrowDownward style={{ color: 'white', width: '16px' }} />
      }
    } else {
      if (value > max) {
        return <ArrowUpward style={{ color: 'white', width: '16px' }} />
      } else if (value < min) {
        return <ArrowDownward style={{ color: 'white', width: '16px' }} />
      }
    }
    return
  };

  return (
    <>
      {found_attr && found_attr.attribute_values ? (
        <Chip
          icon={selectIcon(
            Number(found_attr.attribute_values.value),
            Number(found_attr.attribute_values.min),
            Number(found_attr.attribute_values.max),
            found_attr.attribute_id === 'conc' ? Number(entryFactor ? entryFactor : 1) : 1
            //divide by factor if applied, set to 1 if not factor was set when taking the readings
          )}
          className={`${classes.chip}`}
          style={{ backgroundColor: colour_chip }}
          label={
            <>
              {found_attr.attribute_values.output ?
                <>
                  {found_attr.attribute_id === 'bacteria' ?
                    <>
                      {found_attr.attribute_values?.output.includes("^") ?
                        <>
                          {found_attr.attribute_values.output.split("^")[0]}<sup>{found_attr.attribute_values.output.split("^")[1]}</sup>
                        </>
                        : found_attr.attribute_values.output !== '-' && found_attr.attribute_values.output > 0 ?
                          <>
                            10
                            <sup>{found_attr.attribute_values.output}</sup>
                          </>
                          : found_attr.attribute_values.output === "0" ? 'Clear' : found_attr.attribute_values.output}
                    </>
                    :
                    <>
                      {showFactored && found_attr.attribute_values?.output > 0
                        ? `${(Number(found_attr.attribute_values.output) * Number(entryFactor ? entryFactor : 1)).toFixed(1)} (${Number(found_attr.attribute_values.output).toFixed(1)})` :
                        Number.isInteger(found_attr.attribute_values.output) && !showFactored
                          ? found_attr.attribute_values.output.toFixed(2)
                          : found_attr.attribute_values.output}

                    </>
                  }
                </>
                //no value set check status
                : found_attr.attribute_values.status === -10 ? 'Pending'
                  : found_attr.attribute_values.status === -20 ? '-' : '-' //status false
              }
            </>
          }
        />
      ) : null}
    </>
  );
};

export default CreateChip;
