import { Grid, Typography, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext, useEffect, useState } from 'react';
import { CompanyContext } from '../../store/CompanyContext';
import Ticker from 'react-ticker'
import DropdownCompanies from '../Dashboard/Selection_Row/DropdownCompanies';
import DropdownGroups from '../Dashboard/Selection_Row/DropdownGroups';
import SelectionRow from '../Dashboard/Selection_Row/SelectionRow';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { MachineContext } from '../../store/MachineContext';
import DisplayCard from './DisplayCard';
import CreateChartData from '../../helpers/createChartData';
import PieChart from '../Dashboard/charts/PieChart';
import BarChart from '../Dashboard/charts/BarChart';
import { Link } from 'react-router-dom';
import config from '../../config';
import { LayoutContext } from '../../store/LayoutContext';
//import image from '../../image/cis-logo-small.png';
//const image = React.lazy(() => import(`../../image/${config.APPNAME}-logo-small.png`));

const useStyle = makeStyles(theme => ({
  slider: {
    padding: '10px',
    '& .carousel-status': {
      position: 'fixed!important',
      top: 0,
      right: 0,
      padding: '10px 16px !important',
      fontSize: '20px!important',
      textShadow: 'none!important',
      color: '#fff!important',
      margin: 0,
    }
  },
  ticker: {
    width: '100%',
    height: '70px',
    position:'fixed',
    background : '#464646',
    padding: '5px',
    color: '#FFF',
    bottom: 0
  },
  header: {
    //height: theme.spacing(8),
    padding: theme.spacing(1,2),
    background: theme.palette.primary.main,
    transition: 'background .75s',
    zIndex: 15
  },
  companyLogo: {
    width: '70px',
    '& a': {
      width: '100%',
      '& img': {
        width: '100%'
      }
    }
  },
  companyText: {
    color: theme.palette.primary.contrastText,
    textDecoration: 'none'
  }
}));

const DisplayView = () => {

  const { filteredMachines } = useContext(
    MachineContext
  );
  const theme = useTheme();
  const { selectedCompany } = useContext(CompanyContext);
  const { language } = useContext(LayoutContext);
  const [chartData, setChartData] = useState();
  const [rows, setRows] = useState();
  const perScreen = 8;
  const gridSize = 12 / (perScreen / 2)
  const classes = useStyle();

  let app = config.APPNAME
  const [logo, setLogo] = useState('');
  import(`../../image/${app}-logo-small.png`).then((module) => {
    setLogo(module.default);
  });

  useEffect(() => {

    if (filteredMachines) {
      if (Object.values(filteredMachines).length) {
        setChartData(
          CreateChartData(filteredMachines, selectedCompany.company_attributes, theme, language)
        );

      let rows = []
      let currentRow = [];

      filteredMachines.forEach((machine, i) => {
        if (i % perScreen === 0 && i !== 0) {
          rows.push(currentRow);
          currentRow = [];
        }
        currentRow.push(machine);
      });
      rows.push(currentRow);
      setRows(rows);

      } else {
        setChartData([])
      }
    }
  }, [filteredMachines, selectedCompany.company_attributes, theme, perScreen]);


  const createSlides = (chartData,rows) => {

    // let rows = [],
    //   perScreen = 8,
    //   currentRow = [];

    // filteredMachines.map((machine, i) => {
    //   if (i % perScreen === 0 && i !== 0) {
    //     rows.push(currentRow);
    //     currentRow = [];
    //   }
    //   currentRow.push(machine);
    // });
    // rows.push(currentRow);

    // //console.log(rows)
    // //console.log(gridSize)

    return (
      <Carousel
        autoFocus={true}
        showThumbs={false}
        showArrows={false}
        showIndicators={false}
        infiniteLoop={true}
        autoPlay={true}
        className={classes.slider}
        interval={5000}
      >
        { rows.map((group, i) =>
          <div key={i}>
            <Grid container item style={{ padding: '5px' }} spacing={2} alignItems="stretch">
              {group.map((machine) =>
                <Grid key={machine.machine_id} xs={gridSize} item>
                  <DisplayCard machine={machine} gridView={true} />
                </Grid>
              )}
            </Grid>
          </div>
        )}
        <div>
          <Grid container item style={{ padding: '5px' }} spacing={2}>
            {chartData &&
              chartData.map(chart => {
                return (
                  chart.chart_type === 'pie' ? (
                    <Grid key={chart.id} item xs={12} sm={6} md={4}>
                      <PieChart
                        results={chart}
                      />
                    </Grid>
                  ) : chart.chart_type === 'bar' ? (
                    <Grid key={chart.id} item xs={12} sm={6} md={4}>
                      <BarChart
                        results={chart}
                      />
                    </Grid>
                  ) : null
                );
              })}
          </Grid>
        </div>
      </Carousel>
    )

  }

  const showTicker = (message) => {
    //console.log(message)
    return (
      <Ticker offset="run-in" speed={10} >
        {({ index }) => (
          <h2 style={{ whiteSpace: "nowrap" }}>{message}</h2>
        )}
      </Ticker>
    )
  }

  return (
    <>
       <Grid
        item
        container
        alignItems="center"
        //justifyContent="space-between"
        className={classes.header}
      >
        <Grid item container alignItems="center" xs={4} spacing={2}>
          <Grid item className={classes.companyLogo}>
            <Link to="/">
              {logo && <img height="100%" src={logo} alt="logo" />}
            </Link>
          </Grid>
            <Grid item>
              <Link to="/" className={classes.companyText}>
                <Typography variant="h5">{config.NAME}</Typography>
              </Link>
            </Grid>
        </Grid>
        <Grid item xs={4} style={{textAlign: 'center'}} >
            <Typography className={classes.companyText} variant="h5">{selectedCompany.company_name}</Typography>
        </Grid>
      </Grid>
      { rows && chartData ?
        <>
          {createSlides(chartData,rows,classes)}
          <Grid className={classes.ticker} >
            {selectedCompany?.company_display_settings?.messages && showTicker(selectedCompany?.company_display_settings?.messages[0].message)}
          </Grid>
        </>
        :
        <>
          <SelectionRow />
          <DropdownCompanies fetchEntries={true} />
          <DropdownGroups />
        </>
      }
    </>
  );
};

export default DisplayView;
