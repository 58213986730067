import React, {
  useState,
  useEffect,
  //useCallback,
  useContext
} from 'react';
import {
  faFilePdf,
  faFileExport
} from '@fortawesome/pro-light-svg-icons';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Grid
  // List,
  // ListItem,
  // Accordion,
  // AccordionSummary,
  // AccordionDetails,
  // CardContent
} from '@mui/material';
// import ToggleButton from '@mui/lab/ToggleButton';
// import ToggleButtonGroup from '@mui/lab/ToggleButtonGroup';
// import formatDate from '../../helpers/formatDate';
import { buttonStyles } from '../../styles/buttonStyles';
import SingleMachinePDF from '../pdf/SingleMachinePDF';
//import { CSVLink } from 'react-csv';
import { PDFViewer } from '@react-pdf/renderer';
import { CompanyContext } from '../../store/CompanyContext';
import GlobalPdfDialog from '../reusable/GlobalPdfDialog';
//import { ExpandMore } from '@mui/icons-material';
import LoadingScreen from '../reusable/LoadingScreen';
import LoadingButton from '../buttons/LoadingButton';
import { MachineContext } from '../../store/MachineContext';
//import usePhChart from '../Charts/usePhChart';
import { ChartsContext } from '../../store/ChartsContext';
import { LayoutContext } from '../../store/LayoutContext';

const ExportSingleMachine = ({entries}) => {

  //context
  const { selectedCompany } = useContext(CompanyContext);
  const { selectedMachine } = useContext(MachineContext);
  const { charts } = useContext(ChartsContext);
  const { language } = useContext(LayoutContext)
  //ctates
  const [anchorEl, setAnchorEl] = useState(null);
  //const [selectedOptions, setSelectedOptions] = useState();
  const [localOrientation, setLocalOrientation] = useState('landscape');
  const [orientation, setOrientation] = useState();
  const buttonStyle = buttonStyles();
  const [showPreview, setShowPreview] = useState(false);
  //const [localOptions, setLocalOptions] = useState([]);
  const [reload, setReload] = useState(false);

  //console.log(selectedMachine)

  /*------------- CHARTS  ---------------*/

  // const [plots, setPlots] = useState(true);
  // const [tooltips, setTooltips] = useState(true);
  // const { createPhChart, phData, phBase64 } = usePhChart({
  //   plots,
  //   tooltips
  // });
  // useEffect(()=>{
  //   console.log(tablePage);
  //   if(tablePage && Object.values(tablePage).length ){
  //     createPhChart(tablePage)
  //   }
  // },[tablePage])
  // useEffect(()=>{
  //   console.log(phBase64)
  //   console.log(phData)
  // },[phBase64,phData])


  /*------------- OPEN POPUP ---------------*/

  const handleClick = e => {
    setAnchorEl(e.currentTarget);
    setShowPreview(true);
  };
  const handleClose = () => {
    setShowPreview(false);
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;


  /*------------- OPTIONS ---------------*/

  // const handleChange = event => {
  //   //console.log(event.target.name)
  //   event.persist()
  //   setShowPreview(false);
  //   setReload(true);
  //   //console.log(event.target.name)
  //   setLocalOptions(old => {
  //     let newOptions = [...old];
  //     let index = old.findIndex(obj => obj.id === event.target.name);
  //     //console.log(index)
  //     newOptions[index].isVisible = !newOptions[index].isVisible;
  //     return newOptions;
  //   });

  // };

  /*------------- UPDATE ORIENTATION OF PDF ---------------*/

  // const updateOrientation = (event, newOrientation) => {
  //   event.persist();
  //   setShowPreview(false);
  //   setReload(true);
  //   setLocalOrientation(newOrientation);
  // };

  /*------------- DATA ---------------*/


  //set orintation
  useEffect(() => {
    if (localOrientation) {
      setOrientation(localOrientation);
    }
    return () => {
      setOrientation();
    }
  }, [localOrientation]);

  //Show Reload Button
  const reloadPDF = () => {
    setReload(false)
    //setShowPreview(true); 
    //setSelectedOptions(localOptions);
    setTimeout(() => {
      setShowPreview(true)
    }, 100)
  };


  //console.log(entries)

  return (
    <>
      <LoadingButton
        aria-describedby={id}
        variant="contained"
        //loading={(entries && selectedMachine && Object.values(charts).length > 4 ) ? false : true}
        disabled={entries ? false : true}
        onClick={e => handleClick(e)}
        className={buttonStyle.button}
        icon={faFileExport}
        text={'Export Machine'}
        fullWidth={false}
        iconSize="1x"
        color="primary"
      />
      <GlobalPdfDialog
        open={open}
        title="Export Single Machine"
        closeModal={handleClose}
        maxWidth="xl"
        fullWidth
        onEntered={e => setShowPreview(true)}
      >
        <Grid container style={{ height: "100%", overflow: "auto" }} >
          {/* <Grid container item xs={4} md={3} style={{ height: "100%", overflow: "auto", borderRight: '1px solid #ccc', background: '#fbfbfb' }} >
              <CardContent   style={{ width: '100%' }} >
                <Grid container item spacing={2} >
                  <Grid item xs={12} className={classes.orientation} >
                    <Typography className={classes.heading}>
                      Orientation
                  </Typography>
                    <ToggleButtonGroup
                      value={orientation}
                      exclusive
                      onChange={updateOrientation}
                      aria-label="text alignment"
                      style={{ width: '100%' }}
                    >
                      <ToggleButton
                        value="portrait"
                        aria-label="centered"
                        style={{ width: '100%' }}
                      >
                        <FontAwesomeIcon
                          icon={faFile}
                          style={{
                            fontSize: theme.spacing(3.5)
                          }}
                        />
                      </ToggleButton>
                      <ToggleButton
                        value="landscape"
                        aria-label="centered"
                        style={{ width: '100%' }}
                      >
                        <FontAwesomeIcon
                          icon={faFile}
                          rotation={90}
                          style={{
                            fontSize: theme.spacing(3.5)
                          }}
                        />
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                </Grid>
              </CardContent>
            </Grid>
            <Grid item xs={8} md={9} style={{ height: "100%" }}> */}
          {showPreview && selectedMachine ?
            <PDFViewer width="100%" height="100%">
              <SingleMachinePDF
                company={selectedCompany}
                charts={charts}
                machine={selectedMachine}
                orientation={orientation}
                language={language}
              />
            </PDFViewer>
            : reload ?
              <Grid item style={{ height: "100%" }} container alignContent="center" justifyContent="center">
                <Grid item>
                  <LoadingButton
                    variant="contained"
                    onClick={e => reloadPDF(e)}
                    className={buttonStyle.button}
                    icon={faFilePdf}
                    text={'Update Preview'}//language change in button
                    fullWidth={false}
                    iconSize="1x"
                    color="primary"
                  />
                </Grid>
              </Grid>
              :
              <LoadingScreen
                loading={false}
                background="#666"
                colour="#fff"
                text="Loading PDF Preview"//language change in button
                position="relative"
              />
          }
          {/* </Grid> */}
        </Grid>
      </GlobalPdfDialog>
    </>
  );
};

export default ExportSingleMachine;
