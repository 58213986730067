import React, { useContext, useEffect, useState } from 'react';
import {
    Grid,
    TextField,
} from '@mui/material';
import {
    faPaperPlane,
} from '@fortawesome/pro-light-svg-icons';
import axiosRequest from '../../helpers/axiosRequest';
import useAsync from '../../Hooks/useAsync';
import { UserContext } from '../../store/UserContext';
import GlobalDialog from './GlobalDialog';
import LoadingButton from '../buttons/LoadingButton';
import { Storage } from 'aws-amplify';
//import config from '../../config';
import { fetchUsers } from '../../helpers/fetchUsers';
import Autocomplete, {
    createFilterOptions
} from '@mui/material/Autocomplete';
import setLanguageText from '../../helpers/setLanguageText';
import { LayoutContext } from '../../store/LayoutContext';

const filter = createFilterOptions();

const EmailPDF = ({ downloadObject, serviceID, companyName, title }) => {

    const { language } = useContext(LayoutContext); 
    const [emailOpen, setEmailOpen] = useState(false);
    const { user, authUser } = useContext(UserContext);
    const [emailList, setEmalList] = useState();
    const [emails, setEmails] = useState('');

    const {
        error,
        data,
        loading,
        logCompleted,
        logError,
        executeAsync,
        executeLog
    } = useAsync({
        axiosRequest: axiosRequest
    });

    const handleSubmit = async (e) => {

        e.preventDefault();
        //console.log(email)
        //console.log(PDFEmail)
        //console.log('test')
        //var reader = new FileReader();
        //reader.readAsDataURL(downloadObject.blob);
        //reader.onloadend = async () => {
        //var base64data = reader.result;
        //console.log(base64data);

        var blob = new Blob([downloadObject.blob], { type: "application/pdf;charset=utf-8" });
        let PDFUpload = await Storage.put(`reports/service-${serviceID}.pdf`, blob, {
            ACL: 'public-read',
            visibility: 'public',
            level: 'public',
        });

        if (PDFUpload) {

            let EmailList = emails.map(email => email.email);
            //console.log(EmailList)
            let response = await executeAsync({
                endpoint: 'notifications',
                api_key: authUser.api_key,
                method: 'post',
                body: {
                    user_id: user.id,
                    note_name: 'Email Service Report',
                    note_type: 'service',
                    note_frequency: { "check_freq": { "title": "Minutes", "value": "5" } },
                    note_sent: 0,
                    note_body: '',
                    note_action: { "action": "No action needed", "description": "Send a service report PDF" },
                    note_settings: {
                        "email": EmailList,
                        "id": serviceID,
                        "company": companyName,
                        //"base64": base64data
                    },
                    note_next_send: new Date()
                }
            })
            if (response) {
                await executeLog({
                    endpoint: 'activity',
                    api_key: authUser.api_key,
                    body: {
                        attributes: {
                            type: 'Emailed Service Report',
                            user_id: user.id,
                            sent_to: EmailList,
                            service_id: serviceID,
                            company_name: companyName,
                        }
                    }
                });
            }
        }
    }

    useEffect(() => {
        if (data) {
            //console.log(logError)
            // TODO handle if activity error
            if (logError) console.log('Something went wrong with the Activity Log');
            if (logCompleted || logError) {
                // Clean addNewEntry context
                setEmails();
                setEmailOpen(false);
            }
            setEmails();
            setEmailOpen(false);
        }
    }, [data, logCompleted, logError]);

    const openEmailDialog = e => {
        setEmailOpen(true)
    };
    const closeEmailDialog = e => {
        setEmailOpen(false)
    };
    // const handleEmailInput = e => {
    //     e.persist();
    //     setEmails(e.target.value)
    // };

    useEffect(() => {
        // fetch Users list
        //console.log(user)
        //only fetch user if email button is clicked
        if (emailOpen) {
            if (authUser.api_key) {
                fetchUsers({
                    api_key: authUser.api_key,
                    company_ids: user.companies_id,
                    user_level: user.level
                }).then(users => {
                    //let parsedUsers = users.map(user => parseUser(user))
                    //setEmalList(parsedUsers);
                    var regexItem = new RegExp('@', 'g');

                    let removeNonEmails = []
                    users.forEach((email, i) => {
                        //console.log(regexItem.test(email.email))
                        if (regexItem.test(email.email)) {
                            removeNonEmails.push(email)
                        }
                    })
                    //console.log(removeNonEmails)
                    removeNonEmails.sort(function (a, b) {
                        if (a.email < b.email) { return -1; }
                        if (a.email > b.email) { return 1; }
                        return 0;
                    })
                    setEmalList(removeNonEmails);

                });
            }
        }
    }, [emailOpen, authUser, user, setEmalList]);

    // console.log(emailList)
    // console.log(emails)

    const updateEmails = (e, newValue) => {
        if (typeof newValue === 'string') {
            setEmails({ title: newValue })
        } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            setEmails({ title: newValue.inputValue })
        } else {
            setEmails(newValue)
        }
    }

    return (
        <>
            <Grid item>
                <LoadingButton
                    icon={faPaperPlane}
                    text="Email PDF"
                    fullWidth={false}
                    //iconSize="1x"
                    color="primary"
                    onClick={openEmailDialog}
                    loading={loading}
                    disabled={data ? true : false}
                />
            </Grid>
            <GlobalDialog
                open={emailOpen}
                closeModal={closeEmailDialog}
                title="Email Service Report"
                handleSubmit={handleSubmit}
                buttonTitle="Email Service Report"
                successMessage="Email Service Report Success."
                fullWidth
                maxWidth="md"
                submit_disabled={data}
                loading={loading}
                error={error}
                data={data}
            >
                <Grid item>
                    <Autocomplete
                        aria-required
                        fullWidth
                        multiple // for having mutilple select
                        value={emails || []}
                        onChange={updateEmails}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                            const { inputValue } = params;
                            // Suggest the creation of a new value
                            const isExisting = options.some((option) => inputValue === option.title);
                            if (inputValue !== '' && !isExisting) {
                                filtered.push({
                                    email: inputValue,
                                    title: `Add "${inputValue}"`,
                                });
                            }
                            return filtered;
                        }}
                        //selectOnFocus
                        //clearOnBlur
                        handleHomeEndKeys
                        id="supervisor_email"
                        options={emailList || []}
                        getOptionLabel={option => {

                            // Value selected with enter, right from the input
                            if (typeof option === 'string') {
                                return option;
                            }
                            // Add "xxx" option created dynamically
                            if (option.inputValue) {
                                return option.inputValue;
                            }
                            // Regular option
                            return option.email;
                        }}
                        renderOption={(props, option) => <li {...props} key={option.id} >{option.email}</li>}
                        //freeSolo
                        renderInput={params => {
                            return (
                                <TextField
                                    {...params}
                                    //required
                                   label={setLanguageText(language,"Select or add multiple email addresses")}
                                    variant="outlined"
                                    type="text"
                                //autoComplete="true"
                                />
                            )
                        }}
                    />
                    {/* <TextField
                        type="email"
                        value={email || ''}
                        fullWidth
                        required
                        onChange={handleEmailInput}
                        variant="outlined"
                       label={setLanguageText(language,"Email to")}
                    /> */}
                </Grid>
            </GlobalDialog>
        </>
    )
}

export default EmailPDF;