import { Grid } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { fetchAdditives } from '../../../helpers/fetchAdditives';
import { fetchBrands } from '../../../helpers/fetchBrands';
import parseAdditives from '../../../helpers/parseAdditives';
import { AdditiveContext } from '../../../store/AdditiveContext';
import {
  SAVE_ADDITIVES
} from '../../../store/AdditiveReducers';
import { BrandsContext } from '../../../store/BrandsContext';
import { SAVE_BRANDS } from '../../../store/BrandsReducers';
import { UserContext } from '../../../store/UserContext';
import CardAdditives from './CardAdditive';
import AdditiveList from './AdditiveList';
import EditAdditive from './EditAdditive';
import AdditiveHeader from './AdditiveHeader';
import PageContent from '../../PageContent';
import AdditiveOptions from './AdditiveOptions';
import LoadingScreen from './../../reusable/LoadingScreen';
import { Alert } from '@mui/material';
import { LayoutContext } from '../../../store/LayoutContext';
import setLanguageText from '../../../helpers/setLanguageText';

const Additives = () => {
  //****** CONTEXT ******
  const { authUser } = useContext(UserContext);
  const {
    additives,
    additivesInUse,
    additivesCompare,
    dispatchAdditives,
    selectedAdditive,
    additiveOptions
  } = useContext(AdditiveContext);
  const { brands, dispatchBrands } = useContext(BrandsContext);
  const { language } = useContext(LayoutContext);

  //****** STATE ******
  const [isDialog, setIsDialog] = useState(false);
  const [loading, setLoading] = useState();
  const [additivesVisible, setAdditivesVisible] = useState([]);
  const [noAddititves, setNoAdditives] = useState(false);

  //****** USE EFFECT******

  // Fetch All Additives
  useEffect(() => {
    // only fetch Additives if NOT fetched before
    if (!additives.length) {
      fetchAdditives({
        "api_key": authUser.api_key
      }).then(   
        data => {
          if(data.length){
            dispatchAdditives({
              type: SAVE_ADDITIVES,
              payload: parseAdditives(data)
            });
          }
        }
      );
    } 
  }, [
    authUser,
    dispatchAdditives,
    additives
  ]);

  // //****** USE EFFECT******
  // // Fetch IN USE additives && wait for the Selected Company ID
  // useEffect(() => {
  //   // only fetch Additives if NOT fetched before
  //   if (!additivesInUse.length) {
  //     // Fetch Additives based on Company ID
  //     if (selectedCompany.company_id) {
  //       setLoading(true);
  //       fetchAdditives(authUser.api_key, selectedCompany.company_id).then(
  //         data => {
  //           const machinesUsingAdditive = data.filter(
  //             additive => additive.machines_using_additive
  //           );
  //           // Sort Machines Using Additive by Most Used
  //           const sortMostUsed = sortAdditives(
  //             machinesUsingAdditive,
  //             'most_used'
  //           );
  //           const sortAllAdditives = sortAdditives(data, 'most_used');
  //           dispatchAdditives({
  //             type: SAVE_ADDITIVES,
  //             payload: parseAdditives(sortAllAdditives)
  //           });
  //           dispatchAdditivesInUse({
  //             type: SAVE_ADDITIVES_IN_USE,
  //             payload: parseAdditives(sortMostUsed)
  //           });
  //           setLoading(false);
  //         }
  //       );
  //     }
  //   } else {
  //     //Additives already fetched
  //     setLoading(false);
  //   }
  // }, [
  //   setLoading,
  //   authUser.api_key,
  //   selectedCompany.company_id,
  //   dispatchAdditivesInUse,
  //   additivesInUse.length,
  //   dispatchAdditives
  // ]);

  useEffect(() => {
    // only fetch brands if NOT fetched before
    if (!brands.length) {
      fetchBrands(authUser.api_key).then(brands => {
        dispatchBrands({
          type: SAVE_BRANDS,
          payload: brands
        });
      });
    }
  }, [
    authUser, 
    brands, 
    dispatchBrands
  ]);

  //Show the correct Addititves
  useEffect(() => {
    if (additivesCompare && additivesCompare.length) {
      //console.log(coolants)
      setAdditivesVisible(additivesCompare);
      setNoAdditives(false);
    } else if (!additiveOptions.inUse && additives && additives.length) {
      //console.log(additives)
      setAdditivesVisible(additives);
      setNoAdditives(false);
    } else if (additiveOptions.inUse && additivesInUse) {
      if (additivesInUse) {
        //console.log(additivesInUse)
        if (additivesInUse.length) {
          setAdditivesVisible(additivesInUse);
          setNoAdditives(false);
        } else {
          setAdditivesVisible([]);
          setNoAdditives(true);
        }
      }
    }
  }, [additives, additivesInUse, additivesCompare, additiveOptions.inUse]);

  //****** FUNCTIONS ******
  //****** RENDER ******
  return (
    <>
      <AdditiveHeader setLoading={setLoading} />
      <PageContent>
        <LoadingScreen loading={!loading} background="#fafafa" colour="#666" text="Loading Additives" />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <AdditiveOptions />
          </Grid>
          {noAddititves && !loading ?
            <Alert severity="error">{setLanguageText(language , "No additives in use")}</Alert>
            : null}
          <Grid item xs={12} >
            {additivesVisible && (
              <Grid item container spacing={2} >
                { additiveOptions.toggleView === 'grid' ? (
                  additivesVisible.map((additive, i) =>
                  <Grid item xs={12} lg={6} xl={4} key={i}>
                      <CardAdditives
                        additive={additive}
                        setIsDialog={setIsDialog}
                      />
                    </Grid>
                  )
                ) : (
                    additivesVisible.map((additive, i) =>
                      <AdditiveList
                        key={i}
                        additive={additive}
                      />
                    )
                  )
                }
              </Grid>
            )}
          </Grid>
        </Grid>
      </PageContent>
      {isDialog && (
        <EditAdditive
          isDialog={isDialog}
          additive={selectedAdditive}
          setIsDialog={setIsDialog}
        />
      )}
    </>
  );
};

export default Additives;
