import {
  Chip,
  Grid,
  IconButton,
  TextField,
  Typography
} from '@mui/material';
import AddCircle from '@mui/icons-material/AddCircle';
import RemoveCircle from '@mui/icons-material/RemoveCircle';
import { Autocomplete, Alert } from '@mui/material';
import React, {
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { SelectionsContext } from '../../store/SelectionsContext';
import { UserContext } from '../../store/UserContext';
import parseAdditives from '../../helpers/parseAdditives';
import { fetchAdditives } from '../../helpers/fetchAdditives';
import { useTheme } from '@mui/styles';
import {
  ADD_ADDITIVE,
  REMOVE_ADDITIVE
} from '../../store/SelectionsReducers';
import { LayoutContext } from '../../store/LayoutContext';
import setLanguageText from '../../helpers/setLanguageText';
// import axiosRequest from '../../helpers/axiosRequest';
// import useAsync from '../../Hooks/useAsync';


function AddititveEntries({ setError, errors, edit }) {
  //****************** STYLES******************
  const theme = useTheme();

  //****************** CONTEXT ******************
  const { addNewEntry, dispatchAddNewEntry } = useContext(SelectionsContext);
  const { authUser } = useContext(UserContext);
  const { language } = useContext(LayoutContext)

  //****************** STATE ******************
  const [additives, setAdditives] = useState([]);
  const [selectedAdditive, setSelectedAdditive] = useState(null);
  const [additiveObj, setAdditiveObj] = useState();
  const [addedAdditives, setAddedAdditives] = useState([]);

  // const {
  //   error,
  //   data,
  //   loading,
  //   logCompleted,
  //   logError,
  //   executeAsync,
  //   executeLog,
  //   setData
  // } = useAsync({
  //   axiosRequest: axiosRequest
  // });

  //****************** CALLBACKS ******************
  const fetchAdditivesCB = useCallback(async () => {
    try {
      const additives = await fetchAdditives({
        "api_key": authUser.api_key
      });
      setAdditives(parseAdditives(additives));
      //console.log(additives)
    } catch (error) {
      console.log(error);
    }
  }, [ authUser.api_key]);

  //****************** USE EFFECT ******************
  useEffect(() => {
    if (addNewEntry.company) fetchAdditivesCB();
  }, [addNewEntry.company, fetchAdditivesCB]);

  useEffect(() => {
    if (addNewEntry.entry?.additives) {
      setAddedAdditives(addNewEntry.entry.additives);
    }
  }, [addNewEntry.entry]);

  //****************** FUNCTIONS ******************

  const handleAdditiveSelect = (e, value) => {

    if (value) {
      setAdditiveObj(prevState => ({
        ...prevState,
        additive_id: value.additive_id,
        additive_name: value.additive_name,
        additive_type: value.additive_type_name,
        additive_type_id: value.additive_type_id
      }));
      setSelectedAdditive(value);
      // TODO Need to ask - Remove selected Additive from options in order to not have duplicate
      // setAdditives(prevState =>
      //   prevState.filter(add => add.additive_id !== value.additive_id)
      // );
      setError({
        attr_id: "addititves",
        type: "warning",
        message: "You haven't added the additive above, please click the plus button."
      })

    } else {
      setAdditiveObj({});
      setSelectedAdditive(null);
    }
  };

  const handleDosage = e => {
    e.persist();
    setAdditiveObj(prevState => ({
      ...prevState,
      additive_details: e.target.value
    }));
  };

  const handleAddAdditive = () => {
    console.log('remove Error')
    setError({
      attr_id: "addititves",
      type: "none"
    })
    dispatchAddNewEntry({ type: ADD_ADDITIVE, payload: additiveObj });
    setAdditiveObj({});
    setSelectedAdditive(null);
  };

  const handleRemoveAdditive = additive => {
    dispatchAddNewEntry({ type: REMOVE_ADDITIVE, payload: additive });
    // TODO when remove additive from added list, put back in the option for selection in dropdown
  };

  console.log(errors)

  return (
    <Grid container alignItems="center">
      <Grid item xs={12} sm={6}>
        <Autocomplete

          fullWidth
          options={additives}
          // inside the input when selected
          getOptionLabel={option =>
            Object.entries(option).length
              ? `${option.additive_name} - ${option.additive_attr.type}`
              : ''
          }
          renderInput={params => (
            <TextField
              {...params}
             label={setLanguageText(language,"Select additive")}
              variant="outlined"
            />
          )}
          value={selectedAdditive ? selectedAdditive : {}}
          onChange={handleAdditiveSelect}
          // renderOption={option =>
          //   `${option.additive_name} - ${option.additive_attr.type}`
          // }
        />
      </Grid>
      {selectedAdditive && (
        <Grid item xs={12} sm={6}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item container justifyContent="center" xs={5}>
              <Chip label={selectedAdditive.additive_attr.dilution} />
            </Grid>
            <Grid item xs={5}>
              <TextField
                required
                id="dosage"
               label={setLanguageText(language,"Dosage")}
                variant="outlined"
                size="small"
                onChange={handleDosage}
              />
            </Grid>
            <Grid item xs={2}>
              <IconButton
                onClick={() => handleAddAdditive()}
                // TODO need to check if the requested field is not empty
                type="submit"
                size="large">
                <AddCircle style={{ color: theme.palette.success.main }} />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      )}
      {addedAdditives.map(addedAdditive => {
        return (
          <Grid
            item
            container
            key={addedAdditive.additive_id}
            alignItems="center"
            xs={12}
            style={{marginTop : '5px'}}
          >
            <Grid item xs={10}>
              <Alert severity="success" >
              <Typography>
              {setLanguageText(language,"Additive")}: {addedAdditive.additive_name} - {addedAdditive.additive_type} ({addedAdditive.additive_details})
              </Typography>
            </Alert>
             
            </Grid>
            <Grid item xs={2}>
              <IconButton onClick={() => handleRemoveAdditive(addedAdditive)} size="large">
                <RemoveCircle style={{ color: theme.palette.error.main }} />
              </IconButton>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
}

export default AddititveEntries;
