import React, { useContext } from 'react';
import { Grid } from '@mui/material';
import DropdownCompanies from '../../Dashboard/Selection_Row/DropdownCompanies';
import OutOfControl from './OutOfControl';
import GridView from './GridView';
import DisplayView from './DisplayView';
import SelectionRow from '../../Dashboard/Selection_Row/SelectionRow';
import GroupDropdown from '../../Dashboard/Selection_Row/DropdownGroups';
//import PdfButton from "../../buttons/PdfButton";
import ExportMachines from '../../Exports/ExportMachines';
import { isMobileOnly } from 'react-device-detect';
import { MachineContext } from '../../../store/MachineContext';

const MachineHeader = ({ columns, data, setLoading }) => {

  const { gridView } = useContext(MachineContext);
  
  return (
    <Grid item container spacing={1} justifyContent="space-between">
      <SelectionRow />
      {/* HEADER ONE */}
      <Grid item container xs={12} md={5} spacing={1} justifyContent="space-between">
        {/* COMPANIES DROPDOWN */}
        <Grid item xs={12} sm={6}>
          <DropdownCompanies fetchEntries={true} setLoading={setLoading} />
        </Grid>
        {/* GROUPS DROPDOWN */}
        <Grid item xs={12} sm={6}>
          <GroupDropdown setLoading={setLoading}  />
        </Grid>
      </Grid>
      {/* HEADER TWO */}
      <Grid
        alignItems="stretch"
        item
        container
        xs={12}
        md={7}
        spacing={1}
        justifyContent="space-between"
      >
        {/* BUTTONS */}
        <Grid item xs={12} sm={3}>
          <OutOfControl />
        </Grid>
        {!isMobileOnly && (
          <>
           
            {/* <Grid item xs={6} sm={3}>
              <DisplayView />
            </Grid> */}
            { !gridView ? 
            <Grid item xs={6} sm={3}>
              <ExportMachines columns={columns} data={data} />
            </Grid>
            : null } 
            <Grid item xs={6} sm={3}>
              <GridView />
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default MachineHeader;
