import { useTheme } from '@mui/styles';
import { useState, useRef, useMemo, useContext, useCallback } from 'react';
import formatDate from '../../helpers/formatDate';
import { ChartsContext } from '../../store/ChartsContext';
import { SAVE_CHARTS } from '../../store/ChartsReducers';

const useConcChart = ({ plots, tooltips }) => {
  const [labels, setLabels] = useState();
  const [concentrations, setConcentrations] = useState();
  const [concMax, setConcMax] = useState();
  const [concMin, setConcMin] = useState();
  const colour = useTheme().palette.background.darker;
  const { dispatchCharts } = useContext(ChartsContext);
  const chartRef = useRef(null);
  const [base64Image, setBase64Image] = useState(null);

  const createConcChart = useCallback(entries => {
    let labelsObject = [];
    let concentrationsObject = [];
    let concMaxObject = [];
    let concMinObject = [];

   
    //console.log(entries)

    entries.forEach(entry => {
      let factor = entry.original.factor
      labelsObject.push(formatDate(entry.original.date_stamp).slice(0, -5));
      const details = entry.original.attributes_new.find(
        attr => attr.attribute_id === 'conc'
      );
      if (details) {
        //console.log(details.attribute_values)
        if(factor){
          concentrationsObject.push((Number(details.attribute_values.value) * Number(factor ? factor : 1)).toFixed(1))
        } else {
           concentrationsObject.push(details.attribute_values.value > 0 ? details.attribute_values.value : 'N/A');
        }
        concMaxObject.push(details.attribute_values.max);
        concMinObject.push(details.attribute_values.min);
      }
    });
    setConcMax(concMaxObject);
    setConcMin(concMinObject);
    setConcentrations(concentrationsObject);
    setLabels(labelsObject);
  }, []);

  const data = useMemo(() => {
    return {
      labels: labels,
      datasets: [
        {
          data: concentrations,
          label: 'CONC %',
          fill: false,
          spanGaps: true,
          pointRadius: plots ? 4 : 0,
          lineTension: 0.3,
          pointBackgroundColor: colour,
          pointHoverRadius: 6,
          borderColor: colour,
          borderWidth: 2,
          datalabels: tooltips
            ? {
                align: 'center',
                anchor: 'center'
              }
            : null
        },
        // Show a red background for concentration out of MAX
        {
          spanGaps: true,
          data: concMax,
          label: 'MAX',
          backgroundColor: 'rgba(252, 70, 112, 0.1)',
          borderColor: 'rgb(255, 197,0, 0.100)',
          pointBackgroundColor: 'rgba(252, 70, 112, 0.1)',
          pointBorderColor: 'rgba(252, 70, 112, 0.1)',
          fill: 'end',
          lineTension: 0,
          pointHoverBorderWidth: 0,
          pointHoverRadius: 0
        },
        // Show a green background for concentration in range
        {
          spanGaps: true,
          data: concMin,
          label: 'IN_RANGE',
          backgroundColor: 'rgba(0, 215, 154, 0.1)',
          borderColor: 'rgb(255, 197,0, 0.100)',
          fill: '-1',
          lineTension: 0,
          pointHoverBorderWidth: 0,
          pointHoverRadius: 0
        },
        // Show a red background for concentration out of MIN
        {
          spanGaps: true,
          data: concMin,
          label: 'MIN',
          backgroundColor: 'rgba(252, 70, 112, 0.1)',
          borderColor: 'transparent',
          pointBackgroundColor: 'rgba(252, 70, 112, 0.1)',
          pointBorderColor: 'rgba(252, 70, 112, 0.1)',
          fill: 'origin',
          lineTension: 0,
          pointHoverBorderWidth: 0,
          pointHoverRadius: 0
        }
      ]
    };
  }, [labels, concentrations, plots, colour, tooltips, concMax, concMin]);


  const options = useMemo(() => {
    return {
      pointRadius: 5,
      legend: {
        display: false
      },
      layout: {
        padding: 20
      },
      elements: {
        point: {
          radius: 0,
          hitRadius: 10,
          hoverRadius: 5
        }
      },
      plugins: {
        datalabels: {
          backgroundColor: colour,
          borderRadius: 4,
          color: 'white',
          font: {
            weight: 'bold'
          },
          display: context => (context.dataset.datalabels ? true : false),
          padding: 4
        }
      },
      title: {
        display: false,
        text: 'Concentration levels',
        fontSize: 20
      },
      scales: {
        yAxes: [
          {
            display: true,
            ticks: {
              stepSize: 1,
              suggestedMax: concMax && concMax[0] + 1,
              suggestedMin: concMin && concMin[0] - 1
            }
          }
        ]
      },
      animation: {
        onComplete: () => {
          let b64 = chartRef.current.chartInstance.toBase64Image();
          setBase64Image(b64);
          dispatchCharts({
            type: SAVE_CHARTS,
            payload: {
              conc_base64: b64
            }
          });
        }
      }
    };
  }, [colour, concMax, concMin, dispatchCharts]);

  return {
    createConcChart,
    concData: data,
    concOptions: options,
    concBase64: base64Image,
    concChartRef: chartRef
  };
};

export default useConcChart;
