import { Button, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect, useState, useContext } from 'react';
import GlobalDialog from '../reusable/GlobalDialog';
import SelectionRow from '../Dashboard/Selection_Row/SelectionRow';
import { Autocomplete } from '@mui/material';
import { CompanyContext } from '../../store/CompanyContext';
import axiosRequest from '../../helpers/axiosRequest';
import useAsync from '../../Hooks/useAsync';
import { UserContext } from '../../store/UserContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import { buttonStyles } from '../../styles/buttonStyles';
import { faExternalLink } from '@fortawesome/pro-light-svg-icons';
import removeAllOption from '../../helpers/removeAllOption';
import setLanguageText from '../../helpers/setLanguageText';
import { LayoutContext } from '../../store/LayoutContext';

const Machines = ({ isOpen, closeDialog }) => {

  const [options, setOptions] = useState();
  const { companies } = useContext(CompanyContext);
  const { authUser } = useContext(UserContext);
  const { language } = useContext(LayoutContext)
  const [selectedMachine, setSelectedMachine] = useState();
  const [selectedCompany, setSelectedCompany] = useState();
  const buttonStyle = buttonStyles();
  let history = useHistory();

  const {
    data,
    executeAsync
  } = useAsync({
    axiosRequest: axiosRequest
  });

  const fetchMachines = async (company_id) => {
    await executeAsync({
      api_key: authUser.api_key,
      method: 'get',
      endpoint: 'machines',
      params: {
        company_id: company_id
      }
    });
  }

  useEffect(() => {
    if (data) {
      setOptions(data)
    }
  }, [data, setOptions])

  useEffect(() => {
    //console.log(filteredMachines)
    if (selectedCompany) {
      //console.log(selectedCompany)
      fetchMachines(selectedCompany.company_id)
    }
  }, [selectedCompany]);

  const onSelectMachine = (machine) => {
    setSelectedMachine(machine);
  }

  return (
    <GlobalDialog
      open={isOpen}
      closeModal={closeDialog}
      title="Machine Search"
      fullWidth
      maxWidth="sm"
    >
      <Grid container spacing={1}>
        <SelectionRow />
        <Grid item xs={12}>
          <Autocomplete
            disableClearable
            id="selected-company"
            value={selectedCompany ? selectedCompany : null}
            options={removeAllOption(companies) || []}
            loading={companies ? false : true}
            getOptionLabel={option => option.company_name}
            isOptionEqualToValue={(option, value) =>
              option.company_id === value.company_id
            }
            onChange={(e, newValue) => {
              setSelectedCompany(newValue);
              setSelectedMachine();
            }}
            renderInput={params => (
              <TextField {...params} variant="outlined"label={setLanguageText(language,"Selected Company")} />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            disableClearable
            id="selected-machine"
            value={selectedMachine ? selectedMachine : null}
            options={options || []}
            getOptionLabel={option => option.machine_name}
            isOptionEqualToValue={(option, value) =>
              option.machine_id === value.machine_id
            }
            onChange={(event, newValue) => {
              onSelectMachine(newValue);
            }}
            renderInput={params => (
              <TextField {...params} variant="outlined"label={setLanguageText(language,"Selected Machine")} />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Button
            fullWidth
            variant="contained"
            component="label"
            color="primary"
            disabled={selectedMachine ? false: true}
            onClick={e => history.push({ pathname: `/machine/${selectedMachine.machine_id}` })}
          >
            <FontAwesomeIcon icon={faExternalLink} />
            <Typography className={`${buttonStyle.buttonIconText}`}>
            {setLanguageText(language,"Go To Machine")}
            </Typography>
          </Button>
        </Grid>
      </Grid>


    </GlobalDialog>
  );
};

export default Machines;
