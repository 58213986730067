import React, { useContext, useEffect, useState } from 'react';
import { fetchCoolants } from '../../../helpers/fetchCoolants';
import parseCoolants from '../../../helpers/parseCoolants';
import sortCoolants from '../../../helpers/sortCoolants';
import { CompanyContext } from '../../../store/CompanyContext';
import { CoolantContext } from '../../../store/CoolantContext';
import {
  Grid
} from '@mui/material';
import { BrandsContext } from '../../../store/BrandsContext';
import { SAVE_BRANDS } from '../../../store/BrandsReducers';
import { fetchBrands } from '../../../helpers/fetchBrands';
import { UserContext } from '../../../store/UserContext';
import CoolantCard from './CoolantCard';
import CoolantList from './CoolantList';
import EditCoolant from './EditCoolant';
import CoolantsHeader from './CoolantsHeader';
import PageContent from '../../PageContent';
import LoadingScreen from './../../reusable/LoadingScreen'
import {
  SAVE_COOLANTS_IN_USE,
  SAVE_COOLANTS
} from '../../../store/CoolantReducers';
import CoolantOptions from './CoolantOptions'
import { Alert } from '@mui/material';

const Coolants = () => {
  const { authUser, user } = useContext(UserContext);
  const { selectedCompany } = useContext(CompanyContext);
  const {
    dispatchCoolants,
    coolants,
    coolantOptions,
    coolantsInUse,
    coolantsCompare,
    dispatchCoolantsInUse,
    selectedCoolant
  } = useContext(CoolantContext);
  const { brands, dispatchBrands } = useContext(BrandsContext);
  const [isEditDialog, setIsEditDialog] = useState(false);
  const [coolantsVisable, setCoolantsVisable] = useState([])
  const [loading, setLoading] = useState();
  const [fetched, setFetched] = useState();

  // by default Coolants are fetched based on the Company id (IN USE)
  useEffect(() => {
    // Fetch Coolants based on Company ID
    if (Object.values(selectedCompany).length) {
      setFetched(false);
      setLoading(true);   
      let params =  {
        company_id: selectedCompany.company_id
      } 
      if(selectedCompany.company_id === 0 && user.level !== 9 ){
        params =  {
          company_ids : JSON.stringify(user.companies_id)
        }
      }
      //console.log(params)
      fetchCoolants({
        api_key: authUser.api_key,
        ...params
      }).then(coolants => {
        if (coolants.length) {
          // Sort fetched coolants by Most Used
          const sortMostUsed = sortCoolants(coolants, 'most_used');
          // save sorted coolants into context
          dispatchCoolantsInUse({
            type: SAVE_COOLANTS_IN_USE,
            payload: parseCoolants(sortMostUsed)
          });
          setFetched(true);
        } else {
          dispatchCoolantsInUse({
            type: SAVE_COOLANTS_IN_USE,
            payload: []
          });
          setFetched(true);
        }
      });
    }
  }, [
    authUser,
    user,
    dispatchCoolantsInUse,
    selectedCompany.company_id
  ]);

  useEffect(() => {

    if (!coolantOptions.inUse && !coolants.length) {
      setFetched(false);
      setLoading(true);
      //fetch ALL the Coolants
      fetchCoolants({
        api_key: authUser.api_key
      }).then(coolants => {
        // Sort fetched coolants by Most Used
        const sortMostUsed = sortCoolants(coolants, 'most_used');
        // save sorted coolants into context
        dispatchCoolants({
          type: SAVE_COOLANTS,
          payload: parseCoolants(sortMostUsed)
        });
        setFetched(true);

      });
    }
  }, [coolantOptions, authUser.api_key, dispatchCoolants, coolants]);

  useEffect(() => {
    // only fetch brands if NOT fetched before
    if (!brands.length) {
      fetchBrands(authUser.api_key).then(brands => {
        dispatchBrands({
          type: SAVE_BRANDS,
          payload: brands
        });
      });
    }
  }, [authUser.api_key, brands, dispatchBrands]);

  //Show the correct Addititves
  useEffect(() => {

    if (coolantsCompare && Object.values(coolantsCompare).length) {
      //console.log('set compare')
      setCoolantsVisable(coolantsCompare);

    } else if (!coolantOptions.inUse && Object.values(coolants).length) {
      //console.log('set all coolants')
      setCoolantsVisable(coolants);

    } else if (coolantOptions.inUse && coolantsInUse) {
      setCoolantsVisable(coolantsInUse);
    }

  }, [fetched, coolants, coolantsInUse, coolantsCompare, coolantOptions]);

  useEffect(() => {

    if (fetched) {
      if (Object.values(coolantsVisable).length) {
        setLoading(false);
        setFetched(false);
      } else {
        setLoading(false);
        setFetched(false);
      }
    }
  }, [fetched, coolantsVisable]);

  const openEditDialog = () => setIsEditDialog(true);
  const closeEditDialog = () => setIsEditDialog(false);

  return (
    <>
      <CoolantsHeader />
      <PageContent>
        <LoadingScreen loading={!loading} background="#fafafa" colour="#666" text="Loading Coolants" />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {Object.values(coolantsVisable).length && !loading ?
              <CoolantOptions />
              :
              null}
            {!Object.values(coolantsVisable).length && !loading ?
              <Alert severity="error">No coolants in use</Alert>
              : null}
          </Grid>
          <Grid item container spacing={2}>
            {Object.values(coolantsVisable).length ? (
              <Grid item container spacing={2} >
                { coolantOptions.toggleView === 'grid' ? (
                  <>
                    {coolantsVisable.map((coolant, i) => (
                      <Grid item xs={12} lg={6} xl={4} key={i}>

                        <CoolantCard
                          coolant={coolant}
                          openEditDialog={openEditDialog}
                          timeOut={i}
                          noBar={Boolean(coolantOptions.inUse)}
                        />

                      </Grid>
                    ))}
                  </>
                ) : (
                    <>
                      {coolantsVisable.map((coolant, i) => (
                        <CoolantList key={i} coolant={coolant} />
                      ))}
                    </>
                  )}
              </Grid>
            ) : null}
          </Grid>
        </Grid>
          <EditCoolant
            coolant={selectedCoolant}
            closeEditDialog={closeEditDialog}
            isEditDialog={isEditDialog}
          />
      </PageContent>
    </>
  );
};

export default Coolants;
