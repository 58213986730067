import { TextField } from '@mui/material';
import { Alert, Autocomplete } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import axiosRequest from '../../helpers/axiosRequest';
import setLanguageText from '../../helpers/setLanguageText';
import useAsync from '../../Hooks/useAsync';
import { UserContext } from '../../store/UserContext';
import { LayoutContext } from '../../store/LayoutContext';

const DropdownCoolantsManufacturers = ({ setManufacturer }) => {
  const { authUser } = useContext(UserContext);
  const { language } = useContext(LayoutContext); 
  const [manufacturers, setManufacturers] = useState([]);
  const [value, setValue] = useState(null);
  const { error, data, loading, executeAsync } = useAsync({
    axiosRequest: axiosRequest
  });

  useEffect(() => {
    if (!manufacturers.length) {
      executeAsync({
        method: 'get',
        api_key: authUser.api_key,
        endpoint: 'brands'
      });
    }
  }, [authUser.api_key, executeAsync, manufacturers.length]);

  useEffect(() => {
    if (data) {
      setManufacturers(data);
      //setValue(data[0]);
    }
  }, [data]);

  useEffect(() => {
    setManufacturer(value);
  }, [setManufacturer, value]);

  const onSelectManufacturer = (e, manufacturer) => {
    setValue(manufacturer);
  };
  if (error) return <Alert severity="error">{error.message}</Alert>;
  return (
    <Autocomplete
    disableClearable
      id="manufacturers"
      options={manufacturers}
      loading={loading}
      getOptionLabel={option => option.brand_name || ''}
      renderInput={params => (
        <TextField
          required
          {...params}
         label={setLanguageText(language,"Select Manufacturer")}
          variant="outlined"
        />
      )}
      value={value}
      onChange={onSelectManufacturer}
    />
  );
};

export default DropdownCoolantsManufacturers;
