
import React from 'react';
import { DialogContent, StepLabel, Step, Stepper, Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CompanySelect from './CompanySelect';
import StandardEntries from './StandardEntries';
import CustomEntries from './CustomEntries';
import NotesEntries from './NotesEntries';
import BacteriaFungiEntries from './BacteriaFungiEntries';
import AddititveEntries from './AddititveEntries';
import { isMobileOnly } from 'react-device-detect';
import config from '../../config';

const useStyles = makeStyles(theme => ({
    title: {
        backgroundColor: theme.palette.background.light,
        padding: `${theme.spacing(1)} ${theme.spacing(2)}`
    },
    stepper: {
        backgroundColor: theme.palette.background.light,
        padding: theme.spacing(2),
        '& .MuiStepIcon-completed': {
            color: 'green'
        }
    }
}));

function getStepContent(
    activeStep,
    setError,
    errors,
    edit,
    has_custom_attributes
) {
    //console.log(edit)
    if(config.APPNAME === 'rhenus'){
        switch (activeStep) {
            case 0:
                return edit ? 
                    <StandardEntries {...{ setError, errors, edit }} /> //edit 
                    :<CompanySelect {...{ setError, errors, edit }} />; //add 
            case 1:
                return edit ?
                     <CustomEntries {...{ setError, errors, edit }} /> //edit 
                    : <StandardEntries {...{ setError, errors, edit }} /> //add 
            case 2:
                return edit ?
                    <NotesEntries {...{ setError, errors, edit }} /> //edit 
                    :<CustomEntries {...{ setError, errors, edit }} /> //add 
            case 3:
                return edit ?
                    <AddititveEntries {...{ setError, errors, edit }} /> //edit 
                    : <NotesEntries {...{ setError, errors, edit }} /> //add 

            case 4:
                return !edit && <AddititveEntries {...{ setError, errors, edit }} /> ; //add 
            default:
                return 'Unknown step';
        }
    } else {
        switch (activeStep) {
            case 0:
                return edit ? (<StandardEntries {...{ setError, errors, edit }} />) //edit 
                    : (<CompanySelect {...{ setError, errors, edit }} />); //add 
            case 1:
                return edit ?
                    has_custom_attributes ? (<CustomEntries {...{ setError, errors, edit }} />) //edit & custom
                        : (<BacteriaFungiEntries {...{ setError, errors, edit }} />) //edit & no custom
                    : (<StandardEntries {...{ setError, errors, edit }} />); //add 
            case 2:
                return edit ?
                    has_custom_attributes ? (<BacteriaFungiEntries {...{ setError, errors, edit }} />) //edit & custom
                        : (<NotesEntries {...{ setError, errors, edit }} />) //edit & no custom
                    : has_custom_attributes ? (<CustomEntries {...{ setError, errors, edit }} />) //add & custom
                        : (<BacteriaFungiEntries {...{ setError, errors, edit }} />); //add & no custom
            case 3:
                return edit ?
                    has_custom_attributes ? (<NotesEntries {...{ setError, errors, edit }} />) //edit & custom
                        : (<AddititveEntries {...{ setError, errors, edit }} />) //edit & no custom
                    : has_custom_attributes ? (<BacteriaFungiEntries {...{ setError, errors, edit }} />) //add & custom
                        : (<NotesEntries {...{ setError, errors, edit }} />); //add & no custom

            case 4:
                return edit ?
                    has_custom_attributes && (<AddititveEntries {...{ setError, errors, edit }} />) //edit & custom 
                    : has_custom_attributes ? (<NotesEntries {...{ setError, errors, edit }} />) //add & custom
                        : (<AddititveEntries {...{ setError, errors, edit }} />); //add & no custom
            case 5:
                return !edit && has_custom_attributes && (<AddititveEntries {...{ setError, errors, edit }} />); //add & custom
            default:
                return 'Unknown step';
        }
    }
}


export const EntryStepper = ({
    setMachine,
    edit,
    steps,
    activeStep,
    setActiveStep,
    errors,
    setError,
    has_custom_attributes
}) => {

    //****** STYLES ******
    const classes = useStyles();
    //console.log(errors)

    return (
        <>
            {!isMobileOnly &&
                <>
                    <Divider />
                    <Stepper
                        activeStep={activeStep}
                        alternativeLabel
                        className={classes.stepper}
                    >
                        {steps.map((label, i) => (
                            <Step key={label}>
                                <StepLabel
                                    onClick={e => {
                                        if (edit) {
                                            setActiveStep(i)
                                        }
                                    }}
                                >{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </>
            }
            <DialogContent dividers>
                {getStepContent(
                    activeStep,
                    setError,
                    errors,
                    edit,
                    has_custom_attributes
                )}
            </DialogContent>
        </>

    );
};

export default EntryStepper;
