import React, { useContext, useState, useEffect } from 'react';
import { FormControlLabel, Grid, Switch, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { UserContext } from '../../store/UserContext';
import { CompanyContext } from '../../store/CompanyContext';
import PageContent from '../PageContent';
import PageHeader from '../PageHeader';
import LoadingButton from './../buttons/LoadingButton';
import { faSave } from '@fortawesome/pro-light-svg-icons';
import axiosRequest from '../../helpers/axiosRequest';
import useAsync from '../../Hooks/useAsync';
import setLanguageText from '../../helpers/setLanguageText';
import { LayoutContext } from '../../store/LayoutContext';

const useStyles = makeStyles(theme => ({
  card: {
    width: '100%'
  },
  header: {
    borderBottom: `${theme.spacing(0.4)} solid ${theme.palette.background.light
      }`,
    padding: theme.spacing(1)
  }
}));

const CompanyNotification = ({edit}) => {

  //************  CONTEXT ************
  const { authUser } = useContext(UserContext);
  const { selectedCompany } = useContext(CompanyContext);
  const { language } = useContext(LayoutContext);
  const {
    loading,
    executeAsync
  } = useAsync({
    axiosRequest: axiosRequest
  });

  const defaultOptions = { "alerts": { "email": { "operator": false, "supervisor": false }, "notifications": { "operator": false, "supervisor": false } } }
  const [switches, setSwitches] = useState(defaultOptions);

  //************  STYLES ************
  const classes = useStyles();

  //************  USE EFFECTS ************
  useEffect(() => {

    if (selectedCompany.company_settings) {
      //console.log(selectedCompany.company_settings)
      setSwitches(selectedCompany.company_settings);
    } else {
      setSwitches(defaultOptions);
    }
  }, [selectedCompany])

  //************  FUNCTIONS ***********

  const saveNotification = async (e) => {
    e.preventDefault();
    await executeAsync({
      endpoint: 'company/settings',
      api_key: authUser.api_key,
      method: 'patch',
      body: {
        company_id: selectedCompany.company_id,
        company_settings: switches
      }
    });
    //TODO = update context
  }
  
  return (
    <>
      <PageHeader>
        <Grid item xs={12}>
          <Grid
            item
            container
            justifyContent="space-between"
            className={classes.header}
          >
            <Grid item xs={12} sm={'auto'}>
              <Typography variant="h5">{setLanguageText(language,"Notifications")}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </PageHeader>
      <PageContent className="notification">
        <Grid item container spacing={2}>

          <Grid item>
            <Typography variant="h6">{setLanguageText(language,"Machine Check Frequency Emails/Notification")}</Typography>
            <Typography >{setLanguageText(language,"Enable emails and in app notifications based on the machine coolant check frenquency.")}</Typography>
          </Grid>

          {switches ?
            <Grid item container direction="column">
              <FormControlLabel
                control={<Switch checked={switches?.alerts?.email?.operator || false} onChange={(e, value) =>
                  setSwitches(current => {
                    let swiches = { ...current }
                    swiches.alerts.email.operator = value
                    return swiches
                  })
                } name="operator" />}
               label={setLanguageText(language,"Enable Operator Emails")}
                disabled={!edit}
              />
              <FormControlLabel
                control={<Switch checked={switches?.alerts?.email?.supervisor || false} onChange={(e, value) =>
                  setSwitches(current => {
                    let swiches = { ...current }
                    swiches.alerts.email.supervisor = value
                    return swiches
                  })
                } name="superviser" />}
               label={setLanguageText(language,"Enable Supervisor Emails")}
                disabled={!edit}
              />
              <FormControlLabel
                control={<Switch checked={switches?.notifications?.email?.operator || false} onChange={(e, value) =>
                  setSwitches(current => {
                    let swiches = { ...current }
                    swiches.notifications.email.operator = value
                    return swiches
                  })
                } name="operator" />}
               label={setLanguageText(language,"Enable Operator App Notifications - coming soon")}
                disabled
              />
              <FormControlLabel
                control={<Switch checked={switches?.notifications?.email?.supervisor || false} onChange={(e, value) =>
                  setSwitches(current => {
                    let swiches = { ...current }
                    swiches.notifications.email.supervisor = value
                    return swiches
                  })
                } name="superviser" />}
               label={setLanguageText(language,"Enable Supervisor App Notifications - coming soon")}
                disabled
              />
            </Grid>
            : null}
          <Grid>
            <LoadingButton
              icon={faSave}
              text="Save Notifications"
              iconSize="1x"
              color="primary"
              loading={loading}
              onClick={e => saveNotification(e)}
              disabled={!edit}
            />
          </Grid>
        </Grid>
      </PageContent>
    </>

  )
};

export default CompanyNotification;
