import { faExternalLink } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import axiosRequest from '../../helpers/axiosRequest';
import parseCompanies from '../../helpers/parseCompanies';
import parseEntries from '../../helpers/parseEntries';
import parseMachines from '../../helpers/parseMachines';
import useAsync from '../../Hooks/useAsync';
import { UserContext } from '../../store/UserContext';
import { buttonStyles } from '../../styles/buttonStyles';
import PageContent from '../PageContent';
import PageHeader from '../PageHeader';
import LoadingScreen from '../reusable/LoadingScreen';
import EditEntry from './EditEntry';

//SET ENTRY IN CONTEXT AND USE THAT

const FullEntry = () => {

    const history = useHistory();
    const buttonStyle = buttonStyles();
    let { id, entry_id } = useParams();
    const { authUser, user } = useContext(UserContext);
    const [entry, setEntry] = useState();
    const [company, setCompany] = useState();
    const [machine, setMachine] = useState();
    const [open, setOpen] = useState(true);

    const {
        data,
        executeAsync
    } = useAsync({
        axiosRequest: axiosRequest
    });

    const getEntry = async (entry_id) => {
        try {
            await executeAsync({
                endpoint: 'machines/entries',
                api_key: authUser.api_key,
                method: 'get',
                params: {
                    entry_ids: entry_id
                }
            });
            //window.location.reload();
        } catch (error) {
            throw error;
        }
    }

    useEffect(() => {
        if (entry_id) {
            getEntry(entry_id);
        }
    }, [entry_id])

    useEffect(() => {
        if (data) {
            setEntry(parseEntries(data))
            getCompany(data[0].company_id)
            getMachine(data[0].machine_id);
        }
    }, [data ])

    const {
        data: company_data,
        executeAsync: company_executeAsync
    } = useAsync({
        axiosRequest: axiosRequest
    });

    const getCompany = async (company_id) => {
        try {
            await company_executeAsync({
                endpoint: 'companies',
                api_key: authUser.api_key,
                method: 'get',
                params: {
                    user_level: user.level,
                    id: company_id
                }
            });
            //window.location.reload();
        } catch (error) {
            throw error;
        }
    }
    //set company
    useEffect(() => {
        if (company_data) {
            setCompany(parseCompanies(company_data))
        }
    }, [company_data])

    const {
        data: machine_data,
        executeAsync: machine_executeAsync
    } = useAsync({
        axiosRequest: axiosRequest
    });

    const getMachine = async (machine_id) => {
        try {
            await machine_executeAsync({
                endpoint: 'machines',
                api_key: authUser.api_key,
                method: 'get',
                params: {
                    user_level: user.level,
                    machine_id: machine_id,
                    company_ids: JSON.stringify(user.companies_id)
                }
            });
            //window.location.reload();
        } catch (error) {
            throw error;
        }
    }

    useEffect(() => {
        if (machine_data) {
            setMachine(parseMachines(machine_data))
        }
    }, [machine_data])

    const closeDialog = () =>{
        setOpen(false);
        history.push(`/machine/${id}`)
    }

    //console.log(company && company[0])
    //console.log(entry && entry[0])

    return (
        <>
            <PageHeader>
                <Grid item container alignItems="stretch" justifyContent="space-between" spacing={2}>
                    <Grid item style={{ alignSelf: 'center' }}>
                        <Typography variant="h5"> {company && company[0].company_name}</Typography>
                        <Typography variant="h6"> {machine && machine[0].machine_name} - Entry {entry_id}</Typography>
                    </Grid>
                    {/* <Grid item>
                        <Button
                            fullWidth
                            variant="contained"
                            style={{ height: '100%' }}
                            component="label"
                            color="secondary"
                            onClick={() => history.push(`/machine/${id}`)}
                        >
                            <FontAwesomeIcon icon={faExternalLink} />
                            <Typography className={`${buttonStyle.buttonIconText}`}>
                                Go to {machine && machine[0].machine_name}
                            </Typography>
                        </Button>
                    </Grid> */}
                </Grid>
            </PageHeader>
            <PageContent>
                {entry && company && machine ?

                    <EditEntry
                        isDialog={open}
                        setIsDialog={closeDialog}
                        originalEntry={{ original: entry[0] }}
                        selectedCompany={company[0]}
                        selectedMachine={machine[0]}
                        //qrcodes={} //get qr codes
                        history={history}
                        active={entry[0].attributes_new.length > 5 ? 2 : 1}
                        voided={entry[0].void ? true : false}
                    />
                    :
                    <LoadingScreen
                        loading={false}
                        background="#fafafa"
                        colour="#666"
                        text="Loading Entry"
                    />
                }
            </PageContent>
        </>
    );
};

export default FullEntry;
