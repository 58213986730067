import { Grid, Typography } from '@mui/material';
import React, { useContext } from 'react';
import SelectionRow from '../Dashboard/Selection_Row/SelectionRow';
import PageContent from '../PageContent';
import ActivityLog from './ActivityLog';
import AdditiveUsageByAdditive from './AdditiveUsageByAdditive';
import CoolantUsageByCompany from './CoolantUsageByCompany';
import CoolantUsageByCoolant from './CoolantUsageByCoolant';
//import DipslideQRCodes from './QRCodes/DipslideQRCodes';
import DipslideQRCodesGlobal from './QRCodes/DipslideQRCodesGlobal';
import MachineQRCodes from './QRCodes/MachineQRCodes';
import MachineChartReport from './MachineChartReport';
import ServiceReports from './ServiceReports';
import { CompanyContext } from '../../store/CompanyContext';
import PageHeader from '../PageHeader';
import setLanguageText from '../../helpers/setLanguageText';
import { LayoutContext } from '../../store/LayoutContext';
import MachineQRCodesOnly from './QRCodes/MachineQRCodesOnly';
import config from '../../config';

const Reports = () => {

  const { companies } = useContext(CompanyContext)
  const { language } = useContext(LayoutContext);

  return (
    <>
      <PageHeader>
        <Grid item container alignItems="stretch" spacing={2}>
          <Grid item style={{ alignSelf: 'center' }}>
            <Typography variant="h5">{setLanguageText(language, "Reports")}</Typography>
          </Grid>
        </Grid>
      </PageHeader>
      <PageContent className="reports">
        <SelectionRow />
        <Grid item container spacing={2}>
          <Grid item xs={12} lg={6}>
            <CoolantUsageByCompany companies={companies} />
          </Grid>
          <Grid item xs={12} lg={6}>
            <CoolantUsageByCoolant />
          </Grid>
          <Grid item xs={12} lg={6}>
            <AdditiveUsageByAdditive />
          </Grid>
          {/* <Grid item xs={12} lg={6}>
          <MachineChartReport />
        </Grid> */}
          {/* <Grid item xs={12} lg={6}>
          <DipslideQRCodes companies={companies} />
        </Grid> */}
          <Grid item xs={12} lg={6}>
            <DipslideQRCodesGlobal />
          </Grid>
          <Grid item xs={12} lg={6}>
            {config.APPNAME === 'rhenus' ?
              <MachineQRCodesOnly companies={companies} />
              :
              <MachineQRCodes companies={companies} />
            }
          </Grid>
          <Grid item xs={12} lg={12}>
            <ActivityLog companies={companies} />
          </Grid>
          <Grid item xs={12} lg={12}>
            <ServiceReports companies={companies} />
          </Grid>
        </Grid>
      </PageContent>
    </>
  );
}

export default Reports;
