import React, { useContext, useEffect, useState } from 'react';
import {
  faSortAmountDown
} from '@fortawesome/pro-light-svg-icons';
import {
  faTh,
  faList,
  faSortAlphaDown,
  faSortAlphaDownAlt
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  ButtonGroup,
  Grid,
  TextField
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { AdditiveContext } from '../../../store/AdditiveContext';
import {
  TOGGLE_SORT,
  TOGGLE_VIEW,
  SAVE_ADDITIVES_COMPARE,
  SAVE_ADDITIVES_IN_USE
} from '../../../store/AdditiveReducers';
import sortAdditives from '../../../helpers/sortAdditives';
import useAsync from '../../../Hooks/useAsync';
import axiosRequest from '../../../helpers/axiosRequest';
import { UserContext } from '../../../store/UserContext';
import setLanguageText from '../../../helpers/setLanguageText';
import { LayoutContext } from '../../../store/LayoutContext';


//********** COMPONENT ************
const AdditiveHeader = () => {

  //****** CONTEXT ******
  const {
    additives,
    dispatchAdditives,
    additivesInUse,
    dispatchAdditivesInUse,
    dispatchAdditivesCompare,
    additiveOptions,
    dispatchAdditiveOptions
  } = useContext(AdditiveContext);

  const { authUser } = useContext(UserContext);
  const { language } = useContext(LayoutContext)

  const [searchOptions, setSearchOptions] = useState([]);
  const [types, setTypes] = useState([]);

  //****** FUNCTIONS *****

  const { executeAsync: fetch_types } = useAsync({
    axiosRequest: axiosRequest
  });

  // get s3 folders to create additive types options
  const createTypes = (addititves) => {
    var unique = [...new Set(
      addititves.map(item => {
          return { 
            'additive_type_id' : item.additive_type_id,
            'additive_type_name' : item.additive_type_name,
          }  
      }
    ))];
    unique.push( {
      'additive_type_id' : '0',
      'additive_type_name' : 'all',
    })
      setTypes(unique);

  };
  
  useEffect(() => {
    if (additiveOptions.inUse) {
      createTypes(additivesInUse)
    } else {
      createTypes(additives)
    }
  }, [additiveOptions.inUse]);

  useEffect(() => {
    if (additiveOptions.inUse) {
      setSearchOptions(additivesInUse);
    } else {
      setSearchOptions(additives);
      handleView("grid");
    }
  }, [additives, additivesInUse, additiveOptions.inUse]);

  const handleSort = sort => {
    if (sort) {
      dispatchAdditiveOptions({
        type: TOGGLE_SORT,
        payload: sort
      });
      // Sort based on IN USE selected
      additiveOptions.inUse
        ? dispatchAdditivesInUse({
          type: SAVE_ADDITIVES_IN_USE,
          payload: sortAdditives(additivesInUse, sort)
        })
        : dispatchAdditives({
          type: SAVE_ADDITIVES_IN_USE,
          payload: sortAdditives(additives, sort)
        });
    }
  };

  const handleType = sort => {
    //console.log(sort)
   if (sort) {
     console.log(additivesInUse)
     console.log(additives)

        const types = (additives).filter((add) => add.additive_type_id === sort);
        console.log(types)
        dispatchAdditives({
          type: SAVE_ADDITIVES_IN_USE,
          payload: types
        });
   
    }
  };

  const handleView = view => {
    dispatchAdditiveOptions({
      type: TOGGLE_VIEW,
      payload: view
    });
  };

  const updateVisible = e => {
    dispatchAdditivesCompare({
      type: SAVE_ADDITIVES_COMPARE,
      payload: e
    });
  };

  return <>
    <Grid
      container
      justifyContent="space-between"
      alignItems="stretch"
      spacing={2}
    >
      <Grid item xs={12} md={4}>
        <Autocomplete
          multiple
          id="tags-outlined"
          options={searchOptions}
          getOptionLabel={option => option.additive_name}
          filterSelectedOptions
          renderInput={params => (
            <TextField
              {...params}
              variant="outlined"
             label={setLanguageText(language,"Search and compare Additives")}
            />
          )}
          onChange={(e, newValue) => updateVisible(newValue)}
        />
      </Grid>


      <Grid item container xs={12} md={7} justifyContent="space-between">
        {types &&
          <Grid container xs={12} md={3} item  >
            <Autocomplete
              //multiple
              id="tags-outlined"
              fullWidth
              options={types}
              getOptionLabel={(option) => option.additive_type_name || ''}
              isOptionEqualToValue={(option, value) => option.additive_type_id === value}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                 label={setLanguageText(language,"Filter Types")}
                />
              )}
              onChange={(e, newValue) => handleType(newValue?.additive_type_id || null)}
            />
          </Grid>
        }
        <Grid item container xs={5}>
          <ButtonGroup fullWidth>
            <Button
              variant="contained"
              color={
                additiveOptions.toggleSort === 'asc' ? 'primary' : 'inherit'
              }
              onClick={() => handleSort('asc')}
            >
              <FontAwesomeIcon icon={faSortAlphaDown} size="lg" />
            </Button>
            <Button
              variant="contained"
              color={
                additiveOptions.toggleSort === 'desc'
                  ? 'primary'
                  : 'inherit'
              }
              onClick={() => handleSort('desc')}
            >
              <FontAwesomeIcon icon={faSortAlphaDownAlt} size="lg" />
            </Button>
            <Button
              variant="contained"
              color={
                additiveOptions.toggleSort === 'most_used'
                  ? 'primary'
                  : 'inherit'
              }
              onClick={() => handleSort('most_used')}
            >
              <FontAwesomeIcon icon={faSortAmountDown} size="lg" />
            </Button>
          </ButtonGroup>
        </Grid>

        <Grid item container xs={3}>
          <ButtonGroup fullWidth>
            <Button
              variant="contained"
              color={
                additiveOptions.toggleView === 'grid'
                  ? 'primary'
                  : 'inherit'
              }
              onClick={() => handleView('grid')}
            >
              <FontAwesomeIcon icon={faTh} size="lg" />
            </Button>
            {additiveOptions.inUse &&
              <Button
                variant="contained"
                color={
                  additiveOptions.toggleView === 'list'
                    ? 'primary'
                    : 'inherit'
                }
                onClick={() => handleView('list')}
              >
                <FontAwesomeIcon icon={faList} size="lg" />
              </Button>
            }
          </ButtonGroup>
        </Grid>

      </Grid>
    </Grid>
  </>;
};

export default AdditiveHeader;
