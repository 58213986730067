import React, { useContext, useState } from 'react';
import {
  faTint,
  faRaindrops
} from '@fortawesome/pro-light-svg-icons';
import {
  faPlus,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  ButtonGroup,
  Grid,
  useMediaQuery,
  Hidden,
  Typography
} from '@mui/material';
import {
  TOGGLE_IN_USE,
} from '../../../store/CoolantReducers';
import DropdownCompanies from '../../Dashboard/Selection_Row/DropdownCompanies';
import SelectionRow from '../../Dashboard/Selection_Row/SelectionRow';
import PageHeader from '../../PageHeader';
import { CoolantContext } from '../../../store/CoolantContext';
import { buttonStyles } from '../../../styles/buttonStyles';
import AddCoolant from './AddCoolant';
import { UserContext } from '../../../store/UserContext';
import { isMobileOnly } from 'react-device-detect';
import config from '../../../config';
import setLanguageText from '../../../helpers/setLanguageText';
import { LayoutContext } from '../../../store/LayoutContext';

//********** COMPONENT ************
const CoolantsHeader = ({ setLoading }) => {
  const buttonStyle = buttonStyles();
  //****** CONTEXT ******
  const {
    coolantOptions,
    dispatchCoolantOptions
  } = useContext(CoolantContext);
  const { language } = useContext(LayoutContext);
  const { user } = useContext(UserContext);
  const [isAddDialog, setIsAddDialog] = useState(false);

  //****** FUNCTIONS ******
  //const mdDown = useMediaQuery(theme => theme.breakpoints.down('lg'));

  const handleInUse = boolean => {
    dispatchCoolantOptions({ type: TOGGLE_IN_USE, payload: boolean });

  };
  const openAddDialog = () => setIsAddDialog(true);
  const closeAddDialog = () => setIsAddDialog(false);


  return <>
    <PageHeader>
      <Grid
        container
        alignItems="stretch"
        justifyContent="space-between"
        spacing={2}
      >
            <Grid item xs={12} md={4}>
              <SelectionRow >
                {coolantOptions.inUse &&
                  <DropdownCompanies />
                }
              </SelectionRow>
            </Grid>
        <Grid item container justifyContent="flex-start" xs={12} md={4}>
          <ButtonGroup fullWidth>
            {/* {user.level === 9 && (
              <Button
                variant="contained"
                color={!coolantOptions.inUse ? 'primary' : 'inherit'}
                onClick={() => handleInUse(false)}
              >
                <FontAwesomeIcon icon={faRaindrops} size="lg" />
                <Hidden smDown>
                  <Typography className={buttonStyle.buttonIconText}>
                    All {config.APPNAME === 'act' ? 'Coolants' : 'Coolants'}
                </Typography>
                </Hidden>
              </Button>
            )} */}
            <Button
              variant="contained"
              color={coolantOptions.inUse ? 'primary' : 'inherit'}
              onClick={() => handleInUse(true)}
            >
              <FontAwesomeIcon icon={faTint} size="lg" />
              <Hidden smDown>
                <Typography className={buttonStyle.buttonIconText}>
                {setLanguageText(language,"Coolants Currently In Machines")}
                </Typography>
              </Hidden>
            </Button>
          </ButtonGroup>
        </Grid>
        {/* {user.level === 9 && !isMobileOnly &&  (
          <Grid item container justifyContent="flex-end" xs={12} md={4}>
            <Button
              variant="contained"
              color="primary"
              onClick={openAddDialog}
              //fullWidth={mdDown}
            >
              <FontAwesomeIcon icon={faPlus} size="lg" />
              <Typography className={buttonStyle.buttonIconText}>
                Add New {config.APPNAME === 'act' ? 'Oil' : 'Coolant'}
            </Typography>
            </Button>
          </Grid>
        )} */}
      </Grid>
    </PageHeader>
    <AddCoolant closeAddDialog={closeAddDialog} isAddDialog={isAddDialog} />
  </>;
};

export default CoolantsHeader;
