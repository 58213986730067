import React, { useContext, useState, useEffect } from 'react';
import {
  faVials,
  faVial
} from '@fortawesome/pro-light-svg-icons';
import {
  faPlus,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  ButtonGroup,
  Grid,
  Typography,
  Hidden,
  useMediaQuery
} from '@mui/material';
import { AdditiveContext } from '../../../store/AdditiveContext';
import {
  SAVE_ADDITIVES_IN_USE,
  TOGGLE_IN_USE
} from '../../../store/AdditiveReducers';
import DropdownCompanies from '../../Dashboard/Selection_Row/DropdownCompanies';
import PageHeader from '../../PageHeader';
import AddAdditive from './AddAdditive';
import { buttonStyles } from '../../../styles/buttonStyles';
import { CompanyContext } from '../../../store/CompanyContext';
import { UserContext } from '../../../store/UserContext';
import { fetchAdditives } from '../../../helpers/fetchAdditives';
import sortAdditives from '../../../helpers/sortAdditives';
import parseAdditives from '../../../helpers/parseAdditives';
import { isMobileOnly } from 'react-device-detect';
import setLanguageText from '../../../helpers/setLanguageText';
import { LayoutContext } from '../../../store/LayoutContext';

//********** COMPONENT ************
const AdditiveHeader = ({ setLoading }) => {

  const buttonStyle = buttonStyles();
  const mdDown = useMediaQuery(theme => theme.breakpoints.down('lg'));
  //****** CONTEXT ******
  const { authUser } = useContext(UserContext);
  const {
    dispatchAdditivesInUse,
    dispatchAdditiveOptions,
    additiveOptions
  } = useContext(AdditiveContext);
  const { language } = useContext(LayoutContext);

  const [isAddDialog, setIsAddDialog] = useState(false);
  //const [options, setOptions] = useState(false);

  const { selectedCompany } = useContext(CompanyContext);
  const { user } = useContext(UserContext);

  const openAddDialog = () => setIsAddDialog(true);
  const closeAddDialog = () => setIsAddDialog(false);

  //****** FUNCTIONS *****

  const handleInUse = boolean =>
    dispatchAdditiveOptions({ type: TOGGLE_IN_USE, payload: boolean });


  // const onSelectCompany = async company => {
  //   if (company) {
  //     setLoading(true);
  //     //console.log(company)
  //     dispatchSelectedCompany({
  //       type: SELECTED_COMPANY,
  //       payload: company
  //     });
  //   }
  // };

  // add the option "ALL" into the dropdown
  useEffect(() => {
      if (selectedCompany) {

        let params =  {
          'company_id': selectedCompany.company_id
        } 
        if(selectedCompany.company_id === 0 && user.level !== 9 ){
          params =  {
            'company_ids' : JSON.stringify(user.companies_id)
          }
        }

        //console.log('fetch addititves')
        fetchAdditives({
          'api_key' : authUser.api_key,
          ...params
        }).then(data => {
          const machinesUsingAdditive = data.filter(
            additive => additive.machines_using_additive
          );
          // Sort Machines Using Additive by Most Used
          const sortMostUsed = sortAdditives(
            machinesUsingAdditive,
            'most_used'
          );
          dispatchAdditivesInUse({
            type: SAVE_ADDITIVES_IN_USE,
            payload: parseAdditives(sortMostUsed)
          });
          setLoading(false)
        });
      }
    }, [
      authUser,
      user,
      selectedCompany,
      dispatchAdditivesInUse,
      setLoading
    ]);
  

  // add the option "ALL" into the dropdown
  // useEffect(() => {
  //   if (companies.length) {
  //     setOptions(companies);
  //   }
  // }, [companies]);

  return <>
    <PageHeader>
      <Grid
        container
        alignItems="stretch"
        justifyContent="space-between"
        spacing={2}
      >
        <Grid item container justifyContent="flex-start" xs={12} md={4}>
          <ButtonGroup fullWidth>
            <Button
              variant="contained"
              color={!additiveOptions.inUse ? 'primary' : 'inherit'}
              onClick={() => handleInUse(false)}
            >
              <FontAwesomeIcon icon={faVials} size="lg" />
              <Hidden smDown>
                <Typography className={buttonStyle.buttonIconText}>
                {setLanguageText(language,"All Additives")}
                </Typography>
              </Hidden>
              <Hidden smUp>
                <Typography className={buttonStyle.buttonIconText}>
                  All
                </Typography>
              </Hidden>
            </Button>
            
            <Button
              variant="contained"
              color={additiveOptions.inUse ? 'primary' : 'inherit'}
              onClick={() => handleInUse(true)}
            >
              <FontAwesomeIcon icon={faVial} size="lg" />
              <Hidden smDown>
                <Typography className={buttonStyle.buttonIconText}>
                {setLanguageText(language,"Additives In Use")}
                </Typography>
              </Hidden>
              <Hidden smUp>
                <Typography className={buttonStyle.buttonIconText}>
                {setLanguageText(language,"In Use")}
                </Typography>
              </Hidden>
            </Button>
          </ButtonGroup>
        </Grid>

        <Grid item xs={12} md={4}>
        {additiveOptions.inUse && (
          <DropdownCompanies />
        )}
          {/* <SelectionRow>
            {additiveOptions.inUse && (
              <Autocomplete
                id="selected-company"
                value={Object.entries(selectedCompany).length ? selectedCompany : null}
                options={options || []}
                loading={options ? false : true}
                getOptionLabel={option => option.company_name}
                getOptionSelected={(option, value) =>
                  option.company_id === value.company_id
                }
                onChange={(e, newValue) => {
                  onSelectCompany(newValue);
                }}
                renderInput={params => (
                  <TextField {...params} variant="outlined"label={setLanguageText(language,"Selected Company" />
                )}
              />
            )}
          </SelectionRow> */}
        </Grid>
        {user.level === 9 && !isMobileOnly && (
        <Grid item container justifyContent="flex-end" xs={12} md={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={openAddDialog}
            fullWidth={mdDown}
          >
            <FontAwesomeIcon icon={faPlus} size="lg" />
            <Typography className={buttonStyle.buttonIconText}>
            {setLanguageText(language,"Add New Additive")}
            </Typography>
          </Button>
        </Grid>
        )}
      </Grid>
    </PageHeader>
    {isAddDialog &&
      <AddAdditive closeAddDialog={closeAddDialog} open={isAddDialog} />
    }
  </>;
};

export default AdditiveHeader;
