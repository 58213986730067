
import React, { useContext } from 'react';
import { Grid, Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Alert from '@mui/material/Alert';
import setLanguageText from '../../helpers/setLanguageText';
import { LayoutContext } from '../../store/LayoutContext';

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiAlert-icon' : {
            alignItems: 'center'
        }
    },
    warnings: {
        padding: theme.spacing(2)
    }
}));

export const Alerts = ({ errors, data, showWarning, showError, voidError, sqlErrors }) => {

    const { language } = useContext(LayoutContext)
    //****************** CONTEXT ******************

    //****************** STATE ******************

    //****** STYLES ******
    const classes = useStyles();

    return (
            <div className={classes.root} >
            {data && (
                <>
                    <Grid item className={classes.warnings} >
                        <Alert severity="success" variant="standard">
                        {setLanguageText(language,"New Entry Successfully Added")}
                    </Alert>
                    </Grid>
                    <Divider />
                </>
            )}
            {sqlErrors &&  (
                <>
                    <Grid item className={classes.warnings}>
                        <Alert severity="warning" variant="standard">
                            {sqlErrors}
                        </Alert>
                    </Grid>
                    <Divider />
                </>
            )}
            {showWarning && errors &&  (
                <>
                    <Grid item className={classes.warnings}>
                        <Alert severity="warning" variant="standard">
                            <Grid item container>
                            {errors.map((err, i) => {
                                if (err.type === "warning") {
                                    return <Grid key={i} item xs={12} >{err.message}</Grid>
                                }
                                return null
                            })}
                            </Grid>
                        </Alert>
                    </Grid>
                    <Divider />
                </>
            )}
            {showError && errors && (
                <>
                    <Grid item className={classes.warnings} >
                        <Alert severity="error" variant="standard">
                        <Grid item container>
                            {errors.map((err, i) => {
                                if (err.type === "error") {
                                    return <Grid key={i} item xs={12} >{err.message}</Grid>
                                }
                                return null
                            })}
                            </Grid>
                        </Alert>
                    </Grid>
                    <Divider />
                </>
            )}
              {voidError && errors && (
                <>
                    <Grid item className={classes.warnings} >
                        <Alert severity="error" variant="standard">
                        {setLanguageText(language, "Changing this vaule will void this entry")}
                           
                    </Alert>
                    </Grid>
                    <Divider />
                </>
            )}
            </div>
    )
};

export default Alerts;
