import { faBorderAll, faBars } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonGroup, Button } from '@mui/material';
import React, { useContext } from 'react';
import { MachineContext } from '../../../store/MachineContext';
import { GRID_VIEW } from '../../../store/MachineReducers';
import { buttonStyles } from '../../../styles/buttonStyles';

const GridView = () => {
  const { gridView, dispatchGridView } = useContext(MachineContext);

  const toggleListView = () => {
    dispatchGridView({
      type: GRID_VIEW,
      payload: false
    });
  };
  const toggleGridView = () => {
    dispatchGridView({
      type: GRID_VIEW,
      payload: true
    });
  };

  const buttonStyle = buttonStyles();

  return (
    <ButtonGroup
      variant="contained"
      color="primary"
      fullWidth
      style={{ height: '100%' }}
    >
      <Button
        className={buttonStyle.button}
        variant="contained"
        onClick={toggleGridView}
        color={gridView ? 'primary' : 'inherit'}
      >
        <FontAwesomeIcon icon={faBorderAll} size="lg" />
      </Button>
      <Button
        className={buttonStyle.button}
        variant="contained"
        onClick={toggleListView}
        color={gridView ? 'inherit' : 'primary'}
      >
        <FontAwesomeIcon icon={faBars} size="lg" />
      </Button>
    </ButtonGroup>
  );
};

export default GridView;
