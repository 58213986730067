
import React, { useState, useEffect, useContext, useCallback } from 'react';
//import QrReader from 'react-qr-reader';
import QrReader from 'react-qr-scanner';
import config from '../../config';
import { Alert } from '@mui/material';
import { Button, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { faCog, faPencilRuler, faQrcode } from '@fortawesome/pro-light-svg-icons';
import axiosRequest from '../../helpers/axiosRequest';
import { UserContext } from '../../store/UserContext';
import useAsync from '../../Hooks/useAsync';
import LoadingScreen from '../reusable/LoadingScreen';
import { SelectionsContext } from '../../store/SelectionsContext';
import CoolantCard from '../Products/Coolants/CoolantCard';
import { useHistory, useLocation } from 'react-router-dom';
import parseCoolants from '../../helpers/parseCoolants';
import PageContent from '../PageContent';
import PageHeader from '../PageHeader';
import EditEntry from '../Entry/EditEntry';
import ManualInputs from './ManualInputs';
import { isMobileOnly } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddEntry from '../Entry/AddEntry';
import { CompanyContext } from '../../store/CompanyContext';
import { SELECTED_COMPANY } from '../../store/CompanyReducers';
import { SELECTED_MACHINE } from '../../store/MachineReducers';
import { MachineContext } from '../../store/MachineContext';
import setLanguageText from '../../helpers/setLanguageText';
import { LayoutContext } from '../../store/LayoutContext';

const useStyles = makeStyles(theme => ({
  button: {
    padding: theme.spacing(2)
  }
}));

const Scanner = () => {

  let history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const { authUser, user } = useContext(UserContext);
  const { dispatchSelectedCompany } = useContext(CompanyContext);
  const { dispatchSelectedMachine } = useContext(MachineContext);
  const { addNewEntry } = useContext(SelectionsContext);
  const { language } = useContext(LayoutContext);

  const {
    data,
    executeAsync,
    setData,
  } = useAsync({
    axiosRequest: axiosRequest
  });

  //****** STATES ******

  //const [salting_old] = useState(btoa(config.SALTING));
  const [salting] = useState(Buffer.from(config.SALTING).toString('base64'));
  //console.log(salting_old)
  //console.log(salting)

  const [addEntry, setAddEntry] = useState(false);

  //qr codes
  const [result, setResult] = useState();
  const [details, setDetails] = useState();
  const [qrError, setQrError] = useState();
  const [loading, setLoading] = useState(false);
  const [showCamera, setShowCamera] = useState(true);
  const [machineUrl, setMachineUrl] = useState();
  const [coolantDetails, setCoolantDetails] = useState();
  const [machineID, setMachineID] = useState();
  const [entry, setEntry] = useState();
  const [open, setOpen] = useState(false);
  const [fireAgain, setFireAgain] = useState(true);

  // const [cameraId, setCameraId] = useState('');
  // const [devices, setDevices] = useState([]);
  // const [delay, setDelay] = useState(500);

  const handleAddEntry = () => {
    setAddEntry(true);
    setOpen(true)
  };
  const handleCloseDialog = () => {
    setAddEntry(false)
    setFireAgain(true)
  };

  //console.log(showCamera)
  //console.log(fireAgain)

  const handleScan = data => {
   
    if (data && showCamera && fireAgain) {
      //console.log(data)
      if (data)
        setFireAgain(false);
        setResult({
          result: data.text
        });
      }
  };

  const reverse = s => {
    return s.split('').reverse().join('');
  };

  // get machine details
  const getMachineDetails = useCallback(async (machineID) => {
    //fetch machine and coolant

    const machine = await axiosRequest({
      api_key: authUser.api_key,
      method: 'get',
      endpoint: 'machines',
      params: {
        machine_id: machineID,
        user_level: user.level,
        company_ids: JSON.stringify(user.companies_id)
      }
    });

    if (!machine.errorMessage) {

      let result = await axiosRequest({
        api_key: authUser.api_key,
        method: 'get',
        endpoint: 'companies',
        params: {
          user_level: user.level,
          id: machine[0].company_id
        }
      });
      if (result) {

        dispatchSelectedCompany({
          type: SELECTED_COMPANY,
          payload: result[0]
        });
        dispatchSelectedMachine({
          type: SELECTED_MACHINE,
          payload: machine[0]
        });

        window.sessionStorage.setItem('selectedCompany', JSON.stringify(result[0]));
        window.sessionStorage.setItem('selectedGroup', JSON.stringify({ group_name: setLanguageText(language, "All Groups"), group_id: 0 }));
        window.sessionStorage.setItem('selectedMachine', JSON.stringify(machine[0]))

        //console.log(result);
        setLoading(false);
        setMachineUrl(`machine/${machine[0].machine_id}`);
        setCoolantDetails(machine[0]);
        //set coolant SDS & TDS
        setDetails(machine[0]);
      }

    } else {
      setQrError({
        result: 'error',
        message: result.errorMessage
      });
    }

  }, [user, authUser.api_key]);

  useEffect(() => {
    if (machineID) {
      getMachineDetails(machineID)
    }
  }, [machineID])


  useEffect(() => {
    if (location) {
      console.log(location.search)
      const urlParams = new URLSearchParams(location.search);
      const scan = urlParams.get("scan");
      if(scan){
        setShowCamera(true)
        history.replace('/qr-code')
      }
    }
  }, [location])



  // fecth linked entry
  const getLinkedEntry = (fullCode) => {
    //console.log(fullCode)
    const checkCode = async (fullCode) => {
      await executeAsync({
        endpoint: 'qr',
        api_key: authUser.api_key,
        method: 'post',
        body: {
          code: fullCode,
          entry: true
        }
      });
    }
    checkCode(fullCode);
  };

  useEffect(() => {
    if (data) {
      console.log(data)
      if (data[0].entry_id) {
        //quix fix
        history.push(`machine/${data[0].machine_id}/entry/${data[0].entry_id}`)
        //   setEntry({
        //     "entry": {
        //       "original": {
        //         "additives": [],
        //         "attributes_new": JSON.parse(data[0].attributes_new),
        //         "company_name": data[0].company_name,
        //         "coolant_id": data[0].coolant_id,
        //         "date_stamp": data[0].date_stamp,
        //         "entry_id": data[0].entry_id,
        //         "factor": data[0].factor,
        //         "initial": data[0].initial,
        //         "machine_id": data[0].machine_id,
        //         "mobile": data[0].mobile,
        //         "note_action": data[0].note_action,
        //         "notes": data[0].notes,
        //         "out_of_control": data[0].out_of_control,
        //         "user_id": data[0].user_id,
        //         "void": data[0].void,
        //       },
        //     },
        //     "company": {
        //       "company_attributes": JSON.parse(data[0].company_attributes),
        //       "company_colour_rules": JSON.parse(data[0].company_colour_rules),
        //       "company_id": data[0].company_id,
        //       "company_logo_path": data[0].company_logo_path,
        //       "company_name": data[0].company_name,
        //       "company_settings": JSON.parse(data[0].company_settings)
        //     },
        //     "machine": {
        //       "machine_settings": JSON.parse(data[0].machine_settings),
        //       "machine_name": data[0].machine_name,
        //       "machine_id": data[0].machine_id
        //     },
        //     "qr_codes": JSON.parse(data[0].qr_codes)
        //   })
        //   setOpen(true);
        //   setQrError();

      } else {
        setQrError({
          result: 'error',
          message: 'QR code is not linked to an entry'
        });

      }
    }
  }, [data]);

  const closeEdit = () => {
    setEntry();
    setResult();
    setOpen(false);
    setLoading(false);
    setShowCamera(true);
    setFireAgain(true);
  }

  const qrDetails = (qr) => {
    setData(qr)
  }

  //set details from scaning qr code
  useEffect(() => {
    if (result) {

      console.log(result)

      setLoading(true);
      setShowCamera(false);
      const data = result.result;
      //console.log(data);

      //OLD PDF QR CODES
      if (data.substring(data.length - 3) === 'pdf') {
        //Legacy QR Code e.g https://ccsdev.uk/coolantcare/pdfs/view.php?file=https://ccsdev.uk/coolantcare/uploads/75.pdf
        var id = data.substring(
          data.lastIndexOf('/') + 1,
          data.lastIndexOf('.')
        );
        setDetails(id);
        //Fetch
        //new SDS/SDT QR code  e.g {"company_id":"1","machine_id":"392","instance":"prod"}

      } else if (data) {

        let reverseData = reverse(data);

        //console.log(salting)

        if (reverseData.includes(salting)) {

          reverseData = reverseData.substring(salting.length);

          //console.log('includes salting')
          reverseData = JSON.parse(Buffer.from(reverseData, "base64").toString("utf-8"));

          if (reverseData.app === config.APPNAME) {
            console.log('same app')
            // this is a dipslide code OLD version
            if (reverseData.code) {
              console.log(reverseData)
              setResult();
              getLinkedEntry(reverseData);
              setQrError();
              setLoading(false)
            }

          } else if (reverseData.instance === config.INSTANCE) {
            //get machine details
            console.log('machine QR')
            if (reverseData.machine_id) {
              setMachineID(reverseData.machine_id);
              setQrError();
              setLoading(false);
            }
          } else {
            setQrError({
              result: 'error',
              message: 'QR code not from this application or instance'
            });
            //setLoading(false);
          }
        } else if (data) {
          console.log('no salting - dipslide QR')
          //if its doesn't include salting, check instance 

          const myArr = data.split(" ");
          console.log(myArr)

          if (parseInt(myArr[2]) !== config.APPNAME) {

            setResult();
            getLinkedEntry({
              app: myArr[2],
              code: myArr[1],
              company_id: parseInt(myArr[0])
            });
            setQrError();
            setLoading(false);

          } else {
            setQrError({
              result: 'error',
              message: 'QR code not from this application or instance'
            });
            //setLoading(false);
          }

        } else {
          setQrError({
            result: 'error',
            message: `Not a ${config.NAME} QR code`
          });
          //setLoading(false);
        }
      }
    }
  }, [result, setDetails, salting, addNewEntry, config]);

  return (
    <>
      {machineUrl && details && !showCamera && (
        <PageHeader>
          <Grid item xs={12}>
            <Grid item style={{ textAlign: 'center' }}>
              <Typography variant="h3">
                {details.machine_name}
              </Typography>
              <Typography>{details.machine_group}</Typography>
            </Grid>
          </Grid>
        </PageHeader>
      )}
      <PageContent>
        {entry && Object.values(entry).length ? (
          <EditEntry
            isDialog={open}
            setIsDialog={closeEdit}
            originalEntry={entry.entry}
            selectedCompany={entry.company}
            selectedMachine={entry.machine}
            qrCodes={entry.qr_codes}
            active={entry.entry.original.attributes_new.length > 5 ? 2 : 1}
            voided={entry.entry.original.void ? true : false}
          />
        ) : (

          <Grid container spacing={2} >
            {/* <Grid item xs={12}>
            {devices.length && (
              <Select
                onChange={e => setCamera(e)}
                value={cameraId}
              >
                {devices.map((deviceInfo, index) => (

                  <MenuItem key={deviceInfo.deviceId} value={deviceInfo.deviceId}>{deviceInfo.label || `camera ${index}`}</MenuItem>

                ))}
              </Select>
            )
          }
          </Grid> */}
            <Grid item style={{ position: 'relative' }} container spacing={2}>
              {loading && !qrError &&
                <LoadingScreen
                  background="#fff"
                  colour="#333"
                  text="Checking QR code"
                  //height="auto" 
                  position="relative"
                />
              }
              {showCamera &&
                <Grid item xs={12} container alignItems="center" >
                  <QrReader
                    //resolution={1440}  
                    constraints={{
                      video: {
                        //deviceId: { cameraId },
                        facingMode: "environment",
                        //willReadFrequently: true
                      }
                    }}
                    // //facingMode="environment"
                    // //chooseDeviceId={cameraId}
                    // delay={delay}
                    onError={e => console.log('hi')}
                    onScan={handleScan}
                    style={{ width: '100%', maxWidth: isMobileOnly ? '100%' : '60%', margin: '0 auto' }}
                  />
                </Grid>
              }
              {machineUrl && details && !showCamera && (

                <Grid item container spacing={2} >
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={() => history.push(machineUrl)}
                      className={classes.button}
                    >
                      <Grid container>
                        <Grid item xs={12} >
                          <FontAwesomeIcon icon={faCog} size="2x" />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body1" >{setLanguageText(language, "Go to Machine")}</Typography>
                        </Grid>
                      </Grid>
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={handleAddEntry}
                      className={classes.button}
                    >
                      <Grid container>
                        <Grid item xs={12} >
                          <FontAwesomeIcon icon={faPencilRuler} size="2x" />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body1" >{setLanguageText(language, "Add Entry")}</Typography>
                        </Grid>
                      </Grid>
                    </Button>
                  </Grid>
                </Grid>
              )}
              {coolantDetails && !showCamera && (
                <Grid item xs={12} >
                  <CoolantCard
                    noEdit
                    noMachines
                    coolant={parseCoolants(coolantDetails)}
                    noBar
                  />
                </Grid>
              )}
              {qrError && (
                <Grid item xs={12}>
                  <Alert severity="error">{qrError.message}</Alert>
                </Grid>
              )}
            </Grid>
            <Grid item container spacing={2} alignItems="center" justifyContent="center">
              <ManualInputs qrDetails={qrDetails} />
              <Grid item xs={6} sm={12}>
                {/* <PrimaryBtnIcon
                    icon={faQrcode}
                    text={'Scan Again'}
                    disabled={showCamera}
                    color="secondary"
                    //className={!error ? buttonStyle.warning : null}
                    //iconSize="2x"
                    onClick={e => {
                      setShowCamera(true);
                      setLoading(false);
                      setMachineID();
                      setMachineUrl();
                      setCoolantDetails();
                      setDetails();
                      setFireAgain(true);
                    }}
                  /> */}
                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  disabled={showCamera}
                  onClick={e => {
                    setShowCamera(true);
                    setLoading(false);
                    setMachineID();
                    setMachineUrl();
                    setCoolantDetails();
                    setDetails();
                    setFireAgain(true);
                    setQrError();
                  }}
                  className={classes.button}
                >
                  <Grid container>
                    <Grid item xs={12} >
                      <FontAwesomeIcon icon={faQrcode} size="2x" />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1" >{setLanguageText(language, "Scan Again")}</Typography>
                    </Grid>
                  </Grid>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
        {open && <AddEntry isDialog={addEntry} setIsDialog={handleCloseDialog} />}
      </PageContent>
    </>
  );
};



export default Scanner;
