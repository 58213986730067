import React from 'react';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ThemeProvider, useTheme } from '@mui/system';

const useStyles = makeStyles(theme => ({
  header: {
    backgroundColor: '#ffffff',
    padding: theme.spacing(2, 3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 1)
    },
    minHeight: theme.spacing(10.7)
  }
}));

const PageHeader = ({ children, ...props }) => {

  const classes = useStyles();
  const applicationTheme = useTheme();

  return (
    <ThemeProvider theme={applicationTheme} >
      <Grid item container className={classes.header} xs={12} {...props}>
        {children}
      </Grid>
    </ThemeProvider>
  );
};

export default PageHeader;
