import { faCog, faCommentAlt, faIndustryAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid, ThemeProvider, useTheme } from '@mui/material';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { buttonStyles } from '../../../styles/buttonStyles';
import Logout from '../Logout';
import { UserContext } from '../../../store/UserContext';
import setLanguageText from '../../../helpers/setLanguageText';
import { LayoutContext } from '../../../store/LayoutContext';
import LanguageSelect from '../LanguageSelect';

const NavigationButton = () => {

  const buttonStyle = buttonStyles();
  //************  CONTEXT ************
  const { user } = useContext(UserContext);
  const { language } = useContext(LayoutContext)
  const theme = useTheme();

  return (

    <ThemeProvider theme={theme} >
      <Grid
        item
        container
        justifyContent="flex-end"
        alignItems="center"
        xs={3}
        md={8}
        spacing={2}
      >
        <Grid item>
          <Link to="/chat" className={buttonStyle.buttonText} >
            <Button variant="contained" color="secondary" className={buttonStyle.button} >
              <FontAwesomeIcon
                icon={faCommentAlt}
                size="lg"
                style={{
                  marginRight: theme.spacing(1),
                  //fontSize: useTheme().spacing(3)
                }}
              />
              {setLanguageText(language, 'Chat')}
            </Button>
          </Link>
        </Grid>
        <Grid item>
          {user.level === 9 ? (
            <Link to="/settings/app/1" className={buttonStyle.buttonText}>
              <Button variant="contained" color="secondary" className={buttonStyle.button}>
                <FontAwesomeIcon
                  icon={faIndustryAlt}
                  size="lg"
                  style={{
                    marginRight: theme.spacing(1),
                    //fontSize: theme.spacing(3)
                  }}
                />
                {setLanguageText(language, 'All Companies')}
              </Button>
            </Link>
          ) : (
            <Link to="/settings/company/0" className={buttonStyle.buttonText}>
              <Button variant="contained" color="secondary" className={buttonStyle.button}>
                <FontAwesomeIcon
                  icon={faIndustryAlt}
                  size="lg"
                  style={{
                    marginRight: theme.spacing(1),
                    //fontSize: theme.spacing(3)
                  }}
                />
                {setLanguageText(language, 'My Company')}
              </Button>
            </Link>
          )}
        </Grid>
        {user.level === 9 ?
          <Grid item>
            <Link to="/settings/company/0" className={buttonStyle.buttonText}>
              <Button variant="contained" color="secondary" className={buttonStyle.button}>
                <FontAwesomeIcon
                  icon={faCog}
                  size="lg"
                  style={{
                    marginRight: theme.spacing(1),
                    //fontSize: useTheme().spacing(3)
                  }}
                />
                {setLanguageText(language, 'Settings')}
              </Button>
            </Link>
          </Grid>
          : null}
        <Grid item>
          <Logout />
        </Grid>
        {process.env.NODE_ENV !== 'production' ?
          <Grid item >
            <LanguageSelect />
          </Grid>
          : null}

      </Grid>
    </ThemeProvider>
  );
};

export default NavigationButton;
