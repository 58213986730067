import React, { useContext, useEffect, useState } from 'react';
import axiosRequest from '../../helpers/axiosRequest';
import { UserContext } from '../../store/UserContext';
import config from './../../config';
import AWS from 'aws-sdk';
import useAsync from '../../Hooks/useAsync';
import GlobalDialog from '../reusable/GlobalDialog';
import UserForm from './UserForm';

const AddUser = ({ closeDialog, open, createUser }) => {
  //************************ CONTEXT ************************
  const { authUser, user } = useContext(UserContext);
  //************************ USE STATES ************************

  const defaultUser = {
    name: '',
    initial: '',
    companies_id: [],
    company_id: '',
    level: '',
    level_group:'',
    department: '',
    email: '',
    phone: ''
  };
  const [newUser, setNewUser] = useState(defaultUser);
  const {
    error,
    data,
    loading,
    logCompleted,
    logError,
    executeAsync,
    executeLog,
    setData
  } = useAsync({
    axiosRequest: axiosRequest
  });

  const [cognitoResponse, setCognitoResponse] = useState(null);

  //************************ USE EFFECTS ************************

  // Handle Axios Request
  useEffect(() => {
    if (createUser) {
        setNewUser({
          id: createUser.original.id,
          name: createUser.original.Username,
          initial: createUser.original.initial,
          companies_id: createUser.original.companies_id ? JSON.parse(createUser.original.companies_id) : [createUser.original.company_id],
          company_id: createUser.original.company_id,
          level: createUser.original.level,
          level_group: createUser.original.level_group ? JSON.parse(createUser.original.level_group) : null, //new level details
          department: createUser.original.department,
          email: createUser.original.email,
          phone: ''
        });
    }
  }, [createUser]);

  // Handle Axios Request
  useEffect(() => {
    if (data) {
      // TODO handle if activity error
      if (logError) console.log('Something went wrong with the Activity Log');
      if (logCompleted) {
        closeDialog();
        setData();
        setNewUser(defaultUser);
      }
    }
  }, [closeDialog, data, defaultUser, logCompleted, logError, setData]);

  // Remove Error alert after 5 sec
  useEffect(() => {
    if (cognitoResponse?.result === 'error')
      setTimeout(() => {
        setCognitoResponse(null);
      }, 5000);
  }, [cognitoResponse]);

  //************************ FUNCTIONS ************************

  const handleSubmit = async e => {
    e.preventDefault();
    setCognitoResponse({ result: 'in progress' });
    let userParams = {
      UserPoolId: config.cognito.USER_POOL_ID,
      Username: newUser.name,
      DesiredDeliveryMediums: ['EMAIL'],
      ForceAliasCreation: false,
      //MessageAction: "SUPPRESS",
      //TemporaryPassword: password,
      UserAttributes: [
        { Name: 'email', Value: newUser.email },
        { Name: 'email_verified', Value: 'true' }
      ]
    };
    if (e.aws_phone) {
      // Add phone Number
      userParams = {
        ...userParams,
        UserAttributes: [
          ...userParams.UserAttributes,
          { Name: 'phone_number', Value: newUser.phone }
        ]
      };
    }
    const cognito_identity_service_provider =
      new AWS.CognitoIdentityServiceProvider({ apiVersion: '2016-04-18' });

    const createCognitoUser = new Promise((resolve, reject) => {
      //* CREATE USER
      cognito_identity_service_provider.adminCreateUser(
        userParams,
        (err, userData) => {
          if (err) {
            setCognitoResponse({
              result: 'error',
              message: err.message
            });
            reject(err);
          } else {
            const groupParams = {
              UserPoolId: config.cognito.USER_POOL_ID,
              Username: newUser.name,
              GroupName: newUser.level_group.group
            };
            //* ADD USER TO GROUP
            cognito_identity_service_provider.adminAddUserToGroup(
              groupParams,
              (err, groupData) => {
                if (err) {
                  setCognitoResponse({
                    result: 'error',
                    message: err.message
                  });
                  reject(err);
                } else {
                  setCognitoResponse({
                    result: 'success',
                    message: data
                  });
                  resolve(userData);
                }
              }
            );
            // TODO - IF ADD USER TO GROUP FAIL? REMOVE USER FROM COGNITO?
          }
        }
      );
    });

    try {
      const cognito_user_created = await createCognitoUser;
      if (cognito_user_created) {

        //* ADD USER TO DB
        const db_user_added = await executeAsync({
          endpoint: 'users',
          api_key: authUser.api_key,
          method: 'post',
          body: newUser
        });

        await executeLog({
          endpoint: 'activity',
          api_key: authUser.api_key,
          body: {
            attributes: {
              type: 'Add User',
              created_user_id: db_user_added.insertId,
              user_id: user.id,
              dept: newUser.department,
              name: newUser.name,
              email: newUser.email,
              level: newUser.level_group.level,
              level_group: newUser.level_group,
              initials: newUser.initial
            }
          }
        });
      }
    } catch (err) {
      setCognitoResponse({
        result: 'error',
        message: err.message
      });
    }
  };

  return (
    <GlobalDialog
      open={open}
      closeModal={closeDialog}
      title="Add User"
      handleSubmit={handleSubmit}
      buttonTitle="Add User"
      successMessage="User added successfully!"
      loading={loading}
      error={error || cognitoResponse}
      data={data}
      fullWidth
    >
      <UserForm user={newUser} setUser={setNewUser} />
    </GlobalDialog>
  );
};

export default AddUser;
