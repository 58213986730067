import React, { useState, useEffect, useContext } from 'react';
import { Storage } from 'aws-amplify';
import axiosRequest from '../../../helpers/axiosRequest';
import { UserContext } from '../../../store/UserContext';
import AdditiveForm from './AdditiveForm';
import useAsync from '../../../Hooks/useAsync';
import GlobalDialog from '../../reusable/GlobalDialog';
import { AdditiveContext } from '../../../store/AdditiveContext';
import { SAVE_ADDITIVE } from '../../../store/AdditiveReducers';

const AddAdditive = ({ closeAddDialog, ...props }) => {
  //****** CONTEXT ******
  const { authUser, user } = useContext(UserContext);
  const { dispatchAdditives } = useContext(AdditiveContext);
  //****** STATE ******
  const [attributes, setAttributes] = useState();
  const [additiveExists, setAdditiveExists] = useState(false);

  const [SDS, setSDS] = useState();
  const [TDS, setTDS] = useState();
  const {
    error,
    data,
    loading,
    logCompleted,
    logError,
    executeAsync,
    executeLog
  } = useAsync({
    axiosRequest: axiosRequest
  });

  useEffect(() => {
    // TODO handle if activity error
    if (logError) console.log('Something went wrong with the Activity Log');
  }, [closeAddDialog, data, logCompleted, logError]);

  useEffect(() => {
    if (additiveExists) {
      setTimeout(() => {
        setAdditiveExists(false);
      }, 5000);
    }
  }, [additiveExists]);

  //****** FUNCTIONS ******
  //update state from child component AdditiveForm.js
  const updateAdditive = attributes => {
    setAttributes(attributes);
  };

  const logPDF_upload = async ({ pdf_type, name }) => {
    const response = await executeLog({
      endpoint: 'pdf',
      api_key: authUser.api_key,
      method: 'post',
      body: {
        pdf_type,
        name,
        user_details: {
          user: user.name,
          user_id: user.id
        }
      }
    });
    return response;
  };

  //Create new additive
  const handleSubmit = async e => {
    e.preventDefault();
    let pdf_uploads = [];
    const additiveObj = {
      additive_name: attributes.additive_name,
      additive_company: attributes.additive_company,
      additive_attr: {
        pdf: {
          sds: SDS ? attributes.sds : '',
          tds: TDS ? attributes.tds : ''
        },
        type: attributes.type,
        brand: attributes.brand,
        dilution: attributes.dilution,
        application: attributes.description,
        selling_points: attributes.selling_points
      },
      brand_id : attributes.brand_id
    };

    const additive_db = await executeAsync({
      endpoint: 'additives',
      api_key: authUser.api_key,
      method: 'post',
      body: additiveObj
    });

    // insertId will be 0 if additive already exists
    if (!additive_db.insertId) return setAdditiveExists(true);

    if (SDS) {
      const sds_path = `global_additives/${attributes.sds}`;
      try {
        await Storage.put(sds_path, SDS, {
          contentType: 'application/pdf'
        });
        // Log pdf upload
        const sds_pdf_log = await logPDF_upload({
          pdf_type: 'Additive SDS',
          name: sds_path
        });

        pdf_uploads = [
          ...pdf_uploads,
          {
            type: 'sds',
            path: sds_path,
            upload_id: sds_pdf_log.insertId
          }
        ];
      } catch (err) {
        console.log(err);
      }
    }

    if (TDS) {
      const tds_path = `global_additives/${attributes.tds}`;
      try {
        await Storage.put(tds_path, TDS, {
          contentType: 'application/pdf'
        });
        // Log pdf upload
        const tds_pdf_log = await logPDF_upload({
          pdf_type: 'Additive TDS',
          name: tds_path
        });

        pdf_uploads = [
          ...pdf_uploads,
          {
            type: 'sds',
            path: tds_path,
            upload_id: tds_pdf_log.insertId
          }
        ];
      } catch (err) {
        console.log(err);
      }
    }

    await executeLog({
      endpoint: 'activity',
      api_key: authUser.api_key,
      method: 'post',
      body: {
        attributes: {
          type: 'New Additive',
          user_id: user.id,
          additive_id: additive_db.insertId,
          pdf_uploads
        }
      }
    });

    setTimeout(async () => {
      dispatchAdditives({
        type: SAVE_ADDITIVE,
        payload: { ...additiveObj, additive_id: additive_db.insertId }
      });
      closeAddDialog();
      setAttributes();
    }, 1000);
  };

  //****** RENDER ******
  return (
    <GlobalDialog
      closeModal={closeAddDialog}
      title="Add New Entry"
      handleSubmit={handleSubmit}
      buttonTitle="Add Additive"
      successMessage="Additive added successfully!"
      loading={loading}
      error={error}
      data={data}
      keepMounted
      fullWidth
      maxWidth="sm"
      {...props}
    >
      <AdditiveForm
        formType="add"
        updateAdditive={updateAdditive}
        setSDS={setSDS}
        SDS={SDS}
        setTDS={setTDS}
        TDS={TDS}
      />
    </GlobalDialog>
  );
};

export default AddAdditive;
