import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { Grid } from '@mui/material';
import useAsync from '../../Hooks/useAsync';
import axiosRequest from '../../helpers/axiosRequest';
import { UserContext } from '../../store/UserContext';
import DropdownCoolantsManufacturers from '../reusable/DropdownCoolantsManufacturers';
import DropdownCoolantsByManufacturer from '../reusable/DropdownCoolantsByManufacturer';
import ReportCard from './ReportCard';
import ReportPDF from './ReportPDF';
import { Document, Page, Text, View } from '@react-pdf/renderer';
import parseEntries from '../../helpers/parseEntries';
import create_pdf_headers from './create_pdf_headers';
import create_pdf_rows from './create_pdf_rows';
import splitString from '../../helpers/splitString';
import slugifyString from '../../helpers/slugifyString';
import formatDate from '../../helpers/formatDate';
import { LayoutContext } from '../../store/LayoutContext';

const CoolantUsageByCoolant = () => {
  //******************* USE CONTEXT *******************
  const { authUser, user } = useContext(UserContext);
  const { language } = useContext(LayoutContext);
  //******************* USE STATES *******************

  const [open, setOpen] = useState(false);
  const [showPreview, setShowPreview] = useState(false);

  const [manufacturer, setManufacturer] = useState(null);
  const [coolant, setCoolant] = useState(null);
  const [pdf_data, setPdf_data] = useState(null);
  const [csv_data, setCsv_data] = useState([]);
  const { error, data, loading, executeAsync, setData } = useAsync({
    axiosRequest: axiosRequest
  });
  const [noData, setNoData] = useState(false);
  //******************* USE CALLBACKS *******************

  const runReport = useCallback(() => {
    setOpen(true);
    executeAsync({
      method: 'get',
      api_key: authUser.api_key,
      endpoint: `reports/coolant-usage`,
      params: { 
          coolant_id: coolant?.coolant_id,
          user_level : user.level,
          company_ids: JSON.stringify(user.companies_id)
       }
    });
  }, [authUser.api_key, coolant, user,executeAsync]);

  //******************* USE EFFECTS *******************
  // react-pdf throws an error if PDFViewer is open and repass data again, so we save it locally to only render the pdf once
  useEffect(() => {
    if (data && !pdf_data) {
      if (!data.length) return setNoData(true);
      const parse_attributes_new = parseEntries(data);
      // Create 'conc' column
      parse_attributes_new.forEach((result, i) => {
        const conc_column = result.attributes_new.find(
          attribute => attribute.attribute_id === 'conc'
        );
        // add conc property to object
        parse_attributes_new[i].conc = conc_column.attribute_values.value;
      });
      // Create 'conc' column
      parse_attributes_new.forEach((result, i) => {
        const ph_column = result.attributes_new.find(
          attribute => attribute.attribute_id === 'ph'
        );
        // add ph property to object
        parse_attributes_new[i].ph = ph_column.attribute_values.value;
        // remove columns that we don't need
        delete parse_attributes_new[i].attributes_new;
        delete parse_attributes_new[i].void;
        delete parse_attributes_new[i].additives;
      });
      setPdf_data(parse_attributes_new);
      setShowPreview(true);
    }
  }, [data, pdf_data]);

  useEffect(() => {
    if (noData)
      setTimeout(() => {
        setNoData(false);
        setOpen(false);
      }, 2000);
  }, [noData]);


  const exclude_columns = useMemo(() => ['company_name', 'coolant_id'], []);
  const expand_columns = useMemo(
    () => ['machine_name', 'machine_group', 'coolant_name'],
    []
  );
  const reduce_columns = useMemo(() => ['machine_id', 'conc', 'ph'], []);

  const create_pdf_data = useCallback(
    styles => {
      return (
        <Document key="Coolant Usage by Company" title="Coolant Usage by Company">
        <Page style={styles.BODY}>
          <View fixed style={styles.ROW_ABOVE_TABLE}>
            <Text style={styles.ROW_ABOVE_TABLE.BIG_TEXT}>
              {`${manufacturer.brand_name} - ${coolant.coolant_name}`}
            </Text>
            <Text style={styles.ROW_ABOVE_TABLE.SMALL_TEXT}>
              Total Machines: {data.length}
            </Text>
          </View>
          <View style={styles.TABLE}>
            <View style={styles.TABLE.HEADER} fixed>
              {create_pdf_headers({
                 language: language,
                headers: pdf_data[0],
                styles,
                exclude_columns,
                expand_columns,
                reduce_columns
              })}
            </View>
            {create_pdf_rows({
              rows: pdf_data,
              styles,
              group_value: 'company_name',
              exclude_columns,
              expand_columns,
              reduce_columns
            })}
          </View>
          </Page>
        </Document>
      );
    },
    [
      coolant,
      data,
      exclude_columns,
      pdf_data,
      expand_columns,
      reduce_columns,
      manufacturer
    ]
  );

  const create_csv_data = useCallback(() => {
    const headers = Object.keys(pdf_data[0]).map(header => splitString(header));

    const values = pdf_data.map(value => {
      const formatDates = {
        ...value,
        date_stamp: formatDate(value.date_stamp),
        last_coolant_change: formatDate(value.last_coolant_change)
      };
      return Object.values(formatDates);
    });

    setCsv_data([headers, ...values]);
  }, [pdf_data]);

  const csv_filename = useMemo(() => {
    return `report_${slugifyString(manufacturer?.brand_name)}_${slugifyString(
      coolant?.coolant_name
    )}`;
  }, [coolant, manufacturer]);

  const enableButton = useMemo(() => {
    if (coolant && manufacturer) return true;
  }, [coolant, manufacturer]);

  return (
    <>
      <ReportCard
        title="Coolant Usage by Coolant"
        buttonLabel="Run Report"
        enableButton={enableButton}
        loading={loading}
        error={error}
        runReport={runReport}
        disabled={!manufacturer || !coolant ? true : false}
        noData={noData}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <DropdownCoolantsManufacturers setManufacturer={setManufacturer} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DropdownCoolantsByManufacturer
              manufacturer={manufacturer}
              setCoolant={setCoolant}
            />
          </Grid>
        </Grid>
      </ReportCard>

      {open &&
          <ReportPDF
            dialog_title="Coolant Usage by Coolant"
            dialogOpen={open}
            error={error}
            dialogClose={e => {
              //close and reset
              setOpen(false);
              setData();
              setPdf_data(null);
              setCsv_data([]);
              setShowPreview(false);
            }}
            noData={noData}
            showPreview={showPreview}
            create_pdf_data={create_pdf_data}
            create_csv_data={create_csv_data}
            csv_data={csv_data}
            csv_filename={csv_filename}
          />
        }
      {/* {pdf_data && (
        <ReportPDF
          dialog_title="Coolant Usage by Coolant"
          doc_title={coolant.coolant_name}
          setData={setData}
          dialogOpen={Boolean(pdf_data.length)}
          setPdf_data={setPdf_data}
          // we also need to pass a clean up fn that set the main data to null
          create_pdf_data={create_pdf_data}
          create_csv_data={create_csv_data}
          csv_data={csv_data}
          csv_filename={csv_filename}
        />
      )} */}
    </>
  );
};

export default CoolantUsageByCoolant;
