import {
    Grid,
    TextField,
    Card,
    CardContent,
    Typography
} from '@mui/material';
import Autocomplete, {
    createFilterOptions
} from '@mui/material/Autocomplete';
import React, { useEffect, useContext, useState } from 'react';
import { UserContext } from '../../../store/UserContext';
import { MachineContext } from '../../../store/MachineContext';
import { fetchUsers } from '../../../helpers/fetchUsers';
import useAsync from '../../../Hooks/useAsync';
import axiosRequest from '../../../helpers/axiosRequest';
import GlobalDialog from '../../reusable/GlobalDialog';
import PrimaryBtnIcon from '../../buttons/PrimaryBtnIcon';
import { buttonStyles } from '../../../styles/buttonStyles';
import { faBell } from '@fortawesome/pro-light-svg-icons';
import { UPDATE_MACHINE_SETTINGS } from '../../../store/MachineReducers';
import setLanguageText from '../../../helpers/setLanguageText';
import { LayoutContext } from '../../../store/LayoutContext';

const filter = createFilterOptions();

const Frequency = ({ edit }) => {
    //****** CONTEXT ******

    const { authUser, user } = useContext(UserContext);
    const { selectedMachine, dispatchSelectedMachine } = useContext(MachineContext);
    const { language } = useContext(LayoutContext)

    const {
        error,
        data,
        loading,
        logCompleted,
        logError,
        executeAsync,
        executeLog,
        setData
    } = useAsync({
        axiosRequest: axiosRequest
    });

    const {
        //error: note_error,
        //data: note_data,
        //loading: note_loading,
        //logCompleted: note_logCompleted,
        //logError: note_logError,
        executeAsync: note_executeAsync,
        //executeLog: note_executeLog,
        //setData: note_setData
    } = useAsync({
        axiosRequest: axiosRequest
    });


    //****** STYLES ******
    const buttonStyle = buttonStyles();

    //****** STATE ******

    const [frequency, setFrequency] = useState(false)
    const [emailList, setEmalList] = useState();
    const [admin, setAdmin] = useState();
    const [machineDefaults, setMachineDefaults] = useState();
    const [nextCheck, setNextCheck] = useState();

    const frequencyOptions = [
        //{ value: '0', title: 'Daily' },
        { value: '1', title: 'Weekly' },
        { value: '2', title: 'Every 2 Weeks' },
        { value: '3', title: 'Every 3 Weeks' },
        { value: '4', title: 'Monthly' }
    ];


    const dayOptions = [
        { value: '0', title: 'Monday' },
        { value: '1', title: 'Tuesday' },
        { value: '2', title: 'Wednesday' },
        { value: '3', title: 'Thursday' },
        { value: '4', title: 'Friday' },
        { value: '5', title: 'Saturday' },
        { value: '6', title: 'Sunday' }
    ];

    useEffect(() => {
        //console.log(admin)
        if (admin?.check_freq?.value) {
            let now = new Date();
            //console.log(admin.check_freq.value)
            if (admin.check_freq.value < 4) {
                now.setDate(now.getDate() + parseInt(admin.check_freq.value) * 7);
                now.setDate(now.getDate() - now.getDay() + 1);
                if (admin?.check_day?.value) {
                    now.setDate(now.getDate() + parseInt(admin.check_day.value));
                }
            }
            setNextCheck(now);
        }
    }, [admin])

    //****** FUNCTIONS ******

    const updateChange = (e) => {
        e.persist();
        setMachineDefaults(prevState => {
            return ({
                ...prevState,
                "change_freq": e.target.value
            })
        })
    }

    const updateFrequency = (e, frequency) => {
        //console.log(frequency)  
        setAdmin(prevState => {
            return ({
                ...prevState,
                "check_freq": frequency
            })
        })
    }

    const updateDay = (e, day) => {
        //console.log(frequency)  
        setAdmin(prevState => {
            return ({
                ...prevState,
                "check_day": day
            })
        })
    }
    const updateEmail = (e, email) => {
        //console.log(frequency)  
        setAdmin(prevState => {
            return ({
                ...prevState,
                "operator_email": email
            })
        })
    }

    const updateNotify = (e) => {
        e.persist()
        //console.log(e.target.value)  
        setAdmin(prevState => {
            return ({
                ...prevState,
                "notify_day": e.target.value
            })
        })
    }

    const updateSuperviser = (e, newValue) => {

        if (typeof newValue === 'string') {
            setAdmin(prevState => {
                return ({
                    ...prevState,
                    "supervisers_email": { title: newValue }
                })
            })
        } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            setAdmin(prevState => {
                return ({
                    ...prevState,
                    "supervisers_email": { title: newValue.inputValue }
                })
            })
        } else {
            setAdmin(prevState => {
                return ({
                    ...prevState,
                    "supervisers_email": newValue
                })
            })
        }
    }

    useEffect(() => {
        // fetch Users list
        //console.log(selectedMachine)
        if (selectedMachine.machine_settings) {
            setAdmin(selectedMachine.machine_settings.admin ? selectedMachine.machine_settings.admin : [])
            setMachineDefaults(selectedMachine.machine_settings.machine_defaults ? selectedMachine.machine_settings.machine_defaults : [])
        }

    }, [selectedMachine]);

    useEffect(() => {
        // fetch Users list
        //console.log(user)
        if (authUser.api_key) {
            fetchUsers({
                api_key: authUser.api_key,
                company_ids: user.companies_id,
                user_level: user.level
            }).then(users => {
                //let parsedUsers = users.map(user => parseUser(user))
                //setEmalList(parsedUsers);
                setEmalList(users);
            });
        }
    }, [authUser, user, setEmalList]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        let settings = {
            machine_defaults: {
                ...machineDefaults
            },
            admin: {
                ...admin
            }
        }
        //console.log(machineDefaults);
        //console.log(admin);
        let response = await executeAsync({
            endpoint: 'machines',
            api_key: authUser.api_key,
            method: 'patch',
            body: {
                edit: 'admin',
                machine_id: selectedMachine.machine_id,
                settings: settings
            }
        });

     
            await note_executeAsync({
                endpoint: 'notifications',
                api_key: authUser.api_key,
                method: 'post',
                body: {
                    user_id: settings.admin.operator_email.id,
                    edit: edit,
                    machine_id: selectedMachine.machine_id,
                    note_name: 'Machine Check Notification',
                    note_type: 'machine_check',
                    note_frequency: {
                        change_freq: machineDefaults.change_freq,
                        check_day: settings.admin.check_day,
                        check_freq: settings.admin.check_freq,
                    },
                    note_settings: {
                        machine_name: selectedMachine.machine_name,
                        last_entry: selectedMachine.last_entry,
                        notify_day: settings.admin.notify_day, 
                        operator_email: settings.admin.operator_email,
                        supervisers_email: settings.admin.supervisers_email,
                    },
                    note_sent: 0,
                    note_body: 'MACHINE EMAIL',
                    note_action: {"action": "Machine check reminder", "description": "Email reminder"},
                    note_next_send : nextCheck
                }
            });
      
            dispatchSelectedMachine({
                type: UPDATE_MACHINE_SETTINGS,
                payload: settings
            });

        if (response) {
            await executeLog({
                endpoint: 'activity',
                api_key: authUser.api_key,
                body: {
                    attributes: {
                        type: 'Updated Check Frequency',
                        user_id: user.id,
                        machine_id: selectedMachine.machine_id,
                        new_settings: settings
                    }
                }
            });
            setData();
        }
    }

    useEffect(() => {
        if (data) {
            // TODO handle if activity error
            if (logError) console.log('Something went wrong with the Activity Log');
            if (logCompleted) {
                setFrequency(false);
            }
        }
    }, [data, logCompleted, logError]);


    //****** RENDER ******
    return <>
        <Grid item xs={12} md={6} >
            <PrimaryBtnIcon
                icon={faBell}
                text="Check Frequency"
                onClick={e => setFrequency(true)}
                className={buttonStyle.danger}
            />
        </Grid>
        { frequency ?
            <GlobalDialog
                open={frequency}
                closeModal={e => setFrequency(false)}
                title="Check Frequency &amp; Notifications"
                handleSubmit={handleSubmit}
                buttonTitle="Update Details"
                successMessage="Details updated successfully!"
                loading={loading}
                error={error}
                data={data}
                fullWidth
                maxWidth="md"
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                           label={setLanguageText(language,"Coolant Change Frequency (days)")}
                            id="change_freq"
                            variant="outlined"
                            type="number"
                            value={machineDefaults?.change_freq || ''}
                            onChange={updateChange}
                        // helperText="How many day between coolant changes"
                        // InputProps={{
                        //   startAdornment: <InputAdornment position="start">Coolant Change Frequency</InputAdornment>,
                        // }}
                        />
                    </Grid>
                    <Grid container item spacing={2}>
                        <Grid item xs={6}>
                            <Autocomplete
                                id="check_freq"
                                options={frequencyOptions}
                                fullWidth
                                getOptionLabel={(option) => option.title || ''}
                                isOptionEqualToValue={(option, value) => option.value === value}
                                renderInput={params => (
                                    <TextField
                                        required
                                        {...params}
                                       label={setLanguageText(language,"Select Frequency")}
                                        variant="outlined"
                                    />
                                )}
                                value={admin?.check_freq || ''}
                                onChange={updateFrequency}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Autocomplete
                                id="check_day"
                                options={dayOptions}
                                fullWidth
                                getOptionLabel={(option) => option.title || ''}
                                isOptionEqualToValue={(option, value) => option.value === value}
                                renderInput={params => (
                                    <TextField
                                        required
                                        {...params}
                                       label={setLanguageText(language,"Day Starting")}
                                        variant="outlined"
                                    />
                                )}
                                value={admin?.check_day || ''}
                                onChange={updateDay}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                id="operator_email"
                                options={emailList || []}
                                getOptionLabel={option =>
                                    Object.entries(option).length
                                        ? `${option.name} - ${option.email}`
                                        : ''
                                }
                                //getOptionLabel={(option) => option.name || ''}
                                isOptionEqualToValue={(option, value) => option.id === value}
                                renderOption={(props, option) => <li {...props} key={option.id} >{option.name} - {option.email}</li>}
                                renderInput={params => (
                                    <TextField
                                        required
                                        type="text"
                                        {...params}
                                       label={setLanguageText(language,"Operator Email")}
                                        variant="outlined"
                                        autoComplete="false"
                                    />
                                )}
                                value={admin?.operator_email || null}
                                onChange={updateEmail}
                            />

                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                               label={setLanguageText(language,"Operator Notification days")}
                                id="notification_day"
                                variant="outlined"
                                type="number"
                                value={admin?.notify_day || ''}
                                onChange={updateNotify}
                            />
                        </Grid>
                        <Grid item xs={12}>

                            <Autocomplete
                                fullWidth
                                multiple // for having mutilple select
                                value={admin?.supervisers_email || []}
                                onChange={updateSuperviser}
                                filterOptions={(options, params) => {
                                    const filtered = filter(options, params);

                                    // Suggest the creation of a new value
                                    if (params.inputValue !== '') {
                                        filtered.push({
                                            email: params.inputValue,
                                            title: `Add "${params.inputValue}"`
                                        });
                                    }
                                    return filtered;
                                }}
                                //selectOnFocus
                                //clearOnBlur
                                handleHomeEndKeys
                                id="supervisor_email"
                                options={emailList || []}
                                getOptionLabel={option => {
                                    // Value selected with enter, right from the input
                                    if (typeof option === 'string') {
                                        return option;
                                    }
                                    // Add "xxx" option created dynamically
                                    if (option.inputValue) {
                                        return option.inputValue;
                                    }
                                    // Regular option
                                    return option.email;
                                }}
                                renderOption={(props, option) => <li {...props} key={option.id} >{option.name} - {option.email}</li>}
                                //style={{ width: 300 }}
                                //freeSolo
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        //required
                                       label={setLanguageText(language,"Supervisor Email")}
                                        variant="outlined"
                                        required={!admin?.supervisers_email}
                                        type="text"
                                        //autoComplete="false"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    {language.id === 'de' ?
                                    <Typography>
                                        Das System wird prüfen ob das das Kühlmittel Frequenz wählen gewechselt werden muss, beginnend mit einem Startdatum und den Email des Bedieners für einen Zeitraum von Benachrichtigung des Operators (Tage) aufeinanderfolgende Tage per email erinnern. Wenn kein Wechsel stattfindet wird das System den Projektleiter per email informieren.
                                    </Typography>
                                    :
                                    <Typography>
                                        The system will check if the coolant needs changing <strong>{admin?.check_freq?.title || 'Select Frequency'}</strong> on a
                                    {' '}<strong>{admin?.check_day?.title || 'Select Day Starting'}</strong><br />
                                    and email <strong>{admin?.operator_email?.email || 'Select Operator'}</strong> for <strong>{admin?.notify_day || 'Select Notification Days'}</strong> consecitive days<br />
                                    If no coolant change has been actioned, then the system will email <strong>
                                            {admin?.supervisers_email ?
                                                <>
                                                    {admin?.supervisers_email.map((user, i) => {
                                                        return <strong key={i} >{i > 0 ? ', ' : ' '}{user.email}</strong>
                                                })}
                                            </>
                                            : 'Select Supervisor'}</strong>
                                        </Typography>
                                    }
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </GlobalDialog>
            : null}
    </>;
};

export default Frequency;
