import React from 'react';
import {
  faEdit,
  faLock,
  faPaperPlane,
  faPlaneAlt,
  faToggleOff,
  faToggleOn
} from '@fortawesome/pro-light-svg-icons';
import { buttonStyles } from '../../styles/buttonStyles';
import PrimaryBtnIcon from '../buttons/PrimaryBtnIcon';
import config from './../../config';
import cognitoPromise from '../../helpers/cognitoPromise';
import { Grid, Snackbar, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import DefaultColumnFilter from '../reusable/DefaultColumnFilter';
import SelectColumnFilter  from '../reusable/SelectColumnFilter';
import setLanguageText from '../../helpers/setLanguageText';

const disableUser = async (e, row) => {
  e.preventDefault();
  //console.log(row)
  const userParams = {
    UserPoolId: config.cognito.USER_POOL_ID,
    Username: row.original.Username
  };
  try {
    await cognitoPromise({
      params: userParams,
      fn: 'adminDisableUser'
    });
    row.setState(prevState => ({ ...prevState, enabled: false }));
  } catch (err) {
    row.setState(prevState => ({
      ...prevState,
      error: err.message
    }));
  }
};

const enableUser = async (e, row) => {
  const userParams = {
    UserPoolId: config.cognito.USER_POOL_ID,
    Username: row.original.Username
  };
  try {
    await cognitoPromise({
      params: userParams,
      fn: 'adminEnableUser'
    });
    row.setState(prevState => ({ ...prevState, enabled: true }));
  } catch (err) {
    row.setState(prevState => ({
      ...prevState,
      error: err.message
    }));
  }
};

const resendEmail = async (e, row) => {
  const userParams = {
    UserPoolId: config.cognito.USER_POOL_ID,
    Username: row.original.Username,
    MessageAction: "RESEND",
  };
  try {
    await cognitoPromise({
      params: userParams,
      fn: 'adminCreateUser'
    });
    row.setState(prevState => ({ ...prevState, enabled: true }));
  } catch (err) {
    row.setState(prevState => ({
      ...prevState,
      error: err.message
    }));
  }
};

// const enabled = row => {
//   return (
//     <Grid container spacing={1} justifyContent="center">
//       <Grid item>
//         <FontAwesomeIcon
//           icon={
//             row.state.enabled
//               ? faCheckCircle
//               : !row.state.enabled
//                 ? faExclamationCircle
//                 : faTimesCircle
//           }
//           className={buttonStyles().icon_md}
//         />
//       </Grid>
//       <Grid item>
//         <Typography>
//           {row.state.enabled
//             ? 'Active'
//             : !row.state.enabled
//               ? 'Disabled'
//               : 'No Login'}
//         </Typography>
//       </Grid>
//     </Grid>
//   );
// };

const closeAlert = row => {
  row.setState(prevState => ({ ...prevState, error: null }));
};


const create_usersTable_columns = ({ handleEdit ,handleCreate, classes, language }) => {

  return [
    

    { id: 'full_name', 
    Header: () => null,
    name: 'Full Name',
    //Header: () => <Typography variant="h6">Name</Typography>,
    accessor: 'full_name',
    Filter: (column) => SelectColumnFilter(column, setLanguageText(language,column.column.name), language),
    //Filter: DefaultColumnFilter, 
      Cell: ({ row }) => {
        return ( 
        <>
          <Typography className={classes.cell}>
            <strong>{row.original.full_name}</strong><br/>
            {row.original.email}<br/>
            {setLanguageText(language,"Department")}: {row.original.department}
          </Typography>
          </>
          )
      }
    },
    // { id: 'email', Header: 'Email', accessor: 'email' },
    {
      id: 'company_name',
      name: 'Company',
      //Header: () => <Typography variant="h6">Company</Typography>,
      Header: () => null,
      Filter: (column) => SelectColumnFilter(column, setLanguageText(language,column.column.name),language),
      accessor: 'company_name',
      Cell: ({ row }) => {
        return <Typography className={classes.cell}>{row.original.company_name} </Typography>;
      }
    },
    // {
    //   id: 'companies_id',
    //   Header: 'Companies IDs',
    //   accessor: 'companies_id',
    //   Cell: ({ row }) => {
    //     const parsedIds = JSON.parse(row.original.companies_id);
    //     return (
    //       <Grid container spacing={1} justifyContent="center">
    //         {parsedIds.map(id => (
    //           <Grid item key={id}>
    //             <Chip label={id} />
    //           </Grid>
    //         ))}
    //       </Grid>
    //     );
    //   }
    // },
    {
      id: 'groupName',
      Header: () => <Typography variant="h6">{setLanguageText(language,"Details")}</Typography>,
      accessor: 'groupName',
      minWidth: 120,
      width: 120,
      maxWidth: 120,
      Cell: ({ row }) => {
        // return user group name
        return (
          <>
          <Typography className={classes.cell}>
          {setLanguageText(language,"User Type")}: {row.original.level_group && JSON.parse(row.original.level_group).name}<br/>
          {setLanguageText(language,"Username")}: {row.original.Username}<br/>
          {setLanguageText(language,"Status")}: {row.original.UserStatus}
          </Typography>
          </>
        )
      }
     },
    // {
    //   Filter: DefaultColumnFilter, 
    //   id: 'Level',
    //   Header: 'Level',
    //   accessor: 'level',
    //   minWidth: 120,
    //   width: 120,
    //   maxWidth: 120
    // },
    // {
    //   id: 'Username',
    //   Header: 'Username',
    //   accessor: 'Username',
    //   minWidth: 120,
    //   width: 120,
    //   maxWidth: 120
    // },
    // { id: 'UserStatus', Header: 'Info', accessor: 'UserStatus' },
    // {
    //   id: 'Enabled',
    //   Header: 'Status',
    //   accessor: 'Enabled',
    //   minWidth: 150,
    //   width: 150,
    //   maxWidth: 150,
    //   Cell: ({ row }) => enabled(row)
    // },
    {
      id: 'Actions',
      Header: () => <Typography variant="h6">{setLanguageText(language,"Actions")}</Typography>,
      sortable: false,
      minWidth: 150,
      width: 150,
      maxWidth: 150,
      Cell: ({ row }) => {
        return (
          <Grid container spacing={1} justifyContent="center">
           {row.original.Username ?
            <>
            <Grid item xs={12}>
              <PrimaryBtnIcon
                icon={faEdit}
                text="Edit"
                //disabled
                //iconSize="2x"
                onClick={e => handleEdit(row)}
                size="small"
              />
            </Grid>
           
            {row.state.enabled && (
              <Grid item xs={12}>
                <PrimaryBtnIcon
                  icon={faToggleOn}
                  text="Disable"
                  // iconSize="2x"
                  onClick={e => disableUser(e, row)}
                  className={buttonStyles().grey}
                  size="small"
                />
              </Grid>
            )}
            {!row.state.enabled && (
              <Grid item xs={12}>
                <PrimaryBtnIcon
                  icon={faToggleOff}
                  text="Enable"
                  //iconSize="2x"
                  onClick={e => enableUser(e, row)}
                  className={buttonStyles().danger}
                  size="small"
                />
              </Grid>
            )}
            {row.original.UserStatus === "FORCE_CHANGE_PASSWORD" &&
              <Grid item xs={12}>
                <PrimaryBtnIcon
                  icon={faPaperPlane}
                  text="Resend Email"
                  //iconSize="2x"
                  onClick={e => resendEmail(e, row)}
                  className={buttonStyles().danger}
                  size="small"
                />
              </Grid>
            }
            {row.state.error && (
              <Snackbar
                open={Boolean(row.state.error)}
                autoHideDuration={5000}
                onClose={() => closeAlert(row)}
              >
                <Alert
                  onClose={() => closeAlert(row)}
                  severity="error"
                  elevation={6}
                  variant="standard"
                >
                  {row.state.error}
                </Alert>
              </Snackbar>
            )}
          </>
        :  
          <Grid item xs={12}>
            <PrimaryBtnIcon
              icon={faLock}
              text="Create v2 login"
              //disabled
              //iconSize="2x"
              onClick={e => handleCreate(row)}
              //className={buttonStyles().danger}
              size="small"
            />
          </Grid>
          }
          </Grid>
        );
      }
    }
  ];
};

export default create_usersTable_columns;
