import { TextField } from '@mui/material';
import { Alert, Autocomplete } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import axiosRequest from '../../helpers/axiosRequest';
import setLanguageText from '../../helpers/setLanguageText';
import useAsync from '../../Hooks/useAsync';
import { UserContext } from '../../store/UserContext';
import { LayoutContext } from '../../store/LayoutContext';

const DropdownAdditivesTypes = ({ setAdditive_type }) => {
  const { authUser } = useContext(UserContext);
  const { language } = useContext(LayoutContext); 
  const [additive_types, setAdditive_types] = useState([]);
  const [value, setValue] = useState(null);
  const { error, data, loading, executeAsync } = useAsync({
    axiosRequest: axiosRequest
  });

  useEffect(() => {
    if (!additive_types.length) {
      executeAsync({
        method: 'get',
        api_key: authUser.api_key,
        endpoint: 'additives',
        params: { get_types: true }
      });
    }
  }, [authUser.api_key, executeAsync, additive_types.length]);

  useEffect(() => {
    if (data) {
      setAdditive_types(data);
      //setValue(data[0]);
    }
  }, [data]);

  useEffect(() => {
    setAdditive_type(value);
  }, [setAdditive_type, value]);

  const onSelectType = (e, type) => {
    setValue(type);
  };

  if (error) return <Alert severity="error">{error.message}</Alert>;
  return (
    <Autocomplete
    disableClearable
      id="additive_types"
      options={additive_types}
      loading={loading}
      getOptionLabel={option => option.additive_type_name || ''}
      renderInput={params => (
        <TextField
          required
          {...params}
         label={setLanguageText(language,"Select Additive Type")}
          variant="outlined"
        />
      )}
      value={value}
      onChange={onSelectType}
    />
  );
};

export default DropdownAdditivesTypes;
