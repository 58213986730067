
import React, { useState, useEffect, useRef, useContext } from 'react';
import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import { Alert } from '@mui/material';
import OutlinedDiv from './OutlinedDiv';
import setLanguageText from '../../helpers/setLanguageText';
import { LayoutContext } from '../../store/LayoutContext';

const useStyles = makeStyles(theme => ({
  preview: {
    maxHeight: '200px',
    maxWidth: '200px',
    objectFit: 'contain'
  }
}));

const ImageUpload = ({ edit, path, setImage, setRef}) => {

  const [imageUrl, setImageUrl] = useState();
  const [imagePreview, setImagePreview] = useState();
  const { language } = useContext(LayoutContext)

  const ref = useRef();

  //****** STYLES ******
  const classes = useStyles();

  useEffect(() => {
    //console.log(path)
    if (path) {
      setImageUrl(path);
      setImagePreview();
      ref.current.value = "";
    } else {
      setImageUrl();
      setImagePreview();
      ref.current.value = "";
    }
    return () => {
    }
  }, [path])

  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.files[0]) {
      let file = e.target.files[0]
      //console.log(file);
      setImagePreview(file);
      setImage(file);
    }
  }
  const addDefaultSrc = () => {
    setImageUrl(null)
  }

  return (
    <Grid item container spacing={2}>
      <Grid item xs={12}>
      {imageUrl ?
            <img
              className={classes.preview}
              src={imageUrl}
              alt=""
              onError={addDefaultSrc}
            />
            :
            <Alert
              severity="error"
              elevation={1}
              variant="standard"
            >
             {setLanguageText(language,"No Logo Uploaded")} 
          </Alert>
        }
        </Grid>
      <Grid item xs={12}>
        {imagePreview &&
          <OutlinedDiv label={setLanguageText(language,"New Logo Preview")}>
          <img
            className={classes.preview}
            alt=""
            src={URL.createObjectURL(imagePreview)}
          />
          </OutlinedDiv>
        }
       
      </Grid>
      <Grid item xs={12} >
        <input
          ref={ref}
          disabled={!edit}
          type="file"
          accept="image/png,image/jpeg"
          onChange={handleChange}
        />
      </Grid>
    </Grid>
  );
};

export default ImageUpload;
