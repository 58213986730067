import axiosRequest from './axiosRequest';

// Check user level and fetch companies accordingly
export const fetchMachines = (api_key, fetchLastEntries, company_id) => {
  if (fetchLastEntries) {
    return axiosRequest({
      api_key,
      method: 'get',
      endpoint: 'machines/entries',
      params: {
        company_id
      }
    });
  }
  return axiosRequest({
    api_key,
    method: 'get',
    endpoint: 'machines',
    params: {
      company_id
    }
  });
};
