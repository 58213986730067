import React, { useState } from 'react';
import {
    Grid,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { faFilePdf } from '@fortawesome/pro-light-svg-icons';
import PrimaryBtnIcon from '../../buttons/PrimaryBtnIcon';
import PDFviewer from '../../pdf/PDFviewer';
import GlobalPdfDialog from '../../reusable/GlobalPdfDialog';

const useStyles = makeStyles(theme => ({
    dialog: {
        height: 'calc(100% - 64px)'
    }
}));

const CoolantPDF = ({
    additive
}) => {

    //****** STATES ******
    const [open, setOpen] = useState(false);
    const [path, setPath] = useState();

    //****** STYLES ******
    const classes = useStyles();

    //****** FUNCTIONS ******

    const handleOpen = type => {
        if (type === 'sds') {
            setPath('global_additives/' + additive.additive_attr.pdf.sds);
        } else if (type === 'tds') {
            setPath('global_additives/' + additive.additive_attr.pdf.tds);
        }
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    //****** RENDER ******
    return (
        <>
        <Grid container item spacing={1}>
        <Grid item xs={12} sm={6}>
                <PrimaryBtnIcon
                    disabled={additive.additive_attr.pdf.sds ? false : true}
                    icon={faFilePdf}
                    text="Safety data"
                    //iconSize="2x"
                    onClick={e => handleOpen('sds')}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <PrimaryBtnIcon
                    disabled={additive.additive_attr.pdf.tds ? false : true}
                    icon={faFilePdf}
                    text="Technical data"
                    //iconSize="2x"
                    onClick={e => handleOpen('tds')}
                />
            </Grid>
        </Grid>
            <GlobalPdfDialog
                title={additive.additive_attr.type + ' > ' + additive.additive_name}
                open={open}
                closeModal={handleClose}
                classes={{ paper: classes.dialog }}
                fullWidth
                maxWidth="xl"
            >
                {path && <PDFviewer path={path} />}
            </GlobalPdfDialog>
        </>
        
    );
};

export default CoolantPDF;
