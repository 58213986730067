import React from 'react';
import config from '../../config';

const Chat = () => {
  return (
        <iframe src={config.TIDIOCHAT} style={{
            width :"100%",
            border: "none",
            height: "300px",
            height: "100vh"
        }} 
        allowFullScreen 
        title="TIDIO">

        </iframe>
  );
};
export default Chat;
