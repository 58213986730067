import React, { useContext, useEffect, useState } from 'react';
import { MachineContext } from '../../../store/MachineContext';
import {
  SELECTED_MACHINE,
  SELECTED_MACHINES,
  SELECTED_GROUP,
  FILTER_MACHINES
} from '../../../store/MachineReducers';
import machinesByGroup from '../../../helpers/machinesByGroup';
import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import setLanguageText from '../../../helpers/setLanguageText';
import { LayoutContext } from '../../../store/LayoutContext';

function DropdownGroups({ setLoading }) {

  // get save from session storage selected group
  const [storedGroup] = useState(JSON.parse(window.sessionStorage.getItem('selectedGroup')));

  const {
    groups,
    machines,
    dispatchFilteredMachines,
    selectedGroup,
    dispatchSelectedGroup,
    dispatchSelectedMachine,
    dispatchSelectedMachines
  } = useContext(MachineContext);

  const {language} = useContext(LayoutContext)

  //states
  const [options, setOptions] = useState();

  // ON RENDER
  useEffect(() => {
    // Check what is the selected group and filter the machine accordingly
    if (selectedGroup) {
      dispatchFilteredMachines({
        type: FILTER_MACHINES,
        payload:
          selectedGroup.group_name ===  setLanguageText(language, "All Groups")
            ? machines
            : machinesByGroup(machines, selectedGroup.group_name)
      });
    }
  }, [selectedGroup, machines, dispatchFilteredMachines]);

  // add the option "ALL" into the dropdown
  useEffect(() => {
    //console.log(groups)
    if (groups.length) {
      setOptions([{ group_name:  setLanguageText(language, "All Groups") , group_id: 0 }, ...groups]);
    } else {
      setOptions([]);
    }
  }, [groups]);

  // update with stored group in session storage
  useEffect(() => {
    //console.log(storedGroup)
    dispatchSelectedGroup({
      type: SELECTED_GROUP,
      payload: storedGroup
    });
  }, [storedGroup, dispatchSelectedGroup]);

  const onSelectGroup = group => {

    if (setLoading) setLoading(true);

    if (group) {
      dispatchSelectedGroup({
        type: SELECTED_GROUP,
        payload: group
      });
      dispatchSelectedMachine({
        type: SELECTED_MACHINE,
        payload: { machine_id: 0, machine_name:  setLanguageText(language, "All Machines")}
      });
      //update session storage
      window.sessionStorage.setItem('selectedGroup', JSON.stringify(group));
      window.sessionStorage.setItem('selectedMachine', JSON.stringify({ machine_id: 0, machine_name: setLanguageText(language, "All Machines") }))

      dispatchSelectedMachines({
        type: SELECTED_MACHINES,
        payload: []
      });
      dispatchFilteredMachines({
        type: FILTER_MACHINES,
        payload:
          group.group_name === setLanguageText(language, "All Groups")
            ? machines
            : machinesByGroup(machines, group.group_name)
      });
    }
  };

  //add groups counter
  const modifiedValue =
    selectedGroup?.group_id === 0
      ? {
        ...selectedGroup,
        group_name: `${selectedGroup.group_name}`
      }
      : selectedGroup;

  return (
    <Autocomplete
      disableClearable
      id="selected-group"
      value={modifiedValue && Object.entries(modifiedValue).length ? modifiedValue : null}
      //value={modifiedValue && Object.entries(modifiedValue).length ? modifiedValue : null}
      options={options || []}
      //getOptionLabel={option => option.group_name || ''}
      loading={modifiedValue ? false : true}
      getOptionLabel={option => option.group_id === 0 ? `${option.group_name} (${options?.length > 0 ? (options.length-1) : 0})`: option.group_name }
      //loading={machines.length ? false : true}
      onChange={(event, newValue) => {
        onSelectGroup(newValue);
      }}
      isOptionEqualToValue={(option, value) =>
        option.group_id === value.group_id
      }
      renderInput={params => (
        <TextField {...params} variant="outlined" label={setLanguageText(language,"Selected Group/s")} />
      )}
    />
  );
}

export default DropdownGroups;




