import React, { useContext } from 'react';
import {
  Grid,
  TextField,
  Typography
} from '@mui/material';
import ImageUpload from '../reusable/ImageUpload';
import config from '../../config';
import { LayoutContext } from '../../store/LayoutContext';
import setLanguageText from '../../helpers/setLanguageText';

const BrandForm = ({ brand, updateBrand, setAdditiveImage, setCoolantImage }) => {

  const { language } = useContext(LayoutContext);
  
  //************  STYLES ************

  //************  CONTEXT ************

  //************ STATES ************

  //************  USE EFFECT ************

  //************ FUNCTIONS ************

  return (
    <Grid item container spacing={2}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          name="brand_name"
          label={setLanguageText(language,"Name")}
          variant="outlined"
          onChange={e => updateBrand(e)}
          value={brand ? brand.brand_name : ''}
        />
      </Grid>  
      <Grid item container spacing={2}>
        {setCoolantImage ? 
          <Grid item container xs={6}>
            <Grid item ><Typography>{setLanguageText(language,"Coolant Image")}</Typography></Grid>
            <ImageUpload
              path={brand?`https://${config.s3.BUCKET}.s3.${config.s3.REGION}.amazonaws.com/public/brands/${brand.brand_id}_coolant.png`: null}
              setImage={setCoolantImage}
              edit={true}
            />
          </Grid>
        : null }
           {setAdditiveImage ? 
          <Grid item container xs={6} >
            <Grid item ><Typography>{setLanguageText(language,"Additive Image")}</Typography></Grid>
            <ImageUpload
              path={brand? `https://${config.s3.BUCKET}.s3.${config.s3.REGION}.amazonaws.com/public/brands/${brand.brand_id}_additive.png`:null}
              setImage={setAdditiveImage}
              edit={true}
            />
          </Grid>
      : null}
      </Grid>

    </Grid>

  );
};

export default BrandForm;
