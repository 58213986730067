import QuickChart from 'quickchart-js';
//import { useTheme } from '@mui/styles';

const createBarChart = (results) => {
    //console.log(results)
    const data = {
        datasets: [
          {
            data:
              results.data.map((item) =>
                item.count
              ),
            backgroundColor:
              results.data.map((item) =>
                item.colour
              )
          }
        ],
        labels:
          results.data.map((item) =>
            item.label
          )
      };
      const options = {
        legend: {
          display: false
        },
        title: {
          display: false
        }
    };

    //console.log(data)

    const myChart = new QuickChart();
    myChart
        .setConfig({
            type: 'bar',
            data: data,
            options: options
        })
        .setWidth(250)
        .setHeight(200)
        .setBackgroundColor('transparent');

    // Print the chart URL
    //console.log(myChart.getUrl()); 

    return myChart.getUrl();
}

export default createBarChart;
