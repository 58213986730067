import {
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableFooter,
  TableRow
} from '@mui/material';
import React from 'react';
import { usePagination, useRowState, useSortBy, useTable } from 'react-table';
import TableSettings from '../Machines/TableSettings';
import Pagination from '../reusable/Pagination';

const CompaniesTable = ({ data, columns }) => {
  //************************  TABLE CREATE FUNCTION ************************
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    prepareRow,
    page,
    // The rest of these things are super handy, too ;)
    allColumns,
    getToggleHideAllColumnsProps,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      // Table's Initial state
      initialState: {
        pageIndex: 0
      },
      // Cell's Initial state
      initialCellStateAccessor: () => ({
        // used in the Status Column to handle the hover Popover
        anchorEl: null,
        isOpen: false
      })
    },
    useSortBy,
    usePagination,
    useRowState
  );
  return (
    <Grid item container xs={12} spacing={1}>
      <Grid item container xs={12} justifyContent="flex-end">
        <TableSettings
          allColumns={allColumns}
          getToggleHideAllColumnsProps={getToggleHideAllColumnsProps}
        />
      </Grid>
      <Grid item xs={12}>
        <Card variant="outlined">
          <CardContent
            style={{
              overflowX: 'auto',
              maxHeight: '70vh',
              padding: '0'
            }}
          >
            <Table
              {...getTableProps()}
              className="machines--table"
              stickyHeader
              aria-label="sticky table"
            >
              {/* TABLE HEADER */}
              <TableHead className="machines--header" style={{ width: '100%' }}>
                {
                  // Loop over the header rows
                  headerGroups.map(headerGroup => (
                    // Apply the header row props
                    <TableRow {...headerGroup.getHeaderGroupProps()}>
                      {
                        // Loop over the headers in each row
                        headerGroup.headers.map(column => {
                          return (
                            // Apply the header cell props
                            <TableCell
                              {...column.getHeaderProps(
                                // add sort functionality
                                column.getSortByToggleProps()
                              )}
                            >
                              {
                                // Render the header
                                column.render('Header')
                              }
                            </TableCell>
                          );
                        })
                      }
                    </TableRow>
                  ))
                }
              </TableHead>
              {/* TABLE CONTENT */}
              {/* Apply the table body props */}
              <TableBody {...getTableBodyProps()}>
                {
                  // Loop over the table rows
                  page.map((row, i) => {
                    // Prepare the row for display
                    prepareRow(row);
                    // reomve ALL from table
                    if (row.id !== '0') {
                      return (
                        // Apply the row props
                        <TableRow
                          {...row.getRowProps()}
                          style={{ backgroundColor: '#ffffff' }}
                        >
                          {
                            // Loop over the rows cells
                            row.cells.map(cell => {
                              // Apply the cell props
                              return (
                                <TableCell {...cell.getCellProps()}>
                                  {
                                    // Render the cell contents
                                    cell.render('Cell')
                                  }
                                </TableCell>
                              );
                            })
                          }
                        </TableRow>
                      );
                    }
                    return false
                  })
                }
              </TableBody>

              <TableFooter style={{ backgroundColor: '#fafafa' }}>
                {footerGroups.map(group => (
                  <TableRow {...group.getFooterGroupProps()}>
                    {group.headers.map(column => (
                      <TableCell {...column.getFooterProps()}>
                        {column.render('Footer')}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableFooter>
            </Table>
          </CardContent>
          {/* PAGINATION AND SETTINGS */}
          <Pagination
            allColumns={allColumns}
            rowCount={data.length}
            getToggleHideAllColumnsProps={getToggleHideAllColumnsProps}
            pageIndex={pageIndex}
            gotoPage={gotoPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            previousPage={previousPage}
            canPreviousPage={canPreviousPage}
            nextPage={nextPage}
            canNextPage={canNextPage}
            pageCount={pageCount}
          />
        </Card>
      </Grid>
    </Grid>
  );
};

export default CompaniesTable;
