const rhenus_theme = {
  components: {
    MuiGrid: {
      styleOverrides: {
        root: {
          '& .MuiGrid-container': {
            //backgroundColor: 'red'
          },
          //background : '#000',
          '& .MuiGrid-container .logo': {
            background : '#000',
            width: '100px',
            maxHeight: '80px',
            paddingTop: '4px!important',
            '& .img': {
              width: '100%',
              height: 'auto',
            }
          }
        }
      }
    }

  },
  palette: {
    primary: {
      main: '#ffcc00'
    },
    secondary: {
      main: '#ffffff'
    },
    error: {
      main: '#fc466f'
    },
    warning: {
      main: '#ffc500'
    },
    success: {
      main: '#00d79b'
    },
    background: {
      darker: '#282d4e',
      dark: '#657696',
      light: '#f0f0f0',
      lighter: '#fafafa'
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 427,
      md: 769,
      lg: 1025,
      xl: 1441
    }
  },
  typography: {
    h1: {
      fontSize: '3rem'
    },
    h2: {
      fontSize: '2.5rem'
    },
    h3: {
      fontSize: '2rem'
    },
    h4: {
      fontSize: '1.5rem'
    },
    h5: {
      fontSize: '1.2rem'
    },
    h6: {
      fontWeight: 600,
      fontSize: '15px',
      lineHeight: 1.2
    },
    body1: {
      fontSize: '14px'
    },
    body2: {
      fontSize: '13px'
    },
    caption: {
      fontSize: '12px'
    },
    button: {
      textTransform: 'none'
    }
  }
};

export default rhenus_theme;
