import React, { useState, useEffect, useContext } from 'react';
import { FormControl, Grid, MenuItem, Select, TextField, Typography, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PrimaryBtnIcon from '../buttons/PrimaryBtnIcon';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { isMobileOnly } from 'react-device-detect';
import { CompanyContext } from '../../store/CompanyContext';
import { LayoutContext } from '../../store/LayoutContext';
import setLanguageText from '../../helpers/setLanguageText';

const useStyles = makeStyles(theme => ({
  inputRows: {
    backgroundColor: '#ffffff',
    //fontWeight: 'bold',
    margin: 'auto',
    '& .MuiOutlinedInput-input': {
      padding: theme.spacing(1, 4)
    }
  },
  inputPage: {
    backgroundColor: '#ffffff',
    //fontWeight: 'bold',
    width: theme.spacing(8),
    margin: 'auto',
    '& .MuiOutlinedInput-input': {
      padding: theme.spacing(1)
    }
  }
}));

const Pagination = ({
  allColumns,
  rowCount,
  pageIndex,
  gotoPage,
  pageCount,
  pageSize,
  setPageSize,
  previousPage,
  canPreviousPage,
  nextPage,
  canNextPage
}) => {

  const inputStyle = useStyles();
  const theme = useTheme();
  const [options, setOptions] = useState([10, 20, 30, 50, 100]);
  const { selectedCompany } = useContext(CompanyContext)
  const { language } = useContext(LayoutContext);
  const [show, setShow] = useState(
    window.sessionStorage.getItem('show') ? window.sessionStorage.getItem('show') : 
    selectedCompany?.company_settings?.show ? selectedCompany.company_settings.show : 
    100);

  useEffect(()=>{
    setPageSize(show) 
    window.sessionStorage.setItem('show',show);
  },[show])

  // useEffect(() => {
  //   console.log(rowCount)
  //   console.log(pageCount)
  //   console.log(pageSize)
  //   console.log(pageCount * pageSize)
  //   console.log(rowCount >= (pageCount * pageSize))
  //   let option = []
  //   if(rowCount >  10){
  //     option.push(10)
  //   }
  //   if(rowCount >  20){
  //     option.push(20)
  //   }
  //   if(rowCount >  30){
  //     option.push(30)
  //   }
  //   if(rowCount >  50){
  //     option.push(50)
  //   }
  //   if(rowCount >  100){
  //     option.push(100)
  //   }
  //   //option.push(rowCount)
  //   setOptions(option)
  // },[rowCount,pageCount,pageSize])


  return (

    <Grid
      item
      xs={12}
      container
      className="pagination"
      justifyContent="space-between"
      alignItems="center"
      style={{
        padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
        backgroundColor: theme.palette.background.light,
      }}
    >
      {!isMobileOnly && rowCount &&
        <Grid item
          xs={12}
          md={3}
        >
          {setLanguageText(language,"Total")}: <strong>{allColumns[0]?.filteredRows ? allColumns[0].filteredRows.length : rowCount }</strong> / {rowCount}
        </Grid>
      }
      {!isMobileOnly ?
      <Grid
        item
        container
        alignItems="center"
        spacing={1}
        xs={7}
        md={3}
      >
          <Grid item>
            <Typography variant="button">{setLanguageText(language,"Page")}</Typography>
          </Grid>
          <Grid item>
            <TextField
              id="outlined-number"
              value={pageIndex + 1}
              type="number"
              variant="outlined"
              onChange={e => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              className={inputStyle.inputPage}
              disabled={pageCount > 1 ? false : true}
            />
          </Grid>
        <Grid item>
          <Typography variant="button">
            /<strong> {pageCount}</strong>
          </Typography>
        </Grid>
      </Grid>
      : null }
      <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
        xs={3}
        md={3}
      >
        <FormControl>
          <Select
            value={pageSize}
            id="select_rows"
            variant="outlined"
            onChange={e => {
              //setPageSize(Number(e.target.value));
              setShow(Number(e.target.value));
            }}
            className={inputStyle.inputRows}
          >
            {options.map(pageSize => (
              <MenuItem key={pageSize} value={pageSize}>
                {pageSize}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid
        item
        container
        alignItems="flex-end"
        //justify={!isMobileOnly ? 'flex-end' : 'space-between'}
        justifyContent="flex-end"
        xs={7}
        md={3}
        spacing={1}
      >
        <Grid item  >
          <PrimaryBtnIcon
            icon={faChevronLeft}
            onClick={e => previousPage()}
            disabled={!canPreviousPage}
            iconSize="lg"
          />
        </Grid>
        <Grid item >
          <PrimaryBtnIcon
            icon={faChevronRight}
            onClick={e => nextPage()}
            disabled={!canNextPage}
            iconSize="lg"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Pagination;
