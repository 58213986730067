import { Grid, LinearProgress, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, {useState, useEffect} from 'react';
import { buttonStyles } from '../../../styles/buttonStyles';
import Image from "./../../reusable/Image";
//import { Storage, Cache } from 'aws-amplify';


const useStyles = makeStyles(theme => ({
  linearBarContainer: {
    position: 'relative'
  },
  linearBar: {
    height: '100%',
    padding: '8px',
    position : 'relative',
    borderRadius: theme.spacing(0.5),
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: theme.palette.success.main
    },
    '&.MuiLinearProgress-determinate': {
      backgroundColor: theme.palette.background.light
    }
  },
  machineNumbers: {
    height: '100%',
    padding: '8px',
    position : 'relative',
    backgroundColor: theme.palette.warning.main
  },
  percentage: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    //transform: 'translateY(-50%)'
  },
  image: {
    padding: '10px',
    '& img' : {
      width: '100%',
    }
  }
}));

const Overview = ({ coolant, classes, totalMachines, noBar }) => {

  const [percentage, setPercentage] = useState();

  useEffect(()=>{
    if(totalMachines){
      setPercentage((coolant.machines_count / totalMachines) * 100);
    } else {
      setPercentage(0);
    }
  },[totalMachines,coolant])

  const linearBarStyle = useStyles();
  const buttonStyle = buttonStyles();

  return (
    <Grid container direction="column" alignItems="stretch" spacing={2}>
      { noBar && percentage ? 
        <Grid item container spacing={2} alignItems="stretch">

          <Grid item xs={8} sm={9} className={linearBarStyle.linearBarContainer}>
            <LinearProgress
              value={percentage}
              variant="determinate"
              className={linearBarStyle.linearBar}
            />
            <Typography variant="h6" className={linearBarStyle.percentage}>
              {`${percentage.toFixed(2)}%`}
            </Typography>
          </Grid>
          <Grid item xs={4} sm={3}>
            <Paper
              elevation={0}
              //className={`${buttonStyle.button} ${buttonStyle.warning}`}
              className={linearBarStyle.machineNumbers}
            >
              <Typography variant="h6" align="center">
                {totalMachines
                  ? `${coolant.machines_count}/${totalMachines}`
                  : `0/0`}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      : null }
      <Grid item container className="product--details" spacing={1}>
        <Grid item xs={10}>
          <Typography variant="body2" className="product--desc">
            {coolant.coolant_defaults.coolant.type}
          </Typography>
          <ul style={{padding: '0 0 0 1em', margin : '5px 0'}}>
          {coolant.coolant_defaults.coolant.selling_points &&
            coolant.coolant_defaults.coolant.selling_points.map(
              (point, index) => (
                <li key={`point--${index}`}>
                  <Typography variant="body2" className={classes.li}>
                    {point}
                  </Typography>
                </li>
              )
            )}
          </ul>
        </Grid>
        <Grid item className={linearBarStyle.image} xs={2}>
          <Image coolant={coolant}/>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Overview;
