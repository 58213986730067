import React, { useState, useContext, useEffect } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Switch, Grid } from '@mui/material';
import { faSave } from '@fortawesome/pro-light-svg-icons';
import { Alert } from '@mui/material';
import { MachineContext } from '../../../store/MachineContext';
import { SELECTED_MACHINE } from '../../../store/MachineReducers';
import axiosRequest from '../../../helpers/axiosRequest';
import { UserContext } from '../../../store/UserContext';
import LoadingButton from '../../buttons/LoadingButton';
import useAsync from '../../../Hooks/useAsync';
import setLanguageText from '../../../helpers/setLanguageText';
import { LayoutContext } from '../../../store/LayoutContext';

const FactorMachine = () => {
  const [factor, setFactored] = useState(false);
  const { authUser, user } = useContext(UserContext);
  const { language } = useContext(LayoutContext);
  const { selectedMachine, dispatchSelectedMachine } = useContext(MachineContext);

  useEffect(() => {
    if (selectedMachine?.machine_settings?.factored) {
      setFactored(selectedMachine.machine_settings.factored);
    }
  }, [selectedMachine]);

  const handleChange = factor => {
    const selected = {
      ...selectedMachine,
      machine_settings: {
        ...selectedMachine.machine_settings,
        factored: !factor
      }
    };
    //console.log(selected)
    dispatchSelectedMachine({
      type: SELECTED_MACHINE,
      payload: selected
    });
    setFactored(!factor);
  };

  const {
    //error,
    data,
    loading,
    logCompleted,
    logError,
    executeAsync,
    executeLog
    //setData
  } = useAsync({
    axiosRequest: axiosRequest
  });

  const saveFactored = async (factor, e) => {
      e.preventDefault();
      const response = await executeAsync({
        endpoint: 'machines',
        api_key: authUser.api_key,
        method: 'patch',
        body: {
          edit: 'factor',
          machine_id: selectedMachine.machine_id,
          factored: Boolean(factor)
        }
       });
       if (response) {
          await executeLog({
            endpoint: 'activity',
            api_key: authUser.api_key,
            body: {
              attributes: {
                type: 'Machine Factor Updated',
                user_id: user.id,
                machine_id: selectedMachine.machine_id,
                factored: Boolean(factor)
              }
            }
      });
   }
    // try {
    //   await axiosRequest({
    //     endpoint: 'machines',
    //     api_key: authUser.api_key,
    //     method: 'patch',
    //     body: {
    //       edit: 'factor',
    //       machine_id: selectedMachine.machine_id,
    //       factored: Boolean(factor)
    //     }
    //   });
    //   setLoading(false);
    //   //window.location.reload();
    // } catch (error) {
    //   throw error;
    // }
  };

  useEffect(() => {
    if (data) {
      // TODO handle if activity error
      if (logError) console.log('Something went wrong with the Activity Log');
      if (logCompleted) {        

      }
    }
  }, [data, logCompleted, logError]);

  return (
    <Grid
      item
      container
      justifyContent="space-between"
      alignItems="center"
      spacing={2}
    >
      <Grid item style={{ width: '100%' }}>
        <Alert severity="info">
          {setLanguageText(language, "If you want the Refractometer Factor settings applied to all Readings and Concentration Min And Max")}.
        </Alert>
      </Grid>
      <Grid item>
        <FormControlLabel
          control={
            <Switch
              checked={factor}
              onChange={e => handleChange(factor)}
              name="checkedB"
              color="primary"
            />
          }
          label={setLanguageText(language, 
            !factor
              ? 'Apply Refractometer Factor'
              : 'Remove Refractometer Factor'
          )}
        />
      </Grid>
      <Grid item>
        <LoadingButton
            icon={faSave}
            text="Save Refractometer Factor"
            iconSize="1x"
            color="primary"
            loading={loading}
            onClick={e => saveFactored(factor, e)}
          />
      </Grid>
    </Grid>
  );
};
export default FactorMachine;
