import React, { useContext, useEffect, useState } from 'react';
import { SelectionsContext } from '../../store/SelectionsContext';
import { CompanyContext } from '../../store/CompanyContext';
import { TextField } from '@mui/material';
import {
  SAVE_COMPANY,
  SAVE_GROUPS,
  SAVE_MACHINE
} from '../../store/SelectionsReducers';
import { UserContext } from '../../store/UserContext';
import { fetchMachines } from '../../helpers/fetchMachines';
import extractMachineGroups from '../../helpers/extractMachineGroups';
import { SAVE_MACHINES } from '../../store/MachineReducers';
import Autocomplete from '@mui/material/Autocomplete';
import { LayoutContext } from '../../store/LayoutContext';
import setLanguageText from '../../helpers/setLanguageText';

function DropdownCompanies({ position, hideAll }) {
  const {
    addNewMachine,
    dispatchAddNewMachine,
    addNewEntry,
    dispatchAddNewEntry,
    dispatchGroups,
    dispatchMachines
  } = useContext(SelectionsContext);
  const { companies: allCompanies, selectedCompany } =
    useContext(CompanyContext);
  const { authUser } = useContext(UserContext);
  const { language } = useContext(LayoutContext);
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    if (allCompanies.length && !allCompanies[0].company_id) {
      const removeAllOption = allCompanies.filter(
        company => company.company_id !== 0
      );
      setCompanies(removeAllOption);
    }
  }, [allCompanies]);

  useEffect(() => {
    switch (position) {
      case 'entry':
        if (addNewEntry.company) {
          fetchMachines(
            authUser.api_key,
            false,
            addNewEntry.company.company_id
          ).then(data => {
            dispatchAddNewEntry({ type: SAVE_MACHINE, payload: data[0] });
            dispatchMachines({
              type: SAVE_MACHINES,
              payload: data
            });
            dispatchGroups({
              type: SAVE_GROUPS,
              payload: extractMachineGroups(data)
            });
          });
        }
        // if no company selected before, initial value it to global selected Company
        else
          dispatchAddNewEntry({
            type: SAVE_COMPANY,
            payload: selectedCompany.company_id
              ? selectedCompany
              : // companies[0] can be undefined
                companies[0] || null
          });
        break;
      case 'machine':
        if (addNewMachine.company) {
          fetchMachines(
            authUser.api_key,
            false,
            addNewMachine.company.company_id
          ).then(data => {
            dispatchMachines({
              type: SAVE_MACHINES,
              payload: data
            });
            dispatchGroups({
              type: SAVE_GROUPS,
              payload: extractMachineGroups(data)
            });
          });
        }
        // if no company selected before, initial value it to global selected Company
        else
          dispatchAddNewMachine({
            type: SAVE_COMPANY,
            payload: selectedCompany.company_id
              ? selectedCompany
              : // companies[0] can be undefined
                companies[0] || null
          });
        break;

      default:
        break;
    }
  }, [
    position,
    addNewEntry.company,
    addNewMachine.company,
    authUser.api_key,
    dispatchMachines,
    dispatchGroups,
    dispatchAddNewMachine,
    dispatchAddNewEntry,
    selectedCompany,
    companies
  ]);

  // When user selects company from dropdown
  const onSelectCompany = (e, company) => {
    // If click on the x icon or nothing is selected in the dropdown, clear Machines, Groups and Machine
    if (!company) {
      dispatchAddNewEntry({ type: SAVE_MACHINE, payload: null });
      dispatchMachines({
        type: SAVE_MACHINES,
        payload: null
      });
      dispatchGroups({
        type: SAVE_GROUPS,
        payload: []
      });
    }
    // based on where the dropdown is dispatch in different context
    switch (position) {
      case 'entry':
        dispatchAddNewEntry({ type: SAVE_COMPANY, payload: company });
        break;
      case 'machine':
        dispatchAddNewMachine({ type: SAVE_COMPANY, payload: company });
        break;
      default:
        break;
    }
  };

  return (
    <Autocomplete
      id="companies"
      options={ hideAll ? companies : allCompanies}
      getOptionLabel={option => option.company_name || ''}
      renderInput={params => (
        <TextField
          required
          {...params}
          label={setLanguageText(language,"Select Company")}
          variant="outlined"
        />
      )}
      value={
        position === 'entry'
          ? addNewEntry.company
          : position === 'machine'
          ? addNewMachine.company
          : null
      }
      onChange={onSelectCompany}
    />
  );
}

export default DropdownCompanies;
