import React, { useState, useContext, useEffect } from 'react';
import { Storage } from 'aws-amplify';
import axiosRequest from '../../../helpers/axiosRequest';
import { UserContext } from '../../../store/UserContext';
import { AdditiveContext } from '../../../store/AdditiveContext';
import AdditiveForm from './AdditiveForm';
import GlobalDialog from '../../reusable/GlobalDialog';
import useAsync from '../../../Hooks/useAsync';
import slugifyString from '../../../helpers/slugifyString';
import { UPDATE_ADDITIVE } from '../../../store/AdditiveReducers';

const EditAdditive = ({ isDialog, setIsDialog, additive }) => {
  const {
    data,
    loading,
    error,
    logCompleted,
    logError,
    executeAsync,
    executeLog
  } = useAsync({
    axiosRequest: axiosRequest
  });
  //****** CONTEXT ******
  const { authUser, user } = useContext(UserContext);
  const { dispatchAdditives } = useContext(AdditiveContext);
  //****** STATE ******
  const [attributes, setAttributes] = useState();
  const [SDS, setSDS] = useState();
  const [TDS, setTDS] = useState();

  //****** USE EFFECTS ******
  useEffect(() => {
    // TODO handle if activity error
    if (logError) console.log('Something went wrong with the Activity Log');
  }, [data, logCompleted, logError]);

  //****** FUNCTIONS ******
  const closeDialog = () => {
    setIsDialog(false);
  };

  const logPDF_upload = async ({ pdf_type, name }) => {
    const response = await executeLog({
      endpoint: 'pdf',
      api_key: authUser.api_key,
      body: {
        pdf_type,
        name,
        user_details: {
          user: user.name,
          user_id: user.id
        }
      }
    });
    return response;
  };

  //update current additive details
  const confirmSave = async e => {
    e.preventDefault();
    let pdf_uploads = [];

    const additiveObj = {
      additive_id: attributes.additive_id,
      additive_name: attributes.additive_name,
      additive_company: 0,
      additive_attr: {
        pdf: { sds: attributes.sds, tds: attributes.tds },
        type: attributes.type,
        brand: attributes.brand,
        dilution: attributes.dilution,
        application: attributes.description,
        selling_points: attributes.selling_points
      },
      brand_id : attributes.brand_id
    };

    await executeAsync({
      endpoint: 'additives',
      api_key: authUser.api_key,
      method: 'patch',
      body: additiveObj
    });

    if (SDS) {
      const sds_path = `global_additives/${slugifyString(
        attributes.type
      )}/${slugifyString(attributes.additive_name).toUpperCase()}_SDS.pdf`;

      try {
        await Storage.put(sds_path, SDS, {
          contentType: 'application/pdf'
        });

        const sds_pdf_log = await logPDF_upload({
          path: sds_path,
          type: 'Additive SDS'
        });

        pdf_uploads = [
          ...pdf_uploads,
          {
            type: 'sds',
            path: sds_path,
            upload_id: sds_pdf_log.insertId
          }
        ];
      } catch (err) {
        console.log(err);
      }
    }

    if (TDS) {
      const tds_path = `global_additives/${slugifyString(
        attributes.type
      )}/${slugifyString(attributes.additive_name).toUpperCase()}_TDS.pdf`;
      try {
        await Storage.put(tds_path, TDS, {
          contentType: 'application/pdf'
        });
        const tds_pdf_log = await logPDF_upload({
          path: tds_path,
          type: 'Additive TDS'
        });
        pdf_uploads = [
          ...pdf_uploads,
          {
            type: 'sds',
            path: tds_path,
            upload_id: tds_pdf_log.insertId
          }
        ];
      } catch (err) {
        console.log(err);
      }
    }

    await executeLog({
      endpoint: 'activity',
      api_key: authUser.api_key,
      body: {
        attributes: {
          type: 'Edit Additive',
          user_id: user.id,
          coolant_id: attributes.coolant_id,
          pdf_uploads
        }
      }
    });
    setTimeout(() => {
      dispatchAdditives({ type: UPDATE_ADDITIVE, payload: additiveObj });
      setIsDialog(false);
    }, 1000);
  };

  //update state from child component AdditiveForm.js
  const updateAdditive = attributes => {
    //console.log(attributes)
    setAttributes(attributes);
  };

  //****** RENDER ******
  return (
    <GlobalDialog
      open={isDialog}
      closeModal={closeDialog}
      title="Edit Additive"
      handleSubmit={confirmSave}
      buttonTitle="Update Additive"
      loading={loading}
      error={error}
      data={data}
      successMessage="Coolant edited successfully!"
      fullWidth
      maxWidth="sm"
    >
      <AdditiveForm
        additive={additive}
        updateAdditive={updateAdditive}
        setSDS={setSDS}
        setTDS={setTDS}
      />
    </GlobalDialog>
  );
};

export default EditAdditive;
