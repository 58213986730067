import { faClipboardListCheck } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/styles';
import React, { useContext } from 'react';
import setLanguageText from '../../helpers/setLanguageText';
import { LayoutContext } from '../../store/LayoutContext';
import { buttonStyles } from '../../styles/buttonStyles';
import Pagination from '../reusable/Pagination';

const ServiceEntriesTable = ({
  getTableProps,
  getTableBodyProps,
  headerGroups,
  prepareRow,
  page,
  canPreviousPage,
  canNextPage,
  gotoPage,
  pageCount,
  nextPage,
  previousPage,
  setPageSize,
  pageIndex,
  pageSize,
  getToggleHideAllColumnsProps,
  openDialog,
  selectedFlatRows,
  allColumns
}) => {
  const theme = useTheme();
  const { language } = useContext(LayoutContext);
  const buttonStyle = buttonStyles();

  return (
    <Grid item container spacing={1}>
      <>
        <Grid item xs={12}>
          <Card
            variant="outlined"
            sx={{
              display: 'grid',
              //border: (theme) => `1px solid ${theme.palette.table.border}`
            }}
          >
            <CardContent
              sx={{
                display: 'grid',
                maxHeight: '70vh',
                padding: '0',
                overflow: 'auto',
                maxWidth: '100%'
              }}>
              <Table
                {...getTableProps()}
                className="machines--table"
                stickyHeader
                aria-label="sticky table"
              >
                {/* TABLE HEADER */}
                <TableHead
                  className="machines--header"
                  style={{ width: '100%' }}
                >
                  {
                    // Loop over the header rows
                    headerGroups.map((headerGroup, i) => (
                      // Apply the header row props
                      <TableRow key={i} {...headerGroup.getHeaderGroupProps()}>
                        {
                          // Loop over the headers in each row
                          headerGroup.headers.map((cell, i) => {
                            return (
                              // Apply the header cell props
                              <TableCell
                                key={i}
                                align="left"
                                {...cell.getHeaderProps(
                                  // add sort functionality
                                  cell.getSortByToggleProps()
                                )}
                                style={{
                                  backgroundColor:
                                    theme.palette.background.light,
                                  padding: theme.spacing(1, 2),
                                  width: cell.collapse ? '0.1%' : '1%'
                                }}
                              >
                                {
                                  // Render the header
                                  cell.render('Header')
                                }
                              </TableCell>
                            );
                          })
                        }
                      </TableRow>
                    ))
                  }
                </TableHead>
                {/* TABLE CONTENT */}
                {/* Apply the table body props */}
                <TableBody {...getTableBodyProps()}>
                  {
                    // Loop over the table rows
                    page.map((row, i) => {
                      // Prepare the row for display
                      prepareRow(row);
                      return (
                        // Apply the row props
                        <TableRow
                          key={i}
                          {...row.getRowProps()}
                          style={{
                            backgroundColor: !row.original.serviced
                              ? 'rgba(252, 70, 112, 0.1)'
                              : '#ffffff'
                          }}
                        >
                          {
                            // Loop over the rows cells
                            row.cells.map((cell, i) => {
                              // Apply the cell props
                              return (
                                <TableCell
                                  key={i}
                                  align="left"
                                  {...cell.getCellProps()}
                                  style={{
                                    width: cell.column.collapse ? '0.1%' : '1%'
                                  }}
                                >
                                  {

                                    // Render the cell contents
                                    cell.render('Cell')
                                  }
                                </TableCell>
                              );
                            })
                          }
                        </TableRow>
                      );
                    })
                  }
                </TableBody>
              </Table>
            </CardContent>
            {/* PAGINATION AND SETTINGS */}
            {/* <Pagination
              allColumns={allColumns}
              getToggleHideAllColumnsProps={getToggleHideAllColumnsProps}
              pageIndex={pageIndex}
              gotoPage={gotoPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              previousPage={previousPage}
              canPreviousPage={canPreviousPage}
              nextPage={nextPage}
              canNextPage={canNextPage}
              pageCount={pageCount}
            />*/}
          </Card>
        </Grid>
        <Grid item xs={12} sm={'auto'}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            disabled={!selectedFlatRows.length}
            onClick={openDialog}
          >
            <FontAwesomeIcon icon={faClipboardListCheck} size="2x" />

            <Typography className={buttonStyle.buttonIconText}>
              {setLanguageText(language, "Run report for")} {selectedFlatRows.length}
            </Typography>
          </Button>
        </Grid>
      </>
    </Grid>
  );
};

export default ServiceEntriesTable;
