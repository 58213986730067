import axiosRequest from './axiosRequest';

// Check user level and fetch companies accordingly
export const fetchMachineCoolant = (api_key, coolant_id) => {
  return axiosRequest({
    api_key,
    method: 'get',
    endpoint: 'coolants',
    params: {
      coolant_id
    }
  });
};
