import {
    Button,
    Grid,
    Typography,
    TextField,
    Chip,
    IconButton,
    ButtonGroup
  } from '@mui/material';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import {
  faCloudUpload,
  faEdit
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BrandsContext } from '../../../store/BrandsContext';
import { buttonStyles } from '../../../styles/buttonStyles';
import getFileName from '../../../helpers/getFileName';
import OutlinedDiv from '../../reusable/OutlinedDiv';
import { Autocomplete } from '@mui/material';
import setLanguageText from '../../../helpers/setLanguageText';
import { LayoutContext } from '../../../store/LayoutContext';

const ProductForm = ({ typeOptions, coolant, updateCoolant, setSDS, setTDS }) => {

  //****** CONTEXT ******
  const { brands } = useContext(BrandsContext);
  const { language : contextLanguage } = useContext(LayoutContext);

  //****** REFS ******
  const sds_input_ref = useRef(null);
  const tds_input_ref = useRef(null);
  //****** STATE ******

  const [language, setLanguage] = useState('EN');
  const [selected, setSelected] = useState(null);
  const [selectedType, setSelectedType] = useState();
  const [legacy, setLegacy] = useState(false);
  const folder = 'global_coolants';

  const [attributes, setAttributes] = useState({
    coolant_id: coolant?.coolant_id || '',
    coolant_name: coolant?.coolant_name || '',
    manufacturer: coolant?.coolant_defaults?.coolant?.manufacturer || '',
    brand_id: coolant?.brand_id || '',
    description: coolant?.coolant_defaults?.coolant?.type || '',
    defaults: coolant?.coolant_defaults?.defaults || {},
    selling_points: coolant?.coolant_defaults?.coolant?.selling_points || [],
    variants: coolant?.coolant_defaults?.variants || [],
    coolant_sds_list: coolant?.coolant_sds_list || [],
    coolant_tds_list: coolant?.coolant_tds_list || [],
    pdf: {
      sds: coolant?.coolant_defaults?.pdf?.sds || null,
      tds: coolant?.coolant_defaults?.pdf?.tds || null
    },
    pdf_s3: {
      folder: folder
    },
    language: language,
    sds_uploaded: false,
    tds_uploaded: false,
    type: coolant?.coolant_type
  });

  //****** STYLES******
  const buttonStyle = buttonStyles();

  //****** FUNCTIONS ******


  const uploadSDS = e => {
    if (e.target.files.length) {
       
      setAttributes(prevState => {
        let array = prevState.coolant_sds_list
        if(!array.includes(prevState.language)){
          array.push(prevState.language);
        }   
        return  ({
          ...prevState,
          sds_uploaded: true ,
          coolant_sds_list: JSON.stringify(array)
        })}
      );
      setSDS(e.target.files[0]);
    }
  };

  const removeSDS = () => {
    // remove value also from the SDS input
    sds_input_ref.current.value = '';
    setSDS();
    setAttributes(prevState => ({
      ...prevState,
      sds_uploaded: false
    }));
  };

  const uploadTDS = e => {
     
    setAttributes(prevState => {
      let array = prevState.coolant_tds_list
      if(!array.includes(prevState.language)){
        array.push(prevState.language);
      }   
      return  ({
        ...prevState,
        tds_uploaded: true ,
        coolant_tds_list: JSON.stringify(array)
      })}
    );
    setTDS(e.target.files[0]);
  };

  const removeTDS = () => {
    // remove value also from the TDS input
    tds_input_ref.current.value = '';
    setTDS();
    setAttributes(prevState => ({
      ...prevState,
      tds_uploaded: false
    }));
  };

  // check if legacy file name e.g no .pdf
  useEffect(() => {
    if (attributes.pdf.sds || attributes.pdf.tds) {
      setLegacy(true);
    } else {
      setLegacy(false);
    }
  }, [attributes]);

    // set select type
    useEffect(() => {
      if(coolant?.coolant_type){
        let type = typeOptions.find(type => parseInt(type.coolant_type_id) === parseInt(coolant.coolant_type) )
        setSelectedType(type)
        console.log(type)
      }
    }, [coolant,typeOptions]);
  

  const addSellingPoint = e => {
    e.persist();
    const last_selling_point =
      attributes.selling_points[attributes.selling_points.length - 1];
    // Add selling point new selling point if no selling points or last one is empty
    if (!attributes.selling_points.length || last_selling_point)
      setAttributes(prevState => ({
        ...prevState,
        selling_points: [...prevState.selling_points, '']
      }));
  };

  const handleManufacturer = (brand) => {
    //console.log(brand)
    setSelected(brand);
    setAttributes(prevState => ({
      ...prevState,
      manufacturer: brand.brand_name,
      brand_id: brand.brand_id
    }));
  };

  const handleType = (type) => {
    //console.log(brand)
    setSelectedType(type);
    setAttributes(prevState => ({
      ...prevState,
      type: type.coolant_type_id,
    }));
  };

  //update selected options from brands
  useEffect(() => {
    if(parseInt(coolant?.brand_id) > 0 && Object.values(brands).length) {
      let found = brands.find(brand => brand.brand_id === coolant.brand_id )
      setSelected(found)
    } 
  }, [
    coolant,
    brands
  ]);

  //update parent component attribute, ready for saving
  useEffect(() => {
    updateCoolant(attributes);
  }, [attributes, updateCoolant]);

  //console.log(attributes)

  //****** RENDER ******
  return (
    <>
          <Grid container spacing={2} >
            <Grid item container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="coolant-name"
                 label={setLanguageText(contextLanguage,"Product Name")}
                  variant="outlined"
                  fullWidth
                  required
                  value={attributes.coolant_name || ''}
                  onChange={e =>
                    setAttributes({
                      ...attributes,
                      coolant_name: e.target.value
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
              <Autocomplete
              disableClearable
              id="selected-company"
              value={selectedType ? selectedType : null}
              options={typeOptions || []}
              loading={typeOptions ? false : true}
              getOptionLabel={option => option.coolant_type}
              onChange={(e, newValue) => {
                handleType(newValue);
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                 label={setLanguageText(contextLanguage,"Product Type")}
                  required
                />
              )}
            />
              </Grid>
            </Grid> 

            <Grid item xs={6}>
            <Autocomplete
              disableClearable
              id="selected-company"
              value={selected}
              options={brands || []}
              loading={brands ? false : true}
              getOptionLabel={option => option.brand_name}
              onChange={(e, newValue) => {
                handleManufacturer(newValue);
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                 label={setLanguageText(contextLanguage,"Selected Brand")}
                  required
                />
              )}
            />
              </Grid>
            <Grid item xs={6}>
              <TextField
                id="description"
               label={setLanguageText(contextLanguage,"Description")}
                variant="outlined"
                multiline
                fullWidth
                //required
                value={attributes.description || ''}
                onChange={e =>
                  setAttributes({
                    ...attributes,
                    description: e.target.value
                  })
                }
              />
            </Grid>

            <Grid item container spacing={2}>
              {attributes.selling_points?.map((selling, i) => (
                <Grid item container spacing={2} key={i}>
                  <Grid item xs={12} sm={10}>
                    <TextField
                      required
                      InputLabelProps={{ shrink: selling ? true : false }}
                      id="additive-selling"
                      label={`${setLanguageText(contextLanguage,"Selling point")} ${i + 1}`}
                      variant="outlined"
                      fullWidth
                      value={selling || ''}
                      onChange={e => {
                        let points = [...attributes.selling_points];
                        points[i] = e.target.value;
                        setAttributes({
                          ...attributes,
                          selling_points: points
                        });
                      }}
                    />
                  </Grid>
                  <Grid item container justifyContent="flex-start" xs={2}>
                  <ButtonGroup fullWidth>
                    <Button
                      fullWidth
                      variant="contained"
                      component="label"
                      color="primary"
                      className={buttonStyle.danger}
                      onClick={e => {
                        let points = [...attributes.selling_points];
                        points.splice(i, 1);
                        setAttributes({
                          ...attributes,
                          selling_points: points
                        });
                      }}
                    >
                      {setLanguageText(contextLanguage,"Remove")}
              </Button>
              </ButtonGroup>
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Grid item xs={12}>
              <Button
                //fullWidth
                variant="contained"
                component="label"
                color="primary"
                onClick={addSellingPoint}
              >
                {setLanguageText(contextLanguage,"Add selling point")}
              </Button>
            </Grid>
            <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
            {setLanguageText(contextLanguage,"Coolant Default Settings")}
            </Typography>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item container xs={12} sm={4}>
                <TextField
                  id="con_max"
                 label={setLanguageText(contextLanguage,"Concentration Max")}
                  variant="outlined"
                  fullWidth
                  //required
                  value={attributes.defaults.con_max || ''}
                  onChange={e => {
                    setAttributes({
                      ...attributes,
                      defaults: {
                        ...attributes.defaults,
                        con_max: e.target.value
                      }
                    });
                  }}
                />
              </Grid>
              <Grid item container xs={12} sm={4}>
                <TextField
                  id="con_min"
                 label={setLanguageText(contextLanguage,"Concentration Min")}
                  variant="outlined"
                  fullWidth
                  //required
                  value={attributes.defaults.con_min || ''}
                  onChange={e => {
                    setAttributes({
                      ...attributes,
                      defaults: {
                        ...attributes.defaults,
                        con_min: e.target.value
                      }
                    });
                  }}
                />
              </Grid>
              <Grid item container xs={12} sm={4}>
                <TextField
                  id="factor"
                 label={setLanguageText(contextLanguage,"Factor")}
                  variant="outlined"
                  fullWidth
                  //required
                  value={attributes.defaults.factor || ''}
                  onChange={e => {
                    setAttributes({
                      ...attributes,
                      defaults: {
                        ...attributes.defaults,
                        factor: e.target.value
                      }
                    });
                  }}
                />
              </Grid>
              <Grid item container xs={12} sm={4}>
                <TextField
                  id="ph_max"
                 label={setLanguageText(contextLanguage,"PH Max")}
                  variant="outlined"
                  fullWidth
                  //required
                  value={attributes.defaults.ph_max || ''}
                  onChange={e => {
                    setAttributes({
                      ...attributes,
                      defaults: {
                        ...attributes.defaults,
                        ph_max: e.target.value
                      }
                    });
                  }}
                />
              </Grid>
              <Grid item container xs={12} sm={4}>
                <TextField
                  id="ph_min"
                 label={setLanguageText(contextLanguage,'PH Min')}
                  variant="outlined"
                  fullWidth
                  //required
                  value={attributes.defaults.ph_min || ''}
                  onChange={e => {
                    setAttributes({
                      ...attributes,
                      defaults: {
                        ...attributes.defaults,
                        ph_min: e.target.value
                      }
                    });
                  }}
                />
              </Grid>
            </Grid>

            <Grid item container spacing={2}>
              {/***** SDS FILE UPLOAD ****/}
              <Grid item xs={12}>
                <OutlinedDiv label={setLanguageText(contextLanguage,"Upload SDS")}>
                  <Grid item xs={12}>
                    <Grid container spacing={2} alignItems="center">
                      {/* UPLOAD BUTTON */}
                      <Grid item>
                        <Button
                          fullWidth
                          variant="contained"
                          component="label"
                          color="primary"
                          className={
                            sds_input_ref?.current?.value ? buttonStyle.warning : ''
                          }
                        >
                          <FontAwesomeIcon
                            icon={
                              !sds_input_ref?.current?.value ? faCloudUpload : faEdit
                            }
                            className={buttonStyle.icon_md}
                          />

                          <Typography className={buttonStyle.buttonIconText}>
                            {!sds_input_ref?.current?.value ? 'SDS PDF' : 'SDS PDF'}
                          </Typography>

                          <input
                            type="file"
                            accept=".pdf"
                            onChange={uploadSDS}
                            ref={sds_input_ref}
                            hidden
                          />
                        </Button>
                      </Grid>
                      {/* UPLOADED FILE BUTTON */}
                      {sds_input_ref?.current?.value && (
                        <Grid item style={{ flex: 1 }}>
                          <Grid container spacing={2} alignItems="center">
                            <Grid item>
                              <IconButton
                                size="small"
                                className={buttonStyle.danger}
                                onClick={removeSDS}
                              >
                                <FontAwesomeIcon icon={faTimesCircle} />
                              </IconButton>
                            </Grid>
                            <Grid item>
                              <Chip label={getFileName(sds_input_ref.current.value)} />
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </OutlinedDiv>
              </Grid>
              {/* TDS FILE UPLOAD */}
              <Grid item container>
                <OutlinedDiv label={setLanguageText(contextLanguage,"Upload TDS")} >
                  <Grid item container xs={12} spacing={2} alignItems="center">
                    {/* UPLOAD BUTTON */}
                    <Grid item>
                      <Button
                        fullWidth
                        variant="contained"
                        component="label"
                        color="primary"
                        className={
                          tds_input_ref?.current?.value ? buttonStyle.warning : ''
                        }
                      >
                        <FontAwesomeIcon
                          icon={!tds_input_ref?.current?.value ? faCloudUpload : faEdit}
                          className={buttonStyle.icon_md}
                        />
                        <Typography className={buttonStyle.buttonIconText}>
                          {!tds_input_ref?.current?.value ? 'TDS PDF' : 'TDS PDF'}
                        </Typography>
                        <input
                          type="file"
                          accept=".pdf"
                          onChange={uploadTDS}
                          ref={tds_input_ref}
                          hidden
                        />
                      </Button>
                    </Grid>

                    {/* UPLOADED FILE */}
                    {tds_input_ref?.current?.value && (
                      <Grid item style={{ flex: 1 }}>
                        <Grid container spacing={2} alignItems="center">
                          <Grid item>
                            <IconButton
                              size="small"
                              className={buttonStyle.danger}
                              onClick={removeTDS}
                            >
                              <FontAwesomeIcon icon={faTimesCircle} />
                            </IconButton>
                          </Grid>
                          <Grid item>
                            <Chip label={getFileName(tds_input_ref.current.value)} />
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </OutlinedDiv>
              </Grid>
            </Grid>
          </Grid>

    </>
  );
};

export default ProductForm;
  