import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useTheme, Grid, CardContent, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import axiosRequest from '../../helpers/axiosRequest';
import useAsync from '../../Hooks/useAsync';
import MyDatePicker from '../Machines/MachineDetails/MyDatePicker';
import DropdownUsers from '../reusable/DropdownUsers';
import ReportCard from './ReportCard';
import { UserContext } from '../../store/UserContext';
import ServiceReportPDF from '../pdf/ServiceReportPDF';
import GlobalDialog from '../reusable/GlobalDialog';
import GlobalPdfDialog from '../reusable/GlobalPdfDialog';
import parseServices from '../../helpers/parseServices';
import ServiceTable_columns from './ServiceTable_columns';
import ServiceTable from './ServiceTable';
import { Autocomplete } from '@mui/material';
import createServicePDFData from '../../helpers/createServicePDFData'
import LoadingScreen from '../reusable/LoadingScreen';
import LoadingButton from '../buttons/LoadingButton';
import { faFilePdf } from '@fortawesome/pro-light-svg-icons';
import Orientation from '../reusable/Orientation';
import ColumnOptions from '../reusable/ColumnOptions';
import { RowingSharp } from '@mui/icons-material';
import removeAllOption from '../../helpers/removeAllOption';
import { LayoutContext } from '../../store/LayoutContext';
import setLanguageText from '../../helpers/setLanguageText';


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  orientation: {
    marginBottom: theme.spacing(1),
    '& .MuiToggleButton-root.Mui-selected': {
      backgroundColor: theme.palette.primary.main
    }
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  }
}));

const ServiceReports = ({ companies }) => {

  const theme = useTheme();
  const classes = useStyles();

  const { authUser, user: userContext } = useContext(UserContext);
  const { language } = useContext(LayoutContext);

  /*---- SET TO AND FROM DATES ----*/
  const [dateFrom, setDateFrom] = useState(new Date().setHours(0, 0, 0, 0)); // from Midnight
  const [dateTo, setDateTo] = useState(new Date().setHours(23, 59, 59, 0)); // 1 sec to Midnight
  const [user, setUser] = useState(null);
  const [openServicePDF, setOpenServicePDF] = useState(false);

  const {
    data,
    loading,
    error,
    executeAsync
  } = useAsync({
    axiosRequest: axiosRequest
  });

  const {
    executeAsync: data_executeAsync
  } = useAsync({
    axiosRequest: axiosRequest
  });

  const {
    executeAsync: company_executeAsync
  } = useAsync({
    axiosRequest: axiosRequest
  });

  const [noData, setNoData] = useState(false);
  const [serviceID, setServiceID] = useState();
  const [isModal, setIsModal] = useState(false);
  const [originalData, setOriginalData] = useState();
  const [pdf_data, setPdf_data] = useState(null);
  const [columnOptions, setColumnOptions] = useState();
  const [downloadObject, setDownloadObject] = useState();
  const [selectedCompany, setSelectedCompany] = useState();
  const [orientation, setOrientation] = useState('portrait');
  const [showPreview, setShowPreview] = useState(false);
  const [reload, setReload] = useState(false);

  // run report
  const runReport = useCallback(() => {
    executeAsync({
      method: 'get',
      api_key: authUser.api_key,
      endpoint: `service-report`,
      params: {
        company_id: selectedCompany.company_id !== 0 ? selectedCompany.company_id : JSON.stringify(userContext.companies_id),
        //user_id: user.id,
        date_from: dateFrom,
        date_to: dateTo,
        level: userContext.level
      }
    });
  }, [
    userContext,
    authUser,
    dateFrom,
    dateTo,
    executeAsync,
    selectedCompany,
    user
  ]);

  useEffect(() => {
    if (data) {
      if (!data.length) return setNoData(true);
      setIsModal(true);
      setShowPreview(true)
    }
  }, [data]);

  useEffect(() => {
    if (noData)
      setTimeout(() => {
        setNoData(false);
      }, 3000);
  }, [noData]);

  const closeModal = () => setIsModal(false);


  //convert data to the correct format for the PDF
  const getServiceEntries = async (original) => {

    setOpenServicePDF(true)
    setServiceID(original.service_id);
    //console.log(original)
    let query
    if (original.service_entry_ids) {
      query = {
        entry_ids: original.service_entry_ids,
      }
    } else {
      //legacy
      query = {
        user_id: original.service_user_id,
        service_date: new Date(
          original.service_date
        ).toISOString().substr(0, 10),
        legacy: original.service_entry_ids ? false : true
      }
    }

    let result = await data_executeAsync({
      method: 'get',
      api_key: authUser.api_key,
      endpoint: `machines/entries`,
      params: query
    });
    let company = await company_executeAsync({
      method: 'get',
      api_key: authUser.api_key,
      endpoint: `companies`,
      params: {
        id: original.service_company_id,
      }
    });
    //console.log(company[0])
    //console.log(result)
    //console.log(original)

    //save the original data, so we can minipulate it later on
    let originalData = {
      'selectedCompany' : company[0],
      'rowData' : result,
      'otherData': original
    }
    setOriginalData(originalData);

    //create first set of PDF data
    const data = createServicePDFData({
      'selectedCompany' : company[0],
      'rowData' : result,
      'otherData': original
    });
    setPdf_data(data)
    //save first columns that are selected (all)
    setColumnOptions(data.columns)
  }

  //************ SERVICE TABLE ************

  const table_data = useMemo(() => parseServices(data), [data]);
  const table_columns = useMemo(
    () =>
      ServiceTable_columns({
        getServiceEntries,
        theme
      }),
    [
      theme,
      getServiceEntries
    ]
  );

  //Show Reload Button
  const reloadPDF = () => {
    setReload(false)
    setTimeout(() => {
      setShowPreview(true)
    }, 100)
  };

   /*------------- OPTIONS ---------------*/

   const handleChange = event => {
    console.log(event.target.name)
    event.persist()
    setShowPreview(false);
    setReload(true);
    //console.log(event.target.name)
    //toggle options
    let newOptions = [...columnOptions] 
    let index = newOptions.findIndex(obj => obj.id === event.target.name);
    newOptions[index].isVisible = !newOptions[index].isVisible;
    setColumnOptions(newOptions)

    //create new visibale columns re create PDF date using them
    let newColumns = newOptions.filter(obj => obj.isVisible === true )
    const updatedData = createServicePDFData({...originalData,'headers':newColumns});
    setPdf_data(updatedData);

  };

  return <>
    <Grid item xs={12}>
      <ReportCard
        title="Service Reports"
        buttonLabel="Fetch Service Reports"
        loading={loading}
        error={error}
        noData={noData}
        runReport={runReport}
        disabled={!selectedCompany ? true : false}
        // datePicker={
        //   <MyDatePicker
        //     dateFrom={dateFrom}
        //     setDateFrom={setDateFrom}
        //     dateTo={dateTo}
        //     setDateTo={setDateTo}
        //     size="small"
        //   />
        // }
      >
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              disableClearable
              id="selected-company"
              value={selectedCompany || ''}
              options={removeAllOption(companies) || []}
              loading={companies ? false : true}
              getOptionLabel={option => option.company_name || ''}
              isOptionEqualToValue={(option, value) =>
                option.company_id === value.company_id
              }
              // getOptionLabel={option => {
              //   if (option.company_id === 0) {
              //     return `All Companies (${companies.length > 0 ? companies.length - 1 : 0})`
              //   } else {
              //     return option.company_name || ''
              //   }
              // }}
              onChange={(e, newValue) => {
                setSelectedCompany(
                  newValue
                );
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={setLanguageText(language,"Select Company")}
                  required
                />
              )}
            />
          </Grid>
           <Grid item xs={12} sm={6}>
           <MyDatePicker
            dateFrom={dateFrom}
            setDateFrom={setDateFrom}
            dateTo={dateTo}
            setDateTo={setDateTo}
            size="small"
          />
            {/* <DropdownUsers
              setSelected={setUser}
              selectedCompany={selectedCompany}
            /> */}
          </Grid> 
        </Grid>
      </ReportCard>
    </Grid>
    {isModal && table_data ? 
      <GlobalDialog
        open={isModal}
        closeModal={closeModal}
        maxWidth="xl"
        fullWidth
        title="Service Reports"
      >
        <ServiceTable data={table_data} columns={table_columns} />
      </GlobalDialog>
    : null }
 {openServicePDF ?
    <GlobalPdfDialog
      open={openServicePDF}
      closeModal={e => {
        setPdf_data()
        setOpenServicePDF(false)
      }}
      maxWidth="xl"
      fullWidth
      title={`${pdf_data?.company?.name} - Service Report`}
      serviceID={serviceID}
      companyName={pdf_data?.company?.name}
      email={true}
      download={downloadObject ? true : false}
      downloadObject={downloadObject}
    >
      <Grid container style={{ height: "100%", overflow: "auto" }} >
        <Grid container item xs={4} md={3} 
        style={{ height: "100%", overflow: "auto", borderRight: '1px solid #ccc', background: '#fbfbfb' }} >
          <CardContent  style={{ width: "100%"}} >
            <Grid container item spacing={2} >
              <Orientation
                orientation={orientation}
                setOrientation={setOrientation}
                setShowPreview={setShowPreview}
                setReload={setReload}
              />
            </Grid>

            <Grid container item spacing={2} >
             {columnOptions && 
             <ColumnOptions 
                  options={columnOptions} 
                  handleChange={handleChange}
                  title="Select Machines Columns"
              />
             }
            </Grid>
          </CardContent>
        </Grid>

        <Grid item xs={8} md={9} style={{ height: "100%" }}>

          {showPreview && pdf_data ?
            <ServiceReportPDF
              theme={theme}
              orientation={orientation}
              pdf_data={pdf_data}
              downloadPDF={true}
              setDownloadObject={setDownloadObject}
            />

            : reload ?
              <Grid item style={{ height: "100%" }} container alignContent="center" justifyContent="center">
                <Grid item>
                  <LoadingButton
                    variant="contained"
                    onClick={e => reloadPDF(e)}
                    //className={buttonStyle.button}
                    icon={faFilePdf}
                    text={'Update Preview'}
                    fullWidth={false}
                    iconSize="1x"
                    color="primary"
                  />
                </Grid>
              </Grid>
              :
              <LoadingScreen
                loading={false}
                background="#666"
                colour="#fff"
                text="Loading PDF Preview"
                position="relative"
              />
          }
        </Grid>
      </Grid>

    </GlobalPdfDialog>
    : null }
  </>;
};

export default ServiceReports;
