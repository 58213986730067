import React, { useState, useContext, useEffect } from 'react';
import { Alert, Autocomplete, Grid, TextField } from '@mui/material';
import GlobalDialog from '../reusable/GlobalDialog';
import ProductTypeSelect from '../reusable/ProductTypeSelect';
import { UserContext } from '../../store/UserContext';
import { ProductsContext } from '../../store/ProductsContext';
import useAsync from '../../Hooks/useAsync';
import axiosRequest from '../../helpers/axiosRequest';
import LoadingScreen from '../reusable/LoadingScreen';
import CoolantCard from '../Products/Coolants/CoolantCard';
import parseCoolants from '../../helpers/parseCoolants';
import setLanguageText from '../../helpers/setLanguageText';
import { LayoutContext } from '../../store/LayoutContext';

const Products = ({ isOpen, closeDialog }) => {

    const { productsOptions } = useContext(ProductsContext);
    const { authUser } = useContext(UserContext);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const { language } = useContext(LayoutContext)

    const {
        data,
        loading,
        executeAsync
    } = useAsync({
        axiosRequest: axiosRequest
    });

    const {
        setData,
        error: product_error,
        data: product_data,
        loading: product_loading,
        executeAsync: product_executeAsync
    } = useAsync({
        axiosRequest: axiosRequest
    });

    useEffect(() => {
        if (productsOptions){
            console.log(productsOptions)
            executeAsync({
                api_key: authUser.api_key,
                endpoint: 'coolants',
                method: 'get',
                params: {
                    list: true,
                    type: productsOptions.type.coolant_type_id
                }
            });
            //reset dropdown
            setData();
            setSelectedProduct(null);
        }

    }, [authUser.api_key, executeAsync, productsOptions]);

    const handleSelectProduct = (e, value) => {
        handleSearch(value.coolant_id);
        setSelectedProduct(value)
    };

    const handleSearch = (id) => {
        product_executeAsync({
            api_key: authUser.api_key,
            endpoint: 'coolants',
            method: 'get',
            params: {
                coolant_id: id
            }
        });
    };

    return (
        <GlobalDialog
            open={isOpen}
            closeModal={closeDialog}
            title="Products Search"
            fullWidth
            maxWidth="sm"
            fullScreen
        >
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <ProductTypeSelect/>
                </Grid>
                {data &&
                    <Grid item xs={12}>
                        <Autocomplete
                            disableClearable
                            id="Products"
                            options={data ? data : []}
                            loading={loading}
                            getOptionLabel={option => option.coolant_name || ''}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                   label={setLanguageText(language,"Select Product")}
                                    variant="outlined"
                                />
                            )}
                            value={selectedProduct}
                            onChange={handleSelectProduct}
                        />
                    </Grid>  
                }
                <Grid item xs={12}>
                {product_loading ? (
                    <LoadingScreen
                        loading={!product_loading}
                        background="#fff"
                        colour="#666"
                        text="Loading Coolant"
                        position="relative"
                        height="200px"

                    />
                ) : product_error ? (
                    <Alert severity="error">{product_error.message}</Alert>
                ) : product_data ? (

                    <CoolantCard
                        noEdit
                        noMachines
                        coolant={parseCoolants(product_data[0])}
                        noBar
                    />

                ) : null}
            </Grid>
            </Grid>
        </GlobalDialog>
    );
};

export default Products;
