import React, { useEffect, useContext, useState } from 'react';
import { UserContext } from '../../../store/UserContext';
import Defaults from '../../Products/Coolants/Defaults';
import { fetchCoolants } from '../../../helpers/fetchCoolants';
import { faTint } from '@fortawesome/pro-light-svg-icons';
import GlobalDialog from '../../reusable/GlobalDialog';
import PrimaryBtnIcon from '../../buttons/PrimaryBtnIcon';
import { buttonStyles } from '../../../styles/buttonStyles';
import parseCoolants from '../../../helpers/parseCoolants';
import axiosRequest from '../../../helpers/axiosRequest';
import useAsync from '../../../Hooks/useAsync';
import ConfirmReset from './ConfirmReset';
import Switch from '@mui/material/Switch';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { TextFields } from '@mui/icons-material';
import setLanguageText from '../../../helpers/setLanguageText';
import { LayoutContext } from '../../../store/LayoutContext';

const RefreshCoolant = ({ selectedCoolant, selectedMachine }) => {
  //****** CONTEXT ******
  const { authUser } = useContext(UserContext);
  const { language } = useContext(LayoutContext)
  //****** STATE ******
  const [coolantList, setCoolantList] = useState();
  const currentCoolant = selectedCoolant;
  const [coolant, setCoolant] = useState(selectedCoolant);
  const [refresh, setRefresh] = useState(false);
  const [resetDate, setResetDate] = useState(false);
  const [resetMachineSettings, setResetMachineSettings] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [date, setDate] = useState(new Date().getTime());

  const {
    error,
    data,
    loading,
  } = useAsync({
    axiosRequest: axiosRequest
  });

  //****** STYLES ******

  const buttonStyle = buttonStyles();

  //****** FUNCTIONS ******

  useEffect(() => {
    if (refresh) {
      // fetch Coolants list - default to coolants only 1
      if (authUser.api_key) {
        fetchCoolants({
          api_key: authUser.api_key,
          type: 1
        }).then(coolants => {
          coolants.sort(function (a, b) {
            if (a.coolant_name < b.coolant_name) { return -1; }
            if (a.coolant_name > b.coolant_name) { return 1; }
            return 0;
        })
          setCoolantList(coolants);
        });
      }
    }
    return () => {
      //clean up
    }
  }, [refresh, authUser]);


  const setNewCoolant = (e, coolant) => {
    // console.log(e.target.value);
    // console.log(coolant);
    setCoolant(
      parseCoolants(coolant)
    );
  };

  const updateResetDate = (e, date) => {
    setResetDate(date);
  };

  const updateResetMachineSettings = (e, date) => {
    setResetMachineSettings(date);
  };

  const handleSubmit = async (e) => {
    e.preventDefault()
    // console.log(currentCoolant.coolant_id !== coolant.coolant_id);
    // console.log(currentCoolant);
    // console.log(resetDate);
    if (resetMachineSettings || resetDate) {
      setConfirmDialog(true);
    } else if (currentCoolant.coolant_id !== coolant.coolant_id) {
      setConfirmDialog(true);
    } else {
      console.log('do nothing');

    }
  }

  const closeReset = () => {
    setConfirmDialog(false);
    setRefresh(false);
    setResetDate(false);
    setResetMachineSettings(false);
  }


  const [selectedDate, handleDateChange] = useState(new Date());

  //****** RENDER ******
  return (
    <>
      <Grid item xs={12} md={6}>
        <PrimaryBtnIcon
          icon={faTint}
          text="Reset/Refresh Coolant"
          onClick={e => setRefresh(true)}
          className={buttonStyle.danger}
        />
      </Grid>

      <GlobalDialog
        open={refresh}
        closeModal={e => setRefresh(false)}
        title="Reset/Refresh Coolant"
        handleSubmit={handleSubmit}
        buttonTitle="Update Details"
        successMessage="Details updated successfully!"
        loading={loading}
        error={error}
        data={data}
        fullWidth
        maxWidth="md"

      >
        <Grid container item spacing={2}>
          {refresh &&
            <>
              <Grid item xs={12}>
                <Autocomplete
                  disableClearable
                  id="coolant"
                  options={coolantList || []}
                  fullWidth
                  getOptionLabel={(option) => option.coolant_name || ''}
                  isOptionEqualToValue={(option, value) => option.coolant_id === value}
                  renderOption={(props, option) => 
                      <li {...props} key={option.coolant_id}>
                        {option.coolant_name}
                      </li>
                  }
                  renderInput={params => (
                    <TextField
                      required
                      {...params}
                     label={setLanguageText(language,"Select Coolant")}
                      variant="outlined"
                    />
                  )}
                  value={coolant || null}
                  onChange={setNewCoolant}
                />
              </Grid>
              <Grid item xs={12}>
                {coolant && <Defaults coolant={coolant} />}
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={resetMachineSettings}
                      onChange={updateResetMachineSettings}
                      name="set_machine"
                      color="primary"
                    />
                  }
                 label={setLanguageText(language,"Set Machine Settings to Coolant Defaults")}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={resetDate}
                      onChange={updateResetDate}
                      name="reset_date"
                      color="primary"
                    />
                  }
                 label={setLanguageText(language,"Reset Date of Coolant Change")}
                />
              </Grid>
              {resetDate &&
              <Grid item xs={12} >
                <LocalizationProvider dateAdapter={AdapterDateFns}>

                <DatePicker
                    fullWidth
                    inputVariant="outlined"
                    margin="normal"
                    id="date-picker-dialog"
                   label={setLanguageText(language,"Reset Date")}
                    inputFormat="dd/MM/yyyy"
                    value={selectedDate}
                    onChange={handleDateChange}
                    disableFuture
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Grid>
              }
            </>
          }
        </Grid>
      </GlobalDialog>
      {confirmDialog &&
        <ConfirmReset
          open={confirmDialog}
          handleClose={e => setConfirmDialog(false)}
          handleConfirmClose={closeReset}
          resetDate={resetDate}
          selectedDate={selectedDate}
          resetMachineSettings={resetMachineSettings}
          currentCoolant={currentCoolant}
          coolant={coolant}
          selectedMachine={selectedMachine}
        />
      }
    </>
  );
};

export default RefreshCoolant;
