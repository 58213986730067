import { useTheme } from '@mui/styles';
import { isEmpty } from 'lodash-es';
import React, {
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import axiosRequest from '../../helpers/axiosRequest';
import useAsync from '../../Hooks/useAsync';
import { CompanyContext } from '../../store/CompanyContext';
import { UserContext } from '../../store/UserContext';
import SelectionRow from '../Dashboard/Selection_Row/SelectionRow';
import PageContent from '../PageContent';
import LoadingScreen from '../reusable/LoadingScreen';
import ServiceHeader from './ServiceHeader';
import ServicePdf from './ServicePdf';
import ServiceTable from './ServiceTable';
import service_table_columns from './service_table_columns';
import createServicePDFData from '../../helpers/createServicePDFData'
import { LayoutContext } from '../../store/LayoutContext';

const Service = () => {
  //****************** STYLES ******************
  const theme = useTheme();
  //****************** CONTEXT ******************
  const { authUser, user } = useContext(UserContext);
  const { language } = useContext(LayoutContext);
  const { selectedCompany : selectedCompaniesContext } = useContext(CompanyContext);
  //****************** CUSTOM HOOKS ******************
  const { error, data, loading, executeAsync, setData } = useAsync({
    axiosRequest: axiosRequest
  });

  //****************** STATE ******************

  const [dateFrom, setDateFrom] = useState(new Date().setUTCHours(0, 0, 0, 0));
  const [dateTo, setDateTo] = useState(new Date().getTime());
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState({});
  const [pdf_data, setPdf_data] = useState(null);
  const [serviceID, setServiceID] = useState();
  const [originalData, setOriginalData] = useState();
  const [columnOptions, setColumnOptions] = useState();

  //****************** USE MEMO ******************


  // const user_company = useMemo(() => {
  //   if (companiesContext.length) {
  //     const get_user_company = companiesContext.filter(
  //       company => company.company_id === +user.company_id
  //     )[0];
  //     setSelectedCompany(get_user_company);
  //     return get_user_company;
  //   }
  // }, [companiesContext, user.company_id]);

  //****************** TABLE ******************
  
  const table_data = useMemo(() => data || [], [data]);
  const table_columns = useMemo(
    () => service_table_columns({selectedCompany:selectedCompany,language:language}),
    [selectedCompany, language]
  );

  //console.log(table_columns)

  //set loaal state for dropdown
  useEffect(() => {
    if (isEmpty(selectedCompany)) setSelectedCompany(selectedCompaniesContext);
  }, [selectedCompany, selectedCompaniesContext]);

  //****************** FUNCTIONS ******************
  const handleSearch = () => {
    executeAsync({
      endpoint: 'service-report/entries',
      api_key: authUser.api_key,
      method: 'get',
      params: {
        user_id: user.id,
        date_from: dateFrom,
        date_to: dateTo,
        company_id: selectedCompany.company_id
      }
    });
  };
  const handleDate = date => {
    setDateFrom(new Date(date).setUTCHours(0, 0, 0, 0));
    setDateTo(new Date(date).setUTCHours(23, 59, 59, 59));
  };

  const openServicePdf = (pdf_data,columns,rows) => {

    //save the original data, so we can minipulate it later on
    let originalData = {
      'selectedCompany' : selectedCompany,
      'rowData' : rows,
      'otherData': pdf_data,
      'fromTable' : true
    }
    setOriginalData(originalData);

    //create first set of PDF data
    const data = createServicePDFData({
      'selectedCompany' : selectedCompany,
      'rowData' : rows,
      'otherData': pdf_data,
      'fromTable' : true
    })
    setPdf_data(data);

    //save first columns that are selected (all)
    setColumnOptions(data.columns);
    console.log(data.columns)


  };

  const closeServicePdf = () => {
    setPdf_data();
    setOriginalData();
    setColumnOptions();
  };


  return (
    <>
      <SelectionRow />
      <ServiceHeader
        handleSearch={handleSearch}
        dateFrom={dateFrom}
        dateTo={dateTo}
        handleDate={handleDate}
        setSelectedCompany={setSelectedCompany}
        selectedCompany={selectedCompany}
      />

      <PageContent>
        {loading ? (
          <LoadingScreen
            loading={loading}
            background={theme.palette.background.lighter}
            colour={theme.palette.text.secondary}
            text="Fetching Entries"
          />
        ) : !data ? (
          <LoadingScreen
            loading={loading}
            background={theme.palette.background.lighter}
            colour={theme.palette.text.secondary}
            text="Select a date and click the search button"
          />
        ) : data.length ? (
          <ServiceTable
            data={table_data}
            loading={loading}
            error={error}
            setData={setData}
            columns={table_columns}
            setDateFrom={setDateFrom}
            setDateTo={setDateTo}
            dateFrom={dateFrom}
            dateTo={dateTo}
            executeAsync={executeAsync}
            dialogOpen={dialogOpen}
            setDialogOpen={setDialogOpen}
            handleSearch={handleSearch}
            openServicePdf={openServicePdf}
            selectedCompany={selectedCompany}
            setServiceID={setServiceID}
          />
        ) : (
          <LoadingScreen
            loading={loading}
            background={theme.palette.background.lighter}
            colour={theme.palette.text.secondary}
            text="No entries found!"
          />
        )}
      </PageContent>
      {/* SERVICE PDF */}

      {pdf_data && (
        <ServicePdf
          servicePdfOpen={!!pdf_data}
          closeServicePdf={closeServicePdf}
          //create_pdf_data={create_pdf_data}
          pdf_data={pdf_data}
          originalData={originalData}
          columnOptions={columnOptions}
          serviceID={serviceID}
        />
      )}
    </>
  );
};

export default Service;
