import { useTheme } from '@mui/styles';
import { useState, useRef, useMemo, useContext, useCallback } from 'react';
import formatDate from '../../helpers/formatDate';
import { ChartsContext } from '../../store/ChartsContext';
import { SAVE_CHARTS } from '../../store/ChartsReducers';

const usePhChart = ({ plots, tooltips }) => {
  const [labels, setLabels] = useState();
  const [ph, setPh] = useState();
  const [phMax, setPhMax] = useState();
  const [phMin, setPhMin] = useState();
  const colour = useTheme().palette.background.darker;
  const { dispatchCharts } = useContext(ChartsContext);
  const chartRef = useRef(null);
  const [base64Image, setBase64Image] = useState(null);

  const createPhChart = useCallback(entries => {
    let labelsObject = [];
    let phObject = [];
    let phMaxObject = [];
    let phMinObject = [];
    if (entries) {
      entries.forEach(entry => {
        labelsObject.push(formatDate(entry.original.date_stamp).slice(0, -5));

        const details = entry.original.attributes_new.find(
          attr => attr.attribute_id === 'ph'
        );
        if (details) {
          phObject.push(details.attribute_values.value > 0 ? details.attribute_values.value : 'N/A');
          phMaxObject.push(details.attribute_values.max);
          phMinObject.push(details.attribute_values.min);
        }
      });
      setLabels(labelsObject);
      setPh(phObject);
      setPhMax(phMaxObject);
      setPhMin(phMinObject);
    }
  }, []);

  const data = useMemo(() => {
    return {
      labels: labels,
      datasets: [
        {
          spanGaps: true,
          data: ph,
          label: 'PH',
          fill: false,
          pointRadius: plots ? 4 : 0,
          lineTension: 0.3,
          pointBackgroundColor: colour,
          pointHoverRadius: 6,
          borderColor: colour,
          borderWidth: 2,
          datalabels: tooltips
            ? {
                align: 'center',
                anchor: 'center'
              }
            : null
        },
        {
          spanGaps: true,
          data: phMax,
          label: 'MAX',
          backgroundColor: 'rgba(252, 70, 112, 0.100)',
          borderColor: 'rgb(255, 197,0, 0.100)',
          fill: 'end',
          lineTension: 0
        },
        {
          spanGaps: true,
          data: phMin,
          label: 'IN_RANGE',
          backgroundColor: 'rgba(0, 215, 154, 0.1)',
          borderColor: 'rgb(255, 197,0, 0.100)',
          fill: '-1',
          lineTension: 0
        },
        {
          spanGaps: true,
          data: phMin,
          label: 'MIN',
          backgroundColor: 'rgba(252, 70, 112, 0.100)',
          borderColor: 'transparent',
          fill: 'origin',
          lineTension: 0
        }
      ]
    };
  }, [labels, ph, plots, colour, tooltips, phMax, phMin]);

  const options = useMemo(() => {
    return {
      legend: {
        display: false
      },
      layout: {
        padding: 20
      },
      plugins: {
        datalabels: {
          backgroundColor: colour,
          borderRadius: 4,
          color: 'white',
          font: {
            weight: 'bold'
          },
          display: context => (context.dataset.datalabels ? true : false),
          padding: 4
        }
      },
      elements: {
        point: {
          radius: 0,
          hoverRadius: 0
        }
      },
      title: {
        display: false,
        text: 'PH levels',
        fontSize: 20
      },
      scales: {
        yAxes: [
          {
            display: true,
            ticks: {
              stepSize: 1,
              suggestedMax: phMax && phMax[0] + 0.6,
              suggestedMin: phMin && phMin[0] - 0.6
            }
          }
        ]
      },
      animation: {
        onComplete: () => {
          let b64 = chartRef.current.chartInstance.toBase64Image();
          setBase64Image(b64);
          dispatchCharts({
            type: SAVE_CHARTS,
            payload: {
              ph_base64: b64
            }
          });
        }
      }
    };
  }, [colour, dispatchCharts, phMax, phMin]);

  return {
    createPhChart,
    phData: data,
    phOptions: options,
    phBase64: base64Image,
    phChartRef: chartRef
  };
};

export default usePhChart;
