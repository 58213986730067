import React, { useContext, useEffect } from 'react';
import {
    TextField
} from '@mui/material';
import { UserContext } from '../../store/UserContext';
import { Autocomplete } from '@mui/material';
import axiosRequest from '../../helpers/axiosRequest';
import { BrandsContext } from '../../store/BrandsContext';
import { fetchBrands } from '../../helpers/fetchBrands';
import { SAVE_BRANDS } from '../../store/BrandsReducers';
import { UPDATE_PRODUCT_OPTIONS, SAVE_PRODUCT_TYPES } from '../../store/ProductsReducers';
import useAsync from '../../Hooks/useAsync';
import { ProductsContext } from '../../store/ProductsContext';
import setLanguageText from '../../helpers/setLanguageText';
import { LayoutContext } from '../../store/LayoutContext';

const Products = () => {

    const { language } = useContext(LayoutContext); 
    const { user, authUser } = useContext(UserContext);
    const { productsOptions, dispatchProductsOptions, productTypes, dispatchProductTypes  } = useContext(ProductsContext);
    const { brands, dispatchBrands } = useContext(BrandsContext);

    const {
        data,
        loading,
        executeAsync
    } = useAsync({
        axiosRequest: axiosRequest
    });

    useEffect(() => {
        // only fetch brands if NOT fetched before
        if (!brands.length) {
            fetchBrands(authUser.api_key).then(brands => {
                dispatchBrands({
                    type: SAVE_BRANDS,
                    payload: brands
                });
            });
        }
    }, [authUser.api_key, brands, dispatchBrands]);

    // --------  get all product types  ---------

    useEffect(() => {
        if (authUser && user) {
            //console.log('fetch users')
            let getProducts = async () => {
                //console.log('fetch users')
                let productTypes = await executeAsync({
                    method: 'get',
                    api_key: authUser.api_key,
                    endpoint: `coolants`,
                    params: {
                        coolant_types: true,
                        user_level: user.level
                    }
                });
                dispatchProductTypes({
                    type: SAVE_PRODUCT_TYPES,
                    payload: productTypes
                });
                //default to the first
                dispatchProductsOptions({
                    type: UPDATE_PRODUCT_OPTIONS,
                    payload: {type :productTypes[0]}
                })
            };
            getProducts();
        }
    }, [authUser, user])


    //set type from dropdown
    const setType = (e) => {
        //console.log(e)
        dispatchProductsOptions({
            type: UPDATE_PRODUCT_OPTIONS,
            payload: {type : e}
        })
    }

    return (

        <Autocomplete
            disableClearable
            id="tags-outlined"
            options={productTypes}
            getOptionLabel={option => option.coolant_type || ''}
            loading={!data || loading}
            renderInput={params => (
                <TextField
                    {...params}
                    variant="outlined"
                   label={setLanguageText(language,"Select product type")}
                />
            )}
            onChange={(e, newValue) => setType(newValue)}
            value={productsOptions.type ? productsOptions.type : []}
        />

    );
};

export default Products;
