import {Grid, TextField } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import GlobalDialog from '../reusable/GlobalDialog';
import { fetchAdditives } from '../../helpers/fetchAdditives';
import { AdditiveContext } from '../../store/AdditiveContext';
import { Autocomplete } from '@mui/material';
import { UserContext } from '../../store/UserContext';
import { SAVE_ADDITIVES } from '../../store/AdditiveReducers';
import parseAdditives from '../../helpers/parseAdditives';
import CardAdditives from '../Products/Additives/CardAdditive';
import setLanguageText from '../../helpers/setLanguageText';
import { LayoutContext } from '../../store/LayoutContext';

const Additives = ({ isOpen, closeDialog }) => {

  const {
    additives,
    dispatchAdditives,
  } = useContext(AdditiveContext);
  const { language } = useContext(LayoutContext)
  const {
    authUser
  } = useContext(UserContext);

  const [selectedAdditive, setSelectedAddititve] = useState();

  // Fetch All Additives
  useEffect(() => {
    // only fetch Additives if NOT fetched before
      fetchAdditives({
        "api_key": authUser.api_key
      }).then(
        data => {
          dispatchAdditives({
            type: SAVE_ADDITIVES,
            payload: parseAdditives(data)
          });
        }
      );
    
  }, [
    authUser,
    dispatchAdditives
  ]);

  const handleSelectCoolant = (e, value) => {
    setSelectedAddititve(value)
  }

  return (
    <GlobalDialog
      open={isOpen}
      closeModal={closeDialog}
      title="Additive Search"
      fullWidth
      maxWidth="sm"
      fullScreen
      //backgroundColor="#f0f0f0"
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Autocomplete
            disableClearable
            id="additives"
            options={additives ? additives : []}
            loading={additives ? false : true}
            getOptionLabel={option => option.additive_name || ''}
            renderInput={params => (
              <TextField
                {...params}
               label={setLanguageText(language,"Select Additive")}
                variant="outlined"
              />
            )}
            value={selectedAdditive}
            onChange={handleSelectCoolant}
          />
        </Grid>
        <Grid item xs={12}>
          {selectedAdditive &&
            <CardAdditives additive={selectedAdditive} />
          }
        </Grid>
      </Grid>
    </GlobalDialog>
  );
};

export default Additives;
