import React, { useState, useContext } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Paper,
  Typography,
  Card,
  CardActions,
  CardContent
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { buttonStyles } from '../../../styles/buttonStyles';
import EditSettings from './EditSettings';
import FactorMachine from './FactorMachine';
import { makeStyles } from '@mui/styles';
import calcOverdueCoolant from '../../../helpers/calcOverdueCoolant';
import DeleteMachine from './DeleteMachine';
import { isMobileOnly } from 'react-device-detect';
import { UserContext } from '../../../store/UserContext';
import setLanguageText from '../../../helpers/setLanguageText';
import { LayoutContext } from '../../../store/LayoutContext';

const useStyles = makeStyles(theme => ({
  root: {
    // marginRight: theme.spacing(1),
    // [theme.breakpoints.down('md')]: {
    //   marginBottom: theme.spacing(1),
    //   marginRight: 0
    // }
  },
  card: { height: '100%', width: '100%', boxShadow: 'none' },
  accordion: {
    borderTop: '1px solid #ececec',
    padding: 0
  },
  cardContainer: { height: '100%' },
  cardContent: {
    flex: 1
  },
  cardFooter: {
    padding: theme.spacing(1),
    borderTop: `1px solid ${theme.palette.background.light}`,
    background: `${theme.palette.background.light}`
  },
  leftButton: {
    paddingLeft: '4px'
  },
  rightButton: {
    paddingRight: '4px'
  }
}));

const MachineDefaults = ({ selectedMachine }) => {

  const { user } = useContext(UserContext);
  const { language } = useContext(LayoutContext);
  const [expanded, setExpanded] = useState(false);

  //****** STYLES ******
  const classes = useStyles();
  const buttonStyle = buttonStyles();

  //****** FUNCTIONS ******
  const handleExpand = () => {
    setExpanded(state => setExpanded(!state));
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={handleExpand}
      className={classes.root}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="machine-details"
        id="machine-details"
      >
        <Typography variant="h6">
          {setLanguageText(language, "Machine")}{!isMobileOnly && ' Details'}:{' '}
          <span style={{ color: '#888' }}>{selectedMachine?.machine_name}</span>
        </Typography>
      </AccordionSummary>

      <AccordionDetails className={classes.accordion}>
        {/* Machines Defaults values */}
        <Card className={classes.card}>
          <CardContent justify="center" className={classes.cardContent}>
            <Grid item container spacing={1}>

              <Grid item xs={4}>
                <Paper className={`${buttonStyle.box}`} variant="outlined">
                  <Typography variant="body1" align="center">
                    <strong>{setLanguageText(language, "Factor")}: </strong>
                    {selectedMachine?.machine_settings?.machine_defaults.factor}
                    {/* {`Factor: ${defaults.factor}`} */}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={4} >
                <Paper className={`${buttonStyle.box}`} variant="outlined">
                  <Typography variant="body1" align="center">
                    <strong>{setLanguageText(language, "PH Max")}: </strong>
                    {selectedMachine?.machine_settings?.machine_defaults.ph_max}
                    {/* {`PH Max: ${defaults.ph_max}`} */}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper className={`${buttonStyle.box}`} variant="outlined">
                  <Typography variant="body1" align="center">
                    <strong>{setLanguageText(language, "PH Min")}: </strong>
                    {selectedMachine?.machine_settings?.machine_defaults.ph_min}
                    {/* {`Conc Min: ${defaults.con_max}`} */}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper className={`${buttonStyle.box}`} variant="outlined">
                  <Typography variant="body1" align="center">
                    <strong>{setLanguageText(language, "Conc Min")}: </strong>
                    {selectedMachine?.machine_settings?.machine_defaults.op_con_min}
                    {/* {`PH Min: ${defaults.ph_min}`} */}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper className={`${buttonStyle.box}`} variant="outlined">
                  <Typography variant="body1" align="center">
                    <strong>{setLanguageText(language, "Conc Max")}: </strong>
                    {selectedMachine?.machine_settings?.machine_defaults.op_con_max}
                    {/* {`Conc Min: ${defaults.con_min}`} */}
                  </Typography>
                </Paper>
              </Grid>
           
            {/* {selectedMachine?.machine_settings?.machine_defaults &&
                Object.entries(
                  selectedMachine.machine_settings.machine_defaults
                ).map(entry => {
                  let factor;
                  if (
                    selectedMachine.machine_settings.machine_defaults.factor
                  ) {
                    //legacy
                    factor =
                      selectedMachine.machine_settings.machine_defaults.factor;
                  } else if (
                    selectedMachine.machine_settings.machine_defaults
                      .refractomer_factor
                  ) {
                    //new entires
                    factor =
                      selectedMachine.machine_settings.machine_defaults
                        .refractomer_factor;
                  }

                  const separatedString = entry[0].replaceAll('_', ' ');
                  //lagacy value
                  if (entry[0] !== 'last_coolant_change') {
                    const capitalizedString = separatedString.replace(
                      /\w\S/g,
              word =>
                `${word[0].toUpperCase()}${word
                  .substring(1)
                  .toLowerCase()}`
                    );

                    return (
                      <Grid item xs={6} key={entry}>
              <Paper
                className={`${buttonStyle.box}`}
                variant="outlined"
              >
                <Typography variant="body1" align="center">
                  <strong>{capitalizedString}: </strong>

                  {selectedMachine.machine_settings.factored &&
                    (capitalizedString === 'Op Con Max' ||
                      capitalizedString === 'Op Con Min')
                    ? `${(entry[1] / factor).toFixed(2)} (${entry[1]
                    })`
                    : entry[1]}
                </Typography>
              </Paper>
            </Grid>
                    );
                  } else {
                    return false;
                  }
                })} */}

              <Grid item xs={12}>
              {
                selectedMachine?.machine_settings?.machine_defaults?.change_freq &&
                calcOverdueCoolant(selectedMachine.last_coolant_change, selectedMachine.machine_settings.machine_defaults.change_freq, language)
              }
            </Grid>
            {/* <Grid item xs={12}>
                <Paper className={`${buttonStyle.box}`} variant="outlined">
                  <Typography variant="body1" align="center">
                    <strong>Last Coolant Change: </strong>
                    {formatDate(selectedMachine.last_coolant_change)}
                  </Typography>
                </Paper>
              </Grid> */}
            <FactorMachine />
            </Grid>
          </CardContent>
        <CardActions className={classes.cardFooter}>
          {user.level >= 7 &&
            <Grid item container justifyContent="space-around">
              <Grid className={classes.rightButton} item xs={6}>
                <EditSettings selectedMachine={selectedMachine} />
              </Grid>

              {/* <Grid className={classes.rightButton} item xs={4}>
                <EditMachine selectedMachine={selectedMachine} />
              </Grid> */}
              <Grid className={classes.leftButton} item xs={6}>
                <DeleteMachine selectedMachine={selectedMachine} />
              </Grid>
            </Grid>
          }
        </CardActions>
        </Card>
      </AccordionDetails>
    </Accordion >
  );
};

export default MachineDefaults;
