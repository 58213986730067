import React, { useContext, useEffect, useState } from 'react';
import { ExpandMore } from '@mui/icons-material';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Card,
    CardActions,
    CardContent,
    Grid,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import axiosRequest from '../../../helpers/axiosRequest';
import useAsync from '../../../Hooks/useAsync';
import { UserContext } from '../../../store/UserContext';
import {  faVials } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { buttonStyles } from '../../../styles/buttonStyles';
import MachineProductCard from './MachineProductCard';
import MachineProductAdd from './MachineProductAdd';
import { MachineContext } from '../../../store/MachineContext';
import { UPDATE_MACHINE_SETTINGS } from '../../../store/MachineReducers';
import setLanguageText from '../../../helpers/setLanguageText';
import { LayoutContext } from '../../../store/LayoutContext';

const useStyles = makeStyles(theme => ({
    card: { height: '100%' },
    cardContainer: { height: '100%' },
    cardContent: {
        flex: 1,
        width: '100%'
    },
    cardFooter: {
        padding: theme.spacing(1),
        borderTop: `1px solid ${theme.palette.background.light}`,
        background: `${theme.palette.background.light}`
    },
    dialog: {
        height: 'calc(100% - 64px)'
    }
}));

const MachineProducts = () => {

    const { authUser , user } = useContext(UserContext);
    const { selectedMachine, dispatchSelectedMachine } = useContext(MachineContext);
    const { language } = useContext(LayoutContext);
    const classes = useStyles();
    const buttonStyle = buttonStyles();

    //****** STATE ******

    const [expanded, setExpanded] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const handleExpand = () => {
        setExpanded(state => setExpanded(!state));
    };

    //get machine products
    const {
        data,
        setData,
        executeAsync
    } = useAsync({
        axiosRequest: axiosRequest
    });

    useEffect(() => {
        if (selectedMachine?.machine_settings?.products) {
            const getMachineProducts = async () => {
                //console.log('fetch users')
                await executeAsync({
                    method: 'get',
                    api_key: authUser.api_key,
                    endpoint: `coolants`,
                    params: {
                        coolant_ids: JSON.stringify(selectedMachine.machine_settings.products)
                    }
                });
            };
            getMachineProducts();
        }
    }, [selectedMachine])


    //remove product
    const removeProduct =  (row) => {
        //console.log(row)
        setData(current => current.filter((item) => item.coolant_id !== row.coolant_id)); 
        //update context
        dispatchSelectedMachine({
            type: UPDATE_MACHINE_SETTINGS,
            payload: {
                id: row.coolant_id
            }
        });
    }

    //add product to machine
    const closeAddProduct = (product) => {
        //console.log(product)
        setIsOpen(false);
    }

    const openAddProduct = () => {
        setIsOpen(true);
    }

    return (
        <>
            <Accordion expanded={expanded} onChange={handleExpand}>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="machine-details"
                    id="machine-details"
                >
                    {' '}
                    <Typography variant="h6">
                    {setLanguageText(language,"Machine Products")}
                     </Typography>
                </AccordionSummary>
                <AccordionDetails
                    style={{
                        borderTop: '1px solid #ececec',
                        padding: 0
                    }}
                >
                    <Card className={classes.card}>
                        <CardContent justify="center">
                            <Grid item container spacing={1}>
                                {data && data.map((product, i) =>
                                    // <MachineProductList
                                    //     key={i}
                                    //     product={product}
                                    //     productDefaults={JSON.parse(product.coolant_defaults)}
                                    //     removeProduct={removeProduct}
                                    // />
                                    <Grid item xs={12} md={6} lg={4} key={i}>
                                    <MachineProductCard
                                        key={i}
                                        product={product}
                                        productDefaults={JSON.parse(product.coolant_defaults)}
                                        removeProduct={removeProduct}
                                    />
                                    </Grid>
                                )}
                            </Grid>
                        </CardContent>
                        <CardActions className={classes.cardFooter}>
                        {user.level >= 9 &&
                            <Grid item container justifyContent="flex-end">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => openAddProduct(true)}
                                >
                                    <FontAwesomeIcon icon={faVials} />
                                    <Typography
                                        className={buttonStyle.buttonIconText}
                                    >
                                        {setLanguageText(language, "Add Products")}
                                </Typography>
                                </Button>
                            </Grid>
                        }
                        </CardActions>
                    </Card>
                </AccordionDetails>
            </Accordion>

            {isOpen &&
                <MachineProductAdd
                    closeAddProduct={closeAddProduct}
                    isOpen={isOpen}
                    currentList={data}
                />

            }
        </>
    );
};

export default MachineProducts;
