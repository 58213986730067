import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, darken, Divider, Grid, ListItem, ListItemIcon, ListItemText, Tooltip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/styles';
//import { capitalize } from 'lodash';
import React, { useMemo, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Link, useLocation } from 'react-router-dom';
import { borderRadius } from '@mui/system';

const useStyles = color => makeStyles(theme => ({
  
    listItem : { 
      //textAlign : 'center',
      '& .MuiListItemIcon-root' : {
        // minWidth: '100%',
        // margin: '0 auto',
        // display: 'initial'
      }
    },
    activeTab: {
      backgroundColor: color === 'primary' ? darken(theme.palette.primary.main, 0.2) : theme.palette.secondary.main,
    },
    text: {
      color: theme.palette.primary.contrastText,
      textDecoration: 'none'
    },
    icon : {
      padding: '5px',
      backgroundColor : color === 'primary' ?  'transparent' : theme.palette.secondary.main,
      borderRadius : '5px',
      width: '40px',
      textAlign : 'center',
      position: 'relative'
    },
    iconSelected : {
      backgroundColor : 'transparent'
    }
  }
));

const DrawItem = ({ title, color, url,  icon, isOpen }) => {
  const classes = useStyles(color)();
  const theme = useTheme();
  const pathname = useLocation().pathname;

  return (

    <>
    <Link to={`/${url}`} className={classes.text} >
    
      <ListItem
        button
        key={title}
        className={`${!isOpen && classes.listItem} ${pathname === `/${url}` ? classes.activeTab : null}`}>
        <ListItemIcon >
          <Tooltip title={title} arrow placement="right-end" >
          <Box className={`${classes.icon} ${pathname === `/${url}` && classes.iconSelected}`} >  
          <FontAwesomeIcon  color={theme.palette.primary.contrastText} icon={icon} size={'lg'} /> 
          </Box>
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary={title} className={classes.span} />
      </ListItem>
     
    </Link>
    <Divider/>
    </>

  );
};

export default DrawItem;
