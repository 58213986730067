import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Slide,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Close } from '@mui/icons-material';
import Alert from '@mui/material/Alert';
import React, { forwardRef, useContext } from 'react';
import { isMobileOnly } from 'react-device-detect';
import LoadingButton from './../buttons/LoadingButton';
import { ThemeProvider, useTheme } from '@mui/system';
import setLanguageText from '../../helpers/setLanguageText';
import { LayoutContext } from '../../store/LayoutContext';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiDialogContent-root': {
      padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
      [theme.breakpoints.down('md')]: {
        padding: `${theme.spacing(2)} ${theme.spacing(1)}`
      },
    }
  },
  warnings: {
    padding: theme.spacing(2)
  },
  title: {
    backgroundColor: theme.palette.background.light,
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`
  },
  actions: {
    backgroundColor: theme.palette.background.light
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    '& .MuiDialogContent-root': {
      paddingBottom: '20px'
    }
  }
}));

const GlobalDialog = forwardRef(
  (
    {
      children,
      open,
      closeModal,
      title,
      handleSubmit,
      buttonTitle,
      buttonProps,
      successMessage,
      customError,
      errorMessage,
      loading,
      error,
      data,
      customActions,
      backgroundColor,
      submit_disabled,
      ...props
    },
    ref
  ) => {
    const classes = useStyles();
    const theme = useTheme();
    const { language } = useContext(LayoutContext);

    return (
      <ThemeProvider theme={theme}>
        <Dialog
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={closeModal}
          TransitionComponent={Transition}
          fullScreen={isMobileOnly}
          className={classes.root}
          {...props}
        >
          <DialogTitle className={classes.title}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>{setLanguageText(language,title)}</Grid>
              <Grid item>
                <IconButton onClick={closeModal} size="large">
                  <Close />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <Divider />
          <form onSubmit={handleSubmit} className={classes.form} ref={ref}>
            <DialogContent
              style={
                backgroundColor && {
                  backgroundColor: backgroundColor
                }
              }
            >
              {children}
            </DialogContent>
            <Divider />

            {data && (
              <>
                <Grid item className={classes.warnings}  >
                  {!customError ?
                    <Alert severity="success" variant="standard">
                      {setLanguageText(language,successMessage)} 
                    </Alert>
                    :
                    <Alert severity="error" variant="standard">
                      {errorMessage ? setLanguageText(language,errorMessage) : setLanguageText(language,'Error')}
                    </Alert>
                  }
                </Grid>
                <Divider />
              </>
            )
            }
            {error && (
              <>
                <Grid item className={classes.warnings}  >
                  <Alert severity="error" variant="standard">
                  {setLanguageText(language,error.message)}
                  </Alert>
                </Grid>
                <Divider />
              </>
            )}

            {customActions || (
              <DialogActions className={classes.actions}>

                <Grid item container spacing={2} xs={12} justifyContent="flex-end" alignItems="flex-end">
                  <Grid
                    item
                  >
                    <Button
                      onClick={closeModal}
                      variant="contained"
                      disabled={submit_disabled ? true : false}
                    >
                    {setLanguageText(language,"Cancel")}
                    </Button>
                  </Grid>
                  {buttonTitle && (
                    <Grid item >
                      <LoadingButton
                        //icon={}
                        text={buttonTitle}
                        fullWidth={false}
                        //iconSize="1x"
                        color="primary"
                        type="submit"
                        loading={loading}
                        disabled={submit_disabled ? true : false}
                      />

                      {/* <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={loading || data}
                      >
                        {loading ? (
                          <LoadingDots
                            color={theme.palette.secondary}
                            width={40}
                          />
                        ) : (
                            buttonTitle
                          )}
                      </Button> */}
                    </Grid>
                  )}
                </Grid>

              </DialogActions>
            )}
          </form>
        </Dialog>
      </ThemeProvider>
    );
  }
);

export default GlobalDialog;
