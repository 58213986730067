import { faPencilRuler, faSortDown, faSortUp } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Grid,
  Card,
  CardContent,
  useTheme,
  Typography,
  Button
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { entries } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { usePagination, useFilters, useRowState, useSortBy, useTable } from 'react-table';
import setLanguageText from '../../../../helpers/setLanguageText';
import { LayoutContext } from '../../../../store/LayoutContext';
import { UserContext } from '../../../../store/UserContext';
import AddEntry from '../../../Entry/AddEntry';
import Pagination from '../../../reusable/Pagination';
import TableSettings from '../../TableSettings';
import MyDatePicker from '../MyDatePicker';


const useStyles = makeStyles(theme => ({
  grid: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
      marginBottom: theme.spacing(1)
    }
  },
  buttons: {
    marginBottom: isMobileOnly ? theme.spacing(1) : theme.spacing(0),
    '& button': {
      padding: isMobileOnly ? theme.spacing(2) : theme.spacing(1, 2)
    }
  },
}));

const EntriesTable = ({
  columns,
  data,
  getTableDataFn,
  headerSettings,
  dateFrom,
  setDateFrom,
  dateTo,
  setDateTo,
}) => {
  //************************  STYLES ************************
  const theme = useTheme();
  const classes = useStyles();
  //************************  CONTEXT ************************
  const { user } = useContext(UserContext);
  const { language } = useContext(LayoutContext);
  //************************  STATE ************************
  const [showVoid, setShowVoid] = useState(false);
  const [filterVoid, setFilterVoid] = useState([]);

  const viewSettings = entries(user?.user_attributes?.machine_view);
  // Hide Table columns coming from settings
  const hiddenColumns = viewSettings.map(setting =>
    !setting[1] ? setting[0] : null
  );

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page, which has only the rows for the active page
    // The rest of these things are super handy, too ;)
    allColumns,
    getToggleHideAllColumnsProps,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    visibleColumns,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data: filterVoid,
      // Table's Initial state
      initialState: {
        pageIndex: 0,
        hiddenColumns: [...headerSettings, ...hiddenColumns]
      },
      // Cell's Initial state
      initialCellStateAccessor: () => ({
        // used in the Status Column to handle the hover Popover
        anchorEl: null,
        isOpen: false
      })
    },
    useFilters,
    useSortBy,
    usePagination,
    useRowState
  );
  //************************ USE EFFECTS ************************
  // Handle VOIDS
  useEffect(() => {
    if (showVoid) {
      setFilterVoid(data);
    } else {
      const noVoids = data.filter(entry => !entry.void);
      setFilterVoid(noVoids);
    }
  }, [showVoid, data]);

  //returns table data to parent component
  useEffect(() => {
    if (getTableDataFn) {
      page && getTableDataFn(page);
    }
  }, [getTableDataFn, page]);

  const [open, setOpen] = useState(false);

  return (
    <Grid item container >
      {/* Table Header */}
      <Grid
        item
        container
        alignItems="center"
        justifyContent="space-between"
        style={{ marginBottom: theme.spacing(1) }}
      >
        <Grid
          item
          container
          spacing={1}
          justifyContent="flex-start"
          alignItems="flex-start"
          alignContent="flex-start"
          xs={12}
          lg={6}
          className={classes.buttons}
        >
          {!isMobileOnly &&
            <Grid item >
              <TableSettings
                showEntryOption
                showEntryText={setLanguageText(language,"Show Voided")}
                showEntry={showVoid}
                setShowEntry={setShowVoid}
                allColumns={allColumns}
                getToggleHideAllColumnsProps={getToggleHideAllColumnsProps}
              />
            </Grid>
          }
          <Grid item xs={12} md="auto">
          { user.level >= 7 && 
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={e => setOpen(true)}
              className={classes.button}
            >
              <FontAwesomeIcon icon={faPencilRuler} style={{ marginRight: '10px' }} />
              <Typography variant="body1" >{setLanguageText(language,"Add New Entry")}</Typography>
            </Button>
          }
            {open &&
              <AddEntry
                isDialog={open}
                setIsDialog={setOpen}
              />}
          </Grid>
        </Grid>

        <Grid
          item
          container
          spacing={1}
          xs={12}
          lg={6}
        >
          <MyDatePicker
            dateFrom={dateFrom}
            setDateFrom={setDateFrom}
            dateTo={dateTo}
            setDateTo={setDateTo}
            size="small"
          />

        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Card variant="outlined">
          <CardContent
            style={{
              overflowX: 'auto',
              maxHeight: '70vh',
              padding: '0'
            }}
          >
            <div
              {...getTableProps()}
              className="table-vertical"
              style={{
                display: 'grid',
                gridTemplateRows: `repeat(${visibleColumns.length}, auto)`,
                gridAutoFlow: 'column',
                gridTemplateColumns: theme.spacing(20)
              }}
            >
              {
                // Loop over the header rows
                headerGroups.map(headerGroup =>
                  // Apply the header row props

                  headerGroup.headers.map(column => {
                    return (
                      // Apply the header cell props
                      <Grid
                        item
                        container
                        alignItems="center"
                        {...column.getHeaderProps(
                          // add sort functionality
                          column.getSortByToggleProps()
                        )}
                        style={{
                          textAlign: 'left',
                          backgroundColor: '#fafafa',
                          position: 'sticky',
                          left: 0,
                          padding: theme.spacing(1, 2),
                          borderBottom: '1px solid #ccc'
                        }}
                      >
                        {
                          // Render the header
                          column.render('Header')
                        }
                        {/* Render arrow depending of sorting type */}
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <FontAwesomeIcon icon={faSortDown} />
                          ) : (
                              <FontAwesomeIcon icon={faSortUp} />
                            )
                        ) : (
                            ''
                          )}
                      </Grid>
                    );
                  })
                )
              }
              {
                // Loop over the table rows
                page.map((row, i) => {
                  // Prepare the row for display
                  prepareRow(row);
                  return (
                    // Loop over the rows cells
                    row.cells.map(cell => {
                      // Apply the cell props
                      return (
                        <div
                          {...cell.getCellProps()}
                          style={{
                            textAlign: 'left',
                            padding: theme.spacing(1, 2),
                            backgroundColor:
                              cell.row.original.void ?
                                '#e2e2e2'
                                : row.allCells[0].value
                                  ? '#FFEDF1'
                                  : theme.palette.common.white,
                            borderBottom: '1px solid #ccc'
                          }}
                        >
                          {
                            // Render the cell contents
                            cell.render('Cell')
                          }
                        </div>
                      );
                    })
                  );
                })
              }
            </div>
          </CardContent>
          {/* PAGINATION AND SETTINGS */}
          <Pagination
            allColumns={allColumns}
            rowCount={data.length}
            pageIndex={pageIndex}
            gotoPage={gotoPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            previousPage={previousPage}
            canPreviousPage={canPreviousPage}
            nextPage={nextPage}
            canNextPage={canNextPage}
            pageCount={pageCount}
          />
        </Card>
      </Grid>
    </Grid>
  );
};

export default EntriesTable;
