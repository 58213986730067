import {
    Grid,
    TextField
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { validate } from 'email-validator';
import React, { useContext, useState, useEffect } from 'react';
import setLanguageText from '../../helpers/setLanguageText';
import { CompanyContext } from '../../store/CompanyContext';
import { LayoutContext } from '../../store/LayoutContext';
import { UserContext } from '../../store/UserContext';

const UserForm = ({ user, setUser, edit }) => {

    console.log(user)

    //************************ CONTEXT ************************

    const { companies } = useContext(CompanyContext);
    const { user: userContext } = useContext(UserContext);
    const { language } = useContext(LayoutContext);  

    //************************ USE STATES ************************

    const [selectedCompanies, setSelectedCompanies] = useState([]);
    const [companyName, setCompanyName] = useState('');

    const [userLevels, setUserLevels] = useState([]);

    useEffect(()=>{
        if(userContext.level === 9 ){
            
            setUserLevels([
                { name: 'Read Only', group: 'Company_read_only', level: 6 },
                { name: 'Read/Write', group: 'Company_user', level: 7 },
                { name: 'Company Superuser', group: 'Company_superuser', level: 8 },
                { name: 'Enterprise Superuser', group: 'Enterprise', level: 9 }
            ])
        } else {

            setUserLevels([
                { name: 'Read Only', group: 'Company_read_only', level: 6 },
                { name: 'Read/Write', group: 'Company_user', level: 7 },
                { name: 'Company Superuser', group: 'Company_superuser', level: 8 }
            ])
        }
    },[userContext])

    const [emailCheck, setEmailCheck] = useState('');

    const handleLevel = (e,values) => {
        //console.log(current)
        console.log(values)
        setUser(prevState => ({
            ...prevState,
            level_group: values,
            level: values.level
        }));
    };

    const handleCompany = (e, values) => {
        //console.log(values)
        const companiesID = values.map(company => company.company_id);
        setUser(prevState => ({
            ...prevState,
            companies_id: companiesID,
            company_id: companiesID[0] || null
        }));
    };

    const handleTyping = e => {
        e.persist();
        console.log(e.target.id)
        if (e.target.id === 'company_name') {
            setCompanyName(e.target.value);
        } else if (e.target.id === 'confirm_email') {
            setEmailCheck(e.target.value);
        } else
            setUser(prevState => ({
                ...prevState,
                [e.target.id]: e.target.value
            }));
    };

    //set selected company object
    useEffect(() => {
        let list = [];
        (user.companies_id).forEach((id) => {
            //console.log(id)
            let found = companies.find(company => company.company_id === id);
            if (found) {
                list.push(found)
            }
        })
        setSelectedCompanies(list)
    }, [user, companies])
    //console.log(user)

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} >
                <Autocomplete
                    multiple
                    options={companies.filter(company => company.company_id)}
                    getOptionLabel={company => company.company_name ? company.company_name : null}
                    value={selectedCompanies}
                    filterSelectedOptions
                    renderInput={params => {
                        return (
                            <TextField
                                {...params}
                                required={!user.company_id}
                                variant="outlined"
                               label={setLanguageText(language,"Select Companies")}
                                placeholder={
                                    !user.companies_id.length
                                        ? 'Primary Company'
                                        : 'Other Company'
                                }
                                value={companyName}
                            />
                        );
                    }}
                    onChange={handleCompany}
                />
            </Grid>
            <Grid item container spacing={2} >
                <Grid item xs={12}>
                    <TextField
                        required
                       label={setLanguageText(language,"Full Name")}
                        variant="outlined"
                        fullWidth
                        id="full_name"
                        value={user.full_name}
                        onChange={handleTyping}
                    //disabled={edit}
                    //inputProps={{ pattern: "[\p{L}\p{M}\p{S}\p{N}\p{P}]+" }}
                    //error={user.name !=='' && !user.name.match("[\p{L}\p{M}\p{S}\p{N}\p{P}]+") === ""}
                    //helperText={'User Name cannot contain spaces'}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        required
                       label={setLanguageText(language,"Username")}
                        variant="outlined"
                        fullWidth
                        id="name"
                        value={user.name}
                        onChange={handleTyping}
                        disabled={edit}
                        //inputProps={{ pattern: "[\p{L}\p{M}\p{S}\p{N}\p{P}]+" }}
                        //error={user.name !=='' && !user.name.match("[\p{L}\p{M}\p{S}\p{N}\p{P}]+") === ""}
                        helperText={setLanguageText(language,'Cannot contain spaces or special characters')}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        required
                       label={setLanguageText(language,"Initial")}
                        variant="outlined"
                        fullWidth
                        id="initial"
                        value={user.initial}
                        onChange={handleTyping}
                    />
                </Grid>
            </Grid>
            <Grid item container spacing={2}>
                <Grid item xs={6}>
                    <TextField
                        required
                       label={setLanguageText(language,"Email")}
                        variant="outlined"
                        fullWidth
                        id="email"
                        value={user.email}
                        onChange={handleTyping}
                        error={Boolean(user.email.length) && !validate(user.email)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        required
                       label={setLanguageText(language,"Repeat Email")}
                        variant="outlined"
                        fullWidth
                        id="confirm_email"
                        //for speed
                        //value={user.email}
                        value={emailCheck}
                        onChange={handleTyping}
                        error={Boolean(emailCheck.length) && user.email !== emailCheck}
                    />
                </Grid>
            </Grid>
            <Grid item container spacing={2}>
                <Grid item xs={6}>
                    <TextField
                        //required
                       label={setLanguageText(language,"Title/Department")}
                        variant="outlined"
                        fullWidth
                        id="department"
                        value={user.department}
                        onChange={handleTyping}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        //required
                       label={setLanguageText(language,"Phone Number")}
                        variant="outlined"
                        fullWidth
                        id="phone"
                        value={user.phone}
                        onChange={handleTyping}
                    />
                </Grid>
                <Grid item xs={12}>
                <Autocomplete
                    disableClearable
                    options={userLevels}
                    getOptionLabel={options => options.name ?  options.name : null }
                    value={user.level_group ? user.level_group : null}
                    renderInput={params => (
                        <TextField required {...params} variant="outlined"label={setLanguageText(language,"User Level")} />
                      )}
                    onChange={handleLevel}
                />
                    {/* <FormControl variant="outlined" fullWidth>
                        <InputLabel>User Level</InputLabel>
                        <Select
                            value={user?.level_group?.level || ''}
                            onChange={handleLevel}
                           label={setLanguageText(language,"User Level"
                        >
                            {userLevels.map(level => (
                                <MenuItem
                                    key={level.level}
                                    object={{
                                        name: level.name,
                                        group: level.group,
                                        level: level.level
                                    }}
                                    value={level.level}
                                >
                                    {level.name}
                                </MenuItem>
                            ))}
                        </Select>

                    </FormControl> */}
                </Grid>
            </Grid>
        </Grid>
    );

}
export default UserForm;