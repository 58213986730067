const parseCompany = company => ({
  ...company,
  company_attributes:
    company.company_attributes && typeof company.company_attributes === 'string'
      ? JSON.parse(company.company_attributes)
      : company.company_attributes,
  company_colour_rules:
    typeof company.company_colour_rules === 'string'
      ? JSON.parse(company.company_colour_rules)
      : company.company_colour_rules,
  company_settings:
    typeof company.company_settings === 'string'
      ? JSON.parse(company.company_settings)
      : company.company_settings,
  company_display_settings:
    typeof company.company_display_settings === 'string'
      ? JSON.parse(company.company_display_settings)
      : company.company_display_settings,
  theme_settings:
    typeof company.theme_settings === 'string'
      ? JSON.parse(company.theme_settings)
      : company.theme_settings
});

// parse companies' attributes and settings
const parseCompanies = companies => {
  //console.log(companies);
  if (companies) {
    if (companies?.length) {
      return companies.map(company => parseCompany(company));
    } else if (Object.entries(companies).length) {
      return parseCompany(companies);
    }
  }
  return [];
};

export default parseCompanies;
