import { Button, Grid, TextField } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import createAttributeOutputFunction from '../../helpers/createAttributeOutputFunction';
import setLanguageText from '../../helpers/setLanguageText';
import { LayoutContext } from '../../store/LayoutContext';
import { buttonStyles } from '../../styles/buttonStyles';

const EntryNumbers = ({ initialValue, attr, dispatchFn, setError, edit }) => {

  const buttonStyle = buttonStyles();
  const { language } = useContext(LayoutContext)

  const [attribute, setAttribute] = useState({
    attribute_id: attr.one.attr_id,
    attribute_values: {
      value: initialValue
    }
  });
  const [warning, setWarning] = useState(false);

  //************  USE EFFECT ************
  useEffect(() => {
    dispatchFn && dispatchFn(attribute);
  }, [attribute, dispatchFn]);

  useEffect(() => {
    setAttribute(prevState => ({
      ...prevState,
      // When user change value and then return to pending, we need to reset the pending status manually, spread operator will keep other properties like min, max etc...
      attribute_values:
        attribute.attribute_values.value !== -1
          ? {
            ...prevState.attribute_values,
            ...createAttributeOutputFunction(attr)(
              attribute.attribute_values.value
            )
          }
          : {
            status: -10,
            value: -1,
            output: 'Pending'
          }
    }));
  }, [attr, attribute.attribute_values.value]);

  //set warnings
  useEffect(() => {
    //console.log(attribute.attribute_values.status)
    if (attribute.attribute_values.status === 30) {
      setError({
        attr_id: attribute.attribute_id,
        type: "warning",
        message: `Warning - ${attr.one.attr_name} is high`
      });
      setWarning(true);
    } else {
      setError({
        attr_id: attribute.attribute_id,
        type: "none",
      });
      setWarning(false);
    }
  }, [
    attribute.attribute_id,
    attribute.attribute_values.status
  ]);


  const handleChange = e => {
    e.persist();
    setAttribute(prevState => ({
      ...prevState,
      attribute_values: {
        ...prevState.attribute_values,
        value: +e.target.value
      }
    }));
  };

  const handleEmptyInput = e => {
    // Only enter Number between min and max
    const check_min_max =
      +e.target.value < attr.one.attr_min
        ? attr.one.attr_min
        : +e.target.value > attr.one.attr_max
          ? attr.one.attr_max
          : +e.target.value;

    setAttribute(prevState => ({
      ...prevState,
      attribute_values: {
        ...prevState.attribute_values,
        value: check_min_max
      }
    }));
  };

  //clear 
  const removeValue = () => {
    setAttribute(prevState => ({
      ...prevState,
      attribute_values: {
        status: -20,
        value: -2,
      }
    }));
    //remove error
    setError({
      attr_id: attribute.attribute_id,
      type: "none",
    });
  }

  //console.log(attribute)

  return (
    <Grid container alignItems='center' spacing={1}>
      <Grid xs={12} md={10} item>
        <TextField
          fullWidth
          type="number"
          InputLabelProps={{
            shrink: attribute.attribute_values.value ? true : false,
            //inputProps: { min: attr.one.attr_min, max: attr.one.attr_max } 
          }}
          label={`${attr.one.attr_name} (${attr.one.attr_min} - ${attr.one.attr_max})`}
          // inputProps={{ min, max: max / 10 }}
          value={attribute.attribute_values.value < 0 ? '' : attribute.attribute_values.value}
          onChangeCapture={handleChange}
          onBlur={handleEmptyInput}
          variant="outlined"
          size="medium"
          error={warning}
        //helperText={`${attr.one.attr_min} - ${attr.one.attr_max}`}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <Button
          fullWidth
          onClick={(e) => removeValue()}
          className={buttonStyle.danger}
          variant='outlined'
        >
          {setLanguageText(language, "Clear")}
        </Button>
      </Grid>
    </Grid>
  );
};
export default EntryNumbers;
