import React, { useContext } from 'react';
import { faSignOutAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, useTheme } from '@mui/material';
import { buttonStyles } from '../../styles/buttonStyles';
import { UserContext } from '../../store/UserContext';
import axiosRequest from '../../helpers/axiosRequest';
import useAsync from '../../Hooks/useAsync';
import setLanguageText from '../../helpers/setLanguageText';
import { LayoutContext } from '../../store/LayoutContext';

const Logout = () => {
  const buttonStyle = buttonStyles();
  const { authUser, user } = useContext(UserContext);
  const { language } = useContext(LayoutContext)
  const { executeLog } = useAsync({
    axiosRequest: axiosRequest
  });

  const logMeOut = e => {
    //e.PreventDefault();
    const saveLog = async (authUser, user) => {
      await executeLog({
        endpoint: 'activity',
        api_key: authUser.api_key,
        body: {
          attributes: {
            type: 'logout',
            user_id: user.id,
            user: user.name
            //,mobile: //get from context
          }
        }
      });
    };
    saveLog(authUser, user).then(() => {
      //Reset and remove all authentication
      localStorage.clear();
      sessionStorage.clear();
      //console.log("Session cleared");
      window.location.replace('/');
    });
  };

  return (
    <Button
      variant="contained"
      color="secondary"
      className={buttonStyle.button}
      onClick={e => logMeOut(e)}
    >
      <FontAwesomeIcon
        icon={faSignOutAlt}
        size="lg"
        style={{
          marginRight: useTheme().spacing(1),
          //fontSize: useTheme().spacing(3)
        }}
      />
      {setLanguageText(language,'Logout')}
    </Button>
  );
};

// function Logout() {
//   return (
//     <AmplifySignOut
//       buttonText="Log out"
//       handleAuthStateChange={(state, data) => window.localStorage.clear()}
//     ></AmplifySignOut>
//   );
// }

export default Logout;
