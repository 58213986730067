
import React, { forwardRef, useState, useEffect, useContext, useMemo } from 'react';
import { Button, DialogTitle, Grid, Slide, Dialog, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Close } from '@mui/icons-material';
import { isMobileOnly } from 'react-device-detect';
import EntryStepper from './EntryStepper';
import checkCustomAttributes from '../../helpers/checkCustomAttributes';
import { SelectionsContext } from '../../store/SelectionsContext';
import useAsync from '../../Hooks/useAsync';
import axiosRequest from '../../helpers/axiosRequest';
import { UserContext } from '../../store/UserContext';
import config from '../../config';
import { CLEAN_ENTRY } from '../../store/SelectionsReducers';
import { MachineContext } from '../../store/MachineContext';
import { SAVE_ENTRY } from '../../store/MachineReducers';
import LoadingButton from '../buttons/LoadingButton';
import { faArrowRight, faExclamationTriangle, faPaperPlane } from '@fortawesome/pro-light-svg-icons';
import MobileStepper from '@mui/material/MobileStepper';
import Alerts from './Alerts';
import setLanguageText from '../../helpers/setLanguageText';
import { LayoutContext } from '../../store/LayoutContext';
import { CompanyContext } from '../../store/CompanyContext';


const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiDialogContent-root': {
            padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
            [theme.breakpoints.down('md')]: {
                padding: `${theme.spacing(2)} ${theme.spacing(1)}`
            },
        }
    },
    title: {
        backgroundColor: theme.palette.background.light,
        padding: `${theme.spacing(1)} ${theme.spacing(2)}`
    },
    stepper: {
        backgroundColor: theme.palette.background.light,
        padding: theme.spacing(2),
        '& .MuiStepIcon-completed': {
            color: 'green'
        }
    }
}));

//create steps
function getSteps(language,attributes) {
    let steps = [
        setLanguageText(language,'Select Machine'),
        setLanguageText(language,'Standard Values'),
        setLanguageText(language,'Bacteria & Fungi'),
        setLanguageText(language,'Notes'),
        setLanguageText(language,'Additives')
    ];

    if(config.APPNAME === 'rhenus'){
        steps = [
            setLanguageText(language,'Select Machine'),
            setLanguageText(language,'Standard Values'),
            setLanguageText(language,'Notes'),
            setLanguageText(language,'Additives')
        ];
    }
    //if custom attributes add in extra step
    const has_custom_attributes = checkCustomAttributes(attributes);
    
    if (has_custom_attributes) {
        steps.splice(2, 0, setLanguageText(language,'Custom Attributes'));
    }
    return steps;
}

export const AddEntry = ({ isDialog, setIsDialog }) => {

    //****************** CONTEXT ******************
    const { addNewEntry, dispatchAddNewEntry } = useContext(SelectionsContext);
    const { dispatchSelectedMachine, selectedMachine } = useContext(MachineContext);
    const { authUser, user } = useContext(UserContext);
    const { language } = useContext(LayoutContext);
    //const { selectedCompany } = useContext(CompanyContext);

    //****************** STATE ******************
    const [activeStep, setActiveStep] = useState(0);
    const [compiledForm, setCompiledForm] = useState({});
    const [steps, setSteps] = useState([]);
    const [continueNext, setContinueNext] = useState(true);
    const [errors, setErrors] = useState([]);
    const [showWarning, setShowWarning] = useState(false);
    const [showError, setShowError] = useState(false);

    //****** STYLES ******
    const classes = useStyles();

    const {
        data,
        loading,
        logCompleted,
        logError,
        executeAsync,
        executeLog,
        setData
    } = useAsync({
        axiosRequest: axiosRequest
    });

    const {
        //error: qr_error,
        //data: qr_data,
        loading: qr_loading,
        //logCompleted: qr_logCompleted,
        //logError: qr_logError,
        executeAsync: qr_executeAsync
        //executeLog: qr_executeLog
    } = useAsync({
        axiosRequest: axiosRequest
    });


    useEffect(() => {
        if (data) {
            // TODO handle if activity error
            if (logError) console.log('Something went wrong with the Activity Log');
            if (logCompleted || logError) {
                // Clean addNewEntry context
                setIsDialog();
                dispatchAddNewEntry({ type: CLEAN_ENTRY });
                setData();
            }
        }
    }, [setIsDialog, data, dispatchAddNewEntry, logCompleted, logError]);

    //build steps
    useEffect(() => {
        if (addNewEntry.company) {
            setSteps(getSteps(language, addNewEntry.company.company_attributes));
            //reset entry
        }
    }, [addNewEntry.company]);

    //check for custom attributes
    const has_custom_attributes = useMemo(
        () =>
            addNewEntry.company &&
            checkCustomAttributes(addNewEntry.company.company_attributes),
        [addNewEntry.company]
    );

    const handleNext = () => {
        //console.log(continueNext)
        if (!continueNext) {
            setShowWarning(true);
            setContinueNext(true);
        } else {
            //reset errors
            setErrors([]);
            setShowError(false);
            setShowWarning(false);
            setContinueNext(true);
            let canContinue = true;
            //if you are at the end of the form
            if (activeStep === steps.length - 1) {
                //submit and stop form going any further
                handleSubmit();
                canContinue = false;
            }
            if (canContinue) {
                setActiveStep(prevActiveStep => prevActiveStep + 1);
            }
        }
    };

    const handleBack = () => {
        if (activeStep > 0) {
            //reset errors
            setErrors([]);
            setShowError(false);
            setShowWarning(false);
            setContinueNext(true);
            setActiveStep(prevActiveStep => prevActiveStep - 1);
        }
    };

    const closeModal = () => {
        setActiveStep(0);
        setCompiledForm({});
        setIsDialog();
    };

    const handleSubmit = async e => {
        //console.log('submit')
        // Simulate Entry Submit 
        //set out of control
        let outOfControl = await addNewEntry.entry.attributes_new.some(item => item.attribute_values.status === 30);
        addNewEntry.entry.out_of_control = outOfControl
        //remove previous entry id
        addNewEntry.entry.entry_id = null
        //console.log(addNewEntry)
        // Add the entry
        const response = await executeAsync({
            api_key: authUser.api_key,
            method: 'post',
            endpoint: 'machines/entries',
            body: addNewEntry.entry
        });
        // Will return the success object only if entry has been added correctly
        if (response) {
           //console.log(addNewEntry)
            if (addNewEntry.qr_codes && response.insertId) {
                //Set QRcodes
                let yourDate = new Date()
                const offset = yourDate.getTimezoneOffset()
                yourDate = new Date(yourDate.getTime() - (offset*60*1000))

                await qr_executeAsync({
                    api_key: authUser.api_key,
                    method: 'patch',
                    endpoint: 'qr',
                    body: {
                        qr_codes: addNewEntry.qr_codes,
                        entry_id: response.insertId,
                        machine_id : addNewEntry.machine.machine_id,
                        user_id: user.id,
                        date: yourDate.toISOString(),
                        app: config.APPNAME
                    }
                });
            }
            await executeLog({
                endpoint: 'activity',
                api_key: authUser.api_key,
                body: {
                    attributes: {
                        type: 'New Entry',
                        user_id: user.id,
                        machine_id: addNewEntry.machine.machine_id,
                        entry_id: response.insertId
                    }
                }
            });
            //save entry into enteries table
            if(selectedMachine.machine_id === addNewEntry.machine.machine_id ){
                dispatchSelectedMachine({
                    type: SAVE_ENTRY,
                    payload: { ...addNewEntry.entry, entry_id: response.insertId }
                });
            }
            closeModal()

        }
    };

    const setError = (error) => {
        if (error) {
            setShowError(false);
            setShowWarning(false);
            setContinueNext(true);
            if (errors.length) {
                let index = errors.findIndex((err) => err.attr_id === error.attr_id)
                if (index !== -1) {
                    errors[index] = error
                } else {
                    errors.push(error)
                }
            } else {
                errors.push(error)
            }
            //console.log(errors);
            setErrors(errors);
            //hide show errrors
            errors.forEach((err) => {
                if (err.type === 'error') {
                    setShowError(true);
                }
                if (err.type === 'warning') {
                    //setShowWarning(true);
                    setContinueNext(false);
                }
            })
        }
    }
    //console.log(addNewEntry)

    return (
        <Dialog
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={isDialog}
            onClose={closeModal}
            TransitionComponent={Transition}
            keepMounted
            fullWidth
            maxWidth="md"
            fullScreen={isMobileOnly}
            className={classes.root}
        >
            <DialogTitle className={classes.title}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>{setLanguageText(language,"Add New Entry")}</Grid>
                    <Grid item>
                        <IconButton onClick={closeModal} size="large">
                            <Close />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <EntryStepper
                edit={false}
                steps={steps}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                has_custom_attributes={has_custom_attributes}
                compiledForm={compiledForm}
                setError={setError}
                errors={errors}
                continueNext={continueNext}
            />
            <Alerts errors={errors} data={data} showWarning={showWarning} showError={showError} />
            <MobileStepper
                variant={isMobileOnly ? 'dots' : null}
                steps={steps.length}
                position="static"
                activeStep={activeStep}
                className={classes.root}
                nextButton={
                    <LoadingButton
                        icon={
                            activeStep === steps.length - 1 ? faPaperPlane :
                                showWarning ? faExclamationTriangle
                                    : faArrowRight
                        }
                        text={
                            activeStep === steps.length - 1 ? setLanguageText(language,"Submit Entry") :
                                showWarning ? setLanguageText(language,"Continue Anyway?")
                                    : setLanguageText(language,"Next")
                        }
                        fullWidth={false}
                        iconSize="1x"
                        color="primary"
                        loading={loading || qr_loading }
                        disabled={showError || data}
                        onClick={handleNext}
                    />
                }
                backButton={
                    <Button disabled={loading || qr_loading || showError} onClick={handleBack}>{setLanguageText(language,"Back")}</Button>
                }
            />
        </Dialog>
    );
};

export default AddEntry;
