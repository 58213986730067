import {
  faBook,
  faChartPie,
  faCogs,
  faStethoscope,
  faTachometerAltSlowest,
  faTint,
  faUser,
  faVial,
  faVials
} from '@fortawesome/pro-light-svg-icons';
import { Grid, IconButton, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import React, { useContext, useState } from 'react';
import Logout from '../Logout';
import Item from './MenuItem';
import { ThemeProvider, useTheme } from '@mui/system';
import setLanguageText from '../../../helpers/setLanguageText';
import { LayoutContext } from '../../../store/LayoutContext';

const ResponsiveMenu = () => {
  const { language } = useContext(LayoutContext)
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <ThemeProvider theme={theme}>
    <Grid item container justifyContent="flex-end" xs={2}>
      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        size="large">
        <MenuIcon style={{ color: '#ffffff' }} fontSize="large" />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={10}
      >
        <Item title={setLanguageText(language,'Dashboard')} path="/" icon={faTachometerAltSlowest} handleClose={handleClose} language={language}/>
        <Item title={setLanguageText(language,'Machines')} path="/machines" icon={faCogs} handleClose={handleClose} language={language}/>
        <Item title={setLanguageText(language,'Coolants')} path="/coolants" icon={faTint} handleClose={handleClose} language={language}/>
        <Item title={setLanguageText(language,"Products")} path="/Products" icon={faVials} handleClose={handleClose} language={language}/>
        <Item title={setLanguageText(language,"Additives")} path="/additives" icon={faVial} handleClose={handleClose} language={language}/>
        <Item title={setLanguageText(language,"Reports")} path="/reports" icon={faChartPie} handleClose={handleClose} language={language}/>
        <Item title={setLanguageText(language,"Resources")} path="/resources" icon={faBook} handleClose={handleClose} language={language}/>
        <Item title={setLanguageText(language,"Diagnostic")} path="/diagnostic" icon={faStethoscope} handleClose={handleClose} language={language}/>
        <Item title={setLanguageText(language,"My Account")} path="/account/0" icon={faUser} handleClose={handleClose} language={language}/>
        <MenuItem onClick={handleClose}>
          <Logout />
        </MenuItem>
      </Menu>
    </Grid>
    </ThemeProvider>
  );
};

export default ResponsiveMenu;
