import React, { useState, useEffect, useContext } from 'react';
import axiosRequest from '../../helpers/axiosRequest';
import { UserContext } from '../../store/UserContext';
import useAsync from '../../Hooks/useAsync';
import GlobalDialog from '../reusable/GlobalDialog';
import TypesForm from './TypesForm';


const AddType = ({ closeAddDialog, getTypes,  ...props }) => {
  //****** CONTEXT ******
  const { authUser, user } = useContext(UserContext);
  //****** STATE ******
  const [type, setType] = useState();

  const {
    error,
    data,
    loading,
    logCompleted,
    logError,
    executeAsync,
    setData,
  } = useAsync({
    axiosRequest: axiosRequest
  });

  useEffect(() => {
    // TODO handle if activity error
    if (logError) console.log('Something went wrong with the Activity Log');

  }, [closeAddDialog, data, logCompleted, logError]);

  //set type from form
  const updateType = e => {
    setType(e);
  };

  //Create new brand
  const handleSubmit = async e => {
    e.preventDefault()
    //console.log('new brand')
    let typeDetails = await executeAsync({
      endpoint: 'additives/types',
      api_key: authUser.api_key,
      method: 'post',
      body: type
    });

    setTimeout(() => {
      //reset form 
      setType();
      closeAddDialog();
      //reset data from aysnc
      setData();
      getTypes();
    }, 1000);
  };

  //****** RENDER ******
  return (
    <GlobalDialog
      closeModal={closeAddDialog}
      title="Add New Additive Type"
      handleSubmit={handleSubmit}
      buttonTitle="Add Type"
      successMessage="Type added successfully!"
      loading={loading}
      error={error}
      data={data}
      fullWidth
      maxWidth="sm"
      {...props}
    >
        <TypesForm
          type={type}
          updateType={updateType}
        />
    </GlobalDialog>
  );
};

export default AddType;
