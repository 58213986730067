import React, { useState } from 'react';
//import { Document, Page } from 'react-pdf';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons';
import PrimaryBtnIcon from '../buttons/PrimaryBtnIcon';
import { buttonStyles } from '../../styles/buttonStyles';
//pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.js';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
    
import { pdfjs } from 'react-pdf';


// import { pdfjs } from 'react-pdf'
// import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry'
// pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
if (typeof window !== 'undefined' && 'Worker' in window) {
    pdfjs.GlobalWorkerOptions.workerPort = new Worker(
      new URL('pdfjs-dist/legacy/build/pdf.worker', import.meta.url)
    )
}

const useStyles = makeStyles(theme => ({
    header: {
        padding: theme.spacing(1),
        background: '#444',
        color: '#fff'
    },
    canvas: {
        '& .react-pdf__Document': {
            maxWidth: '100%',
            overflow: 'scroll'
        },
        '& canvas': {
            margin : 'auto',
            //idth: '100%!important',
            //height: 'auto!important'
        }
    }
}));


const ReactPDFFallback = ({ url }) => {


    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const classes = useStyles();
    const buttonStyle = buttonStyles();

    function onDocumentLoadSuccess({ numPages }) {
        console.log(numPages)
        setNumPages(numPages);
        setPageNumber(1);
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }


    return (
        <Grid container>
            {url && (
                <>
                    {numPages > 1 &&
                        <Grid
                            className={classes.header}
                            container
                            item
                            xs={12}
                            alignContent="center"
                            alignItems="center"
                            justifyContent="space-around"
                        >
                            <Grid item >
                                <PrimaryBtnIcon
                                    icon={faChevronLeft}
                                    onClick={previousPage}
                                    className={buttonStyle.grey}
                                    disabled={pageNumber <= 1}

                                />
                            </Grid>
                            <Grid item >
                                <p>
                                    Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
                                </p>
                            </Grid>
                            <Grid item>
                                <PrimaryBtnIcon
                                    icon={faChevronRight}
                                    onClick={nextPage}
                                    className={buttonStyle.grey}
                                    disabled={pageNumber >= numPages}

                                />
                            </Grid>
                        </Grid>
                    }
                    <Grid className={classes.canvas} item xs={12}>
                    
                        <Document
                            file={url}
                          
                            onLoadError={(error) => {
                                console.log(error)
                            }}
                            onLoadSuccess={onDocumentLoadSuccess}
                        >
                            <Page pageNumber={pageNumber} />

                        </Document>
                  
                    </Grid>


                </>
            )
            }
        </Grid >
    );
};

export default ReactPDFFallback;
