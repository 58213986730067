
import { TextField } from '@mui/material';
import { Autocomplete } from '@mui/material';
import React, { useMemo } from 'react';
import setLanguageText from '../../helpers/setLanguageText';

const SelectColumnFilter = ({column: { filterValue, setFilter, preFilteredRows, id , name, }},title,language) => {


  console.log(title)
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  //console.log(options)

  // Render a multi-select box
  return (
    <Autocomplete
        id={id}
        options={options}
        getOptionLabel={option => option || ''}
        renderInput={params => (
            <TextField
                //required
                {...params}
                label={title}
                variant="outlined"
            />
        )}
        value={filterValue || `${setLanguageText(language,'All')} (${options.length})`}
        onChange={(e,value) => {
          //console.log(value)
          setFilter(value || undefined);
        }}
        
    />
  );
}
export default SelectColumnFilter;