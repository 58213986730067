import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme
} from '@mui/material';
import React from 'react';
import {
  useFilters,
  usePagination,
  useRowState,
  useSortBy,
  useTable
} from 'react-table';
import Pagination from '../reusable/Pagination';
import { matchSorter } from 'match-sorter';

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] });
}

const ActivityTable = ({ data, columns }) => {
  //************************  STYLES ************************
  const theme = useTheme();

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id].type;
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      }
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      //Filter: DefaultColumnFilter
      Filter: ''
    }),
    []
  );


  //************************  TABLE CREATE FUNCTION ************************
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    // The rest of these things are super handy, too ;)
    allColumns,
    getToggleHideAllColumnsProps,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, filters }
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      // Table's Initial state
      initialState: {
        pageIndex: 0
      },
      // Cell's Initial state
      initialCellStateAccessor: () => ({
        // used in the Status Column to handle the hover Popover
        anchorEl: null,
        isOpen: false
      })
    },
    useFilters,
    useSortBy,
    usePagination,
    useRowState
  );

  return (
    <Card variant="outlined">
      <CardContent
        style={{
          overflowX: 'auto',
          maxHeight: '70vh',
          padding: '0'
        }}
      >
        <Table
          {...getTableProps()}
          className="machines--table"
          stickyHeader
          aria-label="sticky table"
        >
          {/* TABLE HEADER */}
          <TableHead className="machines--header" style={{ width: '100%' }}>
            {
              // Loop over the header page
              headerGroups.map(headerGroup => (
                // Apply the header row props
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map(column => {
                      return (
                        // Apply the header cell props
                        <TableCell
                          {...column.getHeaderProps(
                            // add sort functionality
                            column.getSortByToggleProps()
                          )}
                          style={{
                            textAlign: 'center',
                            backgroundColor: theme.palette.background.light,
                            padding: theme.spacing(1, 2)
                          }}
                        >
                          {
                            // Render the header
                            column.render('Header')
                          }
                          <div>
                            {column.canFilter
                              ? column.render('Filter')
                              : null}
                          </div>
                        </TableCell>
                      );
                    })
                  }
                </TableRow>
              ))
            }
          </TableHead>
          {/* TABLE CONTENT */}
          {/* Apply the table body props */}
          <TableBody {...getTableBodyProps()}>
            {
              // Loop over the table page
              page.map((row, i) => {
                // Prepare the row for display
                prepareRow(row);
                return (
                  // Apply the row props
                  <TableRow
                    {...row.getRowProps()}
                    style={{ backgroundColor: '#ffffff' }}
                  >
                    {
                      // Loop over the rows cells
                      row.cells.map(cell => {
                        // Apply the cell props
                        return (
                          <TableCell
                            {...cell.getCellProps()}
                            style={{
                              textAlign: 'center'
                            }}
                          >
                            {
                              // Render the cell contents
                              cell.render('Cell')
                            }
                          </TableCell>
                        );
                      })
                    }
                  </TableRow>
                );
              })
            }
          </TableBody>
        </Table>
        {/* <code>{JSON.stringify(filters, null, 2)}</code> */}
      </CardContent>
      {/* PAGINATION AND SETTINGS */}
      <Pagination
        allColumns={allColumns}
        rowCount={data.length}
        getToggleHideAllColumnsProps={getToggleHideAllColumnsProps}
        pageIndex={pageIndex}
        gotoPage={gotoPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        previousPage={previousPage}
        canPreviousPage={canPreviousPage}
        nextPage={nextPage}
        canNextPage={canNextPage}
        pageCount={pageCount}
      />
    </Card>
  );
};

export default ActivityTable;
