import axiosRequest from './axiosRequest';

// Check user level and fetch attributes accordingly
export const fetchCompanyAttributes = (api_key, method, company_id) => {
  return axiosRequest({
    endpoint: 'company/attributes',
    api_key,
    method,
    params: {
      id: company_id
    }
  });
};
