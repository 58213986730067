import { faDownload } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Slide,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Close } from '@mui/icons-material';
import { Alert } from '@mui/material';
import React, { forwardRef, useContext } from 'react';
import { isMobileOnly, isIOS } from 'react-device-detect';
import EmailPDF from './EmailPDF';
import { saveAs } from 'file-saver';
import { LayoutContext } from '../../store/LayoutContext';
import setLanguageText from '../../helpers/setLanguageText';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiDialogContent-root': {
      padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
      [theme.breakpoints.down('md')]: {
        padding: `${theme.spacing(2)} ${theme.spacing(1)}`
      },
    },
    '& .MuiDialog-paper': {
      width: '100%',
      height: '100%'
    }
  },
  title: {
    backgroundColor: theme.palette.background.light,
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`
  },
  actions: {
    backgroundColor: theme.palette.background.light
  }
}));

const GlobalPdfDialog = ({
  children,
  open,
  closeModal,
  title,
  buttonTitle,
  csvDownloadComp,
  onEntered,
  error,
  email,
  serviceID,
  companyName,
  download,
  downloadObject,
  ...props
}) => {
  const classes = useStyles();
  const { language } = useContext(LayoutContext);

  const downloadButton = () => {

    //still not working on  IOS chrome
      // const downloadFile = ({ name, content }) => {
      //   const file = new Blob([content], { type: 'text/plain;charset=utf-8' })
      //   return new Promise(resolve => {
      //   saveAs(file, name)
      //   resolve(true)
      //   })
      // }
      //var blob = new Blob(["Hello, world!"], {type: "text/plain;charset=utf-8"});
      var blob = new  Blob([downloadObject.blob], {type: "application/pdf;charset=utf-8"});
      saveAs(blob, `${title}.pdf`);
   
  }

  return (
    <Dialog
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={closeModal}
      TransitionComponent={Transition}
      //TransitionProps={onEntered}
      error={error}
      //keepMounted
      fullWidth
      maxWidth="sm"
      fullScreen={isMobileOnly}
      className={classes.root}
      {...props}
    >
      <DialogTitle className={classes.title}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>{setLanguageText(language,title)}</Grid>
          <Grid item>
            <IconButton onClick={closeModal} size="large">
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent style={{ position: 'relative', padding : '0px' }}>{children}</DialogContent>
      <Divider />
      {error && (
          <>
          <Grid item  className={classes.warnings}  >
            <Alert severity="error" variant="standard">
            {setLanguageText(language,error.message)}
            </Alert>
          </Grid>
          <Divider />
          </>
        )}
      <DialogActions className={classes.actions}>
        {/* <Grid item> */}
        <Grid container spacing={1} justifyContent="flex-end">
        {email && downloadObject && 
            <EmailPDF 
                downloadObject={downloadObject} 
                serviceID={serviceID} 
                companyName={companyName}
            />
          } 
        {download && downloadObject  &&
        <Grid item>
            <Button 
              onClick={e => downloadButton()}
              color="primary" 
              variant="contained"
            >
              <FontAwesomeIcon style={{marginRight: '10px', textDecoration: 'none'}} icon={faDownload}  />
              {setLanguageText(language,"Download PDF")}
            </Button>
            </Grid>
     
          }
          {/* {download && downloadObject && 
            <a style={{margin: '0', padding: '4px', textDecoration: 'none'}} href={downloadObject.url} type={downloadObject.blob.type} target="_blank" rel="noopener noreferrer" download>
            <Button  color="primary" variant="contained">
              <FontAwesomeIcon style={{marginRight: '10px', textDecoration: 'none'}} icon={faDownload}  />
               Download Report
            </Button>
         </a>
          } */}
          {csvDownloadComp && <Grid item>{csvDownloadComp}</Grid>}
          {buttonTitle && (
            <Grid item>
              <Button type="submit" color="primary" variant="contained">
                {setLanguageText(language,buttonTitle)}
              </Button>
            </Grid>
          )}
          <Grid item>
            <Button onClick={closeModal} variant="contained">
            {setLanguageText(language,"Close")}
            </Button>
          </Grid>
          
        </Grid>
        {/* </Grid> */}
      </DialogActions>
    </Dialog>
  );
};

export default GlobalPdfDialog;
