import { Grid, Typography, CardContent, Card } from '@mui/material';
import React from 'react';
import PageContent from '../PageContent';
import PageHeader from '../PageHeader';

const Maintenance = () => {

  return (
    <>
      <PageHeader>
        <Grid item container alignItems="stretch" spacing={2}>
          <Grid item style={{ alignSelf: 'center' }}>
            <Typography variant="h5">Maintenance</Typography>
          </Grid>
        </Grid>
      </PageHeader>
      <PageContent className="reports">
        <Grid item container spacing={2}>
          <Card
            sx={{
              margin: '1em',
              padding: '1em'
            }}
          >
            <CardContent>
              <Typography variant="h2">Maintenance management software features &amp; benefits</Typography><br /><br />
              <Typography variant="h4">Easy asset management</Typography>
              <p>Quickly build asset hierarchies, add equipment, clone records, and intuitively organize assets with drag and drop functionality.<br />
              Import existing assets from CSV files to easily migrate from other systems. Access asset histories with one click.</p><br />
              <Typography variant="h4">Dashboards and reporting</Typography>
              <p>Make data-driven decisions with a personalized dashboard, KPI tracking, custom reporting, and data management.</p><br />
              <Typography variant="h4">Calendar</Typography>
              <p>Easily schedule resources and get a real-time view of scheduled work orders and their status.</p><br />
              <Typography variant="h4">Multi-site management</Typography>
              <p>Coordinate multiple sites globally, manage work in multiple time zones, and operate in a standard currency &amp; language.</p><br />
              <Typography variant="h4">Inventory, parts &amp; supplies</Typography>
              <p>Get visibility on low stock items with a complete inventory manager.</p><br />
              <Typography variant="h4">Automated PMs</Typography>
              <p>Set up scheduled maintenance according to date, time, meter, event, and alarm-based triggers.<br />
              Standardize practices with automated task lists and receive notifications for upcoming maintenance.</p>
            </CardContent>
          </Card>
        </Grid>
      </PageContent>
    </>
    );
};

export default Maintenance;
