import React, { useCallback } from 'react';
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image,
    //PDFDownloadLink,
} from '@react-pdf/renderer';
import createPieChart from '../Charts/createPieChart';
import createBarChart from '../Charts/createBarChart';
import createHorizontalBar from '../Charts/createHorizontalBar';
import formatDate from '../../helpers/formatDate';
import setLanguageText from '../../helpers/setLanguageText';

const ChartsPDF = ({ chartData, selectedCompany, selectedGroup, orientation, theme , language}) => {

    // console.log(selectedCompany)
    // console.log(selectedGroup)
    let index = chartData.findIndex(item => item.id === 'totals');
    //console.log(chartData[index].data)
    let checked = chartData[index].data.has_entries.count
    let not_checked = chartData[index].data.no_entries.count
    let total = checked + not_checked
    let percentage = (checked * 100) / total

    // Create styles
    const styles = StyleSheet.create({
        body: {
            paddingTop: 10,
            paddingBottom: 10,
            paddingHorizontal: 10,
            height: '100%'
        },
        pageNumber: {
            position: 'absolute',
            fontSize: 10,
            bottom: 10,
            left: 0,
            right: 0,
            textAlign: 'center',
            color: 'grey'
        },
        container: {
            width: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            alignContent: 'space-between',
            flexDirection: 'row'
        },
        charts: {
            width: '30%',
            flexBasis: '30%',
            padding: '10px 20px'
        },
        chart_container: {
            width: '100%',
        },
        chart_header: {
            fontSize: 14,
            width: '100%',
            textAlign: 'center',
            marginBottom: 5
        },
        barchart: {
            height: '100%',
            width: '100%',
            objectFit: 'contain'
        },
        header: {
            width: '100%',
            textAlign: 'center',
            margin: '10px 0 0 0'
        },
        header_title: {
            fontSize: 14,
            fontWeight: 'bold'
        },
        header_subtitle : {
            marginTop:5,
            fontSize: 10
        },
        footer: {
            textAlign: 'center',
            height: '5vh',
            position: 'absolute',
            fontSize: 12,
            bottom: 0,
            left: 0,
            right: 0,
        },
        footer_text: {
            fontSize: 8,
            marginTop: 5,
            marginBottom: 5,
            fontWeight: 'normal'
        }
    });
    const Charts = (chartData) => {
        //console.log(attributes)
        return chartData.map((chart, i) => {
            //console.log(chart)
            let url
            if (chart.chart_type === 'pie') {
                url = createPieChart(chart);
            } else if (chart.chart_type === 'bar') {
                //create bar chart
                url = createBarChart(chart);
            }
            return (
                <View wrap={false} key={i} style={styles.charts}>
                    <Text style={styles.chart_header} >{chart.name}</Text>
                    <View style={styles.chart_container}>
                        <Image style={styles.chart} src={url} />
                    </View>
                </View>
            );
        })
    }

    const Bar = (chartData, theme, checked, not_checked, percentage) => {
        //console.log(attributes)
        let url = createHorizontalBar(chartData, theme, checked, not_checked, percentage,language);
        return (
            <View style={styles.bar}>
                <Image style={styles.barchart} src={url} />
            </View>
        );
    }

    return (
        <Document title={setLanguageText(language,"Dashboard Export")}>
            <Page style={styles.body} orientation={`${orientation}`}>
                <View fixed style={styles.header}>
                    <Text style={styles.header_title}>{setLanguageText(language,"Dashboard Report")} / {selectedCompany.company_name} / {selectedGroup.group_name}({total})</Text>
                    <Text style={styles.header_subtitle}>{setLanguageText(language,"Total Machines")}: {total}</Text>
                </View>
                {Bar(chartData, theme, checked, not_checked, percentage)}
                <View style={styles.container}>
                    {Charts(chartData)}
                </View>
                <View fixed style={styles.footer}>
                    <Text style={styles.footer_text}>
                    {setLanguageText(language,"Dashboard Export")} {formatDate(Date.now())}
                    </Text>
                </View>
            </Page>
        </Document>
    );
};

export default ChartsPDF;
