import { TextField } from '@mui/material';
import { Alert, Autocomplete } from '@mui/material';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import axiosRequest from '../../helpers/axiosRequest';
import setLanguageText from '../../helpers/setLanguageText';
import useAsync from '../../Hooks/useAsync';
import { UserContext } from '../../store/UserContext';
import { LayoutContext } from '../../store/LayoutContext';

const DropdownActivityTypes = ({ setSelected, selectedType }) => {
  const { authUser } = useContext(UserContext);
  const { language } = useContext(LayoutContext);  
  const [types, setTypes] = useState();
  // const [value, setValue] = useState({ activity_type: 'All Activities' });
  const { error, data, loading, executeAsync } = useAsync({
    axiosRequest: axiosRequest
  });

  useEffect(() => {
    if (!types) {
      executeAsync({
        method: 'get',
        api_key: authUser.api_key,
        endpoint: 'reports/activity',
        params: { get_activity_types: true }
      });
    }
  }, [authUser.api_key, executeAsync, types]);

  useEffect(() => {
    if (data) {
      const add_ALL_option = [{ activity_type: 'All Activities' }, ...data];
      setTypes(add_ALL_option);
    }
  }, [data]);

  const onSelectManufacturer = (e, type) => {
    setSelected(type || { activity_type: 'All Activities' });
  };
  //add users counter
  const modifiedValue = useMemo(() => {
    return selectedType.activity_type === 'All Activities'
      ? { activity_type: `${selectedType.activity_type} (${types?.length ? types.length - 1 : null})` }
      : selectedType;
  }, [selectedType, types]);

  if (error) return <Alert severity="error">{error.message}</Alert>;

  return (
    <Autocomplete
      options={types || []}
      loading={loading}
      getOptionLabel={option => option.activity_type || ''}
      renderInput={params => (
        <TextField
          required
          {...params}
         label={setLanguageText(language,"Activity Type")}
          variant="outlined"
        />
      )}
      value={modifiedValue}
      onChange={onSelectManufacturer}
    />
  );
};

export default DropdownActivityTypes;
