import React, { useState, useEffect, useContext, useCallback } from 'react';
import axiosRequest from '../../../helpers/axiosRequest';
import { UserContext } from '../../../store/UserContext';
import useAsync from '../../../Hooks/useAsync';
import GlobalDialog from '../../reusable/GlobalDialog';
import { Autocomplete } from '@mui/lab';
import { Grid, TextField } from '@mui/material';
import { MachineContext } from '../../../store/MachineContext';
import { UPDATE_MACHINE_SETTINGS } from '../../../store/MachineReducers';
import setLanguageText from '../../../helpers/setLanguageText';
import { LayoutContext } from '../../../store/LayoutContext';

const MachineProductAdd = ({ currentList , isOpen, closeAddProduct }) => {

    const { user, authUser } = useContext(UserContext);
    const { selectedMachine , dispatchSelectedMachine} = useContext(MachineContext);
    const [productList, setProductList] = useState();
    const [product, setProduct] = useState();
    const { language } = useContext(LayoutContext);  
    
    const {
        data,
        executeAsync,
    } = useAsync({
        axiosRequest: axiosRequest
    });

    // get product list
    useEffect(() => {
        if (user && authUser) {
            const getProductList = async () => {
                //console.log('fetch all product by type')
                let products = await executeAsync({
                    method: 'get',
                    api_key: authUser.api_key,
                    endpoint: `coolants`,
                    params: {
                        user_level: user.level,
                    }
                });
                let notCoolants = products.filter((product) => product.coolant_type !== 1)
                setProductList(notCoolants)
            };
            getProductList();
        }
    }, [user, authUser])
    
    //add prodcut to machine
    const {
        data: add_data,
        error: add_error,
        loading: add_loading,
        executeAsync : add_executeAsync,
    } = useAsync({
        axiosRequest: axiosRequest
    });

   

    const handleSubmit = useCallback( async (e) => {
        
          //console.log(product)
          //check if this produvt has already been added
          let idx 
          console.log(currentList)
          if(currentList) {
            idx = currentList.findIndex(curr => curr.coolant_id === product.coolant_id);
          } else {
            currentList = []
            selectedMachine.machine_settings.products = []
            idx = -1
          }
          //add new product
          if(idx === -1){    
              let products = [...selectedMachine.machine_settings.products,product.coolant_id]
              let response =  await add_executeAsync({
                  endpoint: 'machines',
                  api_key: authUser.api_key,
                  method: 'patch',
                  body: {
                      edit: 'products',
                      machine_id: selectedMachine.machine_id,
                      settings: products
                  }
              }); 
              if(response){
                  dispatchSelectedMachine({
                      type: UPDATE_MACHINE_SETTINGS,
                      payload: {
                          products: products
                      }
                  });
                  closeAddProduct(product)
              }
          } 
          closeAddProduct();

     },[currentList, selectedMachine, product])

    const addProduct = (e, product) => {
        setProduct(product)    
    }

    //****** RENDER ******
    return (
        <GlobalDialog
            closeModal={closeAddProduct}
            title="Add Product"
            handleSubmit={handleSubmit}
            buttonTitle="Add Product"
            successMessage="Product Added"
            loading={add_loading}
            error={add_error}
            data={add_data}
            keepMounted
            fullWidth
            maxWidth="md"
            open={isOpen}
        >

            <Grid item xs={12}>
                <Autocomplete
                    id="product"
                    options={productList || []}
                    fullWidth
                    getOptionLabel={(option) => `${option.coolant_name} - ${option.coolant_type_name}` || ''}
                    isOptionEqualToValue={(option, value) => option.coolant_id === value}
                    renderInput={params => (
                        <TextField
                            required
                            {...params}
                           label={setLanguageText(language,"Select Product")}
                            variant="outlined"
                        />
                    )}
              
                    //value={coolant || null}
                    onChange={(e, product) => addProduct(e,product)}
                />
            </Grid>

        </GlobalDialog>
    );
};

export default MachineProductAdd;
