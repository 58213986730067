import React, { useContext } from 'react';
import PageContent from '../PageContent';
import {
  Alert,
  Grid,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import PageHeader from '../PageHeader';
import ThemeForm from './ThemeForm';
import { LayoutContext } from '../../store/LayoutContext';
import setLanguageText from '../../helpers/setLanguageText';

const useStyles = makeStyles(theme => ({
  card: {
    width: '100%'
  },
  header: {
    borderBottom: `${theme.spacing(0.4)} solid ${theme.palette.background.light
      }`,
    padding: theme.spacing(1)
  }
}));

//theme type passed from parent componenet
const Theme = ({ type, edit }) => {

  const classes = useStyles();
  const { language } = useContext(LayoutContext)

  return (
    <>
      <PageHeader>
        <Grid item xs={12}>
          <Grid
            item
            container
            justifyContent="space-between"
            className={classes.header}
          >
            <Grid item xs={12} sm={'auto'}>
              <Typography variant="h5">{setLanguageText(language,"Theme")}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </PageHeader>
      <PageContent className="theme">
        {type === 'company' ?
          <Alert severity="info">{setLanguageText(language,'Company Theming has been currently disabled')}</Alert>
          :
          <ThemeForm type={type} edit={edit} />
        }
      </PageContent>
    </>
  );
};

export default Theme;
