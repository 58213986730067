// parse machines' attributes and settings
const parseUser = user => {
  //console.log(user)
  if (user) {
    return {
      ...user,
      companies_id: JSON.parse(user.companies_id),
      level_group: JSON.parse(user.level_group),
      user_attributes:
        typeof user.user_attributes === 'string'
          ? JSON.parse(user.user_attributes)
          : user.user_attributes
    };
  }
  return user;
};
export default parseUser;
