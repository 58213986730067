import React, { useState, useEffect } from 'react';
import config from '../../config';
import { Grid, useMediaQuery } from '@mui/material';
import ReactPDFFallback from './ReactPDFFallback';
import { useTheme } from '@emotion/react';

//import { Storage } from 'aws-amplify';

const PDFviewer = ({ path }) => {
  const [url, setUrl] = useState();
  const mobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));


  useEffect(() => {
    if (path) {
      console.log(path)
      // const getUrl = async () => {
      //   const signed = await Storage.get(`${path}`);
      //   setUrl(signed);
      // };
      // getUrl();
      // return () => {};
      setUrl(`https://${config.s3.BUCKET}.s3.${config.s3.REGION}.amazonaws.com/public/${path}`)
    }

  }, [path, setUrl]);

  console.log(url);
  return (
    <>
      {url && (
        <>
          {mobile ?
            <ReactPDFFallback url={url} />
            :
            <object
              data={url}
              type="application/pdf"
              width="100%"
              height="100%"
            >
              <ReactPDFFallback url={url} />
            </object>
          }
        </>  
      )}
    </>
  );
};

export default PDFviewer;
