import { Button, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useMemo, useRef } from 'react';
import PageHeader from '../PageHeader';
import PageContent from '../PageContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileEdit, faCloudUpload } from '@fortawesome/pro-light-svg-icons';
import { buttonStyles } from '../../styles/buttonStyles';
import { useState } from 'react';
import AddResourceDialog from './AddResourceDialog';
import { useContext } from 'react';
import { UserContext } from '../../store/UserContext';
import axiosRequest from '../../helpers/axiosRequest';
import useAsync from '../../Hooks/useAsync';
import CategoriesDropdown from './CategoriesDropdown';
import FileTypes from './FileTypes';
import FileCard from './FileCard';
import sortResources from '../../helpers/sortResources';
import { memo } from 'react';
import setLanguageText from '../../helpers/setLanguageText';
import { LayoutContext } from '../../store/LayoutContext';

const useStyles = makeStyles(theme => ({
  file_cards: {
    paddingTop: theme.spacing(2)
  }
}));

const Resources = memo(() => {
  //******************* STYLES *******************
  const buttonStyle = buttonStyles();
  const classes = useStyles();
  const input_ref = useRef(null);
  const { user, authUser } = useContext(UserContext);
  const { language } = useContext(LayoutContext);
  const [resource, setResource] = useState({});
  const [isAddResource, setIsAddResource] = useState(false);
  const [resources, setResources] = useState([]);
  //******************* FILTERS *******************
  const [selectedCategory, setSelectedCategory] = useState({});
  const [fileTypeValue, setFileTypeValue] = useState(0);
  const [sortValue, setSortValue] = useState(1);

  //******************* USE ASYNC *******************
  const { data: DBCategories, executeAsync: getCategories } = useAsync({
    axiosRequest: axiosRequest
  });
  const { data: DBResources, executeAsync: getResources } = useAsync({
    axiosRequest: axiosRequest
  });

  useEffect(() => {
    getCategories({
      endpoint: 'resource/categories',
      api_key: authUser.api_key,
      method: 'get'
    });
    getResources({
      endpoint: 'resource',
      api_key: authUser.api_key,
      method: 'get'
    }).then(data => {
      setResources(data);
    });
  }, [authUser.api_key, getCategories, getResources]);

  useEffect(() => {
    if (DBResources) {
        //filter by category
        let filtered  = DBResources
        if(selectedCategory.resource_cat_id > 0){
          filtered = DBResources.filter(
            resource => resource.resource_cat === selectedCategory.resource_cat_id
          );
        } 
        // If selected a file type
        if (fileTypeValue > 0) {
          const type_value =
          fileTypeValue === 1 ? 'application/pdf' : 'video/mp4';
          //console.log(type_value)
          filtered = filtered.filter(
            resource =>
              resource.resource_type === type_value
          );
        } 
        // if (!sortValue)
        //   filtered.sort(
        //     (a, b) =>
        //       new Date(a.resource_upload_time) -
        //       new Date(b.resource_upload_time)
        // );
        return setResources(filtered);

    }
  }, [DBResources, fileTypeValue, selectedCategory.resource_cat_id, sortValue]);

  // useEffect(() => {
  //   if (DBResources) {
  //     if (!selectedCategory.resource_cat_id && !fileTypeValue) {
  //       if (!sortValue) {
  //         setResources(prevState =>
  //           prevState.sort(
  //             (a, b) =>
  //               new Date(a.resource_upload_time) -
  //               new Date(b.resource_upload_time)
  //           )
  //         );
  //       } else setResources(DBResources);
  //     }
  //   }
  // }, [
  //   DBResources,
  //   fileTypeValue,
  //   resources,
  //   selectedCategory.resource_cat_id,
  //   sortValue
  // ]);
  //console.log(resources);
  
  //******************* FUNCTIONS *******************
  const openAddResource = () => setIsAddResource(true);
  const closeAddResource = () => setIsAddResource(false);

  const groupCategories = useMemo(
    () =>
      DBCategories
        ? DBCategories.reduce(
            (acc, cur) => {
              if (!cur.resource_cat_parent_id) {
                const cur_sub_cats = DBCategories.filter(
                  category =>
                    category.resource_cat_parent_id === cur.resource_cat_id
                );
                const add_sub_cat = { ...cur, sub_categories: cur_sub_cats };
                return [...acc, add_sub_cat];
              }
              return acc;
            },
            [
              {
                resource_cat_id: 0,
                resource_cat_parent_id: 0,
                resource_cat_name: 'ALL'
              }
            ]
          )
        : [],
    [DBCategories]
  );

  const uploadFile = e => {
    e.persist();
    if (e.target.files.length) {
      const { type, name } = e.target.files[0];
      // Remove file extension from name
      const file_name = name.split('.')[0];
      const file_ext = name.split('.')[name.split('.').length - 1];
      setResource(prevState => ({
        ...prevState,
        resource_to_send: {
          resource_type: type,
          resource_description: '',
          resource_file_name: file_name
        },
        file: e.target.files[0],
        file_ext
      }));
      setIsAddResource(true);
    }
  };

  return (
    <>
      <PageHeader alignItems="stretch" justifyContent="space-between">
        <Grid item style={{ alignSelf: 'center' }}>
          <Typography variant="h5">{setLanguageText(language,"Resources")}</Typography>
        </Grid>
        {user.level === 9 && (
          <Grid item>
            {!resource.file ? (
              <Button
                fullWidth
                variant="contained"
                style={{ height: '100%' }}
                component="label"
                color="primary"
              >
                <FontAwesomeIcon icon={faCloudUpload} size="lg" />
                <Typography className={buttonStyle.buttonIconText}>
                {setLanguageText(language,"Upload Resource")}
                </Typography>
                <input
                  type="file"
                  accept=".pdf, video/*"
                  onChange={uploadFile}
                  ref={input_ref}
                  hidden
                />
              </Button>
            ) : (
              <Button
                fullWidth
                variant="contained"
                style={{ height: '100%' }}
                component="label"
                color="primary"
                onClick={openAddResource}
              >
                <FontAwesomeIcon icon={faFileEdit} size="lg" />
                <Typography className={buttonStyle.buttonIconText}>
                {setLanguageText(language,"Edit Resource")}
                </Typography>
              </Button>
            )}
          </Grid>
        )}
      </PageHeader>
      <PageContent>
        <Grid container>
          <Grid
            container
            spacing={1}
            alignItems="stretch"
            justifyContent="space-between"
          >
            {/* CATEGORIES */}
            <Grid item xs={12} md={5}>
              <CategoriesDropdown
                groupCategories={groupCategories}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
              />
            </Grid>
            {/* FILE TYPES */}
            <FileTypes
              setResources={setResources}
              sortValue={sortValue}
              setSortValue={setSortValue}
              fileTypeValue={fileTypeValue}
              setFileTypeValue={setFileTypeValue}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          className={classes.file_cards}
          alignItems="stretch"
        >
          {resources.map(resource => (
            <FileCard
              resource={resource}
              key={resource.resource_id}
              getResources={getResources}
            />
          ))}
        </Grid>
      </PageContent>
      <AddResourceDialog
        isAddResource={isAddResource}
        closeAddResource={closeAddResource}
        groupCategories={groupCategories}
        resource={resource}
        getResources={getResources}
        setResource={setResource}
        input_ref={input_ref}
        uploadFile={uploadFile}
      />
    </>
  );
});

export default Resources;
