import React, { useContext, useEffect } from 'react';
import { Button,Typography } from '@mui/material';
import { buttonStyles } from '../../../styles/buttonStyles';
import {
  IS_OUT_OF_CONTROL,
  OUT_OF_CONTROL
} from '../../../store/MachineReducers';
import { MachineContext } from '../../../store/MachineContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import { LayoutContext } from '../../../store/LayoutContext';
import setLanguageText from '../../../helpers/setLanguageText';

const OutOfControl = () => {
  const {
    dispatchIsOutOfControl,
    dispatchOutOfControl,
    isOutOfControl,
    filteredMachines,
    outOfControl
  } = useContext(MachineContext);
  const { language } = useContext(LayoutContext);

  useEffect(() => {
    // set out of control button to false
    dispatchIsOutOfControl({
      type: IS_OUT_OF_CONTROL,
      payload: false
    });
    // render
    dispatchOutOfControl({
      type: OUT_OF_CONTROL,
      payload: filteredMachines.filter(machine => machine.last_entry.out_of_control)
    });
  }, [dispatchIsOutOfControl, dispatchOutOfControl, filteredMachines]);

  const toggleOutOfControl = () => {
    dispatchIsOutOfControl({
      type: IS_OUT_OF_CONTROL,
      payload: !isOutOfControl
    });
  };

  const buttonStyle = buttonStyles();

  return (
    <Button
      variant="contained"
      onClick={toggleOutOfControl}
      //color={isOutOfControl ? 'primary' : 'inherit'}
      //color={'secondary'}
      className={isOutOfControl ? `${buttonStyle.success} ${buttonStyle.button}` :  `${buttonStyle.danger} ${buttonStyle.button}`}
    >
      <FontAwesomeIcon icon={faExclamationTriangle} size="lg" />
        <Typography variant="caption" className={buttonStyle.buttonIconText}>
        {!isOutOfControl ? `${setLanguageText(language,"Out Of Control")} (${outOfControl.length})` : `${setLanguageText(language,"Show All")} (${filteredMachines.length}) `}
        </Typography>
    </Button>
  );
};

export default OutOfControl;
