import React, { useEffect, useState, useContext, Fragment } from 'react';
import { machineQRCodeCreater } from '../../helpers/machineQRCodeCreater';
import config from '../../config';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image
  //PDFDownloadLink,
} from '@react-pdf/renderer';
import formatDate from '../../helpers/formatDate';
import calcDaysPast from '../../helpers/calcDaysPast';
import setLanguageText from '../../helpers/setLanguageText';

const SingleMachinePDF = ({ company, machine, charts, orientation, language }) => {

  console.log(charts)
  const [machineQrCode, setMachineQrCode] = useState();

  // Create styles
  const styles = StyleSheet.create({
    page: {
      padding: 10
    },
    body: {
      height: '80vh'
    },
    header: {
      width: '100%',
      textAlign: 'center',
      height: '5vh'
    },
    header_title: {
      fontSize: 14,
      fontWeight: 'bold'
    },
    text_container: {
      padding: 10
    },
    text: {
      fontSize: 9,
      marginBottom: 5,
      fontWeight: 'normal'
    },
    bold: {
      fontSize: 9,
      fontWeight: 'bold'
    },
    title: {
      fontSize: 14,
      marginTop: 10,
      marginBottom: 10,
      fontWeight: 'normal'
    },
    chart_title: {
      fontSize: 10,
      fontWeight: 'bold',
      marginBottom: 5,
      width: '100%',
      textAlign: 'center'
    },
    logo_contanier: {
      width: '100%',
      height: '120px',
    },
    logo: {
      width: '100%',
      height: '100%',
      objectFit: 'contain'
    },
    machineQr: {
      width: '100%',
      height: '200px',
    },
    qr: {
      width: '100%',
      height: '100%',
      objectFit: 'contain'
    },
    chart: {
      height: '33.3333%',
      width: 'auto',
      //padding: 10
    },
    column_container: {
      width: '100%',
      flexDirection: 'row'
      //alignItems: 'stretch'
    },
    column_1: {
      width: '20%',
    },
    column_2: {
      width: '40%'
      //padding: 10
    },
    column_3: {
      width: '40%'
      //padding: 10
    },
    footer: {
      textAlign: 'center',
      height: '5vh',
      position: 'absolute',
      fontSize: 12,
      bottom: 0,
      left: 0,
      right: 0,
    },
    footer_text: {
      fontSize: 8,
      marginTop: 5,
      marginBottom: 5,
      fontWeight: 'normal'
    }
  });

  useEffect(() => {
    if (machine) {
      let url = machineQRCodeCreater({
        company_id: machine.company_id,
        machine_id: machine.machine_id,
        instance: config.INSTANCE
      });
      setMachineQrCode(url);
    }
  }, [machine]);

  const renderCharts = charts => {
    //NEED TO HARD CODE THE LAYOUT FOT NOW
    return (
      <Fragment>
        <View style={styles.column_2}>
          {charts?.conc_base64 &&

            <View style={styles.chart} wrap={false}>
              <Text style={styles.chart_title}>{setLanguageText(language, "Concentration levels")}</Text>
              <Image src={charts.conc_base64} allowDangerousPaths />
            </View>
          }
          {charts?.ph_base64 &&
            <View style={styles.chart} wrap={false}>
              <Text style={styles.chart_title}>{setLanguageText(language, "PH levels")}</Text>
              <Image src={charts.ph_base64} allowDangerousPaths />
            </View>
          }
          {charts?.fungi_base64 &&
            <View style={styles.chart} wrap={false}>
              <Text style={styles.chart_title}>{setLanguageText(language, "Fungi levels")}</Text>
              <Image src={charts.fungi_base64} allowDangerousPaths />
            </View>
          }
        </View>
        <View style={styles.column_3}>

          {charts?.bacteria_base64 &&
            <View style={styles.chart} wrap={false}>
              <Text style={styles.chart_title}>{setLanguageText(language, "Bacteria levels")}</Text>
              <Image src={charts.bacteria_base64} allowDangerousPaths />
            </View>
          }
          {charts?.tramp_base64 &&
            <View style={styles.chart} wrap={false}>
              <Text style={styles.chart_title}>{setLanguageText(language, "Tramp oil levels")}</Text>
              <Image src={charts.tramp_base64} allowDangerousPaths />
            </View>
          }
        </View>
      </Fragment>
    );
    //LOOP
    // return Object.values(charts).map((image,i) => {
    //     return <View key={i} style={styles.chart}>
    //             <Image
    //             src={image}
    //             allowDangerousPaths
    //         />
    //     </View>
    // })
  };

  return (

    <Document title={`${machine.machine_name} Export`}>
      <Page style={styles.page} orientation={'landscape'}>
        <View fixed style={styles.header}>
          <Text style={styles.header_title}>{company.company_name} / {machine.machine_group} / {machine.machine_name}</Text>
        </View>
        <View style={styles.body}>
          <View style={styles.column_container}>
            <View style={styles.column_1}>
              <View style={styles.company}>
                <View style={styles.logo_contanier}>
                   <Image style={styles.logo}
                    src={`https://${config.s3.BUCKET}.s3.${config.s3.REGION}.amazonaws.com/public/company/${company?.company_logo_path}`}
                  /> 
                </View>
              </View>
              <View style={styles.machineQr}>
                {machineQrCode ? <Image style={styles.qr} src={machineQrCode} /> : null}
              </View>
              <View style={styles.text_container}>
                <View>
                  <Text style={styles.title}>{setLanguageText(language, "Last Machine Check")}</Text>
                  <Text style={styles.text}>
                    {formatDate(machine.last_entry.date_stamp)} - {machine.name}
                  </Text>
                  <Text style={styles.text}>
                    {' '}
                    {calcDaysPast(machine.last_entry.date_stamp, language, setLanguageText)}*
                  </Text>
                </View>
                <View>
                  <Text style={styles.title}>{setLanguageText(language, "Coolant Details")}</Text>
                  <Text style={styles.text}>
                    {setLanguageText(language, "Name")} : {machine.coolant_name}
                  </Text>
                  <Text style={styles.text}>
                    {setLanguageText(language, "Manufacturer")}:{' '}
                    {machine?.coolant_defaults?.coolant.manufacturer}
                  </Text>
                  <Text style={styles.text}>
                    {setLanguageText(language, "Last Coolant Change")}:{' '}
                    {formatDate(machine.last_coolant_change)}
                  </Text>
                  {machine.last_coolant_change &&
                    <Text style={styles.text}>
                      {calcDaysPast(machine.last_coolant_change, language, setLanguageText)}*
                    </Text>
                  }
                </View>
                <View>
                  <Text style={styles.title}>{setLanguageText(language, "Machine Settings")}</Text>
                  <Text style={styles.text}>
                    <Text style={styles.bold}>{setLanguageText(language, "Sump Size")}: </Text>
                    <Text>
                      {machine.machine_settings.machine_defaults.sump}
                    </Text>
                  </Text>
                  <Text style={styles.text}>
                    Refractometer Factor:{' '}
                    {machine.machine_settings.machine_defaults.factor ? machine.machine_settings.machine_defaults.factor : machine.machine_settings.machine_defaults.refractomer_factor}
                    {'\n'}
                  </Text>
                  <Text style={styles.text}>
                    {setLanguageText(language, "PH Min")}:{' '}
                    {machine.machine_settings.machine_defaults.ph_min}
                    {'\n'}
                  </Text>
                  <Text style={styles.text}>
                    {setLanguageText(language, "PH Max")}:{' '}
                    {machine.machine_settings.machine_defaults.ph_max}
                    {'\n'}
                  </Text>
                  <Text style={styles.text}>
                    {setLanguageText(language, "Conc Min")}:{' '}
                    {machine.machine_settings.machine_defaults.op_con_min}
                    {'\n'}
                  </Text>
                  <Text style={styles.text}>
                    {setLanguageText(language, "Conc Max")}:{' '}
                    {machine.machine_settings.machine_defaults.op_con_max}
                    {'\n'}
                  </Text>
                </View>
              </View>
            </View>
            {charts && renderCharts(charts)}
          </View>
        </View>
        <View style={styles.footer}>
          <Text style={styles.footer_text}>
            * {setLanguageText(language, "Since date of report printed")}
            {formatDate(Date.now())} - {config.NAME} {setLanguageText(language, "PDF Export")}
          </Text>
        </View>
      </Page>
    </Document>

  );
};

export default SingleMachinePDF;
