import { Card, CardContent, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import React, { useContext } from 'react';
import { Bar } from 'react-chartjs-2';
import setLanguageText from '../../../helpers/setLanguageText';
import { LayoutContext } from '../../../store/LayoutContext';
import CardStyles from '../../../styles/cardStyles';

function BarChart({ results }) {
  const { language } = useContext(LayoutContext)
  //console.log(results);
  // const labels = horizontal_labels.map(option => option.output);
  // let index = results.findIndex(item => item.id === request);
  const cardStyles = CardStyles();
  const theme = useTheme();
  const data = {
    datasets: [
      {
        data:
          results.data.map((item) =>
            item.count
          ),
        backgroundColor:
          results.data.map((item) =>
            item.colour
          )

      }
    ],
    labels:
      results.data.map((item) =>
        item.label
      )
  };

  const options = {
    legend: {
      display: false
    },
    title: {
      display: false
    },
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true,
          steps: 1,
          stepValue: 1
        }
      }]
    }

  };

return (
  <Card style={{ height: '100%' }}>
    <Grid
      item
      container
      className={cardStyles.header}
      justifyContent="space-between"
    >
      <Typography variant="h5">{results.name}</Typography>
    </Grid>
    <CardContent>
      <Bar data={data} options={options} />
    </CardContent>
    <Grid
      item
      container
      justifyContent="space-between"
      className={cardStyles.footer}
    >
      <Grid item container xs={4} justifyContent="center">
  
        <Typography variant="body2">{setLanguageText(language,"Total Entries")}:</Typography>
        <Typography
          variant="body2"
          align="center"
          style={{ marginLeft: theme.spacing(1) }}
        >
          <strong>
          {results.data.map((item) => item.count).reduce((a, b) => a + b, 0)}
          </strong>
        </Typography>
   
      </Grid>
    </Grid>
  </Card>
);

}

export default BarChart;
