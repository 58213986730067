import React from 'react';
import { Alert } from "@mui/material";
import setLanguageText from "./setLanguageText";


const calcOverdueCoolant = (date ,check_freq, language) => {

    if (date) {
      const today = new Date();
      const dateFrom = new Date(date);
      const time_difference = today.getTime() - dateFrom.getTime();
      const day_difference = time_difference / (1000 * 60 * 60 * 24);
      const days = parseInt(day_difference);
  

      if(days > check_freq) {
            return <Alert severity="error">{setLanguageText(language,"Change overdue by")} {days - check_freq } {setLanguageText(language,"day(s)")}</Alert>
      } else if (days > check_freq -10 ) {
            return <Alert severity="warning">{setLanguageText(language,"Cleanout due in")} {check_freq - days }{setLanguageText(language,"day(s)")}</Alert>
      }
    }
  };
  
  export default calcOverdueCoolant;
  