// import { user_attributes } from '../themes/theme_1';

export const AUTH_USER = 'AUTH_USER';
export const GET_USERS = 'GET_USERS';
export const CHANGE_THEME_ACTIVE = 'CHANGE_THEME_ACTIVE';
// export const CHANGE_DASHBOARD_SETTING = 'CHANGE_DASHBOARD_SETTING';
// export const CHANGE_MACHINE_VIEW_SETTING = 'CHANGE_MACHINE_VIEW_SETTING';
// export const CHANGE_USER_LEVEL = 'CHANGE_USER_LEVEL';

export const AuthReducer = (state, action) => {
  switch (action.type) {
    case AUTH_USER:
      return {
        api_key: action.payload.api_key,
        isAuth: action.payload.isAuth
      };
    default:
      return state;
  }
};

export const UserReducer = (state, action) => {
  switch (action.type) {
    case GET_USERS:
      return action.payload;
    case CHANGE_THEME_ACTIVE:
      return {
        ...state,
        theme_active: action.payload
      };
    // case CHANGE_USER_LEVEL:
    //   return {
    //     ...state,
    //     theme: action.payload
    //   };
    // // return state;
    // case CHANGE_DASHBOARD_SETTING:
    //   return {
    //     ...state,
    //     user_attributes: {
    //       ...state.user_attributes,
    //       settings: {
    //         ...state.user_attributes.settings,
    //         dashboard: action.payload
    //       }
    //     }
    //   };
    // case CHANGE_MACHINE_VIEW_SETTING:
    //   return {
    //     ...state,
    //     user_attributes: {
    //       ...state.user_attributes,
    //       settings: {
    //         ...state.user_attributes.settings,
    //         machine_view: {
    //           ...state.user_attributes.settings.machine_view,
    //           [action.payload.name]: action.payload.checked
    //         }
    //       }
    //     }
    //   };

    default:
      return state;
  }
};
