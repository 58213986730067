import { TextField } from '@mui/material';
import { Alert, Autocomplete } from '@mui/material';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import axiosRequest from '../../helpers/axiosRequest';
import setLanguageText from '../../helpers/setLanguageText';
import useAsync from '../../Hooks/useAsync';
import { UserContext } from '../../store/UserContext';
import { LayoutContext } from '../../store/LayoutContext';

const DropdownUsers = ({ setSelected, selectedCompany }) => {
  const { authUser, user:user_context } = useContext(UserContext);
  const { language } = useContext(LayoutContext); 
  const [allUsers, setAllUsers] = useState([]);
  const [user, setUser] = useState(null);
  const { error, data, loading, executeAsync } = useAsync({
    axiosRequest: axiosRequest
  });

  useEffect(() => {
    //console.log(selectedCompany)
    if (selectedCompany) {
      //if selected a specific company, get company's users, else don't pass a company_id and will get ALL users
      let params
      //console.log(selectedCompany.company_id)
      if(selectedCompany.company_id){
        params= {
          company_id: selectedCompany.company_id 
        }
      } else {
        params= {
          user_level: user_context.level,
          company_ids: JSON.stringify(user_context.companies_id) 
        }
      }
      //console.log(user_context)
      executeAsync({
        method: 'get',
        api_key: authUser.api_key,
        endpoint: 'users',
        params: params
      });
    }
  }, [authUser, user_context, executeAsync, selectedCompany]);

  useEffect(() => {
    if (data) {
      const addAllOption = [{ name: `${setLanguageText(language,'All Users')} (${data.length > 0 ? data.length : 0})` }, ...data];
      setAllUsers(addAllOption);
      //pass selected to parent
      setSelected(addAllOption[0]);
      setUser(addAllOption[0]);
    }
  }, [data, setSelected]);

  const onUserSelect = (e, type) => {
    //pass selected to parent
    setSelected(type);
    setUser(type);
  };

  //add users counter
  // const modifiedValue = useMemo(() => {
  //   if (value)
  //     return !value.id
  //       ? {
  //           ...value,
  //           name: `${value.name} (${users.length - 1})`
  //         }
  //       : value;
  //   return {};
  // }, [users, value]);
  if (error) return <Alert severity="error">{error.message}</Alert>;

  return (
    <Autocomplete
      disableClearable
      options={allUsers}
      loading={loading}
      getOptionLabel={option => option.name || ''}
      renderInput={params => (
        <TextField {...params} required label={setLanguageText(language,"Users")} variant="outlined" />
      )}
      value={user}
      onChange={onUserSelect}
    />
  );
};

export default DropdownUsers;
