import React, { useContext, useEffect } from 'react';
import { Grid, TextField } from '@mui/material';
import DropdownCompanies from '../reusable/DropdownCompanies';
import DropdownGroups from '../reusable/DropdownGroups';
import {
  SAVE_MACHINE_NAME,
  SAVE_GROUP_NAME,
  SAVE_MACHINES
} from '../../store/SelectionsReducers';
import { SelectionsContext } from '../../store/SelectionsContext';
import { UserContext } from '../../store/UserContext';
import { MachineContext } from '../../store/MachineContext';
import axiosRequest from '../../helpers/axiosRequest';
import useAsync from '../../Hooks/useAsync';
import GlobalDialog from '../reusable/GlobalDialog';
import { fetchMachines } from '../../helpers/fetchMachines';
import setLanguageText from '../../helpers/setLanguageText';
import { LayoutContext } from '../../store/LayoutContext';

const AddMachineDialog = ({ isDialog, setIsDialog }) => {
  const { authUser, user } = useContext(UserContext);
  const { dispatchMachines } = useContext(MachineContext);
  const { language } = useContext(LayoutContext);
  const { dispatchAddNewMachine, addNewMachine } = useContext(
    SelectionsContext
  );
  const {
    error,
    data,
    loading,
    logCompleted,
    logError,
    executeAsync,
    executeLog
  } = useAsync({
    axiosRequest: axiosRequest
  });

  const closeModal = () => setIsDialog();

  //***************** USE EFFECTS *****************/
  // Reload Page when also Log Activity is done
  useEffect(() => {
    if (data) {
      // TODO handle if activity error
      if (logError) console.log('Something went wrong with the Activity Log');
      if (logCompleted) {
        // Reload page
        // setTimeout(() => {
        //   window.location.reload();
        // }, 2000);
        //TODO - PUSH MACHINE TO CONTEXT IF ON THE MACHINES PAGE
      }
    }
  }, [data, logCompleted, logError]);

  const handleSubmit = async e => {
    e.preventDefault();
    // Add Machine
    const response = await executeAsync({
      api_key: authUser.api_key,
      method: 'post',
      endpoint: 'machines',
      body: {
        machine_name: addNewMachine.machine_name,
        machine_group: addNewMachine.machine_group,
        company_id: addNewMachine.company.company_id
      }
    });

    if (response) {
      await executeLog({
        api_key: authUser.api_key,
        endpoint: 'activity',
        method: 'post',
        body: {
          attributes: {
            type: 'New Machine',
            user_id: user.id,
            machine_id: response.insertId,
            machine_name: addNewMachine.machine_name,
            machine_group: addNewMachine.machine_group,
            company_id: addNewMachine.company.company_id
          }
        }
      });
      setTimeout(() => {
        fetchMachines(
          authUser.api_key,
          true,
          addNewMachine.company.company_id
        ).then(data => {
          dispatchMachines({ type: SAVE_MACHINES, payload: data });
          closeModal();
        });
      }, 1000);
    }
  };

  return (
    <GlobalDialog
      open={isDialog}
      closeModal={closeModal}
      title="Add New Machine"
      handleSubmit={handleSubmit}
      buttonTitle="Add Machine"
      successMessage="Machine Added Successfully!"
      loading={loading || data}
      error={error}
      data={data}
      fullWidth
      maxWidth="sm"
    >
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <DropdownCompanies position="machine" />
        </Grid>
        <Grid item>
          <TextField
            required
            id="machine-name"
            label={setLanguageText(language,"Machine Name")}
            variant="outlined"
            fullWidth
            onChange={e =>
              dispatchAddNewMachine({
                type: SAVE_MACHINE_NAME,
                payload: e.target.value
              })
            }
          />
        </Grid>
        <Grid item>
          <TextField
            required
            id="machine-group"
            label={setLanguageText(language,"Create New Machine Group")}
            variant="outlined"
            fullWidth
            onChange={e => {
              dispatchAddNewMachine({
                type: SAVE_GROUP_NAME,
                payload: e.target.value
              });
            }}
            value={addNewMachine.machine_group}
          />
        </Grid>
        <Grid item>
          <DropdownGroups menuDirection="top" />
        </Grid>
      </Grid>
    </GlobalDialog>
  );
};
export default AddMachineDialog;
