import React, { useContext, useEffect, useState, useCallback } from 'react';
import { UserContext } from '../../../store/UserContext';
import { CompanyContext } from '../../../store/CompanyContext';
import {
  SELECTED_COMPANY,
  SAVE_COMPANIES
} from '../../../store/CompanyReducers';
import useAsync from '../../../Hooks/useAsync';
import axiosRequest from '../../../helpers/axiosRequest';
import { Snackbar } from '@mui/material';
import { Alert } from '@mui/material';
import { LayoutContext } from '../../../store/LayoutContext';
import { COMPANY_THEME } from '../../../store/LayoutReducers';
import addAllOption from '../../../helpers/addAllOption'

const SelectionRow = ({ children }) => {
  const { authUser, user } = useContext(UserContext);
  const { dispatchTheme, language } = useContext(LayoutContext);
  const {
    companies,
    dispatchCompanies,
    dispatchSelectedCompany,
    selectedCompany
  } = useContext(CompanyContext);

  const { error, data, executeAsync } = useAsync({
    axiosRequest: axiosRequest
  });

  const { 
    executeAsync : executeAsync_company
   } = useAsync({
    axiosRequest: axiosRequest
  });

  const [storedCompany] = useState( 
    JSON.parse(window.sessionStorage.getItem('selectedCompany'))
  );

  const closeRequestResult = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
  };

  useEffect(() => {
    if (!companies.length) {
      //console.log('get companies')
      executeAsync({
        api_key: authUser.api_key,
        method: 'get',
        endpoint: 'companies',
        params: {
          all : user.level === 9 ? true : false,
          user_level: user.level,
          companies_id: user.companies_id
        }
      });
    }
  }, [
    authUser.api_key,
    companies.length,
    executeAsync,
    user.companies_id,
    user.level
  ]);

  const getSelectedCompany = useCallback(async () => {

    // already stroed the the local session storage
    //console.log(storedCompany)
    if (storedCompany) {
      dispatchSelectedCompany({
        type: SELECTED_COMPANY,
        payload: storedCompany
      });
    } else {
      //fetch users company and set as default selected
      let result = await executeAsync_company({
        api_key: authUser.api_key,
        method: 'get',
        endpoint: 'companies',
        params: {
          user_level: user.level,
          id: user.company_id
        }
      });
      dispatchSelectedCompany({
        type: SELECTED_COMPANY,
        payload: result[0]
      });
      window.sessionStorage.setItem('selectedCompany',JSON.stringify(result[0]));
    }
    //window.sessionStorage.setItem('selectedCompany', JSON.stringify(setCompany));

  }, [
    executeAsync_company,
    dispatchSelectedCompany,
    storedCompany,
    user,
    authUser
  ])

  useEffect(() => {
    if (data && data.length) {
      //console.log('set selected')

      //chack user company
      //console.log(user_company)
      dispatchCompanies({ type: SAVE_COMPANIES, payload: addAllOption(data,language)});
      getSelectedCompany()
      // dispatchSelectedCompany({
      //   type: SELECTED_COMPANY,
      //   // As default use Company saved in session storage, else if first company is "ALL" use the one after, else use user company
      //   payload: storedCompany || user_company || data[0]
      // });

      //set on first load, if not stored company
      // if(!storedCompany){
      //   window.sessionStorage.setItem('selectedCompany',JSON.stringify(user_company || data[0]));
      // }
    }
  }, [
    data,
    dispatchCompanies,
    getSelectedCompany
  ]);



  //update theme
  useEffect(() => {
    if (selectedCompany) {
      dispatchTheme({
        type: COMPANY_THEME,
        payload: selectedCompany.theme_settings
      });
    }
  }, [dispatchTheme, selectedCompany]);

  //if (loading) return <CircularProgress />;
  // if (error)
  //   return (
  //     <Snackbar
  //       open={Boolean(error)}
  //       autoHideDuration={6000}
  //       onClose={closeRequestResult}
  //     >
  //       <Alert
  //         onClose={closeRequestResult}
  //         severity="error"
  //         elevation={6}
  //         variant="standard"
  //       >
  //         {error.message}
  //       </Alert>
  //     </Snackbar>
  //   );

  return <React.Fragment>{children}</React.Fragment>;
};

export default SelectionRow;
