import React, { useContext, useState, useMemo } from 'react';
import { SelectionsContext } from '../../store/SelectionsContext';
import BacteriaFungi from '../reusable/BacteriaFungi';
import {
    Grid
} from '@mui/material';

function BacteriaFungiEntries({ setError, edit }) {

    //****************** CONTEXT ******************
    const { addNewEntry } = useContext(SelectionsContext);

    //****************** STATE ******************
    const [attributes, setAttributes] = useState([]);

    //****************** USE EFFECT ******************

    //Get Bacteria and Fungi and set them to "Not Needed" status/value
    useMemo(() => {
        //console.log(addNewEntry.company.company_attributes)
        const filteredAttributes = addNewEntry.company.company_attributes.filter(
            attr => attr.one.attr_id === 'bacteria' || attr.one.attr_id === 'fungi'
        );
        //console.log(filteredAttributes)
        setAttributes(filteredAttributes);
    }, [addNewEntry.company.company_attributes]);

    //****************** FUNCTIONS ******************

    return (
        <Grid item container spacing={2}>
            { attributes && attributes.map((attr, i) => {
                const attribute = addNewEntry.entry.attributes_new.find(
                    contextAttr => contextAttr.attribute_id === attr.one.attr_id
                )
                return (
                    <Grid item xs={12 } key={i}>
                        <BacteriaFungi
                            key={attr.one.attr_id}
                            attr={attr}
                            //dispatchFn={dispatchFnCallback}
                            company={addNewEntry.company}
                            machine={addNewEntry.machine}
                            // If attribute in addNewEntry use its value else
                            initialValue={attribute?.attribute_values.value}
                            setError={setError}
                            edit={edit}
                        />
                    </Grid>
                )
            })}
        </Grid>
    )
}

export default BacteriaFungiEntries;