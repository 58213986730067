import { faTimes, faEdit, faCheck } from '@fortawesome/pro-light-svg-icons';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  Grid
} from '@mui/material';
import React, { useContext, useState, Fragment, useEffect } from 'react';
import { buttonStyles } from '../../../styles/buttonStyles';
import PrimaryBtn from '../../buttons/PrimaryBtn';
import PrimaryBtnIcon from '../../buttons/PrimaryBtnIcon';
import axiosRequest from '../../../helpers/axiosRequest';
import { UserContext } from '../../../store/UserContext';
import { Alert } from '@mui/material';
import { MachineContext } from '../../../store/MachineContext';
import { SELECTED_MACHINE } from '../../../store/MachineReducers';
import useAsync from '../../../Hooks/useAsync';
import setLanguageText from '../../../helpers/setLanguageText';
import { LayoutContext } from '../../../store/LayoutContext';

function ResetMachine() {
  //****** CONTEXT ******
  const { authUser, user } = useContext(UserContext);
  const { language } = useContext(LayoutContext);
  const { dispatchSelectedMachine, selectedMachine } = useContext(
    MachineContext
  );

  //****** STATE ******
  const [open, setOpen] = useState(false);

  //****** STYLES ******
  const buttonStyle = buttonStyles();

  //****** FUNCTIONS ******

  const openPopover = () => {
    setOpen(true);
  };

  const {
    data,
    logError,
    logCompleted,
    executeAsync,
    executeLog,
    setData
  } = useAsync({
    axiosRequest: axiosRequest
  });

  const confirmReset = async e => {
    
    e.preventDefault();
    const response = await executeAsync({
      endpoint: 'machines',
      api_key: authUser.api_key,
      method: 'patch',
      body: {
        edit: 'reset',
        machine_id: selectedMachine.machine_id,
        company_id: selectedMachine.company_id
      }
    });
    if (response) {
      await executeLog({
        endpoint: 'activity',
        api_key: authUser.api_key,
        body: {
          attributes: {
            type: 'Machine Reset Settings',
            user_id: user.id,
            machine_id: selectedMachine.machine_id,
          }
        }
      });
    }
    // try {
    //   await axiosRequest({
    //     endpoint: 'machines',
    //     api_key: authUser.api_key,
    //     method: 'patch',
    //     body: {
    //       edit: 'reset',
    //       machine_id: selectedMachine.machine_id,
    //       company_id: selectedMachine.company_id
    //     }
    //   }).then(machine => {
    //     setOpen(false);
    //     //update selected machine
    //     const selected = {
    //       ...selectedMachine,
    //       machine_settings: JSON.parse(machine[0].machine_settings)
    //     };
    //     //console.log(selected)
    //     dispatchSelectedMachine({
    //       type: SELECTED_MACHINE,
    //       payload: selected
    //     });
    //   });
    // } catch (error) {
    //   throw error;
    // }
  };

  useEffect(() => {
    if (data) {
      // TODO handle if activity error
      if (logError) console.log('Something went wrong with the Activity Log');
      if (logCompleted) {
        //update selected machine
        const selected = {
          ...selectedMachine,
          machine_settings: JSON.parse(data[0].machine_settings)
        };
        //console.log(selected)
        dispatchSelectedMachine({
          type: SELECTED_MACHINE,
          payload: selected
        });
        setOpen(false);
        setData();
      }
    }
  }, [
    dispatchSelectedMachine,
    setData,
    data, 
    logCompleted, 
    logError
  ]);

  return (
    <Fragment>
      <PrimaryBtn
        icon={faEdit}
        text="Reset to defaults"
        className={buttonStyle.warning}
        onClick={openPopover}
      />
      <Dialog
        open={open}
        onClose={e => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="form-dialog-title">
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>{setLanguageText(language,"Reset Machine Defaults")}</Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Alert severity="error">
          {setLanguageText(language,"Reset this machine to the default settings?")}
          </Alert>
        </DialogContent>
        <DialogActions>
          <PrimaryBtnIcon
            icon={faCheck}
            text="Confirm Reset"
            //size="small"
            //iconSize="lg"
            //textVariant="caption"
            onClick={e => confirmReset(e)}
          />
          <PrimaryBtnIcon
            icon={faTimes}
            text="Cancel"
            //size="small"
            //iconSize="lg"
            //textVariant="caption"
            className={buttonStyle.danger}
            onClick={e => setOpen(false)}
          />
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default ResetMachine;
