const sortCoolants = (coolants, sortType) => {
  switch (sortType) {
    case 'most_used':
      return coolants.sort((a, b) =>
        a.machines_count < b.machines_count
          ? 1
          : a.machines_count > b.machines_count
          ? -1
          : 0
      );
    case 'asc':
      return coolants.sort((a, b) =>
        a.coolant_name < b.coolant_name
          ? -1
          : a.coolant_name > b.coolant_name
          ? 1
          : 0
      );
    case 'desc':
      return coolants.sort((a, b) =>
        a.coolant_name < b.coolant_name
          ? 1
          : a.coolant_name > b.coolant_name
          ? -1
          : 0
      );
    default:
      break;
  }
};

export default sortCoolants;
