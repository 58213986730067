import React, { useEffect, useState } from 'react';
import {
    Document,
    Image,
    Page,
    StyleSheet,
    Text,
    View,
    usePDF
} from '@react-pdf/renderer';
import formatDate from '../../helpers/formatDate';
import config from '../../config';
import ReactPDFFallback from './ReactPDFFallback';
import AWS from 'aws-sdk';

const ServiceReportPDF = ({
    orientation,
    pdf_data,
    setDownloadObject
}) => {



    const styles = StyleSheet.create({
        BODY: {
            paddingTop: 10,
            paddingBottom: 10,
            paddingHorizontal: 10
        },
        HEADER: {
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: '#efefef',
            maxHeight: '100px',
            marginBottom: 20,
            padding: 10,
            FONT: {
                marginBottom: 5,
                fontSize: 12
            },
            LOGO: {
                height: '100%',
                width: '200px'
            },
            DETAILS: {
                display: 'flex',
                justifyContent: 'center',
                paddingLeft: 30,
                flex: 1,
                TEXT: {
                    fontSize: 12,
                    lineHeight: 1.3
                },
                DATE: {
                    fontSize: 10
                }
            }
        },
        TABLE: {
            display: 'flex',
            width: 'auto',
            marginBottom: 20,
            borderTop: '1px solid #ccc',
            borderLeft: '1px solid #ccc',
            borderRight: '1px solid #ccc',
            borderBottom: 'none',
            HEADER: {
                padding: 5,
                display: 'flex',
                flexDirection: 'row',
                backgroundColor: '#efefef',
                color: '#222',
                borderBottom: '1px solid #ccc',
                CELL: {
                    padding: 5,
                    fontSize: 8
                }
            },
            ROW: {
                padding: 5,
                display: 'flex',
                flexDirection: 'row',
                backgroundColor: '#fff',
                color: '#222',
                borderBottom: '1px solid #ccc',
                CELL: {
                    padding: 5,
                    fontSize: 8,
                    textAlign: 'left',
                    overflow: 'hidden'
                },
            },
            ROW1: {
                padding: 5,
                display: 'flex',
                flexDirection: 'row',
                backgroundColor: '#fff',
                color: '#222',
            }
        },
        NOTES: {
            margin: 5,
            border: 1,
            fontSize: 8,
            lineHeight: 1.4,
            padding: 10,
            borderColor: '#ccc',
            borderWidth: '1px'
        },
        SERVICE_NOTES: {
            border: 1,
            fontSize: 8,
            padding: 10,
            borderColor: '#ccc',
            borderWidth: '1px'
        },
        SIGNATURES: {
            display: 'flex',
            flexDirection: 'row'
        },
        GAP: {
            width: 10
        },
        SIGNATURE: {
            flex: 1,
            fontSize: 10,
            HEADER: {
                marginBottom: 5,
                fontSize: 12
            },
            NAME: {
                marginTop: 5,
                fontSize: 10
            },
        },
        SIGNATURE_IMG: {
            border: '1px solid #ccc',
            width: 200,
            height: 100
        },
        logo_contanier: {
            width: '100%',
            height: '120px',
            overflow: 'hidden'
        },
        logo: {
            width: '100%',
            height: '100%',
            objectFit: 'contain'
        },
        notes_container: {
            display: 'flex',
            flexDirection: 'row',
            marginBottom: 20
        },
    });

    const create_pdf_headers = ({
        headers,
        styles
    }) => {
        return headers.map((header, i) => {
            if (header.id === "notes") {
                return
            }
            return (
                <View
                    key={i}
                    style={{ flex: 1 }}
                >
                    <Text style={styles.TABLE.HEADER.CELL}>{header.name}</Text>
                </View>
            )
        });
    }

    const create_pdf_rows = ({
        rows,
        styles
    }) => {
        //console.log(rows)
        return (
            <View>
                {rows.map((row, i) => {
                    //console.log(row)
                    let notes = null
                    return (
                        <View style={{ width: '100%', borderBottom: '1px solid #ccc', }} wrap={false} key={i} >
                            <View style={styles.TABLE.ROW1} >
                                {row.map((item, id) => {

                                    //console.log(item)
                                    if (item?.type === "notes") {
                                        notes = item
                                        return
                                    }
                                    return (
                                        <View
                                            key={id}
                                            style={{ flex: 1 }}
                                        >
                                            <Text style={styles.TABLE.ROW.CELL}>{item}</Text>
                                        </View>
                                    )
                                })}
                            </View>
                            {notes &&
                                <View style={[styles.NOTES, { backgroundColor: notes.action === 'N' ? '#f0f6f0' : notes.action === 'C' ? '#f9f0f0' : '#fdf6f1' }]} >
                                    <Text>Notes: {notes.value}</Text>
                                </View>
                            }
                        </View>
                    )
                }
                )}
            </View>
        );
    };

    const MyDoc = (
        <Document title="Service Pdf">
            <Page style={styles.BODY} orientation={`${orientation}`}>
                <View fixed style={styles.HEADER}>
                    <View style={{ display: 'flex' }}>
                        <View style={styles.logo_contanier}>
                            {pdf_data?.company.logo_path &&
                                <Image
                                    src={`https://${config.s3.BUCKET}.s3.${config.s3.REGION}.amazonaws.com/public/company/${pdf_data.company.logo_path}`}
                                    style={styles.logo}
                                />
                            }
                        </View>
                    </View>
                    <View style={styles.HEADER.DETAILS}>
                        <Text style={styles.HEADER.DETAILS.TEXT}>Service Report</Text>
                        <Text style={styles.HEADER.DETAILS.TEXT}>
                            {pdf_data.company.name}
                        </Text>
                        <Text style={styles.HEADER.DETAILS.TEXT}>
                            {pdf_data.service_name}
                        </Text>
                        <Text style={styles.HEADER.DETAILS.DATE}>{`${formatDate(
                            pdf_data.service_date
                        )} - ${pdf_data.service_attr.startTime} : ${pdf_data.service_attr.finishTime
                            }`}</Text>
                    </View>
                </View>

                <Text style={styles.HEADER.FONT} >Serviced Machines</Text>
                <View style={styles.TABLE}>
                    <View style={styles.TABLE.HEADER} fixed>
                        {create_pdf_headers({
                            headers: pdf_data.columns,
                            styles
                        })}
                    </View>
                    {create_pdf_rows({
                        rows: pdf_data.rows,
                        styles
                    })}
                </View>
                <View wrap={false} style={[styles.notes_container, {width: '100%' , height: !pdf_data.service_notes ? '1px' : 'auto'}]} >
                    {pdf_data.service_notes &&
                        <View style={{width: '100%'}} >
                            <Text style={styles.HEADER.FONT} >Service Notes</Text>
                            <View style={styles.SERVICE_NOTES}>
                                <Text> {pdf_data.service_notes && pdf_data.service_notes}</Text>
                            </View>
                        </View>
                    }
                </View>
                <View wrap={false} style={styles.SIGNATURES}>
                    <View style={styles.SIGNATURE}>
                        <Text style={styles.SIGNATURE.HEADER} >Service Signature</Text>
                        <View style={styles.SIGNATURE_IMG}>
                            {pdf_data.service_sign && <Image src={pdf_data.service_sign} />}
                        </View>
                        <Text style={styles.SIGNATURE.NAME} >Name: {pdf_data.service_name}</Text>
                    </View>
                    <View style={styles.GAP}>
                    </View>
                    <View style={styles.SIGNATURE}>
                        <Text style={styles.SIGNATURE.HEADER} >Customer Signature</Text>
                        <View style={styles.SIGNATURE_IMG}>
                            {pdf_data.cust_sign && <Image src={pdf_data.cust_sign} />}
                        </View>
                        <Text style={styles.SIGNATURE.NAME} >Name: {pdf_data.customer_name}</Text>
                    </View>
                </View>
            </Page>
        </Document>
    )

    const [instance, updateInstance] = usePDF({ document: MyDoc });

    useEffect(() => {

        if (instance.blob) {
            //console.log(instance)
            setDownloadObject(instance)
        }

    }, [instance, setDownloadObject])

    return (
        <>
            {instance.url ?
                <object
                    data={instance.url}
                    type="application/pdf"
                    height="100%"
                    width="100%"
                >

                    <ReactPDFFallback url={instance.url} />
                </object>
                :
                null
            }
        </>
    )

};

export default ServiceReportPDF;
