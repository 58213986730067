import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme
} from '@mui/material';
import React from 'react';
import {
  useFilters,
  usePagination,
  useRowState,
  useSortBy,
  useTable
} from 'react-table';
import Pagination from '../reusable/Pagination';

const ServiceTable = ({ data, columns }) => {
  //************************ STYLES ************************
  const theme = useTheme();

  //************************ TABLE CREATE FUNCTION ************************
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    // The rest of these things are super handy, too ;)
    allColumns,
    getToggleHideAllColumnsProps,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      // Table's Initial state
      initialState: {
        pageIndex: 0
      },
      // Row Initial state
      initialRowStateAccessor: () => ({
        email_open: false,
        email: '',
        input_ref: null,
        loading: false,
        error: false,
        // error_txt: '',
        success: false,
        // sendEmail: async note_settings =>
        //   notification_executeAsync({
        //     endpoint: 'notifications',
        //     api_key: authUser.api_key,
        //     method: 'post',
        //     body: {
        //       user_id: user.id,
        //       note_name: 'Email Service Report',
        //       note_type: 'service',
        //       note_frequency: {"check_freq": {"title": "Minutes", "value": "5"}},
        //       note_sent: 0,
        //       note_body: '',
        //       note_action: {"action": "No action needed", "description": "Send a service report PDF"},
        //       note_settings,
        //       note_next_send: new Date()
        //     }
        //   }),
        complete: false
      })
    },
    useFilters,
    useSortBy,
    usePagination,
    useRowState
  );

  return (
    <Card variant="outlined">
      <CardContent
        style={{
          overflowX: 'auto',
          maxHeight: '70vh',
          padding: '0'
        }}
      >
        <Table
          {...getTableProps()}
          className="machines--table"
          stickyHeader
          aria-label="sticky table"
        >
          {/* TABLE HEADER */}
          <TableHead className="machines--header" style={{ width: '100%' }}>
            {
              // Loop over the header rows
              headerGroups.map(headerGroup => (
                // Apply the header row props
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map(column => {
                      return (
                        // Apply the header cell props
                        <TableCell
                          {...column.getHeaderProps(
                            // add sort functionality
                            column.getSortByToggleProps()
                          )}
                          style={{
                            //textAlign: 'center',
                            backgroundColor: theme.palette.background.light,
                            padding: theme.spacing(1, 2),
                            width: column.width,
                            minWidth: column.minWidth,
                            maxWidth: column.maxWidth
                          }}
                        >
                          {
                            // Render the header
                            column.render('Header')
                          }
                        </TableCell>
                      );
                    })
                  }
                </TableRow>
              ))
            }
          </TableHead>
          {/* TABLE CONTENT */}
          {/* Apply the table body props */}
          <TableBody {...getTableBodyProps()}>
            {
              // Loop over the table rows
              page.map((row, i) => {
                // Prepare the row for display
                prepareRow(row);
                return (
                  // Apply the row props
                  <TableRow
                    {...row.getRowProps()}
                    style={{ backgroundColor: '#ffffff' }}
                  >
                    {
                      // Loop over the rows cells
                      row.cells.map(cell => {
                        // Apply the cell props
                        return (
                          <TableCell
                            {...cell.getCellProps()}
                            style={{
                              //textAlign: 'center'
                            }}
                          >
                            {
                              // Render the cell contents
                              cell.render('Cell')
                            }
                          </TableCell>
                        );
                      })
                    }
                  </TableRow>
                );
              })
            }
          </TableBody>
        </Table>
      </CardContent>
      {/* PAGINATION AND SETTINGS */}
      <Pagination
        allColumns={allColumns}
        getToggleHideAllColumnsProps={getToggleHideAllColumnsProps}
        pageIndex={pageIndex}
        gotoPage={gotoPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        previousPage={previousPage}
        canPreviousPage={canPreviousPage}
        nextPage={nextPage}
        canNextPage={canNextPage}
        pageCount={pageCount}
      />
    </Card>
  );
};

export default ServiceTable;
