import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField
} from '@mui/material';
import ReportCard from '../ReportCard';
import MachineQRCodesOnlyPDF from './MachineQRCodesOnlyPDF';
import { Autocomplete } from '@mui/material';
import { UserContext } from '../../../store/UserContext';
import extractGroups from '../../../helpers/extractGroups';
import config from '../../../config';
import { machineQRCodeCreater } from '../../../helpers/machineQRCodeCreater';
import { Document, Image, Page, Text, View } from '@react-pdf/renderer';
import formatDate from '../../../helpers/formatDate';
import parseMachines from '../../../helpers/parseMachines';
import useAsync from '../../../Hooks/useAsync';
import axiosRequest from '../../../helpers/axiosRequest';
import removeAllOption from '../../../helpers/removeAllOption';
import { LayoutContext } from '../../../store/LayoutContext';
import setLanguageText from '../../../helpers/setLanguageText';

const MachineQRCodes = ({companies}) => {
  //******************* USE CONTEXT *******************

  //const { selectedGroup: selectedGroupContext } = useContext(CompanyContext);
  const { authUser } = useContext(UserContext);
  const { language } = useContext(LayoutContext);  

  const { data, executeAsync } = useAsync({
    axiosRequest: axiosRequest
  });

  //******************* USE STATES *******************
  const [open, setOpen] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [pdf_codes, setPdf_codes] = useState();
  const [selectedSize, setSelectedSize] = useState(1);
  const [selectedCompany, setSelectedCompany] = useState();
  const [selectedGroup, setSelectedGroup] = useState();
  const [machines, setMachines] = useState();
  const [groups, setGroups] = useState([]);

  // const companies = useMemo(
  //   () => companiesContext.slice(1),
  //   [companiesContext]
  // );
  // useEffect(() => {
  //   if (companies.length) {
  //     const user_company = companies.filter(
  //       company => company.company_id === +user.company_id
  //     )[0];
  //     setSelectedCompany(user_company);
  //   }
  // }, [companies, user]);

  const create_pdf_codes = useCallback(async () => {
    let machineInGroup = machines;
    //console.log(machines)
    if (selectedGroup.group_id !== 0) {
      //console.log('not All');
      machineInGroup = machines.filter(machine => machine.machine_group === selectedGroup.group_name);
    }
    let codes = [];
    machineInGroup.forEach((machine) => {
     
      let url = machineQRCodeCreater({
        company_id: `${machine.company_id}`,
        machine_id: `${machine.machine_id}`,
        instance: config.INSTANCE
      });
      //{"company_id":"1","machine_id":"392","instance":"prod"}
      codes.push({ url: url, machine: parseMachines(machine), company: selectedCompany })
    })
    //console.log(codes)
    setPdf_codes(codes);
    setShowPreview(true);
  },
    [machines, selectedGroup,selectedCompany]);

  const runReport = useCallback(async () => {

    setOpen(true);
    create_pdf_codes();

  }, [selectedCompany, selectedSize, selectedGroup, machines]);

  const handleSelectCompany = async (e, newValue) => {
    setSelectedCompany(newValue);
  };

  const fetchMachines = useCallback(
    async ( company_id) => {
      executeAsync({
        api_key: authUser.api_key,
        method: 'get',
        endpoint: 'machines',
        params: {
          company_id,
          coolant: true
        }
      });
    },
    [authUser.api_key, executeAsync]
  );

  useEffect(() => {  
    if (selectedCompany) {
      fetchMachines(selectedCompany.company_id);
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (data) {
        const AddAllOption = [
          { group_name:  setLanguageText(language, "All Groups"), group_id: 0 },
          ...extractGroups(data)
        ];
        setSelectedGroup(AddAllOption[0]);
        setGroups(AddAllOption);
        setMachines(data);
    }
  }, [data]);

  //add groups counter
  const modifiedValue = useMemo(() => {
    if (selectedGroup)
      return selectedGroup.group_id === 0
        ? {
          ...selectedGroup,
          group_name: `${selectedGroup.group_name} (${groups.length - 1})`
        }
        : selectedGroup;
    return {};
  }, [groups, selectedGroup]);

//console.log(selectedSize)

  const create_pdf_data = useCallback(
    (styles) => {
      return (
        <Document title={setLanguageText(language,'Machine QR codes')}>
          <Page style={styles.BODY} orientation='landscape'>
            <View style={styles.BODY}>
              {pdf_codes && pdf_codes.map(machine => (
                <View wrap={false} key={`${machine.machine.company_id}-${machine.machine.machine_id}`} style={selectedSize === 1 ? styles.SIZE_1 : styles.SIZE_2}>

                  <View style={styles.CONTAINER} >
                    <View style={styles.FULL} >
                      <Text style={styles.MAINTITLE}>{machine.machine.machine_name}</Text>
                    </View>
                    <View style={styles.FULL} >
                      <View style={styles.HALF}>
                        <View style={styles.QR}>
                          <Image src={machine.url} />
                        </View>
                      </View>
                      <View style={styles.HALF}>
                        <Text style={styles.TITLE}>{machine.company.company_name}</Text>
                        <Text style={styles.TEXT}>{machine.machine.machine_group}</Text>
                        <Text style={styles.TITLE}>{machine.machine.coolant_name}</Text>
                        <Text style={styles.TEXT}>{machine.machine.brand_name}</Text>
                        <Text style={styles.TEXT}> {setLanguageText(language,'SUMP')}:{machine.machine?.machine_settings?.machine_defaults?.sump}Ltrs</Text>
                        <Text style={styles.TEXT}>{setLanguageText(language,'LCC')}: {formatDate(machine.machine.last_coolant_change)}</Text>
                      </View>
                    </View>
                    <View style={styles.FULL} >
                      <Text style={styles.TITLE}>MACHINE SETTINGS</Text>
                    </View>
                    {machine.machine && (
                      <View style={styles.FULL} >
                        <Text style={styles.TEXT}>
                        {setLanguageText(language,'Refractometer Factor')} :{' '}
                          {machine.machine.machine_settings?.machine_defaults?.factor}
                          {'\n'}
                        </Text>
                        <Text style={styles.TEXT}>
                        {setLanguageText(language,'PH Min')} :{' '}
                          {machine.machine.machine_settings?.machine_defaults?.ph_min}
                          {'\n'}
                        </Text>
                        <Text style={styles.TEXT}>
                        {setLanguageText(language,'PH Max')} :{' '}
                          {machine.machine.machine_settings?.machine_defaults?.ph_max}
                          {'\n'}
                        </Text>
                        <Text style={styles.TEXT}>
                        {setLanguageText(language,'Conc Min')} :{' '}
                          {
                            machine.machine.machine_settings?.machine_defaults?.op_con_min
                          }
                          {'\n'}
                        </Text>
                        <Text style={styles.TEXT}>
                        {setLanguageText(language,'Conc Max')} :{' '}
                          {
                            machine.machine.machine_settings?.machine_defaults?.op_con_max
                          }
                          {'\n'}
                        </Text>
                      </View>

                    )}

                  </View>
                </View>
              ))}
            </View>
          </Page>
        </Document >
      );
    }, [pdf_codes,selectedSize]);

  return <>
    <ReportCard
      title="Create Machine QR Labels"
      buttonLabel="Run Report"
      //loading={loading}
      //error={error}
      disabled={!selectedCompany || !selectedSize || !selectedGroup ? true : false}
      runReport={runReport}
    >
      <Grid container spacing={1}>
        <Grid item xs={12} sm={4}>
          <Autocomplete
            disableClearable
            id="selected-company"
            value={selectedCompany || ''}
            options={removeAllOption(companies) || []}
            loading={companies ? false : true}
            getOptionLabel={option => option.company_name || ''}
            isOptionEqualToValue={(option, value) =>
              option.company_id === value.company_id
            }
            onChange={handleSelectCompany}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
               label={setLanguageText(language,"Select Company")}
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Autocomplete
            disableClearable
            id="selected-group"
            value={modifiedValue || ''}
            disabled={!selectedCompany}
            options={groups || []}
            loading={groups ? false : true}
            getOptionLabel={option => option.group_name || ''}
            //loading={machines.length ? false : true}
            onChange={(event, newValue) => {
              setSelectedGroup(
                newValue || { group_name:  setLanguageText(language, "All Groups"), group_id: 0 }
              );
            }}
            isOptionEqualToValue={(option, value) =>
              option.group_id === value.group_id
            }
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
               label={setLanguageText(language,"Select Group")}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>{setLanguageText(language,"Per A4 page (size)")}</InputLabel>
            <Select
              value={selectedSize}
              onChange={e => setSelectedSize(e.target.value)}
             label={setLanguageText(language,"Per A4 page (size)")}
            >
              <MenuItem value={1}>6</MenuItem>
              {/* <MenuItem value={2}>12</MenuItem> */}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </ReportCard>

    <MachineQRCodesOnlyPDF
      dialogOpen={open}
      dialogClose={() => {
        setOpen(false);
        setShowPreview(false);
        setPdf_codes();
      }}
      showPreview={showPreview}
      create_pdf_data={create_pdf_data}
    />

  </>;
};

export default MachineQRCodes;
