import {
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  Typography,
  TextField,
  MenuItem,
  Chip,
  IconButton
} from '@mui/material';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { buttonStyles } from '../../../styles/buttonStyles';
import { BrandsContext } from '../../../store/BrandsContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { faCloudUpload, faEdit } from '@fortawesome/pro-light-svg-icons';
import getFileName from '../../../helpers/getFileName';
import axiosRequest from '../../../helpers/axiosRequest';
import useAsync from '../../../Hooks/useAsync';
import { UserContext } from '../../../store/UserContext';
import OutlinedDiv from '../../reusable/OutlinedDiv';
import setLanguageText from '../../../helpers/setLanguageText';
import { LayoutContext } from '../../../store/LayoutContext';

const AdditiveForm = ({ additive, updateAdditive, setSDS, setTDS }) => {
  //****** CONTEXT ******
  const { brands } = useContext(BrandsContext);
  const { language : contextLanguage } = useContext(LayoutContext);

  const { authUser } = useContext(UserContext);
  const { executeAsync: fetch_types } = useAsync({
    axiosRequest: axiosRequest
  });

  //****** STATE ******
  const [attributes, setAttributes] = useState({
    additive_id: additive?.additive_id || '',
    additive_name: additive?.additive_name || '',
    brand: additive?.additive_attr.brand || '',
    description: additive?.additive_attr.application || '',
    type: additive?.additive_attr.type.replace(/\s/g, '_').toLowerCase() || '', //save all new to lowercase , no spaces
    dilution: additive?.additive_attr.dilution || '',
    selling_points: additive?.additive_attr.selling_points || [],
    sds: additive?.additive_attr.pdf?.sds || '',
    tds: additive?.additive_attr.pdf?.tds || '',
    brand_id: additive?.brand_id || ''
  });

  const [options, setOptions] = useState();
  //const [language, setLanguage] = useState('English');
  const language = 'English';

  //****** STYLES******
  const buttonStyle = buttonStyles();

  //****** REFS ******
  const sds_input_ref = useRef(null);
  const tds_input_ref = useRef(null);
  //****** USE EFFECTS ******

  // get types
  useEffect(() => {
    console.log('fetch types')
    fetch_types({
      endpoint: 'additives/types',
      api_key: authUser.api_key,
      method: 'get'
    }).then(types => {
      setOptions(types);
    });
  }, [authUser.api_key, fetch_types]);

  //update parent component attribute, ready for saving
  useEffect(() => {
    // if (attributes && SDS && TDS) {
    updateAdditive(attributes);
    // }
  }, [attributes, updateAdditive]);

  //****** FUNCTIONS ******

  const createFilePath = (attrType, attrName, language, pdfType) => {
    if (language === 'English') {
      return `${attrType}/${attrName
        .replace(/\s/g, '_')
        .toUpperCase()}_${pdfType}.pdf`;
    } else if (language === 'Dutch') {
      return `${attrType}/${attrName
        .replace(/\s/g, '_')
        .toUpperCase()}_${pdfType}_NL.pdf`;
    }
  };

  const uploadSDS = e => {
    if (e.target.files.length) {
      console.log(e.target.files);
      setAttributes(prevState => ({
        ...prevState,
        sds: createFilePath(
          prevState.type,
          prevState.additive_name,
          language,
          'SDS'
        )
      }));
      setSDS(e.target.files[0]);
    }
  };

  const removeSDS = () => {
    // remove value also from the SDS input
    sds_input_ref.current.value = '';
    setSDS();
    setAttributes(prevState => ({
      ...prevState,
      sds: ''
    }));
    // setSDS_uploaded(false);
  };

  const uploadTDS = e => {
    // setTDS_uploaded(true);
    setAttributes(prevState => ({
      ...prevState,
      tds: createFilePath(
        prevState.type,
        prevState.additive_name,
        language,
        'TDS'
      )
    }));
    setTDS(e.target.files[0]);
  };

  const removeTDS = () => {
    // remove value also from the TDS input
    tds_input_ref.current.value = '';
    setTDS();
    setAttributes(prevState => ({
      ...prevState,
      tds: ''
    }));
    // setSDS_uploaded(false);
  };
  const addSellingPoint = e => {
    e.persist();
    const last_selling_point =
      attributes.selling_points[attributes.selling_points.length - 1];
    // Add selling point new selling point if no selling points or last one is empty
    if (!attributes.selling_points.length || last_selling_point)
      setAttributes(prevState => ({
        ...prevState,
        selling_points: [...prevState.selling_points, '']
      }));
  };

  const handleManufacturer = (e,value) => {
    setAttributes(prevState => ({
      ...prevState,
      brand: e.target.value,
      brand_id: value.props.id
    }));
  };

  const handleType = e => {
    setAttributes(prevState => ({
      ...prevState,
      type: e.target.value,
      sds: createFilePath(
        e.target.value,
        prevState.additive_name,
        language,
        'SDS'
      ),
      tds: createFilePath(
        e.target.value,
        prevState.additive_name,
        language,
        'TDS'
      )
    }));
  };

  // const handleLanguage = e => {
  //   setLanguage(e.target.value);
  //   setAttributes(prevState => ({
  //     ...prevState,
  //     sds: createFilePath(
  //       prevState.type,
  //       prevState.additive_name,
  //       e.target.value,
  //       'SDS'
  //     ),
  //     tds: createFilePath(
  //       prevState.type,
  //       prevState.additive_name,
  //       e.target.value,
  //       'TDS'
  //     )
  //   }));
  // };

  //****** RENDER ******
  return (
    <Grid container spacing={2}>
      <Grid item container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            id="additive-name"
           label={setLanguageText(contextLanguage,"Additive Name")}
            variant="outlined"
            fullWidth
            required
            disabled={additive ? true : false}
            value={attributes.additive_name}
            onChange={e =>
              setAttributes({
                ...attributes,
                additive_name: e.target.value,
                sds: createFilePath(
                  attributes.type,
                  e.target.value,
                  language,
                  'SDS'
                ),
                tds: createFilePath(
                  attributes.type,
                  e.target.value,
                  language,
                  'TDS'
                )
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth variant="outlined" required>
            <InputLabel>{setLanguageText(contextLanguage,'Manufacturer')}</InputLabel>
            <Select
             label={setLanguageText(contextLanguage,"Manufacturer")}
              onChange={(e, value) => handleManufacturer(e,value)}
              value={attributes.brand}
            >
              {brands?.map(brand => (
                <MenuItem key={brand.brand_id} value={brand.brand_name} id={brand.brand_id}>
                  {brand.brand_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="description"
         label={setLanguageText(contextLanguage,"Description")}
          variant="outlined"
          multiline
          fullWidth
          required
          value={attributes.description}
          onChange={e =>
            setAttributes({
              ...attributes,
              description: e.target.value
            })
          }
        />
      </Grid>
      <Grid item container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth variant="outlined" required>
            <InputLabel>{setLanguageText(contextLanguage,'Type')}</InputLabel>
            <Select
              labelId="additive-type"
             label={setLanguageText(contextLanguage,"Type")}
              onChange={handleType}
              value={attributes.type}
              //disabled={additive ? true: false}
            >
              {options?.map(option => (
                <MenuItem
                  key={option.additive_type_id}
                  value={option.additive_type_folder}
                >
                  {option.additive_type_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="additive-dilution"
           label={setLanguageText(contextLanguage,"Dilution")}
            variant="outlined"
            fullWidth
            value={attributes.dilution}
            onChange={e =>
              setAttributes({
                ...attributes,
                dilution: e.target.value
              })
            }
          />
        </Grid>
      </Grid>
      <Grid item container spacing={2}>
        {attributes.selling_points?.map((selling, i) => (
          <Grid item container spacing={2} xs={12} alignItems="center" key={i}>
            <Grid item xs={12} sm={10}>
              <TextField
                required
                InputLabelProps={{ shrink: selling ? true : false }}
                id="additive-selling"
                label={`Selling point ${i + 1}`}
                variant="outlined"
                fullWidth
                value={selling}
                onChange={e => {
                  let points = [...attributes.selling_points];
                  points[i] = e.target.value;
                  setAttributes({
                    ...attributes,
                    selling_points: points
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Button
                fullWidth
                variant="contained"
                component="label"
                color="primary"
                className={buttonStyle.danger}
                onClick={e => {
                  let points = [...attributes.selling_points];
                  points.splice(i, 1);
                  setAttributes({
                    ...attributes,
                    selling_points: points
                  });
                }}
              >
                Remove
              </Button>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Grid item xs={12}>
        <Button
          fullWidth
          variant="contained"
          component="label"
          color="primary"
          onClick={addSellingPoint}
        >
          {setLanguageText(contextLanguage,'Add selling point')}
        </Button>
      </Grid>

      {/* <Grid item xs={12}>
        <FormControl fullWidth variant="outlined">
          <InputLabel>Select language</InputLabel>
          <Select
            labelId="additive-lang"
           label={setLanguageText(contextLanguage,"Select language"
            onChange={handleLanguage}
            value={language}
          >
            <MenuItem key="English" value="English">
              English
            </MenuItem>
            <MenuItem key="Dutch" value="Dutch">
              Dutch
            </MenuItem>
          </Select>
        </FormControl>
      </Grid> */}
      <Grid item container spacing={2}>
        {/***** SDS FILE UPLOAD ****/}

        <Grid item xs={12}>
          <OutlinedDiv label={setLanguageText(contextLanguage,"Upload SDS")}>
            <Grid container spacing={2}>
              <Grid item>
                <Typography>{setLanguageText(contextLanguage,"SDS Filepath")}:</Typography>
              </Grid>
              <Grid item>
                <Typography>{attributes.sds}</Typography>
              </Grid>
            </Grid>
            <Grid item container xs={12} spacing={2} alignItems="center">
              {/* UPLOAD BUTTON */}
              <Grid item>
                <Button
                  fullWidth
                  variant="contained"
                  component="label"
                  color="primary"
                  className={
                    sds_input_ref?.current?.value ? buttonStyle.warning : ''
                  }
                >
                  <FontAwesomeIcon
                    icon={
                      !sds_input_ref?.current?.value ? faCloudUpload : faEdit
                    }
                    className={buttonStyle.icon_md}
                  />
                  <Typography className={buttonStyle.buttonIconText}>
                  {setLanguageText(contextLanguage, !sds_input_ref?.current?.value ? 'SDS PDF' : 'TDS PDF')}
                  </Typography>
                  <input
                    type="file"
                    accept=".pdf"
                    onChange={uploadSDS}
                    ref={sds_input_ref}
                    hidden
                  />
                </Button>
              </Grid>
              {/* UPLOADED FILE BUTTON */}
              {sds_input_ref?.current?.value && (
                <Grid item style={{ flex: 1 }}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      <IconButton
                        size="small"
                        className={buttonStyle.danger}
                        onClick={removeSDS}
                      >
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <Chip label={getFileName(sds_input_ref.current.value)} />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </OutlinedDiv>
        </Grid>
        {/* TDS FILE UPLOAD */}
        <Grid item xs={12}>
          <OutlinedDiv label={setLanguageText(contextLanguage,"Upload TDS")}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item>
                  <Typography>{setLanguageText(contextLanguage,"TDS Filepath")}:</Typography>
                </Grid>
                <Grid item>
                  <Typography>{attributes.tds}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item container xs={12} spacing={2} alignItems="center">
              {/* UPLOAD BUTTON */}
              <Grid item>
                <Button
                  fullWidth
                  variant="contained"
                  component="label"
                  color="primary"
                  className={
                    tds_input_ref?.current?.value ? buttonStyle.warning : ''
                  }
                >
                  <FontAwesomeIcon
                    icon={
                      !tds_input_ref?.current?.value ? faCloudUpload : faEdit
                    }
                    className={buttonStyle.icon_md}
                  />
                  <Typography className={buttonStyle.buttonIconText}>
                  {setLanguageText(contextLanguage,!tds_input_ref?.current?.value ? 'TDS PDF' : 'TDS PDF')}
                  </Typography>

                  <input
                    type="file"
                    accept=".pdf"
                    onChange={uploadTDS}
                    ref={tds_input_ref}
                    hidden
                  />
                </Button>
              </Grid>

              {/* UPLOADED FILE */}
              {tds_input_ref?.current?.value && (
                <Grid item style={{ flex: 1 }}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      <IconButton
                        size="small"
                        className={buttonStyle.danger}
                        onClick={removeTDS}
                      >
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <Chip label={getFileName(tds_input_ref.current.value)} />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </OutlinedDiv>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AdditiveForm;
