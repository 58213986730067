import React, { useState, useEffect, useContext } from 'react';
import { Storage } from 'aws-amplify';
import axiosRequest from '../../../helpers/axiosRequest';
import { UserContext } from '../../../store/UserContext';
import ProductForm from './ProductForm';
import useAsync from '../../../Hooks/useAsync';
import GlobalDialog from '../../reusable/GlobalDialog';
import { ADD_PRODUCT } from '../../../store/ProductsReducers';
import { ProductsContext } from '../../../store/ProductsContext';

const AddProducts = ({ closeAddDialog, isAddDialog, typeOptions}) => {
  const {
    loading,
    error,
    data,
    logError,
    executeAsync,
    executeLog
  } = useAsync({
    axiosRequest: axiosRequest
  });

  //****** CONTEXT ******
  const { authUser, user } = useContext(UserContext);
  const { dispatchProducts, productsOptions } = useContext(ProductsContext);

  //****** STATE ******
  const [attributes, setAttributes] = useState();
  const [createdCoolantID, setCreatedCoolantID] = useState(null);

  //NEED TO CREATE A GLOBAL VARIABLE
  //const [appName] = useState('cis'); // for app specific PDFs
  const [SDS, setSDS] = useState();
  const [TDS, setTDS] = useState();
  const [coolantExists, setCoolantExists] = useState(false);

  //****** FUNCTIONS ******

  const logPDF_upload = async ({ pdf_type, name }) => {
    const response = await executeLog({
      endpoint: 'pdf',
      api_key: authUser.api_key,
      method: 'post',
      body: {
        pdf_type,
        name,
        user_details: {
          user: user.name,
          user_id: user.id
        }
      }
    });
    return response;
  };

  useEffect(() => {
    if (data) {
      // TODO handle if activity error
      if (logError) console.log('Something went wrong with the Activity Log');
    }
  }, [data, logError]);

  useEffect(() => {
    if (coolantExists) {
      setTimeout(() => {
        setCoolantExists(false);
      }, 5000);
    }
  }, [coolantExists]);


  //Create new coolant
  const handleSubmit = async e => {
    e.preventDefault();
    let pdf_uploads = [];
    let coolantObj = {
      'coolant_name': attributes.coolant_name,
      'coolant_company': 0,
      'brand_id': attributes.brand_id,
      'language': attributes.language,
      'tds_uploaded': attributes.tds_uploaded,
      'sds_uploaded': attributes.sds_uploaded,
      'coolant_defaults': {
        'pdf_s3': {
          'folder': attributes.pdf_s3.folder
        },
        'coolant': {
          'type': JSON.stringify(attributes.description).slice(1, -1),
          'manufacturer': attributes.manufacturer,
          'selling_points': attributes.selling_points,
          'variants': attributes.variants
        },
        'defaults': attributes.defaults
      },
      'type': attributes.type,
      'machines_using_coolant': []
    };

    console.log(coolantObj)
    
    const coolant_db = await executeAsync({
      endpoint: 'coolants',
      api_key: authUser.api_key,
      method: 'post',
      body: coolantObj
    });

    if (!coolant_db.insertId) return setCoolantExists(true);

    setCreatedCoolantID(coolant_db.insertId);

    if (SDS) {
      const sds_path = `${attributes.pdf_s3.folder}/${coolant_db.insertId}_${attributes.language}_SDS.pdf`;
      try {
        await Storage.put(sds_path, SDS, {
          contentType: 'application/pdf'
        });

        const sds_pdf_log = await logPDF_upload({
          path: sds_path,
          type: 'Coolant SDS'
        });

        pdf_uploads = [
          ...pdf_uploads,
          {
            type: 'sds',
            path: sds_path,
            upload_id: sds_pdf_log.insertId
          }
        ];
      } catch (err) {
        console.log(err);
      }
    }
    if (TDS) {
      let tds_path = `${attributes.pdf_s3.folder}/${coolant_db.insertId}_${attributes.language}_TDS.pdf`;
      try {
        await Storage.put(tds_path, TDS, {
          contentType: 'application/pdf'
        });
        const tds_pdf_log = await logPDF_upload({
          path: tds_path,
          type: 'Coolant TDS'
        });

        pdf_uploads = [
          ...pdf_uploads,
          {
            type: 'tds',
            path: tds_path,
            upload_id: tds_pdf_log.insertId
          }
        ];
      } catch (err) {
        console.log(err);
      }
    }
    
    //set activity
    await executeLog({
      endpoint: 'activity',
      api_key: authUser.api_key,
      body: {
        attributes: {
          type: 'New Coolant',
          user_id: user.id,
          coolant_id: coolant_db.insertId,
          pdf_uploads
        }
      }
    });

    // add language to object so the PDFs works / needs looking at
    if (coolantObj.sds_uploaded) {
      coolantObj.coolant_sds_list = JSON.stringify([coolantObj.language]);
    }
    if (coolantObj.tds_uploaded) {
      coolantObj.coolant_tds_list = JSON.stringify([coolantObj.language]);
    }
    console.log(coolantObj.type)
    console.log(productsOptions)
    setTimeout(() => {
      //if new product matchine the selected product type
      if(coolantObj.type === productsOptions.type.coolant_type_id){
        dispatchProducts({
          type: ADD_PRODUCT,
          payload: {
            'brand_id': coolantObj.brand_id,
            'coolant_defaults': JSON.stringify(coolantObj.coolant_defaults),
            'coolant_id': coolant_db.insertId ,
            'coolant_name': coolantObj.coolant_name,
            'coolant_sds_list': JSON.stringify(coolantObj.coolant_sds_list),
            'coolant_tds_list': JSON.stringify(coolantObj.coolant_tds_list),
            'coolant_type': coolantObj.type
          } 
        });
      }
      closeAddDialog();
      setAttributes();
    }, 1000);
  };

  const updateCoolant = attributes => {
    setAttributes(attributes);
  };

  //****** RENDER ******
  return (
    <GlobalDialog
      closeModal={closeAddDialog}
      title="Add Product"
      handleSubmit={handleSubmit}
      buttonTitle="Save Product"
      successMessage={`Product added ID: ${createdCoolantID}`}
      customError={createdCoolantID === null ? true : false}
      errorMessage={`There is already a product with this name`}
      loading={loading}
      error={error}
      data={data}
      keepMounted
      fullWidth
      maxWidth="md"
      submit_disabled={loading || data }
      open={isAddDialog}
    >
      <ProductForm
        updateCoolant={updateCoolant}
        setSDS={setSDS}
        setTDS={setTDS}
        SDS={SDS}
        TDS={TDS}
        typeOptions={typeOptions}
      />
    </GlobalDialog>
  );
};

export default AddProducts;
