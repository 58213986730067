import React from 'react';
import { Link } from 'react-router-dom';
function NotFound(props) {
	return (
		<div className='NotFound'>
			<h3>Whoops! 404 Error, page not found.</h3>
			<Link className='btn btn-lg btn-primary btn-block' to='/'>
				Dashboard
			</Link>
		</div>
	);
}

export default NotFound;
