import { faEdit } from '@fortawesome/pro-light-svg-icons';
import {
    Grid,
    TextField
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { buttonStyles } from '../../../styles/buttonStyles';
import PrimaryBtnIcon from '../../buttons/PrimaryBtnIcon';
import axiosRequest from '../../../helpers/axiosRequest';
import { UserContext } from '../../../store/UserContext';
import { MachineContext } from '../../../store/MachineContext';
import { SELECTED_MACHINE } from '../../../store/MachineReducers';
import GlobalDialog from '../../reusable/GlobalDialog';
import useAsync from '../../../Hooks/useAsync';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LayoutContext } from '../../../store/LayoutContext';
import setLanguageText from '../../../helpers/setLanguageText';

const machineToEditObject = machine => ({
    machine_name: machine.machine_name,
    machine_group: machine.machine_group
});

function EditMachine({ selectedMachine }) {

    //****** CONTEXT ******
    const { authUser, user } = useContext(UserContext);
    const { dispatchSelectedMachine } = useContext(MachineContext);
    const { language } = useContext(LayoutContext)
    
    const {
        error,
        data,
        loading,
        executeAsync,
        executeLog,
        setData
      } = useAsync({
        axiosRequest: axiosRequest
      });

    //****** STATE ******

    const [openEdit, setOpenEdit] = useState(false);
    const [machineToEdit, setMachineToEdit] = useState();

    //******  USE EFFECT ******
    useEffect(() => {
        if (Object.entries(selectedMachine).length) {
            setMachineToEdit(machineToEditObject(selectedMachine));
        }
    }, [selectedMachine]);

    //****** STYLES ******
    const buttonStyle = buttonStyles();

    //****** FUNCTIONS ******

    const openDialog = () => {
        setOpenEdit(true)
    };

    const closeDialog = () => {
        setOpenEdit(false);
        // Reset the Machine Name/Group if close the edit dialog
        setMachineToEdit({
            machine_name: selectedMachine.machine_name,
            machine_group: selectedMachine.machine_group
        });
    };

    const handleEditMachine = event => {
        event.persist();
        setMachineToEdit(prevState => ({
            ...prevState,
            [event.target.name]: event.target.value
        }));
    };

    const confirmEditMachine =  async e  => {
        e.preventDefault();
        const response = await executeAsync({
            endpoint: 'machines',
            api_key: authUser.api_key,
            method: 'patch',
            body: {
                edit: 'machine',
                machine_id: selectedMachine.machine_id,
                ...machineToEdit
            }
         });
         if (response) {
            await executeLog({
              endpoint: 'activity',
              api_key: authUser.api_key,
              body: {
                attributes: {
                  type: 'Machine Edit',
                  user_id: user.id,
                  machine_id: selectedMachine.machine_id,
                  machine_detail : machineToEdit
                }
              }
        });
     }
     //update selected machine
    //  dispatchSelectedMachine({
    //     type: SELECTED_MACHINE,
    //     payload: response[0]
    //   });
    dispatchSelectedMachine({
        type: SELECTED_MACHINE,
        payload: {
          ...selectedMachine,
          ...machineToEdit
        }
      });

     setData();
     closeDialog();

    };


    return (
        <>
            {/* <PrimaryBtnIcon 
                icon={faEdit}
                text="Edit Machine"
                //size="small"
                //iconSize="lg"
                //textVariant="caption"
                //className={buttonStyle.warning}
                onClick={openDialog}
            /> */}
             <FontAwesomeIcon 
                onClick={openDialog}
                style={{ cursor : 'pointer' ,display: 'inline-block', margin : '5px 5px 8px 10px'}} 
                icon={faEdit}  
                //size="lg"
             />

            <GlobalDialog
                open={openEdit}
                closeModal={closeDialog}
                title="Edit Machine Details"
                handleSubmit={confirmEditMachine}
                buttonTitle="Confirm"
                successMessage="Company Edittd successfully!"
                loading={loading}
                error={error}
                data={data}
            >
                {machineToEdit && 
                    <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            variant="outlined"
                            name="machine_name"
                            label={setLanguageText(language,"Machine Name")}
                            value={machineToEdit.machine_name}
                            onChange={handleEditMachine}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            variant="outlined"
                            name="machine_group"
                            label={setLanguageText(language,"Machine Group")}
                            value={machineToEdit.machine_group}
                            onChange={handleEditMachine}
                        />
                    </Grid>
                </Grid>
                }
            </GlobalDialog>
        </>
    );
}

export default EditMachine;
