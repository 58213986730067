import React, { useContext, useEffect, useState } from 'react';
import PageContent from '../PageContent';
import {
  Grid,
  Typography,
  TextField
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import PageHeader from '../PageHeader';
import useAsync from '../../Hooks/useAsync';
import axiosRequest from '../../helpers/axiosRequest';
import { UserContext } from '../../store/UserContext';
import { faSave } from '@fortawesome/pro-light-svg-icons';
import LoadingButton from '../buttons/LoadingButton';
import setLanguageText from '../../helpers/setLanguageText';
import { LayoutContext } from '../../store/LayoutContext';

const useStyles = makeStyles(theme => ({
  card: {
    width: '100%'
  },
  header: {
    borderBottom: `${theme.spacing(0.4)} solid ${theme.palette.background.light
      }`,
    padding: theme.spacing(1)
  }
}));

const UserDetails = () => {
  //************  STYLES ************

  const classes = useStyles();

  //************  CONTEXT ************

  const { language } = useContext(LayoutContext);  
  const { authUser, user: contextUser } = useContext(UserContext);
  const [user, setUser] = useState();

  const {
    error,
    data,
    loading,
    logCompleted,
    logError,
    executeAsync,
    executeLog,
    setData
  } = useAsync({
    axiosRequest: axiosRequest
  });

  //************  USE EFFECT ************

  useEffect(() => {
    if (Object.values(contextUser).length) {
      console.log(contextUser)
      setUser(contextUser)
    }
  }, [contextUser]);

  //************ FUNCTIONS ************

  const setName = (e) => {
    e.persist()
    setUser(prevState => ({
        ...prevState,
        full_name: e.target.value
    }))
  }

  const setInitial = (e) => {
    e.persist()
    setUser(prevState => ({
      ...prevState,
      initial: e.target.value
    }))
  }

  const setUserName = (e) => {
    e.persist()
    // setUser(prevState => ({
    //     ...prevState,
    //     company_name: e.target.value
    //   }))
  }

  const setEmail = (e) => {
    e.persist()
    // setUser(prevState => ({
    //     ...prevState,
    //     company_name: e.target.value
    //   }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(user)
    await executeAsync({
      endpoint: 'users',
      api_key: authUser.api_key,
      method: 'patch',
      body: user
    });

    await executeLog({
      endpoint: 'activity',
      api_key: authUser.api_key,
      body: {
        attributes: {
          type: 'Update User',
          user_id: user.id,
          full_name: user.full_name,
          initials: user.initial
        }
      }
    });

  }

  return (
    <>
      <PageHeader>
        <Grid item xs={12}>
          <Grid
            item
            container
            justifyContent="space-between"
            className={classes.header}
          >
            <Grid item xs={12} sm={'auto'}>
              <Typography variant="h5">{setLanguageText(language,"My Profile")}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </PageHeader>
      <PageContent className="ApplicationDetails">
        {user ?
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                InputLabelProps={{ shrink: user.name ? true : false }}
                name="full_name"
               label={setLanguageText(language,"Name")}
                variant="outlined"
                value={user.full_name}
                //disabled
                onChange={e => setName(e)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                InputLabelProps={{ shrink: user.name ? true : false }}
                name="initial"
               label={setLanguageText(language,"Initials")}
                variant="outlined"
                value={user.initial}
                //disabled
                onChange={e => setInitial(e)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                InputLabelProps={{ shrink: user.name ? true : false }}
                name="user_name"
               label={setLanguageText(language,"User Name")}
                variant="outlined"
                value={user.name}
                disabled
                onChange={e => setUserName(e)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                InputLabelProps={{ shrink: user.name ? true : false }}
                name="user_email"
               label={setLanguageText(language,"User Email")}
                variant="outlined"
                value={user.email}
                disabled
                onChange={e => setEmail(e)}
              />
            </Grid>

            <Grid item container xs={12}>
              <Grid item >
                <LoadingButton
                  //disabled
                  icon={faSave}
                  text="Update Profile"
                  iconSize="1x"
                  color="primary"
                  loading={loading}
                  onClick={e => handleSubmit(e)}
                />
              </Grid>
            </Grid>
          </Grid>
          : null}
      </PageContent>
    </>
  );
};

export default UserDetails;
