import React, { useContext, useEffect, useState } from 'react';
import sortCoolants from '../../../helpers/sortCoolants';
import { CoolantContext } from '../../../store/CoolantContext';
import Autocomplete from '@mui/material/Autocomplete';
import {
  faSortAmountDown,
} from '@fortawesome/pro-light-svg-icons';
import {
  faTh,
  faList,
  faSortAlphaDown,
  faSortAlphaDownAlt
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  ButtonGroup,
  Grid,
  TextField,
} from '@mui/material';
import {
  TOGGLE_SORT,
  TOGGLE_VIEW,
  SAVE_COOLANTS_COMPARE,
  SAVE_COOLANTS_IN_USE
} from '../../../store/CoolantReducers';
import setLanguageText from '../../../helpers/setLanguageText';
import { LayoutContext } from '../../../store/LayoutContext';

const CoolantOptions = () => {
  const {
    dispatchCoolants,
    coolants,
    coolantOptions,
    dispatchCoolantOptions,
    coolantsInUse,
    dispatchCoolantsCompare,
    dispatchCoolantsInUse,
  } = useContext(CoolantContext);
  const [searchOptions, setSearchOptions] = useState([]);
  const { theme, language } = useContext(LayoutContext);
  //****** USEEFFECTS ******

  useEffect(() => {
    if (coolantOptions.inUse) {
      setSearchOptions(coolantsInUse);
    } else {
      setSearchOptions(coolants);
      handleView("grid");
    }
  }, [coolants, coolantsInUse, coolantOptions.inUse]);


  //****** FUNCTIONS ******

  const handleSort = sort => {
    dispatchCoolantOptions({
      type: TOGGLE_SORT,
      payload: sort
    });
    // Sort based on IN USE selected
    coolantOptions.inUse
      ? dispatchCoolantsInUse({
        type: SAVE_COOLANTS_IN_USE,
        payload: sortCoolants(coolantsInUse, sort)
      })
      : dispatchCoolants({
        type: SAVE_COOLANTS_IN_USE,
        payload: sortCoolants(coolants, sort)
      });
  };

  const handleView = view => {
    dispatchCoolantOptions({
      type: TOGGLE_VIEW,
      payload: view
    });
  };

  const updateVisible = e => {
    //console.log(e)
    dispatchCoolantsCompare({
      type: SAVE_COOLANTS_COMPARE,
      payload: e
    });
  };

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="stretch"
      spacing={2}
    >
      <Grid item xs={12} md={4}>
        <Autocomplete
          multiple
          id="tags-outlined"
          options={searchOptions}
          getOptionLabel={option => option.coolant_name}
          filterSelectedOptions
          renderInput={params => (
            <TextField
              {...params}
              variant="outlined"
             label={setLanguageText(language,"Search and compare Coolants")}
            />
          )}
          onChange={(e, newValue) => updateVisible(newValue)}
        />
      </Grid>

      <Grid item container xs={12} md={4} justifyContent="space-between">
        <Grid item container xs={7}>
          <ButtonGroup fullWidth>
            <Button
              variant="contained"
              color={
                coolantOptions.toggleSort === 'asc' ? 'primary' : 'inherit'
              }
              onClick={() => handleSort('asc')}
            >
              <FontAwesomeIcon icon={faSortAlphaDown} size="lg" />
            </Button>
            <Button
              variant="contained"
              color={
                coolantOptions.toggleSort === 'desc' ? 'primary' : 'inherit'
              }
              onClick={() => handleSort('desc')}
            >
              <FontAwesomeIcon icon={faSortAlphaDownAlt} size="lg" />
            </Button>
            <Button
              variant="contained"
              color={
                coolantOptions.toggleSort === 'most_used'
                  ? 'primary'
                  : 'inherit'
              }
              onClick={() => handleSort('most_used')}
            >
              <FontAwesomeIcon icon={faSortAmountDown} size="lg" />
            </Button>
          </ButtonGroup>
        </Grid>

        <Grid item container xs={4}>
          <ButtonGroup fullWidth>
            <Button
              variant="contained"
              color={
                coolantOptions.toggleView === 'grid' ? 'primary' : 'inherit'
              }
              onClick={() => handleView('grid')}
            >
              <FontAwesomeIcon icon={faTh} size="lg" />
            </Button>
            {coolantOptions.inUse &&

              <Button
                variant="contained"
                color={
                  coolantOptions.toggleView === 'list' ? 'primary' : 'inherit'
                }
                size="small"
                onClick={() => handleView('list')}
              >
                <FontAwesomeIcon icon={faList} size="lg" />
              </Button>
            }
          </ButtonGroup>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CoolantOptions;
