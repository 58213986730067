import React from 'react';
import { Button, Grid } from '@mui/material';
import GlobalDialog from '../reusable/GlobalDialog';
import SignatureCanvas from 'react-signature-canvas';
import { useTheme } from '@mui/styles';
import { isMobileOnly } from 'react-device-detect';

const SignatureDialog = ({
  open,
  closeDialog,
  title,
  submit_sign,
  sign_ref,
  dialog_content_ref,
  serviceObj,
  setServiceObj
}) => {
  const theme = useTheme();
  return (
    <GlobalDialog
      open={open}
      closeModal={closeDialog}
      fullWidth
      maxWidth="md"
      title={title}
      backgroundColor={theme.palette.background.light}
      buttonTitle={'Confirm Signature'}
      handleSubmit={submit_sign}
    >
      <Grid>
      <SignatureCanvas
        ref={sign_ref}
        canvasProps={{
          maxWidth: '100%',
          width: (dialog_content_ref.current?.clientWidth - 8),
          height: isMobileOnly ? 200 :dialog_content_ref.current?.clientHeight,
          className: 'sigCanvas'
        }}
        backgroundColor={theme.palette.common.white}
        fromDataURL={serviceObj.cust_sign || ''}
      />
      <Button
        onClick={() =>
          setServiceObj({
            ...serviceObj,
            cust_sign: sign_ref.current.clear()
          })
        }
        variant="contained"
      >
        Clear
      </Button>
      </Grid>
    </GlobalDialog>
  );
};

export default SignatureDialog;
