import {
  Grid,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme
} from '@mui/material';
import React, { useEffect, useMemo } from 'react';
// import { useHistory } from 'react-router-dom';
import {
  usePagination,
  useRowState,
  useSortBy,
  useTable,
  useFilters
} from 'react-table';
import Pagination from '../reusable/Pagination';
import { matchSorter } from 'match-sorter';

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] });
}

const UserTable = ({ columns, data, getTableDataFn }) => {
  //************************  STYLES ************************
  const theme = useTheme();
  //************************  CONTEXT ************************

  //************************  FUNCTIONS ************************

  const filterTypes = useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      }
    }),
    []
  );
  const defaultColumn = useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: ''
    }),
    []
  );

  // We need this function in order to create the Machine's id route
  // let history = useHistory();
  //************************  TABLE CREATE FUNCTION ************************
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page
    rows,
    // The rest of these things are super handy, too ;)
    allColumns,
    getToggleHideAllColumnsProps,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, filters }
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      // Cell's Initial state
      initialRowStateAccessor: cell => {
        return {
          // user to simulate enabled user
          enabled: cell.original.Enabled,
          error: null
        };
      }
    },
    useFilters,
    useSortBy,
    usePagination,
    useRowState
  );
  //************************  USE EFFECTS ************************
  //returns table data to parent component
  useEffect(() => {
    if (getTableDataFn) {
      rows && getTableDataFn(rows);
    }
  }, [getTableDataFn, rows]);

  return (
    // apply the table props
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Card variant="outlined">
          <CardContent
            style={{
              overflowX: 'auto',
              maxHeight: '70vh',
              padding: '0'
            }}
          >
            <Table
              {...getTableProps()}
              className="users--table"
              stickyHeader
              aria-label="sticky table"
            >
              {/* TABLE HEADER */}
              <TableHead className="users--header" style={{ width: '100%' }}>
                {
                  // Loop over the header rows
                  headerGroups.map(headerGroup => (
                    // Apply the header row props
                    <TableRow {...headerGroup.getHeaderGroupProps()}>
                      {
                        // Loop over the headers in each row
                        headerGroup.headers.map(column => {
                          return (
                            // Apply the header cell props
                            <TableCell
                              {...column.getHeaderProps(
                                // add sort functionality
                               // column.getSortByToggleProps()
                              )}
                              style={{
                                //textAlign: 'center',
                                backgroundColor: theme.palette.background.light,
                                padding: theme.spacing(1, 2)
                              }}
                            >
                              <span //{...column.getSortByToggleProps()}
                              >
                                {column.render('Header')}
                                {/* Render the columns filter UI */}
                                <div>
                                  {column.canFilter
                                    ? column.render('Filter')
                                    : null}
                                </div>
                              </span>
                            </TableCell>
                          );
                        })
                      }
                    </TableRow>
                  ))
                }
              </TableHead>
              {/* TABLE CONTENT */}
              {/* Apply the table body props */}
              <TableBody {...getTableBodyProps()}>
                {
                  // Loop over the table rows
                  page.map((row, i) => {
                    // Prepare the row for display
                    prepareRow(row);
                    return (
                      // Apply the row props
                      <TableRow
                        {...row.getRowProps()}
                        style={{ backgroundColor: row.state.enabled ? '#ffffff' : '#fc46701a' }}
                      >
                        {
                          // Loop over the rows cells
                          row.cells.map(cell => {
                            // Apply the cell props
                            return (
                              <TableCell
                                {...cell.getCellProps()}
                                style={{
                                  //textAlign: 'center',
                                  padding: theme.spacing(1, 2),
                                  overflowX: 'auto',
                                  width: '2%'
                                }}
                              >
                                {
                                  // Render the cell contents
                                  cell.render('Cell')
                                }
                              </TableCell>
                            );
                          })
                        }
                      </TableRow>
                    );
                  })
                }
              </TableBody>
            </Table>
          </CardContent>
          {/* PAGINATION AND SETTINGS */}
          <Pagination
            allColumns={allColumns}
            rowCount={data.length}
            getToggleHideAllColumnsProps={getToggleHideAllColumnsProps}
            pageIndex={pageIndex}
            gotoPage={gotoPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            previousPage={previousPage}
            canPreviousPage={canPreviousPage}
            nextPage={nextPage}
            canNextPage={canNextPage}
            pageCount={pageCount}
          />
        </Card>
      </Grid>
    </Grid>
  );
};

export default UserTable;
