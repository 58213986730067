import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PageContent from '../PageContent';
import {
  Button,
  Grid,
  Typography,
  CircularProgress
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { buttonStyles } from '../../styles/buttonStyles';
import TypesTable from './TypesTable';
import {
  faEdit,
  faPlus,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageHeader from '../PageHeader';
import useAsync from '../../Hooks/useAsync';
import { UserContext } from '../../store/UserContext';
import axiosRequest from '../../helpers/axiosRequest';
import PrimaryBtnIcon from '../buttons/PrimaryBtnIcon';
import GlobalDialog from '../reusable/GlobalDialog';
import TypesForm from './TypesForm';
import AddType from './AddType';
import LoadingScreen from './../reusable/LoadingScreen';
import setLanguageText from '../../helpers/setLanguageText';
import { LayoutContext } from '../../store/LayoutContext';

const useStyles = makeStyles(theme => ({
  card: {
    width: '100%'
  },
  header: {
    borderBottom: `${theme.spacing(0.4)} solid ${theme.palette.background.light
      }`,
    padding: theme.spacing(1)
  },
  image: {
    '& img': {
      height: '100px',
      width: '100px',
      objectFit: 'contain'
    }
  }
}));

const Types = () => {
  //************  STYLES ************
  const classes = useStyles();
  const buttonStyle = buttonStyles();
  //************  CONTEXT ************
  const { authUser, user } = useContext(UserContext);
  const { language } = useContext(LayoutContext)
  //************ STATES ************
  const [editOpen, setEditOpen] = useState(false);
  const [addOpen, setAddOpen] = useState(false);
  const [types, setTypes] = useState();
  const [type, setType] = useState();

  const {
    error: edit_error,
    data: edit_data,
    loading: edit_loading,
    executeAsync: edit_brand,
    executeLog: logBrand,
    setData
  } = useAsync({
    axiosRequest: axiosRequest
  });

  const {
    loading,
    executeAsync: fetch_types
  } = useAsync({
    axiosRequest: axiosRequest
  });

  const getTypes = useCallback(() => {

    fetch_types({
      endpoint: 'additives/types',
      api_key: authUser.api_key,
      method: 'get'
    }).then(types => {
      setTypes(types)
    });

  }, [authUser.api_key, fetch_types])

  //************  USE EFFECT ************
  useEffect(() => {

    if (!types) {
      getTypes();
    }
  }, [types, getTypes]);

  //************ TABLE ************

  const table_data = useMemo(() => types, [types]);
  const table_columns = useMemo(() => [
    {
      id: 'additive_type_name',
      name: 'Type Name',
      Header: () => <Typography variant="h6">{setLanguageText(language,"Addititve Type Name")}</Typography>,
      accessor: 'additive_type_name',
    }, {
      id: 'Actions',
      Header: () => <Typography variant="h6">{setLanguageText(language,"Actions")}</Typography>,
      sortable: false,
      minWidth: 50,
      width: 50,
      maxWidth: 50,
      Cell: ({ row }) => {
        return (
          <Grid container spacing={1} justifyContent="center">
            <Grid item xs={12}>
              <PrimaryBtnIcon
                icon={faEdit}
                text="Edit"
                //iconSize="2x"
                onClick={e => openEditDialog(e, row)}
                size="small"
              />
            </Grid>
          </Grid>
        )
      }
    }
  ]);

  //************ FUNCTIONS ************

  const updateType = e => {
    setType(e);
  };

  const closeEditDialog = e => {
    setEditOpen(false);
  };

  const openEditDialog = (e, row) => {
    setType(row.original);
    setEditOpen(true);
  };

  const handleSubmit = async (e) => {
    console.log('save edit')
    e.preventDefault()

    await edit_brand({
      endpoint: 'additives/types',
      api_key: authUser.api_key,
      method: 'patch',
      body: type
    });

    await logBrand({
      endpoint: 'activity',
      api_key: authUser.api_key,
      body: {
        attributes: {
          type: 'Edit Additive Type',
          user_id: user.id,
          additive_type_id: type.additive_type_id,
        }
      }
    });

    setTimeout(() => {
      setEditOpen(false);
      //reset data from aysnc
      setData();
    }, 1000);
    //add editted type 
    let items = [];
    types.forEach((item) => {
      if (item.additive_type_id === type.additive_type_id) {
        items.push(type);
      } else {
        items.push(item);
      }
    })
    setTypes(items);
  }

  const closeAddDialog = e => {
    setAddOpen(false);
  };

  const openAddDialog = e => {
    setAddOpen(true);
  };

  return (
    <>
      {user.level === 9 && (
        <PageHeader>
          <Grid item xs={12}>
            <Grid
              item
              container
              justifyContent="space-between"
              className={classes.header}
            >
              <Grid item xs={12} sm={'auto'}>
                <Typography variant="h5">{setLanguageText(language,"Additive Types")}</Typography>
              </Grid>
              <Grid item xs={12} sm={'auto'}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={'auto'}>
                    <Button
                      fullWidth
                      onClick={openAddDialog}
                      variant="contained"
                      color="primary"
                      className={buttonStyle.fixPointerEvent}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                      {/* <FontAwesomeIcon icon={faIndustry} /> */}
                      <Typography className={buttonStyle.buttonIconText}>
                      {setLanguageText(language,"Add Additive Type")}
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <AddType
              closeAddDialog={closeAddDialog}
              getTypes={e => getTypes()}
              open={addOpen}
            />
          </Grid>
        </PageHeader>
      )}
      <PageContent className="types">
        <LoadingScreen 
          loading={!loading} 
          background="#fff" 
          colour="#666"
          text="Loading Types" 
        />
        {table_data && !loading ?
          <TypesTable data={table_data} columns={table_columns} />
        : null }
      </PageContent>
      <GlobalDialog
        open={editOpen}
        closeModal={closeEditDialog}
        title="Edit Addititve Type"
        handleSubmit={e => handleSubmit(e)}
        buttonTitle="Edit Type"
        successMessage="Addititve type editied successfully!"
        loading={edit_loading}
        error={edit_error}
        data={edit_data}
        fullWidth
        maxWidth="sm"
      >
        <TypesForm
          type={type}
          updateType={updateType}
        />
      </GlobalDialog>
    </>
  );
};

export default Types;
