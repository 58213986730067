import React, { useContext } from 'react';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import config from '../../config';
import { isMobileOnly } from 'react-device-detect';
import setLanguageText from '../../helpers/setLanguageText';
import { LayoutContext } from '../../store/LayoutContext';

const useStyles = makeStyles(theme => ({
  footer: {
    position: isMobileOnly ? 'relative' : 'fixed',
    bottom: 0,
    width: '100%',
    height: '60px',
    padding: '1em',
    background: '#fafafa',
    textAlign: 'center',
    display: 'grid',
    alignItems: 'center'
  },
}));

const Footer = () => {

  const { language } = useContext(LayoutContext)
  const classes = useStyles();
  const year = new Date();

  return (
    <Grid className={classes.footer} >
      <Typography variant="body2">{`${config.COPYRIGHT} - ${setLanguageText(language, 'copyright')} © ${year.getFullYear()}`} - v2.20.18</Typography>
    </Grid>
  )
};

export default Footer;
