import React, { useMemo, useState } from 'react';
import {
  Grid,
  Card,
  Typography,
  CardHeader,
  IconButton,
  CardMedia,
  CardContent,
  Button,
  ClickAwayListener,
  TextField,
  CircularProgress,
  Chip,
  CardActions,
  Divider,
  Fade,
  Avatar,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faTimesCircle,
  faEdit,
  faTrash,
  faFilePdf,
  faVideo
} from '@fortawesome/pro-light-svg-icons';
import axiosRequest from '../../helpers/axiosRequest';
import useAsync from '../../Hooks/useAsync';
import { useContext } from 'react';
import { UserContext } from '../../store/UserContext';
import Alert from '@mui/material/Alert';
import GlobalPdfDialog from '../reusable/GlobalPdfDialog';
import config from '../../config';
import setLanguageText from '../../helpers/setLanguageText';
import { LayoutContext } from '../../store/LayoutContext';

const useStyles = makeStyles(theme => ({
  item: {
    position: 'relative'
  },
  card: {
    position: 'relative',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    //backgroundColor: theme.palette.background.light
  },
  cardHeader: {
    backgroundColor: theme.palette.background.light,
    //borderBottom : '1px solid #cbcbcb'
  },
  popover: {
    padding: theme.spacing(2)
  },
  edit : {
    marginRight: '10px',
    backgroundColor: theme.palette.error.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    }
  },
  delete : {
    backgroundColor: theme.palette.error.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    }
  },
  confirm_delete: {
    position: 'absolute',
    zIndex: 2,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0, 0.8)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  confirm_delete_button: {
    backgroundColor: theme.palette.error.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    }
  },
  save: {
    float: 'right',
    backgroundColor: theme.palette.success.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.success.dark
    }
  },
  view_button: {
    backgroundColor: theme.palette.success.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.success.dark
    }
  },
  description: {
    display: 'block',
    padding: theme.spacing(2),
  },
  avatar: {
    background: theme.palette.secondary.main,
    padding: '10px'
  },
  cardFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1),
    borderTop: `1px solid ${theme.palette.background.light}`,
    background: `${theme.palette.background.light}`
  },
}));

const FileCard = ({ resource, getResources }) => {
  const classes = useStyles();
  const { authUser, user } = useContext(UserContext);
  const { language } = useContext(LayoutContext);  
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [editDescription, setEditDescription] = useState(false);
  const [description, setDescription] = useState(resource.resource_description);
  const [title, setTitle] = useState(resource.resource_file_name);
  const [open, setOpen] = useState(false);
  const [hover, setHover] = useState(false);

  // const handlePopoverOpen = () => {
  //   setHover(true);
  // };

  // const handlePopoverClose = () => {
  //   setHover(false);
  // };

  const {
    loading: update_L,
    error: update_R,
    executeAsync: executeUpdate
  } = useAsync({
    axiosRequest
  });
  const {
    loading: delete_L,
    error: delete_E,
    executeAsync: executeDelete
  } = useAsync({
    axiosRequest
  });

  const handleClick = event => {
    setConfirmDelete(true);
  };
  const handleClose = () => {
    setConfirmDelete(false);
  };
  // const handleEditDescription = () => {
  //   setEditDescription(true);
  // };

  // const handleCancelEdit = () => {
  //   setDescription(resource.resource_description);
  //   setEditDescription(false);
  // };
  const handleAcceptEdit = async e => {
    e.preventDefault();
    const response = await executeUpdate({
      endpoint: 'resource',
      api_key: authUser.api_key,
      method: 'patch',
      body: {
        resource_id: resource.resource_id,
        resource_description: description,
        resource_file_name: title
      }
    });
    if (response) {
      setEditDescription(false);
    }
  };

  const handleDeleteFile = async () => {
    const response = await executeDelete({
      endpoint: 'resource',
      api_key: authUser.api_key,
      method: 'delete',
      body: {
        resource_id: resource.resource_id
      }
    });
    if (response) {
      getResources({
        endpoint: 'resource',
        api_key: authUser.api_key,
        method: 'get'
      });
    }
  };

  const handleDescriptionInput = e => {
    e.persist();
    setDescription(e.target.value);
  };

  const handleTileInput = e => {
    e.persist();
    setTitle(e.target.value);
  };

  const date = useMemo(
    () => new Date(resource.resource_upload_time),
    [resource.resource_upload_time]
  );

  //console.log(resource)

  return (
    <>
      <Grid item className={classes.item} xs={12} sm={4} xl={3}>
        <Card className={classes.card}>
          {confirmDelete && (
            <Fade
              in={true}
              timeout={400}
            >
              <div className={classes.confirm_delete}>
                <ClickAwayListener onClickAway={handleClose}>
                  <Button
                    onClick={handleDeleteFile}
                    variant="contained"
                    className={classes.confirm_delete_button}
                  >
                    {setLanguageText(language,"Confirm Delete")}
                  </Button>
                </ClickAwayListener>
              </div>
            </Fade>
          )}

          <CardHeader
            onClick={e => {
              if (!editDescription) {
                setOpen(true)
              }
            }}
            className={classes.cardHeader}
            avatar={
              <Avatar className={classes.avatar} aria-label="file type">
                {resource.resource_type.includes('pdf') ?
                  <FontAwesomeIcon icon={faFilePdf} size="md" />
                  :
                  <FontAwesomeIcon icon={faVideo} size="md" />
                }
              </Avatar>
            }
            title={
              editDescription ? (
                <TextField
                  value={title}
                 label={setLanguageText(language,"File Name")}
                  variant="outlined"
                  fullWidth
                  onChange={handleTileInput}
                  required
                />
              ) : (
                  <Typography>{title}</Typography>
                )}
            subheader={
              <Chip
                size="small"
                label={resource.resource_cat_name}
                style={{ margin: '5px 0 0 -2px' }}
              />
            }
          />
          {/* <CardContent className={classes.card_header}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h6">
                  {resource.resource_file_name}
                </Typography>
              </Grid>
              <Grid item xs={12} >
                <Typography variant="caption">{`${date.toDateString()}, ${date.toLocaleTimeString(
                  'en-GB'
                )}`}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Chip
                  size="small"
                  label={resource.resource_cat_name}
                  style={{ margin: '5px 0 0 -2px' }}
                />
              </Grid>
            </Grid>
          </CardContent> 
          <Divider />
          {user.level === 9 && (
          <IconButton
              onClick={handleClick}
              className={classes.delete_button}
            >
              <FontAwesomeIcon icon={faTrash} size="sm" />
          </IconButton>
          )} 
          <CardContent
            style={{ position: 'relative', backgroundColor: '#fff' }}
            onMouseEnter={() => handlePopoverOpen()}
            onMouseLeave={() => handlePopoverClose()}
          > 
                    
            <CardMedia
              image={
                resource.resource_type.includes('pdf')
                  ? 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1667px-PDF_file_icon.svg.png'
                  : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSnuewJiRqdRec6JlrHakZMO3AMZ7ciNQPrSw&usqp=CAU'
              }
              title="PDF Icon"
              style={{
                height: 200,
                width: '100%',
                backgroundSize: 'contain'
              }}

            /> 
          {confirmDelete && (
              <div className={classes.confirm_delete}>
                {delete_L ? (
                  <CircularProgress />
                ) : delete_E ? (
                  <Alert severity="error" variant="standard">
                    {delete_E.message}
                  </Alert>
                ) : (
                      <ClickAwayListener onClickAway={handleClose}>
                        <Button
                          onClick={handleDeleteFile}
                          variant="contained"
                          className={classes.confirm_delete_button}
                        >
                          Delete File
                  </Button>
                      </ClickAwayListener>
                    )}
              </div>
            )}
            {hover && !confirmDelete && (
              <Fade
                in={true}
                timeout={400}
              >
                <div className={classes.confirm_delete}>
                  <Button
                    onClick={e => setOpen(true)}
                    variant="contained"
                    className={classes.view_button}
                  >
                    View
                  </Button>
                </div>
              </Fade>
            )} 
          </CardContent>
          <Divider />

          <CardContent className={classes.description} >

            <form onSubmit={handleAcceptEdit}>
              {update_L ? (
                <CircularProgress className={classes.loading_edit} size={30} />
              ) : update_R ? (
                <Alert
                  severity="error"
                  variant="standard"
                  className={classes.error_edit}
                >
                  {update_R.message}
                </Alert>
              ) : editDescription && !confirmDelete ? (
                <>
                  <IconButton
                    onClick={handleCancelEdit}
                    className={`${classes.edit_description} ${classes.cancel_edit}`}
                  >
                    <FontAwesomeIcon icon={faTimesCircle} />
                  </IconButton>
                  <IconButton
                    type="submit"
                    className={`${classes.edit_description} ${classes.accept_edit}`}
                  >
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </IconButton>
                </>
              ) : user.level === 9 && !confirmDelete ? (
                <IconButton
                  onClick={handleEditDescription}
                  className={classes.edit_description}
                >
                  <FontAwesomeIcon size="sm" icon={faEdit} />
                </IconButton>
              ) : null}
              {editDescription ? (
                <TextField
                  value={description}
                  variant="outlined"
                  fullWidth
                  onChange={handleDescriptionInput}
                  required
                  multiline
                />
              ) : (
                  <Typography>{description}</Typography>
                )}
            </form>
          </CardContent> */}

          <CardContent
            onClick={e => {
              if (!editDescription) {
                setOpen(true)
              }
            }}
            className={classes.description}
          >
            {editDescription ? (
              <TextField
                value={description}
               label={setLanguageText(language,"Description")}
                variant="outlined"
                fullWidth
                onChange={handleDescriptionInput}
                required
                multiline
              />
            ) : (
                <Typography>{description}</Typography>
              )}
          </CardContent>


          <CardActions disableSpacing className={classes.cardFooter} >

            {editDescription ?
              <>
                <Button
                  onClick={() => setEditDescription(false)}
                  variant="contained"
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleAcceptEdit}
                  variant="contained"
                  className={classes.save}
                >
                  {setLanguageText(language,"Save")}
                </Button>

              </>
              :
              <>
                {user.level === 9 && (
                  <div>
                    <Button
                      onClick={() => setEditDescription(true)}
                      variant="contained"
                      className={classes.edit}
                    >
                      {setLanguageText(language,"Edit")}
                    </Button>
                    <Button
                      onClick={handleClick}
                      variant="contained"
                      className={classes.delete}
                    >
                      {setLanguageText(language,"Delete")}
                    </Button>
                  </div>

                )}

                <Button
                  onClick={e => setOpen(true)}
                  variant="contained"
                  className={classes.view_button}
                >
                   {resource.resource_type.includes('pdf') ? "View PDF" : "Watch Video"}
                </Button>
              </>

            }

          </CardActions>


        </Card>
      </Grid>

      <GlobalPdfDialog
        open={open}
        closeModal={e => setOpen(false)}
        title="View"
        //handleSubmit={handleSubmit}
        //buttonTitle="Add Company"
        //successMessage="Company added successfully!"
        // loading={loading}
        // error={error}
        // data={data}
        //maxWidth="md"
        fullScreen
        style={{
          top: '10%',
          left: '10%',
          width: '80%' ,
          height: '80%'
        }}
        fullWidth
      >
        
          <object
            data={`https://${config.s3.BUCKET}.s3.${config.s3.REGION}.amazonaws.com/public/resources/${resource.resource_id}.${resource.resource_type === 'application/pdf' ? 'pdf' : 'mp4'}`}
            type={resource.resource_type}
            width="100%"
            height="100%"
          >
          </object>
        

      </GlobalPdfDialog>
    </>
  );
};

export default FileCard;
