import React from 'react';
import { Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/pro-light-svg-icons';
import setLanguageText from '../../helpers/setLanguageText';

const companiesTable_columns = (updateSelectCompany,language) => {
  //edit company
  const editCompany = e => {
    console.log(e)
    updateSelectCompany(e);
  };

  let columns = [
    { 
      id: 'company_id',
      name: 'Company ID',
      Header: () => <Typography variant="h6">{setLanguageText(language,"Company ID")}</Typography>,
      accessor: 'company_id'
    },
    {
      id: 'company_name',
      name: 'Company Name',
      Header: () => <Typography variant="h6">{setLanguageText(language,"Company Name")}</Typography>,
      accessor: 'company_name',
      Cell: ({ row, value }) => <Typography>{value}</Typography>,
      Footer: info => {
        const total = React.useMemo(
          () => info.rows.reduce((sum, row) => 1 + sum, 0),
          [info.rows]
        );
        return (
          <strong
            style={{ fontSize: 16, color: '#000' }}
          >{`${setLanguageText(language,"Totals")}: ${total}`}</strong>
        );
      }
    },
    {
      id: 'edit',
      name: 'Edit',
      //Header: () => <Typography variant="h6">Edit</Typography>,
      accessor: 'company_name',
      Cell: ({ row, value }) => (
        <FontAwesomeIcon
          styles={{ cursor: 'hover' }}
          icon={faEdit}
          size="lg"
          onClick={e => editCompany(row)}
        />
      )
    },
    {
      id: 'total_users',
      name: 'Total Users',
      Header: () => <Typography variant="h6">{setLanguageText(language,"Total Users")}</Typography>,
      accessor: 'total_users',
      Cell: ({ row, value }) => <Typography>{value}</Typography>,
      Footer: info => {
        const total = React.useMemo(
          () => info.rows.reduce((sum, row) => row.values.total_users + sum, 0),
          [info.rows]
        );
        return <strong style={{ fontSize: 16, color: '#000' }}>{total}</strong>;
      }
    },
    {
      id: 'level_9',
      name: 'Enterprise Superusers',
      Header: () => <Typography variant="h6">{setLanguageText(language,"Enterprise Superusers")}</Typography>,
      accessor: 'level_9',
      Cell: ({ row, value }) => <Typography>{value}</Typography>,
      Footer: info => {
        const total = React.useMemo(
          () => info.rows.reduce((sum, row) => row.values.level_9 + sum, 0),
          [info.rows]
        );
        return <strong style={{ fontSize: 16, color: '#000' }}>{total}</strong>;
      }
    },
    {
      id: 'level_8',
      name: 'Company Superusers',
      Header: () => <Typography variant="h6">{setLanguageText(language,"Company Superusers")}</Typography>,
      accessor: 'level_8',
      Cell: ({ row, value }) => <Typography>{value}</Typography>,
      Footer: info => {
        const total = React.useMemo(
          () => info.rows.reduce((sum, row) => row.values.level_8 + sum, 0),
          [info.rows]
        );
        return <strong style={{ fontSize: 16, color: '#000' }}>{total}</strong>;
      }
    },
    {
      id: 'level_7',
      name: 'Read/Write Users',
      Header: () => <Typography variant="h6">{setLanguageText(language,"Read/Write Users")}</Typography>,
      accessor: 'level_7',
      Cell: ({ value }) => {
        return <Typography>{value}</Typography>;
      },
      Footer: info => {
        const total = React.useMemo(
          () => info.rows.reduce((sum, row) => row.values.level_7 + sum, 0),
          [info.rows]
        );
        return <strong style={{ fontSize: 16, color: '#000' }}>{total}</strong>;
      }
    },
    {
      id: 'level_6',
      name: 'Read Only Users',
      Header: () => <Typography variant="h6">{setLanguageText(language,"Read Only Users")}</Typography>,
      accessor: 'level_6',
      Cell: ({ value }) => {
        return <Typography>{value}</Typography>;
      },
      Footer: info => {
        const total = React.useMemo(
          () => info.rows.reduce((sum, row) => row.values.level_6 + sum, 0),
          [info.rows]
        );
        return <strong style={{ fontSize: 16, color: '#000' }}>{total}</strong>;
      }
    },
    {
      id: 'total_machines',
      name: 'Total Machines',
      Header: () => <Typography variant="h6">{setLanguageText(language,"Total Machines")}</Typography>,
      accessor: 'total_machines',
      Cell: ({ value }) => {
        return <Typography>{value}</Typography>;
      },
      Footer: info => {
        const total = React.useMemo(
          () =>
            info.rows.reduce((sum, row) => row.values.total_machines + sum, 0),
          [info.rows]
        );
        return <strong style={{ fontSize: 16, color: '#000' }}>{total}</strong>;
      }
    }
  ];

  return columns;
};

export default companiesTable_columns;
