import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  Fragment
} from 'react';
import {
  Grid,
  Button,
  Dialog,
  Typography,
  Chip,
} from '@mui/material';
import PageContent from '../PageContent';
import PageHeader from '../PageHeader';
import CompanyVariables from './CompanyVariables';
import TableBasic from './TableBasic';
import { fetchCompanyAttributes } from '../../helpers/fetchCompanyAttributes';
import { CompanyContext } from '../../store/CompanyContext';
import { UserContext } from '../../store/UserContext';
import { buttonStyles } from '../../styles/buttonStyles';
import tableChipStyle from '../../styles/tableChipStyles';
import { FormStepper } from './Stepper';
import { createCompanyAttributes } from '../../helpers/createCompanyAttributes';
import { makeStyles } from '@mui/styles';
import setLanguageText from '../../helpers/setLanguageText';
import { LayoutContext } from '../../store/LayoutContext';

const useStyles = makeStyles(theme => ({
  header: {
    borderBottom: `${theme.spacing(0.4)}px solid ${theme.palette.background.light
      }`,
    padding: theme.spacing(1)
  }
}));

function CompanyAttributes({ edit }) {
  const { language } = useContext(LayoutContext)
  const { selectedCompany } = useContext(CompanyContext);
  const { authUser } = useContext(UserContext);
  const [selectedAttribute, setSelectedAttribute] = useState();
  const [currentAttributes, setCurrentAttributes] = useState();
  const [currentVariables, setCurrentVariables] = useState();
  const buttonStyle = buttonStyles();
  const [open, setOpen] = useState(false);

  //************  STYLES ************
  const classes = useStyles();
  const chipClasses = tableChipStyle();

  //---------------------- FETCH COMPANY ATTRIBUTES ------------------------//

  const getCompany = useCallback(
    (api_key, company_id) => {
      fetchCompanyAttributes(api_key, 'get', company_id).then(attrs => {
        setCurrentAttributes(JSON.parse(attrs[0].company_attributes));
        setCurrentVariables(JSON.parse(attrs[0].company_colour_rules));
      });
    },
    [setCurrentAttributes]
  );

  // useEffect(() => {
  //   if (selectedCompany.company_id && authUser) {
  //     getCompany(authUser.api_key, selectedCompany.company_id);
  //   }
  // }, [getCompany, selectedCompany.company_id, authUser]);

  useEffect(() => {
    if (selectedCompany) {
      setCurrentAttributes(selectedCompany.company_attributes);
      setCurrentVariables(selectedCompany.company_colour_rules);
    }
  }, [selectedCompany]);

  //---------------------- DIALOG ------------------------//

  const handleClose = () => {
    setOpen(false);
  };

  const addNewAttribute = () => {
    //remove selected attribute
    setSelectedAttribute();
    setOpen(true);
  };

  const editAttribute = selected => {
    //console.log(selected)
    //add selected attribute to edit
    setSelectedAttribute(selected);
    setOpen(true);
  };

  const closeModalRefresh = () => {
    setSelectedAttribute();
    setOpen(false);
    //refresh table
    getCompany(authUser.api_key, selectedCompany.company_id);
  };

  const removeAttribute = selected => {

    //console.log(selected)
    //console.log(currentAttributes)
    //console.log(selectedCompany)
    let updatedRules = currentAttributes.filter(rule => rule.one.attr_id !== selected.one.attr_id)
    //console.log(updatedRules)
    let body = {
      id : selectedCompany.company_id,
      //id: selected.one.company_id,
      attributes: updatedRules
    };
    createCompanyAttributes(authUser.api_key, 'post', body).then(result => {
      //refetch details for table
      getCompany(authUser.api_key, selectedCompany.company_id);
    });

  };

  //---------------------- RULES ------------------------//

  const createRules = useCallback(
    data => {
      //console.log(currentVariables)
      //console.log(data.cell.value);
      let object = [...currentVariables];

      if (data.cell.value) {
        return data.cell.value.map((item, i) => {
          //set colour by matching the value to global variables
          let found = object.find(obj => obj.value === item.status);
          //console.log(colour)
          return (
            <div key={i}>
              <strong> {item.name}: </strong>

              {item.logic && (
                <Fragment>
                  <strong>IF </strong>

                  {item.logic.map((logic, i) => {
                    return (
                      <span key={i}>
                        @VALUE <strong>{logic.operator}</strong> {logic.value}
                        <strong>
                          {' '}
                          {logic.connection && logic.connection}{' '}
                        </strong>
                      </span>
                    );
                  })}
                </Fragment>
              )}
              <span>
                <strong></strong>
                <Chip
                  size="small"
                  icon={null}
                  className={`${chipClasses.chip}`}
                  style={{
                    backgroundColor: found ? found.colour.hex : 'transparent'
                  }}
                  label={<span>{item.label ? item.label : '@VALUE'}</span>}
                />
              </span>
            </div>
          );
        });
      } else {
        return <p>no rules</p>;
      }
    },
    [currentVariables, chipClasses]
  );

  //---------------------- ATTRIBUTES TABLE ------------------------//

  const columns = React.useMemo(
    () => [
      {
        id: 'attr_id',
        hidden: true,
        Header: () => <Typography variant="h6">{setLanguageText(language,"Attribute ID")}</Typography>,
        accessor: 'one.attr_id'
      },
      {
        id: 'attr_name',
        Header: () => <Typography variant="h6">{setLanguageText(language,"Attribute Name")}</Typography>,
        accessor: 'one.attr_name'
      },
      {
        id: 'attr_locked',
        hidden : !edit,
        Header: () => <Typography variant="h6">{setLanguageText(language,"Locked")}</Typography>,
        accessor: 'one.attr_locked',
        Cell: data => JSON.stringify(data.cell.value)
      },
      {
        id: 'attr_column',
        hidden : !edit,
        Header: () => <Typography variant="h6">{setLanguageText(language,"Columns")}</Typography>,
        accessor: 'one.attr_column',
        Cell: data => JSON.stringify(data.cell.value)
      },
      {
        id: 'attr_rules',
        Header: () => <Typography variant="h6">{setLanguageText(language,"Rules")}</Typography>,
        accessor: 'two.attr_rules',
        Cell: data => createRules(data)
      },
      {
        id: 'attr_actions',
        hidden : !edit,
        Cell: data => (
          <div className="form-button">
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => editAttribute(data.row.original)}
              className={`${buttonStyle.button}`}
              disabled={!edit}
            >
              {setLanguageText(language,"Edit")}
            </Button>

            {!data.row.original.one.attr_locked ? (
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                onClick={() => removeAttribute(data.row.original)}
                className={`${buttonStyle.button}`}
                disabled={!edit}
              >
                {setLanguageText(language,"Remove")}
              </Button>
            ) : null}
          </div>
        )
      }
    ],
    [edit, buttonStyle, createRules]
  );

  return (
    <>
      <PageHeader>
        <Grid item xs={12}>
          <Grid
            item
            container
            justifyContent="space-between"
            className={classes.header}
          >
            <Grid item xs={12} sm={'auto'}>
              <Typography variant="h5">{setLanguageText(language,"Attributes")}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </PageHeader>
      <PageContent>
        {/* <Grid item container xs={12} spacing={1}>
          <Grid
            item
            xs={12}
            style={{
              marginBottom: useTheme().spacing(1)
            }}
          >
            <Alert severity="error">Please do not edit any of the statuses below</Alert>
          </Grid>
        </Grid> */}
        <Grid item container xs={12} spacing={1}>
          <Grid item xs={12}>
            <CompanyVariables
              currentVariables={currentVariables}
              closeModalRefresh={closeModalRefresh}
              edit={edit}
            />
          </Grid>
          {/* <Grid
            item
            xs={12}
            style={{
              marginBottom: useTheme().spacing(1)
            }}
          >
            <Alert severity="error">Please do not edit any of the attributes below</Alert>
          </Grid> */}
          <Grid item xs={12}>
            {currentAttributes && currentVariables && (
              <TableBasic 
                columns={columns} 
                data={currentAttributes} 
              />
            )}
          </Grid>
          {edit &&
            <Grid item container xs={12} spacing={1}>
              <Grid item xs={3}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => addNewAttribute()}
                  className={`${buttonStyle.button}`}
                  disabled={!edit}
                >
                  {setLanguageText(language,"Add a new Attribute")}
              </Button>
              </Grid>
            </Grid>
          }
        </Grid>

        <Dialog
          fullWidth={true}
          maxWidth={'md'}
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          {
            <FormStepper
              selectedAttribute={selectedAttribute}
              currentAttributes={currentAttributes}
              currentVariables={currentVariables}
              closeModalRefresh={closeModalRefresh}
            />

            // <AttributesForm
            //   currentAttributes={currentAttributes}
            //   selectedAttribute={selectedAttribute}
            //   closeModalRefresh={closeModalRefresh}
            // />
          }
        </Dialog>
      </PageContent>
    </>
  );
}

export default CompanyAttributes;
