import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { Grid, TextField } from '@mui/material';
import useAsync from '../../Hooks/useAsync';
import axiosRequest from '../../helpers/axiosRequest';
import { UserContext } from '../../store/UserContext';
//import { CompanyContext } from '../../store/CompanyContext';
import ReportCard from './ReportCard';
import ReportPDF from './ReportPDF';
import { Document, Page, Text, View } from '@react-pdf/renderer';
import parseEntries from '../../helpers/parseEntries';
import create_pdf_headers from './create_pdf_headers';
import create_pdf_rows from './create_pdf_rows';
import splitString from '../../helpers/splitString';
import slugifyString from '../../helpers/slugifyString';
import formatDate from '../../helpers/formatDate';
import { Autocomplete } from '@mui/material';
import { isEmpty } from 'lodash-es';
import { fetchMachines } from '../../helpers/fetchMachines';
import extractGroups from '../../helpers/extractGroups';
import removeAllOption from '../../helpers/removeAllOption';
import { LayoutContext } from '../../store/LayoutContext';
import setLanguageText from '../../helpers/setLanguageText';

const CoolantUsageByCompany = ({companies}) => {

  //******************* USE CONTEXT *******************
  const { authUser, user } = useContext(UserContext);
  const { language } = useContext(LayoutContext);

  //******************* USE STATES *******************

  const [open, setOpen] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [pdf_data, setPdf_data] = useState(null);
  const [csv_data, setCsv_data] = useState([]);
  
  const [selectedCompany, setSelectedCompany] = useState();
  const [selectedGroup, setSelectedGroup] = useState();

  const [groups, setGroups] = useState([]);
  const { error, data, loading, executeAsync, setData } = useAsync({
    axiosRequest: axiosRequest
  });
  const [noData, setNoData] = useState(false);

  //******************* USE CALLBACKS *******************
  const runReport = useCallback(() => {
    setOpen(true);
    //console.log('test')
    const params = selectedGroup.group_id
      ? {
        company_id: selectedCompany.company_id,
        group_name: selectedGroup.group_name
      }
      : { company_id: selectedCompany.company_id };

    executeAsync({
      method: 'get',
      api_key: authUser.api_key,
      endpoint: `reports/coolant-usage`,
      params
    });

  }, [authUser, executeAsync, selectedCompany, selectedGroup]);

  //************************ USE MEMO ************************
  const exclude_columns = useMemo(() => ['machine_id', 'machine_group'], []);
  const expand_columns = useMemo(() => ['machine_name', 'coolant_name'], []);
  const reduce_columns = useMemo(() => ['coolant_id'], []);
  // const companies = useMemo(
  //   () => companiesContext.slice(1),
  //   [companiesContext]
  // );

  const csv_filename = useMemo(
    () =>
      selectedGroup &&
      `report_${slugifyString(selectedCompany.company_name)}_${slugifyString(
        selectedGroup.group_name
      )}`,
    [selectedCompany, selectedGroup]
  );


  //******************* USE EFFECTS *******************
  // react-pdf throws an error if PDFViewer is open and repass data again, so we save it locally to only render the pdf once
  useEffect(() => {
    if (data && !pdf_data) {
      if (!data.length) return setNoData(true);
      const parse_attributes_new = parseEntries(data);
      setPdf_data(parse_attributes_new);
      setShowPreview(true);
    }
  }, [data, pdf_data]);

  useEffect(() => {
    if (noData)
      setTimeout(() => {
        setNoData(false);
      }, 3000);
  }, [noData]);

  // const user_company = useMemo(() => {
  //   if (companies.length) {
  //     const get_user_company = companies.filter(
  //       company => company.company_id === +user.company_id
  //     )[0];
  //     setSelectedCompany(get_user_company);
  //     return get_user_company;
  //   }
  // }, [companies, user]);

  useEffect(() => {
    if (!isEmpty(selectedCompany)) {
      fetchMachines(authUser.api_key, true, selectedCompany.company_id).then(
        machines => {
          const AddAllOption = [
            { group_name: setLanguageText(language,'All Groups'), group_id: 0 },
            ...extractGroups(machines)
          ];
          setSelectedGroup(AddAllOption[0]);
          setGroups(AddAllOption);
        }
      );
    }
  }, [authUser.api_key, selectedCompany]);

  //************************ USE CALLBACK ************************

  const create_pdf_data = useCallback(
    (styles) => {
      return (
        <Document key="Coolant Usage by Company" title="Coolant Usage by Company">
          <Page style={styles.BODY}>
            <View fixed style={styles.ROW_ABOVE_TABLE}>
              <Text style={styles.ROW_ABOVE_TABLE.BIG_TEXT}>
                {selectedCompany.company_name}
              </Text>
              <Text style={styles.ROW_ABOVE_TABLE.SMALL_TEXT}>
              {setLanguageText(language,"Total Machines")}: {data.length}
              </Text>
            </View>
              <View style={styles.TABLE}>
                <View style={styles.TABLE.ROW} fixed>
                  {create_pdf_headers({
                    language: language,
                    headers: pdf_data[0],
                    styles,
                    exclude_columns,
                    expand_columns,
                    reduce_columns
                  })}
                </View>
                {pdf_data && create_pdf_rows({
                  rows: pdf_data,
                  styles,
                  group_value: 'machine_group',
                  exclude_columns,
                  expand_columns,
                  reduce_columns
                })}
              </View>
            <Text
              style={styles.PAGE_NUMBER}
              render={({ pageNumber, totalPages }) =>
                `${pageNumber} / ${totalPages}`
              }
              fixed
            />
          </Page>
        </Document>
      );
    },
    [
      data,
      pdf_data,
      exclude_columns,
      expand_columns,
      reduce_columns,
      selectedCompany
    ]
  );
  
  const create_csv_data = useCallback(() => {
    const headers = Object.keys(pdf_data[0]).map(header => splitString(header));
    const values = pdf_data.map(value => {
      let format_dates = { ...value };
      if (value.date_stamp)
        format_dates = {
          ...format_dates,
          date_stamp: formatDate(value.date_stamp)
        };
      if (value.last_coolant_change)
        format_dates = {
          ...format_dates,
          last_coolant_change: formatDate(value.last_coolant_change)
        };
      return Object.values(format_dates);
    });
    setCsv_data([headers, ...values]);
    //setShowPreview(true);
  }, [pdf_data]);

  //************************ FUNCTIONS ************************
  const handleSelectCompany = async (e, newValue) => {
    setSelectedCompany(newValue);
  };

  //add groups counter
  const modifiedValue = useMemo(() => {
    if (selectedGroup)
      return selectedGroup.group_id === 0
        ? {
          ...selectedGroup,
          group_name: `${selectedGroup.group_name} (${groups.length - 1})`
        }
        : selectedGroup;
    return {};
  }, [groups, selectedGroup]);


  return <>
    <ReportCard
      title="Coolant Usage by Company"
      buttonLabel="Run Report"
      loading={loading}
      disabled={!selectedCompany || !selectedGroup ? true : false}
      error={error}
      runReport={runReport}
      noData={noData}
    >
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            disableClearable
            id="selected-company"
            value={selectedCompany || ''}
            options={removeAllOption(companies) || []}
            loading={companies ? false : true}
            getOptionLabel={option => option.company_name || ''}
            isOptionEqualToValue={(option, value) =>
              option.company_id === value.company_id
            }
            onChange={handleSelectCompany}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                label={setLanguageText(language,"Select Company")}
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            disableClearable
            id="selected-group"
            value={modifiedValue}
            disabled={!selectedCompany}
            options={groups || []}
            loading={groups ? false : true}
            getOptionLabel={option => option.group_name || ''}
            //loading={machines.length ? false : true}
            onChange={(event, newValue) => {
              setSelectedGroup(
                newValue || { group_name: setLanguageText(language,'ALL'), group_id: 0 }
              );
            }}
            isOptionEqualToValue={(option, value) =>
              option.group_id === value.group_id
            }
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                label={setLanguageText(language,"Selected Group")}
              />
            )}
          />
        </Grid>
      </Grid>
    </ReportCard>
    {open &&
        <ReportPDF
          dialog_title="Coolant Usage by Company"
          dialogOpen={open}
          dialogClose={e => {
            //close and reset
            setOpen(false);
            setData();
            setPdf_data(null);
            setCsv_data([]);
            setShowPreview(false);
          }}
          showPreview={showPreview}
          create_pdf_data={create_pdf_data}
          create_csv_data={create_csv_data}
          csv_data={csv_data}
          csv_filename={csv_filename}
        />
      }
  </>;
};

export default CoolantUsageByCompany;
