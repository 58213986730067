import React, {
  useState,
  useEffect,
  useCallback,
  useContext
} from 'react';
import {
  faFileCsv,
  faFile,
  faFilePdf,
  faFileExport
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Switch,
  FormControlLabel,
  Typography,
  Grid,
  List,
  ListItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CardContent,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import formatDate from '../../helpers/formatDate';
import { buttonStyles } from '../../styles/buttonStyles';
import AllMachinesPDF from '../pdf/AllMachinesPDF';
import { CSVLink } from 'react-csv';
import { CompanyContext } from '../../store/CompanyContext';
import GlobalPdfDialog from '../reusable/GlobalPdfDialog';
import { ExpandMore } from '@mui/icons-material';
import LoadingScreen from '../reusable/LoadingScreen';
import LoadingButton from '../buttons/LoadingButton';
import { MachineContext } from '../../store/MachineContext';
import calcDaysPast from '../../helpers/calcDaysPast';
import Orientation from '../reusable/Orientation';
import { LayoutContext } from '../../store/LayoutContext';
import setLanguageText from '../../helpers/setLanguageText';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  orientation: {
    marginBottom: theme.spacing(1),
    '& .MuiToggleButton-root.Mui-selected': {
      backgroundColor: theme.palette.primary.main
    }
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  }
}));

const ExportMachines = ({ columns, data }) => {

  //styles
  const classes = useStyles();
  const buttonStyle = buttonStyles();
  //context
  const { selectedCompany } = useContext(CompanyContext);
  const { selectedGroup } = useContext(MachineContext);
  const { language } = useContext(LayoutContext);
  //states
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState();
  const [selectedData, setSelectedData] = useState();
  const [csvData, setCsvData] = useState();
  const [pdfData, setPdfData] = useState();
  const [orientation, setOrientation] = useState('landscape');
  const [showPreview, setShowPreview] = useState(false);
  const [localOptions, setLocalOptions] = useState([]);
  const [reload, setReload] = useState(false);
  const [downloadObject, setDownloadObject] = useState();

  /*------------- SET CHIP COLOURS  ---------------*/

  const setColour = useCallback(
    status => {
      let colour = '';
      selectedCompany.company_colour_rules.some(attr => {
        if (parseInt(attr.value) === parseInt(status)) {
          // remove transparent as pdf renders as black!
          colour = attr.colour.hex === 'transparent' ? '' : attr.colour.hex;
        }
        return null;
      });
      return colour;
    },
    [selectedCompany.company_colour_rules]
  );

  /*------------- OPEN POPUP ---------------*/

  const handleClick = e => {
    setAnchorEl(e.currentTarget);
    setShowPreview(true);
  };
  const handleClose = () => {
    setShowPreview(false);
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;


  /*------------- OPTIONS ---------------*/

  const handleChange = event => {
    //console.log(event.target.name)
    event.persist()
    setShowPreview(false);
    setReload(true);
    //console.log(event.target.name)
    setLocalOptions(old => {
      let newOptions = [...old];
      let index = old.findIndex(obj => obj.id === event.target.name);
      //console.log(index)
      newOptions[index].isVisible = !newOptions[index].isVisible;
      return newOptions;
    });

  };

  /*------------- DATA ---------------*/

  
  useEffect(() => {
    if (data && columns) {
      setSelectedData(data);
      setSelectedOptions(columns);
    }
  }, [columns, data])

  useEffect(() => {

    // console.log(selectedOptions);
    //console.log(selectedData);

    if (selectedOptions && selectedData) {

      const csvData = [];
      const pdfData = [];
      const headers = [];
      const options = [];

      selectedOptions.forEach(option => {
        if (option.isVisible) {
          headers.push(option.name);
          options.push(option.id);
        }
      });
      selectedData.forEach(item => {
        const csvItem = [];
        const pdfItem = [];

        /*------------- FLATTEN OBJECT ---------------*/
        const flattenObject = obj => {
          const flattened = {};
          Object.keys(obj).forEach(key => {
            if (typeof obj[key] === 'object' && obj[key] !== null) {
              Object.assign(flattened, flattenObject(obj[key]));
            } else {
              flattened[key] = obj[key];
            }
          });
          return flattened;
        };


        let obj;
        //flatten the new attributes
        let factor = item.last_entry.factor
        if (item.last_entry.attributes_new) {
          let array = [];
          item.last_entry.attributes_new.forEach(item => {
            //console.log(item.attribute_id)
            if (item.attribute_id === 'conc' && factor) {
              array.push([[item.attribute_id], { 'factor': factor, ...item }]);
            } else {
              array.push([[item.attribute_id], item]);
            }
          });
          obj = Object.fromEntries(array);
        }

        //console.log(obj)

        let flatObj = { ...obj, ...flattenObject(item) };
        if (item.last_entry.additives) {
          flatObj.additives = item.last_entry.additives
        }
        //console.log(flatObj)

        options.forEach(option => {
          //match options
          let match = Object.entries(flatObj).find(
            ([key, value]) => key === option
          );
          //console.log(match)
          if (match) {
            //if dates, format
            if (
              match[0] === 'date_stamp' ||
              match[0] === 'last_coolant_change'
            ) {
              csvItem.push(formatDate(match[1]));
              pdfItem.push({ value: match[1] ? ` ${formatDate(match[1])} \n ${calcDaysPast(match[1], language, setLanguageText)}` : '-' });
            } else if (
              match[0] === 'additives'
            ) {
              //console.log(match[1])
              if (match[1]?.length) {
                let string = match[1].map(add => `${add.additive_type} : ${add.additive_name}(${add.additive_details})\n`)
                csvItem.push(string);
                pdfItem.push({ value: string });

              } else {
                csvItem.push('-');
                pdfItem.push({ value: '-' });
              }

            } else {
              //Custom Attributes
              if (match[1] && typeof match[1] === 'object') {
                let control = setColour(match[1].attribute_values.status);
                if (match[1].factor) {
                  csvItem.push(`${(Number(match[1].attribute_values.output) * Number(match[1].factor ? match[1].factor : 1)).toFixed(1)}(${match[1].attribute_values.output})`);
                  pdfItem.push({
                    value: `${(Number(match[1].attribute_values.output) * Number(match[1].factor ? match[1].factor : 1)).toFixed(1)} (${match[1].attribute_values.output})`,
                    control: control
                  });
                } else {
                  csvItem.push(match[1].attribute_values.output);
                  pdfItem.push({
                    value: match[1].attribute_values.output,
                    control: control
                  });
                }
              } else {
                //All other attributes
                csvItem.push(match[1]);
                pdfItem.push({
                  value: match[1]
                });
              }
            }
            //null values
          } else {
            csvItem.push('');
            pdfItem.push({ value: '' });
          }
        });
        csvData.push(csvItem);
        pdfData.push(pdfItem);
      });
      //add header to the top
      csvData.unshift(headers);
      setCsvData(csvData);
      pdfData.unshift(headers);
      setPdfData(pdfData);
      //console.log(pdfData);
    }
    return () => {
      //cleanup
      setCsvData();
      setPdfData();
    }
  }, [selectedOptions, selectedData, setColour]);

  //set Local options 
  useEffect(() => {
    if (selectedOptions) {
      const localOptions = [];
      //console.log(selectedOptions)
      selectedOptions.forEach(option => {
        localOptions.push({ "id": option.id, "name": option.name, "isVisible": option.isVisible });
      });
      setLocalOptions(localOptions);
    }
    return () => {
      setLocalOptions();
    }
  }, [selectedOptions]);

  //Show Reload Button
  const reloadPDF = () => {
    setReload(false)
    //setShowPreview(true);
    setSelectedOptions(localOptions);
    setTimeout(() => {
      setShowPreview(true)
    }, 100)
  };


  return (
    <>
      <Button
        disabled={(selectedData && selectedOptions) ? false : true}
        variant="contained"
        onClick={e => handleClick(e)}
        size="large"
        endIcon={<FontAwesomeIcon icon={faFileExport} />}
      >
        {setLanguageText(language, "Export Machines")}
      </Button>

      {/* <LoadingButton
        aria-describedby={id}
        variant="contained"
        loading={(selectedData && selectedOptions) ? false : true}
        disabled={(selectedData && selectedOptions) ? false : true}
        onClick={e => handleClick(e)}
        className={buttonStyle.button}
        icon={faFileExport}
        text={'Export Machines'}
        fullWidth={false}
        iconSize="1x"
        color="primary"
      /> */}
      <GlobalPdfDialog
        open={open}
        title={`${selectedCompany.company_name} - ${setLanguageText(language, "Machines")}`}
        closeModal={handleClose}
        maxWidth="xl"

        //onEntered={e => setShowPreview(true)}
        //email={true}
        companyName={selectedCompany.company_name}
        download={downloadObject ? true : false}
        downloadObject={downloadObject}
        csvDownloadComp={
          <CSVLink
            className={buttonStyle.buttonText}
            data={csvData || []}
            filename={`${selectedCompany.company_name} - ${setLanguageText(language, "Machines")}.csv`}
          >
            <Button
              variant="contained"
              color="primary"
              className={buttonStyle.button}
              disabled={csvData && Object.values(csvData).length ? false : true}
            >
              {setLanguageText(language, "Download as CSV")}
              <FontAwesomeIcon icon={faFileCsv} style={{ marginLeft: '10px' }} />
            </Button>
          </CSVLink>
        }
      >
        <Grid container style={{ height: "100%", overflow: "auto" }} >
          <Grid container item xs={4} md={3} style={{ height: "100%", overflow: "auto", borderRight: '1px solid #ccc', background: '#fbfbfb' }} >
            <CardContent>
              <Grid container item spacing={2} >
                <Orientation
                  orientation={orientation}
                  setOrientation={setOrientation}
                  setShowPreview={setShowPreview}
                  setReload={setReload}
                />
              </Grid>
              <Grid item xs={12}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>
                      {setLanguageText(language, "Column Options")}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <List>
                      {localOptions.map(setting => (
                        <ListItem style={{ borderTop: '1px solid #ececec' }} key={setting.id}>
                          <FormControlLabel
                            control={
                              <Switch
                                onChange={handleChange}
                                checked={setting.isVisible}
                                name={setting.id}
                                //disabled={value === 'machine_name'}
                                color="primary"
                              />
                            }
                            name={setting.name}
                            label={setLanguageText(language, setting.name)}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </CardContent>
          </Grid>
          <Grid item xs={8} md={9} style={{ height: "100%" }}>
            {showPreview && pdfData && orientation ?

              <AllMachinesPDF
                title="Machines"
                rows={pdfData}
                orientation={orientation}
                selectedCompany={selectedCompany}
                selectedGroup={selectedGroup}
                setDownloadObject={setDownloadObject}
                language={language}
              />

              : reload ?
                <Grid item style={{ height: "100%" }} container alignContent="center" justifyContent="center">
                  <Grid item>
                    <LoadingButton
                      variant="contained"
                      onClick={e => reloadPDF(e)}
                      className={buttonStyle.button}
                      icon={faFilePdf}
                      text={'Update Preview'}
                      fullWidth={false}
                      iconSize="1x"
                      color="primary"
                    />
                  </Grid>
                </Grid>
                :
                <LoadingScreen
                  loading={false}
                  background="#666"
                  colour="#fff"
                  text="Loading PDF Preview"
                  position="relative"
                />
            }
          </Grid>
        </Grid>
      </GlobalPdfDialog>
    </>
  );
};

export default ExportMachines;
