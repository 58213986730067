import React, { useContext, useState } from 'react';
import {
  faPlus,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Grid,
  Typography
} from '@mui/material';
import DropdownCompanies from '../../Dashboard/Selection_Row/DropdownCompanies';
import SelectionRow from '../../Dashboard/Selection_Row/SelectionRow';
import PageHeader from '../../PageHeader';
import { buttonStyles } from '../../../styles/buttonStyles';
import { ProductsContext } from '../../../store/ProductsContext';
import AddProducts from './AddProducts';
import DropdownCompaniesNew from '../../reusable/DropdownCompaniesNew';
import setLanguageText from '../../../helpers/setLanguageText';
import { LayoutContext } from '../../../store/LayoutContext';

//********** COMPONENT ************
const ProductsHeaderAdmin = () => {

  const buttonStyle = buttonStyles();
  const { language } = useContext(LayoutContext);
  const {
    productsOptions,
    productTypes
  } = useContext(ProductsContext);

  const [isAddDialog, setIsAddDialog] = useState(false);
  const openAddDialog = () => setIsAddDialog(true);
  const closeAddDialog = () => setIsAddDialog(false);

  return (
    <>
      <PageHeader>
        <Grid
          container
          alignItems="stretch"
          justifyContent="space-between"
          spacing={2}
        >
          <Grid item xs={12} md={4}>
            <DropdownCompaniesNew removeAll />
            {/* <SelectionRow >
              <DropdownCompanies />
            </SelectionRow> */}
          </Grid>
          <Grid item container justifyContent="flex-end" xs={12} md={4}>
            <Button
              variant="contained"
              color="primary"
              onClick={openAddDialog}
            >
              <FontAwesomeIcon icon={faPlus} size="lg" />
              <Typography className={buttonStyle.buttonIconText}>
              {setLanguageText(language,"Add Product")}
              </Typography>
            </Button>
          </Grid>

        </Grid>
        {isAddDialog &&
          <AddProducts
            closeAddDialog={closeAddDialog}
            isAddDialog={isAddDialog}
            typeOptions={productTypes}
          />
        }
      </PageHeader>

    </>
  );
};

export default ProductsHeaderAdmin;
