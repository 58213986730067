import { snakeCase } from 'lodash';

const extractMachineGroups = machines => {
  // Machines is an empty array
  if (!machines.length) {
    return machines;
  }

  const groupNames = machines.map(machine => machine.machine_group);
  const uniqueGroups = [...new Set(groupNames)];
  // format for React Select
  const groups = uniqueGroups.map(group => {
    return {
      group_id: snakeCase(group),
      group_name: group
    };
  });
  return groups;
};
export default extractMachineGroups;
