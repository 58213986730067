import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import {
  Tabs,
  Tab
} from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import CompanyAttributes from '../Attributes/CompanyAttributes';
import DropdownCompanies from '../Dashboard/Selection_Row/DropdownCompanies';
import SelectionRow from '../Dashboard/Selection_Row/SelectionRow';
import { isMobileOnly } from 'react-device-detect';
import CompanyDetails from './CompanyDetails';
import CompanyDefaults from './CompanyDefaults';
import Theme from '../Theme/Theme';
import { LayoutContext } from '../../store/LayoutContext';
import CompanyNotification from '../Notifications/CompanyNotification';
import DisplaySettings from '../Display/DisplaySettings';
import Users from '../Users/Users';
import { UserContext } from '../../store/UserContext';
import setLanguageText from '../../helpers/setLanguageText';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: isMobileOnly ? 'block' : 'flex',
    //height: 224,
  },
  dropdown: {
    width: '100%',
    padding: '10px 10px 10px 0',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    minWidth: isMobileOnly ? '100%' : '240px',
    overflow: 'visible',
    background: '#fafafa'
  },
  tabs_content: {
    width: '100%',
    overflow: 'auto',
  },
}));



function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      className={classes.tabs_content}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        children
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function createProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const CompanySettings = () => {

  let history = useHistory();
  let { tab } = useParams();
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [edit, setEdit] = useState(false);
  const { language } = useContext(LayoutContext);
  const { user } = useContext(UserContext);

  useEffect(() => {
    //console.log(tab)
    setValue(parseInt(tab));
  }, [tab])

  const handleChange = (event, newValue) => {
    history.push({ pathname: `/settings/company/${newValue}` })
    setValue(parseInt(newValue));
  };

  //add edit to form items
  useEffect(() => {
    if (user.level > 7) {
      setEdit(true);
    } else {
      setEdit(false);
    }
  }, [user])

  return (
    <>
      <div className={classes.dropdown} >
        <SelectionRow>
          <DropdownCompanies fetchEntries={false} />
        </SelectionRow>
      </div>
      <div className={classes.root}>
        <Tabs
          allowScrollButtonsMobile
          orientation={isMobileOnly ? 'horizontal' : 'vertical'}
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Company Settings Menu"
          className={classes.tabs}
        >
          <Tab label={setLanguageText(language,"Company Details")} {...createProps(0)} />
          {edit && <Tab label={setLanguageText(language,"Company Users")} {...createProps(1)} />}
          {edit && <Tab label={setLanguageText(language,"Company Defaults")} {...createProps(1)} />}
          <Tab label={setLanguageText(language,"Theme")} {...createProps(edit ? 3 : 2)} />
          <Tab label={setLanguageText(language,"Attributes")} {...createProps(edit ? 4 : 3)} />
          <Tab label={setLanguageText(language,"Display View")} {...createProps(edit ? 5 : 4)} />
          <Tab label={setLanguageText(language,"Notifications")} {...createProps(edit ? 6 : 5)} />

        </Tabs>
        <TabPanel value={value} index={0}>
          <CompanyDetails edit={edit} />
        </TabPanel>
        {edit &&
          <TabPanel value={value} index={1}>
            <Users />
          </TabPanel>
        }
        {edit &&
          <TabPanel value={value} index={2}>
            <CompanyDefaults />
          </TabPanel>
        }
        <TabPanel value={value} index={edit ? 3 : 2}>
          <Theme edit={edit} type="company"/> 
        </TabPanel>
        <TabPanel value={value} index={edit ? 4 : 3}>
          <CompanyAttributes edit={edit} />
        </TabPanel>
        <TabPanel value={value} index={edit ? 5 : 4}>
          <DisplaySettings edit={edit} />
        </TabPanel>
        <TabPanel value={value} index={edit ? 6 : 5}>
          <CompanyNotification edit={edit} />
        </TabPanel>
      </div>
    </>
  );
}

export default CompanySettings;


