import { TextField } from '@mui/material';
import { Alert, Autocomplete } from '@mui/material';
import { snakeCase } from 'lodash-es';
import React, { useContext, useEffect, useState } from 'react';
import axiosRequest from '../../helpers/axiosRequest';
import setLanguageText from '../../helpers/setLanguageText';
import useAsync from '../../Hooks/useAsync';
import { UserContext } from '../../store/UserContext';
import { LayoutContext } from '../../store/LayoutContext';

const DropdownAdditivesByType = ({ additive_type, setSelectedAdditive }) => {
  const { authUser } = useContext(UserContext);
  const { language } = useContext(LayoutContext); 
  const [additives, setAdditives] = useState([]);
  const [value, setValue] = useState(null);
  const { error, data, loading, executeAsync } = useAsync({
    axiosRequest: axiosRequest
  });

  useEffect(() => {
    if (additive_type) {
      executeAsync({
        method: 'get',
        api_key: authUser.api_key,
        endpoint: 'additives',
        params: { additive_type: snakeCase(additive_type.additive_type_name) }
      });
    }
  }, [additive_type, authUser.api_key, executeAsync]);

  useEffect(() => {
    if (data) {
      setAdditives(data);
      setValue(data[0]);
    }
  }, [data]);

  useEffect(() => {
    if (value) {
      setSelectedAdditive(value);
    }
  }, [setSelectedAdditive, value]);

  const onSelectCoolant = (e, coolant) => {
    setValue(coolant);
  };

  if (error) return <Alert severity="error">{error.message}</Alert>;
  return (
    <Autocomplete
    disableClearable
      id="additives"
      options={additives}
      loading={loading}
      getOptionLabel={option => option.additive_name || ''}
      renderInput={params => (
        <TextField
          required
          {...params}
         label={setLanguageText(language,"Select Additive")}
          variant="outlined"
        />
      )}
      value={value}
      onChange={onSelectCoolant}
    />
  );
};

export default DropdownAdditivesByType;
