import axios from 'axios';
import config from '../config';
import Auth from '@aws-amplify/auth';
import { Signer } from '@aws-amplify/core';
import queryString from 'query-string';

const axiosRequest = async ({ endpoint, api_key, method, params, body }) => {
  const current_credentials = await Auth.currentCredentials();
  const credentials = Auth.essentialCredentials(current_credentials);
  // Signer don't accept params in the url, not pass in params: {...} in Axios
  const encode_companies_array = params?.companies_id
    ? { ...params, companies_id: encodeURI(params.companies_id) }
    : { ...params };
  const add_sk_to_params = { sk: config.KEY, ...encode_companies_array };
  const stringify_params = queryString.stringify(add_sk_to_params);

  let request;

  //console.log(method)

  //signer needs data stingified
  if (method === 'post' || method === 'patch' || method === 'delete') {
    request = {
      method: method.toUpperCase(),
      url: `${config.apiGateway.URL}/${endpoint}`,
      //headers: { 'X-Api-Key': api_key },
      data: body ? JSON.stringify({ ...body, sk:config.KEY }) : null
    };
  }

  if (method === 'get') {
    request = {
      method: method.toUpperCase(),
      url: `${config.apiGateway.URL}/${endpoint}?${stringify_params}`
      //headers: { 'X-Api-Key': api_key }
    };
  }

  if (api_key) {
    request = {
      ...request,
      headers: { 'X-Api-Key': api_key }
    };
  }
  //console.log(request)
  
  const access_info = {
    access_key: credentials.accessKeyId,
    secret_key: credentials.secretAccessKey,
    session_token: credentials.sessionToken
  };
  const service_info = {
    service: 'execute-api',
    region: config.apiGateway.REGION
  };

  const signedRequest = Signer.sign(request, access_info, service_info);

  delete signedRequest.headers['host'];

  //create instance
  const instance = axios.create();

  try {
    const response = await instance(signedRequest);
    return response.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      throw Error('Request cancelled');
    } else {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      if (error.response) {
        throw error.response;
      }
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
      if (error.request) {
        throw Error('Request error');
      }
      // Something happened in setting up the request that triggered an Error

      throw error;
      // console.log(error.config);
    }
  }
};

export default axiosRequest;
