import axiosRequest from './axiosRequest';

// Fetch users accordingly
export const fetchUsers = ({ user_id, api_key, company_ids, user_level}) => {
  if (user_id) {
    return axiosRequest({
      api_key,
      method: 'get',
      endpoint: 'users',
      params: {
        user_id
      }
    });
  }
  return axiosRequest({
    api_key,
    method: 'get',
    endpoint: 'users',
    params: {
      user_level: user_level,
      company_ids : JSON.stringify(company_ids)
    }
  });
};
