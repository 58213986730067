import React, { useReducer } from 'react';
import { CompanyReducer } from './CompanyReducers';

export const CompanyContext = React.createContext();

export const CompanyProvider = ({ children }) => {
  const [companies, dispatchCompanies] = useReducer(CompanyReducer, []);
  const [selectedCompany, dispatchSelectedCompany] = useReducer(
    CompanyReducer,
    {}
  );

  const contextValues = {
    companies,
    dispatchCompanies,
    selectedCompany,
    dispatchSelectedCompany
  };

  return (
    <CompanyContext.Provider value={contextValues}>
      {children}
    </CompanyContext.Provider>
  );
};
