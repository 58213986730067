import React, { useReducer } from 'react';
import { UserReducer, AuthReducer } from './UserReducers';

export const UserContext = React.createContext();

export const UserProvider = ({ children }) => {
	const [authUser, dispatchAuthUser] = useReducer(AuthReducer, {
		isAuth: 'signin',
		api_key: '',
	});
	const [user, dispatchUser] = useReducer(UserReducer, {});

	const contextValues = {
		authUser,
		dispatchAuthUser,
		user,
		dispatchUser,
	};

	return (
		<UserContext.Provider value={contextValues}>
			{children}
		</UserContext.Provider>
	);
};
