import React, { useEffect, useState, Fragment, useContext } from 'react';
import {
  Card,
  CardContent,
  Grid,
  Tab,
  Tabs,
  Typography,
  useTheme,
  Fade
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Overview from './Overview';
import Defaults from './Defaults';
import Machines from './Machines';
import CardStyles from '../../../styles/cardStyles';
import { faEdit } from '@fortawesome/pro-light-svg-icons';
import PrimaryBtnIcon from '../../buttons/PrimaryBtnIcon';
import { buttonStyles } from '../../../styles/buttonStyles';
import { SAVE_SELECTED_COOLANT } from '../../../store/CoolantReducers';
import { CoolantContext } from '../../../store/CoolantContext';
import CoolantPDF from './CoolantPDF';
import { UserContext } from '../../../store/UserContext';
import { isMobileOnly } from 'react-device-detect';
import setLanguageText from '../../../helpers/setLanguageText';
import { LayoutContext } from '../../../store/LayoutContext';

const useStyles = makeStyles(theme => ({
  tab: {
    minWidth: 'auto'
  },
  li: {
    display: 'inline'
  },
  cardFooter: {
    padding: theme.spacing(1),
    borderTop: `1px solid ${theme.palette.background.light}`,
    background: `${theme.palette.background.light}`
  },
  dialog: {
    height: 'calc(100% - 64px)'
  },
  leftButton: {
    paddingLeft: '4px'
  },
  rightButton: {
    paddingRight: '4px'
  }
}));

const CoolantCard = ({
  coolant,
  openEditDialog,
  noMachines,
  noEdit,
  timeOut,
  noBar
}) => {
  const { dispatchSelectedCoolant } = useContext(CoolantContext);
  const { user } = useContext(UserContext);
  const { language } = useContext(LayoutContext);
  //****** STATES ******
  const [tabValue, setTabValue] = useState(0);
  const [show, setShow] = useState(false);

  //****** STYLES ******
  const classes = useStyles();
  const theme = useTheme();
  const buttonStyle = buttonStyles();
  const cardStyles = CardStyles();

  //****** FUNCTIONS ******
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    setShow(true);
    return () => {
      setShow(false);
    };
  }, []);

  //****** RENDER ******
  return (
    <>
      <Fade in={show} style={{ transitionDelay: `${timeOut * 1}ms` }}>
        <Card className={cardStyles.card} elevation={0}>
          <Grid container direction="column" className={cardStyles.cardContainer}>
            <Grid
              item
              container
              direction="column"
              className={cardStyles.header}
              spacing={1}
            >
              <Grid item container justifyContent="space-between">
                <Grid item>
                  <Typography variant="h4">{coolant.coolant_name}</Typography>
                </Grid>
                {!noEdit && user.level >= 8 && !isMobileOnly && (
                  <Grid item>
                    <PrimaryBtnIcon
                      icon={faEdit}
                      text="edit"
                      size="small"
                      //iconSize="lg"
                      textVariant="caption"
                      className={buttonStyle.danger}
                      onClick={() => {
                        dispatchSelectedCoolant({
                          type: SAVE_SELECTED_COOLANT,
                          payload: coolant
                        });
                        openEditDialog();
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>

            <CardContent justify="center" className={cardStyles.cardContent}>
              <Tabs
                indicatorColor="primary"
                textColor="primary"
                value={tabValue}
                onChange={handleTabChange}
                variant="fullWidth"
                style={{ marginBottom: theme.spacing(1) }}
              >
                <Tab label={setLanguageText(language,"Overview")} className={`${classes.tab}`} />
                <Tab label={setLanguageText(language,"Defaults")} className={`${classes.tab}`} />
                {!noMachines && (
                  <Tab label={setLanguageText(language,"Machines")} className={`${classes.tab}`} />
                )}
              </Tabs>

              {tabValue === 0 ? (
                <Overview
                  coolant={coolant}
                  classes={classes}
                  totalMachines={coolant.total_machines}
                  noBar={noBar}
                />
              ) : tabValue === 1 ? (
                <Defaults coolant={coolant} />
              ) : (
                <Fragment>
                  {!noMachines ? <Machines coolant={coolant} /> : null}
                </Fragment>
              )}
            </CardContent>

            <Grid
              item
              container
              justifyContent="space-around"
              className={cardStyles.footer}
            >
              <CoolantPDF coolant={coolant}/>
            </Grid>
          </Grid>
        </Card>
      </Fade>
    </>
  );
};

export default CoolantCard;
