import { faSortDown, faSortUp } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Grid,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme
} from '@mui/material';
import React, { useEffect } from 'react';
import { usePagination, useRowState, useSortBy, useTable } from 'react-table';
import Pagination from '../../reusable/Pagination';
import { makeStyles } from '@mui/styles';
import formatDate from '../../../helpers/formatDate';

const useStyles = makeStyles(theme => ({
  cardContent: {
    overflowX: 'auto',
    maxHeight: '70vh',
    padding: '0'
  },
  tableHeader: {
    position: 'sticky',
    zIndex: 1500,
    width: 'fit-content'
  },
  headerCell: column => ({
    textAlign: 'left',
    backgroundColor: theme.palette.background.light,
    padding: theme.spacing(1, 2),
    zIndex: 1000
  }),
  tableRow: {},
  tableCell: cell => ({
    textAlign: 'left',
    padding: theme.spacing(1, 2),
    overflowX: 'auto',
    zIndex: 1000
  })
}));

const NotificationsTable = ({ columns, data, getTableDataFn, headerSettings }) => {
  //************************  STYLES ************************
  const theme = useTheme();
  const classes = useStyles();

  //************************  TABLE CREATE FUNCTION ************************
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page
    rows,
    // The rest of these things are super handy, too ;)
    allColumns,
    getToggleHideAllColumnsProps,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      // Table's Initial state
      initialState: {
        pageIndex: 0
      },
      // Cell's Initial state
      initialCellStateAccessor: () => ({
        // used in the Status Column to handle the hover Popover
        anchorEl: null,
        isOpen: false
      })
    },
    useSortBy,
    usePagination,
    useRowState
    // useFlexLayout
  );
  //************************  USE EFFECTS ************************
  //returns table data to parent component
  useEffect(() => {
    if (getTableDataFn) {
      rows && getTableDataFn(rows);
    }
  }, [getTableDataFn, rows]);


  return (
    // apply the table props
    <Grid item container xs={12}>
      <Grid
        item
        container
        xs={12}
        justifyContent="flex-end"
        style={{ marginBottom: theme.spacing(1) }}
      >
      </Grid>

      <Grid item xs={12}>
        <Card variant="outlined">
          <CardContent className={classes.cardContent}>
            <Table {...getTableProps()} className={classes.table} stickyHeader>
              {/* TABLE HEADER */}
              <TableHead className={classes.tableHeader}>
                {
                  // Loop over the header rows
                  headerGroups.map(headerGroup => (
                    // Apply the header row props
                    <TableRow {...headerGroup.getHeaderGroupProps()}>
                      {
                        // Loop over the headers in each row
                        headerGroup.headers.map(column => {
                          return (
                            // Apply the header cell props
                            <TableCell
                              {...column.getHeaderProps(
                                // add sort functionality
                                column.getSortByToggleProps()
                              )}
                              className={classes.headerCell}
                              style={{
                                minWidth: column.minWidth,
                                zIndex: column.sticky ? 1500 : 0
                              }}
                            >
                              {
                                // Render the header
                                column.render('Header')
                              }
                              {/* Render arrow depending of sorting type */}
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <FontAwesomeIcon icon={faSortDown} />
                                ) : (
                                  <FontAwesomeIcon icon={faSortUp} />
                                )
                              ) : (
                                ''
                              )}
                            </TableCell>
                          );
                        })
                      }
                    </TableRow>
                  ))
                }
              </TableHead>
              {/* TABLE CONTENT */}
              {/* Apply the table body props */}
              <TableBody {...getTableBodyProps()}>
                {
                  // Loop over the table rows
                  page.map((row, i) => {
                    // Prepare the row for display
                    prepareRow(row);       
                    let now = new Date().setHours(0,0,0,0);
                    let date = new Date(row.allCells[4].value).setHours(0,0,0,0)
                    // console.log(now)
                    // console.log(date)
                    return (
                      // Apply the row props
                      <TableRow
                        {...row.getRowProps()}
                        className={classes.tableRow}    
                        style={{
                          backgroundColor:
                            (date < now)
                              ? '#FFEDF1'
                              : (date === now)
                              ? '#FDF9E4'
                              : theme.palette.common.white,
                          left: 0
                        }}
                      >
                        {
                          // Loop over the rows cells
                          row.cells.map(cell => {
                            // Apply the cell props

                            return ( 
                              <TableCell
                                {...cell.getCellProps()}
                                className={classes.tableCell}
                              >
                                {
                                  // Render the cell contents
                                  cell.render('Cell')
                                }
                              </TableCell>
                            );
                          })
                        }
                      </TableRow>
                    );
                  })
                }
              </TableBody>
            </Table>
          </CardContent>
          {/* PAGINATION AND SETTINGS */}
          <Pagination
            allColumns={allColumns}
            getToggleHideAllColumnsProps={getToggleHideAllColumnsProps}
            pageIndex={pageIndex}
            gotoPage={gotoPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            previousPage={previousPage}
            canPreviousPage={canPreviousPage}
            nextPage={nextPage}
            canNextPage={canNextPage}
            pageCount={pageCount}
          />
        </Card>
      </Grid>
    </Grid>
  );
};

export default NotificationsTable;
