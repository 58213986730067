export const APP_THEME = 'APP_THEME';
export const COMPANY_THEME = 'COMPANY_THEME';
export const USER_THEME = 'USER_THEME';
export const LANGUAGE = 'LANGUAGE';

export const LayoutReducer = (state, action) => {
  switch (action.type) {
    case APP_THEME:
      return {
        ...state,
        app : action.payload
      };
      case COMPANY_THEME:
      return {
        ...state,
        company : action.payload
      };
      case USER_THEME:
      return {
        ...state,
        user : action.payload
      };
      case LANGUAGE:
        return action.payload;
    default:
      return state;
  }
};
