const parseMachine = machine => ({
  ...machine,
  additives:
    machine.additives && typeof machine.additives === 'string'
      ? JSON.parse(machine.additives)
      : machine.additives,
  attributes_new:
    typeof machine.attributes_new === 'string'
      ? JSON.parse(machine.attributes_new)
      : machine.attributes_new,
  machine_settings:
    typeof machine.machine_settings === 'string'
      ? JSON.parse(machine.machine_settings)
      : machine.machine_settings,
  coolant_defaults:
    typeof machine.coolant_defaults === 'string'
      ? JSON.parse(machine.coolant_defaults)
      : machine.coolant_defaults,
  last_entry:
      typeof machine.last_entry === 'string'
        ? JSON.parse(machine.last_entry)
        : machine.last_entry
});

// parse machines' attributes and settings
const parseMachines = machines => {
  if (machines) {
    if (machines.length) {
      return machines.map(machine => parseMachine(machine));
    } else if (Object.entries(machines).length) {
      return parseMachine(machines);
    }
  }
  return [];
};
export default parseMachines;
