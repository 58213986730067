import { Button, Typography } from '@mui/material';
import React, { useContext } from 'react';
import setLanguageText from '../../helpers/setLanguageText';
import { LayoutContext } from '../../store/LayoutContext';
import { buttonStyles } from '../../styles/buttonStyles';

const PrimaryBtn = ({ text, textVariant, className, ...props }) => {
  const buttonStyle = buttonStyles();
  const { language } = useContext(LayoutContext);
  
  return (
    <Button
      variant="contained"
      fullWidth
      color="primary"
      className={`${buttonStyle.button} ${className}`}
      {...props}
    >
      <Typography variant={textVariant} className={buttonStyle.buttonText}>
        {setLanguageText(language,text)}
      </Typography>
    </Button>
  );
};

export default PrimaryBtn;
