import React, {
    useMemo,
} from 'react';
import {
    StyleSheet,
    usePDF
} from '@react-pdf/renderer';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/styles';
import GlobalPdfDialog from '../../reusable/GlobalPdfDialog';
import LoadingScreen from '../../reusable/LoadingScreen';

const useStyles = makeStyles(theme => ({
    dialogContent: {
        '& .MuiDialogContent-root': {
            overflow: 'hidden'
        },
        '& .MuiDialog-paper': {
            width: '100%',
            height: '100%'
        }
    }
}));

const create_pdf_styles = theme => {
    return StyleSheet.create({
        BODY: {
            padding: '2mm 4mm',
            //paddingHorizontal: '0',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            width: '100%'
        },
        SIZE_1: {
            width: '33.33%',
            height: '102mm',
            padding: '10mm'
        },
        SIZE_2: {
            width: '33.33%',
            height: '50mm',
            padding: '10mm'
        },
        CONTAINER : {
            //display: 'grid',
            //alignItems: 'center',
            //alignContent: 'center',
            //textAlign: 'center',
            //border: '1px solid #ccc',
            height: '100%',
            width: '100%',
            //padding: '2mm'
        },
        FULL : {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap'
        },
        HALF: {
            width: '50%',
            float: 'left'
        },
        MAINTITLE: {
            fontSize: 12,
            //textAlign: 'center',
            width: '100%',
            padding: '2mm 0 0 4mm',
            fontWeigth :'bold'
        },
        TITLE: {
            fontSize: 11,
            //textAlign: 'center',
            width: '100%',
            padding: '2mm'
        },
        TEXT: {
            fontSize: 9,
            //textAlign: 'center',
            width: '100%',
            padding: '1mm 2mm'
        },
        QR: {
            maxWidth: '100%',
            height: 'auto'
        },
        PAGE_NUMBER: {
            position: 'absolute',
            fontSize: theme.spacing(1.5),
            bottom: theme.spacing(1.5),
            left: 0,
            right: 0,
            textAlign: 'center',
            color: 'grey'
        }
    });
};

const CreatePDF = ({ document }) => {
    //console.log(document)
    const [instance, updateInstance] = usePDF({ document: document });

    return (
        <>
            { instance.url ?
                <object data={instance.url} type="application/pdf" height="100%" width="100%"></object>
                :
                null
            }
        </>
    )
}

const MachineQRCodesOnlyPDF = ({
    dialogOpen,
    dialogClose,
    create_pdf_data,
    showPreview
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const pdf_styles = useMemo(() => create_pdf_styles(theme), [theme]);

    const handleClose = () => {
        dialogClose();
    };

    return (
        <GlobalPdfDialog
            open={dialogOpen}
            closeModal={handleClose}
            title="QR codes"
            maxWidth="xl"
            fullWidth
            className={classes.dialogContent}
        >
            <>
                { showPreview ?
                    <CreatePDF document={create_pdf_data(pdf_styles)} />
                    : <LoadingScreen
                        loading={false}
                        background="#666"
                        colour="#fff"
                        text="Loading PDF Preview"
                        position="relative"
                    />
                }
            </>

        </GlobalPdfDialog>
    );
};

export default MachineQRCodesOnlyPDF;
