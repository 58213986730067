
const setLanguageText = (lan, string) => {

    if (lan.id !== 'en') {
        //console.log(lang)
        let data = [{ en: 'Dashboard', de: 'Armaturenbrett' },
        { en: 'Machines', de: 'Maschinen' },
        { en: 'Coolants', de: 'Kühlschmierstoffe' },
        { en: 'Products', de: 'Produkte' },
        { en: 'Additives', de: 'Additive' },
        { en: 'Reports', de: 'Berichte' },
        { en: 'Resources', de: 'Ressourcen' },
        { en: 'Service', de: 'Service' },
        { en: 'Logout', de: 'Ausloggen' },
        { en: 'Chat', de: 'Chat' },
        { en: 'All Companies', de: 'Alle Unternehmen' },
        { en: 'My Company', de: 'Meine Firma' },
        { en: 'Settings', de: 'Einstellungen' },
        { en: 'Selected Company', de: 'Ausgewähltes Unternehmen' },
        { en: 'Select Company', de: 'Unternehmen auswählen' },
        { en: 'Selected Group/s', de: 'Ausgewählte Gruppe/n' },
        { en: 'Selected Machine/s', de: 'Ausgewählte Maschine/n' },
        { en: 'Maintenance', de: 'Wartung' },
        { en: 'Diagnostic', de: 'Diagnose' },
        { en: 'Export Charts', de: 'Diagramme exportieren' },
        { en: 'Add First Machine', de: 'Erste Maschine hinzufügen' },
        { en: 'Loading', de: 'Wird geladen' },
        { en: 'Company has no machines, please add a machine', de: 'Für diese Firma sind noch keine Maschinen angelegt, bitte fügen Sie eine Maschine hinzu' },
        { en: 'Fetching Machines', de: 'Maschinen werden geladen' },
        { en: 'Add Machine', de: 'Maschine hinzufügen' },
        { en: 'Add Company', de: 'Firma hinzufügen' },
        { en: 'Add Entry', de: 'Eintrag hinzufügen' },
        { en: 'Add User', de: 'Benutzer hinzufügen' },
        { en: 'Add Service Report', de: 'Servicebericht hinzufügen' },
        { en: 'Add New Machine', de: 'Neue Maschine hinzufügen' },
        { en: 'Cancel', de: 'Abbrechen' },
        { en: 'Machine Name', de: 'Maschinenname' },
        { en: 'Create New Machine Group', de: 'Neue Maschinengruppe erstellen' },
        { en: 'Select Current Group', de: 'Bestehende Gruppe auswählen' },
        { en: 'Company ID required', de: 'Firmen-Nr. erforderlich' },
        { en: 'Attribute ID required', de: 'Eigenschafts-Nr.. erforderlich' },
        { en: 'Attribute Name', de: 'Eigenschaftsname' },
        { en: 'Attribute Type', de: 'Eigenschaftstyp' },
        { en: 'Company ID', de: 'Firmen-Nr.' },
        { en: 'Attribute ID', de: 'Attribut-Nr.' },
        { en: 'Number', de: 'Zahl' },
        { en: 'String', de: 'Text' },
        { en: 'Float', de: 'Fließkomma' },
        { en: 'Minimum Value', de: 'Mindestwert' },
        { en: 'Maximum Value', de: 'Maximalwert' },
        { en: 'Available in Columns & Reports', de: 'In Tabellen und Berichten verfügbar' },
        { en: 'Locked Attribute (only available to admin)', de: 'Gesperrte Eigenschaft (nur für Administratoren verfügbar)' },
        { en: 'Select Language', de: 'Sprache wählen' },
        { en: 'Edit', de: 'Bearbeiten' },
        { en: 'Locked', de: 'Gesperrt' },
        { en: 'Columns', de: 'Spalten anzeigen' },
        { en: 'Rules', de: 'Regeln' },
        { en: 'Attributes', de: 'Eigenschaften' },
        { en: 'Add a new Attribute', de: 'Eigenschaft hinzufügen' },
        { en: 'ID', de: 'Nr.' },
        { en: 'Name', de: 'Name' },
        { en: 'Status', de: 'Status' },
        { en: 'Colour', de: 'Farbe' },
        { en: 'Example', de: 'Beispiel' },
        { en: 'Brand Name', de: 'Markenname' },
        { en: 'Coolant Image', de: 'KSS-Image' },
        { en: 'Additive Image', de: 'Additiv-Image' },
        { en: 'Actions', de: 'Maßnahmen' },
        { en: 'Brand added successfully!', de: 'Marke erfolgreich hinzugefügt' },
        { en: 'Add New Brand', de: 'Neue Marke hinzufügen' },
        { en: 'Loading Brands', de: 'Marken werden geladen' },
        { en: 'Edit Brand', de: 'Marke bearbeiten' },
        { en: 'Brands', de: 'Marken' },
        { en: 'Add Brands', de: 'Marke hinzufügen' },
        { en: 'Brand edited successfully!', de: 'Marke erfolgreich bearbeitet!' },
        { en: 'Add New Additive Type', de: 'Neuen Additivtyp hinzufügen' },
        { en: 'Loading Types', de: 'Typen werden geladen' },
        { en: 'Edit Additive Type', de: 'Additivtyp bearbeiten' },
        { en: 'Add New Additive Type', de: 'Neuen Additivtyp hinzufügen' },
        { en: 'Loading Types', de: 'Typen werden geladen' },
        { en: 'Edit Addititve Type', de: 'Additivtyp bearbeiten' },
        { en: 'Loading Companies', de: 'Firmen werden geladen' },
        { en: 'Disable', de: 'Deaktivieren' },
        { en: 'Edit User', de: 'Anwender bearbeiten' },
        { en: 'Create Theme', de: 'Neues Basisschema erstellen' },
        { en: 'Primary Styles', de: 'Primäre Stile' },
        { en: 'Secondary Styles', de: 'Sekundäre Stile' },
        { en: 'Update Theme', de: 'Basisschema aktualisieren' },
        { en: 'Loading Machines', de: 'Maschinen laden' },
        { en: 'Close', de: 'Schließen' },
        { en: 'Loading Machines', de: 'Maschinen laden' },
        { en: 'My Account', de: 'Mein Zugang' },
        { en: 'Next', de: 'Weiter' },
        { en: 'Coolant Search', de: 'KSS suchen' },
        { en: 'Products Search', de: 'Produkte suchen' },
        { en: 'Additive Search', de: 'Additive suchen' },
        { en: 'Machine Search', de: 'Maschinen suchen' },
        { en: 'Scan QR', de: 'QR-Code scannen' },
        { en: 'Create Service Report', de: 'Servicebericht erstellen' },
        { en: '12 character code', de: '12-Zeichen-Code' },
        { en: 'Manual QR input', de: 'Manuelle QR-Eingabe' },
        { en: 'Go', de: 'Los' },
        { en: 'Machine Group', de: 'Maschinengruppe' },
        { en: 'Edit Machine Details', de: 'Maschinendaten bearbeiten' },
        { en: 'Save Refractometer Factor', de: 'Refraktometerfaktor speichern' },
        { en: 'Sump', de: 'Füllvolumen' },
        { en: 'SUMP', de: 'Füllvolumen' },
        { en: 'Refractometer Factor', de: 'Refraktometerfaktor' },
        { en: 'PH Max', de: 'pH max.' },
        { en: 'PH Min', de: 'pH min.' },
        { en: 'Op Con Max', de: 'Konz. max.' },
        { en: 'Op Con Min', de: 'Konz. min.' },
        { en: 'Edit Machine Details', de: 'Maschinendaten bearbeiten' },
        { en: 'Remove Machine', de: 'Maschine entfernen' },
        { en: 'Remove Machine', de: 'Maschine entfernen' },
        { en: 'Reset/Refresh Coolant', de: 'KSS zurücksetzen / wechseln' },
        { en: 'Check Frequency', de: 'KSS Wechselfrequenz prüfen' },
        { en: 'Safety data', de: 'Sicherheitsdatenblatt' },
        { en: 'Technical data', de: 'Technisches Merkblatt' },
        { en: 'FROM', de: 'Von' },
        { en: 'copyright', de: 'Urheberrechte' },
        { en: 'TO', de: 'Bis' },
        { en: 'Total', de: 'Gesamt' },
        { en: 'Loading machine details', de: 'Maschinendaten laden' },
        { en: 'Machine', de: 'Maschine' },
        { en: 'Coolant', de: 'KSS' },
        { en: 'Machine Products', de: 'Maschinenprodukte' },
        { en: 'Entries Table', de: 'Einträge' },
        { en: 'Charts', de: 'Diagramme' },
        { en: 'Hide Tooltips', de: 'Werte verbergen' },
        { en: 'Hide Plots', de: 'Datenpunkte verbergen' },
        { en: 'Concentration levels', de: 'Konzentration' },
        { en: 'PH levels', de: 'pH-Werte' },
        { en: 'Bacteria levels', de: 'Bakterien' },
        { en: 'Fungi levels', de: 'Pilze' },
        { en: 'Tramp oil levels', de: 'Fremdöl' },
        { en: 'Last Coolant Change', de: 'Letzter Kühlmittelwechsel' },
        { en: 'Coolant Defaults', de: 'KSS Standardwerte' },
        { en: 'Coolant Id', de: 'KSS-Nr' },
        { en: 'Coolant Name', de: 'KSS' },
        { en: 'Factor', de: 'Faktor' },
        { en: 'Conc Min', de: 'Konz. min.' },
        { en: 'Conc Max', de: 'Konz. max.' },
        { en: 'Entry Date', de: 'Eintragsdatum' },
        { en: 'User', de: 'Anwender' },
        { en: 'Notes / Actions', de: 'Notizen / Maßnahmen' },
        { en: 'This machine has no entries', de: 'Diese Maschine hat noch keine Einträge' },
        { en: 'Add First Entry', de: 'Ersten Eintrag hinzufügen' },
        { en: 'Out', de: 'Außerhalb Sollbereich' },
        { en: 'Edge', de: 'Im Grenzbereich ' },
        { en: 'N/A', de: 'Nicht verfügbar' },
        { en: 'Total Entries', de: 'Einträge insgesamt' },
        { en: 'Total Machines', de: 'Maschinen insgesamt' },
        { en: 'Meets All Requirements', de: 'Erfüllt alle Erfordernisse' },
        { en: 'Checked', de: 'Geprüft' },
        { en: 'Download as CSV', de: 'Als CSV-Datei herunterladen' },
        { en: 'Column Options', de: 'Spaltenoptionen' },
        { en: 'Back to Machines', de: 'Zurück zu Maschinen' },
        { en: 'Export Machine', de: 'Maschine exportieren' },
        { en: 'Export Single Machine', de: 'Einzelne Maschine exportieren' },
        { en: 'Edit Table', de: 'Tabelle bearbeiten' },
        { en: 'Page', de: 'Seite' },
        { en: 'Edit settings', de: 'Einstellungen bearbeiten' },
        { en: 'Edit Machine Settings', de: 'Maschineneinstellungen bearbeiten' },
        { en: 'Reset Machine Defaults', de: 'Maschine auf Standardwerte zurücksetzen' },
        { en: 'Reset this machine to the default settings?', de: 'Wollen Sie die Maschine auf Standardwerte zurücksetzen?' },
        { en: 'Reset to defaults', de: 'Auf Standardwerte zurücksetzen' },
        { en: 'Are you sure you want to delete this machine,this action will be permanent?', de: 'Sind Sie sicher, dass Sie diese Maschine löschen wollen? Diese Aktion kann nicht rückgängig gemacht werden!' },
        { en: 'Apply Refractometer Factor', de: 'Refraktometerfaktor anwenden' },
        { en: 'Out Of Control', de: 'Außerhalb des Sollbereichs' },
        { en: 'Details', de: 'Details' },
        { en: 'Scan Again', de: 'Scan wiederholen' },
        { en: 'Manual Input', de: 'Manuelle Eingabe' },
        { en: 'Date', de: 'Datum' },
        { en: 'Search', de: 'Suchen' },
        { en: 'Select a date and click the search button', de: 'Bitte Datum auswählen und auf Suchen klicken' },
        { en: 'Add New Entry', de: 'Neuen Eintrag hinzufügen' },
        { en: 'Not Needed', de: 'Nicht erforderlich' },
        { en: 'Clear', de: 'Löschen' },
        { en: 'edit product', de: 'Produkt bearbeiten' },
        { en: 'Show', de: 'Zeigen' },
        { en: 'Hide', de: 'Verbergen' },
        { en: 'Select product type', de: 'Produkttyp auswählen' },
        { en: 'Add Product', de: 'Produkt hinzufügen' },
        { en: 'Add Products', de: 'Produkt hinzufügen' },
        { en: 'Show Company User View', de: 'Ansicht des Company Users anzeigen' },
        { en: 'All Additives', de: 'Alle Additive' },
        { en: 'Additives In Use', de: 'Additive in Verwendung' },
        { en: 'In Use', de: 'In Verwendung' },
        { en: 'Add New Additive', de: 'Neues Additiv hinzufügen' },
        { en: 'Loading Additives', de: 'Additive werden geladen' },
        { en: 'Search and compare Additives', de: 'Additive suchen und vergleichen' },
        { en: 'Filter Types', de: 'Filtertypen' },
        { en: 'Run Report', de: 'Bericht erstellen' },
        { en: 'Select Additive Type', de: 'Additivtyp auswählen' },
        { en: 'Select Additive', de: 'Additiv auswählen' },
        { en: 'Fetch Activity Logs', de: 'Aktivitätsprotokoll laden' },
        { en: 'Activity Log', de: 'Aktivitätsprotokoll' },
        { en: 'Activity Type', de: 'Aktivitätstyp' },
        { en: 'Categories', de: 'Kategorien' },
        { en: 'Change File', de: 'Filename ändern' },
        { en: 'Remove File', de: 'File löschen' },
        { en: 'Add Resource', de: 'Ressource hinzufügen' },
        { en: 'Upload Resource', de: 'Ressource hochladen' },
        { en: 'Fetch Service Reports', de: 'Servicereports laden' },
        { en: 'Service Reports', de: 'Servicereports' },
        { en: 'Select Manufacturer', de: 'Hersteller auswählen' },
        { en: 'day(s) ago', de: 'Vor Tagen' },
        { en: 'Select Machine', de: 'Maschine auswählen' },
        { en: 'Standard Values', de: 'Standardwerte' },
        { en: 'Bacteria & Fungi', de: 'Bakterien und Pilze' },
        { en: 'Notes', de: 'Anmerkungen' },
        { en: 'Date Stamp', de: 'Datum' },
        { en: 'Custom Attributes', de: 'Benutzerdefinierte Attribute' },
        { en: 'Check Frequency & Notifications', de: 'Prüffrequenz und Benachrichtigungen' },
        { en: 'App Name', de: 'App-Name' },
        { en: 'Instance', de: 'Sitzung' },
        { en: 'Application Logo', de: 'App-Logo' },
        { en: 'Application Details', de: 'App-Details' },
        { en: 'Application Settings', de: 'App-Einstellungen' },
        { en: 'All Users', de: 'Alle Anwender' },
        { en: 'Theme', de: 'Farbschema' },
        { en: 'Additive Types', de: 'Additivtypen' },
        { en: 'Training resources', de: 'Trainingsbereich' },
        { en: 'Company Name', de: 'Firmenname' },
        { en: 'Last Entry', de: 'Letzter Eintrag' },
        { en: 'Export Machines', de: 'Maschine exportieren' },
        { en: 'Set Machine Settings to Coolant Defaults', de: 'Maschinenvorgaben auf KSS-Standardwerte zurücksetzen' },
        { en: 'Reset Date of Coolant Change', de: 'Datum des KSS-Wechsels zurücksetzen' },
        { en: 'Day Starting', de: 'Startdatum' },
        { en: 'Operator Email', de: 'Email des Bedieners' },
        { en: 'Supervisor Email', de: 'Email des Projektleiters' },
        { en: 'Update Details', de: 'Details aktualisieren' },
        { en: 'View Service Reports', de: 'Serviceberichte anzeigen' },
        { en: 'My Profile', de: 'Mein Profil' },
        { en: 'My Theme', de: 'Mein Farbschema' },
        { en: 'My Notifications', de: 'Meine Benachrichtigungen' },
        { en: 'My Visibility', de: 'Meine Sichtbarkeit' },
        { en: 'My Profile', de: 'Mein Profil' },
        { en: 'Initials', de: 'Initialen' },
        { en: 'User Name', de: 'Name des Anwenders' },
        { en: 'User Email', de: 'Email des Anwenders' },
        { en: 'Update Profile', de: 'Profil aktualisieren' },
        { en: 'Overview', de: 'Übersicht' },
        { en: 'Defaults', de: 'Standardwerte' },
        { en: 'edit', de: 'Bearbeiten' },
        { en: 'Loading Products', de: 'Produkte werden geladen' },
        { en: 'Coolant Usage by Coolant', de: 'Kühlmittelverbrauch nach KSS' },
        { en: 'Select Coolant', de: 'KSS auswählen' },
        { en: 'Additive Usage by Additive', de: 'Additivverbrauch nach Additiv' },
        { en: 'Select Page Amount', de: 'Anzahl Seiten auswählen' },
        { en: 'Select Size', de: 'Größe auswählen' },
        { en: 'Create Dipslide QR Labels', de: 'Displide-QR-Etiketten erstellen' },
        { en: 'Per A4 page (size)', de: 'Pro DIN A4-Seite' },
        { en: 'Create Machine QR Labels', de: 'Maschinen-QR-Etiketten erstellen' },
        { en: 'Select Group', de: 'Gruppe auswählen' },
        { en: 'QR codes', de: 'QR-Codes' },
        { en: 'Company Details', de: 'Unternehmens-Details' },
        { en: 'Company Users', de: 'Unternehmensbenutzer' },
        { en: 'Company Defaults', de: 'Firmenvorgaben' },
        { en: 'Company Settings', de: 'Unternehmenseinstellungen' },
        { en: 'Display Settings', de: 'Bildschirmeinstellungen' },
        { en: 'Display View', de: 'Bildschirmeinstellungen' },
        { en: 'Notifications', de: 'Benachrichtigungen' },
        { en: 'Upload TDS', de: 'Technisches Datenblatt hochladen' },
        { en: 'Upload SDS', de: 'Sicherheitsdatenblatt hochladen' },
        { en: 'Path', de: 'Dateipfad' },
        { en: 'Selected Brand', de: 'Ausgewählte Marke' },
        { en: 'Save Coolant', de: 'Kühlschmierstoff speichern' },
        { en: 'Loading Coolants', de: 'Kühlschmierstoffe werden geladen' },
        { en: 'Selected Brand', de: 'Ausgewählte Marke' },
        { en: 'Update Coolant', de: 'Kühlschmierstoff aktualisieren' },
        { en: 'Edit Coolant', de: 'Kühlschmierstoff bearbeiten' },
        { en: 'Description', de: 'Beschreibung' },
        { en: 'Coolant Default Settings', de: 'Kühlschmierstoff-Standardwerte' },
        { en: 'Concentration Max', de: 'Maximale Konzentration' },
        { en: 'Concentration Min', de: 'Minimale Konzentration' },
        { en: 'Coolants Currently In Machines', de: 'Kühlschmierstoffe aktuell in Maschinen ' },
        { en: 'Add selling point', de: 'Verkaufsargument hinzufügen' },
        { en: 'Additive Name', de: 'Additivname' },
        { en: 'Manufacturer', de: 'Hersteller' },
        { en: 'Type', de: 'Additivtyp' },
        { en: 'Dilution', de: 'Verdünnung' },
        { en: 'Add Additive', de: 'Additiv hinzufügen' },
        { en: 'Hidden for', de: 'Nicht sichtbar für' },
        { en: 'Visible for', de: 'Sichtbar für' },
        { en: 'Fetching Company Settings', de: 'Unternehmenseinstellungen werden geladen' },
        { en: 'Search and compare Coolants', de: 'Kühlschmierstoffe suchen und vergleichen' },
        { en: 'On this page you can select a company, then add and remove products that are visible for that companies users.Use the toggle button to see how the view will look for the selected companies users.', de: 'Auf dieser Seite können Sie ein Unternehmen auswählen, anschließend können Sie Sichtbarkeit der Produkte für die Company User aktivieren oder verbergen. Verwenden Sie den Schieberegler um die Ansicht des Company Users zu sehen.' },
        { en: 'Edit Product', de: 'Produkt bearbeiten' },
        { en: 'Product Name', de: 'Produktname' },
        { en: 'Product Type', de: 'Produkttyp' },
        { en: 'Update Product', de: 'Produkt aktualisieren' },
        { en: 'Product Type', de: 'Produkttyp' },
        { en: 'Selling point', de: 'Verkaufsargument' },
        { en: 'Show All', de: 'Alle anzeigen' },
        { en: 'Show Voided', de: 'Ausgeblendete Werte anzeigen' },
        { en: 'Show Tooltips', de: 'Werte anzeigen' },
        { en: 'Hide Tooltips', de: 'Werte verbergen' },
        { en: 'Show Plots', de: 'Datenpunkte anzeigen' },
        { en: 'Hide Plots', de: 'Datenpunkte verbergen' },
        { en: 'Remove Refractometer Factor', de: 'Refraktometerfaktor entfernen' },
        { en: 'If you want the Refractometer Factor settings applied to all Readings and Concentration Min And Max', de: 'Falls Sie die Einstellungen für den Refraktometerfaktor auf alle Werte und die Konzentrationsober- und Untergrenzen anwenden wollen' },
        { en: 'Change overdue by', de: 'Wechsel überfällig seit' },
        { en: 'Cleanout due in', de: 'Reinigung fällig in' },
        { en: 'day(s)', de: 'Tag(en)' },
        { en: 'Confirm', de: 'Bestätigen' },
        { en: 'Company Logo', de: 'Firmenlogo' },
        { en: 'Update Company Details', de: 'Firmendetails aktualisieren' },
        { en: 'Enable emails and in app notifications based on the machine coolant check frenquency.', de: 'Mail und App-Benachrichtigungen auf Basis der Maschinenprüffrequenz ermöglichen.' },
        { en: 'Enable Supervisor App Notifications - coming soon', de: 'Supervisor App-Benachrichtigungen erlauben - in Arbeit' },
        { en: 'Enable Operator Emails', de: 'Mailversand durch Operator erlauben' },
        { en: 'Enable Supervisor Emails', de: 'Mailversand durch Supervisors erlauben' },
        { en: 'Save Notifications', de: 'Benachrichtigungen speichern' },
        { en: 'Display Delay', de: 'Anzeigeverzögerung' },
        { en: 'Show message on dashboard?', de: 'Nachricht auf dashboard anzeigen?' },
        { en: 'Loading Users', de: 'Benutzer werden geladen' },
        { en: 'Table items in view', de: 'Tabelleninhalte anzeigen' },
        { en: 'Machines table columns', de: 'Maschinentabellenspalten' },
        { en: 'Save columns', de: 'Spalten speichern' },
        { en: 'Display Ticker', de: 'Ticker anzeigen' },
        { en: 'On the limit', de: 'An der Grenze' },
        { en: 'In control', de: 'Im Sollbereich' },
        { en: 'Clear Result', de: 'Ergebnis löschen' },
        { en: 'No result needed', de: 'Kein Eintrag erforderlich' },
        { en: 'Pending result', de: 'Ergebnis steht noch nicht fest' },
        { en: 'This machine has no coolant set', de: 'Für diese Maschine wurde noch kein Kühlmittel festgelegt' },
        { en: 'Add Coolant', de: 'Kühlmittel hinzufügen' },
        { en: 'Settings edited successfully!', de: 'Einstellungen wurden erfolgreich geändert!' },
        { en: 'Are you sure you want to:', de: 'Sind Sie sicher, dass Sie…:' },
        { en: 'Change the machine coolant to', de: 'den folgenden Kühlschmierstoff eintragen wollen:' },
        { en: 'Reset the Coolant Change Date', de: 'das KSS-Wechseldatum ändern wollen' },
        { en: 'Yes', de: 'Ja' },
        { en: 'Entry updated successfully!', de: 'Eintrag erfolgreich aktualisiert!' },
        { en: 'Machine added successfully!', de: 'Maschine erfolgreich hinzugefügt!' },
        { en: 'Username or Email', de: 'Benutzername oder Email' },
        { en: 'username or email address', de: 'Benutzername oder Emailadresse' },
        { en: 'Password', de: 'Passwort' },
        { en: 'password', de: 'Passwort' },
        { en: 'Forgot your password?', de: 'Passwort vergessen?' },
        { en: 'Reset password', de: 'Passwort zurücksetzen' },
        { en: 'SIGN IN', de: 'ANMELDEN' },
        { en: 'Show all', de: 'Alle anzeigen' },
        { en: 'All groups', de: 'Alle Gruppen' },
        { en: 'All Machines', de: 'Alle Maschinen' },
        { en: 'In Control', de: 'i.O.' },
        { en: 'On Edge', de: 'An Grenze' },
        { en: 'Out Control', de: 'n.i.O.' },
        { en: 'No Entries', de: 'Keine Einträge' },
        { en: 'All', de: 'Alle' },
        { en: 'Coolant', de: 'Kühlmittel' },
        { en: 'Machine Details', de: 'Maschinendetails' },
        { en: 'Last coolant change', de: 'Letzter Kühlmittelwechsel' },
        { en: 'Machine group', de: 'Maschinengruppe' },
        { en: 'Machine name', de: 'Maschinenname' },
        { en: 'Last entry', de: 'Letzer Eintrag' },
        { en: 'Notes', de: 'Bemerkungen' },
        { en: 'Additives', de: 'Additive' },
        { en: 'German', de: 'Deutsch' },
        { en: 'Show Voided', de: 'Verborgene Werte anzeigen' },
        { en: 'True', de: 'Ja' },
        { en: 'False', de: 'Nein' },
        { en: 'Factored', de: 'Mit Faktor' },
        { en: 'User', de: 'Anwender' },
        { en: 'Notes', de: 'Bemerkungen' },
        { en: 'Save Product', de: 'Produkt speichern' },
        { en: 'no additives in use', de: 'keine Additive in Verwendung' },
        { en: 'Dilution', de: 'Einsatzkonzentration' },
        { en: 'Selling point', de: 'Verkaufsargument' },
        { en: 'Remove', de: 'Entfernen' },
        { en: 'Coolant Usage by Company', de: 'Kühlmittelverbrauch nach Unternehmen' },
        { en: 'Selected Group', de: 'Ausgewählte Gruppe' },
        { en: 'Users', de: 'Anwender' },
        { en: 'All Companies', de: 'Alle Unternehmen' },
        { en: 'All Groups', de: 'Alle Gruppen' },
        { en: 'All Activities', de: 'Alle Aktivitäten' },
        { en: 'No options', de: 'Keine Optionen' },
        { en: 'Add Company', de: 'Unternehmen hinzufügen' },
        { en: 'New Machine', de: 'Neue Maschine' },
        { en: 'login', de: 'Anmelden' },
        { en: 'logout', de: 'Abmelden' },
        { en: 'New Coolant', de: 'Neues Kühlmittel' },
        { en: 'Coolant Added', de: 'Kühlmittel hinzugefügt' },
        { en: 'New Entry', de: 'Neuer Eintrag' },
        { en: 'Add User', de: 'Benutzer hinzufügen' },
        { en: 'Update User', de: ' Benutzer aktualisieren' },
        { en: 'Machine Deleted', de: 'Maschine gelöscht' },
        { en: 'Machine Edit', de: 'Maschine bearbeiten' },
        { en: 'Edit Company', de: 'Unternehmen bearbeiten' },
        { en: 'Edit Coolant', de: 'Kühlmittel bearbeiten' },
        { en: 'Machine Edit Settings', de: 'Maschineneinstellungen bearbeiten' },
        { en: 'Updated Pending Values Entry', de: 'Ausstehenden Eintrag aktualisiert' },
        { en: 'Edit Entry', de: 'Eintrag bearbeiten' },
        { en: 'New Additive', de: 'Neues Additiv' },
        { en: 'Edit Additive', de: 'Additiv bearbeiten' },
        { en: 'Void Entry', de: 'Eintrag verbergen' },
        { en: 'Edit Brand', de: 'Marke bearbeiten' },
        { en: 'Change Coolant', de: 'Kühlmittel ändern' },
        { en: 'Machine Factor Updated', de: 'Maschinenfaktor geändert' },
        { en: 'Machine Reset Settings', de: 'Maschineneinstellung zurücksetzen' },
        { en: 'Coolant Change Frequency (days)', de: 'Kühlmittel-Wechselfrequenz (in Tagen)' },
        { en: 'Select Frequency', de: 'Frequenz wählen' },
        { en: 'Weekly', de: 'Wöchentlich' },
        { en: 'Every 2 Weeks', de: 'Alle 2 Wochen' },
        { en: 'Every 3 Weeks', de: 'Alle 3 Wochen' },
        { en: 'Monthly', de: 'Monatlich' },
        { en: 'Operator Notification days', de: 'Benachrichtigung des Operators (Tage)' },
        { en: 'Monday', de: 'Montag' },
        { en: 'Tuesday', de: 'Dienstag' },
        { en: 'Wednesday', de: 'Mittwoch' },
        { en: 'Thursday', de: 'Donnerstag' },
        { en: 'Friday', de: 'Freitag' },
        { en: 'Saturday', de: 'Samstag' },
        { en: 'Sunday', de: 'Sonntag' },
        { en: 'The system will check if the coolant needs changing Select Frequency on a Select Day Starting and email Select Operator for Select Notification Days consecitive days. If no coolant change has been actioned, then the system will email', de: 'Das System wird prüfen ob das das Kühlmittel Frequenz wählen gewechselt werden muss, beginnend mit einem Startdatum und Email des Bedieners für einen Zeitraum von Benachrichtigung des Operators (Tage) aufeinanderfolgende Tage per email erinnern. Wenn kein Wechsel stattfindet wird das System den Projektleiter per email informieren.' },
        { en: 'ALL', de: 'Alle' },
        { en: 'Add Company', de: 'Unternehmen hinzufügen' },
        { en: 'Company ID', de: 'Unternehmens-ID' },
        { en: 'Company Name', de: 'Unternehmensname' },
        { en: 'Total Users', de: 'Anwender insgesamt' },
        { en: 'Enterprise Superusers', de: 'Unternehmens-Superanwender' },
        { en: 'Company Superusers', de: 'Firmen-Superanwender' },
        { en: 'Read/Write Users', de: 'Lesen/Schreiben Anwender' },
        { en: 'Read Only Users', de: 'Nur Lesen Anwender' },
        { en: 'Total Machines', de: 'Maschinen insgesamt' },
        { en: 'Totals', de: 'Summe' },
        { en: 'Full Name', de: 'Kompletter Name' },
        { en: 'All', de: 'Alle' },
        { en: 'Company', de: 'Unternehmen' },
        { en: 'Details', de: 'Details' },
        { en: 'Actions', de: 'Aktionen' },
        { en: 'User Type', de: 'Anwendertyp' },
        { en: 'The security token included in the request is expired', de: 'Das Sicherheitstoken der Anfrage ist abgelaufen, bitte durch Drücken von F5 aktualisieren' },
        { en: 'Department', de: 'Abteilung' },
        { en: 'Primary Styles', de: 'Primäre Stile' },
        { en: 'Secondary Styles', de: 'Sekundäre Stile' },
        { en: 'Colour', de: 'Farbe' },
        { en: 'Text', de: 'Text' },
        { en: 'Add Brand', de: 'Marke hinzufügen' },
        { en: 'Add Additve Type', de: 'Additivtyp hinzufügen' },
        { en: 'Additive Type Name', de: 'Additiv Typenbezeichnung' },
        { en: 'Coming Soon', de: 'In Vorbereitung' },
        { en: 'No Logo Uploaded', de: 'Kein Logo hochgeladen' },
        { en: 'Select Companies', de: 'Unternehmen auswählen' },
        { en: 'Username', de: 'Benutzername' },
        { en: 'Initial', de: 'Initialen' },
        { en: 'Email', de: 'Email' },
        { en: 'Repeat Email', de: 'Email wiederholen' },
        { en: 'Tilte/Department', de: 'Titel / Abteilung' },
        { en: 'Phone Number', de: 'Telefonnummer' },
        { en: 'User Level', de: 'Benutzerebene' },
        { en: 'Read Only', de: 'Nur Lesen' },
        { en: 'Read/Write', de: 'Lesen/Schreiben' },
        { en: 'Company Superuser', de: 'Firmen-Superanwender' },
        { en: 'Enterprise Superuser', de: 'Unternehmens-Superanwender' },
        { en: 'Show 10', de: '10 anzeigen' },
        { en: 'Show 20', de: '20 anzeigen' },
        { en: 'Show 30', de: '30 anzeigen' },
        { en: 'Show 50', de: '50 anzeigen' },
        { en: 'Show 100', de: '100 anzeigen' },
        { en: 'Company Theming has been currently disabled', de: 'Unternehmensschema ist derzeit deaktiviert' },
        { en: 'Out of control', de: 'Außerhalb des Sollbereichs' },
        { en: 'Machine Check Frequency Emails/Notification', de: 'Maschinenwechselfrequenz Emails/Benachrichtigungen' },
        { en: 'Enable Operator App Notifications – coming soon', de: 'Benutzerbenachrichtigungen erlauben – in Vorbereitung' },
        { en: 'Go to Machine', de: 'Werte anzeigen' },
        { en: 'Not needed', de: 'Nicht erforderlich' },
        { en: 'Back', de: 'Zurück' },
        { en: 'Note body', de: 'Bemerkungen' },
        { en: 'Need Action', de: 'Eingreifen erforderlich' },
        { en: 'No', de: 'Nein' },
        { en: 'Critical', de: 'Kritisch' },
        { en: 'Select Addtive', de: 'Additiv auswählen' },
        { en: 'Select additive', de: 'Additiv auswählen' },
        { en: 'Submit Entry', de: 'Eintrag abschicken' },
        { en: 'New Entry Successfully Added', de: 'Neuer Eintrag erfolgreich hinzugefügt' },
        { en: 'No additives in use', de: 'keine Additive in Verwendung' },
        { en: 'Machine Export', de: 'Maschinenübersicht' },
        { en: 'Download PDF', de: 'Als pdf herunterladen' },
        { en: 'Set Orientation', de: 'Ausrichtung festlegen' },
        { en: 'Machine Export', de: 'Maschinenübersicht' },
        { en: 'Cannot contain spaces or special characters', de: 'Darf keine Leerzeichen oder Sonderzeichen enthalten' },
        { en: 'Title/Department', de: 'Titel / Abteilung' },
        { en: 'Update Preview', de: 'Voransicht aktualisieren' },
        { en: 'Loading PDF Preview', de: 'pdf-Voransicht laden' },
        { en: 'Download CSV', de: 'CSV-Datei herunterladen' },
        { en: 'Entries', de: 'Einträge' },
        { en: 'No Machines, Please add a Machine', de: 'Keine Maschinen vorhanden, bitte Maschine hinzufügen' },
        { en: 'Dashboard Export', de: 'Export Gesamtübersicht' },
        { en: 'Dashboard Report', de: 'Bericht Gesamtübersicht' },
        { en: 'Dosage', de: 'Dosierung' },
        { en: 'Last Machine Check', de: 'Letzte Maschinenprüfung' },
        { en: 'Coolant Details', de: 'KSS-Details' },
        { en: 'Machine Settings', de: 'Maschinenvorgaben' },
        { en: 'Sump Size', de: 'Tankvolumen' },
        { en: 'Since date of report printed', de: 'Seit letztem Druckdatum' },
        { en: 'PDF Export', de: 'Als pdf exportieren' },
        ]
        
        let words = data.find(lan => lan.en === string);
        //console.log(words)
        if (words && lan) {
            if (lan.id === 'de') {
                if (words.de !== '') {
                    return words.de
                }
                return words.en
            }

            return words.en
        }
        //console.log({ en: string, de: "" })
    }
    return string

};
export default setLanguageText;
