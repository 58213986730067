import React, { useState, useEffect, useContext } from 'react';
import { Button, Typography, TextField, Grid, Chip } from '@mui/material';
import TableBasic from './TableBasic';
import { useForm, Controller } from 'react-hook-form';
import makeStyles from '@mui/styles/makeStyles';
import { CirclePicker } from 'react-color';
import { UserContext } from '../../store/UserContext';
import { CompanyContext } from '../../store/CompanyContext';
import { createCompanyAttributes } from '../../helpers/createCompanyAttributes';
import GlobalDialog from '../reusable/GlobalDialog';
import { LayoutContext } from '../../store/LayoutContext';
import setLanguageText from '../../helpers/setLanguageText';

const useStyles = makeStyles({
  disabled: {
    color: '#ccc',
    '& .MuiInputBase-root ': {
      color: '#7b7b7b',
      background: '#f9f9f9'
    }
  }
});

function CompanyVariables({ currentVariables, closeModalRefresh, edit }) {
  const { selectedCompany } = useContext(CompanyContext);
  const { language } = useContext(LayoutContext)
  const { authUser } = useContext(UserContext);
  const [selectedVariable, setSelectedVariable] = useState();
  const [open, setOpen] = useState(false);

  //---------------------- FORM ------------------------//
  const { handleSubmit, control, reset } = useForm({
    mode: 'all',
    defaultValue: selectedVariable
  });
  const classes = useStyles();

  useEffect(() => {
    if (selectedVariable) {
      reset({ ...selectedVariable });
    }
  }, [reset, selectedVariable]);

  const onSubmit = data => {
    let updatedVariables;
    if (currentVariables) {
      let current = [...currentVariables];
      if (currentVariables) {
        //update selected attribute
        const index = current.findIndex(e => e.id === selectedVariable.id);

        if (index > -1) {
          current[index] = data;
        }
      } else {
        //add new attribute
        current.push(data);
      }
      updatedVariables = current;
    } else {
      //add first attribute
      updatedVariables = [data];
    }
    //console.log(updatedVariables)
    let body = {
      id: selectedCompany.company_id,
      variables: updatedVariables
    };
    createCompanyAttributes(authUser.api_key, 'post', body).then(result => {
      //console.log(result);
      setOpen(false);
      closeModalRefresh();
    });
  };

  //---------------------- DIALOG ------------------------//

  const handleClose = () => {
    setOpen(false);
  };

  const editVariable = selected => {
    //add selected Variable to edit
    setSelectedVariable(selected);
    setOpen(true);
  };

  //---------------------- VARIABLE TABLE ------------------------//

  const columns = React.useMemo(
    () => [
      {
        id: 'id',
        hidden: true,
        Header: () => <Typography variant="h6">{setLanguageText(language,"ID")}</Typography>,
        accessor: 'id'
      },
      {
        id: 'name',
        Header: () => <Typography variant="h6">{setLanguageText(language,"Name")}</Typography>,
        accessor: 'name',
        Cell: ({value}) => setLanguageText(language,value)
      },
      {
        id: 'value',
        hidden : !edit,
        Header: () => <Typography variant="h6">{setLanguageText(language,"Status")}</Typography>,
        accessor: 'value'
      },
      {
        id: 'colour',
        Header: () => <Typography variant="h6">{setLanguageText(language,"Colour")}</Typography>,
        accessor: 'colour',
        Cell: data => (
          <Chip
            icon={null}
            className={`${classes.chip}`}
            style={{
              backgroundColor: data.cell.value.hex
                ? data.cell.value.hex
                : 'transparent'
            }}
            label={<span>{setLanguageText(language,"Example")}</span>}
          />
        )
      },
      {
        id: 'attr_actions',
        hidden : !edit,
        Cell: data => (
          <div className="form-button">
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => editVariable(data.row.original)}
              //className={`${buttonStyle.button}`}
              disabled={!edit}
            >
              {setLanguageText(language,"Edit")}
            </Button>
          </div>
        )
      }
    ],
    [classes, edit]
  );

  return (
    <>
      {currentVariables && (
        <TableBasic columns={columns} data={currentVariables} />
      )}
      <GlobalDialog
        open={open}
        closeModal={handleClose}
        title={selectedVariable?.name}
        handleSubmit={handleSubmit(onSubmit)}
        buttonTitle="Save"
        fullWidth
        maxWidth="md"
      >
        <Grid container item spacing={2}>
          <Grid item xs={6}>     
          <Controller
            name="id"
            control={control}
            defaultValue=""
            rules={{ required: 'ID required' }}
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
               label={setLanguageText(language,"ID")}
                variant="outlined"
                value={value}
                disabled={true}
                onChange={onChange}
              />
            )}
          />
          </Grid>
          <Grid item xs={6}>
          <Controller
            name="name"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
               label={setLanguageText(language,"ID")}
                variant="outlined"
                value={value}
                disabled={true}
                onChange={onChange}
              />
            )}
          />
          </Grid>
          <Grid item xs={6}>
          <Controller
            name="value"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
               label={setLanguageText(language,"ID")}
                variant="outlined"
                value={value}
                disabled={true}
                onChange={onChange}
              />
            )}
          />
          </Grid>
          <Grid item xs={6}>
          <Controller
            name="colour"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => (
              <CirclePicker
                  color={value.hex}
                  onChange={onChange}
                  colors={[
                    '#00d79b',
                    '#ffc500',
                    '#fc466f',
                    '#bdbdbd',
                    'transparent'
                  ]}
                />
            )}
          />
          </Grid>
        </Grid>
      </GlobalDialog>
    </>
  );
}

export default CompanyVariables;
