import React, { useContext, useEffect, useState } from 'react';
import {
  darken,
  lighten,
  Grid,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Switch,
  FormControlLabel
} from '@mui/material';
import { TwitterPicker } from 'react-color';
import { APP_THEME, COMPANY_THEME, USER_THEME, } from '../../store/LayoutReducers';
import { CHANGE_THEME_ACTIVE } from '../../store/UserReducers';
import { LayoutContext } from '../../store/LayoutContext';
import PrimaryBtnIcon from '../buttons/PrimaryBtnIcon';
import { faCheck, faSave } from '@fortawesome/pro-light-svg-icons';
import { makeStyles } from '@mui/styles';
import LoadingButton from '../buttons/LoadingButton';
import axiosRequest from '../../helpers/axiosRequest';
import useAsync from '../../Hooks/useAsync';
import { UserContext } from '../../store/UserContext';
import { Alert } from '@mui/material';
import { CompanyContext } from '../../store/CompanyContext';
import setLanguageText from '../../helpers/setLanguageText';

const useStyles = makeStyles(theme => ({
  card: { height: '100%' },
  cardHeader: { borderBottom: '3.2px solid #f0f0f0' },
  cardFooter: {
    padding: theme.spacing(1),
    borderTop: `1px solid ${theme.palette.background.light}`,
    background: `${theme.palette.background.light}`
  }
}));

const ThemeForm = ({ type , edit }) => {

  //************  STYLES ************

  const classes = useStyles();

  //************  CONTEXT ************
  const { dispatchUser, authUser, user } = useContext(UserContext);
  const { selectedCompany } = useContext(CompanyContext);
  const { dispatchTheme, theme , language} = useContext(LayoutContext);
  const {
    data,
    loading,
    executeAsync
  } = useAsync({
    axiosRequest: axiosRequest
  });

  //************ STATES ************

  const [currentTheme, setCurrentTheme] = useState();
  const [enableTheme, setEnableTheme] = useState();

  //************  USE EFFECT ************

  useEffect(() => {
    // console.log(theme)
    // console.log(type)
    if (type) {
      if (type === 'app') {
        setCurrentTheme(theme.app)
      } else if (type === 'company') {
        setCurrentTheme(theme.company)
      } else if (type === 'user') {
        setCurrentTheme(theme.user);
        setEnableTheme(user.theme_active)
      }
    }
  }, [theme, type , user])

  //************ FUNCTIONS ************

  const createBaseTheme = async e => {
    e.preventDefault();
    let body = {
      theme_type: type,
      theme_settings: theme.app
    }
    if (type === 'company') {
      body.company_id = selectedCompany.company_id
      //user theme
    } else if (type === 'user') {
      body.user_id = user.id
    }
    await executeAsync({
      endpoint: 'theme',
      api_key: authUser.api_key,
      method: 'post',
      body: body
    });
    setCurrentTheme(theme.app)
  }

  const Update = async e => {
    e.preventDefault();
    let body = {
      theme_type: type,
      theme_settings: currentTheme
    }
    //company theme
    if (type === 'company') {
      body.company_id = selectedCompany.company_id
      //user theme
    } else if (type === 'user') {
      body.user_id = user.id
      body.active = enableTheme
    }
    await executeAsync({
      endpoint: 'theme',
      api_key: authUser.api_key,
      method: 'patch',
      body: body
    });
    //update context
    if (type === 'app') {
      dispatchTheme({
        type: APP_THEME,
        payload: currentTheme
      });
      //remove company
      dispatchTheme({
        type: COMPANY_THEME,
        payload: []
      });
    } else if (type === 'company') {
      dispatchTheme({
        type: COMPANY_THEME,
        payload: currentTheme
      });
    } else if (type === 'user') {
      dispatchTheme({
        type: USER_THEME,
        payload: currentTheme
      });
      dispatchUser({
        type: CHANGE_THEME_ACTIVE,
        payload: enableTheme
      });
    }
  }

  return (
    <Grid container spacing={2}>
      {currentTheme && Object.keys(currentTheme).length ?
        <>
          {type === 'user' ?
            <Grid item xs={12} >
           
              <FormControlLabel
                control={
                  <Switch
                    checked={enableTheme}
                    onChange={(e, value) =>
                      setEnableTheme(enableTheme => !enableTheme)
                    }
                    name="active"
                  />
                }
               label={setLanguageText(language,"Enable Theme")}
              />
                 <Alert
                severity="info" Î
                elevation={1}
                variant="standard">{setLanguageText(language,"Enabling a user theme will override all company themes.")}</Alert>
            </Grid>
            : null}
          <Grid item xs={12} md={6} >
            <Card className={classes.card} >
              <CardHeader className={classes.cardHeader} title={setLanguageText(language,"Primary Styles")} />
              <CardContent>
                <Grid item xs={12}>
                  <p>{setLanguageText(language,"Colour")}</p>
                </Grid>
                <Grid item xs={12}>
                  <TwitterPicker
                    width='100%'
                    color={currentTheme.palette.primary.main}
                    onChange={e => setCurrentTheme(theme => {
                      return {
                        palette: {
                          ...theme.palette,
                          primary: {
                            ...theme.palette.primary,
                            main: e.hex,
                            dark: darken(e.hex, .2),
                            light: lighten(e.hex, .2)
                          }
                        }
                      }
                    })}
                    colors={[
                      '#008CBA',
                      '#00d79b',
                      '#ffc500',
                      '#fc466f',
                      '#bdbdbd'
                    ]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <p>{setLanguageText(language,"Text")}</p>
                </Grid>
                <Grid item xs={12}>
                  <TwitterPicker
                    width='100%'
                    color={currentTheme?.palette?.primary?.contrastText}
                    onChange={e => setCurrentTheme(theme => {
                      return {
                        palette: {
                          ...theme.palette,
                          primary: {
                            ...theme.palette.primary,
                            contrastText: e.hex

                          }
                        }
                      }
                    })}
                    colors={[
                      `#ffffff`,
                      '#000000',
                    ]}
                  />
                </Grid>

              </CardContent>
              <CardActions className={classes.cardFooter} >
                <Grid item xs={12}>
                  <PrimaryBtnIcon
                    icon={faCheck}
                    text="Primary Styles"
                    style={{
                      backgroundColor: currentTheme?.palette?.primary?.main,
                      color: currentTheme?.palette?.primary?.contrastText
                    }}
                    disabled={!edit}
                  />
                </Grid>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card className={classes.card} >
              <CardHeader className={classes.cardHeader} title={setLanguageText(language,"Secondary Styles")} />
              <CardContent>
                <Grid item xs={12}>
                  <p>{setLanguageText(language,"Colour")}</p>
                </Grid>
                <Grid item xs={12}>
                  < TwitterPicker
                    width='100%'
                    color={currentTheme.palette.secondary.main}
                    onChange={e => setCurrentTheme(theme => {
                      return {
                        palette: {
                          ...theme.palette,
                          secondary: {
                            ...theme.palette.secondary,
                            main: e.hex,
                            dark: darken(e.hex, .2),
                            light: lighten(e.hex, .2)
                          }
                        }
                      }
                    })}
                    colors={[
                      '#008CBA',
                      '#00d79b',
                      '#ffc500',
                      '#fc466f',
                      '#bdbdbd'
                    ]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <p>{setLanguageText(language,"Text")}</p>
                </Grid>
                <Grid item xs={12}>
                  < TwitterPicker
                    width='100%'
                    color={currentTheme?.palette?.secondary?.contrastText}
                    onChange={e => setCurrentTheme(theme => {
                      return {
                        palette: {
                          ...theme.palette,
                          secondary: {
                            ...theme.palette.secondary,
                            contrastText: e.hex

                          }
                        }
                      }
                    })}
                    colors={[
                      `#ffffff`,
                      '#000000',
                    ]}
                  />
                </Grid>

              </CardContent>
              <CardActions className={classes.cardFooter} >
                <Grid item xs={12}>
                  <PrimaryBtnIcon
                    color="secondary"
                    icon={faCheck}
                    text="Secondary Styles"
                    style={{
                      backgroundColor: currentTheme?.palette?.secondary?.main,
                      color: currentTheme?.palette?.secondary?.contrastText
                    }}
                    disabled={!edit}
                  />
                </Grid>
              </CardActions>
            </Card>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item >
              <LoadingButton
                icon={faSave}
                text="Update Theme"
                iconSize="1x"
                color="primary"
                loading={loading}
                onClick={e => Update(e)}
                disabled={!edit}
              />
            </Grid>
            {data?.errorMessage && (
              <Grid item>
                <Alert severity="error" variant="standard">
                  {data.errorMessage}
                </Alert>
              </Grid>
            )}
          </Grid>
        </>
        :
        <Grid item>
          <LoadingButton
            icon={faSave}
            text="Create Theme"
            iconSize="1x"
            color="primary"
            loading={loading}
            onClick={e => createBaseTheme(e)}
            disabled={!edit}
          />
        </Grid>

      }

    </Grid>


  );
};

export default ThemeForm;
