import React, { useContext } from 'react';
import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PageContent from './PageContent';
import PageHeader from './PageHeader';
import { LayoutContext } from '../store/LayoutContext';
import setLanguageText from '../helpers/setLanguageText';

const useStyles = makeStyles(theme => ({
  card: {
    width: '100%'
  },
  header: {
    borderBottom: `${theme.spacing(0.4)} solid ${theme.palette.background.light
      }`,
    padding: theme.spacing(1)
  }
}));

const ComingSoon = () => {

//************  STYLES ************
  const classes = useStyles();
  const { language } = useContext(LayoutContext)

  return (
    <>
      <PageHeader>
        <Grid item xs={12}>
          <Grid
            item
            container
            justifyContent="space-between"
            className={classes.header}
          >
            <Grid item xs={12} sm={'auto'}>
              <Typography variant="h5">{setLanguageText(language,"Coming Soon")}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </PageHeader>
      <PageContent className="notification">
      
      </PageContent>
    </>

  )
};

export default ComingSoon;
