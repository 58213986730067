import React, { useEffect, useState, useContext } from 'react';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  ButtonGroup
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { buttonStyles } from '../../../styles/buttonStyles';
import { UserContext } from '../../../store/UserContext';
import Image from "./../../reusable/Image";
import CoolantPDF from '../Coolants/CoolantPDF';
//import { isMobileOnly } from 'react-device-detect';
import PrimaryBtnIcon from '../../buttons/PrimaryBtnIcon';
import { faEdit, faPlus, faTimes } from '@fortawesome/pro-light-svg-icons';
import { ProductsContext } from '../../../store/ProductsContext';
import { SAVE_SELECTED_PRODUCT, UPDATE_PRODUCT_OPTIONS } from '../../../store/ProductsReducers';
import { Alert } from '@mui/material';
import { CompanyContext } from '../../../store/CompanyContext';
import useAsync from '../../../Hooks/useAsync';
import axiosRequest from '../../../helpers/axiosRequest';
import LoadingButton from '../../buttons/LoadingButton';
import CardStyles from '../../../styles/cardStyles';
import setLanguageText from '../../../helpers/setLanguageText';
import { LayoutContext } from '../../../store/LayoutContext';

const useStyles = makeStyles(theme => ({
  image: {
    width: '100px',
    height: '100px',
    '& img': {
      objectFit: 'contain',
      width: '100%',
      height: '100%'
    }
  }
}));

const ProductCard = ({ product, openEditDialog, loading_vis }) => {

  const { user, authUser } = useContext(UserContext);
  const { selectedCompany } = useContext(CompanyContext);
  const { language } = useContext(LayoutContext);
  const { dispatchSelectedProduct, productsOptions, dispatchProductsOptions } = useContext(ProductsContext);

  //****** STATES ******
  const [visible, setVisible] = useState();

  //****** STYLES ******
  const classes = useStyles();
  const buttonStyle = buttonStyles();
  const cardStyles = CardStyles();

  //****** FUNCTIONS ******

  //set if visible to company
  useEffect(() => {
    if (productsOptions.visible) {
      //console.log(productsOptions.visible)
      const index = productsOptions.visible.findIndex(
        coolant => coolant.coolant_id === product.coolant_id
      );
      //console.log(index)
      if (index > -1) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    }
  }, [
    productsOptions.visible,
    product
  ]);


  const {
    loading,
    executeAsync
  } = useAsync({
    axiosRequest: axiosRequest
  });

  const updateToggle = (product) => {
    //update the datbase
    //console.log(product)
    setVisible(!visible)
    executeAsync({
      method: 'patch',
      api_key: authUser.api_key,
      endpoint: `coolants`,
      body: {
        visible: !visible ? 'add' : 'remove',
        company_id: selectedCompany.company_id,
        coolant_id: product.coolant_id
      }
    });
    if (visible) {
      console.log('remove')

      dispatchProductsOptions({
        type: UPDATE_PRODUCT_OPTIONS,
        payload: { 'visible': productsOptions.visible.filter(visible => visible.coolant_id !== product.coolant_id) }
      });

    } else {
      console.log('add')
      dispatchProductsOptions({
        type: UPDATE_PRODUCT_OPTIONS,
        payload: { 'visible': [...productsOptions.visible, { company_id: selectedCompany.company_id, coolant_id: product.coolant_id }] }
      });
    }
  };


  //****** RENDER ******
  return (
    <>

      <Card className={cardStyles.card} elevation={0}>

        <Grid container direction="column" className={cardStyles.cardContainer}>
          {user.level === 9 && (
            <Grid className={cardStyles.adminHeader} item container spacing={2} alignItems="stretch" >
              { !loading_vis ?
                <>
                  <Grid item container justifyContent="flex-start" xs={4} >
                    <ButtonGroup fullWidth>
                      <LoadingButton
                        variant="contained"
                        className={visible ? buttonStyle.danger : buttonStyle.success}
                        onClick={() => updateToggle(product)}
                        //disabled
                        icon={visible ? faTimes : faPlus}
                        text={setLanguageText(language, visible ? 'Hide' : 'Show')}
                        loading={loading}
                      />

                    </ButtonGroup>
                  </Grid>
                  <Grid item xs={8} >
                    <Alert severity={visible ? 'success' : 'error'}>
                      {visible ? `${setLanguageText(language,"Visible for")} ${selectedCompany.company_name}` : `${setLanguageText(language,"Hidden for")} ${selectedCompany.company_name}`}
                    </Alert>
                  </Grid>
                </>
                :
                <Grid item container justifyContent="flex-start" xs={12} >
                  <Alert severity="info">
                    {setLanguageText(language,"Fetching Company Settings")}
                  </Alert>
                </Grid>
              }
            </Grid>
          )}
          <Grid
            item
            container
            //direction="column"
            className={cardStyles.header}
            spacing={1}
          >
            <Grid item container justifyContent="space-between">
              <Grid item>
                <Typography variant="h5">{product.coolant_name}</Typography>
              </Grid>
              {user.level === 9 && (
                <Grid item>
                  <PrimaryBtnIcon
                    icon={faEdit}
                    text="edit product"
                    //size="small"
                    //iconSize="lg"
                    textVariant="caption"
                    //className={buttonStyle.primary}
                    onClick={() => {
                      dispatchSelectedProduct({
                        type: SAVE_SELECTED_PRODUCT,
                        payload: product
                      });
                      openEditDialog();
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <CardContent justify="center" className={visible ? cardStyles.cardContent : cardStyles.cardContentHide}>
            <Grid container>
              <Grid item xs={10}>
                <Typography variant="body2" className="product--desc">
                  {product?.coolant_defaults?.coolant?.type}
                </Typography>
                <ul style={{ padding: '0 0 0 1em', margin: '5px 0' }}>
                  {product?.coolant_defaults?.coolant?.selling_points &&
                    product.coolant_defaults.coolant.selling_points.map(
                      (point, index) => (
                        <li key={`point--${index}`}>
                          <Typography variant="body2" className={classes.li}>
                            {point}
                          </Typography>
                        </li>
                      )
                    )}
                </ul>
              </Grid>
              <Grid className={classes.image} item xs={2}>
                <Image coolant={product} />
              </Grid>
            </Grid>
          </CardContent>

          <Grid
            item
            container
            justifyContent="space-around"
            className={visible ? cardStyles.footer : cardStyles.footerHide}
          >
            <CoolantPDF coolant={product} />
          </Grid>
        </Grid>
      </Card>

    </>
  );
};

export default ProductCard;
