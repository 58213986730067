import React, { useContext, useState, Fragment } from 'react';
import {
  Card,
  CardContent,
  Chip,
  Grid,
  Tab,
  Tabs,
  Typography,
  useTheme
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Overview from './Overview';
import Machines from './Machines';
import CardStyles from '../../../styles/cardStyles';
import { faEdit } from '@fortawesome/pro-light-svg-icons';
import tableChipStyles from '../../../styles/tableChipStyles';
import PrimaryBtnIcon from '../../buttons/PrimaryBtnIcon';
import { AdditiveContext } from '../../../store/AdditiveContext';
import { SAVE_SELECTED_ADDITIVE } from '../../../store/AdditiveReducers';
import { buttonStyles } from '../../../styles/buttonStyles';
import AdditivePDF from './AdditivePDF';
import { UserContext } from '../../../store/UserContext';
import { isMobileOnly } from 'react-device-detect';
import setLanguageText from '../../../helpers/setLanguageText';
import { LayoutContext } from '../../../store/LayoutContext';

const useStyles = makeStyles(theme => ({
  tab: {
    minWidth: 'auto'
  },
  li: {
    display: 'inline'
  },
  dialog: {
    height: 'calc(100% - 64px)'
  }
}));

const CardAdditives = ({ additive, setIsDialog }) => {

  //****** CONTEXT ******
  const { dispatchSelectedAdditive } = useContext(AdditiveContext);
  const { user } = useContext(UserContext);
  const { language } = useContext(LayoutContext)
  //****** STATES ******
  const [tabValue, setTabValue] = useState(0);

  //****** STYLES ******
  const classes = useStyles();
  const theme = useTheme();
  const tableChipStyle = tableChipStyles();
  const buttonStyle = buttonStyles();
  const cardStyles = CardStyles();



  //****** FUNCTIONS ******
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Card className={cardStyles.card}>
        <Grid container direction="column" className={cardStyles.cardContainer}>
          <Grid
            item
            container
            direction="column"
            className={CardStyles().header}
            spacing={1}
          >
            <Grid item container justifyContent="space-between">
              <Grid item>
                <Typography variant="h5" gutterBottom>
                  {additive.additive_name}
                </Typography>
              </Grid>
              {user.level === 9 && !isMobileOnly && (
              <Grid item>
                <PrimaryBtnIcon
                  icon={faEdit}
                  text="edit"
                  size="small"
                  iconSize="lg"
                  textVariant="caption"
                  className={buttonStyle.danger}
                  onClick={() => {
                    dispatchSelectedAdditive({
                      type: SAVE_SELECTED_ADDITIVE,
                      payload: additive
                    });
                    setIsDialog(true);
                  }}
                />
              </Grid>
              )}
            </Grid>
            <Grid item container spacing={1}>
              <Grid item>
                <Chip
                  size="small"
                  style={{ fontWeight: 'bold' }}
                  label={additive.additive_attr.type}
                />
              </Grid>
              <Grid item>
                <Chip
                  size="small"
                  className={tableChipStyle.success}
                  label={`Dilution: ${additive.additive_attr.dilution}`}
                />
              </Grid>
            </Grid>
          </Grid>
          <CardContent justify="center" className={cardStyles.cardContent}>
            {additive.machines_count ? (
              <>
                <Tabs
                  indicatorColor="primary"
                  textColor="primary"
                  value={tabValue}
                  onChange={handleTabChange}
                  variant="fullWidth"
                  style={{ marginBottom: theme.spacing(1) }}
                >
                  <Tab label={setLanguageText(language,"Overview")} className={`${classes.tab}`} />
                  <Tab label={setLanguageText(language,"Machines")} className={`${classes.tab}`} />
                </Tabs>
                {tabValue === 0 ? (
                  <Overview
                    additive={additive}
                    classes={classes}
                    totalMachines={additive.total_machines}
                    //totalMachines={machines.length}
                  />
                ) : (
                    <Machines additive={additive} />
                  )}
              </>
            ) : (
                <Overview
                  additive={additive}
                  classes={classes}
                  totalMachines={additive.total_machines}
                  //totalMachines={machines.length}
                  noBar={true}
                />
              )}
          </CardContent>

          <Grid
            item
            container
            justifyContent="space-around"
            className={cardStyles.footer}
          >
            <AdditivePDF additive={additive} />

          </Grid>
        </Grid>
      </Card>

    </>
  );
};

export default CardAdditives;
