import React, { useState, useEffect, useContext } from 'react';
import axiosRequest from '../../helpers/axiosRequest';
import { UserContext } from '../../store/UserContext';
import useAsync from '../../Hooks/useAsync';
import GlobalDialog from '../reusable/GlobalDialog';
import BrandForm from './BrandForm';

const AddBrand = ({ closeAddDialog, ...props }) => {
  //****** CONTEXT ******
  const { authUser } = useContext(UserContext);
  //****** STATE ******
  const [brand, setBrand] = useState();

  const {
    error,
    data,
    loading,
    logCompleted,
    logError,
    executeAsync,
    setData,
  } = useAsync({
    axiosRequest: axiosRequest
  });

  useEffect(() => {
    // TODO handle if activity error
    if (logError) console.log('Something went wrong with the Activity Log');

  }, [closeAddDialog, data, logCompleted, logError]);

  //set brand name from form
  const updateBrand = e => {
    setBrand({ brand_name: e.target.value });
  };

  //Create new brand
  const handleSubmit = async e => {
    e.preventDefault()
    //console.log('new brand')
    await executeAsync({
      endpoint: 'brands',
      api_key: authUser.api_key,
      method: 'post',
      body: {
        brand_name: brand.brand_name
      }
    });
    setTimeout(() => {
      //reset form 
      setBrand();
      closeAddDialog();
      //reset data from aysnc
      setData();
    }, 1000);
  };

  //****** RENDER ******
  return (
    <GlobalDialog
      closeModal={closeAddDialog}
      title="Add New Brand"
      handleSubmit={handleSubmit}
      buttonTitle={"Add Brand"}
      successMessage={"Brand added successfully!"}
      loading={loading}
      error={error}
      data={data}
      fullWidth
      maxWidth="sm"
      {...props}
    >
      <BrandForm
        brand={brand}
        updateBrand={updateBrand}
      />
    </GlobalDialog>
  );
};

export default AddBrand;
