import axiosRequest from './axiosRequest';

// Check user level and fetch companies accordingly
export const fetchBrands = (api_key, brand_id) => {
  if (brand_id) {
    return axiosRequest({
      api_key,
      method: 'get',
      endpoint: 'brands',
      params: {
        brand_id
      }
    });
  }
  return axiosRequest({
    api_key,
    method: 'get',
    endpoint: 'brands'
  });
};
