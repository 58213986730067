import React, {useContext, useState} from 'react';
import PageContent from '../PageContent';
import {
  Grid,
  Typography,
  TextField
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import PageHeader from '../PageHeader';
import config from './../../config';
import OutlinedDiv from '../reusable/OutlinedDiv';
import setLanguageText from '../../helpers/setLanguageText';
import { LayoutContext } from '../../store/LayoutContext';

const useStyles = makeStyles(theme => ({
  card: {
    width: '100%'
  },
  header: {
    borderBottom: `${theme.spacing(0.4)} solid ${theme.palette.background.light
      }`,
    padding: theme.spacing(1)
  }
}));

const ApplicationDetails = () => {
  //************  STYLES ************
  const classes = useStyles();
  const { language } = useContext(LayoutContext);  
  let app = config.APPNAME
  const [logo, setLogo] = useState('');
  import(`../../image/${app}-logo.jpg`).then((module) => {
    setLogo(module.default);
  });

  return (
    <>

      <PageHeader>
        <Grid item xs={12}>
          <Grid
            item
            container
            justifyContent="space-between"
            className={classes.header}
          >
            <Grid item xs={12} sm={'auto'}>
              <Typography variant="h5">{setLanguageText(language,"Application Details")}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </PageHeader>
      <PageContent className="ApplicationDetails">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="app_name"
             label={setLanguageText(language,"App Name")}
              variant="outlined"
              value={config.APPNAME}
              disabled
            />
            </Grid>
            <Grid item xs={12} >
              <TextField
                fullWidth
                name="app_ins"
               label={setLanguageText(language,"Instance")}
                variant="outlined"
                value={config.INSTANCE}
                disabled
              />
            </Grid>
            <Grid item xs={12} >
              <OutlinedDiv label={setLanguageText(language,"Application Logo")} >
                <img src={logo}/>
              </OutlinedDiv>
            </Grid>
          </Grid>

        </PageContent>
    </>
  );
};

export default ApplicationDetails;
