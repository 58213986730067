import React, { useContext, useEffect } from 'react';
import { TextField } from '@mui/material';
import { SAVE_GROUP, SAVE_GROUP_NAME } from '../../store/SelectionsReducers';
import { SelectionsContext } from '../../store/SelectionsContext';
import { LayoutContext } from '../../store/LayoutContext';
import Autocomplete from '@mui/material/Autocomplete';
import setLanguageText from '../../helpers/setLanguageText';

function GroupDropdown() {
  const { addNewMachine, dispatchAddNewMachine, groups } =
    useContext(SelectionsContext);
  const { language } =useContext(LayoutContext);

  useEffect(() => {
    if (groups.length) {
      dispatchAddNewMachine({ type: SAVE_GROUP, payload: groups[0] });
      dispatchAddNewMachine({
        type: SAVE_GROUP_NAME,
        payload: groups[0].group_name
      });
    }
  }, [dispatchAddNewMachine, groups]);

  const onSelectGroup = (e, group) => {
    if(group){
      dispatchAddNewMachine({
        type: SAVE_GROUP,
        payload: group
      });

      dispatchAddNewMachine({
        type: SAVE_GROUP_NAME,
        payload: group.group_name
      });
    }
  };

  return (
    <>
      <Autocomplete
        id="groups"
        options={groups}
        getOptionLabel={option => option.group_name}
        renderInput={params => (
          <TextField {...params} label={setLanguageText(language,"Select Current Group")} variant="outlined" />
        )}
        value={addNewMachine.group}
        onChange={onSelectGroup}
      />
    </>
  );
}

export default GroupDropdown;
