import React, { useEffect, useState, useContext } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Paper,
  Typography,
  useTheme
} from '@mui/material';
import { buttonStyles } from '../../styles/buttonStyles';
import formatDate from '../../helpers/formatDate';
import CardCell from '../Machines/GridView/CardCell';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVial } from '@fortawesome/pro-solid-svg-icons';
import { Sms } from '@mui/icons-material';
import CreateChip from '../reusable/CreateChip';
import { CompanyContext } from '../../store/CompanyContext';
import { LayoutContext } from '../../store/LayoutContext';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import calcOverdueCoolant from '../../helpers/calcOverdueCoolant';
import setLanguageText from '../../helpers/setLanguageText';

const useStyles = makeStyles({
  headerLink: {
    textDecoration: 'none',
    color: 'inherit'
  }
});

const DisplayCard = ({ machine, gridView }) => {
  const isOOC = machine.last_entry.out_of_control;
  //****** STYLES ******
  const theme = useTheme();
  const classes = useStyles();
  const buttonStyle = buttonStyles();
  const [hasAdditives, setHasAdditives] = useState(false);
  const [hasNotes, setHasNotes] = useState(false);
  const { selectedCompany } = useContext(CompanyContext);
  const { language } = useContext(LayoutContext)

  useEffect(() => {
    if (machine && machine.additives) {
      setHasAdditives(true);
    }
  }, [machine]);

  useEffect(() => {
    if (machine && machine.notes) {
      setHasNotes(true);
    }
  }, [machine]);
  // console.log(machine);
  return (
    <Card style={{ width: '100%', height : '100%' }}>
      <Link
        to={`/machine/${machine.machine_id}`}
        className={classes.headerLink}
      >
        <CardHeader
          style={{
            backgroundColor: isOOC
              ? theme.palette.error.light
              : theme.palette.success.light,
              padding : '10px'
          }}
          title={<Typography variant="h6">{machine.machine_name }</Typography>}
        />
      </Link>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item container spacing={1}>
            {/* LAST COOLANT CHANGE & LAST ENTRY */}
            <Grid item style={{textAlign: 'left'}} xs={12}>
       
                  <Typography variant="body2">
                    <strong>{setLanguageText(language,"Group")}</strong>: {machine.machine_group} <br/>
                    <strong>{setLanguageText(language,"Operator")}</strong>: {machine?.machine_settings?.admin?.operator_email?.name} <br/>
                    <strong>{setLanguageText(language,"Last Coolant Chang")}e</strong>:{' '}
                    {formatDate(
                      machine.last_coolant_change ? machine.last_coolant_change :
                      machine?.machine_settings?.machine_defaults?.last_coolant_change ? 
                      machine?.machine_settings?.machine_defaults?.last_coolant_change :
                      null 
                    )} 
                  </Typography> 
                  {machine.machine_settings.machine_defaults?.change_freq && calcOverdueCoolant(machine.last_coolant_change, machine.machine_settings.machine_defaults.change_freq, language)}
            </Grid>
          </Grid>
          <Grid item container spacing={1}>
            {selectedCompany.company_attributes &&
              selectedCompany.company_attributes.map(item => {
                return (
                  <Grid item container xs={6} key={item.one.attr_id}>
                    <CardCell title={item.one.attr_name}>
                      <CreateChip
                        company_attr_id={item.one.attr_id}
                        entry_attributes={machine.last_entry.attributes_new}
                      />
                    </CardCell>
                  </Grid>
                );
              })}
          </Grid>
          {!gridView && hasAdditives ? (
            <Grid item container>
              <CardCell
                title="Additives"
                icon={
                  <FontAwesomeIcon
                    icon={faVial}
                    style={{ fontSize: '20px', marginRight: theme.spacing(1) }}
                  />
                }
              >
                {machine.additives &&
                  machine.additives.map(additive => {
                    return (
                      <Paper
                        key={additive.additive_id}
                        className={buttonStyle.button}
                        style={{
                          backgroundColor: theme.palette.grey[400]
                        }}
                      >
                        <Typography variant="caption">
                          {additive.additive_type}
                        </Typography>
                      </Paper>
                    );
                  })}
              </CardCell>
            </Grid>
          ) : null}
          {!gridView && hasNotes ? (
            <Grid item container>
              <CardCell
                title="Notes"
                icon={
                  <Sms
                    style={{ fontSize: '20px', marginRight: theme.spacing(1) }}
                  />
                }
              >
                <strong style={{ color: theme.palette.error.main }}>
                  {machine.notes}
                </strong>
              </CardCell>
            </Grid>
          ) : null}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default DisplayCard;
