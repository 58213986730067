import React, { useEffect, useState, useContext } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Paper,
  Typography,
  useTheme
} from '@mui/material';
import { buttonStyles } from '../../../styles/buttonStyles';
import formatDate from '../../../helpers/formatDate';
import CardCell from './CardCell';
import CreateChip from '../../reusable/CreateChip';
import { CompanyContext } from '../../../store/CompanyContext';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import calcOverdueCoolant from '../../../helpers/calcOverdueCoolant';
import { Alert } from '@mui/material';
import AdditiveTooltip from '../../reusable/AdditiveTooltip';
import setLanguageText from '../../../helpers/setLanguageText';
import { LayoutContext } from '../../../store/LayoutContext';

const useStyles = makeStyles({
  headerLink: {
    textDecoration: 'none',
    color: 'inherit'
  }
});

const MachineCard = ({ machine }) => {
  const isOOC = machine.last_entry.out_of_control;
  //****** STYLES ******
  const theme = useTheme();
  const classes = useStyles();
  const buttonStyle = buttonStyles();
  const [hasAdditives, setHasAdditives] = useState(false);
  const [hasNotes, setHasNotes] = useState(false);
  const [hideLCC, setHideLCC] = useState();
  const { selectedCompany } = useContext(CompanyContext);
  const { language } = useContext(LayoutContext);

  useEffect(() => {
    if (machine && machine.last_entry.additives) {
      setHasAdditives(true);
    }
  }, [machine]);

  useEffect(() => {
    if (selectedCompany?.company_settings?.machines_defaults) {
      let found = (selectedCompany.company_settings.machines_defaults).find(attr => attr.id === "last_coolant_change")
      if(found && found.isVisible){
        setHideLCC(found.isVisible);
      }
    }
  }, [selectedCompany]);

  useEffect(() => {
    //console.log(machine)
    if (machine && machine.last_entry.notes) {
      setHasNotes(true);
    }
  }, [machine]);
  // console.log(machine);
  return (
    <Card style={{ width: '100%' }}>
      <Link
        to={`/machine/${machine.machine_id}`}
        className={classes.headerLink}
      >
        <CardHeader
          style={{
            backgroundColor: isOOC
              ? theme.palette.error.light
              : theme.palette.success.light
          }}
          title={<Typography variant="h4">{machine.machine_name}</Typography>}
          subheader={machine.machine_group}
        />
      </Link>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item container spacing={1}>
            {/* LAST COOLANT CHANGE & LAST ENTRY */}

            <Grid item container xs={12}>
              <Paper className={`${buttonStyle.box}`} variant="outlined">
                <Grid container direction="column">
                  {machine?.machine_settings?.admin?.operator_email?.name && 
                    <Typography variant="body1">
                      <strong>{setLanguageText(language,"Operator")}</strong>: {machine.machine_settings.admin.operator_email.name}{machine.machine_settings.admin.operator_email.email ? ` (${machine.machine_settings.admin.operator_email.email})` : null}
                    </Typography>
                  }
                {hideLCC ?
                  <Typography variant="body1">
                    <strong>{setLanguageText(language,"Last Coolant Change")}</strong>:{' '}
                    {formatDate(
                      machine.last_coolant_change ? machine.last_coolant_change :
                      machine?.machine_settings?.machine_defaults?.last_coolant_change ? 
                      machine?.machine_settings?.machine_defaults?.last_coolant_change :
                      null 
                    )} 
                  </Typography> 
                  : null }
                  {machine.coolant_name && 
                    <>
                    <Typography variant="body1">
                      <strong>{setLanguageText(language,"Coolant")}</strong>:{' '}
                      {machine.coolant_name} 
                    </Typography> 
                    {machine.machine_settings.machine_defaults?.change_freq && calcOverdueCoolant(machine.last_coolant_change, machine.machine_settings.machine_defaults.change_freq, language)}
                    </>
                  }
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          <Grid item container spacing={1}>
            {selectedCompany.company_attributes &&
              selectedCompany.company_attributes.map(item => {

              if(selectedCompany?.company_settings?.machines_defaults){
                let found = (selectedCompany.company_settings.machines_defaults).find(attr => attr.id === item.one.attr_id)
                if(found && found.isVisible){
                  return (
                    <Grid item container xs={6} key={item.one.attr_id}>
                      <CardCell title={item.one.attr_name}>
                        <CreateChip
                          company_attr_id={item.one.attr_id}
                          entry_attributes={machine.last_entry.attributes_new}
                        />
                      </CardCell>
                    </Grid>
                  );
                 }
              } else {
                return (
                  <Grid item container xs={6} key={item.one.attr_id}>
                    <CardCell title={item.one.attr_name}>
                      <CreateChip
                        company_attr_id={item.one.attr_id}
                        entry_attributes={machine.last_entry.attributes_new}
                      />
                    </CardCell>
                  </Grid>
                )
              }

              
              })}
          </Grid>
          {machine?.last_entry?.additives?.length ? (
            <Grid item container>
                <Alert style={{width: '100%'}} severity="info"><strong>{setLanguageText(language,"Additives")}:</strong> <AdditiveTooltip additives={machine.last_entry.additives} mobile={true} /></Alert>
            </Grid>
          ) : null}
          { machine?.last_entry?.notes ? (
            <Grid item container>
              <Typography variant="h6">{setLanguageText(language,"Notes / Actions")}</Typography><br/>
              {machine.last_entry.note_action === 'C' &&
                <Alert style={{width: '100%'}} severity="error"><strong>{setLanguageText(language,"Details")}:</strong><br/> {machine.last_entry.notes}</Alert>
              }
              {machine.last_entry.note_action === 'N' &&
                <Alert style={{width: '100%'}} severity="success"><strong>{setLanguageText(language,"Details")}:</strong><br/>  {machine.last_entry.notes}</Alert>
              }
              {machine.last_entry.note_action === 'Y' &&
                <Alert style={{width: '100%'}} severity="warning"><strong>{setLanguageText(language,"Details")}:</strong><br/>  {machine.last_entry.notes}</Alert>
              }
            </Grid>
          ) : null}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default MachineCard;
