import React from 'react';
import {
  Grid,
  Typography,
  LinearProgress,
  Divider
} from '@mui/material';
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles(theme => ({
    linearBarContainer: {
      position: 'relative' 
    },
    linearBar: {
      height: '100%',
      padding: '1em',
      borderRadius: theme.spacing(0.5),
      '& .MuiLinearProgress-barColorPrimary': {
        backgroundColor: theme.palette.success.main
      },
      '&.MuiLinearProgress-determinate': {
        backgroundColor: theme.palette.background.light
      }
    },
    machineNumbers: {
      height: '100%',
      padding: '8px',
      position : 'relative',
      backgroundColor: theme.palette.warning.main
    },
    percentage: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      //transform: 'translateY(-50%)'
    }
  }));

const CoolantList = ({
  coolant
}) => {

  //****** STYLES ******
  const linearBarStyle = useStyles();

  const percentage = coolant.total_machines
    ? (coolant.machines_count / coolant.total_machines) * 100
    : 0;

  //****** RENDER ******
  return (
        <Grid
            item
            container
            alignItems="center"
        >
        <Divider light style={{width :'100%', marginBottom : '1em'}} />
        <Grid item xs={2} >
            <Typography >{coolant.coolant_name}</Typography>
        </Grid>
        <Grid item xs={2} >
            <Typography >{coolant.coolant_defaults.coolant.manufacturer}</Typography>
        </Grid>
        <Grid item xs={8} container spacing={2} alignItems="stretch">

          <Grid item xs={10}  className={linearBarStyle.linearBarContainer}>
            <LinearProgress
              value={percentage}
              variant="determinate"
              className={linearBarStyle.linearBar}
            />
            <Typography variant="h6" className={linearBarStyle.percentage}>
              {`${percentage.toFixed(2)}%`}
            </Typography>
          </Grid>
          <Grid item xs={2} >
              <Typography variant="h6" align="center"  className={linearBarStyle.machineNumbers} >
                {coolant.total_machines
                  ? `${coolant.machines_count}/${coolant.total_machines}`
                  : `0/0`}
              </Typography>
    
          </Grid>
        </Grid>
        </Grid>

  );
};

export default CoolantList;
