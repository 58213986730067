import {
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';
import React, { useState, useEffect, useContext } from 'react';
import setLanguageText from '../../helpers/setLanguageText';
import { LayoutContext } from '../../store/LayoutContext';

const CategoriesDropdown = ({
  setResource,
  groupCategories,
  noAll,
  selectedCategory,
  setSelectedCategory
}) => {
  const [categories, setCategories] = useState([]);
  const { language } = useContext(LayoutContext);  

  useEffect(() => {
    if (groupCategories.length) {
      let modify_category;
      if (noAll) {
        modify_category = groupCategories.slice(1);
      } else modify_category = [...groupCategories];
      setCategories(modify_category);
      setSelectedCategory(modify_category[0]);
      if (setResource)
        setResource(prevState => ({
          ...prevState,
          resource_to_send: {
            ...prevState.resource_to_send,
            resource_cat: modify_category[0].resource_cat_id,
            resource_cat_name: modify_category[0].resource_cat_name
          }
        }));
    }
  }, [groupCategories, noAll, setResource, setSelectedCategory]);

  const handleChange = e => {
    //console.log(e.target.value)
    setSelectedCategory(e.target.value);
    if (setResource)
      setResource(prevState => ({
        ...prevState,
        resource_to_send: {
          ...prevState.resource_to_send,
          resource_cat: e.target.value.resource_cat_id,
          resource_cat_name: e.target.value.resource_cat_name
        }
      }));
  };

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel id="demo-simple-select-label">{setLanguageText(language,"Categories")}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedCategory}
       label={setLanguageText(language,"Categories")}
        onChange={handleChange}
        variant="outlined"
      >
        {categories.map(category => {
          return (
            <MenuItem key={category.resource_cat_id} value={category}>
              {category.resource_cat_name}
              {/* {category.sub_categories?.map(
                sub_cat => (
                  // <NestedMenuItem
                  //label={setLanguageText(language,"Button 5")}
                  // parentMenuOpen={category}
                  // value={sub_cat}
                  // onClick={() => console.log(e.target.value)}
                  // >
                  <MenuItem key={category.resource_cat_id} value={category}>
                    {sub_cat.resource_cat_name}
                  </MenuItem>
                )
                // <MenuItem onClick={handleItemClick}>Sub-Button 2</MenuItem>
                // </NestedMenuItem>
              )} */}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default CategoriesDropdown;
