import React, { useContext, useEffect, useState } from 'react';
import PageContent from '../PageContent';
import { Grid, Typography, TextField, ListItem, Switch, List, FormControlLabel, FormControl, Select, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PageHeader from '../PageHeader';
import { CompanyContext } from '../../store/CompanyContext';
import useAsync from '../../Hooks/useAsync';
import axiosRequest from '../../helpers/axiosRequest';
import { UserContext } from '../../store/UserContext';
import { faSave } from '@fortawesome/pro-light-svg-icons';
import LoadingSuccessButton from '../buttons/LoadingSuccessButton';
import OutlinedDiv from '../reusable/OutlinedDiv';
import { Storage } from 'aws-amplify';
import config from '../../config';
import { SAVE_COMPANY_SETTINGS, SELECTED_COMPANY } from '../../store/CompanyReducers';
import setLanguageText from '../../helpers/setLanguageText';
import { LayoutContext } from '../../store/LayoutContext';

const useStyles = makeStyles(theme => ({
  card: {
    width: '100%'
  },
  header: {
    borderBottom: `${theme.spacing(0.4)} solid ${theme.palette.background.light
      }`,
    padding: theme.spacing(1)
  }
}));

const CompanyDefaults = ({ edit }) => {
  //************  STYLES ************

  const classes = useStyles();
  const { authUser, user } = useContext(UserContext);
  const { language } = useContext(LayoutContext);
  const { selectedCompany, dispatchSelectedCompany } = useContext(CompanyContext);
  const [company, setCompany] = useState();
  const [columnOptions, setColumnOptions] = useState();

  const {
    //error,
    data,
    loading,
    //logCompleted,
    //logError,
    executeAsync,
    executeLog,
    setData
  } = useAsync({
    axiosRequest: axiosRequest
  });

  //************ STATES ************

  const handleChange = (e) => {

    console.log(e)

    //console.log(event.target.name)
    //toggle options
    let newOptions = [...columnOptions]
    let index = newOptions.findIndex(obj => obj.id === e.target.name);
    newOptions[index].isVisible = !newOptions[index].isVisible;
    setColumnOptions(newOptions)

  }

  //************  USE EFFECT ************

  useEffect(() => {
    if (Object.values(selectedCompany).length) {
      console.log(selectedCompany.company_settings.machines_defaults);
      let defaultOptions
      if (selectedCompany?.company_settings?.machines_defaults) {
        defaultOptions = selectedCompany?.company_settings?.machines_defaults
      } else {

        let createOptions = [
          { 'id': 'machine_name', 'name': 'Machine Name', 'isVisible': true },
          { 'id': 'machine_group', 'name': 'Machine Group', 'isVisible': true },
          { 'id': 'last_coolant_change', 'name': 'Last Coolant Change', 'isVisible': true },
          { 'id': 'date_stamp', 'name': 'Last Entry', 'isVisible': true },
        ]
        selectedCompany.company_attributes.forEach((item) => {
          createOptions.push({ 'id': item.one.attr_id, 'name': item.one.attr_name, 'isVisible': true })
        });
        createOptions.push(
          { 'id': 'coolant_name', 'name': 'Coolant', 'isVisible': true },
          { 'id': 'notes', 'name': 'Notes', 'isVisible': true },
          { 'id': 'additives', 'name': 'Additives', 'isVisible': true }
        )
        defaultOptions = createOptions
         
      }
      if(selectedCompany?.company_settings?.show){
        setShow(selectedCompany?.company_settings?.show)
      }
      setColumnOptions(defaultOptions)

    }
  }, [selectedCompany]);

  //save columns
  const saveSettings = async (columnOptions, show) => {

    //console.log(selectedCompany.company_settings)
    let newSettings = { ...selectedCompany.company_settings }
    newSettings.machines_defaults = columnOptions
    newSettings.show = show
    delete newSettings.required

    await executeAsync({
      endpoint: 'company/settings',
      api_key: authUser.api_key,
      method: 'patch',
      body: {
        company_id: selectedCompany.company_id,
        company_settings: newSettings
      }
    });
    window.sessionStorage.setItem('show', show);
    
    //TODO = update context
    console.log(newSettings)
    dispatchSelectedCompany({
      type: SAVE_COMPANY_SETTINGS,
      payload: newSettings
    });

  }

  const options = [10, 20, 30, 50, 100];
  const [show, setShow] = useState(window.sessionStorage.getItem('show') ? window.sessionStorage.getItem('show') : 10);

  return (
    <>
      <PageHeader>
        <Grid item xs={12}>
          <Grid
            item
            container
            justifyContent="space-between"
            className={classes.header}
          >
            <Grid item xs={12} sm={'auto'}>
              <Typography variant="h5">{setLanguageText(language,"Company Defaults")}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </PageHeader>
      <PageContent className="ApplicationDetails">
        <Grid
          item
          container
          className={classes.header}
          spacing={2}
        >
          <Grid item xs={12}>
            <Typography variant="h5">{setLanguageText(language,"Table items in view")}</Typography>
            </Grid>
            <Grid item xs={12}>
            <FormControl>
              <Select
                value={show}
                id="select_rows"
                variant="outlined"
                name={setLanguageText(language,"Show")}
                onChange={e => {
                  //setPageSize(Number(e.target.value));
                  setShow(Number(e.target.value));
                }}
              //className={inputStyle.inputRows}
              >
                {options.map(pageSize => (
                  <MenuItem key={pageSize} value={pageSize}>
                    {pageSize}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            </Grid>
          
          <Grid item xs={12}>
            <Typography variant="h5">{setLanguageText(language,"Machines table columns")}</Typography>
          </Grid>
          <Grid item xs={12} >
            <List>
              {columnOptions && columnOptions.map(setting => (
                <ListItem style={{ borderTop: '1px solid #ececec' }} key={setting.id}>
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={handleChange}
                        checked={setting.isVisible ? setting.isVisible : false}
                        name={setting.id}
                        //disabled
                        color="primary"
                      />
                    }
                    name={setting.id}
                    label={setting.name}
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid>
            <LoadingSuccessButton
              icon={faSave}
              text="Save columns"
              iconSize="1x"
              color="primary"
              loading={loading}
              onClick={e => saveSettings(columnOptions, show)}
              data={data}
            />
          </Grid>
        </Grid>
      </PageContent>
    </>
  );
};

export default CompanyDefaults;
