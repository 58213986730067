import React, { useEffect, useState } from 'react';
import GlobalPdfDialog from '../reusable/GlobalPdfDialog';
import { CardContent, Grid, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import LoadingScreen from '../reusable/LoadingScreen';
import ServiceReportPDF from '../pdf/ServiceReportPDF';
import { isMobileOnly } from 'react-device-detect';
import Orientation from '../reusable/Orientation';
import ColumnOptions from '../reusable/ColumnOptions';
import createServicePDFData from '../../helpers/createServicePDFData';
import LoadingButton from '../buttons/LoadingButton';
import { faFilePdf } from '@fortawesome/pro-light-svg-icons';
import { ThemeProvider } from '@mui/system';

const useStyles = makeStyles(theme => ({
  dialogContent: {
    '& .MuiDialogContent-root': {
      overflow: 'hidden'
    },
    '& .MuiDialog-paper': {
      width: '100%',
      height: '100%'
    }
  }
}));

const ServicePdf = ({ 
  servicePdfOpen, 
  closeServicePdf, 
  pdf_data:pdf_data_services , 
  originalData,
  columnOptions:columnOptions_services,
  serviceID
}) => {

  const theme = useTheme();
  const classes = useStyles();
  const [pdf_data, setPdf_data] = useState();
  const [orientation, setOrientation] = useState('portrait');
  const [downloadObject, setDownloadObject] = useState();
  const [showPreview, setShowPreview] = useState(false);
  const [reload, setReload] = useState(false);
  const [columnOptions, setColumnOptions] = useState();

  //Show Reload Button
  useEffect(()=>{
    if(pdf_data_services){
      setPdf_data(pdf_data_services)
      setShowPreview(true)

    }
  },[pdf_data_services])
  
  //Show Reload Button
  useEffect(()=>{
      if(columnOptions_services){
        setColumnOptions(columnOptions_services)
      }
  },[columnOptions_services])

  //************ SERVICE TABLE ************

  //Show Reload Button
  const reloadPDF = () => {
    setReload(false)
    setTimeout(() => {
      setShowPreview(true)
    }, 100)
  };

   /*------------- OPTIONS ---------------*/

   const handleChange = event => {
    //console.log(event.target.name)
    event.persist()
    setShowPreview(false);
    setReload(true);
    //console.log(event.target.name)
    //toggle options
    let newOptions = [...columnOptions] 
    let index = newOptions.findIndex(obj => obj.id === event.target.name);
    newOptions[index].isVisible = !newOptions[index].isVisible;
    setColumnOptions(newOptions)

    //create new visibale columns re create PDF date using them
    let newColumns = newOptions.filter(obj => obj.isVisible === true )
    const updatedData = createServicePDFData({...originalData,'headers':newColumns});
    setPdf_data(updatedData);

  };
  
  return (
    <ThemeProvider theme={theme}>
      <GlobalPdfDialog
        open={servicePdfOpen}
        closeModal={closeServicePdf}
        title={`${pdf_data?.company?.name} - Service Report`}
        maxWidth="xl"
        fullWidth
        className={classes.dialogContent}
        email={true}
        serviceID={serviceID}
        companyName={pdf_data?.company?.name}
        download={downloadObject ? true : false}
        downloadObject={downloadObject}
      >
        <Grid container style={{ height: "100%", overflow: "auto" }} >
          <Grid container item xs={4} md={3} 
          style={{ height: "100%", overflow: "auto", borderRight: '1px solid #ccc', background: '#fbfbfb' }} >
            <CardContent  style={{ width: "100%"}} >
              <Grid container item spacing={2} >
                <Orientation
                  orientation={orientation}
                  setOrientation={setOrientation}
                  setShowPreview={setShowPreview}
                  setReload={setReload}
                />
              </Grid>

              <Grid container item spacing={2} >
               {columnOptions && 
               <ColumnOptions 
                    options={columnOptions} 
                    handleChange={handleChange}
                    title="Select Entry Columns"
                />
               }
              </Grid>
            </CardContent>
          </Grid>

          <Grid item xs={8} md={9} style={{ height: "100%" }}>

            {showPreview && pdf_data ?
              <ServiceReportPDF
                theme={theme}
                orientation={orientation}
                pdf_data={pdf_data}
                downloadPDF={true}
                setDownloadObject={setDownloadObject}
              />

              : reload ?
                <Grid item style={{ height: "100%" }} container alignContent="center" justifyContent="center">
                  <Grid item>
                    <LoadingButton
                      variant="contained"
                      onClick={e => reloadPDF(e)}
                      //className={buttonStyle.button}
                      icon={faFilePdf}
                      text={'Update Preview'}
                      fullWidth={false}
                      iconSize="1x"
                      color="primary"
                    />
                  </Grid>
                </Grid>
                :
                <LoadingScreen
                  loading={false}
                  background="#666"
                  colour="#fff"
                  text="Loading PDF Preview"
                  position="relative"
                />
            }
          </Grid>
        </Grid>
      </GlobalPdfDialog>
    </ThemeProvider>
  );
};

export default ServicePdf;
