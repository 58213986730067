import {
    Grid
} from '@mui/material';
import React, { useContext, useEffect } from 'react';
import axiosRequest from '../../../helpers/axiosRequest';
import { UserContext } from '../../../store/UserContext';
import { MachineContext } from '../../../store/MachineContext';
import Alert from '@mui/material/Alert';
import useAsync from '../../../Hooks/useAsync';
import {
    UPDATE_MACHINE_SETTINGS
} from '../../../store/MachineReducers';
import GlobalDialog from '../../reusable/GlobalDialog';
import { SAVE_SELECTED_COOLANT } from '../../../store/CoolantReducers';
import { CoolantContext } from '../../../store/CoolantContext';

const ConfirmReset = ({
    open,
    handleClose,
    handleConfirmClose,
    resetDate,
    resetMachineSettings,
    currentCoolant,
    coolant,
    selectedMachine,
    selectedDate
}) => {

    //************************  CONTEXT ************************
    const { authUser, user } = useContext(UserContext);
    const { dispatchSelectedMachine } = useContext(MachineContext);
    const { dispatchSelectedCoolant} = useContext(CoolantContext);

    const {
        error,
        data,
        loading,
        logCompleted,
        logError,
        executeAsync,
        executeLog,
        setData
    } = useAsync({
        axiosRequest: axiosRequest
    });

    //************************ USE EFFECTS ************************

    // Handle Axios Request
    useEffect(() => {
        if (data) {
            // TODO handle if activity error
            if (logError) console.log('Something went wrong with the Activity Log');
        }
    }, [data, logCompleted, logError]);

    //************************ FUNCTIONS ************************

    const handleSubmit = async e => {
        e.preventDefault();
        //console.log(coolant);
        //console.log(currentCoolant);

        let response = await executeAsync({
            endpoint: 'machines',
            api_key: authUser.api_key,
            method: 'patch',
            body: {
                edit: 'coolant',
                machine_id: selectedMachine.machine_id,
                coolant: coolant,
                newCoolant: currentCoolant.coolant_id !== coolant.coolant_id,
                resetDate: resetDate,
                selectedDate: selectedDate,
                resetMachineSettings: resetMachineSettings
            }
        });
        if(currentCoolant.coolant_id !== coolant.coolant_id){
            dispatchSelectedCoolant({
                type: SAVE_SELECTED_COOLANT,
                payload: coolant
            });
        }
        if(resetMachineSettings){
            dispatchSelectedMachine({
                type: UPDATE_MACHINE_SETTINGS,
                payload: { 
                coolant_id: coolant.coolant_id ,
                machine_defaults : { 
                    "factor":coolant.coolant_defaults.defaults.factor,
                    "op_con_max":coolant.coolant_defaults.defaults.con_max,
                    "op_con_min":coolant.coolant_defaults.defaults.con_min,
                    "ph_max":coolant.coolant_defaults.defaults.ph_max,
                    "ph_min":coolant.coolant_defaults.defaults.ph_min 
                }}
            });
        }
        if(resetDate){
            dispatchSelectedMachine({
                type: UPDATE_MACHINE_SETTINGS,
                payload: { 
                    last_coolant_change: selectedDate
                }
            });
        }
        if (response) {
            await executeLog({
                endpoint: 'activity',
                api_key: authUser.api_key,
                body: {
                    attributes: {
                        type: currentCoolant ? 'Change Coolant' : 'Update Settings',
                        user_id: user.id,
                        machine_id: selectedMachine.machine_id,
                        new_settings: {
                            "newCoolant": currentCoolant.coolant_id !== coolant.coolant_id,
                            "resetDate": resetDate,
                            "selectedDate" : selectedDate,
                            "resetMachineSettings": resetMachineSettings
                        } 
                    }
                }
            });
            setData();
            handleConfirmClose();
         }

    };

    return (
        <GlobalDialog
            open={open}
            closeModal={handleClose}
            title="Reset/Refresh Coolant"
            handleSubmit={handleSubmit}
            buttonTitle="Yes"
            loading={loading}
            error={error}
            data={data}
            successMessage="Entry Updated successfully!"
            fullWidth
            maxWidth="sm"
        >
            <Grid item container spacing={2}>
                <Grid item>
                    Are you Sure you want to:
                </Grid>
                {currentCoolant.coolant_id !== coolant.coolant_id ?
                    <Grid item xs={12}>
                        <Alert severity="warning">
                            Change the machine coolant to {coolant.coolant_name}
                        </Alert>
                    </Grid>
                    : null}
                {resetDate ?
                    <Grid item xs={12}>
                        <Alert severity="warning">
                            Reset the Coolant Change Date
                    </Alert>
                    </Grid>
                    : null}
                {resetMachineSettings ?
                    <Grid item xs={12}>
                        <Alert severity="warning">
                            Set Machine Values to Coolant Defaults
                    </Alert>
                    </Grid>
                    : null}

            </Grid>
        </GlobalDialog>
    );
};

export default ConfirmReset;
