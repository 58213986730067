import React, { useContext } from 'react';
import {
  Grid,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFilePdf
} from '@fortawesome/pro-light-svg-icons';
import formatDate from '../../helpers/formatDate';
import setLanguageText from '../../helpers/setLanguageText';
import { LayoutContext } from '../../store/LayoutContext';

const ServiceTable_columns = ({ getServiceEntries }) => {

  const { language } = useContext(LayoutContext)

  let columns = [
    {
      id: 'company_name',
      name: 'ID',
      width: 250,
      minWidth: 250,
      Header: () => <Typography variant="h6">{setLanguageText(language, "Company")}</Typography>,
      accessor: 'company_name',
      Cell: ({ row, value }) => {
        return <Typography>{value}</Typography>;
      }
    },
    {
      id: 'date',
      name: 'Date',
      Header: () => <Typography variant="h6">{setLanguageText(language, "Date")}</Typography>,
      accessor: 'service_date',
      Cell: ({ row, value }) => {
        return <Typography>{formatDate(value)}</Typography>;
      }
    },
    {
      id: 'time',
      name: 'Time',
      Header: () => <Typography variant="h6">{setLanguageText(language, "Time")}</Typography>,
      accessor: 'service_date',
      Cell: ({ row, value }) => {
        return <Typography>{formatDate(value, true)}</Typography>;
      }
    },
    {
      id: 'user',
      name: 'User',
      Header: () => <Typography variant="h6">{setLanguageText(language, "Engineer Name")}</Typography>,
      accessor: 'service_name',
      Cell: ({ row, value }) => {
        return <Typography>{value}</Typography>;
      }
    },
    {
      id: 'customer',
      name: 'Customer',
      Header: () => <Typography variant="h6">{setLanguageText(language, "Customer Name")}</Typography>,
      accessor: 'customer_name',
      Cell: ({ row, value }) => {
        return <Typography>{value}</Typography>;
      }
    },
    // {
    //   id: 'start',
    //   name: 'Start',
    //   Header: () => <Typography variant="h6">Start</Typography>,
    //   accessor: 'service_attr.startTime',
    //   Cell: ({ row, value }) => {
    //     return <Typography>{value}</Typography>;
    //   }
    // },
    // {
    //   id: 'finish',
    //   name: 'Finish',
    //   Header: () => <Typography variant="h6">End</Typography>,
    //   accessor: 'service_attr.finishTime',
    //   Cell: ({ row, value }) => {
    //     return <Typography>{value}</Typography>;
    //   }
    // },
    {
      id: 'action',
      name: 'Action',
      width: 350,
      minWidth: 350,
      Header: () => null,
      Cell: ({ row }) => {
        // const handleEmailIcon = async e => {
        //   //console.log(row.state.input_ref);
        //   if (row.state.email_open) {
        //     if (row.state.email) {
        //       // Sent Request
        //       row.setState(prevState => ({ ...prevState, loading: true }));
        //       const response = await row.state.sendEmail({
        //         note_settings: {
        //           service_id: row.original.service_id,
        //           service_user_id: row.original.service_user_id,
        //           email_address: row.state.email
        //         }
        //       });
        //       // Success
        //       if (response) {
        //         row.setState(prevState => ({
        //           ...prevState,
        //           loading: false,
        //           success: true
        //         }));
        //       } else {
        //         // Error
        //         row.setState(prevState => ({
        //           ...prevState,
        //           loading: false,
        //           error: true
        //         }));
        //       }
        //       setTimeout(() => {
        //         row.setState(prevState => ({
        //           ...prevState,
        //           success: false,
        //           error: false,
        //           email: '',
        //           email_open : false
        //         }));
        //       }, 2000);
        //     }
        //   }
        //   // open Email input
        //   return row.setState(prevState => ({
        //     ...prevState,
        //     email_open: true
        //   }));
        // };
        // const closeEmailDialog = e => {
        //   row.setState(prevState => ({
        //     ...prevState,
        //     email_open: false
        //   }));
        // };
        // const handleEmailInput = e => {
        //   e.persist();
        //   row.setState(prevState => ({
        //     ...prevState,
        //     email: e.target.value,
        //     error: false,
        //     error_txt: ''
        //   }));
        // };

        return (
          <Grid container justifyContent="center">
            {/* <Grid item>
            {row.state.email_open && (
              <Grid container>
                <IconButton onClick={closeEmailDialog}>
                  <FontAwesomeIcon icon={faTimesCircle} />
                </IconButton>

                <TextField
                  type="email"
                  value={row.state.email}
                  onChange={handleEmailInput}
                  variant="outlined"
                 label={setLanguageText(language,"Email to")}
                  inputRef={row.state.input_ref}
                  onClick={e =>
                    row.setState(prevState => ({
                      ...prevState,
                      input_ref: e.currentTarget
                    }))
                  }
                />
              </Grid>
            )}
          </Grid> */}
            {/* <Grid item>
            <Tooltip title="Send Email" placement="top">
              <IconButton  onClick={handleEmailIcon}>
                {row.state.loading ? (
                  <LoadingDots color="#666" width={20} height={20} />
                ) : row.state.success ? (
                  <FontAwesomeIcon color={theme.palette.error.main} icon={faThumbsUp} />
                ) : row.state.error ? (
                  <FontAwesomeIcon color={theme.palette.success.main} icon={faThumbsDown} />
                ) : (
                  <FontAwesomeIcon icon={faPaperPlane} />
                )}
              </IconButton>
            </Tooltip>
          </Grid> */}
            {!row.state.email_open && (
              <Grid item>
                <Tooltip title="View Pdf" placement="top">
                  <IconButton
                    onClick={() =>
                      getServiceEntries(row.original)
                    }
                    size="large">
                    <FontAwesomeIcon icon={faFilePdf} />
                  </IconButton>
                </Tooltip>
                {setLanguageText(language, "View PDF")}
              </Grid>
            )}
          </Grid>
        );
      }
    }
  ];
  return columns;
}

export default ServiceTable_columns;
