import React, { useState, useEffect, useContext } from 'react';
import CircularSlider from '@fseehawer/react-circular-slider';
import createAttributeOutputFunction from '../../helpers/createAttributeOutputFunction';
import { Button, Grid, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import useColorChip from './useColorChip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltDown, faArrowAltUp } from '@fortawesome/pro-solid-svg-icons';
import OutlinedDiv from './OutlinedDiv';
import { Alert } from '@mui/material';
import { buttonStyles } from '../../styles/buttonStyles';
import setLanguageText from '../../helpers/setLanguageText';
import { LayoutContext } from '../../store/LayoutContext';

const useStyles = makeStyles(theme => ({
  dial: {
    paddingTop: '1em',
    position: 'relative'
  },
  valuePosition: {
    position: 'absolute',
    width: theme.spacing(9),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 3
  },
  arrow: {
    color: theme.palette.error.main,
    marginRight: '5px'
  }
}));

function CircleDial({
  attr,
  company,
  machine,
  factored,
  factor,
  initialValue,
  dispatchFn,
  min,
  max,
  setError,
  ...props
}) {
  //************  STYLES ************
  const classes = useStyles();
  const buttonStyle = buttonStyles();
  const { language } = useContext(LayoutContext)

  //************  STATE ************
  const [attribute, setAttribute] = useState({
    attribute_id: attr.one.attr_id,
    attribute_values: {
    }
  });
  const [valueHexColor, setStatus] = useColorChip(company.company_colour_rules);
  const [warning, setWarning] = useState(false);
  const [upper, setUpper] = useState();
  const [lower, setLower] = useState();
  const [reset, setReset] = useState(false);
  const [localNumber, setLocalNumber] = useState(0);

  //************  USE EFFECT ************

  //set object if there is an initial value - edit form
  useEffect(() => {
    if (initialValue) {
      setAttribute(prevState => ({
        ...prevState,
        attribute_values: {
          ...prevState.attribute_values,
          value: initialValue
        }
      }));
    }
  }, [initialValue]);

  //update global selection context
  useEffect(() => {
    dispatchFn && dispatchFn(attribute);
  }, [attribute, dispatchFn]);

  //set loacl status
  useEffect(() => {
    if (attribute.attribute_values.status) {
      setStatus(attribute.attribute_values.status);
    }
  }, [attribute.attribute_values.status, setStatus]);

  //set all values based on rule
  useEffect(() => {
    //console.log(attribute.attribute_values.value)
    if (attribute.attribute_values.value !== -2) {
      setAttribute(prevState => ({
        ...prevState,
        attribute_values: {
          ...prevState.attribute_values,
          ...createAttributeOutputFunction(
            attr,
            machine.machine_settings.machine_defaults,
            factored,
            factor
          )(attribute.attribute_values.value)
        }
      }));
    } else {
      //create a not needed object, but include the min and max
      setAttribute(prevState => ({
        ...prevState,
        attribute_values: {
          ...prevState.attribute_values,
          ...createAttributeOutputFunction(
            attr,
            machine.machine_settings.machine_defaults,
            factored,
            factor
          )(attribute.attribute_values.value),
          status: -20,
          value: -2,
          output: '-'
        }
      }));
    }
  }, [
    machine.machine_settings.machine_defaults,
    attr,
    factored,
    factor,
    attribute.attribute_values.value
  ]);

  //set in & out values
  useEffect(() => {
    //only set factored for concentration
    if (factored && attribute.attribute_id === "conc") {
      setLower(attribute.attribute_values.min / factor)
      setUpper(attribute.attribute_values.max / factor)
    } else {
      setLower(attribute.attribute_values.min)
      setUpper(attribute.attribute_values.max)
    }
  }, [
    attribute.attribute_id,
    attribute.attribute_values.value,
    attribute.attribute_values.min,
    attribute.attribute_values.max
  ]);

  //set warnings
  useEffect(() => {
    //console.log(attribute.attribute_values.value)
    //console.log(min)
    //console.log(max)
    if (attribute.attribute_values.value !== -2) {
      if (attribute.attribute_values.value < lower || attribute.attribute_values.value > upper) {
        setError({
          attr_id: attribute.attribute_id,
          type: "warning",
          message: `Warning - ${attr.one.attr_name} is out of control`
        })
        setWarning(true);
      } else {
        setError({
          attr_id: attribute.attribute_id,
          type: "none",
        });
        setWarning(false);
      }
    } else {
      //remove error
      setError({
        attr_id: attribute.attribute_id,
        type: "none",
      });
      setWarning(false);
    }
  }, [
    attribute.attribute_id,
    attribute.attribute_values.value,
    upper,
    lower
  ]);


  //set loacl status
  useEffect(() => {
    if (localNumber) {
      setAttribute(prevState => ({
        ...prevState,
        attribute_values: {
          ...prevState.attribute_values,
          value: localNumber
        }
      }));
    }
  }, [localNumber]);


  //************  FUNCTIONS ************

  const handleSlider = (value) => {
    if (!reset) {
      //console.log(value)
      setError()
      setLocalNumber(value / 10)
    } else {
      setReset(false)
    }
  };

  const handleInput = e => {
    e.persist();
    //console.log(e.target.value)
    setLocalNumber(e.target.value)
  };

  useEffect(() => {
    if (reset) {
      //if you change the value to -2 it will reset the dial
      setAttribute(prevState => ({
        ...prevState,
        attribute_values: {
          value: -2
        }
      }));
      setLocalNumber(0)
    }
  }, [reset])

  //fire reset
  const removeValue = () => {
    setReset(true);
  }

  return (
    <>
      <OutlinedDiv
        label={attr.one.attr_name}
        error={warning}
      >
        <Grid container justifyContent="center" spacing={1}>
          <Grid container direction="column" justifyContent="center">
            <Grid item container justifyContent="center" className={classes.dial}>
              <Grid item container justifyContent="center" alignItems="center" className={classes.valuePosition}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    type="number"
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{ min, max: max / 10 }}
                    value={localNumber}
                    onChange={e => handleInput(e)}
                    variant="outlined"
                    size="small"
                    error={warning}
                  />
                </Grid>
              </Grid>
              <CircularSlider
                {...props}
                min={min}
                max={max}
                knobColor={valueHexColor}
                onChange={(e) => handleSlider(e)}
                dataIndex={attribute.attribute_values.value !== -2 ? attribute.attribute_values.value * 10 : null}
              />
            </Grid>
            <Grid container justifyContent="space-around" style={{ textAlign: "center" }}>
              <Grid item xs={6}>
                <FontAwesomeIcon
                  icon={faArrowAltDown}
                  className={classes.arrow}
                  color="black"
                />
                {lower && lower.toFixed(1)}
                {factored && attribute.attribute_id === "conc" && `(${attribute.attribute_values.min})`}
              </Grid>
              <Grid item xs={6}>
                <FontAwesomeIcon icon={faArrowAltUp} className={classes.arrow} />
                {upper && upper.toFixed(1)}
                {factored && attribute.attribute_id === "conc" && `(${attribute.attribute_values.max})`}
              </Grid>
            </Grid>
          </Grid>
          <Grid item >
            <Button
              fullWidth
              onClick={(e) => removeValue()}
              className={attribute.attribute_values.value === -2 && buttonStyle.danger}
              variant='outlined'
            >
              {setLanguageText(language, "Not Needed")}
            </Button>
          </Grid>
        </Grid>
      </OutlinedDiv>
      {factored && attribute.attribute_id === "conc" &&

        <Alert style={{ marginTop: '5px' }} severity="info" > This machine has a refractomer factor of {factor}</Alert>

      }
    </>
  );
}

export default CircleDial;
