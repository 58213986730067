import React, { Fragment, useContext } from 'react';
import { Typography, Grid, Alert} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import formatDate from '../../../helpers/formatDate';
import calcDaysPast from '../../../helpers/calcDaysPast';
import CreateChip from '../../reusable/CreateChip';
import SelectColumnFilter from '../../reusable/SelectColumnFilter';
import { faEdit } from '@fortawesome/pro-light-svg-icons';
import AdditiveTooltip from '../../reusable/AdditiveTooltip';
import config from '../../../config';
import setLanguageText from '../../../helpers/setLanguageText';

const machinesTable_columns = ({selectedCompany, language})=> {

  let columns = [
    {
      id: 'out_of_control',
      name: 'Out Of Control',
      Header: () => <Typography variant="h6">OOC</Typography>,
      accessor: 'last_entry.out_of_control',
      Cell: ({ row, value }) => (parseInt(value) === 1 ? 'true' : 'false')
    },
    {
      id: 'machine_name',
      name: 'Machine Name',
      //Header: () => <Typography variant="h6">Machine Name</Typography>,
      Header: () => null,
      accessor: props => props.machine_name,
      Filter: (column) => SelectColumnFilter(column, setLanguageText(language,column.column.name),language),
      sticky: true,
      //minWidth: 170,
      Cell: ({ row, value }) => (
        <>
        <FontAwesomeIcon icon={faEdit} style={{
          left: '0px',
          position: 'absolute',
          height: '100%',
          margin: '0 0 0 10px',
          top: 0, 
          cursor : 'pointer'}}/>
        <Typography style={{paddingLeft: '20px', cursor : 'pointer'}} variant="body1">
          <strong>{value}</strong><br/>
          {/* <small>{row.original.machine_group}</small> */}
        </Typography>
        
        </>
      )
    },
    {
      id: 'machine_group',
      name: 'Machine Group',
      Header: () => <Typography variant="h6">{setLanguageText(language,"Machine Group")}</Typography>,
      accessor: props => props.machine_group,
      Cell: ({ row, value }) => <Typography variant="body1">{value}</Typography>
    },
    {
      id: 'last_coolant_change',
      name: 'Last Coolant Change',
      Header: () => <Typography variant="h6">{setLanguageText(language,"Last Coolant Change")}</Typography>,
      accessor: props =>
        props.last_coolant_change ? props.last_coolant_change :
        props?.machine_settings?.machine_defaults?.last_coolant_change ?
        props?.machine_settings?.machine_defaults?.last_coolant_change : null , //legacy coolant change date
      Cell: ({ value }) => {
        return (
          <>
            <Typography variant="body1">
              {value ? formatDate(value) : '-'}
            </Typography>
            <Typography variant="caption">{calcDaysPast(value,language,setLanguageText)}</Typography>
          </>
        );
      }
    },
    {
      id: 'date_stamp',
      name: 'Last Entry',
      Header: () => <Typography variant="h6">{setLanguageText(language,"Last Entry")}</Typography>,
      accessor: 'last_entry.date_stamp',
      Cell: ({ value }) => {
        return (
          <>
          {value ?
          <Fragment>
            <Typography variant="body1">{formatDate(value)}</Typography>
            <Typography variant="caption">{calcDaysPast(value,language,setLanguageText)}</Typography>
          </Fragment>
          : '-'}
          </>
        );
      }
    }
  ];

  /*------------- LOOP THROUGH COMPANY ATTRIBUTES TO CREATE OTHER COLUMNS ---------------*/
  if (Object.entries(selectedCompany).length) {
    //console.log(selectedCompany.company_attributes)
    selectedCompany.company_attributes.forEach(item => {
      // console.log(item);
      columns = [
        ...columns,
        {
          id: item.one.attr_id,
          name: item.one.attr_name,
          Header: <Typography variant="h6">{item.one.attr_name}</Typography>,
          accessor: props => {
            //console.log(props)
            if (props.last_entry.attributes_new) {
              let found = props.last_entry.attributes_new.find(
                attr => attr.attribute_id === item.one.attr_id
              );
              return found && found.attribute_values.value;
            } else {
              return null;
            }
          },
          Cell: ({ row }) => {
            //console.log(row)
            return (
              <CreateChip
                company_attr_id={item.one.attr_id}
                entry_attributes={row.original.last_entry.attributes_new}
                factorApplied={row.original.last_entry?.factor ? true : false}
                entryFactor={row.original.last_entry?.factor}
              />
            );
          }
        }
      ];
    });
  }

  //add status and notes
  columns = [
    ...columns,
    {
      id: 'coolant_name',
      name: 'Coolant',
      Header: () => <Typography variant="h6">{config.APPNAME === 'act' ? 'Oil' : 'Coolant'}</Typography>,
      accessor: 'coolant_name',

    },
    {
      id: 'notes',
      name: 'Notes',
      Header: () => <Typography variant="h6">{setLanguageText(language,"Notes / Actions")}</Typography>,
      accessor: props => props.last_entry.notes,
      Cell: ({ row, value }) => {
        //console.log(row)\
        if(value){
          return (
            <>
            {row.original.last_entry.note_action === 'C' &&
              <Alert style={{width: '100%'}} severity="error"><strong>{setLanguageText(language,"Details")}:</strong><br/> {value}</Alert>
            }
            {row.original.last_entry.note_action === 'N' &&
              <Alert style={{width: '100%'}} severity="success"><strong>{setLanguageText(language,"Details")}:</strong><br/>  {value}</Alert>
            }
            {row.original.last_entry.note_action === 'Y' &&
              <Alert style={{width: '100%'}} severity="warning"><strong>{setLanguageText(language,"Details")}:</strong><br/>  {value}</Alert>
            }
            </>
          )
        } else {
          return '-'
        }
      }
    },
    {
      id: 'additives',
      name: 'Additives',
      Header: () => <Typography variant="h6">{setLanguageText(language,"Additives")}</Typography>,
      accessor: props => props.last_entry.additives,
      Cell: ({ value }) => {
        return (
          <Grid
            container
            alignItems="center"
            justifyContent="center"
          >
   
            {value?.length ? (
              <AdditiveTooltip additives={value} />
            ) : '-'}
          </Grid>
        );
      }
    }
  ];
  return columns;
};

export default machinesTable_columns;
