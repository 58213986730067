import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { UserProvider } from './store/UserContext';
import { CompanyProvider } from './store/CompanyContext';
import { MachineProvider } from './store/MachineContext';
import { AdditiveProvider } from './store/AdditiveContext';
import { CoolantProvider } from './store/CoolantContext';
import { ProductsProvider } from './store/ProductsContext';
import { SelectionsProvider } from './store/SelectionsContext';
import { BrandsProvider } from './store/BrandsContext';
import { ChartsProvider } from './store/ChartsContext';
import { LayoutProvider } from './store/LayoutContext';

//App component
let app = document.getElementById('react-app');

//Get from wordpress
if (app !== null) {
  ReactDOM.render(
    <UserProvider>
      <LayoutProvider>
        <CompanyProvider>
          <MachineProvider>
            <AdditiveProvider>
              <CoolantProvider>
                <ProductsProvider>
                  <SelectionsProvider>
                    <BrandsProvider>
                      <ChartsProvider>
                        <App />
                      </ChartsProvider>
                    </BrandsProvider>
                  </SelectionsProvider>
                </ProductsProvider>
              </CoolantProvider>
            </AdditiveProvider>
          </MachineProvider>
        </CompanyProvider>
      </LayoutProvider>
    </UserProvider>,
    app
  );
}
