
import React, { forwardRef, useState, useEffect, useContext, useMemo } from 'react';
import { Button, DialogTitle, Grid, Slide, Dialog, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Close } from '@mui/icons-material';
import { isMobileOnly } from 'react-device-detect';
import EntryStepper from './EntryStepper';
import checkCustomAttributes from '../../helpers/checkCustomAttributes';
import { SelectionsContext } from '../../store/SelectionsContext';
import useAsync from '../../Hooks/useAsync';
import axiosRequest from '../../helpers/axiosRequest';
import { CLEAN_ENTRY, SAVE_COMPANY, SAVE_MACHINE, SAVE_QRCODES, SET_EDIT_ENTRY } from '../../store/SelectionsReducers';
import LoadingButton from './../buttons/LoadingButton';
import { faArrowRight, faEdit, faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import MobileStepper from '@mui/material/MobileStepper';
import VoidEntry from './VoidEntry';
import Alerts from './Alerts';
import { buttonStyles } from '../../styles/buttonStyles';
import ConfirmEdit from './ConfirmEdit';
import { UPDATE_ENTRY } from '../../store/MachineReducers';
import { UserContext } from '../../store/UserContext';
import { MachineContext } from '../../store/MachineContext';
import { Alert } from '@mui/material';
import setLanguageText from '../../helpers/setLanguageText';
import { LayoutContext } from '../../store/LayoutContext';
import config from '../../config';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiDialogContent-root': {
            padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
            [theme.breakpoints.down('md')]: {
                padding: `${theme.spacing(2)} ${theme.spacing(1)}`
            },
        },
        '& .MuiMobileStepper-root': {
            justifyContent: 'flex-end'
        }

    },
    title: {
        backgroundColor: theme.palette.background.light,
        padding: `${theme.spacing(1)} ${theme.spacing(2)}`
    },
    stepper: {
        backgroundColor: theme.palette.background.light,
        padding: theme.spacing(2),
        '& .MuiStepIcon-completed': {
            color: 'green'
        }
    },
    warnings: {
        padding: theme.spacing(2)
    }
}));

//create steps
function getSteps(language, attributes) {

    let steps = [
        setLanguageText(language,'Standard Values'),
        setLanguageText(language,'Bacteria & Fungi'),
        setLanguageText(language,'Notes'),
        setLanguageText(language,'Additives')
    ];

    if(config.APPNAME === 'rhenus'){
        steps = [
            setLanguageText(language,'Standard Values'),
            setLanguageText(language,'Notes'),
            setLanguageText(language,'Additives')
        ];
    }
    //if custom attributes add in extra step
    const has_custom_attributes = checkCustomAttributes(attributes);
    if (has_custom_attributes) {
        steps.splice(1, 0, `Custom Attributes`);
    }
    return steps;
}

export const EditEntry = ({ isDialog, setIsDialog, originalEntry, selectedCompany, selectedMachine, qrCodes, active, voided }) => {

    //****************** CONTEXT ******************
    const { addNewEntry, dispatchAddNewEntry } = useContext(SelectionsContext);
    const { dispatchSelectedMachine } = useContext(MachineContext);
    const { user, authUser } = useContext(UserContext);
    const { language } = useContext(LayoutContext);

    //****************** STATE ******************
    const [activeStep, setActiveStep] = useState(active ? active : 0);
    const [compiledForm, setCompiledForm] = useState({});
    const [steps, setSteps] = useState([]);
    const [continueNext, setContinueNext] = useState(true);
    const [errors, setErrors] = useState([]);
    const [showWarning, setShowWarning] = useState(false);
    const [showError, setShowError] = useState(false);
    const [voidDialog, setVoidDialog] = useState(false);

    const [confirmDialog, setConfirmDialog] = useState(false);

    //****** STYLES ******
    const classes = useStyles();
    const buttonStyle = buttonStyles();

    const {
        error,
        data,
        loading,
        logCompleted,
        logError,
        executeAsync,
        executeLog
        //setData
    } = useAsync({
        axiosRequest: axiosRequest
    });

    const {
        error: qr_error,
        //data: qr_data,
        loading: qr_loading,
        //logCompleted: qr_logCompleted,
        //logError: qr_logError,
        //executeAsync: qr_executeAsync,
    } = useAsync({
        axiosRequest: axiosRequest
    });

    useEffect(() => {
        if (originalEntry) {
            console.log(originalEntry.original)
            dispatchAddNewEntry({
                type: SET_EDIT_ENTRY,
                payload: originalEntry.original
            });
        }
        if (selectedMachine) {
            dispatchAddNewEntry({
                type: SAVE_MACHINE,
                payload: selectedMachine
            });
        }
        if (selectedCompany) {
            dispatchAddNewEntry({
                type: SAVE_COMPANY,
                payload: selectedCompany
            });
        }
        if (qrCodes) {
            dispatchAddNewEntry({
                type: SAVE_QRCODES,
                payload: qrCodes
            });
        }

    }, [
        qrCodes,
        selectedCompany, 
        selectedMachine, 
        originalEntry,
        dispatchAddNewEntry
    ])


    useEffect(() => {
        if (data) {
            // TODO handle if activity error
            if (logError) console.log('Something went wrong with the Activity Log');
            if (logCompleted || logError) {
                setTimeout(() => {
                    setIsDialog();
                    // Clean addNewEntry context
                    dispatchAddNewEntry({ type: CLEAN_ENTRY });
                }, 1000);
            }
        }
    }, [setIsDialog, data, dispatchAddNewEntry, logCompleted, logError]);

    //build steps
    useEffect(() => {
        if (addNewEntry.company) {
            setSteps(getSteps(language, addNewEntry.company.company_attributes));
        }
    }, [addNewEntry.company]);

    //check for custom attributes
    const has_custom_attributes = useMemo(
        () =>
            addNewEntry.company &&
            checkCustomAttributes(addNewEntry.company.company_attributes),
        [addNewEntry.company]
    );

    const handleNext = () => {
        console.log(continueNext)
        if (!continueNext) {
            setShowWarning(true);
            setContinueNext(true);
        } else {
            //reset errors
            setErrors([]);
            setShowError(false);
            setShowWarning(false);
            setContinueNext(true);
            let canContinue = true;
            //if you are at the end of the form
            if (activeStep === steps.length - 1) {
                //submit and stop form going any further

                handleSubmit();
                canContinue = false;
            }
            if (canContinue) {
                setActiveStep(prevActiveStep => prevActiveStep + 1);
            }

        }
    };

    const handleBack = () => {
        if (activeStep > 0) {
            //reset errors
            setErrors([]);
            setShowError(false);
            setShowWarning(false);
            setContinueNext(true);
            setActiveStep(prevActiveStep => prevActiveStep - 1);
        }
    };

    const closeModal = () => {
        setActiveStep(0);
        setCompiledForm({});
        setIsDialog();
    };

    const openVoidDialog = e => {
        setVoidDialog(true);
    };

    const setError = (error) => {
        if (error) {
            setShowError(false);
            setShowWarning(false);
            setContinueNext(true);
            if (errors.length) {
                let index = errors.findIndex((err) => err.attr_id === error.attr_id)
                if (index !== -1) {
                    errors[index] = error
                } else {
                    errors.push(error)
                }
            } else {
                errors.push(error)
            }
            //console.log(errors);
            setErrors(errors);
            //hide show errrors
            errors.forEach((err) => {
                if (err.type === 'error') {
                    setShowError(true);
                }
                if (err.type === 'warning') {
                    //setShowWarning(true);
                    setContinueNext(false);
                }
            })
        }
    }

    const handleSubmit = async e => {    
        let oldEntry = originalEntry.original.attributes_new;
        let newEntry = addNewEntry.entry.attributes_new;
        // let oldAdditives = originalEntry.original.additives;
        //let newAdditives = addNewEntry.entry.additives;
        // console.log(oldAdditives)
        // console.log(newAdditives)
        //check if attributes have changed
        let bacteria = false
        let editted = false
        let fungi = false
        
        //if none pendning attriubtes have been chnaged
        oldEntry.forEach(item => {
            //console.log(item)
            let found = newEntry.find(newItem => newItem.attribute_id === item.attribute_id)
            //console.log(found)
            if (item.attribute_values.status !== -10) { //-10 is a pending result, so ignore them if they have been updated
                console.log(item)
                if (item.attribute_values.value !== found.attribute_values.value) {
                    editted = true  
                    if(found.attribute_id === 'bacteria'){
                        bacteria = true
                    }
                    if(found.attribute_id === 'fungi'){
                        fungi = true
                    }
                }   
            } else {
                if (item.attribute_values.value !== found.attribute_values.value) {
                    //set if bacteria or fungi have been updated, so we can remove notifications
                    if(found.attribute_id === 'bacteria'){
                        bacteria = true
                    }
                    if(found.attribute_id === 'fungi'){
                        fungi = true
                    }
                }
            }
        });
        //if addititves have been chnaged
        if(addNewEntry.entry.additives !== originalEntry.original.additives) {
            editted = true
        }
        //if notes have been chnaged
        if(addNewEntry.entry.notes !== originalEntry.original.notes) {
            editted = true
        }

        //console.log(editted)
        if (editted) {
            //console.log('edit and void current entry');
            setConfirmDialog(true);
        } else {
            //console.log('update current entry');
            const updatedEntry = {
                attributes_new: newEntry,
                entry_id: originalEntry.original.entry_id,
                machine_id: originalEntry.original.machine_id,
                fungi_updated: fungi,
                bacteria_updated: bacteria
            };
            //console.log(updatedEntry)
            //UPDATE entry
            const response = await executeAsync({
                endpoint: 'machines/entries',
                api_key: authUser.api_key,
                method: 'patch',
                body: updatedEntry
            });
            // LOG ACTIVITY
            if (response) {
                await executeLog({
                    endpoint: 'activity',
                    api_key: authUser.api_key,
                    body: {
                        attributes: {
                            type: 'Updated Pending Values Entry',
                            user_id: user.id,
                            entry_id: updatedEntry.entry_id,
                            machine_id: updatedEntry.machine_id
                        }
                    }
                });
                // Save in context to reflect change
                setTimeout(() => {
                    dispatchSelectedMachine({
                        type: UPDATE_ENTRY,
                        payload: updatedEntry
                    });
                }, 1000);
            }
        }
    };

    const changeStep = (e) => {
        //console.log(e)
        //reset errors
        setErrors([]);
        setShowError(false);
        setShowWarning(false);
        setContinueNext(true);
        setActiveStep(e);
    }

    return (
        <Dialog
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={isDialog}
            onClose={closeModal}
            TransitionComponent={Transition}
            keepMounted
            fullWidth
            maxWidth="md"
            fullScreen={isMobileOnly}
            className={classes.root}
        >
            {!voided ?
    <>
            <DialogTitle className={classes.title}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>{setLanguageText(language,'Edit or Void Entry')}: {selectedCompany.company_name} - {selectedMachine.machine_name} </Grid>
                    <Grid item>
                        <IconButton onClick={closeModal} size="large">
                            <Close />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>

            <EntryStepper
                edit={true}
                steps={steps}
                activeStep={activeStep}
                setActiveStep={changeStep}
                has_custom_attributes={has_custom_attributes}
                compiledForm={compiledForm}
                setError={setError}
                errors={errors}
                continueNext={continueNext}
            />

            <Alerts errors={errors} data={data} showWarning={showWarning} showError={showError} sqlErrors={error || qr_error} />
            <MobileStepper
                variant={isMobileOnly ? 'dots' : null}
                steps={steps.length}
                position="static"
                activeStep={activeStep}
                className={classes.root}
                nextButton={
                    <LoadingButton
                        icon={
                            activeStep === steps.length - 1 ? faEdit :
                                showWarning ? faExclamationTriangle
                                    : faArrowRight
                        }
                        text={
                            activeStep === steps.length - 1 ? setLanguageText(language,'Update Entry') :
                                showWarning ? setLanguageText(language,'Continue Anyway?')
                                    : setLanguageText(language,'Next')
                        }
                        disabled={data? true : false}
                        fullWidth={false}
                        iconSize="1x"
                        color="primary"
                        loading={loading || qr_loading || showError}
                        onClick={handleNext}
                    />

                }
                backButton={
                    <div style={{
                        display: 'flex',
                        flex: 'auto'
                    }}>
                        <Button
                            variant="contained"
                            className={`${buttonStyle.danger}`}
                            value="void"
                            onClick={openVoidDialog}
                            style={{ marginRight: '8px' }}
                        >
                            {setLanguageText(language,'Void')}
                        </Button>

                        {activeStep !== 0 ?
                            <Button onClick={handleBack}>{setLanguageText(language,'Back')}</Button>
                            : null}

                    </div>
                }
            />
            <ConfirmEdit
                open={confirmDialog}
                handleClose={e => setConfirmDialog(false)}
                handleConfirmClose={e => {
                    setConfirmDialog(false);
                }}
                newEntry={addNewEntry}
            />
            <VoidEntry
                open={voidDialog}
                handleClose={e => setVoidDialog(false)}
                handleVoidedClose={e => {
                    setVoidDialog(false);
                }}
                copyEntry={originalEntry}
            />
            </>
        :
            <Alert severity="error">This entry has been editted or voided</Alert>
        }
        </Dialog>
    );
};

export default EditEntry;
