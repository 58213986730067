import setLanguageText from "./setLanguageText";

const addAllOption = (companies,language) => {

    // if fetched Company is empty
    if (!companies.length) {
        return companies;
    }
    // add All company if there is more than 1 company
    //console.log(companies.length)
    if(companies.length > 1){
        companies.unshift({'company_id' : 0, 'company_name' : `${setLanguageText(language,'All Companies')}(${companies.length})`});
    }

    return companies;

};
export default addAllOption;
