import React, { useContext } from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/pro-light-svg-icons';
import setLanguageText from '../../helpers/setLanguageText';
import { LayoutContext } from '../../store/LayoutContext';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex'
    },
    orientation: {
        width: '100%',
        marginBottom: theme.spacing(1),
        '& .MuiToggleButton-root.Mui-selected': {
            backgroundColor: theme.palette.primary.main,
            '& svg' :{
                fill: theme.palette.primary.contrastText,
                color:theme.palette.primary.contrastText,
            }
        }
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    }
}));

const Orientation = ({ orientation, setOrientation, setShowPreview ,setReload }) => {

    const theme = useTheme();
    const classes = useStyles();
    const { language } = useContext(LayoutContext);

    /*------------- UPDATE ORIENTATION OF PDF ---------------*/

    const updateOrientation = (e, value) => {
        console.log(value)
        setShowPreview(false);
        setReload(true);
        setOrientation(value);
    };
    return (
        <Grid item xs={12} className={classes.orientation} >
            <Typography  variant="h6" >
            {setLanguageText(language,"Set Orientation")}
                </Typography>
            <ToggleButtonGroup
                value={orientation}
                exclusive
                onChange={(e,value) => updateOrientation(e,value)}
                aria-label="text alignment"
                style={{ width: '100%', marginTop: '5px' }}
            >
                <ToggleButton
                    value="portrait"
                    aria-label="centered"
                    style={{ width: '100%' }}
                >
                    <FontAwesomeIcon
                        icon={faFile}
                        //color={theme.palette.secondary.contrastText}
                        style={{
                            fontSize: theme.spacing(3.5)
                        }}
                    />
                </ToggleButton>
                <ToggleButton
                    value="landscape"
                    aria-label="centered"
                    style={{ width: '100%' }}
                >
                    <FontAwesomeIcon
                        icon={faFile}
                        rotation={90}
                        //color={theme.palette.primary.contrastText}
                        style={{
                            fontSize: theme.spacing(3.5)
                        }}
                    />
                </ToggleButton>
            </ToggleButtonGroup>
        </Grid>
    );
};

export default Orientation;
