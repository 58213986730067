import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import config from '../../../config';
import { makeStyles } from '@mui/styles';
import { faFilePdf } from '@fortawesome/pro-light-svg-icons';
import PrimaryBtnIcon from '../../buttons/PrimaryBtnIcon';
import PDFviewer from '../../pdf/PDFviewer';
import GlobalPdfDialog from '../../reusable/GlobalPdfDialog';

const useStyles = makeStyles(theme => ({
  dialog: {
    height: 'calc(100% - 64px)'
  }
}));

const CoolantPDF = ({ coolant }) => {
  

  //****** STATES ******
  const [open, setOpen] = useState(false);
  const [path, setPath] = useState();
  const [SDSPath, setSDSPath] = useState();
  const [TDSPath, setTDSPath] = useState();
  const [noSDS, setNoSDS] = useState(true);
  const [noTDS, setNoTDS] = useState(true);

  let language = 'EN';

  //****** STYLES ******
  const classes = useStyles();

  useEffect(() => {
    if (coolant) {
      
      if (
        (!typeof coolant.coolant_defaults === 'object' && coolant.coolant_defaults !== null)
      ) {
        coolant.coolant_defaults = JSON.parse(coolant.coolant_defaults);
      }
      //SDS path
      if (
        coolant.coolant_sds_list &&
        JSON.parse(coolant.coolant_sds_list).includes(language)
      ) {   
        setSDSPath(
          `${coolant.coolant_defaults.pdf_s3?.folder? coolant.coolant_defaults.pdf_s3?.folder:'global_coolants' }/${coolant.coolant_id}_${language}_SDS.pdf`
        );
        setNoSDS(false);
      } else if (coolant.coolant_defaults.pdf?.sds) {
        //legacy sds
        setSDSPath(
          `products/${config.APPNAME}/${coolant.coolant_defaults.pdf?.sds}.pdf`
        );
        setNoSDS(false);
      } else {
        //no sdspdf
        setNoSDS(true);
      }
      // check list of tds languages available
      if (
        coolant.coolant_tds_list &&
        JSON.parse(coolant.coolant_tds_list).includes(language)
      ) {
        setTDSPath(
          `${coolant.coolant_defaults.pdf_s3?.folder? coolant.coolant_defaults.pdf_s3?.folder:'global_coolants'}/${coolant.coolant_id}_${language}_TDS.pdf`
        );
        setNoTDS(false);
      } else if (coolant.coolant_defaults.pdf?.tds) {
        //legacy sds
        setTDSPath(
          `products/${config.APPNAME}/${coolant.coolant_defaults.pdf?.tds}.pdf`
        );
        setNoTDS(false);
      } else {
        //no sdspdf
        setNoTDS(true);
      }
    }
  }, [coolant, language]);

  const handleOpen = type => {
    if (type === 'sds') {
     setPath(SDSPath);
    } else if (type === 'tds') {
      setPath(TDSPath);
    }
    setOpen(true);
  };

  //****** RENDER ******
  return (
    <>
      <Grid container item spacing={1}>
        <Grid item xs={12} sm={6}>
          <PrimaryBtnIcon
            disabled={noSDS ? noSDS : false}
            icon={faFilePdf}
            text="Safety data"
            //iconSize="2x"
            onClick={e => handleOpen('sds')}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <PrimaryBtnIcon
            disabled={noTDS ? noTDS : false}
            icon={faFilePdf}
            text="Technical data"
            //iconSize="2x"
            onClick={e =>  handleOpen('tds')}
          />
        </Grid>

      </Grid>
      <GlobalPdfDialog
        title={coolant.coolant_name}
        open={open}
        closeModal={e => setOpen(false)}
       // classes={{ paper: classes.dialog }}
        fullWidth
        maxWidth="xl"
      >
        {(path && open) ? <PDFviewer path={path} /> : null}
      </GlobalPdfDialog>
    </>
  );
};

export default CoolantPDF;
