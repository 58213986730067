import AWS from 'aws-sdk';


const cognitoPromise = ({ params, fn }) =>
  new Promise((resolve, reject) => {
    
    const cognito_identity_service_provider = new AWS.CognitoIdentityServiceProvider(
      { apiVersion: '2016-04-18' }
    );
    
    cognito_identity_service_provider[fn](params, (err, data) => {
      if (err) reject(err);
      resolve(data);
    });
  });

export default cognitoPromise;
