import {
    Grid
} from '@mui/material';
import React, { useContext, useEffect } from 'react';
import axiosRequest from '../../helpers/axiosRequest';
import { UserContext } from '../../store/UserContext';
import { MachineContext } from '../../store/MachineContext';
import Alert from '@mui/material/Alert';
import useAsync from '../../Hooks/useAsync';
import {
    SAVE_ENTRY,
    VOID_ENTRY
} from '../../store/MachineReducers';
import GlobalDialog from '../reusable/GlobalDialog';

const ConfirmEdit = ({
    open,
    handleClose,
    handleConfirmClose,
    newEntry,
}) => {

    //************************ STYLES ************************

    //************************  CONTEXT ************************
    const { authUser, user } = useContext(UserContext);
    const { dispatchSelectedMachine } = useContext(MachineContext);
    //************************ STATE ************************

    const {
        error,
        data,
        loading,
        logCompleted,
        logError,
        executeAsync,
        executeLog
    } = useAsync({
        axiosRequest: axiosRequest
    });

    //************************ USE EFFECTS ************************

    // Handle Axios Request
    useEffect(() => {
        if (data) {
            // TODO handle if activity error
            if (logError) console.log('Something went wrong with the Activity Log');
            setTimeout(()=>{
                handleConfirmClose()
            },1000)
        }
    }, [data, logCompleted, logError, handleConfirmClose]);

    //************************ FUNCTIONS ************************

    const handleSubmit = async e => {
         e.preventDefault();
          const todayDate = new Date().toISOString();
        //  const newEntry = {
        //      ...copyEntry,
        //      date_stamp: todayDate
        //  };
        //  delete newEntry.entry_id;
        //console.log(newEntry)
        // //* VOID old entry
        const voidedEntry = await executeAsync({
            endpoint: 'machines/entries',
            api_key: authUser.api_key,
            method: 'patch',
            body: {
                toVoid: true,
                date: todayDate,
                reason: 'Edited',
                user_id : user.id,  
                user_name : user.name,
                entry_id: newEntry.entry.entry_id,
                machine_id: newEntry.machine.machine_id
            }
        });
        //* CREATE new Entry
        const addedEntry = await executeAsync({
            api_key: authUser.api_key,
            method: 'post',
            endpoint: 'machines/entries',
            body: newEntry.entry
        });
        //* LOG ACTIVITY
        if (voidedEntry && addedEntry) {
            await executeLog({
                endpoint: 'activity',
                api_key: authUser.api_key,
                body: {
                    attributes: {
                        type: 'Edit Entry',
                        user_id: user.id,
                        void_reason: 'Edited',
                        voided_entry_id: newEntry.entry.entry_id,
                        new_entry_id: addedEntry.insertId,
                        machine_id: newEntry.machine_id
                    }
                }
            });
            // Save in context to reflect change
            dispatchSelectedMachine({
                type: VOID_ENTRY,
                payload: {
                    entry_id: newEntry.entry.entry_id,
                    void: { date: todayDate, reason: 'Edited', user_id : user.id,  user_name : user.name }
                }
            });
            dispatchSelectedMachine({
                type: SAVE_ENTRY,
                payload: { ...newEntry.entry, entry_id: addedEntry.insertId }
            });
        }
    };

    return (
        <GlobalDialog
            open={open}
            closeModal={handleClose}
            title="Update Entry"
            handleSubmit={handleSubmit}
            buttonTitle="Update Entry"
            loading={loading}
            error={error}
            data={data}
            successMessage="Entry Updated successfully!"
            fullWidth
            maxWidth="sm"
        >
            <Grid item>
                <Alert severity="error">
                    When editing entries, the original entry will be voided. Voided entries will not display on graphs/exports. But will still be available to view as edit
                </Alert>
            </Grid>
        </GlobalDialog>
    );
};

export default ConfirmEdit;
