import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import {
  Tabs,
  Tab,
  Card,
  Grid,
  CardContent
} from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import PageContent from '../PageContent';
import Theme from '../Theme/Theme';
import { LayoutContext }  from '../../store/LayoutContext'
import { isMobileOnly } from 'react-device-detect';
import UserDetails from './UserDetails';
import ComingSoon from '../ComingSoon';
import UserNotifications from './UserNotifications';
import setLanguageText from '../../helpers/setLanguageText';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: isMobileOnly ? 'block' : 'flex',
    //height: 224,
  },
  dropdown: {
    width: '100%',
    padding: '10px 10px 10px 0',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    minWidth:  isMobileOnly ? '100%' : '200px',
    overflow : 'visible',
    background: '#fafafa'
  },
  tabs_content: {
    width: '100%',
    minHeight: '600px',
    overflow : 'auto',
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      className={classes.tabs_content}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        //lazy load
        children
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function createProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const Settings = () => {

    const { language } = useContext(LayoutContext);
    let history = useHistory();
    let { tab } = useParams();
    const classes = useStyles();
    const [value, setValue] = useState(0);

    //update current tab from url poarameters
    useEffect(() => {
        //console.log(tab)
        setValue(parseInt(tab));
    },[tab])

    //change URL parameters
    const handleChange = (event, newValue) => {
        history.push({pathname: `/account/${newValue}` })
        setValue(parseInt(newValue));
    };

  return (
    <PageContent>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}>
          <Card>
            <CardContent justify="center" className={classes.cardContent}>
            <div className={classes.root}>
              <Tabs
               allowScrollButtonsMobile
               orientation={isMobileOnly?'horizontal':'vertical'}
               variant="scrollable"
               value={value}
               onChange={handleChange}
               aria-label="Application Settings Menu"
               className={classes.tabs}
              >
                <Tab label={setLanguageText(language,"My Profile")} {...createProps(0)} />
                <Tab label={setLanguageText(language,"My Theme")} {...createProps(1)} />
                <Tab label={setLanguageText(language,"My Notifications")} {...createProps(2)} />
                <Tab label={setLanguageText(language,"My Visibility")} {...createProps(3)} />
              </Tabs>
              <TabPanel value={value} index={0}>
                <UserDetails/>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Theme type="user"/>
              </TabPanel>
              <TabPanel value={value} index={2}>
              <UserNotifications/>
              </TabPanel>
              <TabPanel value={value} index={3}>
                <ComingSoon/>
              </TabPanel>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </PageContent>
  );
}

export default Settings;
