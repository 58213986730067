import React from 'react';
import { TextField } from '@mui/material';
import { forwardRef } from 'react';

const InputComponent = forwardRef((props, ref) => <div style={{margin: '0 auto' , width: '100%'}} ref={ref}>{props.children}</div>);
const OutlinedDiv = ({ children, label , error}) => {
  return (

    <TextField
      variant="outlined"
      fullWidth
      label={label}
      error={error}
      multiline
      InputLabelProps={{ shrink: true }}
      InputProps={{
        inputComponent: InputComponent
      }}
      inputProps={{ children: children }}
    />

  );
};
export default OutlinedDiv;
