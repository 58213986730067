const calcDaysPast = (date, language, setLanguageText) => {
  if (date) {
    const today = new Date();
    const dateFrom = new Date(date);
    const time_difference = today.getTime() - dateFrom.getTime();
    const day_difference = time_difference / (1000 * 60 * 60 * 24);
    const days = parseInt(day_difference);

    return `${days} ${setLanguageText(language,'day(s) ago')}`;
  }
};

export default calcDaysPast;
