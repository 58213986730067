const createPercentage = number => {
    if (number) {
        if (Number.isInteger(number)) {
            return `${number}%`;
        } else {
            return `${number.toFixed(1)}%`;
        }
    }
};
export default createPercentage;
