import React, { useContext, useEffect, useState } from 'react';
import {Grid, TextField } from '@mui/material';
import { Alert, Autocomplete } from '@mui/material';
import axiosRequest from '../../helpers/axiosRequest';
import parseCoolants from '../../helpers/parseCoolants';
import useAsync from '../../Hooks/useAsync';
import { UserContext } from '../../store/UserContext';
import CoolantCard from '../Products/Coolants/CoolantCard';
import GlobalDialog from '../reusable/GlobalDialog';
import LoadingScreen from '../reusable/LoadingScreen';
import setLanguageText from '../../helpers/setLanguageText';
import { LayoutContext } from '../../store/LayoutContext';

const Coolants = ({ isOpen, closeDialog }) => {
  const { authUser } = useContext(UserContext);
  const { language } = useContext(LayoutContext)
  const {
    data: coolants_data,
    loading: coolants_loading,
    executeAsync: get_coolants
  } = useAsync({
    axiosRequest: axiosRequest
  });

  const {
    error: coolant_error,
    data: coolant_data,
    loading: coolant_loading,
    executeAsync: get_single_coolant
  } = useAsync({
    axiosRequest: axiosRequest
  });

  const [selectedCoolant, setSelectedCoolant] = useState(null);

  useEffect(() => {
    if (!coolants_data && isOpen)
      get_coolants({
        api_key: authUser.api_key,
        endpoint: 'coolants',
        method: 'get',
        params: {
          list: true,
          type: 1
        }
      });
  }, [authUser.api_key, coolants_data, get_coolants, isOpen]);

  // useEffect(() => {
  //   if (coolants_data) setSelectedCoolant(coolants_data[0]);
  // }, [coolant_data, coolants_data]);

  const handleSelectCoolant = (e, value) => {
    setSelectedCoolant(value);
    handleSearch(value.coolant_id);
  };

  const handleSearch = (id) => {
    get_single_coolant({
      api_key: authUser.api_key,
      endpoint: 'coolants',
      method: 'get',
      params: {
        coolant_id: id
      }
    });
  };

  return (
    <GlobalDialog
      open={isOpen}
      closeModal={closeDialog}
      title="Coolant Search"
      fullWidth
      maxWidth="sm"
      fullScreen
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Autocomplete
          disableClearable
            id="coolants"
            options={coolants_data ? coolants_data : []}
            loading={coolants_loading}
            getOptionLabel={option => option.coolant_name || ''}
            renderInput={params => (
              <TextField
                {...params}
               label={setLanguageText(language,"Select Coolant")}
                variant="outlined"
              />
            )}
            value={selectedCoolant}
            onChange={handleSelectCoolant}
          />
        </Grid>
        {/* <Grid item xs={12}>
          <Button
            onClick={handleSearch}
            fullWidth
            variant="contained"
            color="primary"
          >
            <FontAwesomeIcon icon={faSearch} />
            Search
          </Button>
        </Grid> */}
        <Grid style={{position:'relative'}} item xs={12}>
        {coolant_loading ? (
          <LoadingScreen 
            loading={!coolant_loading } 
            background="#fff" 
            colour="#666" 
            text="Loading Coolant"
            position="relative"
            height="200px"
            
          />
        ) : coolant_error ? (
          <Alert severity="error">{coolant_error.message}</Alert>
        ) : coolant_data ? (
         
            <CoolantCard
              noEdit
              noMachines
              coolant={parseCoolants(coolant_data[0])}
              noBar
            />
          
        ) : null}
        </Grid>
      </Grid>
    </GlobalDialog>
  );
};

export default Coolants;
