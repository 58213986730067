import {
    Grid , TextField
} from '@mui/material';
import React, { useContext, useMemo, useState } from 'react';
import { SelectionsContext } from '../../store/SelectionsContext';
import { UPDATE_DATE } from '../../store/SelectionsReducers';
import EntryAttributeTypeSelector from '../reusable/Entry_AttributeTypeSelector';
import { isMobileOnly } from 'react-device-detect';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { LayoutContext } from '../../store/LayoutContext';
import setLanguageText from '../../helpers/setLanguageText';


function StandardEntries({setError, edit}) {
    //****************** STYLES******************

    //****************** CONTEXT ******************
    const { addNewEntry, dispatchAddNewEntry } = useContext(SelectionsContext);
    const { language } = useContext(LayoutContext)

    //****************** STATE ******************
    const [attributes, setAttributes] = useState([]);

    //****************** MEMO ******************
    useMemo(() => {
        const filteredAttributes = addNewEntry.company.company_attributes.filter(
            attr =>
                attr.one.attr_id === 'ph' ||
                attr.one.attr_id === 'conc' ||
                attr.one.attr_id === 'tramp_oil'
        );

        setAttributes(filteredAttributes);
    }, [addNewEntry.company.company_attributes]);

    //****************** USE EFFECT ******************


    //****************** FUNCTIONS ******************

    const handleDate = date => {
        const createDate = new Date(date).toISOString();
        dispatchAddNewEntry({ type: UPDATE_DATE, payload: createDate });
    };

    return (
        addNewEntry.entry && (

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <LocalizationProvider 
                        dateAdapter={AdapterDateFns}
                    >
                        {/* <DatePicker
                            mask="__ __ _____"
                            openTo="year"
                            views={['year', 'month', 'day']}
                           label={setLanguageText(language,"Year, month and date")}
                            value={addNewEntry.entry.date_stamp}
                            onChange={handleDate}
                            inputFormat="dd/MM/yyyy"
                            renderInput={(params) => <TextField {...params} helperText={null} />}
                            /> */}
                        <DatePicker
                            
                            size="medium"
                           label={setLanguageText(language,"Date")}
                            fullWidth={isMobileOnly}
                            inputVariant="outlined"
                            //margin="normal"
                            id="date-picker-dialog"
                            inputFormat="dd/MM/yyyy"
                            value={addNewEntry.entry.date_stamp}
                            onChange={handleDate}
                            disableFuture
                            renderInput={(params) => <TextField {...params} />}
                        /> 
                    </LocalizationProvider>
                </Grid>

                {attributes.map(attr => {
                    const attribute = addNewEntry.entry.attributes_new.find(
                        contextAttr => contextAttr.attribute_id === attr.one.attr_id
                    );

                    return (
                        <EntryAttributeTypeSelector
                            key={attr.one.attr_id}
                            attribute={attr}
                            initialValue={attribute?.attribute_values.value}
                            setError={setError}
                            edit={edit}
                        />
                    );
                })}
            </Grid>

        )
    );
}

export default StandardEntries;
