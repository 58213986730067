import React, { useContext } from 'react';
import {
    Button,
    ButtonGroup,
    Grid
} from '@mui/material';
import { SAVE_PRODUCTS, UPDATE_PRODUCT_OPTIONS } from '../../store/ProductsReducers';
import { ProductsContext } from '../../store/ProductsContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortAlphaDown, faSortAlphaDownAlt } from '@fortawesome/pro-light-svg-icons';
import sortCoolants from '../../helpers/sortCoolants';

const ProductSort = () => {

    const { products, productsOptions, dispatchProductsOptions, dispatchProducts } = useContext(ProductsContext);

    //set type from dropdown
    const handleSort = sort => {
        dispatchProductsOptions({
            type: UPDATE_PRODUCT_OPTIONS,
            payload: { 'toggleSort': sort }
        });
        // Sort
        dispatchProducts({
            type: SAVE_PRODUCTS,
            payload: sortCoolants(products, sort)
        })
    };

    return (
        <ButtonGroup fullWidth>
            <Button
                variant="contained"
                color={
                    productsOptions.toggleSort === 'asc' ? 'primary' : 'inherit'
                }
                onClick={() => handleSort('asc')}
            >
                <FontAwesomeIcon icon={faSortAlphaDown} size="lg" />
            </Button>
            <Button
                variant="contained"
                color={
                    productsOptions.toggleSort === 'desc' ? 'primary' : 'inherit'
                }
                onClick={() => handleSort('desc')}
            >
                <FontAwesomeIcon icon={faSortAlphaDownAlt} size="lg" />
            </Button>
        </ButtonGroup>
    );
};

export default ProductSort;
