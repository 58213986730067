import React, { useEffect } from 'react';
import {
    Document,
    Page,
    StyleSheet,
    Text,
    View,
    usePDF
} from '@react-pdf/renderer';
import formatDate from '../../helpers/formatDate';
import ReactPDFFallback from './ReactPDFFallback';
import setLanguageText from '../../helpers/setLanguageText';

const AllMachinesPDF = ({ title, rows, orientation, selectedCompany, selectedGroup, setDownloadObject, language }) => {
    // Create styles
    const BORDER_COLOR = '#bfbfbf';
    const BORDER_STYLE = 'solid';
    const COL_NUMBER = rows[0].length;
    const COLN_WIDTH = 100 / COL_NUMBER;
    const styles = StyleSheet.create({
      body: {
        paddingTop: 10,
        paddingBottom: 10,
        paddingHorizontal: 10,
        height: '100%'
      },
      header: {
        width: '100%',
        textAlign: 'center',
        margin: '0 0 10px 0'
      },
      header_title: {
        fontSize: 14,
        fontWeight: 'bold'
      },
      table: {
        display: 'table',
        width: 'auto',
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderWidth: 1,
        borderRightWidth: 0,
        borderLeftWidth: 0,
        borderBottomWidth: 0
      },
      tableRow: {
        margin: '0 auto',
        flexDirection: 'row',
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderWidth: 1,
        borderTopWidth: 0,
        borderBottomWidth: 0,
        borderRightWidth: 0,
        borderLeftWidth: 1
      },
      tableColHeader: {
        width: COLN_WIDTH + '%',
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderBottomColor: BORDER_COLOR,
        backgroundColor: '#efefef',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
      },
      tableCol: {
        width: COLN_WIDTH + '%',
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        padding: 5
      },
      tableCellHeader: {
        //margin: 5,
        padding: 5,
        fontSize: 8,
        fontWeight: 500
      },
      tableCell: {
        //margin: 5
        padding: 5,
        fontSize: 8,
        //textAlign: 'center'
      },
      tableCellChip: {
        padding: 5,
        fontSize: 8,
        //backgroundColor: '#00d79b',
        borderRadius: 16,
        textAlign: 'center'
      },
      footer: {
        textAlign: 'center',
        height: '5vh',
        position: 'absolute',
        fontSize: 12,
        bottom: 5,
        left: 0,
        right: 0,
      },
      footer_text: {
        fontSize: 8,
        marginBottom: 5,
        fontWeight: 'normal'
      }
    });

    const TableHeaders = headers => {
        console.log(headers)
        return headers.map((header, i) => {
          return (
            <View key={i} style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}> {setLanguageText(language, header)}</Text>
            </View>
          );
        });
      };
    
      const TableRows = rows => {
        const pdf_rows = rows.map((row, i) => {
          console.log(row)
          return (
            <View key={i} style={styles.tableCol}>
              {row.control ?
              <Text
                style={{ ...styles.tableCellChip, backgroundColor: row.control }}
              > 
                {row.value} 
              </Text>
             : <Text style={{ ...styles.tableCell }} >{row.value}</Text>  }
            </View>
          );
        });
        return pdf_rows;
      };

    const MyDoc = (
        <Document title={title}>
        <Page style={styles.body} orientation={`${orientation}`}>
          <View fixed style={styles.header}>
            <Text style={styles.header_title}>{selectedCompany.company_name} / {selectedGroup.group_name}</Text>
          </View>
          <View style={styles.table}>
            <View style={styles.tableRow} fixed>
              {TableHeaders(rows[0])}
            </View>
            {rows.map((row, i) => {
              if (i !== 0) {
                return (
                  <View key={i} style={styles.tableRow} wrap={false}>
                    {TableRows(row)}
                  </View>
                );
              }
              return false;
            })}
          </View>
          <View fixed style={styles.footer}>
            <Text
              style={styles.footer_text}
              render={({ pageNumber, totalPages }) =>
                `${pageNumber} / ${totalPages}`
              }
            />
            <Text style={styles.footer_text}>
            {setLanguageText(language, "Machine Export")} {formatDate(Date.now())}
            </Text>
          </View>
        </Page>
      </Document>
    )

    const [instance, updateInstance] = usePDF({ document: MyDoc });
 
    useEffect(() => {

        if (instance.blob) {
            //console.log(instance)
            setDownloadObject(instance)

        }

    }, [instance,setDownloadObject])

    return (
        <>
            { instance.url ?
                <object
                    data={instance.url}
                    type="application/pdf"
                    height="100%"
                    width="100%"
                >

                    <ReactPDFFallback url={instance.url} />
                </object>
                :
                null
            }
        </>
    )

};

export default AllMachinesPDF;
