import QuickChart from 'quickchart-js';
import createPercentage from '../../helpers/createPercentage';
import setLanguageText from '../../helpers/setLanguageText';
//import { useTheme } from '@mui/styles';

const createHorizontalBar = (results, theme, checked, not_checked,percentage,language) => {
    //console.log(theme)
    const data = {
        datasets: [
              {
                data: [checked],
                backgroundColor: theme.success.main,
                label: [`${createPercentage(percentage)} ${setLanguageText(language,"Checked")}`]
              },{
                data: [not_checked],
                backgroundColor: theme.error.main,
                label:[`${percentage < 100 ? `${createPercentage(100 - percentage)} ${setLanguageText(language,"Not Checked")}` : '' }`]
              }
            ]
      };
    const options = {
        plugins: {
            datalabels: {
                display: true,
                formatter: (value, context) => {  
                    return context.chart.data.datasets[context.datasetIndex].label
                },
                textAlign: 'center',
                color: '#fff',
                backgroundColor: '#404040',
            }
        },
        layout: {
            padding: 10
        },
        legend: { display: false },
        title: {
            display: false
        },
        scales : {
            xAxes : [{stacked:true,min:0,display:false}],
            yAxes : [{stacked:true,display:false}]
        }
    }

    //console.log(data)

    const myChart = new QuickChart();
    myChart
        .setConfig({
            type: 'horizontalBar',
            data: data,
            options: options
        })
        .setWidth(800)
        .setHeight(60)
        .setBackgroundColor('transparent');

    // Print the chart URL
    //console.log(myChart.getUrl()); 

    return myChart.getUrl();
}

export default createHorizontalBar;
