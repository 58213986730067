import axiosRequest from './axiosRequest';

// Check user level and fetch companies accordingly
export const createCompanyAttributes = (api_key, method, body) => {
  return axiosRequest({
    endpoint: `company/attributes`,
    api_key,
    method,
    body
  });
};
