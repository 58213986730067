import React, { useContext, useEffect, useMemo, useState , useCallback} from 'react';
import PageContent from '../PageContent';
import {
  Button,
  Grid,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import AddCompany from './AddCompany';
import { buttonStyles } from '../../styles/buttonStyles';
import CompaniesTable from './CompaniesTable';
import {
  faPlus,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageHeader from '../PageHeader';
import useAsync from '../../Hooks/useAsync';
import { CompanyContext } from '../../store/CompanyContext';
import { LayoutContext } from '../../store/LayoutContext';
import { UserContext } from '../../store/UserContext';
import axiosRequest from '../../helpers/axiosRequest';
import companiesTable_columns from './companiesTable_columns';
import LoadingScreen from './../reusable/LoadingScreen';
import { useHistory } from 'react-router-dom';
import { SELECTED_COMPANY } from '../../store/CompanyReducers';
import setLanguageText from '../../helpers/setLanguageText';

const useStyles = makeStyles(theme => ({
  card: {
    width: '100%'
  },
  header: {
    borderBottom: `${theme.spacing(0.4)} solid ${
      theme.palette.background.light
    }`,
    padding: theme.spacing(1)
  }
}));

const Companies = () => {

  let history = useHistory();

  //************  STYLES ************
  const classes = useStyles();
  const buttonStyle = buttonStyles();
  //************  CONTEXT ************
  const { authUser, user } = useContext(UserContext);
  const { dispatchSelectedCompany } = useContext(CompanyContext);
  const { language } = useContext(LayoutContext);

  //************ STATES ************
  const [companyOpen, setCompanyOpen] = useState(false);
  const { data, loading, executeAsync } = useAsync({
    axiosRequest: axiosRequest
  });
  const { 
    executeAsync : updateSelected 
  } = useAsync({
    axiosRequest: axiosRequest
  });
  //************  USE EFFECT ************
  
  const fecthCompanies = useCallback(() => {
    executeAsync({
      endpoint: 'companies',
      api_key: authUser.api_key,
      method: 'get',
      params : {
        table: true,
        companies_id: user.companies_id.join(),
        user_level: user.level
      }
    });
  },[authUser, user, executeAsync])

  useEffect(() => {
    if (!data) {
      fecthCompanies();
    }
  }, [data, fecthCompanies]);

  //update select company then go to edit view
  const updateSelectCompany = async (e) =>{
    //console.log(e)
    const selected =  await updateSelected({
      endpoint: 'companies',
      api_key: authUser.api_key,
      method: 'get',
      params: {
        id: e.original.company_id,
        user_level: user.level
      }
    });
    dispatchSelectedCompany({
      type: SELECTED_COMPANY,
      payload: selected[0]
    });
    //console.log(selected[0])
    window.sessionStorage.setItem(
      'selectedCompany',
      JSON.stringify(selected[0])
    );
    history.push({ pathname: `/settings/company/0` })
  }

  //************ TABLE ************
  const table_data = useMemo(() => data, [data]);
  const table_columns = useMemo(() => companiesTable_columns(updateSelectCompany, language), []);

  //************ FUNCTIONS ************
  const openCompany = e => {
    setCompanyOpen(true);
  };
  const closeCompany = () => {
    fecthCompanies();
    setCompanyOpen(false)
  };

  return (
    <>
      {user.level === 9 && (
        <PageHeader>
          <Grid item xs={12}>
            <Grid
              item
              container
              justifyContent="space-between"
              className={classes.header}
            >
              <Grid item xs={12} sm={'auto'}>
                <Typography variant="h5">{setLanguageText(language,"All Companies")}</Typography>
              </Grid>
              <Grid item xs={12} sm={'auto'}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={'auto'}>
                    <Button
                      fullWidth
                      onClick={openCompany}
                      variant="contained"
                      color="primary"
                      className={buttonStyle.fixPointerEvent}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                      <Typography className={buttonStyle.buttonIconText}>
                        Add Company
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <AddCompany closeDialog={closeCompany} open={companyOpen} />
          </Grid>
        </PageHeader>
      )}
      <PageContent className="companies">
        <LoadingScreen 
          loading={!loading } 
          background="#fff" 
          colour="#666" 
          text="Loading Companies"
        />
         {table_data && !loading ?
            <CompaniesTable data={table_data} columns={table_columns} />
         : null }
      </PageContent>
    </>
  );
};

export default Companies;
