import parseMachines from '../helpers/parseMachines';
//****************** SelectionsReducer ******************
export const SAVE_GROUPS = 'SAVE_GROUPS';
export const SAVE_MACHINES = 'SAVE_MACHINES';
export const ADD_TO_GROUPS = 'ADD_TO_GROUPS';

export const SelectionsReducer = (state, action) => {
  switch (action.type) {
    case SAVE_GROUPS:
      return action.payload;
    case SAVE_MACHINES:
      return parseMachines(action.payload);
    case ADD_TO_GROUPS:
      return [...state, action.payload];
    default:
      return state;
  }
};

//****************** AddNewMachineReducer ******************
export const SAVE_MACHINE_NAME = 'SAVE_MACHINE_NAME';
export const SAVE_GROUP_NAME = 'SAVE_GROUP_NAME';
export const SAVE_COMPANY = 'SAVE_COMPANY';
export const SAVE_GROUP = 'SAVE_GROUP';

export const AddNewMachineReducer = (state, action) => {
  switch (action.type) {
    case SAVE_MACHINE_NAME:
      return {
        ...state,
        machine_name: action.payload
      };
    case SAVE_GROUP_NAME:
      return {
        ...state,
        machine_group: action.payload
      };
    case SAVE_COMPANY:
      return {
        ...state,
        company: action.payload
      };
    case SAVE_GROUP:
      return {
        ...state,
        group: action.payload
      };
    default:
      break;
  }
};

//****************** AddNewEntryReducer ******************
export const SAVE_MACHINE = 'SAVE_MACHINE';
export const INIT_ENTRY = 'INIT_ENTRY';
export const CLEAN_ENTRY = 'CLEAN_ENTRY';
export const UPDATE_ATTRIBUTE = 'UPDATE_ATTRIBUTE';
export const UPDATE_DATE = 'UPDATE_DATE';
export const ADD_NOTES = 'ADD_NOTES';
export const ADD_NOTE_ACTION = 'ADD_NOTE_ACTION';
export const ADD_ADDITIVE = 'ADD_ADDITIVE';
export const REMOVE_ADDITIVE = 'REMOVE_ADDITIVE';
export const OUT_OF_CONTROL = 'OUT_OF_CONTROL';
export const SAVE_QRCODES = 'SAVE_QRCODES';
export const REMOVE_QRCODES = 'REMOVE_QRCODES';
export const SET_EDIT_ENTRY = 'SET_EDIT_ENTRY';

export const AddNewEntryReducer = (state, action) => {
  switch (action.type) {
    case SAVE_MACHINE:

      let machineObject = {
        ...state,
        //machine: action.payload,
        machine: parseMachines(action.payload), //this was breaking the edit form???????
        qr_codes:[]
      }
      //console.group('save machine')
      //console.log(machineObject);
      return machineObject;

    case SAVE_COMPANY:
      //console.group('company')
      return {
        ...state,
        company: action.payload,
        qr_codes:[]
      };
    case REMOVE_QRCODES:
      //console.log(state.qr_codes)
      const codes = (state.qr_codes).filter((code) => code.type !== action.payload);
      //console.log(codes)
      return {
        ...state,
        qr_codes: codes
      };
    case SAVE_QRCODES:
      //console.group('qr')
      if(Array.isArray(action.payload)) {
        return {
          ...state,
          qr_codes: action.payload
        };
      } else {
        return {
          ...state,
          qr_codes: [
            ...state.qr_codes,
            action.payload
          ]
        };
      }
    case INIT_ENTRY:
      // initialize entry object
      //console.group('initialize')
      const entry = action.payload;
      return {
        ...state,
        entry: {
          ...state.entry,
          entry_id: null,
          date_stamp: entry.date_stamp,
          user_id: entry.user_id,
          machine_id: entry.machine_id,
          coolant_id: entry.coolant_id,
          factored: entry.factored,
          factor: entry.factor,
          attributes_new: entry.attributes_new,
          notes: '',
          note_action: 'N',
          additives: [],
          out_of_control: 0
        },
        qr_codes:[]
      };
    case SET_EDIT_ENTRY:
      let entryObject = { ...state,
        entry:{
          ...action.payload,
          factored : action.payload.factor > 1 ? true : false
        }
      }
      //console.group('save entry')
      //console.log(entryObject);
      return entryObject;

    case CLEAN_ENTRY:
      return { company: null, entry: null, machine: null, qr_codes: [] };
    case UPDATE_ATTRIBUTE:
      if (state.entry.attributes_new.length) {
        const removeAttribute = state.entry.attributes_new.filter(
          attribute => attribute.attribute_id !== action.payload.attribute_id
        );
        return {
          ...state,
          entry: {
            ...state.entry,
            attributes_new: [...removeAttribute, action.payload]
          }
        };
      }
      return {
        ...state,
        entry: {
          ...state.entry,
          attributes_new: [...state.entry.attributes_new, action.payload]
        }
      };
    case UPDATE_DATE:
      return {
        ...state,
        entry: { ...state.entry, date_stamp: action.payload }
      };
      case ADD_NOTE_ACTION:
      return {
        ...state,
        entry: {
          ...state.entry,
          note_action: action.payload,
          
        }
      };
      case ADD_NOTES:
        return {
          ...state,
          entry: {
            ...state.entry,
            notes: action.payload
          }
        };
    case ADD_ADDITIVE:
      return {
        ...state,
        entry: {
          ...state.entry,
          // additives can't be null at the beginning
          additives: state.entry.additives
            ? [...state.entry.additives, action.payload]
            : [action.payload]
        }
      };
    case REMOVE_ADDITIVE:
      const removedAdditive = state.entry.additives.filter(
        addedAdditive =>
          addedAdditive.additive_id !== action.payload.additive_id
      );
      return {
        ...state,
        entry: {
          ...state.entry,
          // additives can't be null at the beginning
          additives: removedAdditive
        }
      };
    case OUT_OF_CONTROL:
      return {
        ...state,
        entry: {
          ...state.entry,
          out_of_control: action.payload
        }
      };

    default:
      break;
  }
};
