import React, { useContext } from 'react';
import { Card, CardActions, CardContent, Divider, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Alert } from '@mui/material';
import LoadingButton from '../buttons/LoadingButton';
import { LayoutContext } from '../../store/LayoutContext';
import setLanguageText from '../../helpers/setLanguageText';



const useStyles = makeStyles(theme => ({
  title: {
    backgroundColor: theme.palette.background.light,
    padding: `${theme.spacing(2)} ${theme.spacing(2)}`
  },
  actions: {
    backgroundColor: theme.palette.background.light
  },
}));


const ReportCard = ({
  children,
  title,
  buttonLabel,
  loading,
  disabled,
  error,
  noData,
  runReport,
  datePicker,
  customAction
}) => {

  const classes = useStyles();
  const { language } = useContext(LayoutContext)
  
  return (
 
      <Card
        style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
      >
        <CardContent className={classes.title}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography variant="h6">{setLanguageText(language,title)}</Typography>
            </Grid>
            <Grid item xs={12} sm={'auto'}>
              {datePicker}
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardContent style={{ flex: 1 }}>{children}</CardContent>
        <Divider />
        <CardActions  className={classes.actions} >
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              {error ? (
                <Alert severity="error">{error.message}</Alert>
              ) : (
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  {noData && (
                    <Grid item>
                      <Alert severity="error">No Data found!</Alert>
                    </Grid>
                  )}
                </Grid>
              )}
            </Grid>
            <Grid item>
              <LoadingButton
                loading={loading}
                text={buttonLabel}
                onClick={runReport}
                disabled={disabled}
                //type="submit"
                />
            </Grid>
            {customAction && <Grid item>{customAction}</Grid>}
          </Grid>
        </CardActions>
      </Card>

  );
};

export default ReportCard;
