
const removeAllOption = companies => {

  // if fetched Company is empty
  if (!companies.length) {
    return companies;
  }
  // remove All company
  const removedAll = companies.filter(company => company.company_id !== 0 );
  //console.log(removedAll)
  return removedAll;

};
export default removeAllOption;
