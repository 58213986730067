import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent,
  Grid,
  TableContainer,
  TableBody,
  TableCell,
  Table,
  TableRow,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext } from 'react';
import PageContent from '../PageContent';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { withStyles } from '@mui/styles';
import PageHeader from '../PageHeader';
import { LayoutContext } from '../../store/LayoutContext';
import setLanguageText from '../../helpers/setLanguageText';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const Diagnostic = () => {

  const classes = useStyles();
  const { language } = useContext(LayoutContext)

  return (
    <>
      <PageHeader>
        <Grid item container alignItems="stretch" spacing={2}>
          <Grid item style={{ alignSelf: 'center' }}>
            <Typography variant="h5">{setLanguageText(language,"Diagnostic")}</Typography>
          </Grid>
        </Grid>
      </PageHeader>
      <PageContent className="reports">
        <Grid item container spacing={2}>
          <Card
            sx={{
              margin: '1em',
              padding: '1em'
            }}
          >
            <CardContent>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>{setLanguageText(language,"Excessive misting")}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Table className={classes.table} aria-label="customized table">
                    <TableBody>
                      <StyledTableRow>
                        <StyledTableCell align="left">{setLanguageText(language,"Concentration")}</StyledTableCell>
                        <StyledTableCell align="left">{setLanguageText(language,"Adjust")}.....</StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell align="left">{setLanguageText(language,"Temperature")}</StyledTableCell>
                        <StyledTableCell align="left">{setLanguageText(language,"Ensure")}....</StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>{setLanguageText(language,"Corrosion")}</Typography>
                </AccordionSummary>
                <AccordionDetails>

                </AccordionDetails>
              </Accordion>


            </CardContent>
          </Card>
        </Grid>
      </PageContent>

    </>
  );

};

export default Diagnostic;
