import React, { useContext, useState, useEffect } from 'react';
import { Grid, TextField, Typography, FormControlLabel, Switch } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { CompanyContext } from '../../store/CompanyContext';
import PageContent from '../PageContent';
import PageHeader from '../PageHeader';
import LoadingButton from './../buttons/LoadingButton';
import { faSave } from '@fortawesome/pro-light-svg-icons';
import axiosRequest from '../../helpers/axiosRequest';
import useAsync from '../../Hooks/useAsync';
import { UserContext } from '../../store/UserContext';
import setLanguageText from '../../helpers/setLanguageText';
import { LayoutContext } from '../../store/LayoutContext';

const useStyles = makeStyles(theme => ({
  card: {
    width: '100%'
  },
  header: {
    borderBottom: `${theme.spacing(0.4)} solid ${theme.palette.background.light
      }`,
    padding: theme.spacing(1)
  }
}));

const DisplaySettings = ({edit}) => {

  //************  CONTEXT ************

  const { selectedCompany } = useContext(CompanyContext);
  const { authUser } = useContext(UserContext);
  const { language } = useContext(LayoutContext)
  const classes = useStyles();
  
  const {
    loading,
    executeAsync
  } = useAsync({
    axiosRequest: axiosRequest
  });

  const defaultOptions = {
    "messages": [{
      "message": "",
      "active": true
    }],
    "delay": 3000
  }
  const [displayOptions, setDisplayOption] = useState(defaultOptions);

  //************  USE EFFECTS ************

  useEffect(() => {
    console.log('update')
    if (selectedCompany.company_display_settings && Object.values(selectedCompany.company_display_settings).length) {
      //console.log(selectedCompany.company_settings)
      setDisplayOption(selectedCompany.company_display_settings);
    } else {
      setDisplayOption(defaultOptions);
    }
  }, [selectedCompany])


  useEffect(() => {
    if (displayOptions) {
      //console.log(displayOptions)
    }
  }, [displayOptions])

  //************  FUNCTIONS ***********

  const updateMessage = (e,i) => {
    e.persist();
    setDisplayOption(current => {
      let newObject = {...current}
      newObject.messages[i].message = e.target.value
      return newObject
    })
  }

  const updateActive = (boolean,i) => {
    //console.log(boolean)
    //console.log(i)
    setDisplayOption(current => {
      let newObject = {...current}
      newObject.messages[i].active = !boolean
      return newObject
    })
  }

  const updateDelay = (e) => {
    e.persist();
    setDisplayOption(current => {
      return ({
        ...current,
        "delay": e.target.value
      })
    })
  }

  const saveDisplay = async (e) => {
    e.preventDefault();
    await executeAsync({
      endpoint: 'company/settings',
      api_key: authUser.api_key,
      method: 'patch',
      body: {
        company_id: selectedCompany.company_id,
        company_display_settings: displayOptions
      }
    });
  }

  return (
    <>
      <PageHeader>
        <Grid item xs={12}>
          <Grid
            item
            container
            justifyContent="space-between"
            className={classes.header}
          >
            <Grid item xs={12} sm={'auto'}>
              <Typography variant="h5">{setLanguageText(language,"Display Settings")}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </PageHeader>
      <PageContent className="notification">
        <Grid item container spacing={2}>

          {displayOptions && displayOptions.messages.map((item,i) => {
            return (
          
              <Grid key={i} item xs={12} >
                <Grid>
                <TextField
                  fullWidth
                  id="display-ticker"
                 label={setLanguageText(language,"Display Ticker")}
                  multiline
                  rows={4}
                  value={item.message}
                  variant="outlined"
                  onChange={e => updateMessage(e,i)}
                  disabled={!edit}
                />
                </Grid>
                <Grid>
                <FormControlLabel
                  control={
                    <Switch
                      checked={item.active}
                      onChange={e => updateActive(item.active,i)}
                      name="checkedB"
                      color="primary"
                      disabled={!edit}
                    />
                  }
                 label={setLanguageText(language,"Show message on dashboard?")}
                />
                </Grid>
              </Grid>
            )

          })}

          <Grid item xs={12} >
            <TextField
              id="display-delay"
             label={setLanguageText(language,"Display Delay")}
              value={displayOptions.delay}
              variant="outlined"
              type="number"
              onChange={e => updateDelay(e)}
              disabled={!edit}
            />
          </Grid>

          <Grid item >
            <LoadingButton
              icon={faSave}
              text="Save Notifications"
              iconSize="1x"
              color="primary"
              loading={loading}
              onClick={e => saveDisplay(e)}
              disabled={!edit}
            />
          </Grid>
        </Grid>
      </PageContent>
    </>

  )
};

export default DisplaySettings;
