import axiosRequest from './axiosRequest';

// Check user level and fetch companies accordingly
export const fetchCoolants = ({api_key, company_id, company_ids,list, type}) => {
  //console.log(company_id)
  if (company_ids) {
    return axiosRequest({
      api_key,
      method: 'get',
      endpoint: 'coolants',
      params: {
        company_ids,
        type
      }
    });
  }
  if (company_id === 0 || company_id ) {
    return axiosRequest({
      api_key,
      method: 'get',
      endpoint: 'coolants',
      params: {
        company_id,
        type
      }
    });
  }
  if (list) {
    return axiosRequest({
      api_key,
      method: 'get',
      endpoint: 'coolants',
      params: {
        list,
        type
      }
    });
  }
  if (type) {
    return axiosRequest({
      api_key,
      method: 'get',
      endpoint: 'coolants',
      params: {
        type
      }
    });
  }

  return axiosRequest({
      api_key,
      method: 'get',
      endpoint: 'coolants'
  });
};
