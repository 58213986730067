import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { buttonStyles } from '../../styles/buttonStyles';
import PageHeader from '../PageHeader';
import { CompanyContext } from '../../store/CompanyContext';
import { useHistory } from 'react-router-dom';
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import axiosRequest from '../../helpers/axiosRequest';
import useAsync from '../../Hooks/useAsync';
import { UserContext } from '../../store/UserContext';
import { LayoutContext } from '../../store/LayoutContext';
import parseCompanies from '../../helpers/parseCompanies';
import PageContent from '../PageContent';
import { SELECTED_COMPANY } from '../../store/CompanyReducers';
import { isMobileOnly } from 'react-device-detect';
import Autocomplete from '@mui/material/Autocomplete';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import setLanguageText from '../../helpers/setLanguageText';

const useStyles = makeStyles(theme => ({
  date_picker: {
    '& .MuiFormControl-marginNormal': {
      margin: 0
    },
    // [theme.breakpoints.up('xs')]: {
    //   paddingRight: theme.spacing(1)
    // },
    [theme.breakpoints.down('xs')]: {
      paddingBottom: theme.spacing(1),
      paddingRight: 0
    }
  }
}));
const ServiceHeader = ({
  handleSearch,
  handleDate,
  dateFrom,
  setSelectedCompany,
  selectedCompany
}) => {
  const classes = useStyles();
  const buttonStyle = buttonStyles();
  let history = useHistory();
  const { companies, dispatchSelectedCompany } = useContext(CompanyContext);
  const { user, authUser } = useContext(UserContext);
  const { language } = useContext(LayoutContext)

  const {
    executeAsync
  } = useAsync({
    axiosRequest: axiosRequest
  });


  const handleSelectCompany = async (e, newValue) => {

    let result = await executeAsync({
      api_key: authUser.api_key,
      method: 'get',
      endpoint: 'companies',
      params: {
        user_level: user.level,
        id: newValue.company_id
      }
    });
    dispatchSelectedCompany({
      type: SELECTED_COMPANY,
      payload: result[0]
    });
    setSelectedCompany(parseCompanies(result[0]));
  };

  /*---- SET TO AND FROM DATES ----*/
  return (
    <>
      <PageHeader>
        <Grid item container alignItems="stretch" justifyContent={!isMobileOnly ? "space-between" : "center"  } spacing={2}>

          <Grid item style={{ alignSelf: 'center' }}>
            <Typography variant="h5">{setLanguageText(language,"Create Service Report")}</Typography>
          </Grid>
          {!isMobileOnly &&
            <Grid item>
              <Button
                fullWidth
                variant="contained"
                style={{ height: '100%' }}
                component="label"
                color="secondary"
                onClick={e => history.push({ pathname: '/reports' })}
              >
                <FontAwesomeIcon icon={faExternalLink} />
                <Typography className={`${buttonStyle.buttonIconText}`}>
                {setLanguageText(language,"View Service Reports")}
                </Typography>
              </Button>
            </Grid>
            }
        </Grid>
      </PageHeader>
      <PageContent>
        <Grid item container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              disableClearable
              id="selected-company"
              value={selectedCompany || {}}
              options={companies || []}
              loading={companies ? false : true}
              getOptionLabel={option => option.company_name || ''}
              isOptionEqualToValue={(option, value) =>
                option.company_id === value.company_id
              }
              onChange={handleSelectCompany}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={setLanguageText(language,"Selected Company")}
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} className={classes.date_picker}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                fullWidth
                inputVariant="outlined"
                margin="normal"
                id="date-picker-dialog"
               label={setLanguageText(language,"Date")}
                inputFormat="dd/MM/yyyy"
                value={dateFrom}
                onChange={handleDate}
                disableFuture
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button
              onClick={handleSearch}
              variant="contained"
              color="primary"
              className={buttonStyle.button}
            >
              <FontAwesomeIcon icon={faSearch} size="lg" />
              <Typography className={`${buttonStyle.buttonIconText}`}>
              {setLanguageText(language,"Search")}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </PageContent>
    </>
  );
};

export default ServiceHeader;
