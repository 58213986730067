import React, { useContext, useCallback, useEffect, useMemo } from 'react';
import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { UserContext } from '../../store/UserContext';
import PageContent from '../PageContent';
import PageHeader from '../PageHeader';
import axiosRequest from '../../helpers/axiosRequest';
import useAsync from '../../Hooks/useAsync';
import NotificationsTable from './NotificationsTable/NotificationsTable';
import NotificationsTable_columns from './NotificationsTable/NotificationsTable_columns';
import { useHistory } from 'react-router-dom';
import LoadingScreen from '../reusable/LoadingScreen';
import setLanguageText from '../../helpers/setLanguageText';
import { LayoutContext } from '../../store/LayoutContext';

const useStyles = makeStyles(theme => ({
  card: {
    width: '100%'
  },
  header: {
    borderBottom: `${theme.spacing(0.4)} solid ${theme.palette.background.light
      }`,
    padding: theme.spacing(1)
  }
}));

const UserNotifications = () => {

  let history = useHistory();
  const classes = useStyles();
  const { authUser, user } = useContext(UserContext);
  const { language } = useContext(LayoutContext)

  const {
    data,
    loading,
    executeAsync
  } = useAsync({
    axiosRequest: axiosRequest
  });

  //fetch react table data from table state in new order
  const getNotification = useCallback(async (id) => {
    if (id) {
      await executeAsync({
        endpoint: 'notifications',
        api_key: authUser.api_key,
        method: 'get',
        params: {
          'user_id': id
        }
      });
    }
  }, []);

  /*------------- CREATE DATA FOR TABLE ---------------*/

  useEffect(() => {
    if (user.id) {
      getNotification(user.id)
    }
  }, [user])

  /*------------- CREATE COLUMNS FOR TABLE ---------------*/

  //go to machine to change coolant
  const goToMachine = async (id) => {
    history.push({ pathname: `/machine/${id}` })
  }

  const goToEntry = async (row) => {
    let entry_id = JSON.parse(row.note_settings).entry_id
    history.push({ pathname: `/machine/${row.machine_id}/entry/${entry_id}`})
  }

  const columns = useMemo(
    () => NotificationsTable_columns(user, goToMachine, goToEntry),
    [user, goToMachine]
  );

  return (
    <>
      <PageHeader>
        <Grid item xs={12}>
          <Grid
            item
            container
            justifyContent="space-between"
            className={classes.header}
          >
            <Grid item xs={12} sm={'auto'}>
              <Typography variant="h5">{setLanguageText(language,"My Notifications")}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </PageHeader>
      <PageContent className="notification">
        <Grid item container spacing={2}>

          {data ?
            <NotificationsTable
              columns={columns}
              data={data}
            //getTableDataFn={getTableDataFn}
            //  headerSettings={
            //    ['out_of_control', 'notes']
            //    // pass in header hide/show from company/user settings
            //  }
            />
       :
       <LoadingScreen
            loading={!loading}
            background="#fff"
            colour="#666"
            text="Loading My Notifications"
          />
        }
        </Grid>
      </PageContent>
    </>

  )
};

export default UserNotifications;
