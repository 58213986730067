import { useTheme } from '@mui/material';
import { useCallback, useContext, useMemo, useRef, useState } from 'react';
import formatDate from '../../helpers/formatDate';
import { ChartsContext } from '../../store/ChartsContext';
import { SAVE_CHARTS } from '../../store/ChartsReducers';

const useTrampChart = ({ plots, tooltips }) => {
  const [labels, setLabels] = useState();
  const [tramp, setTramp] = useState();
  const [trampMax, setTrampMax] = useState();
  const [trampMedium, setTrampMedium] = useState();
  const colour = useTheme().palette.error.main;
  const { dispatchCharts } = useContext(ChartsContext);
  const chartRef = useRef(null);
  const [base64Image, setBase64Image] = useState(null);

  const createTrampChart = useCallback(entries => {
    let labelsObject = [];
    let trampObject = [];
    let trampMaxLineObject = [];
    let trampMediumLineObject = [];

    entries.forEach(entry => {
      labelsObject.push(formatDate(entry.original.date_stamp).slice(0, -5));
      const details = entry.original.attributes_new.find(
        attr => attr.attribute_id === 'tramp_oil'
      );
      if (details) {
        //remove pending and N/A results
        if (
          details.attribute_values.status === -10 ||
          details.attribute_values.status === -20
        ) {
          trampObject.push(null);
        } else {
          trampObject.push(details.attribute_values.value);
        }
        trampMaxLineObject.push(2.5);
        trampMediumLineObject.push(1.5);
      }
    });

    setTramp(trampObject);
    setLabels(labelsObject);
    setTrampMax(trampMaxLineObject);
    setTrampMedium(trampMediumLineObject);
  }, []);

  const data = useMemo(() => {
    return {
      xLabels: labels,
      datasets: [
        {
          data: tramp,
          label: 'T/Oil',
          fill: false,
          pointRadius: plots ? 4 : 0,
          lineTension: 0.3,
          pointBackgroundColor: colour,
          pointHoverRadius: 6,
          borderColor: colour,
          borderWidth: 2,
          spanGaps: true,
          datalabels: tooltips
            ? {
                align: 'center',
                anchor: 'center'
              }
            : null
        },
        {
          data: trampMedium,
          label: 'Low',
          backgroundColor: 'rgb(0, 215, 155, 0.100)',
          borderColor: 'transparent',
          fill: 'origin',
          lineTension: 0,
          pointRadius: 0
        },
        {
          data: trampMedium,
          label: 'Medium',
          backgroundColor: 'rgb(255, 197,0, 0.100)',
          borderColor: 'transparent',
          fill: 3,
          lineTension: 0,
          pointRadius: 0
        },
        {
          data: trampMax,
          label: 'MAX',
          backgroundColor: 'rgba(252, 70, 112, 0.100)',
          borderColor: 'transparent',
          fill: 'end',
          lineTension: 0,
          pointRadius: 0
        }
      ]
    };
  }, [labels, tramp, plots, colour, trampMedium, trampMax, tooltips]);

  const options = useMemo(() => {
    return {
      elements: {
        point: {
          radius: 4,
          pointHitRadius: 0,
          hoverRadius: 0,
          pointHoverBorderWidth: 0,
          pointHoverRadius: 0
        }
      },
      plugins: {
        datalabels: {
          backgroundColor: colour,
          borderRadius: 4,
          color: 'white',
          font: {
            weight: 'bold'
          },
          display: context => {
            return context.dataset.datalabels ? true : false;
          },
          padding: 4
        }
      },
      title: {
        display: false,
        text: 'Tramp oil levels',
        fontSize: 20
      },
      bezierCurve: false,
      legend: { display: false },
      scales: {
        yAxes: [
          {
            display: true,
            ticks: {
              stepSize: 1,
              suggestedMax: trampMax && trampMax[0] + 1,
              callback: function (value) {
                //console.log(value)
                if (value < 0) {
                  return 'N/A';
                } else if (value === 0) {
                  return 'Clear';
                } else if (value === 1) {
                  return 'Low';
                } else if (value === 2) {
                  return 'Medium';
                } else if (value === 3) {
                  return 'High';
                }
              }
            }
          }
        ]
      },
      animation: {
        onComplete: () => {      
          let b64 = chartRef.current.chartInstance.toBase64Image();
          setBase64Image(b64);
          dispatchCharts({
            type: SAVE_CHARTS,
            payload: {
              tramp_base64: b64
            }
          });
        }
      }
    };
  }, [colour, dispatchCharts, trampMax]);

  return {
    createTrampChart,
    trampData: data,
    trampOptions: options,
    trampBase64: base64Image,
    trampChartRef: chartRef
  };
};

export default useTrampChart;
