import { capitalize } from 'lodash';

const splitString = string => {
  const multi_string = string.split('_');
  const capitalizeWords = multi_string.map(word => capitalize(word));
  const joinedString = capitalizeWords.join(' ');
  return joinedString;
};

export default splitString;
