import React, { useContext, useEffect, useState } from 'react';
import { fetchMachineCoolant } from '../../../helpers/fetchMachineCoolant';
import parseCoolants from '../../../helpers/parseCoolants';
import { UserContext } from '../../../store/UserContext';
import CoolantDefaults from './CoolantDefaults';
import { SAVE_SELECTED_COOLANT } from '../../../store/CoolantReducers';
import { CoolantContext } from '../../../store/CoolantContext';
import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Grid,
  Paper
} from '@mui/material';
import { buttonStyles } from '../../../styles/buttonStyles';
import RefreshCoolant from './RefreshCoolant';
import Frequency from './Frequency';
import { isMobileOnly } from 'react-device-detect';
import formatDate from '../../../helpers/formatDate';
import setLanguageText from '../../../helpers/setLanguageText';
import { LayoutContext } from '../../../store/LayoutContext';

const CoolantSettings = ({ selectedMachine }) => {

  const { authUser, user } = useContext(UserContext);
  const { language } = useContext(LayoutContext);

  const [expanded, setExpanded] = useState(false);

  //****** STYLES ******
  const buttonStyle = buttonStyles();

  const handleExpand = () => {
    setExpanded(state => setExpanded(!state));
  };

  const { dispatchSelectedCoolant, selectedCoolant } = useContext(
    CoolantContext
  );

  useEffect(() => {
    //console.log(selectedMachine)
    if (selectedMachine) {
      if (selectedMachine.coolant_id) {
        fetchMachineCoolant(authUser.api_key, selectedMachine.coolant_id).then(
          data => {
            if (data) {
              dispatchSelectedCoolant({
                type: SAVE_SELECTED_COOLANT,
                payload: parseCoolants(data)[0]
              });
            } else {
              dispatchSelectedCoolant({
                type: SAVE_SELECTED_COOLANT,
                payload: []
              });
            }
          }
        );
        //setCoolant(parseCoolants(data)[0]));
      } else {
        //no coolant set
        dispatchSelectedCoolant({
          type: SAVE_SELECTED_COOLANT,
          payload: null
        });
        //setExpanded(true);
      }
    }
  }, [dispatchSelectedCoolant, selectedMachine, authUser.api_key]);

  return (
    <Accordion expanded={expanded} onChange={handleExpand}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="machine-details"
        id="machine-details"
      >
        {' '}
        <Typography variant="h6">
        {setLanguageText(language,"Coolant")}{!isMobileOnly && ' Details'}:{' '}
          <span style={{ color: '#888' }}>{selectedCoolant?.coolant_name}</span>
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        style={{
          borderTop: '1px solid #ececec',
          padding: 0
        }}
      >
        <Grid container >
          {selectedCoolant &&
            <>
              <Grid container item style={{
                margin: '1em 1em -.5em 1em'
              }} spacing={1} >
                <Paper className={`${buttonStyle.box}`} variant="outlined">
                  <Typography variant="body1" align="center">
                    <strong>{setLanguageText(language,"Last Coolant Change")}: </strong>
                    {formatDate(selectedMachine.last_coolant_change)}
                  </Typography>
                </Paper>
              </Grid>

              {selectedMachine?.machine_settings?.admin ?
                <Grid container item style={{
                  margin: '1em 1em -.5em 1em'
                }} spacing={1} >
                  <Grid item xs={12} >
                    <Paper className={`${buttonStyle.box}`} variant="outlined">
                      <Typography variant="body1" align="center">
                        <strong>{setLanguageText(language,"Operator")}: </strong>
                        {selectedMachine?.machine_settings?.admin?.operator_email?.name} - {selectedMachine?.machine_settings?.admin?.operator_email?.email}
                        {/* {`PH Max: ${defaults.ph_max}`} */}
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} >
                    <Paper className={`${buttonStyle.box}`} variant="outlined">
                      <Typography variant="body1" align="center">
                        <strong>{setLanguageText(language,"Operators Company")}: </strong>
                        {selectedMachine?.machine_settings?.admin?.operator_email?.company_name}
                        {/* {`PH Max: ${defaults.ph_max}`} */}
                      </Typography>
                    </Paper>
                  </Grid>
                </Grid>
                : null}

              <Grid
                item
                container
                spacing={1}
                style={{
                  margin: '1em 1em 0 1em'
                }}
              >
                {user.level >= 7 &&
                <>
                  <RefreshCoolant selectedCoolant={selectedCoolant} selectedMachine={selectedMachine} />
                  <Frequency edit={selectedMachine?.machine_settings?.admin ? true : false} />
                </>
                }
              </Grid>
              <Grid item xs={12}>
                {selectedCoolant && <CoolantDefaults coolant={selectedCoolant} />}
              </Grid>
            </>
          }
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default CoolantSettings;
