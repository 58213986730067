import React, { useState, useEffect, useContext } from 'react';
import { Storage } from 'aws-amplify';
import axiosRequest from '../../../helpers/axiosRequest';
import { UserContext } from '../../../store/UserContext';
import CoolantForm from './CoolantForm';
import useAsync from '../../../Hooks/useAsync';
import GlobalDialog from '../../reusable/GlobalDialog';
import { CoolantContext } from '../../../store/CoolantContext';
import { UPDATE_COOLANT } from '../../../store/CoolantReducers';

const EditCoolant = ({ isEditDialog, closeEditDialog, coolant }) => {
  const {
    data,
    loading,
    error,
    logCompleted,
    logError,
    executeAsync,
    executeLog,
    setData
  } = useAsync({
    axiosRequest: axiosRequest
  });

  //****** CONTEXT ******
  const { authUser, user } = useContext(UserContext);
  const { dispatchCoolants, dispatchCoolantsCompare, coolantsCompare } = useContext(CoolantContext);

  //****** STATE ******
  const [attributes, setAttributes] = useState(coolant);

  const [SDS, setSDS] = useState();
  const [TDS, setTDS] = useState();

  //****** USE EFFECTS ******
  useEffect(() => {
    // TODO handle if activity error
    if (logError) console.log('Something went wrong with the Activity Log');
    if (logCompleted || logError) {
      // completed or error
      setData();
    }

  }, [
    data, 
    setData,
    logCompleted, 
    logError
  ]);

  //****** FUNCTIONS ******
  const logPDF_upload = async ({ path, type }) => {
    const response = await axiosRequest({
      endpoint: 'pdf',
      api_key: authUser.api_key,
      body: {
        name: path,
        user_details: {
          user: user.name,
          user_id: user.id
        },
        pdf_type: type
      }
    });
    return response;
  };

  //Edit coolant
  const handleSubmit = async e => {
    e.preventDefault();
    let pdf_uploads = [];

    const coolantObj = {
      coolant_id: attributes.coolant_id,
      coolant_name: attributes.coolant_name,
      coolant_company: attributes.coolant_company,
      brand_id: attributes.brand_id,
      language: attributes.language,
      tds_uploaded: attributes.tds_uploaded,
      sds_uploaded: attributes.sds_uploaded,
      coolant_defaults: {
        pdf_s3: {
          folder: attributes.pdf_s3.folder
        },
        coolant: {
          type: attributes.description,
          manufacturer: attributes.manufacturer,
          selling_points: attributes.selling_points,
          variants: attributes.variants
        },
        defaults: attributes.defaults
      }
    };

    await executeAsync({
      endpoint: 'coolants',
      api_key: authUser.api_key,
      method: 'patch',
      body: coolantObj
    });

    // insertId will be 0 if coolant already exists
    if (SDS) {
      const sds_path = `${attributes.pdf_s3.folder}/${attributes.coolant_id}_${attributes.language}_SDS.pdf`;

      try {
        await Storage.put(sds_path, SDS, {
          contentType: 'application/pdf'
        });

        const sds_pdf = await logPDF_upload({
          path: sds_path,
          type: 'Coolant SDS'
        });

        pdf_uploads = [
          ...pdf_uploads,
          {
            type: 'sds',
            path: sds_path,
            upload_id: sds_pdf.insertId
          }
        ];
      } catch (err) {
        console.log(err);
      }
    }

    if (TDS) {
      const tds_path = `${attributes.pdf_s3.folder}/${attributes.coolant_id}_${attributes.language}_TDS.pdf`;
      try {
        await Storage.put(tds_path, TDS, {
          contentType: 'application/pdf'
        });
        const tds_pdf = await logPDF_upload({
          path: tds_path,
          type: 'Coolant TDS'
        });
        pdf_uploads = [
          ...pdf_uploads,
          {
            type: 'tds',
            path: tds_path,
            upload_id: tds_pdf.insertId
          }
        ];
      } catch (err) {
        console.log(err);
      }
    }

    await executeLog({
      endpoint: 'activity',
      api_key: authUser.api_key,
      body: {
        attributes: {
          type: 'Edit Coolant',
          user_id: user.id,
          coolant_id: attributes.coolant_id,
          pdf_uploads
        }
      }
    });
    
    // add language to object so the PDFs works / needs looking at
    coolantObj.coolant_sds_list = attributes.coolant_sds_list
    coolantObj.coolant_tds_list =  attributes.coolant_sds_list

    //bug need to update compare if active
    
    if(coolantsCompare && Object.values(coolantsCompare).length){
       dispatchCoolantsCompare({ type: UPDATE_COOLANT, payload: coolantObj });
    } else {
        dispatchCoolants({ type: UPDATE_COOLANT, payload: coolantObj });
    }
    closeEditDialog();
  };

  const updateCoolant = attributes => {
    setAttributes(attributes);
  };

  //****** RENDER ******
  return (
    <GlobalDialog
      closeModal={closeEditDialog}
      title="Edit Coolant"
      handleSubmit={handleSubmit}
      buttonTitle="Update Coolant"
      loading={loading}
      error={error}
      data={data}
      successMessage="Coolant edited successfully!"
      fullWidth
      maxWidth="md"
      open={isEditDialog}
    >
        <CoolantForm
          coolant={coolant}
          updateCoolant={updateCoolant}
          setSDS={setSDS}
          setTDS={setTDS}
        />
    </GlobalDialog>
  );
};

export default EditCoolant;
