import React, {
  useContext,
  useState,
  useMemo,
  useCallback,
  useEffect
} from 'react';
import { Grid } from '@mui/material';
import MachineHeader from './Headers/MachineHeader';
import GridView from './GridView/GridView';
import PageHeader from '../PageHeader';
import PageContent from '../PageContent';
import MachineTable from './MachinesTable/MachinesTable';
import { MachineContext } from '../../store/MachineContext';
import { CompanyContext } from '../../store/CompanyContext';
import machinesTable_columns from './MachinesTable/machinesTable_columns';
import LoadingScreen from '../reusable/LoadingScreen';
import { useTheme } from '@mui/styles';
import { isMobileOnly } from 'react-device-detect';
import { LayoutContext } from '../../store/LayoutContext';

function Machines() {

  const theme = useTheme();
  const { selectedCompany } = useContext(CompanyContext);
  const { machines } = useContext(MachineContext);
  const { language } = useContext(LayoutContext);
  const [filteredData, setFilteredData] = useState();
  const [visibleMachines, setVisibleMachines] = useState();
  const [loading, setLoading] = useState(false);
  const [filteredColumns, setFilteredColumns] = useState();
  const { gridView, filteredMachines, outOfControl, isOutOfControl } =
    useContext(MachineContext);

  /*------------- TOGGLE MACHINES TO SHOW OOC MACHINES ONLY ---------------*/

  // const data = useMemo(
  //   () => (isOutOfControl ? outOfControl : filteredMachines),
  //   [filteredMachines, isOutOfControl, outOfControl]
  // );

  useEffect(() => {
    if (filteredMachines || isOutOfControl) {
      //console.log(filteredMachines) 
      setVisibleMachines(isOutOfControl ? outOfControl : filteredMachines);
      setLoading(false);
    }
  }, [filteredMachines, isOutOfControl, outOfControl])


  /*------------- CREATE COLUMNS FOR TABLE ---------------*/
  const columns = useMemo(
    () => machinesTable_columns({selectedCompany:selectedCompany, language:language}),
    [selectedCompany,language]
  );

  //fetch react table data from table state in new order
  const getTableDataFn = useCallback(tableData => {
    if (tableData[0]) {
      //filter to data only
      let data = tableData.map(item => item.original);
      let columns = tableData[0].allCells.map(item => item.column);
      setFilteredColumns(columns);
      setFilteredData(data);
    }
  }, []);

  return (
    <Grid item container xs={12}>
      <PageHeader>
        <MachineHeader
          columns={filteredColumns}
          data={filteredData}
          setLoading={setLoading}
        />
      </PageHeader>
      <PageContent>
        {loading ? (
          <LoadingScreen
            loading={loading}
            background={theme.palette.background.lighter}
            colour={theme.palette.text.secondary}
            text="Fetching Machines"
          />
        ) : !machines.length ? (
          <LoadingScreen
            loading={loading}
            background={theme.palette.background.lighter}
            colour={theme.palette.text.secondary}
            text="Company has no machines, please add a machine"
          />
        ) : gridView || isMobileOnly ? (
          <GridView 
            columns={columns} 
            defaultHiddenColumns={selectedCompany?.company_settings?.machines_defaults}
          />
        ) : (
                <>
                  {visibleMachines &&
                    <MachineTable
                      columns={columns}
                      data={visibleMachines}
                      getTableDataFn={getTableDataFn}
                      defaultHiddenColumns={selectedCompany?.company_settings?.machines_defaults}
                      headerSettings={
                        ['out_of_control']
                        // pass in header hide/show from company/user settings
                      }
                    />
                  }
                </>
              )}
      </PageContent>
    </Grid>
  );
}

export default Machines;
