import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { usePagination, useRowSelect, useSortBy, useTable } from 'react-table';
import GlobalDialog from '../reusable/GlobalDialog';
import useAsync from '../../Hooks/useAsync';
import axiosRequest from '../../helpers/axiosRequest';
import { UserContext } from '../../store/UserContext';
import ServiceDialog from './ServiceDialog';
import SignatureDialog from './SignatureDialog';
import ServiceEntriesTable from './ServiceEntriesTable';
import { buttonStyles } from '../../styles/buttonStyles';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import setLanguageText from '../../helpers/setLanguageText';
import { LayoutContext } from '../../store/LayoutContext';


const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return <Checkbox ref={resolvedRef} {...rest} />;
});

const ServiceTable = ({
  data,
  columns,
  dialogOpen,
  setDialogOpen,
  handleSearch,
  openServicePdf,
  selectedCompany,
  setServiceID
}) => {
  //************************  STYLES ************************

  const buttonStyle = buttonStyles();

  //****************** CONTEXT ******************
  const { user, authUser } = useContext(UserContext);
  const { language } = useContext(LayoutContext);

  //************************ CUSTOM HOOKS ************************
  const {
    error: service_error,
    data: service_data,
    loading: service_loading,
    //logCompleted: service_logCompleted,
    //logError: service_logError,
    executeAsync: service_executeAsync,
    executeLog: service_executeLog,
    //setData: service_setData
  } = useAsync({
    axiosRequest: axiosRequest
  });
  //************************  STATE ************************
  const [showServiced, setShowServiced] = useState(false);
  const [filterServiced, setFilterServiced] = useState([]);
  const [engineerSignOpen, setEngineerSignOpen] = useState(false);
  const [customerSignOpen, setCustomerSignOpen] = useState(false);

  const [serviceObj, setServiceObj] = useState({
    service_user_id: user.id,
    service_company_id: selectedCompany.company_id,
    service_name: user.name,
    customer_name: '',
    service_notes: '',
    service_sign: null,
    cust_sign: null,
    service_attr: {
      startTime: new Date().getTime(),
      finishTime: new Date().getTime()
    }
  });

  const openEngineerSignOpen = e => {
    setEngineerSignOpen(true);
  };
  const openCustomerSignOpen = e => {
    setCustomerSignOpen(true);
  };
  const closeEngineerSignOpen = e => {
    setEngineerSignOpen(false);
  };
  const closeCustomerSignOpen = e => {
    setCustomerSignOpen(false);
  };

  const engineer_sign_ref = useRef(null);
  const customer_sign_ref = useRef(null);
  const dialog_content_ref = useRef(null);
  const form_ref = useRef(null);

  //************************ USE EFFECTS ************************
  // Handle SERVICED
  useEffect(() => {
    if (showServiced) {
      setFilterServiced(data);
    } else {
      const noServiced = data.filter(entry => !entry.serviced);
      setFilterServiced(noServiced);
    }
  }, [showServiced, data]);

  //************************ FUNCTIONS ************************
  const openDialog = () => {
    setDialogOpen(true);
  };
  const closeDialog = useCallback(() => {
    setDialogOpen(false);
  }, [setDialogOpen]);

  const submitEngineerSign = (e, sign_ref) => {
    e.preventDefault();
    setServiceObj({
      ...serviceObj,
      service_sign: sign_ref.current.getSignaturePad().toDataURL('image/png')
    });
    closeEngineerSignOpen();
  };
  const submitCustomerSign = (e, sign_ref) => {
    e.preventDefault();
    setServiceObj({
      ...serviceObj,
      cust_sign: sign_ref.current.getSignaturePad().toDataURL('image/png')
    });
    closeCustomerSignOpen();
  };

  //************************ USE TABLE ************************
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page
    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    getToggleHideAllColumnsProps,
    allColumns,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data: filterServiced,
      initialState: {
        hiddenColumns: columns
          .filter(col => col.show === false)
          .map(col => col.accessor)
      }
    },
    useSortBy,
    usePagination,
    useRowSelect,
    // useFlexLayout,
    hooks => {
      hooks.visibleColumns.push(columns => [
        // Let's make a column for selection
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method getToggleAllPageRowsSelectedProps
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
          ),
          collapse: true
        },
        ...columns
      ]);
    }
  );
  //************************ USE MEMO ************************
  const removeSelectionColumn = useMemo(
    () => [...allColumns].splice(1),
    [allColumns]
  );


  const handleSubmit =
    // useCallback(
    async e => {
      e.preventDefault(0);
      //? Check if the form is valid
      // We need to create reference to the form outer form
      // const formRef = useRef(null);
      // if (!formRef.current.checkValidity()) {
      //   // If validation is false don't go ahead
      //   return;
      // }

      const service_entry_ids = selectedFlatRows.map(
        sel_row => sel_row.original.entry_id
      );

      const service_obj_to_send = {
        ...serviceObj,
        service_entry_ids,
        service_attr: {
          startTime: new Date(
            serviceObj.service_attr.startTime
          ).toLocaleTimeString(),
          finishTime: new Date(
            serviceObj.service_attr.finishTime
          ).toLocaleTimeString()
        }
      };

      const service_db = await service_executeAsync({
        endpoint: 'service-report',
        api_key: authUser.api_key,
        method: 'post',
        body: service_obj_to_send
      });

      const setEntryServiced = await service_entry_ids.map(entry_id =>
        service_executeAsync({
          endpoint: 'machines/entries',
          api_key: authUser.api_key,
          method: 'patch',
          body: {
            serviced: true,
            entry_id
          }
        })
      );

      await Promise.all(setEntryServiced);
      openServicePdf(
        service_obj_to_send,
        removeSelectionColumn,
        selectedFlatRows
      );

      setServiceID(service_db.insertId)

      await service_executeLog({
        endpoint: 'activity',
        api_key: authUser.api_key,
        body: {
          attributes: {
            type: 'New Service',
            user_id: user.id,
            service_id: service_db.insertId
          }
        }
      });
      await handleSearch();
      closeDialog();
    };

  console.log(selectedFlatRows)

  return (
    <>
      <Grid item container xs={12} spacing={1}>
        <Grid item container alignItems="center">
          <Grid
            item
            container
            xs={12}
            sm={4}
            spacing={1}
          >
            <Grid item>
              <Typography variant="h5">{setLanguageText(language,"Entries")}</Typography>
            </Grid>
            {/* <Grid item>
              <TableSettings
                showEntryOption
                showEntryText="Show Serviced"
                showEntry={showServiced}
                setShowEntry={setShowServiced}
                allColumns={removeSelectionColumn}
                //getToggleHideAllColumnsProps={getToggleHideAllColumnsProps}
              />
            </Grid> */}


              <Grid item>
                <Button
                  onClick={() => setShowServiced(prevState => !prevState)}
                  variant="contained"
                  size="small"
                  className={`${buttonStyle.fixPointerEvent} ${showServiced ? buttonStyle.success : buttonStyle.danger}`}
                >
                  {showServiced ? 'Hide' : 'Show'} Serviced
                </Button>
            </Grid>
          </Grid>
        </Grid>
        <ServiceEntriesTable
          getTableProps={getTableProps}
          getTableBodyProps={getTableBodyProps}
          headerGroups={headerGroups}
          prepareRow={prepareRow}
          page={rows}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          gotoPage={gotoPage}
          pageCount={pageCount}
          nextPage={nextPage}
          previousPage={previousPage}
          setPageSize={setPageSize}
          pageIndex={pageIndex}
          //pageSize={pageSize}
          pageSize={selectedFlatRows.length}
          getToggleHideAllColumnsProps={getToggleHideAllColumnsProps}
          openDialog={openDialog}
          selectedFlatRows={selectedFlatRows}
          allColumns={allColumns}
        />
      </Grid>
      {/* SERVICE DIALOG */}
      <GlobalDialog
        open={dialogOpen}
        closeModal={closeDialog}
        title="Service Entries"
        handleSubmit={handleSubmit}
        buttonTitle="Submit Service"
        successMessage="Service Submitted!"
        loading={service_loading}
        error={service_error}
        data={service_data}
        fullWidth
        maxWidth="lg"
        ref={form_ref}
        //submit_disabled={!serviceObj.cust_sign || !serviceObj.service_sign}
      >
        <ServiceDialog
          selectedCompany={selectedCompany}
          allColumns={removeSelectionColumn}
          selectedFlatRows={selectedFlatRows}
          openEngineerSignOpen={openEngineerSignOpen}
          openCustomerSignOpen={openCustomerSignOpen}
          serviceObj={serviceObj}
          setServiceObj={setServiceObj}
          ref={dialog_content_ref}
        />
      </GlobalDialog>
      {/* ENGINEER SIGNATURE */}
      <SignatureDialog
        open={engineerSignOpen}
        closeDialog={closeEngineerSignOpen}
        submit_sign={e => submitEngineerSign(e, engineer_sign_ref)}
        title="Engineer Signature"
        sign_ref={engineer_sign_ref}
        dialog_content_ref={dialog_content_ref}
        serviceObj={serviceObj}
        setServiceObj={setServiceObj}
      />
      {/* CUSTOMER SIGNATURE */}
      <SignatureDialog
        open={customerSignOpen}
        closeDialog={closeCustomerSignOpen}
        submit_sign={e => submitCustomerSign(e, customer_sign_ref)}
        title="Customer Signature"
        sign_ref={customer_sign_ref}
        dialog_content_ref={dialog_content_ref}
        serviceObj={serviceObj}
        setServiceObj={setServiceObj}
      />
    </>
  );
};

export default ServiceTable;
