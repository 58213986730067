import React, { useContext, useState, useEffect } from 'react';
import {
  SELECTED_MACHINE,
  SELECTED_MACHINES
} from '../../../store/MachineReducers';
import { MachineContext } from '../../../store/MachineContext';
import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import setLanguageText from '../../../helpers/setLanguageText';
import { LayoutContext } from '../../../store/LayoutContext';

const DropdownMachines = () => {
  const {
    selectedMachine,
    dispatchSelectedMachine,
    dispatchSelectedMachines,
    filteredMachines
  } = useContext(MachineContext);
  const {language} = useContext(LayoutContext)

  //states
  const [options, setOptions] = useState();

  const [storedMachine] = useState(
    JSON.parse(window.sessionStorage.getItem('selectedMachine'))
  );

  // update with stored group in session storage
  useEffect(() => {
    //console.log(storedMachine)
    dispatchSelectedMachine({
      type: SELECTED_MACHINE,
      payload: storedMachine
    });
  }, [storedMachine, dispatchSelectedMachine]);

  // add the option "ALL" into the dropdown
  useEffect(() => {
    //console.log(filteredMachines)
    if (filteredMachines.length) {
      setOptions([{ machine_name: 'All Machines', machine_id: 0 }, ...filteredMachines]);
    } else {
      //no machines
      setOptions([])
    }
  }, [filteredMachines]);

  // dispatch selected machine
  const onSelectMachine = machine => {
    if (machine) {
      dispatchSelectedMachine({
        type: SELECTED_MACHINE,
        payload: machine
      });
      //update session storage
      window.sessionStorage.setItem('selectedMachine', JSON.stringify(machine))
      dispatchSelectedMachines({
        type: SELECTED_MACHINES,
        payload: machine
      });
    }
  };

  //add machine counter
  const modifiedValue =
    selectedMachine?.machine_id === 0
      ? {
        ...selectedMachine,
        machine_name: `${selectedMachine.machine_name}`
      }
      : selectedMachine;


  return (
    <Autocomplete
     disableClearable
     id="selected-machine"
     //value={selectedMachine}
     value={modifiedValue && Object.entries(modifiedValue).length ? modifiedValue : null}
     options={options || []}
     loading={modifiedValue && options ? false : true}
     //getOptionLabel={option => option.machine_name || ''}
     getOptionLabel={option => option.machine_id === 0 ? `${option.machine_name} (${options?.length > 0 ? (options.length-1) : 0})`: option.machine_name }  
     isOptionEqualToValue={(option, value) =>
       option.machine_id === value.machine_id
     }
     onChange={(event, newValue) => {
       onSelectMachine(newValue);
     }}
     renderInput={params => (
       <TextField
         {...params}
         variant="outlined"
         label={setLanguageText(language,"Selected Machine/s")}
       />
     )}
   />
  );
};

export default DropdownMachines;
