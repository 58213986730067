// parse machines' attributes and settings
const parseCoolant = coolant => ({
  ...coolant,
  coolant_defaults: JSON.parse(coolant.coolant_defaults),
  machines_using_coolant: coolant.machines_using_coolant
    ? JSON.parse(coolant.machines_using_coolant)
    : null
});
const parseCoolants = coolants => {
  //console.log(coolants)
  if (coolants.length) return coolants.map(coolant => parseCoolant(coolant));
  return parseCoolant(coolants);
};
export default parseCoolants;
