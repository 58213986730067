import React, { useContext, useCallback } from 'react';
import {
    Grid,
    Typography,
    Card,
    CardContent,
} from '@mui/material';
import { buttonStyles } from '../../../styles/buttonStyles';
import CardStyles from '../../../styles/cardStyles';
import { MachineContext } from '../../../store/MachineContext';
import { faTrash } from '@fortawesome/pro-light-svg-icons';
import useAsync from '../../../Hooks/useAsync';
import axiosRequest from '../../../helpers/axiosRequest';
import LoadingSuccessButton from '../../buttons/LoadingSuccessButton';
import CoolantPDF from '../Coolants/CoolantPDF';
import { UserContext } from '../../../store/UserContext';


const MachineProductList = ({
    product,
    removeProduct
}) => {

    //****** STYLES ******
    const cardStyles = CardStyles();
    const buttonStyle = buttonStyles();

    const { selectedMachine } = useContext(MachineContext);
    const { authUser, user } = useContext(UserContext);

    const {
        loading,
        executeAsync,
    } = useAsync({
        axiosRequest: axiosRequest
    });

    const remove = useCallback(async (product) => {
        // console.log(product);
        // console.log(selectedMachine)
        let products = selectedMachine.machine_settings.products ? selectedMachine.machine_settings.products : []
        if (products.length) {
            products = products.filter((id) => id !== product.coolant_id)
            //e.preventDefault();
            await executeAsync({
                endpoint: 'machines',
                api_key: authUser.api_key,
                method: 'patch',
                body: {
                    edit: 'products',
                    machine_id: selectedMachine.machine_id,
                    settings: products,
                }
            });
            removeProduct(product)
        }
    }, [selectedMachine])

    //****** RENDER ******
    return (

        <Card className={cardStyles.card} elevation={0}>
            <Grid container direction="column" className={cardStyles.cardContainer}>
                <Grid
                    item
                    container
                    className={cardStyles.header}
                    spacing={1}
                >
                    <Grid item container justifyContent="space-between">
                        <Grid item>
                            <Typography variant="h6">{product.coolant_name}</Typography>
                            <Typography variant="body1">{JSON.parse(product.coolant_defaults).coolant?.manufacturer}</Typography>
                        </Grid>
                        {user.level >= 9 &&
                            <Grid item >
                                <LoadingSuccessButton
                                    loading={loading}
                                    fullWidth
                                    variant="contained"
                                    className={buttonStyle.danger}
                                    onClick={() => remove(product)}
                                    icon={faTrash}
                                    text="Remove"
                                /> 
                            </Grid>
                        }
                    </Grid>
                </Grid>
                <CardContent justify="center" className={cardStyles.cardContent}>
                    <Typography variant="body2" className="product--desc">
                        {JSON.parse(product.coolant_defaults).coolant?.type}
                    </Typography>
                </CardContent>
                <Grid
                    item
                    container
                    justifyContent="space-around"
                    className={cardStyles.footer}
                >
                    <CoolantPDF coolant={product} />
                </Grid>
            </Grid>
        </Card>

    );
};

export default MachineProductList;
