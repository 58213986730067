import React from 'react';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ThemeProvider, useTheme } from '@mui/system';

const useStyles = makeStyles(theme => ({
  content: {
    padding: theme.spacing(2, 3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 1)
    }
  },
  container: {
    position: 'relative'
  }
}));

const PageContent = ({children }) => {
  
  const classes = useStyles();
  const applicationTheme = useTheme();

  return (
    <Grid item container className={classes.container}>
        <Grid
          item
          container
          className={classes.content}
          xs={12}
          component="main"
        >
          <ThemeProvider theme={applicationTheme}>
            {children}
          </ThemeProvider>
        </Grid>
    </Grid>
  );
};

export default PageContent;
