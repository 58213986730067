import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import setLanguageText from '../../helpers/setLanguageText';
import splitString from '../../helpers/splitString';

const create_pdf_headers = ({
  language,
  headers,
  styles,
  exclude_columns,
  expand_columns,
  reduce_columns
}) => {
  
  const filter_header_columns = exclude_columns
    ? Object.keys(headers).filter(header => !exclude_columns.includes(header))
    : Object.keys(headers);

  return filter_header_columns.map((header, i) => {
    const to_expand = expand_columns?.includes(header)
      ? 2
      : reduce_columns?.includes(header)
      ? 0.5
      : 1;
    return (
      <View
        key={i}
        style={{
          ...styles.TABLE.HEADER,
          flex: to_expand
        }}
      >
        <Text style={styles.TABLE.HEADER.CELL}>{setLanguageText(language,splitString(header))}</Text>
      </View>
    );
  });
};

export default create_pdf_headers;
