import { faSortDown, faSortUp } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Grid,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme
} from '@mui/material';
import React, { useContext, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { usePagination, useFilters, useRowState, useSortBy, useTable } from 'react-table';
import { MachineContext } from '../../../store/MachineContext';
import {
  SELECTED_MACHINE,
  SELECTED_MACHINES
} from '../../../store/MachineReducers';
import Pagination from '../../reusable/Pagination';
import TableSettings from '../TableSettings';
import { makeStyles } from '@mui/styles';
import { matchSorter } from 'match-sorter';

const useStyles = makeStyles(theme => ({
  cardContent: {
    overflowX: 'auto',
    maxHeight: '70vh',
    padding: '0'
  },
  tableHeader: {
    position: 'sticky',
    zIndex: 1500,
    width: 'fit-content'
  },
  headerCell: column => ({
    textAlign: 'left',
    backgroundColor: theme.palette.background.light,
    padding: theme.spacing(1, 2),
    zIndex: 1000
  }),
  tableRow: {},
  tableCell: cell => ({
    textAlign: 'left',
    padding: theme.spacing(1, 2),
    overflowX: 'auto',
    zIndex: 1000
  })
}));


function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] });
}


const MachinesTable = ({ columns, data, getTableDataFn, headerSettings, defaultHiddenColumns }) => {


  //************************  STYLES ************************

  const theme = useTheme();
  const classes = useStyles();

  //************************  CONTEXT ************************

  const {
    dispatchSelectedMachine,
    dispatchSelectedMachines,
    filteredMachines
  } = useContext(MachineContext);

  //const { user } = useContext(UserContext);
  //************************  FUNCTIONS ************************
  // Hide Table columns coming from settings
  // const viewSettings = entries(user?.user_attributes?.machine_view);
  // const hiddenColumns = viewSettings.map(setting =>
  //   !setting[1] ? setting[0] : null
  // );
  //console.log(hiddenColumns)

  // When click on Machine's row
  const handleRowClick = row => {
    // fetch all the entries for the selected machine
    // const entries = await fetchEntries(event.data.id, authUser.api_key);
    const machine = filteredMachines.find(machine => {
      return machine.machine_id === row.original.machine_id;
    });
    // save the selected machine
    dispatchSelectedMachine({ type: SELECTED_MACHINE, payload: machine });
    dispatchSelectedMachines({
      type: SELECTED_MACHINES,
      payload: machine
    });
    // create machine id route when clicking on Machine's row
    history.push(`/machine/${row.original.machine_id}`);
  };
  // We need this function in order to create the Machine's id route
  let history = useHistory();
  //************************  TABLE CREATE FUNCTION ************************
  const filterTypes = useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      }
    }),
    []
  );
  const defaultColumn = useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: ''
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page
    rows,
    // The rest of these things are super handy, too ;)
    allColumns,
    getToggleHideAllColumnsProps,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setHiddenColumns,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      initialState: {
        pageIndex: 0,
        hiddenColumns: [...headerSettings]
      },
      // Cell's Initial state
      initialRowStateAccessor: cell => {
        return {
          // user to simulate enabled user
          enabled: cell.original.Enabled,
          error: null
        };
      }
    },
    useFilters,
    useSortBy,
    usePagination,
    useRowState
  );

  //set hidden columns for company gloabl default
  useEffect(() => {
    if (defaultHiddenColumns) {
      //console.log(columns)
      //console.log(defaultHiddenColumns)
      let hide = []
      defaultHiddenColumns.forEach(column => {
        if (!column.isVisible) {
          hide.push(column.id)
        }
      })
      setHiddenColumns([...headerSettings, ...hide])
    }
  }, [defaultHiddenColumns])

  //returns table data to parent component
  useEffect(() => {
    if (getTableDataFn) {
      rows && getTableDataFn(rows);
    }
  }, [getTableDataFn, rows]);


  return (
    // apply the table props
    <Grid item container xs={12}>
      <Grid
        item
        container
        xs={12}
        justifyContent="flex-end"
        style={{ marginBottom: theme.spacing(1) }}
      >
        <TableSettings
          allColumns={allColumns}
          getToggleHideAllColumnsProps={getToggleHideAllColumnsProps}
        />
      </Grid>

      <Grid item xs={12}>
        <Card variant="outlined">
          <CardContent className={classes.cardContent}>
            <Table {...getTableProps()} className={classes.table} stickyHeader>
              {/* TABLE HEADER */}
              <TableHead className={classes.tableHeader}>
                {
                  // Loop over the header rows
                  headerGroups.map(headerGroup => (
                    // Apply the header row props
                    <TableRow {...headerGroup.getHeaderGroupProps()}>
                      {
                        // Loop over the headers in each row
                        headerGroup.headers.map(column => {
                          return (
                            // Apply the header cell props
                            <TableCell
                              {...column.getHeaderProps(
                                // add sort functionality
                                column.getSortByToggleProps()
                              )}
                              className={classes.headerCell}
                              style={{
                                minWidth: column.minWidth,
                                zIndex: column.sticky ? 1500 : 0
                              }}
                            >
                              {column.render('Header')}
                              <div>
                                {column.canFilter
                                  ? column.render('Filter')
                                  :
                                  <>
                                    {column.isSorted ? (
                                      column.isSortedDesc ? (
                                        <FontAwesomeIcon icon={faSortDown} />
                                      ) : (
                                          <FontAwesomeIcon icon={faSortUp} />
                                        )
                                    ) : (
                                        ''
                                      )}
                                  </>
                                }
                              </div>
                            </TableCell>
                          );
                        })
                      }
                    </TableRow>
                  ))
                }
              </TableHead>
              {/* TABLE CONTENT */}
              {/* Apply the table body props */}
              <TableBody {...getTableBodyProps()}>
                {
                  // Loop over the table rows
                  page.map((row, i) => {
                    // Prepare the row for display
                    prepareRow(row);
                    return (
                      // Apply the row props
                      <TableRow
                        {...row.getRowProps()}
                        onClick={() => handleRowClick(row)}
                        className={classes.tableRow}
                      // style={{
                      //   backgroundColor:
                      //     row.allCells && row.allCells[0].value
                      //       ? theme.palette.error.main
                      //       : theme.palette.common.white
                      // }}
                      >
                        {
                          // Loop over the rows cells
                          row.cells.map(cell => {
                            // Apply the cell props
                            return (
                              <TableCell
                                {...cell.getCellProps()}
                                className={classes.tableCell}
                                style={{
                                  minWidth: cell.column.minWidth,
                                  zIndex: cell.column.sticky ? 1000 : 0,
                                  position: cell.column.sticky
                                    ? 'sticky'
                                    : 'initial',
                                  backgroundColor:
                                    row.allCells && row.allCells[0].value
                                      ? '#FFEDF1'
                                      : theme.palette.common.white,
                                  left: 0
                                }}
                              >
                                {
                                  // Render the cell contents
                                  cell.render('Cell')
                                }
                              </TableCell>
                            );
                          })
                        }
                      </TableRow>
                    );
                  })
                }
              </TableBody>
            </Table>
          </CardContent>
          {/* PAGINATION AND SETTINGS */}
          <Pagination
            allColumns={allColumns}
            rowCount={data.length}
            getToggleHideAllColumnsProps={getToggleHideAllColumnsProps}
            pageIndex={pageIndex}
            gotoPage={gotoPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            previousPage={previousPage}
            canPreviousPage={canPreviousPage}
            nextPage={nextPage}
            canNextPage={canNextPage}
            pageCount={pageCount}
          />
        </Card>
      </Grid>
    </Grid>
  );
};

export default MachinesTable;
