import cis_theme from '../themes/cis_theme';
import act_theme from '../themes/act_theme';
import sar_theme from '../themes/sar_theme';
import dev_theme from '../themes/dev_theme';
import rhenus_theme from '../themes/rhenus_theme';
import config from '../config'

let theme
if (config.APPNAME === 'act') {
  theme = act_theme
} else if (config.APPNAME === 'cis') {
  theme = cis_theme;
}  else if (config.APPNAME === 'sar') {
  theme = sar_theme;
} else if (config.APPNAME === 'dev') {
  theme = dev_theme;
} else if (config.APPNAME === 'rhenus') {
  theme = rhenus_theme;
}
export default theme;
