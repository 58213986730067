import React, { useContext, useEffect, useState, useCallback } from 'react';
import { ProductsContext } from '../../../store/ProductsContext';
import {
  FormControlLabel,
  Grid,
  Switch
} from '@mui/material';
import { UserContext } from '../../../store/UserContext';
import ProductsHeader from './ProductsHeader';
import ProductsHeaderAdmin from './ProductsHeaderAdmin';
import PageContent from '../../PageContent';
import { Alert } from '@mui/material';
import useAsync from '../../../Hooks/useAsync';
import axiosRequest from '../../../helpers/axiosRequest';
import ProductCard from './ProductCard';
import ProductList from './ProductList';
import CoolantList from '../Coolants/CoolantList';
import CoolantCard from '../Coolants/CoolantCard';
import EditProduct from './EditProduct';
import { BrandsContext } from '../../../store/BrandsContext';
import { fetchBrands } from '../../../helpers/fetchBrands';
import { SAVE_BRANDS } from '../../../store/BrandsReducers';
import LoadingScreen from '../../reusable/LoadingScreen';
import {
  SAVE_PRODUCTS,
  UPDATE_PRODUCT_OPTIONS
} from '../../../store/ProductsReducers';
import { CompanyContext } from '../../../store/CompanyContext';
import LayoutSelect from '../../reusable/LayoutSelect';
import ProductTypeSelect from '../../reusable/ProductTypeSelect';
import ProductSort from '../../reusable/ProductSort';
import parseCoolant from '../../../helpers/parseCoolants';
import sortCoolants from '../../../helpers/sortCoolants';
import setLanguageText from '../../../helpers/setLanguageText';
import { LayoutContext } from '../../../store/LayoutContext';

const Products = () => {

  const {
    products,
    productsOptions,
    dispatchProducts,
    selectedProduct,
    dispatchProductsOptions,
    productTypes
  } = useContext(ProductsContext);
  const { language } = useContext(LayoutContext);  
  const { selectedCompany } = useContext(CompanyContext);
  const { user, authUser } = useContext(UserContext);
  const { brands, dispatchBrands } = useContext(BrandsContext);
  const [companyProducts, setCompanyProducts] = useState();
  const [showCompanyOnly, setShowCompanyOnly] = useState();
  const [show, setShow] = useState(false);

  const [isEditDialog, setIsEditDialog] = useState(false);


  useEffect(() => {
    // only fetch brands if NOT fetched before
    if (!brands.length) {
      fetchBrands(authUser.api_key).then(brands => {
        dispatchBrands({
          type: SAVE_BRANDS,
          payload: brands
        });
      });
    }
  }, [authUser.api_key, brands, dispatchBrands]);


  // ---------  fetch companies visible products ------------

  const {
    data: data_vis,
    loading: loading_vis,
    executeAsync: executeAsync_vis
  } = useAsync({
    axiosRequest: axiosRequest
  });

  const getVisibleProducts = async (company_id, level) => {
    //console.log('fetch all product by type')
    executeAsync_vis({
      method: 'get',
      api_key: authUser.api_key,
      endpoint: `coolants`,
      params: {
        type: 0, //add for new sql
        user_level: level,
        company_ids: company_id
      }
    });
  };

  //update context
  useEffect(() => {
    if (data_vis) {
      dispatchProductsOptions({
        type: UPDATE_PRODUCT_OPTIONS,
        payload: { 'visible': data_vis }
      });
    }
  }, [data_vis]);

  useEffect(() => {
    if (user.level === 9) {
      getVisibleProducts(selectedCompany.company_id, user.level);
    }
  }, [
    selectedCompany.company_id,
    user
  ]);

  // --------  get all products level 9 ---------

  const {
    data: data_product,
    loading: loading_product,
    executeAsync: data_executeAsync
  } = useAsync({
    axiosRequest: axiosRequest
  });

  const getProducts = useCallback(async (productType) => {
    setShowCompanyOnly(false);
    const products = await data_executeAsync({
      endpoint: 'coolants',
      api_key: authUser.api_key,
      method: 'get',
      params: {
        user_level: user.level,
        type: productType
      }
    });
    //console.log(products)
    if (products) {
      dispatchProducts({
        type: SAVE_PRODUCTS,
        payload: sortCoolants(products, 'asc')
      })
    }

  }, [authUser, user, data_executeAsync])

  // --------  get company products  ---------

  const getProductsByCompany = useCallback( async (productType, selectedCompany) => {
    setShowCompanyOnly(true);
    const products = await data_executeAsync({
      endpoint: 'coolants',
      api_key: authUser.api_key,
      method: 'get',
      params: {
        company_ids: selectedCompany.company_id,
        user_level: user.level,
        type: productType
      }
    });

    if (products) {
      console.log(products)
      dispatchProducts({
        type: SAVE_PRODUCTS,
        payload: sortCoolants(products, 'asc')
      })
    }

  }, [authUser, user, data_executeAsync])

  useEffect(() => {
    if (productsOptions?.type?.coolant_type_id) {
      setShow(false);
      if (user.level === 9) {
        console.log('fetch once for level 9')
        getProducts(productsOptions.type.coolant_type_id)
      }
    }
  }, [
    productsOptions.type,
  ]);

  useEffect(() => {
    if (productsOptions?.type?.coolant_type_id) {
      setShow(false);
      if (user.level !== 9) {
        console.log('fetch every time for user')
        //if user can only see thier compnay products
        getProductsByCompany(productsOptions.type.coolant_type_id, selectedCompany);
      }
    }
  }, [
    productsOptions.type,
    selectedCompany
  ]);

  //open close edit
  const openEditDialog = () => setIsEditDialog(true);
  const closeEditDialog = () => setIsEditDialog(false);

  //show the users view of products - testing users view
  const setUserView = (e, show) => {
    setShow(show)
    //console.log(products)
    //console.log(show)
    if (show) {
      let companyProducts = [];
      productsOptions.visible.filter(visible => {
        let found = products.find(product => product.coolant_id === visible.coolant_id)
        if (found) {
          companyProducts.push(found)
        }
        return
      })
      companyProducts = sortCoolants(companyProducts, productsOptions.toggleSort)
      setCompanyProducts(companyProducts)
      setShowCompanyOnly(true)
    } else {
      setCompanyProducts([])
      setShowCompanyOnly(false);
    }
  };

  return (
    <>
      {user.level === 9 ?
        <ProductsHeaderAdmin />
        :
        <ProductsHeader />
      }
      <PageContent>

        <Grid
          container
          //alignItems="stretch"
          justifyContent="space-between"
          spacing={2}
        >
          {user.level === 9 &&
            <Grid container item xs={12} spacing={2} >
              <Grid item>
                <Alert severity='info'>
                {setLanguageText(language,"On this page you can select a company, then add and remove products that are visible for that companies users.Use the toggle button to see how the view will look for the selected companies users.")}
                </Alert>
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Switch
                      onChange={(e, value) => setUserView(e, value)}
                      checked={show}
                      name="view"
                      color="primary"
                    />
                  }
                  name="view"
                 label={setLanguageText(language,"Show Company User View")}
                />
              </Grid>
            </Grid>
          }

          <Grid item xs={12} md={4}>
            <ProductTypeSelect />
          </Grid>
          <Grid item container xs={12} md={3} justifyContent="space-between">
            <Grid container item xs={7} >
              <ProductSort />
            </Grid>
            <Grid container item xs={4} >
              <LayoutSelect />
            </Grid>
          </Grid>
        </Grid>
      </PageContent>
      <PageContent>
        {loading_product &&
          <LoadingScreen background="#fafafa" colour="#666" text="Loading Products" />
        }
        {user.level === 9 ? (
          <Grid container spacing={productsOptions.toggleView === 'grid' ? 2 : 0} >

            {products.length ? (
              <>
                {!showCompanyOnly ? (
                  <>
                    {products.map((product, i) =>
                      productsOptions.toggleView === 'grid' ? (
                        <Grid item xs={12} lg={6} xl={4} key={i}>
                          <ProductCard
                            product={parseCoolant(product)}
                            openEditDialog={openEditDialog}
                            loading_vis={loading_vis}
                          />
                        </Grid>
                      ) : (
                          <ProductList key={i}
                            product={parseCoolant(product)}
                            openEditDialog={openEditDialog}
                            loading_vis={loading_vis}
                          />
                        )
                    )}
                  </>
                ) : (
                    <>
                      {companyProducts.length ?
                        <>
                          {companyProducts.map((product, i) =>
                            productsOptions.toggleView === 'grid' ?
                              <Grid item xs={12} md={6} lg={4} xl={3} key={i}>
                                <CoolantCard
                                  coolant={parseCoolant(product)}
                                  openEditDialog={openEditDialog}
                                />
                              </Grid>
                              :
                              <CoolantList key={i} coolant={parseCoolant(product)} />
                          )}
                        </>
                        :
                        <>
                          { !loading_product &&
                            <Grid item xs={12}>
                              <Alert severity='error'>No products visible to {selectedCompany.company_name}</Alert>
                            </Grid>
                          }
                        </>
                      }
                    </>
                  )}
              </>
            ) : (
                <>
                  { !loading_product &&
                    <Grid item xs={12}>
                      <Alert severity='error'>No products available in this category</Alert>
                    </Grid>
                  }
                </>
              )}
          </Grid>
        ) : (
            <Grid container spacing={productsOptions.toggleView === 'grid' ? 2 : 0} >

              {products.length ?
                <>
                  {products.map((product, i) =>
                    productsOptions.toggleView === 'grid' ?
                      <Grid item xs={12} md={6} lg={4} xl={3} key={i}>
                        <CoolantCard
                          coolant={parseCoolant(product)}
                          openEditDialog={openEditDialog}
                        />
                      </Grid>
                      :
                      <CoolantList key={i} coolant={parseCoolant(product)} />
                  )}
                </>
                :
                <>
                  { !loading_product &&
                    <Grid item xs={12}>
                      <Alert severity='error'>No products available in this category</Alert>
                    </Grid>
                  }
                </>
              }
            </Grid>
          )}
        {isEditDialog &&
          <EditProduct
            typeOptions={productTypes}
            coolant={selectedProduct}
            closeEditDialog={closeEditDialog}
            isEditDialog={isEditDialog}
          />
        }
      </PageContent>
    </>
  );
};

export default Products;
