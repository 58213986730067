import React, { useContext } from 'react';
import { Typography, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PrimaryBtnIcon from '../../buttons/PrimaryBtnIcon';
import { faEdit, faExternalLink } from '@fortawesome/pro-light-svg-icons';
import formatDate from '../../../helpers/formatDate';
import setLanguageText from '../../../helpers/setLanguageText';
import { LayoutContext } from '../../../store/LayoutContext';

const NotificationsTable_columns = (user, goToMachine,goToEntry) => {

  const { language } = useContext(LayoutContext)
  
  //console.log(user);
  let columns = [
    // {
    //   id: 'note_id',
    //   name: 'Notification ID',
    //   Header: () => <Typography variant="h6">Notification ID</Typography>,
    //   accessor: 'note_id',
    //   Cell: ({ row, value }) => (
    //     <Typography variant="body1">
    //       {value}
    //     </Typography>
    //   )
    // },
    {
      id: 'note_name',
      name: 'Name',
      Header: () => <Typography variant="h6">{setLanguageText(language,"Name")}</Typography>,
      accessor: 'note_name',
      Cell: ({ row, value }) => (
        <Typography variant="body1">
          {value}
        </Typography>
      )
    },
    // {
    //   id: 'note_frequency',
    //   name: 'Check Frequency',
    //   Header: () => <Typography variant="h6">Frequency</Typography>,
    //   accessor: 'note_frequency',
    //   Cell: ({ row, value }) => {
    //     let check = JSON.parse(value)
    //     return (
    //       row.original.note_type === "machine_check" ?
    //         <Typography variant="body1">
    //           {check.check_freq.title} on a {check.check_day.title}
    //         </Typography>
    //         : null
    //     )
    //   }
    // },
    {
      id: 'note_settings',
      name: 'Details',
      Header: () => <Typography variant="h6">{setLanguageText(language,"Details")}</Typography>,
      accessor: 'note_settings',
      Cell: ({ row, value }) => {
        let setting = JSON.parse(value)
        console.log(row.original)
        return (
          <>
          <Typography variant="body1">
          {setLanguageText(language,"Company")}: <strong>{row.original.company_name}</strong>
          </Typography>
          <Typography variant="body1">
          {setLanguageText(language,"Machine")}: <strong>{row.original.machine_name}</strong>
        </Typography>
        </>
        )
      }
    },{
      id: 'note_sent',
      name: 'Reminders Sent',
      Header: () => <Typography variant="h6">{setLanguageText(language,"Reminders Sent")}</Typography>,
      accessor: 'note_sent',
      Cell: ({ row, value }) => (
        <Typography variant="body1">
          {value}
        </Typography>
      )
    },
    {
      id: 'note_next_send',
      name: 'Check Due',
      Header: () => <Typography variant="h6">{setLanguageText(language,"Check Due")}</Typography>,
      accessor: 'note_next_send',
      Cell: ({ row, value }) => (
        <Typography variant="body1">
          {formatDate(value)}
        </Typography>
      )
    },
    // {
    //   id: 'note_status',
    //   name: 'Note Status',
    //   Header: () => <Typography variant="h6">Note Status</Typography>,
    //   accessor: 'note_status',
    //   Cell: ({ row, value }) => (
    //     <Typography variant="body1">
    //       {value}
    //     </Typography>
    //   )
    // },
    {
      id: 'Actions',
      Header: () => <Typography variant="h6">{setLanguageText(language,"Actions")}</Typography>,
      sortable: false,
      minWidth: 150,
      width: 150,
      maxWidth: 150,
      Cell: ({ row }) => {
        //console.log(row)
        return (
          <Grid container spacing={1} justifyContent="center">
            {row.original.note_type === "bacteria" || row.original.note_type === "fungi" ?
              <Grid item xs={12}>
                <PrimaryBtnIcon
                  icon={faEdit}
                  text="Edit Entry"
                  //disabled
                  //iconSize="2x"
                  onClick={e => goToEntry(row.original)}
                  size="small"
                />
              </Grid>
              : row.original.note_type === "machine_check" ?
                <Grid item xs={12}>
                  <PrimaryBtnIcon
                    icon={faExternalLink}
                    text="Go to Machine"
                    color="secondary"
                    //disabled
                    //iconSize="2x"
                    onClick={e => goToMachine(row.original.machine_id)}
                    size="small"
                  />
                </Grid>
                : null}
          </Grid>
        )
      }
    }]
  return columns;
};

export default NotificationsTable_columns;
