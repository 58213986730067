import {
  Button,
  Grid,
  Typography,
  TextField,
  LinearProgress
} from '@mui/material';
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCloudUpload,
  faFileEdit,
  faTrashAlt
} from '@fortawesome/pro-light-svg-icons';
import GlobalDialog from '../reusable/GlobalDialog';
import CategoriesDropdown from './CategoriesDropdown';
import { buttonStyles } from '../../styles/buttonStyles';
import { useCallback } from 'react';
import axiosRequest from '../../helpers/axiosRequest';
import useAsync from '../../Hooks/useAsync';
import { Storage } from 'aws-amplify';
import { useContext } from 'react';
import { UserContext } from '../../store/UserContext';
import { useTheme } from '@mui/styles';
import setLanguageText from '../../helpers/setLanguageText';
import { LayoutContext } from '../../store/LayoutContext';

const AddResourceDialog = ({
  isAddResource,
  closeAddResource,
  groupCategories,
  resource,
  getResources,
  setResource,
  input_ref,
  uploadFile
}) => {
  const { authUser, user } = useContext(UserContext);
  const { language } = useContext(LayoutContext);  
  const buttonStyle = buttonStyles();

  const [progress, setProgress] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState({});

  const { loading, error, setData, executeAsync, executeLog } = useAsync({
    axiosRequest: axiosRequest
  });
  // //******************* REFS *******************
  const theme = useTheme();

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault();
      const response = await executeAsync({
        endpoint: 'resource',
        method: 'post',
        api_key: authUser.api_key,
        body: {
          ...resource.resource_to_send,
          resource_upload_time: new Date().getTime()
        }
      });
      if (response) {
        const file_path = `resources/${response.insertId}.${resource.file_ext}`;
        try {
          await Storage.put(file_path, resource.file, {
            progressCallback(progress) {
              setProgress((progress.loaded / progress.total) * 100);
            },
            contentType: resource.resource_to_send.resource_type
          });
        } catch (error) {
          console.log(error);
        }

        await executeLog({
          endpoint: 'activity',
          api_key: authUser.api_key,
          body: {
            attributes: {
              type: 'Add Resource',
              user_id: user.id,
              resource_id: response.insertId,
              file_type: resource.resource_to_send.resource_type
            }
          }
        });
        getResources({
          endpoint: 'resource',
          api_key: authUser.api_key,
          method: 'get'
        });
        setTimeout(() => {
          closeAddResource();
          input_ref.current = null;
          setResource({});
          setData(null);
          setProgress(0);
        }, 1000);
      }
    },
    [
      authUser.api_key,
      closeAddResource,
      executeAsync,
      executeLog,
      getResources,
      input_ref,
      resource,
      setData,
      setResource,
      user.id
    ]
  );

  const removeFile = () => {
    closeAddResource();
    input_ref.current.value = '';
    setResource({});
  };

  const handleFileName = e => {
    e.persist();
    setResource(prevState => ({
      ...prevState,
      resource_to_send: {
        ...prevState.resource_to_send,
        resource_file_name: e.target.value
      }
    }));
  };

  const handleDescription = e => {
    e.persist();

    setResource(prevState => ({
      ...prevState,
      resource_to_send: {
        ...prevState.resource_to_send,
        resource_description: e.target.value
      }
    }));
  };

  return (
    <GlobalDialog
      open={isAddResource}
      closeModal={closeAddResource}
      title="Add Resource"
      buttonTitle="Add Resource"
      successMessage="Resource added!"
      fullWidth
      maxWidth="md"
      handleSubmit={handleSubmit}
      data={progress === 100}
      loading={loading || progress > 0}
      error={error}
    >
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs={12} sm={6}>
          <Button
            fullWidth
            variant="contained"
            component="label"
            style={{ backgroundColor: theme.palette.warning.main }}
          >
            <FontAwesomeIcon
              icon={!resource.file ? faCloudUpload : faFileEdit}
              size="lg"
            />
            <Typography className={buttonStyle.buttonIconText}>
            {setLanguageText(language,"Change File")}
            </Typography>
            <input
              type="file"
              accept=".pdf, video/*"
              onChange={uploadFile}
              ref={input_ref}
              hidden
            />
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            fullWidth
            variant="contained"
            component="label"
            style={{ backgroundColor: theme.palette.error.main }}
            onClick={removeFile}
          >
            <FontAwesomeIcon icon={faTrashAlt} size="lg" />
            <Typography className={buttonStyle.buttonIconText}>
            {setLanguageText(language,"Remove File")}
            </Typography>
          </Button>
        </Grid>

        {resource.file && (
          <>
            <Grid item xs={12} style={{ marginTop: theme.spacing(1) }}>
              <Grid container>
                <Grid item style={{ flex: 1 }}>
                  <TextField
                    value={resource.resource_to_send.resource_file_name}
                    variant="outlined"
                    fullWidth
                    onChange={handleFileName}
                   label={setLanguageText(language,"File Name")}
                    required
                  />
                </Grid>
              </Grid>
            </Grid>
            {/* DESCRIPTION */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                onChange={handleDescription}
                value={resource.description}
                variant="outlined"
               label={setLanguageText(language,"Description")}
                multiline
                required
              />
            </Grid>
            {/* FILE TYPE */}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                value={resource.resource_to_send.resource_type}
                variant="outlined"
               label={setLanguageText(language,"File Type")}
                disabled
              />
            </Grid>
            {/* CATEGORIES */}
            <Grid item xs={12} sm={6}>
              <CategoriesDropdown
                setResource={setResource}
                groupCategories={groupCategories}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                noAll
              />
            </Grid>
            {progress > 0 && (
              <Grid item xs={12}>
                <Typography>{setLanguageText(language,"File Uploading Progress")}:</Typography>

                <Grid container alignItems="center" spacing={1}>
                  <Grid item xs={11}>
                    <LinearProgress
                      value={progress}
                      variant="determinate"
                      color="primary"
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <Typography>{`${Math.round(progress)}%`}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </GlobalDialog>
  );
};

export default AddResourceDialog;
