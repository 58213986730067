import React, { useState, useEffect, useContext } from 'react';
import {
    Grid,
    TextField
} from '@mui/material';
import setLanguageText from '../../helpers/setLanguageText';
import { LayoutContext } from '../../store/LayoutContext';

const TypesForm = ({ type, updateType }) => {


    const { language } = useContext(LayoutContext)
    //************  STYLES ************

    //************  CONTEXT ************

    //************ STATES ************

    //************  USE EFFECT ************

    //************ FUNCTIONS ************

    const updateName = (e) => {
        e.persist();
        updateType(type => {
            return ({
                ...type,
                additive_type_name: e.target.value
            })
        })
    }

    return (
        <Grid item container spacing={2}>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    name="addititve_type_name"
                   label={setLanguageText(language,"Name")}
                    variant="outlined"
                    onChange={e => updateName(e)}
                    value={type ? type.additive_type_name : ''}
                />
            </Grid>
        </Grid>

    );
};

export default TypesForm;
